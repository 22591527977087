import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { slide as Menu } from 'react-burger-menu';
import GreenButton from './greenButton';
import SimpleCloseBtn from './simpleCloseBtn';
import { Autocomplete } from '@react-google-maps/api';
import * as geolib from 'geolib';
import CatchLogTable from './catchLogTable';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Sidebar = ({ 
  showLoader,
  handleLoginData, 
  handleLogout, 
  handleFeedback,
  isLoggedIn, 
  userInfo, 
  userSettingsObjectDB, 
  handleSettingsRadioBtnChange,
  setUserLocation,
  userLocation,
  nearestCity,
  parentSignUpModalOpen,
  closeSignUpModalFromParent,
  parentLoginModalOpen,
  closeLoginModalFromParent,
}) => {
  
    const userId = userInfo.id;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [nearestStation, setNearestStation] = useState(null);

    // Function to open the menu
    const openMenu = () => {
        setIsMenuOpen(true);
    };

    // Function to close the menu
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [newFeaturesModalOpen, setNewFeaturesModalOpen] = useState(false);
  const [aboutUsModalOpen, setAboutUsModalOpen] = useState(false);
  const [termsOfServiceModalOpen, setTermsOfServiceModalOpen] = useState(false);
  const [fishingReportsModalOpen, setFishingReportsModalOpen] = useState(false);
  const [tideDataModalOpen, setTideDataModalOpen] = useState(false);
  const [catchLogModalOpen, setCatchLogModalOpen] = useState(false);
  const [today, setToday] = useState('');
  const [tomorrow, setTomorrow] = useState('');
  const [todayTide, setTodayTide] = useState('');
  const [tomorrowTide, setTomorrowTide] = useState('');
  const [tideLocation, setTideLocation] = useState(nearestCity);
  const [tideLocationCoords, setTideLocationCoords] = useState({});
  const [tideData, setTideData] = useState([]);
  const [tideStations, setTideStations] = useState([]);
  const [signupCompleted, setSignupCompleted] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  // trigger to open sign up modal.. after closing it the button stops working
  useEffect(() => {
    if(parentSignUpModalOpen) {
      setSignUpModalOpen(true)
    }
  }, [parentSignUpModalOpen])

  useEffect(() => {
    if(parentLoginModalOpen) {
      setLoginModalOpen(true)
    }
  }, [parentLoginModalOpen])

    useEffect(() => {
      if (userInfo.id) {
        setFeedbackFormData({
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,        
        });
      }
    }, [userInfo]);

  useEffect(() => {
    setTideLocation(nearestCity);
  }, [nearestCity]);

  const onLoad = (autocomplete) => {
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
  
        if (place.address_components) {
          const cityComponent = place.address_components.find(
            (component) => component.types.includes('locality')
          );
          if (cityComponent) {
            setTideLocation(cityComponent.long_name);
            setTideLocationCoords({ latitude, longitude });
          }
        }
      }
    });
  };

  const handleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  }

  const sendPasswordResetEmail = () => {
    axios.post(API_BASE_URL + '/forgotPassword', {
      email: loginFormData.email,
    })
      .then((response) => {
        console.log(response);
        closeLoginModal();
      })
      .catch((error) => {
        console.error(error);
        alert('Error sending password reset email');
      });
  };

  const getTideData = () => {

  showLoader(true);

    fetch('https://api-iwls.dfo-mpo.gc.ca/api/v1/stations')
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      setTideStations(data);
      showLoader(false);
      console.log(data);
      // Process the JSON data here
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
        
  }
    
    useEffect(() => {

      if (userLocation && tideStations.length > 0) {
        if(tideLocationCoords.latitude && tideLocationCoords.longitude) {
          setUserLocation(tideLocationCoords);
        }
        const nearest = geolib.findNearest(userLocation, tideStations.map(station => ({
          latitude: station.latitude,
          longitude: station.longitude,
        })));
  
        const nearestStationInfo = tideStations.find(station => station.latitude === nearest.latitude && station.longitude === nearest.longitude);
  
        setNearestStation(nearestStationInfo);
  
        // Find data for nearest station
        fetch(`https://api-iwls.dfo-mpo.gc.ca/api/v1/stations/${nearestStationInfo.id}/data?time-series-code=wlp-hilo&from=${todayTide}T00:00:00Z&to=${tomorrowTide}T00:30:00Z`)
          .then((response) => {
            console.log(today, tomorrow)
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            // Update the state variable you intended to use
            setTideData(data);
            // Process the JSON data here
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }, [userLocation, tideStations]);

    const TideTables = () => {
      // Create a map to store tide data grouped by day
      const groupedTideData = new Map();
    
      tideData.forEach((entry) => {
        const date = new Date(entry.eventDate);

        const formattedDay = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
    
        if (!groupedTideData.has(formattedDay)) {
          groupedTideData.set(formattedDay, []);
        }
    
        groupedTideData.get(formattedDay).push(entry);
        
      });

        // Filter keys to exclude the first and last tables
      const filteredKeys = Array.from(groupedTideData.keys()).filter((formattedDay, index, array) => {
        return index > 1 && index < array.length - 1;
      });
    
      return filteredKeys.map((formattedDay, index) => {

        return (

        <div key={index} className='tide-table-container'>
          <h3>{formattedDay}</h3>
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Height (m)</th>
                <th>Height (f)</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody>
              {groupedTideData.get(formattedDay).map((entry) => {
                const date = new Date(entry.eventDate);
                const formattedTime = date.toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'America/Los_Angeles', // PST
                });
    
                return (
                  <tr key={entry.eventDate}>
                    <td>{formattedTime}</td>
                    <td>{entry.value.toFixed(2)}</td>
                    <td>{(entry.value * 3.28084).toFixed(1)}</td>
                    {/* Add more cells based on the structure of your data */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        )
    });
    };
    
  
  // const handleStartDateChange = (e) => {
  //   setToday(e.target.value);
  // };

  // const handleEndDateChange = (e) => {
  //   setTomorrow(e.target.value);
  // };

  const handleStartDateChange = (e) => {
    const pstDate = e.target.value;
        // keep track of the actual day the user selected
    setToday(pstDate);
    // Parse the input date string
    const selectedDate = new Date(pstDate);
  
    // Subtract one day from the selected date.. something weird in the API call so I am collectin additional data then filtering down to what the user selected
    selectedDate.setDate(selectedDate.getDate() - 1);
  
    // Format the updated date as a string
    const formattedUpdatedDate = selectedDate.toISOString().split('T')[0];
    console.log('usersDatezz:', formattedUpdatedDate);

    // Set the formatted date string in your state variable
    setTodayTide(formattedUpdatedDate);

  };
  
  const handleEndDateChange = (e) => {
    const pstDate = e.target.value;
    // keep track of the actual day the user selected
    setTomorrow(pstDate);
    console.log('usersDate:', pstDate);
    // Parse the input date string
    const selectedDate = new Date(pstDate);
  
    // Add one day to the selected date.. something weird in the API call so I am collectin additional data then filtering down to what the user selected
    selectedDate.setDate(selectedDate.getDate() + 2);
  
    // Format the updated date as a string
    const formattedUpdatedDate = selectedDate.toISOString().split('T')[0];
  
    // Set the formatted date string in your state variable
    setTomorrowTide(formattedUpdatedDate);

  };

  const signUpInitialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const [signUpFormData, setSignUpFormData] = useState(signUpInitialState);

  const loginInitialState = {
    email: '',
    password: '',
  };
  const [loginFormData, setLoginFormData] = useState(loginInitialState);

  const feedbackInitialState = {
    firstName: userInfo.firstName ? userInfo.firstName : '',
    lastName: userInfo.lastName ? userInfo.lastName : '',
    email: userInfo.email ? userInfo.email : '',
    feedback: '',
  }

  const [feedbackFormData, setFeedbackFormData] = useState(feedbackInitialState);

  const formErrorsInitialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    textArea: '',
  }

  const [formErrors, setFormErrors] = useState(formErrorsInitialState);

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedbackFormData({ ...feedbackFormData, [name]: value });
  };

  const openModal = (modalStateSetter) => {
    modalStateSetter(true);
    closeMenu();
  };

  const closeModal = (modalStateSetter, dataStateSetter, errorsStateSetter, dataInitialState, errorsInitialState) => {
    modalStateSetter(false);
    if (dataStateSetter) dataStateSetter(dataInitialState);
    if (errorsStateSetter) errorsStateSetter(errorsInitialState);
  };
  
  const closeSignUpModal = () => {
    closeModal(setSignUpModalOpen, setSignUpFormData, setFormErrors, signUpInitialState, formErrorsInitialState);
    closeSignUpModalFromParent();
  };
  const closeLoginModal = () => {
    closeModal(setLoginModalOpen, setLoginFormData, setFormErrors, loginInitialState, formErrorsInitialState);
    closeLoginModalFromParent();
    setForgotPassword(false);
  };
  const closeSettingsModal = () => closeModal(setSettingsModalOpen);
  const closeFeedbackModal = () => closeModal(setFeedbackModalOpen, setFeedbackFormData, setFormErrors, feedbackInitialState, formErrorsInitialState);
  const closeNewFeaturesModal = () => closeModal(setNewFeaturesModalOpen);
  const closeAboutUsModal = () => closeModal(setAboutUsModalOpen);
  const closeTermsOfServiceModal = () => closeModal(setTermsOfServiceModalOpen);
  const closeFishingReportsModal = () => closeModal(setFishingReportsModalOpen);
  const closeCatchLogModal = () => closeModal(setCatchLogModalOpen);
  const closeTideDataModal = () => {
    closeModal(setTideDataModalOpen);
    setTideLocation(nearestCity);
    setTideData([]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSignUpFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

    const handleLoginInputChange = (event) => {
        const { name, value } = event.target;
        setLoginFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

  const validateSignUpForm = () => {
    const errors = {};

    if (signUpFormData.firstName.trim() === '') {
      errors.firstName = 'First Name is required';
    }
    if (signUpFormData.lastName.trim() === '') {
      errors.lastName = 'Last Name is required';
    }
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (signUpFormData.email.trim() === '') {
        errors.email = 'Email is required';
    } else if (!emailRegex.test(signUpFormData.email)) {
        errors.email = 'Invalid email address';
    }

    // Password validation
    if (signUpFormData.password === '') {
        errors.password = 'Password is required';
    } else if (signUpFormData.password.length < 6) {
        errors.password = 'Password must be at least 6 characters';
    }

    // Confirm Password validation
    if (signUpFormData.confirmPassword === '') {
        errors.confirmPassword = 'Confirm Password is required';
    } else if (signUpFormData.password !== signUpFormData.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }
    setFormErrors(errors);

    // Return true if there are no errors, indicating a valid form
    return Object.keys(errors).length === 0;
  };

  const handleSignUp = () => {
    // Validate the form before proceeding
    if (!validateSignUpForm()) {
      return;
    }
  
    // Prepare the user data to send to the server
    const userDataSignUp = {
      firstName: signUpFormData.firstName,
      lastName: signUpFormData.lastName,
      email: signUpFormData.email,
      password: signUpFormData.password,
    };
  
    // Send the user data to the server using Axios
    axios.post(API_BASE_URL + '/signup', userDataSignUp)
      .then((response) => {
        // Close the modal and reset the form data
        closeSignUpModal();
        setLoginFormData((prevFormData) => ({
          ...prevFormData,
          email: signUpFormData.email,
          password: signUpFormData.password,
        }));
        setSignupCompleted(true);
      })
      .catch((error) => {
        console.error('Error registering user:', error.response.data);
        // Display an error message to the user (e.g., email already exists)
        setSignUpFormData({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
        });
        alert(error.response.data.error);
      });
      setSignUpFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
  };
  
  useEffect(() => {
    if (signupCompleted && Object.keys(loginFormData).length > 0) {
      // This effect will run whenever loginFormData changes after signup is complete
      console.log(loginFormData);
      handleLogin();
    }
  }, [loginFormData, signupCompleted]);

  const validateLoginForm = () => {
    const errors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (loginFormData.email.trim() === '') {
        errors.email = 'Email is required';
    } else if (!emailRegex.test(loginFormData.email)) {
        errors.email = 'Invalid email address';
    }

    // Password validation
    if (loginFormData.password === '') {
        errors.password = 'Password is required';
    } else if (loginFormData.password.length < 6) {
        errors.password = 'Password must be at least 6 characters';
    }

    setFormErrors(errors);

    // Return true if there are no errors, indicating a valid form
    return Object.keys(errors).length === 0;
  };

    const validateFeedbackForm = () => {
      const errors = {};
    
      // Check and validate firstName
      if (!userInfo.firstName && feedbackFormData.firstName.trim() === '') {
        errors.firstName = 'First Name is required';
      }
    
      // Check and validate lastName
      if (!userInfo.lastName && feedbackFormData.lastName.trim() === '') {
        errors.lastName = 'Last Name is required';
      }
    
      // Check and validate feedback
      if (feedbackFormData.feedback.trim() === '') {
        errors.feedback = 'Feedback is required';
      }
    
      // Check and validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/;
      if (!userInfo.email && feedbackFormData.email.trim() === '') {
        errors.email = 'Email is required';
      } else if (
        !userInfo.email &&
        !emailRegex.test(feedbackFormData.email)
      ) {
        errors.email = 'Invalid email address';
      }
    
      setFormErrors(errors);
    
      // Return true if there are no errors, indicating a valid form
      return Object.keys(errors).length === 0;
    };

  const handleLogin = () => {
    // Validate the form before proceeding
    if (!validateLoginForm()) {
      console.log('validateLoginForm() returned false');
      return;
    }
  
    // Prepare the user data to send to the server
    const userDataLogin = {
      email: loginFormData.email,
      password: loginFormData.password,
    };
  
    // Send the user data to the server using Axios
    axios.post(API_BASE_URL + '/login', userDataLogin)
    .then((response) => {
      if (response.data && response.data.error) {
        // Handle an error response
        console.error('Error logging in user:', response.data.error);
        // Display an error message to the user (e.g., invalid login)
        setLoginFormData({
          email: '',
          password: '',
        });
        alert(response.data.error);
      } else {
        console.log("HERE IS THE RESPONSE:", response.data)
        // Handle a successful response
        if (response.data.user) {
          handleLoginData(response.data.user);
        }
      
        // Close the modal and reset the form data
        closeLoginModal();
        setLoginFormData({
          email: '',
          password: '',
        });
      } 
    })
    .catch((error) => {
      console.error('Axios request error:', error);
      // Handle other errors, e.g., network issues
      setFormErrors(prevErrors => ({ ...prevErrors, password: 'Username or password is incorrect' }));
    });
  
  };

  const handleFeedbackForm = () => {
    // Validate the form before proceeding
    if (!validateFeedbackForm()) {
      return;
    }
  
    // Prepare the user data to send to the server
    const feedbackFormDataToSubmit = {
      firstName: feedbackFormData.firstName,
      lastName: feedbackFormData.lastName,
      email: feedbackFormData.email,
      feedback: feedbackFormData.feedback,
    };
  
    // Send the user data to the server using Axios
    axios.post(API_BASE_URL+'/feedback', feedbackFormDataToSubmit)
      .then((response) => {
        if(response.data) {
          handleFeedback()
        }

        // Close the modal and reset the form data
        closeFeedbackModal();
        setFeedbackFormData(feedbackInitialState);
      })
      .catch((error) => {
        console.error('Error logging in user:', error.response.data);
        // Display an error message to the user (e.g., email already exists)
        setFeedbackFormData(feedbackInitialState);
        alert(error.response.data.error);
      });
  };

  const linkArray = [
    // {
    //     name: 'Sign Up',
    //     link: '/',
    //     style: 'normal-link'
    // },
    {
        name: 'Log In',
        link: '/about',
        style: 'normal-link'
    },
    {
        name: 'Settings',
        link: '/settings',
        style: 'normal-link'
    },
    {
        name: 'Tides',
        link: '/tides',
        style: 'normal-link'
    },
    {
        name: 'Fishing Reports',
        link: '/fishingReports',
        style: 'normal-link'
    },
    {
        name: 'Catch Log',
        link: '/catchLog',
        style: 'normal-link'
    },
    {
        name: 'New Features',
        link: '/services',
        style: 'normal-link'
    },
    {
        name: 'Feedback',
        link: '/feedback',
        style: 'normal-link'
    },
    {
      name: 'Log Out',
      link: '/logout',
      style: 'normal-link'
  },
    {
        name: 'About',
        link: '/about',
        style: 'small-link'
    },
    // {
    //     name: 'Contact Us',
    //     link: '/contact',
    //     style: 'small-link'
    // },
    // {
    //     name: 'FAQ',
    //     link: '/faq',
    //     style: 'small-link'
    // },
    {
        name: 'Terms of Service',
        link: '/terms',
        style: 'small-link'
    },
]

  return (
    <Menu isOpen={isMenuOpen} onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)} customCrossIcon={false}>
      <p className='written-logo'>Strait Weather</p>
        <div className='sidebar-menu-container'>
            <h1>Menu</h1>
            <div className='sidebar-menu-container'>
                {linkArray.map((link, index) => {
                    return (
                        <span key={index}
                          className={
                            isLoggedIn && link.name === 'Sign Up' || isLoggedIn && link.name === 'Log In' || !isLoggedIn && link.name === 'Settings' || !isLoggedIn && link.name === 'Log Out' || !isLoggedIn && link.name === 'Catch Log'
                            ? 'hidden' 
                            : `menu-item ${link.style}`
                            }
                        href={link.link}
                        onClick={() => {
                            if (link.name === 'Sign Up') {
                              openModal(setSignUpModalOpen);
                            } else if (link.name === 'Log In') {
                              openModal(setLoginModalOpen);
                            } else if (link.name === 'Settings') {
                              openModal(setSettingsModalOpen);
                            } else if (link.name === 'Log Out') {
                              handleLogout();
                            } else if (link.name === 'Feedback') {
                              openModal(setFeedbackModalOpen);
                            } else if (link.name === 'New Features') {
                              openModal(setNewFeaturesModalOpen);
                            } else if (link.name === 'About') {
                              openModal(setAboutUsModalOpen);
                            } else if (link.name === 'Terms of Service') {
                              openModal(setTermsOfServiceModalOpen);
                            } else if (link.name === 'Fishing Reports') {
                              openModal(setFishingReportsModalOpen);
                            } else if (link.name === 'Tides') {
                              openModal(setTideDataModalOpen);
                            } else if (link.name === 'Catch Log') {
                              openModal(setCatchLogModalOpen);
                            }
                        }}
                        >
                            {link.name}
                        </span>
                    )
                })}
            </div>
        </div>
        <Modal
          isOpen={signUpModalOpen}
          onRequestClose={closeSignUpModal}
          contentLabel='Sign Up Modal'
          className="modal-general sign-up-modal"
          overlayClassName="modal-overlay"
        >
        <div>
          <h2>Sign Up</h2>
          <form>
            <div className='form-field large-input'>
              <input
                name='firstName'
                placeholder='First Name'
                value={signUpFormData.firstName}
                onChange={handleInputChange}
              />
              {formErrors.firstName && (
                <div className='error-message'>{formErrors.firstName}</div>
              )}
            </div>
            <div className='form-field large-input'>
              <input
                name='lastName'
                placeholder='Last Name'
                value={signUpFormData.lastName}
                onChange={handleInputChange}
              />
              {formErrors.lastName && (
                <div className='error-message'>{formErrors.lastName}</div>
              )}
            </div>
            <div className='form-field large-input'>
              <input
                type='email'
                name='email'
                placeholder='Email'
                value={signUpFormData.email}
                onChange={handleInputChange}
              />
              {formErrors.email && (
                <div className='error-message'>{formErrors.email}</div>
              )}
            </div>
            <div className='form-field large-input'>
              <input
                  type='password'
                  name='password'
                  placeholder='Password'
                  value={signUpFormData.password}
                  onChange={handleInputChange}
                />
                {formErrors.password && (
                    <div className="error-message">{formErrors.password}</div>
                )}
                <input
                  type='password'
                  name='confirmPassword'
                  placeholder='Confirm Password'
                  value={signUpFormData.confirmPassword}
                  onChange={handleInputChange}
                />
                {formErrors.confirmPassword && (
                    <div className="error-message">{formErrors.confirmPassword}</div>
                )}
              </div>
            <div className='sign-up-btns'>
              <GreenButton btnText={'Create Account'} onClick={handleSignUp} />
              <SimpleCloseBtn text={'X'} onClick={closeSignUpModal} />
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={loginModalOpen}
        onRequestClose={closeLoginModal}
        contentLabel='Login Up Modal'
        className="modal-general sign-up-modal"
        overlayClassName="modal-overlay"
      >
      {forgotPassword ? 
        <div>
          <form>
            <div className='form-field large-input'>
            <h3>Forgot Your Password?</h3>
            <p>We'll email you a link to reset it.</p>
              <input
                type='email'
                name='email'
                placeholder='Email'
                value={loginFormData.email}
                onChange={handleLoginInputChange}
              />
              {formErrors.email && (
                <div className='error-message'>{formErrors.email}</div>
              )}
            </div>
            <GreenButton
              btnText={'Send Reset Link'}
              onClick={sendPasswordResetEmail}
            />
            <SimpleCloseBtn text={'X'} onClick={closeLoginModal} />
            </form>
          </div>
            :
        <div>
          <h2>Login</h2>
          <form>
            <div className='form-field large-input'>
              <input
                type='email'
                name='email'
                placeholder='Email'
                value={loginFormData.email}
                onChange={handleLoginInputChange}
              />
              {formErrors.email && (
                <div className='error-message'>{formErrors.email}</div>
              )}
            <input
                type='password'
                name='password'
                placeholder='Password'
                value={loginFormData.password}
                onChange={handleLoginInputChange}
              />
            </div>
              {formErrors.password && (
                  <div className="error-message">{formErrors.password}</div>
              )}
            <div className='sign-up-btns'>
              <GreenButton btnText={'Login'} onClick={handleLogin} />
              <p
                className='forgot-password-btn'
                onClick={handleForgotPassword}
              >Forgot Password</p>
              <SimpleCloseBtn text={'X'} onClick={closeLoginModal} />
            </div>
          </form>
        </div>
      }
      </Modal>
      <Modal
        isOpen={settingsModalOpen}
        className="modal-general settings-modal"
        overlayClassName="modal-overlay"
        onRequestClose={closeSettingsModal}
      >
      <div className='settings-container'>
        <span className='settings-close-btn' onClick={closeSettingsModal}>x</span>
        <h2>Settings</h2>
        <p className='settings-p'>Default settings for when you first login</p>
            <h3>Units</h3>
            <div>
                <h5>Wave Height</h5>
                <div className="radio-group">
                    <label>
                        Feet
                        <input type="radio" id="metric" name="wave_height" value="feet" checked={userSettingsObjectDB.wave_height === 'feet'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Meters
                        <input type="radio" id="imperial" name="wave_height" value="meters" checked={userSettingsObjectDB.wave_height === 'meters'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Temperature</h5>
                <div className="radio-group">
                    <label>
                        Fahrenheit
                        <input type="radio" id="metric" name="temperature" value="fahrenheit" checked={userSettingsObjectDB.temperature === 'fahrenheit'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Celsius
                        <input type="radio" id="imperial" name="temperature" value="celsius" checked={userSettingsObjectDB.temperature === 'celsius'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Wind Speed</h5>
                <div className='radio-group'>
                    <label>
                        Knots
                        <input type="radio" id="metric" name="wind_speed" value="knots" checked={userSettingsObjectDB.wind_speed === 'knots'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Kmh
                        <input type="radio" id="imperial" name="wind_speed" value="kmh" checked={userSettingsObjectDB.wind_speed === 'kmh'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Mph
                        <input type="radio" id="imperial" name="wind_speed" value="mph" checked={userSettingsObjectDB.wind_speed === 'mph'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
            </div>
            <h3>Layers</h3>
            <div>
                <h5>Wave Height</h5>
                <div className="radio-group">
                    <label>
                        On
                        <input type="radio" id="metric" name="wave_height_layer" value="on" checked={userSettingsObjectDB.wave_height_layer === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="wave_height_layer" value="off" checked={userSettingsObjectDB.wave_height_layer === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Fishing Areas</h5>
                <div className="radio-group">
                    <label>
                        On
                        <input type="radio" id="metric" name="fishing_area" value="on" checked={userSettingsObjectDB.fishing_area === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="fishing_area" value="off" checked={userSettingsObjectDB.fishing_area === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Webcams</h5>
                <div className="radio-group">
                    <label>
                        On
                        <input type="radio" id="metric" name="webcam" value="on" checked={userSettingsObjectDB.webcam === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="webcam" value="off" checked={userSettingsObjectDB.webcam === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Wave Buoy</h5>
                <div className="radio-group">
                    <label>
                        On
                        <input type="radio" id="metric" name="wave_buoy" value="on" checked={userSettingsObjectDB.wave_buoy === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="wave_buoy" value="off" checked={userSettingsObjectDB.wave_buoy === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Wind Station</h5>
                <div className="radio-group">
                    <label>
                        On
                        <input type="radio" id="metric" name="wind_station" value="on" checked={userSettingsObjectDB.wind_station === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="wind_station" value="off" checked={userSettingsObjectDB.wind_station === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>RCA</h5>
                <div className='radio-group'>
                    <label>
                        On
                        <input type="radio" id="metric" name="rca" value="on" checked={userSettingsObjectDB.rca === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="rca" value="off" checked={userSettingsObjectDB.rca === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>
                <h5>Sponge Reef</h5>
                <div className='radio-group'>
                    <label>
                        On
                        <input type="radio" id="metric" name="sponge_reef" value="on" checked={userSettingsObjectDB.sponge_reef === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="sponge_reef" value="off" checked={userSettingsObjectDB.sponge_reef === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>                
                <h5>Fishing Hotspots</h5>
                <div className='radio-group'>
                    <label>
                        On
                        <input type="radio" id="metric" name="fishing_hotspots" value="on" checked={userSettingsObjectDB.fishing_hotspots === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="fishing_hotspots" value="off" checked={userSettingsObjectDB.fishing_hotspots === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>                
                <h5>Catch Log</h5>
                <div className='radio-group'>
                    <label>
                        On
                        <input type="radio" id="metric" name="catch_log" value="on" checked={userSettingsObjectDB.catch_log === 'on'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                    <label>
                        Off
                        <input type="radio" id="imperial" name="catch_log" value="off" checked={userSettingsObjectDB.catch_log === 'off'} onChange={handleSettingsRadioBtnChange} />
                    </label>
                </div>                
            </div>
        </div>
        {/* <SimpleCloseBtn onClick={closeSettingsModal} /> */}
        </Modal>
        <Modal 
          isOpen={feedbackModalOpen}
          className="modal-general"
          overlayClassName="modal-overlay"
          onRequestClose={closeFeedbackModal}
        >
          <div className='feedback-container large-input'>
            <h2>Send Us Your Feedback!</h2>
            <form>
              <div className='form-field'>
                <input
                  name='firstName'
                  placeholder='First Name'
                  value={userInfo.firstName ? userInfo.firstName : feedbackFormData.firstName}
                  onChange={handleFeedbackChange}
                />
                {formErrors.firstName && (
                  <div className='error-message'>{formErrors.firstName}</div>
                )}
              </div>
              <div className='form-field'>
                <input
                  name='lastName'
                  placeholder='Last Name'
                  value={userInfo.lastName ? userInfo.lastName : feedbackFormData.lastName}
                  onChange={handleFeedbackChange}
                />
                {formErrors.lastName && (
                  <div className='error-message'>{formErrors.lastName}</div>
                )}
              </div>
              <div className='form-field'>
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={userInfo.email ? userInfo.email : feedbackFormData.email}
                  onChange={handleFeedbackChange}
                />
                {formErrors.email && (
                  <div className='error-message'>{formErrors.email}</div>
                )}
              </div>
              <div className='form-field' >
                <textarea
                  maxlength="250"
                  name='feedback'
                  placeholder='Feedback'
                  value={feedbackFormData.feedback}
                  onChange={handleFeedbackChange}
                />
              </div>
              <div className=''>
                <GreenButton btnText={'Send Feedback'} onClick={handleFeedbackForm} />
                <SimpleCloseBtn text={'X'} onClick={closeFeedbackModal} />
              </div>
            </form>
          </div>
        </Modal>
        <Modal 
          isOpen={newFeaturesModalOpen}
          className="modal-general"
          overlayClassName="modal-overlay"
          onRequestClose={closeNewFeaturesModal}
        >
          <div className='new-features-container'>
            <h2>New Features</h2>   
            <div>
            <span>
              <h3>New Layer Selectors</h3>
              <p>Checkboxes are so old school! Try out the new buttons found on the right side of your screen which allow you to toggle specific layers on and off. These settings are only "tempoarary" and will reset to your user settings upon page refresh. If you are viewing as a guest your settings will reset to the default settings on page refresh.</p>
            </span>  
            <span>
              <h3>Feedback Form</h3>
              <p>Have a suggestion for a new feature? Want to report a bug? Click on the feedback button in the menu to send us your feedback!</p>
            </span>
            <span>
              <h3>Custom User Settings</h3>
              <p>Click on the settings button in the menu to change your default settings. This will allow you to set which layers you want to see upon page load, which units you prefer and much more! These settings will be applied to the map immediately with <b>no</b> need to click save! User settings are only available with an account, so make sure to sign up!</p>
            </span>
            <span>
              <h3>Webcams</h3>
              <p>Click on the webcam button in the menu to view webcams from around the Strait of Georgia. If you know of one which you'd like to see on here make sure to leave us feedback!</p>
            </span>
            <span>
              <h3>Juan de Fuca Weather</h3>
              <p>New landstations and weather buoys are being added to the map offering better predictions for the JDF.</p>
            </span>
            </div>  
          </div>   
          <SimpleCloseBtn text={'X'} onClick={closeNewFeaturesModal} />
        </Modal>
        <Modal 
          isOpen={aboutUsModalOpen}
          className="modal-general"
          overlayClassName="modal-overlay"
          onRequestClose={closeAboutUsModal}
        >
          <div className='about-us-container'>
            <h2>About Us</h2>
            <div>
              <p>Hello, I'm Jeff, a proud Vancouver resident with a passion for the great outdoors. I spend my free time fishing in the stunning waters of Vancouver, exploring the city, and embracing the beautiful natural landscapes surrounding us. As a dedicated boater and fisherman, I've always had an innate connection to the sea.</p>
              
              <h4>A Love for Technology and the Outdoors</h4>
              <p>My enthusiasm for technology led me to the world of programming. I'm not only passionate about writing code but also about utilizing it to make data more accessible and meaningful for users.</p>
              
              <h4>The Project</h4>
              <p>I've developed a web application tailored to fellow fishing and boating enthusiasts. This app is designed to help users navigate local fishing areas, providing essential information at their fingertips. With real-time weather updates, wind data, and predictive wave height information, you can plan your fishing trips with confidence.</p>
              
              <p>I believe that technology should enhance our outdoor experiences, not detract from them. My mission is to bridge the gap between the digital and natural worlds, empowering you to make the most of your time on the water.</p>

              <p>Thank you for joining me on this exciting journey. Let's make every fishing and boating adventure memorable!</p>
            </div>
          </div>
          <SimpleCloseBtn text={'X'} onClick={closeAboutUsModal} />
        </Modal>
        <Modal
          isOpen={termsOfServiceModalOpen}
          className="modal-general"
          overlayClassName="modal-overlay"
          onRequestClose={closeTermsOfServiceModal}
        >
          <div className='terms-of-service-container'>
            <h2>Terms of Service</h2>
            <div>
              <p>
                <strong>1. Acceptance of Terms</strong>
              </p>

              <p>
                By accessing or using our web application (the "Service"), you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use the Service.
              </p>

              <p>
                <strong>2. Use of the Service</strong>
              </p>

              <p>
                Our Service provides information related to weather, wind, and wave predictions, as well as other data intended to assist users with their outdoor activities, such as fishing and boating. However, please note the following:
              </p>

              <ul>
                <li>The information provided by the Service is for reference purposes only. It may not always be accurate or up-to-date, and it should not be the sole basis for making critical decisions.</li>

                <li>We are not responsible for the accuracy, reliability, or completeness of the information presented through the Service. Users should verify any critical information from authoritative sources before relying on it.</li>

                <li>Engaging in outdoor activities such as fishing, boating, and others can be inherently risky. Accidents can occur due to various factors, including weather conditions. Users should exercise caution and follow safety guidelines at all times.</li>

                <li>Users are solely responsible for their safety during outdoor activities. We are not liable for any accidents, injuries, damages, or losses that occur as a result of using the information provided by the Service.</li>
              </ul>

              <p>
                <strong>3. Changes to the Service</strong>
              </p>

              <p>
                We reserve the right to modify or discontinue the Service, temporarily or permanently, with or without notice. Users agree that we shall not be liable to them or any third party for any modification, suspension, or discontinuance of the Service.
              </p>

              <p>
                <strong>4. Privacy Policy</strong>
              </p>

              <p>
                Your use of the Service is also governed by our Privacy Policy, which you can review separately.
              </p>

              <p>
                <strong>5. Governing Law</strong>
              </p>

              <p>
                These Terms of Service are governed by the laws of British Columbia, Canada, without regard to its conflict of law principles.
              </p>

              <p>
                Please read and understand these Terms of Service carefully before using our Service. Your continued use of the Service indicates your acceptance of these terms. These Terms of Service were last updated on Oct 13, 2023.
              </p>
            </div>
          </div>
          <SimpleCloseBtn text={'X'} onClick={closeTermsOfServiceModal} />
        </Modal>   
        <Modal
          isOpen={fishingReportsModalOpen}
          className="modal-general"
          overlayClassName="modal-overlay"
          onRequestClose={closeFishingReportsModal}
        >
          <div className='fishing-reports-container'>
            <h2>Fishing Reports</h2>
            <div>
              <span>
                <h4>November</h4>
                <p><a a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-november-10-2023/' target='_blank'>Pacific Angler - Friday Nov 10th</a></p>
                <p><a a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-november-03-2023/' target='_blank'>Pacific Angler - Friday Nov 3rd</a></p>

              </span>
              <span>
                <h4>October</h4>
                <p><a a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-october-27-2023/' target='_blank'>Pacific Angler - Friday Oct 27th</a></p>
                <p><a a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-october-20-2023/' target='_blank'>Pacific Angler - Friday Oct 20th</a></p>
                <p><a a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-october-13-2023/' target='_blank'>Pacific Angler - Friday Oct 13th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-october-6-2023' target="_blank">Pacific Angler - Friday Oct 6th</a></p>
              </span>
              <span>
                <h4>September</h4>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-september-29-2023' target="_blank">Pacific Angler - Friday Sept 29th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-friday-september-22-2023' target="_blank">Pacific Angler - Friday Sept 22nd</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-september-15-2023' target="_blank">Pacific Angler - Friday Sept 15th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-september-8-2023' target="_blank">Pacific Angler - Friday Sept 8th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-september-1-2023' target="_blank">Pacific Angler - Friday Sept 1st</a></p>
              </span>
              <span>
                <h4>August</h4>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-august-25-2023' target="_blank">Pacific Angler - Friday Aug 25th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-august-18-2023' target="_blank">Pacific Angler - Friday Aug 18th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-august-11-2023' target="_blank">Pacific Angler - Friday Aug 11th</a></p>
                <p><a href='https://www.pacificangler.ca/pacific-angler-friday-fishing-report-august-4-2023' target="_blank">Pacific Angler - Friday Aug 4th</a></p>
              </span>
            </div>
          </div>
          <SimpleCloseBtn text={'X'} onClick={closeFishingReportsModal} />
        </Modal>
        <Modal 
          isOpen={tideDataModalOpen}
          className="modal-general"
          overlayClassName="modal-overlay"
          onRequestClose={closeTideDataModal}
        >
          <div className='tide-data-container'>
            <h2>Tide Data</h2>
            <div>
              <div className='tide-location-selection'>
                {/* <label>Location: </label><input placeholder='Search for location' /> */}
                <Autocomplete onLoad={onLoad}>
                  <input
                    type="text"
                    placeholder="Enter a location"
                    className="location-input"
                    value={tideLocation}
                    onChange={(e) => setTideLocation(e.target.value)}
                  />
                </Autocomplete>
              </div>
              <div className='tide-date-selection'>
                <label>Start Date:</label>
                <input
                  type="date"
                  value={today}
                  onChange={handleStartDateChange}
                />
                <label>End Date:</label>
                <input
                  type="date"
                  value={tomorrow}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>
          <div>
            <TideTables />
          </div>
          <GreenButton btnText={'Get Tide Data'} onClick={getTideData} />
          <SimpleCloseBtn text={'X'} onClick={closeTideDataModal} />
        </Modal>
        <Modal 
          isOpen={catchLogModalOpen}
          className="modal-catch-log"
          overlayClassName="modal-overlay"
          onRequestClose={closeCatchLogModal}
        >
          <div className='tide-data-container'>
            <div>
              <CatchLogTable closeCatchLogModal={closeCatchLogModal} userInfo={userInfo}/>
            </div>
          </div>
        </Modal>
    </Menu>
  );
};

export default Sidebar;