import React, { useState } from 'react';
import { GiBigWave, GiBoatFishing, GiBuoy, GiCoral, GiFishingHook } from "react-icons/gi";
import { LuWebcam, LuFishOff } from "react-icons/lu";
import { BsWind } from "react-icons/bs";
import { IoFishOutline } from "react-icons/io5";

const CheckboxMenu = ({
  userSettingsObject,
  handleSettingsCheckBoxChange,
  isLoggedInCheck
}) => {

  const [hoveredCheckbox, setHoveredCheckbox] = useState(null);

  const handleHoverOver = (name) => {
    setHoveredCheckbox(name);
  };

  const handleHoverOut = () => {
    setHoveredCheckbox(null);
  };

  const iconSize = 22;

  return (
    <div className="checkbox-menu">
      {/* Wave Layer */}
      <label
        onMouseOver={() => handleHoverOver('wave')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.wave_height_layer === 'on' ? 'selected' : ''}`}
      >
        <GiBigWave style={{ fontSize: iconSize }} />
        <input
          name="wave_height_layer"
          type="checkbox"
          className="toggle-checkbox wave-toggle"
          checked={userSettingsObject.wave_height_layer === 'on'}
          onChange={handleSettingsCheckBoxChange}
          // defaultChecked={parsedUserSettingsData.waveHeightLayer === 'on' ? true : false}
        />
        {hoveredCheckbox === 'wave' && <span className='icon-description'>Wave Layer</span>}
      </label>
      {/* Fishing Area */}
      <label
        onMouseOver={() => handleHoverOver('fishingArea')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.fishing_area === 'on' ? 'selected' : ''}`}
      >
        <GiBoatFishing style={{ fontSize: iconSize }} />
        <input
          name="fishing_area"
          type="checkbox"
          className="toggle-checkbox fishing-area-toggle"
          checked={userSettingsObject.fishing_area === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'fishingArea' && <span className='icon-description'>Fishing Areas</span>}
      </label>

      {/* Webcams */}
      <label
        onMouseOver={() => handleHoverOver('webcams')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.webcam === 'on' ? 'selected' : ''}`}
      >
        <LuWebcam style={{ fontSize: iconSize }} />
        <input
          name="webcam"
          type="checkbox"
          className="toggle-checkbox webcam-toggle"
          checked={userSettingsObject.webcam === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'webcams' && <span className='icon-description'>Webcams</span>}
      </label>

      {/* Wave Buoy */}
      <label
        onMouseOver={() => handleHoverOver('buoys')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.wave_buoy === 'on' ? 'selected' : ''}`}
      >
        <GiBuoy style={{ fontSize: iconSize }} />
        <input
          name="wave_buoy"
          type="checkbox"
          className="toggle-checkbox buoy-toggle"
          checked={userSettingsObject.wave_buoy === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'buoys' && <span className='icon-description'>Wave Buoy</span>}
      </label>

      {/* Wind Station */}
      <label
        onMouseOver={() => handleHoverOver('weatherStations')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.wind_station === 'on' ? 'selected' : ''}`}
      >
        <BsWind style={{ fontSize: iconSize }} />
        <input
          name="wind_station"
          type="checkbox"
          className="toggle-checkbox weather-station-toggle"
          checked={userSettingsObject.wind_station === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'weatherStations' && <span className='icon-description'>Wind Station</span>}
      </label>

      {/* RCA */}
      <label
        onMouseOver={() => handleHoverOver('rca')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.rca === 'on' ? 'selected' : ''}`}
      >
        <LuFishOff style={{ fontSize: iconSize }} />
        <input
          name="rca"
          type="checkbox"
          className="toggle-checkbox weather-station-toggle"
          checked={userSettingsObject.rca === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'rca' && <span className='icon-description'>RCA</span>}
      </label>

      {/* Sponge Reef */}
      <label
        onMouseOver={() => handleHoverOver('spongeReef')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.sponge_reef === 'on' ? 'selected' : ''}`}
      >
        <GiCoral style={{ fontSize: iconSize }} />
        <input
          name="sponge_reef"
          type="checkbox"
          className="toggle-checkbox weather-station-toggle"
          checked={userSettingsObject.sponge_reef === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'spongeReef' && <span className='icon-description'>Sponge Reef</span>}
      </label>
      <label
        onMouseOver={() => handleHoverOver('fishingHotspots')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.fishing_hotspots === 'on' ? 'selected' : ''}`}
      >
      <IoFishOutline style={{ fontSize: iconSize }} />
        <input
          name="fishing_hotspots"
          type="checkbox"
          className="toggle-checkbox weather-station-toggle"
          checked={userSettingsObject.fishing_hotspots === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'fishingHotspots' && <span className='icon-description'>Fishing Hotspots</span>}
      </label>
      {isLoggedInCheck &&
      <label
        onMouseOver={() => handleHoverOver('catchLog')}
        onMouseOut={handleHoverOut}
        className={`checkbox-label ${userSettingsObject.catch_log === 'on' ? 'selected' : ''}`}
      >
      <GiFishingHook style={{ fontSize: iconSize }} />
        <input
          name="catch_log"
          type="checkbox"
          className="toggle-checkbox weather-station-toggle"
          checked={userSettingsObject.catch_log === 'on'}
          onChange={handleSettingsCheckBoxChange}
        />
        {hoveredCheckbox === 'catchLog' && <span className='icon-description'>Catch Log</span>}
      </label>
      }
    </div>
  );
};

export default CheckboxMenu;
