export const straitOfGeorgiaCoords = [
    { lat: 49.324647, lng: -123.4151916 },
    { lat: 49.2063556, lng: -123.7365417 },
    { lat: 49.181227, lng: -123.7392882 },
    { lat: 49.1650661, lng: -123.6843566 },
    { lat: 49.1399166, lng: -123.6651305 },
    { lat: 49.1219448, lng: -123.6568908 },
    { lat: 49.0967734, lng: -123.629425 },
    { lat: 49.0715892, lng: -123.6019591 },
    { lat: 49.0445919, lng: -123.5827331 },
    { lat: 49.0130766, lng: -123.5525207 },
    { lat: 48.9887511, lng: -123.5236816 },
    { lat: 48.9689215, lng: -123.4934692 },
    { lat: 48.9508878, lng: -123.4508971 },
    { lat: 48.9346519, lng: -123.4096984 },
    { lat: 48.9193132, lng: -123.3561401 },
    { lat: 48.9111907, lng: -123.3314208 },
    { lat: 48.903067, lng: -123.303955 },
    { lat: 48.8859125, lng: -123.2819823 },
    { lat: 48.8723654, lng: -123.2627563 },
    { lat: 48.8570075, lng: -123.2297973 },
    { lat: 48.8416449, lng: -123.2119445 },
    { lat: 48.822661, lng: -123.1679992 },
    { lat: 48.8063834, lng: -123.1075744 },
    { lat: 48.9635121, lng: -123.0842284 },
    { lat: 48.9932567, lng: -123.1254272 },
    { lat: 49.014878, lng: -123.1803588 },
    { lat: 49.0544926, lng: -123.2023315 },
    { lat: 49.1102597, lng: -123.2146911 },
    { lat: 49.1937929, lng: -123.2339172 },
    { lat: 49.2332649, lng: -123.2517699 },
    { lat: 49.2780812, lng: -123.3025817 },
    { lat: 49.324647, lng: -123.4151916 },
  ];

  export const vancouverHarbourCoords = [
    { lat: 49.324647, lng: -123.4151916 },
    { lat: 49.2780812, lng: -123.3025817 },
    { lat: 49.2657902, lng: -123.2877689 },
    { lat: 49.2669103, lng: -123.2678562 },
    { lat: 49.2754228, lng: -123.2644229 },
    { lat: 49.2837098, lng: -123.2524066 },
    { lat: 49.2830379, lng: -123.2290607 },
    { lat: 49.2774386, lng: -123.1916385 },
    { lat: 49.2758707, lng: -123.1796222 },
    { lat: 49.2769907, lng: -123.1669193 },
    { lat: 49.2796784, lng: -123.1545597 },
    { lat: 49.2877408, lng: -123.1497531 },
    { lat: 49.2949062, lng: -123.1531864 },
    { lat: 49.297593, lng: -123.1624561 },
    { lat: 49.3022945, lng: -123.1634861 },
    { lat: 49.3054285, lng: -123.1603962 },
    { lat: 49.3099054, lng: -123.1590229 },
    { lat: 49.3148296, lng: -123.1535297 }, 
    { lat: 49.3161724, lng: -123.1456333 },
    { lat: 49.3202008, lng: -123.1480365 },
    { lat: 49.3251239, lng: -123.1545597 },
    { lat: 49.3255714, lng: -123.1614261 },
    { lat: 49.3255714, lng: -123.1705242 },
    { lat: 49.3298227, lng: -123.1770473 },
    { lat: 49.3316127, lng: -123.1856304 },
    { lat: 49.3335144, lng: -123.1943851 },
    { lat: 49.3364228, lng: -123.1998783 },
    { lat: 49.3365346, lng: -123.2060581 },
    { lat: 49.3382125, lng: -123.2105213 },
    { lat: 49.3359753, lng: -123.2194477 },
    { lat: 49.3379887, lng: -123.228889 },
    { lat: 49.3361991, lng: -123.2378154 },
    { lat: 49.3368702, lng: -123.2448535 },
    { lat: 49.3355279, lng: -123.2498317 },
    { lat: 49.3319483, lng: -123.2530933 },
    { lat: 49.3276971, lng: -123.2632213 },
    { lat: 49.3294871, lng: -123.2707744 },
    { lat: 49.3400021, lng: -123.272491 },
    { lat: 49.3442482, lng: -123.2706785 },
    { lat: 49.3498398, lng: -123.2768583 },
    { lat: 49.3549836, lng: -123.2802915 },
    { lat: 49.3643752, lng: -123.3290434 },
    { lat: 49.359456, lng: -123.33179 },
    { lat: 49.35476, lng: -123.3338499 },
    { lat: 49.3507344, lng: -123.3379698 },
    { lat: 49.3473796, lng: -123.3396864 },
    { lat: 49.3449192, lng: -123.3441496 },
    { lat: 49.3446955, lng: -123.3479261 },
    { lat: 49.3417876, lng: -123.3523893 },
    { lat: 49.3368662, lng: -123.3544493 },
    { lat: 49.3341816, lng: -123.3595991 },
    { lat: 49.332168, lng: -123.3712721 },
    { lat: 49.3319443, lng: -123.3925581 },
    { lat: 49.3308256, lng: -123.4059477 },
    { lat: 49.324647, lng: -123.4151916 },
  ];

  export const sunshineCoastCoords = [
    { lat: 49.530184, lng: -123.9798621 },
    { lat: 49.4940717, lng: -124.115818 },
    { lat: 49.4855971, lng: -124.1343574 },
    { lat: 49.4748902, lng: -124.1590766 },
    { lat: 49.4637347, lng: -124.1631965 },
    { lat: 49.446327, lng: -124.1549567 },
    { lat: 49.4356116, lng: -124.1604499 },
    { lat: 49.3359349, lng: -124.2696265 },
    { lat: 49.3189295, lng: -124.2545203 },
    { lat: 49.3207198, lng: -124.2366676 },
    { lat: 49.3225101, lng: -124.2037086 },
    { lat: 49.3135581, lng: -124.1721229 },
    { lat: 49.3019182, lng: -124.15839 },
    { lat: 49.2947538, lng: -124.1199378 },
    { lat: 49.2723583, lng: -124.0952186 },
    { lat: 49.2633973, lng: -124.0773658 },
    { lat: 49.2625011, lng: -124.0402869 },
    { lat: 49.2481597, lng: -124.0114478 },
    { lat: 49.2472633, lng: -123.9716224 },
    { lat: 49.2329175, lng: -123.9345435 },
    { lat: 49.2131853, lng: -123.9043311 },
    { lat: 49.199727, lng: -123.8960914 },
    { lat: 49.210494, lng: -123.8205604 },
    { lat: 49.2006244, lng: -123.8027076 },
    { lat: 49.1853675, lng: -123.7889747 },
    { lat: 49.181227, lng: -123.7392882 },
    { lat: 49.2063556, lng: -123.7365417 },
    { lat: 49.324647, lng: -123.4151916 },
    { lat: 49.3359349, lng: -123.4367255 },
    { lat: 49.3430933, lng: -123.464878 },
    { lat: 49.3641152, lng: -123.5019569 },
    { lat: 49.381105, lng: -123.5253028 },
    { lat: 49.3837871, lng: -123.5527686 },
    { lat: 49.3931731, lng: -123.5754279 },
    { lat: 49.4034511, lng: -123.606327 },
    { lat: 49.4114932, lng: -123.658512 },
    { lat: 49.4240005, lng: -123.6900977 },
    { lat: 49.4293598, lng: -123.7271766 },
    { lat: 49.4615033, lng: -123.7518958 },
    { lat: 49.4632884, lng: -123.8013343 },
    { lat: 49.4632884, lng: -123.8452796 },
    { lat: 49.4686434, lng: -123.8823585 },
    { lat: 49.4788757, lng: -123.9106928 },
    { lat: 49.4985017, lng: -123.926829 },
    { lat: 49.5014003, lng: -123.9560114 },
    { lat: 49.5089805, lng: -123.9714609 },
    { lat: 49.5170053, lng: -123.9748942 },
    { lat: 49.5219087, lng: -123.9711176 },
    { lat: 49.530184, lng: -123.9798621 },
  ];

  export const texadaIslandCoords = [
    { lat: 49.7458997, lng: -124.900666 },
    { lat: 49.7064578, lng: -124.8424219 },
    { lat: 49.6789185, lng: -124.8589014 },
    { lat: 49.6540309, lng: -124.8918604 },
    { lat: 49.6389143, lng: -124.90422 },
    { lat: 49.5961916, lng: -124.8735955 },
    { lat: 49.5530013, lng: -124.8578026 },
    { lat: 49.5280493, lng: -124.822097 },
    { lat: 49.5044223, lng: -124.7857048 },
    { lat: 49.4803378, lng: -124.7788384 },
    { lat: 49.4736455, lng: -124.7472527 },
    { lat: 49.4758764, lng: -124.7245934 },
    { lat: 49.4620434, lng: -124.6813347 },
    { lat: 49.4392776, lng: -124.6435692 },
    { lat: 49.4138208, lng: -124.6222832 },
    { lat: 49.4102469, lng: -124.6037438 },
    { lat: 49.3900943, lng: -124.5697165 },
    { lat: 49.3883065, lng: -124.5395041 },
    { lat: 49.3775783, lng: -124.5099784 },
    { lat: 49.375343, lng: -124.479766 },
    { lat: 49.3623761, lng: -124.45642 },
    { lat: 49.367295, lng: -124.4179679 },
    { lat: 49.3637177, lng: -124.3850089 },
    { lat: 49.3394911, lng: -124.3296408 },
    { lat: 49.3267385, lng: -124.321401 },
    { lat: 49.3269623, lng: -124.3172812 },
    { lat: 49.330766, lng: -124.3100714 },
    { lat: 49.3359349, lng: -124.2696265 },
    { lat: 49.4356116, lng: -124.1604499 },
    { lat: 49.446327, lng: -124.1549567 },
    { lat: 49.4637347, lng: -124.1631965 },
    { lat: 49.4748902, lng: -124.1590766 },
    { lat: 49.4940717, lng: -124.115818 },
    { lat: 49.530184, lng: -123.9798621 },
    { lat: 49.5355662, lng: -123.9942292 },
    { lat: 49.5516057, lng: -124.0162019 },
    { lat: 49.5907914, lng: -124.0299348 },
    { lat: 49.5952423, lng: -124.0587739 },
    { lat: 49.6174908, lng: -124.0862397 },
    { lat: 49.6361718, lng: -124.1027192 },
    { lat: 49.6450649, lng: -124.1411713 },
    { lat: 49.6619942, lng: -124.1949521 },
    { lat: 49.6728823, lng: -124.2145215 },
    { lat: 49.6975382, lng: -124.2172681 },
    { lat: 49.732587, lng: -124.273072 },
    { lat: 49.7458997, lng: -124.3115241 },
    { lat: 49.7529983, lng: -124.3650825 },
    { lat: 49.7565472, lng: -124.4090278 },
    { lat: 49.7716272, lng: -124.4502265 },
    { lat: 49.7858159, lng: -124.4790656 },
    { lat: 49.7849292, lng: -124.5188911 },
    { lat: 49.8026595, lng: -124.5408637 },
    { lat: 49.8336719, lng: -124.5463569 },
    { lat: 49.7458997, lng: -124.900666 },
  ];

  export const campbellRiverCoords = [
    { lat: 49.9856519, lng: -125.2145034 },
    { lat: 49.9644552, lng: -125.193904 },
    { lat: 49.9512026, lng: -125.1719314 },
    { lat: 49.9238023, lng: -125.1691848 },
    { lat: 49.9016939, lng: -125.1307326 },
    { lat: 49.8946171, lng: -125.1005202 },
    { lat: 49.8654142, lng: -125.0895339 },
    { lat: 49.8477069, lng: -125.0579482 },
    { lat: 49.8308789, lng: -125.0263625 },
    { lat: 49.8069555, lng: -125.00027 },
    { lat: 49.7856802, lng: -124.9686843 },
    { lat: 49.7714915, lng: -124.9494582 },
    { lat: 49.7555243, lng: -124.9206191 },
    { lat: 49.7458997, lng: -124.900666 },
    { lat: 49.8336719, lng: -124.5463569 },
    { lat: 49.8512489, lng: -124.5484572 },
    { lat: 49.86984, lng: -124.5786696 },
    { lat: 49.891963, lng: -124.6198684 },
    { lat: 49.9140758, lng: -124.6638137 },
    { lat: 49.9326428, lng: -124.7091323 },
    { lat: 49.9529698, lng: -124.7434646 },
    { lat: 49.9626884, lng: -124.7709304 },
    { lat: 49.9838858, lng: -124.7819167 },
    { lat: 49.9953639, lng: -124.8135024 },
    { lat: 50.0183117, lng: -124.8313552 },
    { lat: 50.0535946, lng: -124.8464614 },
    { lat: 50.0518311, lng: -124.8849135 },
    { lat: 50.0509493, lng: -124.9206191 },
    { lat: 50.0438944, lng: -124.9480849 },
    { lat: 50.032428, lng: -124.9659377 },
    { lat: 50.011252, lng: -124.9755507 },
    { lat: 50.0059565, lng: -124.9988967 },
    { lat: 49.9856519, lng: -125.2145034 },
  ];
  
  export const straitOfJDFCoords = 
  [
    {
        "lat": 48.3984564,
        "lng": -124.745813
    },
    {
        "lat": 48.3944251,
        "lng": -124.7138844
    },
    {
        "lat": 48.395337,
        "lng": -124.7022115
    },
    {
        "lat": 48.3976166,
        "lng": -124.6630727
    },
    {
        "lat": 48.3971607,
        "lng": -124.6335469
    },
    {
        "lat": 48.3729918,
        "lng": -124.6129475
    },
    {
        "lat": 48.3770968,
        "lng": -124.5992146
    },
    {
        "lat": 48.3757285,
        "lng": -124.5731221
    },
    {
        "lat": 48.3707112,
        "lng": -124.5566426
    },
    {
        "lat": 48.3561124,
        "lng": -124.53261
    },
    {
        "lat": 48.3533747,
        "lng": -124.5154439
    },
    {
        "lat": 48.3437915,
        "lng": -124.4962178
    },
    {
        "lat": 48.3360324,
        "lng": -124.4763051
    },
    {
        "lat": 48.3255328,
        "lng": -124.4460927
    },
    {
        "lat": 48.3132045,
        "lng": -124.4309865
    },
    {
        "lat": 48.3104645,
        "lng": -124.4172536
    },
    {
        "lat": 48.3004165,
        "lng": -124.4000874
    },
    {
        "lat": 48.2908233,
        "lng": -124.3794881
    },
    {
        "lat": 48.2944781,
        "lng": -124.3575154
    },
    {
        "lat": 48.2812284,
        "lng": -124.3362294
    },
    {
        "lat": 48.2748307,
        "lng": -124.2970906
    },
    {
        "lat": 48.2656899,
        "lng": -124.2819844
    },
    {
        "lat": 48.2615759,
        "lng": -124.2627583
    },
    {
        "lat": 48.2716316,
        "lng": -124.2531453
    },
    {
        "lat": 48.2702605,
        "lng": -124.2435323
    },
    {
        "lat": 48.225908,
        "lng": -124.1254292
    },
    {
        "lat": 48.2254506,
        "lng": -124.1096364
    },
    {
        "lat": 48.220876,
        "lng": -124.0931569
    },
    {
        "lat": 48.2039462,
        "lng": -124.0952168
    },
    {
        "lat": 48.1870109,
        "lng": -124.0526448
    },
    {
        "lat": 48.1792279,
        "lng": -124.025179
    },
    {
        "lat": 48.1760228,
        "lng": -124.001833
    },
    {
        "lat": 48.169612,
        "lng": -123.9778004
    },
    {
        "lat": 48.1732754,
        "lng": -123.9489613
    },
    {
        "lat": 48.1654903,
        "lng": -123.9345418
    },
    {
        "lat": 48.1664063,
        "lng": -123.8775502
    },
    {
        "lat": 48.1590782,
        "lng": -123.8617574
    },
    {
        "lat": 48.1641164,
        "lng": -123.8246785
    },
    {
        "lat": 48.1595363,
        "lng": -123.8109456
    },
    {
        "lat": 48.1599943,
        "lng": -123.7814198
    },
    {
        "lat": 48.1664063,
        "lng": -123.7560139
    },
    {
        "lat": 48.1686961,
        "lng": -123.7319813
    },
    {
        "lat": 48.1709858,
        "lng": -123.7024556
    },
    {
        "lat": 48.1686961,
        "lng": -123.6701833
    },
    {
        "lat": 48.1558719,
        "lng": -123.6365376
    },
    {
        "lat": 48.1448771,
        "lng": -123.624178
    },
    {
        "lat": 48.1425862,
        "lng": -123.6008321
    },
    {
        "lat": 48.1412117,
        "lng": -123.5822926
    },
    {
        "lat": 48.1502542,
        "lng": -123.5687255
    },
    {
        "lat": 48.1543704,
        "lng": -123.556133
    },
    {
        "lat": 48.3057706,
        "lng": -123.5575063
    },
    {
        "lat": 48.308053,
        "lng": -123.6141164
    },
    {
        "lat": 48.3066829,
        "lng": -123.6429555
    },
    {
        "lat": 48.3153598,
        "lng": -123.6717946
    },
    {
        "lat": 48.326318,
        "lng": -123.6910207
    },
    {
        "lat": 48.3349915,
        "lng": -123.7184865
    },
    {
        "lat": 48.3473146,
        "lng": -123.7267262
    },
    {
        "lat": 48.3509653,
        "lng": -123.7486989
    },
    {
        "lat": 48.3641969,
        "lng": -123.7672383
    },
    {
        "lat": 48.3516376,
        "lng": -123.825578
    },
    {
        "lat": 48.3666938,
        "lng": -123.8441174
    },
    {
        "lat": 48.3730799,
        "lng": -123.8619702
    },
    {
        "lat": 48.3762727,
        "lng": -123.889436
    },
    {
        "lat": 48.3717115,
        "lng": -123.9230816
    },
    {
        "lat": 48.3894977,
        "lng": -123.9560406
    },
    {
        "lat": 48.3885857,
        "lng": -123.9835064
    },
    {
        "lat": 48.4004399,
        "lng": -123.9965527
    },
    {
        "lat": 48.4045427,
        "lng": -124.0109722
    },
    {
        "lat": 48.4136587,
        "lng": -124.0336315
    },
    {
        "lat": 48.4177604,
        "lng": -124.0693371
    },
    {
        "lat": 48.4282409,
        "lng": -124.0961163
    },
    {
        "lat": 48.4282409,
        "lng": -124.1098492
    },
    {
        "lat": 48.4350749,
        "lng": -124.1222088
    },
    {
        "lat": 48.4423634,
        "lng": -124.1565411
    },
    {
        "lat": 48.4523835,
        "lng": -124.201173
    },
    {
        "lat": 48.4637675,
        "lng": -124.2423718
    },
    {
        "lat": 48.4742386,
        "lng": -124.2725842
    },
    {
        "lat": 48.4833421,
        "lng": -124.2986767
    },
    {
        "lat": 48.4969943,
        "lng": -124.3048565
    },
    {
        "lat": 48.5001793,
        "lng": -124.3172161
    },
    {
        "lat": 48.5033641,
        "lng": -124.3556683
    },
    {
        "lat": 48.509733,
        "lng": -124.3927471
    },
    {
        "lat": 48.519285,
        "lng": -124.439439
    },
    {
        "lat": 48.5251972,
        "lng": -124.4566052
    },
    {
        "lat": 48.5392928,
        "lng": -124.5012371
    },
    {
        "lat": 48.54793,
        "lng": -124.5451824
    },
    {
        "lat": 48.5565658,
        "lng": -124.5918743
    },
    {
        "lat": 48.5647458,
        "lng": -124.6275799
    },
    {
        "lat": 48.5770132,
        "lng": -124.6735852
    },
    {
        "lat": 48.590186,
        "lng": -124.7209637
    },
    {
        "lat": 48.6078957,
        "lng": -124.7518627
    },
    {
        "lat": 48.3984564,
        "lng": -124.745813
    }
]

  export const southernSOGCoords = 
  [
    {
        "lat": 48.3057706,
        "lng": -123.5575063
    },
    {
        "lat": 48.1543704,
        "lng": -123.556133
    },
    {
        "lat": 48.1492849,
        "lng": -123.5309576
    },
    {
        "lat": 48.1382888,
        "lng": -123.518598
    },
    {
        "lat": 48.1355393,
        "lng": -123.5076117
    },
    {
        "lat": 48.138747,
        "lng": -123.4650396
    },
    {
        "lat": 48.1304984,
        "lng": -123.4478735
    },
    {
        "lat": 48.1254569,
        "lng": -123.4210943
    },
    {
        "lat": 48.1199566,
        "lng": -123.3970617
    },
    {
        "lat": 48.120415,
        "lng": -123.3744024
    },
    {
        "lat": 48.1240819,
        "lng": -123.3524298
    },
    {
        "lat": 48.1176646,
        "lng": -123.3338903
    },
    {
        "lat": 48.1194982,
        "lng": -123.3091711
    },
    {
        "lat": 48.1259153,
        "lng": -123.2906317
    },
    {
        "lat": 48.1213317,
        "lng": -123.2741522
    },
    {
        "lat": 48.1213317,
        "lng": -123.2494329
    },
    {
        "lat": 48.1282069,
        "lng": -123.2274603
    },
    {
        "lat": 48.1506593,
        "lng": -123.1938147
    },
    {
        "lat": 48.1584466,
        "lng": -123.1519293
    },
    {
        "lat": 48.1579885,
        "lng": -123.1210302
    },
    {
        "lat": 48.1529498,
        "lng": -123.1052374
    },
    {
        "lat": 48.1373723,
        "lng": -123.0894445
    },
    {
        "lat": 48.1236235,
        "lng": -123.0599188
    },
    {
        "lat": 48.1039106,
        "lng": -123.0413794
    },
    {
        "lat": 48.0896942,
        "lng": -123.0345129
    },
    {
        "lat": 48.1043691,
        "lng": -122.9624151
    },
    {
        "lat": 48.1007007,
        "lng": -122.9184698
    },
    {
        "lat": 48.1130803,
        "lng": -122.8861975
    },
    {
        "lat": 48.1401216,
        "lng": -122.846372
    },
    {
        "lat": 48.1515755,
        "lng": -122.7818274
    },
    {
        "lat": 48.2234251,
        "lng": -122.77732
    },
    {
        "lat": 48.2449213,
        "lng": -122.7697669
    },
    {
        "lat": 48.2673227,
        "lng": -122.7587806
    },
    {
        "lat": 48.2828607,
        "lng": -122.7395545
    },
    {
        "lat": 48.3111825,
        "lng": -122.732688
    },
    {
        "lat": 48.3267072,
        "lng": -122.7162085
    },
    {
        "lat": 48.3394887,
        "lng": -122.7024756
    },
    {
        "lat": 48.3581986,
        "lng": -122.6846228
    },
    {
        "lat": 48.3773578,
        "lng": -122.6763831
    },
    {
        "lat": 48.3937742,
        "lng": -122.6763831
    },
    {
        "lat": 48.4179331,
        "lng": -122.6811896
    },
    {
        "lat": 48.4161102,
        "lng": -122.8171454
    },
    {
        "lat": 48.41292,
        "lng": -122.8727637
    },
    {
        "lat": 48.4170217,
        "lng": -122.9057227
    },
    {
        "lat": 48.4416251,
        "lng": -122.9661475
    },
    {
        "lat": 48.4548332,
        "lng": -123.0554114
    },
    {
        "lat": 48.4079144,
        "lng": -123.2544611
    },
    {
        "lat": 48.4006209,
        "lng": -123.3025263
    },
    {
        "lat": 48.3969737,
        "lng": -123.355398
    },
    {
        "lat": 48.408826,
        "lng": -123.4034632
    },
    {
        "lat": 48.4179412,
        "lng": -123.440542
    },
    {
        "lat": 48.395078,
        "lng": -123.4652552
    },
    {
        "lat": 48.3832225,
        "lng": -123.4666285
    },
    {
        "lat": 48.3741011,
        "lng": -123.4975275
    },
    {
        "lat": 48.3526592,
        "lng": -123.5174403
    },
    {
        "lat": 48.3284693,
        "lng": -123.5263667
    },
    {
        "lat": 48.3170549,
        "lng": -123.5325465
    },
    {
        "lat": 48.3057706,
        "lng": -123.5575063
    }
]