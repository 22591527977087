import React from 'react';

const WebcamIframe = ({ closeWebCam, webCamUrl }) => {
    return (

        <div className="webcam-window">
            <span className='webcam-window-close-btn' onClick={closeWebCam}><p>x</p></span>
            <iframe
                width={'100%'}
                height={'100%'}
                src={webCamUrl}
            >
            </iframe>
        </div>


    )
}

export default WebcamIframe;