import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GreenButton from './greenButton';
import Modal from 'react-modal';
import SimpleCloseBtn from './simpleCloseBtn';
import Loader from './loader';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const SubAreaSearch = ({ handleSearchForSubAreasContent, handleShowHighlightedFishingAreas, handleUserSettingsObject, handleShowSubAreaSearch, handleSearchForSubAreas, showSubAreaSearch }) => {
    const [speciesType, setSpeciesType] = useState([]);
    const [speciesName, setSpeciesName] = useState([]);
    const [status, setStatus] = useState('');
    const [selectedSpeciesType, setSelectedSpeciesType] = useState('All');
    const [selectedSpeciesName, setSelectedSpeciesName] = useState('All');
    const [data, setData] = useState([]);
    const [results, setResults] = useState([]); // Store the search results
    const [filteredSpeciesNames, setFilteredSpeciesNames] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        axios.get(API_BASE_URL + '/fishingSpecies')
            .then((response) => {
                console.log('##@@@@@@@@@@@@', response.data)
                const uniqueSpeciesType = [...new Set(response.data.rows.map(item => item.species_type))];
                const uniqueSpeciesName = [...new Set(response.data.rows.map(item => item.species_name))];
                setData(response.data.rows);
                setSpeciesType(uniqueSpeciesType);
                setSpeciesName(uniqueSpeciesName);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (selectedSpeciesType === 'All') {
            setFilteredSpeciesNames(speciesName);
        } else {
            const filteredNames = data
                .filter(item => item.species_type === selectedSpeciesType)
                .map(item => item.species_name);

            setFilteredSpeciesNames(filteredNames);
        }
    }, [selectedSpeciesType, speciesName, data]);

    const handleSearch = () => {
        handleSearchForSubAreasContent({
            "speciesType": selectedSpeciesType,
            "speciesName": selectedSpeciesName,
            "status": status,
        });
        setShowLoader(true);
        axios.get(API_BASE_URL + '/subAreaSearch', {
          params: {
            speciesType: selectedSpeciesType === 'All' ? null : selectedSpeciesType,
            speciesName: selectedSpeciesName === 'All' ? null : selectedSpeciesName,
            status: status === 'All' ? null : status,
          }
        })
          .then((response) => {
            setShowLoader(false);
            setResults(response.data.rows[0]);
            handleSearchForSubAreas(response.data.rows[0]);
            handleShowSubAreaSearch();
            handleUserSettingsObject();
            handleShowHighlightedFishingAreas();
            setErrorMsg('');
            setSelectedSpeciesName('All');
            setSelectedSpeciesType('All');
            setStatus('');
          })
          .catch((error) => {
            setShowLoader(false);
            setErrorMsg("No results found");
            console.error(error);
          });
      };

    return (
        <Modal
            isOpen={showSubAreaSearch}
            onRequestClose={handleShowSubAreaSearch}
            contentLabel='Sign Up Modal'
            className="modal-general sign-up-modal"
            overlayClassName="modal-overlay sub-area-search"
        >
        {showLoader && <Loader /> }
            Species Type:
            <select
                value={selectedSpeciesType}
                onChange={(e) => setSelectedSpeciesType(e.target.value)}
            >
                <option value="All">All</option>
                {speciesType.map((type, index) => (
                    <option key={index} value={type}>
                        {type}
                    </option>
                ))}
            </select>
            Species Name:
            <select
                value={selectedSpeciesName}
                onChange={(e) => setSelectedSpeciesName(e.target.value)}
            >
                <option value="All">All</option>
                {filteredSpeciesNames.map((name, index) => (
                    <option key={index} value={name}>
                        {name}
                    </option>
                ))}
            </select>
            Status:
            <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
            >
                <option value="">All</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
            </select>
            <span style={{color:'red'}}>{errorMsg}</span>
            {/* <button onClick={handleSearch}>Search</button> */}
            <GreenButton className={'small-green-btn'} onClick={handleSearch} btnText='Search' />
            <SimpleCloseBtn text={'close'} onClick={() => {
                handleShowSubAreaSearch();
                setErrorMsg('');
                }
            }                 
            />
        </Modal>
    );
};

export default SubAreaSearch;
