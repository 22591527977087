export const pipersLagoonCam = {
    name: 'Pipers Lagoon',
    url: 'https://windisgood.com/pipers-lagoon.html',
    coordinates: { lat: 49.221296, lng: -123.947214 },
    generalLocation: 'Nanaimo, BC',
    directionFacing: 'east'
    };

export const jerichoBeachCam = {
    name: 'Jericho Beach',
    url: 'https://www.earthtv.com/en/webcam/vancouver-jericho-sailing-centre',
    coordinates: { lat: 49.275695, lng: -123.200575 },
    generalLocation: 'Vancouver, BC',
    directionFacing: 'north'
    };

export const englishBayCam = {
    name: 'English Bay',
    url: 'https://www.youtube.com/watch?v=Hvd6zQqxNLE',
    coordinates: { lat: 49.279817, lng: -123.139958 },
    generalLocation: 'Vancouver, BC',
    directionFacing: 'west'
    };
    
export const dallasRoadCam = {
    name: 'Dallas Road',
    url: 'https://windisgood.com/dallas-road-webcam-live.html',
    coordinates: { lat: 48.408027, lng: -123.364973},
    generalLocation: 'Victoria, BC',
    directionFacing: 'south'
    };

export const rossBayCam = {
    name: 'Ross Bay',
    url: 'https://windisgood.com/ross-bay-webcam-live.html',
    coordinates: { lat: 48.410150, lng: -123.340856 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'south'
    };

export const islandViewBeachCam = {
    name: 'Island View Beach',
    url: 'https://windisgood.com/island-view.html',
    coordinates: { lat: 48.574631, lng: -123.367330 },
    generalLocation: 'Saanich, BC',
    directionFacing: 'east'
    };

export const willowsBeachCam = {
    name: 'Willows Beach',
    url: 'https://windisgood.com/willows-beach.html',
    coordinates: { lat: 48.432858, lng: -123.303984 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'east'
    };

export const gordonsBeachCam = {
    name: 'Gordons Beach',
    url: 'https://windisgood.com/gordons-2.html',
    coordinates: { lat: 48.370225, lng: -123.835742 },
    generalLocation: 'Sooke, BC',
    directionFacing: 'south'
    };

export const sanPareilCam = {
    name: 'San Pareil',
    url: 'https://windisgood.com/san-pareil.html',
    coordinates: { lat: 49.322923, lng: -124.315148 },
    generalLocation: 'Parksville, BC',
    directionFacing: 'north'
    };

export const deepBayCam = {
    name: 'Deep Bay',
    url: 'https://windisgood.com/deep-bay.html',
    coordinates: { lat: 49.456739, lng: -124.712154 },
    generalLocation: 'Bowser, BC',
    directionFacing: 'north'
    };

export const howeSoundCam = {
    name: 'Howe Sound',
    url: 'https://www.howe-sound.ca/',
    coordinates: { lat: 49.380308, lng: -123.276088 },
    generalLocation: 'Vancouver, BC',
    directionFacing: 'north'
    };

export const katKam = {
        name: 'Vancouver Harbour',
        url: 'http://www.katkam.ca/',
        coordinates: { latitude: 49.275828, longitude: -123.137699 },
        generalLocation: 'Vancouver, BC',
        directionFacing: 'north'
        };