import React from "react";

const WindTable = ({ weatherStation, handleMarkerClick, userSettingsObject, convertUnitsUserSettings }) => (
    <div className='wind-table'>
    <table>
      <thead>
        <tr>
          <th>Station</th>
          <th>Wind<br/> Speed</th>
          <th>Wind<br/> Direction</th>
        </tr>
      </thead>
      <tbody>
        {weatherStation.map((station, index) => (
          <tr key={index} onClick={() => handleMarkerClick(station)}>
            <td>{station.name}</td>
            <td>{station.windDirection[0][0] === 'calm' ? station.windDirection[0][0] : convertUnitsUserSettings(station.windSpeed[0][0], userSettingsObject.wind_speed)}</td>
            <td>{station.windDirection[0][0] === 'calm' ? '' : station.windDirection[0][0]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default WindTable;