// ResetPassword.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import GreenButton from './greenButton';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const ResetPassword = () => {

  const { userId, token } = useParams();
  const [showModal, setShowModal] = useState(true); 
  const [userPassword, setUserPassword] = useState({
    password: '',
    confirmPassword: '',
  });

  const formErrorsInitialState = {
    password: '',
  }

  const [formErrors, setFormErrors] = useState(formErrorsInitialState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserPassword({ ...userPassword, [name]: value });
  };

  const closeModal = () => {
    setShowModal(false)
    };

  const handleResetPassword = () => {

    if(userPassword.password !== userPassword.confirmPassword) {
      setFormErrors({
        ...formErrors,
        password: 'Passwords do not match'
      });
      return;
    }
    // Make an API call to reset the password
    axios.post(`${API_BASE_URL}/setNewPassword`, {
      userId,
      token,
      newPassword: userPassword.password,
    })
      .then((response) => {
        // Handle success, e.g., show a success message
        closeModal();
        console.log(response.data);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error(error);
      });
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel='Reset Password Modal'
      className="modal-general sign-up-modal"
      overlayClassName="modal-overlay"
    >
      <div>
        <h2>Reset Password</h2>
        <form>
          <div className='form-field large-input'>
            <input
              name='password'
              type='password'
              placeholder='Password'
              value={userPassword.password}
              onChange={handleInputChange}
            />
          </div>
          <div className='form-field large-input'>
            <input
              name='confirmPassword'
              type='password'
              placeholder='Confirm Password'
              value={userPassword.confirmPassword}
              onChange={handleInputChange}
            />
          </div>
          {formErrors.password && (
                <div className='error-message'>{formErrors.password}</div>
              )}
          <div>
            <GreenButton btnText={'Reset Password'} onClick={handleResetPassword} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ResetPassword;
