export const API_ENDPOINTS = {
  weatherDataEnglishBay: '/weatherDataEnglishBay',
  weatherDataSentryShoal: '/weatherDataSentryShoal',
  weatherDataGeorgiaStrait: '/weatherDataGeorgiaStrait',
  landStationBallenasIslands: '/landStationBallenasIslands',
  landStationCampbellRiver: '/landStationCampbellRiver',
  landStationComoxAirport: '/landStationComoxAirport',
  landStationEntranceIsland: '/landStationEntranceIsland',
  landStationFerryTerminal: '/landStationFerryTerminal',
  landStationGriefPoint: '/landStationGriefPoint',
  landStationHoweSound: '/landStationHoweSound',
  landStationPointAtkinson: '/landStationPointAtkinson',
  landStationSandHeads: '/landStationSandHeads',
  landStationSisterIslets: '/landStationSisterIslets',
  landStationVancouverAirport: '/landStationVancouverAirport',
  landStationVancouverHarbour: '/landStationVancouverHarbour',
  landStationWestVancouver: '/landStationWestVancouver',
  landStationDiscoveryIsland: '/landStationDiscoveryIsland',
  landStationEsquimaltHarbour: '/landStationEsquimaltHarbour',
  landStationKelpReefs: '/landStationKelpReefs',
  landStationNanaimoAirport: '/landStationNanaimoAirport',
  landStationRaceRocksLightstation: '/landStationRaceRocksLightstation',
  landStationSechelt: '/landStationSechelt',
  landStationSheringhamPoint: '/landStationSheringhamPoint',
  landStationSquamishAirport: '/landStationSquamishAirport',
  landStationVictoriaGonzales: '/landStationVictoriaGonzales',
  landStationVictoriaIntlAirport: '/landStationVictoriaIntlAirport',

  landStationSolanderPoint: '/landStationSolanderPoint',
  landStationSartineIsland: '/landStationSartineIsland',
  landStationHerbertIsland: '/landStationHerbertIsland',
  landStationPortHardyAirport: '/landStationPortHardyAirport',
  landStationFannyIsland: '/landStationFannyIsland',
  
  landStationUsa1: '/landStationUsa1',
  landStationUsa2: '/landStationUsa2',
  landStationUsa3: '/landStationUsa3',
  landStationUsa4: '/landStationUsa4',
  landStationUsa5: '/landStationUsa5',
  landStationUsa6: '/landStationUsa6',
};

