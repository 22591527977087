import { CategoryScale, LinearScale, Chart, PointElement, LineController, LineElement } from "chart.js";
import React, { useState, useRef, useEffect, createContext } from 'react';
import { GoogleMap, Marker, InfoWindowF, InfoWindow, LoadScript, Polyline, Polygon } from '@react-google-maps/api';
import axios from 'axios';
import { Routes, Route } from 'react-router-dom';
import * as geolib from 'geolib';
import './index.scss';
import { API_ENDPOINTS } from "./data/apiEndpoints";
import { trainingData } from "./data/trainingData";
import { fishingAreaDataArray } from "./data/fishingAreaData";
import { closureAreas } from "./data/closureArea";
import  { straitOfGeorgiaCoords, vancouverHarbourCoords, sunshineCoastCoords, texadaIslandCoords, campbellRiverCoords, straitOfJDFCoords, southernSOGCoords, connector1 } from './data/polygons.jsx';
import {
  pipersLagoonCam,
  jerichoBeachCam,
  englishBayCam,
  dallasRoadCam,
  rossBayCam,
  islandViewBeachCam,
  willowsBeachCam,
  gordonsBeachCam,
  sanPareilCam,
  deepBayCam,
  howeSoundCam,
  katKam
} from "./data/webcams";
// import TideStatus from "./components/tideCheck";
import CheckboxMenu from "./components/layerCheckbox";
import WebcamIframe from "./components/webCamIframe";
import HistoryWindow from "./components/historyWindow";
import HistoryGraph from "./components/historyGraph";
import BannerMsg from "./components/bannerMsg";
import WindTable from "./components/windTable";
import Sidebar from "./components/sidebar";
import HideButton from "./components/hideBtn";
import Loader from "./components/loader";
import SignUpLoginBtn from "./components/signUpLoginBtn";
import UserSettingsBar from "./components/userSettingsBar";
import SubAreaSearch from "./components/subAreaSearch";
import ResetPassword from "./components/resetPassword";
import CatchLog from "./components/catchLog";
import { fishingHotSpotsArray } from "./data/fishingHotSpots";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import TableFilterForm from "./components/tableFilterForm";
import SimpleCloseBtn from "./components/simpleCloseBtn.jsx";

// *******************
// TODO: set calm to equal 0ft sees on graph, set N/A to equal calm if winds are under x knots
// fix time showing correctly on graph and in infowindow
// load data for markers and weather stations before anything else happens
// review past few hours of wind data to see if accurate before selecting color of polygon (ex. wind at sandheads was 35 knots then the next hour was 5 knots.. wind should represent the earlier high wind conditions)
// show wave height as a range - ex. 1-2ft
// issues with web scraper.. not collecting correct land station values.. need better logic, match station name to table etc
// take the highest wind of the last 4 hours and use to calculate wave height.. reduce by a % depending on if wind is coming down

Chart.register(CategoryScale, LinearScale, PointElement, LineController, LineElement);

const brain = require('brain.js');
const network = new brain.NeuralNetwork();

let windData = {}
let waveData = {}
const mapId = ['77d8b2612253aeee'];
const libraries = ['places'];
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const changeTime = (dateString) => {

  const parts = dateString.split(',');

  // Extract the time part, which is the second element in the parts array
  const timePart = parts[2];

  // // Split the time part into hours, minutes, seconds, and AM/PM using a space as the separator
  // const timeParts = timePart.split(' ');

  // // Extract the minutes, seconds, and AM/PM parts
  // const minutes = timeParts[0];
  // // const seconds = timeParts[2];
  // // const amPm = timeParts[3];

  // // Format the result in 12-hour format
  // const resultTime = `${minutes}`;

  // Return the formatted time
  return timePart;

}

function App() {

const isLoggedInCheck = localStorage.getItem('isLoggedIn') === 'true';
// Try to get settings from local storage
const storedSettings = localStorage.getItem('userSettings');

const userInfoFromStorage = localStorage.getItem('userInfo');

// Use default settings if not found in local storage
const initialSettings = storedSettings && storedSettings !== undefined ? JSON.parse(storedSettings) : {
  "id": "",
  "user_id": "",
  "wave_height": "feet",
  "temperature": "celsius",
  "wind_speed": "knots",
  "wave_height_layer": "on",
  "fishing_area": "on",
  "webcam": "on",
  "wave_buoy": "on",
  "wind_station": "on",
  "rca": "on",
  "sponge_reef": "on",
  "fishing_hotspots": "on",
  "catch_log": "on",
};

  const [map, setMap] = useState(null);
  const [newSiteMsg, setNewSiteMsg] = useState(true);
  const [userSettingsObject, setUserSettingsObject] = useState(initialSettings);
  const [userSettingsObjectDB, setUserSettingsObjectDB] = useState(initialSettings);
  const [mapCenter, setMapCenter] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [nearestCity, setNearestCity] = useState(null);
  const [userCatchLogData, setUserCatchLogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideBtn, setHideBtn] = useState('Wind Table');
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [weatherDataEnglishBay, setWeatherDataEnglishBay] = useState([]);
  const [weatherDataSentryShoal, setWeatherDataSentryShoal] = useState([]);
  const [weatherDataGeorgiaStrait, setWeatherDataGeorgiaStrait] = useState([]);
  const [render, setRender] = useState(false);
  const [renderFishingAreas, setRenderFishingAreas] = useState(false);
  const [displayBanner, setDisplayBanner] = useState(false);
  const [showGraph, setShowGraph] = useState(true);
  const [ballenasIslandData, setBallenasIslandData] = useState([]);
  const [campbellRiverAirportData, setCampbellRiverAirportData] = useState([]);
  const [comoxAirportData, setComoxAirportData] = useState([]);
  const [entranceIslanData, setEntranceIslandData] = useState([]);
  const [ferryTerminalData, setFerryTerminalData] = useState([]);
  const [griefPointData, setGriefPointData] = useState([]);
  const [howeSoundData, setHoweSoundData] = useState([]);
  const [pointAtkinsonData, setPointAtkinsonData] = useState([]);
  const [sandheadsData, setSandheadsData] = useState([]);
  const [sisterIsletsData, setSisterIsletsData] = useState([]);
  const [vancouverAirportData, setVancouverAirportData] = useState([]);
  const [vancouverHarbourData, setVancouverHarbourData] = useState([]);
  const [westVancouverData, setWestVancouverData] = useState([]);
  const [discoveryIslanData, setDiscoveryIslandData] = useState([]);
  const [esquimaltHarbourData, setEsquimaltHarbourData] = useState([]);
  const [kelpReefsData, setKelpReefsData] = useState([]);
  const [nanaimoAirportData, setNanaimoAirportData] = useState([]);
  const [raceRocksLightstationData, setRaceRocksLightstationData] = useState([]);
  const [secheltData, setSecheltData] = useState([]);
  const [sheringhamPointData, setSheringhamPointData] = useState([]);
  const [squamishAirportData, setSquamishAirportData] = useState([]);
  const [victoriaGonzalesData, setVictoriaGonzalesData] = useState([]);
  const [victoriaIntlAirportData, setVictoriaIntlAirportData] = useState([]);
  const [usaStation1Data, setUsaStation1Data] = useState([]);
  const [usaStation2Data, setUsaStation2Data] = useState([]);
  const [usaStation3Data, setUsaStation3Data] = useState([]);
  const [usaStation4Data, setUsaStation4Data] = useState([]);
  const [usaStation5Data, setUsaStation5Data] = useState([]);
  const [usaStation6Data, setUsaStation6Data] = useState([]);
  const [fishingAreaRegulations, setFishingAreaRegulations] = useState([]);
  const [highlightFishingAreas, setHighlightFishingAreas] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [historyPage, setHistoryPage] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [hideLayerBtn, setHideLayerBtn] = useState('-');
  const [webCam, setWebCam] = useState(false);
  const [webCamUrl, setWebCamUrl] = useState('');
  const [delay, setDelay] = useState(false);
  const [hoveredOver, setHoveredOver] = useState(false);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [parentLoginModalOpen, setParentLoginModalOpen] = useState(false);
  const [parentSignUpModalOpen, setParentSignUpModalOpen] = useState(false);
  const [catchLogModal, setCatchLogModal] = useState(false);
  const [userSelectedMarker, setUserSelectedMarker] = useState(null);
  const [newBounds, setNewBounds] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(8);
  const [currentFishingArea, setCurrentFishingArea] = useState(null);
  const [currentFishingAreaLink, setCurrentFishingAreaLink] = useState(null);
  const [mapClickableForLocation, setMapClickableForLocation] = useState(false);
  const [searchSubAreaContent, setSubAreaSearchContent] = useState({
    "speciesType": '',
    "speciesName": '',
    "status": '',
}); 
  const [userInfo, setUserInfo] = useState({
    id: userInfoFromStorage ? JSON.parse(userInfoFromStorage).id : '',
    firstName: userInfoFromStorage ? JSON.parse(userInfoFromStorage).firstName : '',
    lastName: userInfoFromStorage ? JSON.parse(userInfoFromStorage).lastName : '',
    email: userInfoFromStorage ? JSON.parse(userInfoFromStorage).email : '',
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSubAreaSearch, setShowSubAreaSearch] = useState(false);
  const englishBay = 'English Bay';
  const georgiaStrait = 'Georgia Strait';
  const sentryShoal = 'Sentry Shoal';
  const southernSOG = 'Southern Strait of Georgia';
  const straitOfJDF = 'Strait of Juan de Fuca';
  const ballenasIslands = 'Ballenas Islands'
  const campbellRiverAirport = 'Campbell River Airport'
  const comoxAirport = 'Comox Airport'
  const entranceIsland = 'Entrance Island'
  const ferryTerminal = 'Ferry Terminal'
  const griefPoint = 'Grief Point'
  const howeSound = 'Howe Sound'
  const pointAtkinson = 'Point Atkinson'
  const sandheads = 'Sandheads'
  const sisterIslets = 'Sister Islets'
  const vancouverAirport = 'Vancouver Airport'
  const vancouverHarbour = 'Vancouver Harbour'
  const westVancouver = 'West Vancouver'
  const discoveryIsland = 'Discovery Island'
  const esquimaltHarbour = 'Esquimalt Harbour'
  const kelpReefs = 'Kelp Reefs'
  const nanaimoAirport = 'Nanaimo Airport'
  const raceRocksLightstation = 'Race Rocks Lightstation'
  const sechelt = 'Sechelt'
  const sheringhamPoint = 'Sheringham Point'
  const squamishAirport = 'Squamish Airport'
  const victoriaGonzales = 'Victoria Gonzales'
  const victoriaIntlAirport = 'Victoria Int\'l Airport'
  const usaStation1 = 'Port Angeles'
  const usaStation2 = 'Smith Island'
  const usaStation3 = 'Friday Harbor'
  const usaStation4 = 'New Dungeness'
  const usaStation5 = 'Neah Bay'
  const usaStation6 = 'Neah Bay North'
  const [weatherStation, setWeatherStation] = useState(
    [
      {
        'name': ballenasIslands,
        'type': 'weatherStation',
        'coordinates': { lat: 49.346435, lng: -124.156619 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': campbellRiverAirport,
        'type': 'weatherStation',
        'coordinates': { lat: 49.951592, lng: -125.265284 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': comoxAirport,
        'type': 'weatherStation',
        'coordinates': { lat: 49.706722, lng: -124.901936 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': entranceIsland,
        'type': 'weatherStation',
        'coordinates': { lat: 49.229230, lng: -123.809082 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': ferryTerminal,
        'type': 'weatherStation',
        'coordinates': { lat: 49.018519, lng: -123.169686 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': griefPoint,
        'type': 'weatherStation',
        'coordinates': { lat: 49.804271, lng: -124.526152 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': howeSound,
        'type': 'weatherStation',
        'coordinates': { lat: 49.488123, lng: -123.299373 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': pointAtkinson,
        'type': 'weatherStation',
        'coordinates': { lat: 49.330365, lng: -123.264753 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': sandheads,
        'type': 'weatherStation',
        'coordinates': { lat: 49.104885, lng: -123.293518 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': sisterIslets,
        'type': 'weatherStation',
        'coordinates': { lat: 49.486329, lng: -124.434674 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': vancouverAirport,
        'type': 'weatherStation',
        'coordinates': { lat: 49.200401, lng: -123.209452 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': vancouverHarbour,
        'type': 'weatherStation',
        'coordinates': { lat: 49.279122, lng: -123.235011 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },
      {
        'name': westVancouver,
        'type': 'weatherStation',
        'coordinates': { lat: 49.327970, lng: -123.168640 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': discoveryIsland,
        'type': 'weatherStation',
        'coordinates': { lat: 48.425861, lng: -123.235767 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': esquimaltHarbour,
        'type': 'weatherStation',
        'coordinates': { lat: 48.432528, lng: -123.434707 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': kelpReefs,
        'type': 'weatherStation',
        'coordinates': { lat: 48.547154, lng: -123.237409 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': nanaimoAirport,
        'type': 'weatherStation',
        'coordinates': { lat: 49.052975, lng: -123.875056 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': raceRocksLightstation,
        'type': 'weatherStation',
        'coordinates': { lat: 48.298756, lng: -123.532302 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': sechelt,
        'type': 'weatherStation',
        'coordinates': { lat: 49.470869, lng: -123.756311 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': sheringhamPoint,
        'type': 'weatherStation',
        'coordinates': { lat: 48.376669, lng: -123.920956 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': squamishAirport,
        'type': 'weatherStation',
        'coordinates': { lat: 49.781937, lng: -123.159819 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': victoriaGonzales,
        'type': 'weatherStation',
        'coordinates': { lat: 48.408451, lng: -123.332307 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': victoriaIntlAirport,
        'type': 'weatherStation',
        'coordinates': { lat: 48.651414, lng: -123.432940 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
      },   
      {
        'name': usaStation1,
        'type': 'weatherStation',
        'coordinates': { lat: 48.124356, lng: -123.448060 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
        'websiteStationName': 'PTAW1'
      },   
      {
        'name': usaStation2,
        'type': 'weatherStation',
        'coordinates': { lat: 48.322209, lng: -122.822642 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
        'websiteStationName': 'SISW1'
      },   
      {
        'name': usaStation3,
        'type': 'weatherStation',
        'coordinates': { lat: 48.545407, lng: -123.011602 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
        'websiteStationName': 'FRDW1'
      },   
      // {
      //   'name': usaStation4,
      //   'type': 'weatherStation',
      //   'coordinates': { lat: 48.332, lng: -123.179 },
      //   'windDirection': ['N/A'],
      //   'windSpeed': ['N/A'],
      //   'updateTime': ['N/A'],
      //   'websiteStationName': '46088'
      // },   
      {
        'name': usaStation5,
        'type': 'weatherStation',
        'coordinates': { lat: 48.367, lng: -124.614 },
        'windDirection': ['N/A'],
        'windSpeed': ['N/A'],
        'updateTime': ['N/A'],
        'websiteStationName': 'NEAW1'
      },   
      // {
      //   'name': usaStation6,
      //   'type': 'weatherStation',
      //   'coordinates': { lat: 48.493, lng: -124.727 },
      //   'windDirection': ['N/A'],
      //   'windSpeed': ['N/A'],
      //   'updateTime': ['N/A'],
      //   'websiteStationName': '46087'
      // },   
  ]
  )

  const [markers, setMarkers] = useState([
    {
      'name': englishBay,
      'coordinates': {lat: 49.3, lng: -123.36},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    {
      'name': georgiaStrait,
      'coordinates': {lat: 49.025, lng: -123.43},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    {
      'name': sentryShoal,
      'coordinates': {lat: 49.92, lng: -125.0},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    {
      'name': southernSOG,
      'coordinates': {lat: 48.332, lng: -123.179},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    },
    {
      'name': straitOfJDF,
      'coordinates': {lat: 48.493, lng: -124.727},
      'waveHeight': ['N/A'],
      'wavePeriod': ['N/A'],
      'windSpeed': ['N/A'],
      'windDirection': ['N/A'],
      'gustSpeed': ['N/A'],
      'airTemp': ['N/A'],
      'waterTemp': ['N/A'],
      'barometer': ['N/A'],
      'updateTime': ['N/A'],
    }
  ]);

  // const apiKey = process.env.REACT_APP_GOOGLE_API_KEY
  const apiKey = 'AIzaSyAZeFTSQSrsGFtb7SqaxBLvvokCl_ReiFo'
  const mapRef = useRef(null); // Reference to Google Map component

  const webCamArray = [
    pipersLagoonCam,
    jerichoBeachCam,
    englishBayCam,
    dallasRoadCam,
    rossBayCam,
    islandViewBeachCam,
    willowsBeachCam,
    gordonsBeachCam,
    sanPareilCam,
    deepBayCam,
    howeSoundCam,
    katKam
  ];

  const [polygonHoverStates, setPolygonHoverStates] = useState({});
  const [subAreaLabel, setSubAreaLabel] = useState(null);

  useEffect(() => {
    if(userLocation) {
      const coordinatesToCheck = { lat: userLocation?.lat, lng: userLocation?.lng};
      const result = findPolygonForCoordinates(coordinatesToCheck, fishingAreaDataArray);
      setSubAreaLabel(result);
    }

    if(userSelectedMarker) {
      const result = findPolygonForCoordinates(userSelectedMarker, fishingAreaDataArray);
      if(result === null) {
      setSubAreaLabel('');
      } else {
        setSubAreaLabel(result);
      }
    }
    
  }, [catchLogModal, userSelectedMarker]);


  const findPolygonForCoordinates = (coordinatesToCheck, fishingAreas) => {
      for (const fishingArea of fishingAreas) {
          const point = { latitude: coordinatesToCheck.lat, longitude: coordinatesToCheck.lng };
          const isInside = geolib.isPointInPolygon(point, fishingArea.coordinates);
          
          if (isInside) {
              return fishingArea.properties.LABEL; // or return the whole fishingArea object if needed
          }
      }
  
      return null; // Return null if no matching polygon is found
  }

  const handleShowSubAreaSearch = () => {
    setMapCenter({ lat: 51.5, lng: -130 });
    setZoomLevel(5)
    setShowSubAreaSearch(!showSubAreaSearch);
  }

  const handleShowHighlightedFishingAreas = () => {
    setHighlightFishingAreas(!highlightFishingAreas);
    onLoad();
  }

  const handleSearchForSubAreasContent = (content) => {
  setSubAreaSearchContent(content);
  }

  const handleUserSettingsObject = () => {
    if (userSettingsObject.fishing_area !== 'on') {
      setUserSettingsObject({
        ...userSettingsObject,
        "wave_height_layer": "off",
        "fishing_area": "on"
      });
    }
  }

  const handleSearchForSubAreas = (subAreas) => {
    const updatedPolygonHoverStates = {...polygonHoverStates};
    subAreas.forEach(item => {
      const id = item.area_label;
      updatedPolygonHoverStates[id] = true;
    });
    setPolygonHoverStates(updatedPolygonHoverStates);
  }

  const handleClearSubAreaSearch = () => {
    setPolygonHoverStates({});
    setHighlightFishingAreas(false);
  }

  const onLoad = (map) => {
    setMap(map);
      map.addListener('zoom_changed', () => {
        if (highlightFishingAreas === false) {
        setZoomLevel(map.zoom)
        }
      });
  }

  let filteredFishingAreaPolygons = [];

  const onBoundsChanged = () => {
    if (!map) return;
  
    const bounds = map.getBounds();
  
    filteredFishingAreaPolygons = fishingAreaDataArray.filter((fishingArea) => {
      // Check if all polygon coordinates are within the bounds
      return fishingArea.coordinates.every((coordinate) => {
        return bounds.contains(new window.google.maps.LatLng(coordinate.lat, coordinate.lng));
      });
    });
  
    // Now, you have filtered polygons that match the criteria
    // You can set the filtered polygons or do other actions with them
    setNewBounds(filteredFishingAreaPolygons);
  };
  
  const maxWindSpeed = 64.4;
  const minWinSpeed = 0.4;

  const chartOptionsWaves = {
    type: 'line',
    data: waveData,
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
        title: {
          display: true,
          text: `Wave ${userSettingsObject.wave_height}`,
        },
      },
    },
  };
  
  const chartOptionsWind = {
    type: 'line',
    data: windData,
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
        title: {
          display: true,
          text: `Wind ${userSettingsObject.wind_speed}`,
        },
      },
    },
  };

useEffect(() => {
  setMapCenter({ lat: 49.3, lng: -123.36 });
}, []);

// get user city
function getNearestCity(lat, lng, apiKey) {
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find((component) =>
          component.types.includes("locality")
        );
        if (cityComponent) {
          const nearestCity = cityComponent.long_name;
          setNearestCity(nearestCity)
          return nearestCity;
        }
      } else {
        console.log("No results found");
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}

  // get user location every 60 seconds
  const getUserLocation = () => {
    if (navigator.geolocation) {
      const options  = {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0
      };
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          getNearestCity(latitude, longitude, apiKey)
        },
        (error) => {
          console.log('Error getting user location:', error);
        },
        options
      );      
      // navigator.geolocation.clearWatch(watchId);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    // Fetch user's location when component mounts
    getUserLocation();
  }, []);

  // find distance between user and weather stations
  const calculateDistances = () => {
    const stationsWithDistances = weatherStation.map((station) => {
      const distance = geolib.getDistance(userLocation, station.coordinates);
      return { ...station, distance };
    });

    // Sort the stations based on distance in ascending order
    stationsWithDistances.sort((a, b) => a.distance - b.distance);

    // Update the state with sorted stations
    setWeatherStation(stationsWithDistances);
  };

  useEffect(() => {
    if (userLocation) {
      calculateDistances();
    }
  }, [userLocation]);

  const toggleLinkButton = () => {
    setShowLinks(!showLinks)
  }

  // check for mobile screen size
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
      
  // get catchlog info
  useEffect(() => {

    if(isLoggedInCheck) {

    axios.get(API_BASE_URL + `/getCatchLog`, {
        params: {
          userId: userInfo.id,
        }
      })
    .then((response) => {
      // Handle success
      setUserCatchLogData(response.data.rows);
    })
    .catch((error) => {
      // Handle error
      console.error('Error getting regs:', error.response.data);
    });
  } 

  }, [catchLogModal, isLoggedInCheck]);

    // fetch data from api for buoys and weather stations
  const fetchData = async (endpoint, setData) => {
    try {
      const response = await axios.get(API_BASE_URL + endpoint);
      setData(response.data.rows);
      setRender(true)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    useEffect(() => {
      const fetchDataAndUpdateState = async () => {
        setLoading(true);
        const fetchAndUpdate = async (endpoint, setData) => {
          try {
            await fetchData(endpoint, setData);
          } catch (error) {
            console.error(`Error fetching data for ${endpoint}: ${error.message}`);
          }
        };
    
        await fetchAndUpdate(API_ENDPOINTS.landStationBallenasIslands, setBallenasIslandData);
        await fetchAndUpdate(API_ENDPOINTS.weatherDataEnglishBay, setWeatherDataEnglishBay);
        await fetchAndUpdate(API_ENDPOINTS.weatherDataSentryShoal, setWeatherDataSentryShoal);
        await fetchAndUpdate(API_ENDPOINTS.weatherDataGeorgiaStrait, setWeatherDataGeorgiaStrait);
        await fetchAndUpdate(API_ENDPOINTS.landStationCampbellRiver, setCampbellRiverAirportData);
        await fetchAndUpdate(API_ENDPOINTS.landStationComoxAirport, setComoxAirportData);
        await fetchAndUpdate(API_ENDPOINTS.landStationEntranceIsland, setEntranceIslandData);
        await fetchAndUpdate(API_ENDPOINTS.landStationFerryTerminal, setFerryTerminalData);
        await fetchAndUpdate(API_ENDPOINTS.landStationGriefPoint, setGriefPointData);
        await fetchAndUpdate(API_ENDPOINTS.landStationHoweSound, setHoweSoundData);
        await fetchAndUpdate(API_ENDPOINTS.landStationPointAtkinson, setPointAtkinsonData); 
        await fetchAndUpdate(API_ENDPOINTS.landStationSisterIslets, setSisterIsletsData);
        await fetchAndUpdate(API_ENDPOINTS.landStationVancouverAirport, setVancouverAirportData);
        await fetchAndUpdate(API_ENDPOINTS.landStationVancouverHarbour, setVancouverHarbourData);
        await fetchAndUpdate(API_ENDPOINTS.landStationWestVancouver, setWestVancouverData);
        await fetchAndUpdate(API_ENDPOINTS.landStationDiscoveryIsland, setDiscoveryIslandData);
        await fetchAndUpdate(API_ENDPOINTS.landStationEsquimaltHarbour, setEsquimaltHarbourData);
        await fetchAndUpdate(API_ENDPOINTS.landStationKelpReefs, setKelpReefsData);
        await fetchAndUpdate(API_ENDPOINTS.landStationNanaimoAirport, setNanaimoAirportData);
        await fetchAndUpdate(API_ENDPOINTS.landStationRaceRocksLightstation, setRaceRocksLightstationData);
        await fetchAndUpdate(API_ENDPOINTS.landStationSechelt, setSecheltData);
        await fetchAndUpdate(API_ENDPOINTS.landStationSheringhamPoint, setSheringhamPointData);
        await fetchAndUpdate(API_ENDPOINTS.landStationSquamishAirport, setSquamishAirportData);
        await fetchAndUpdate(API_ENDPOINTS.landStationVictoriaGonzales, setVictoriaGonzalesData);
        await fetchAndUpdate(API_ENDPOINTS.landStationVictoriaIntlAirport, setVictoriaIntlAirportData);
        // await fetchAndUpdate(API_ENDPOINTS.landStationUsa1, setUsaStation1Data);
        // await fetchAndUpdate(API_ENDPOINTS.landStationUsa2, setUsaStation2Data);
        // await fetchAndUpdate(API_ENDPOINTS.landStationUsa3, setUsaStation3Data);
        // await fetchAndUpdate(API_ENDPOINTS.landStationUsa4, setUsaStation4Data);
        // await fetchAndUpdate(API_ENDPOINTS.landStationUsa5, setUsaStation5Data);
        // await fetchAndUpdate(API_ENDPOINTS.landStationUsa6, setUsaStation6Data);
        // TODO REVIEW THIS ENDPOINT, NOT GETTING DATA
        // await fetchAndUpdate(API_ENDPOINTS.landStationSandHeads, setSandheadsData);
        
        setLoading(false);
        setRenderFishingAreas(true);
      };
    
      fetchDataAndUpdateState();
    }, []);
    
  // set buoy data after axios call
  useEffect(() => {
    if(weatherDataEnglishBay.length > 0) {

    const updatedMarkers = [...markers];
  
    // Find the index of the marker to update
    const indexToUpdate = updatedMarkers.findIndex(marker => marker.name === englishBay);
  
    if (indexToUpdate !== -1) {
      const data = weatherDataEnglishBay; 
  
      updatedMarkers[indexToUpdate] = {
        ...updatedMarkers[indexToUpdate],
        waveHeight: [data.map((item) => dbWaveToFeet(item.waveheight))],
        wavePeriod: [data.map((item) => item.waveperiod)],
        windSpeed: [data.map((item) => item.speed)],
        windDirection: [data.map((item) => item.direction)],
        gustSpeed: [data.map((item) => item.gustspeed)],
        airTemp: [data.map((item) => item.airtemp)],
        waterTemp: [data.map((item) => item.watertemp)],
        barometer: [data.map((item) => item.barometer)],
        updateTime: [data.map((item) => convertTime(item.created_at))],
      };
  
      setMarkers(updatedMarkers);
    }
  }
  }, [weatherDataEnglishBay]);

  // set buoy data after axios call
  useEffect(() => {
    if(usaStation4Data.length > 0) {

    const updatedMarkers = [...markers];
  
    // Find the index of the marker to update
    const indexToUpdate = updatedMarkers.findIndex(marker => marker.name === southernSOG);
  
    if (indexToUpdate !== -1) {
      const data = usaStation4Data; 
      updatedMarkers[indexToUpdate] = {
        ...updatedMarkers[indexToUpdate],
        waveHeight: [data.map((item) => item.waveheight)],
        wavePeriod: ['N/A'],
        windSpeed: [data.map((item) => item.windspeed)],
        windDirection: [data.map((item) => item.winddirection)],
        gustSpeed: ['N/A'],
        airTemp: ['N/A'],
        waterTemp: ['N/A'],
        barometer: ['N/A'],
        updateTime: [data.map((item) => item.created_at)],
      };
  
      setMarkers(updatedMarkers);
    }
  } 
  }, [usaStation4Data]);

  // set buoy data after axios call
  useEffect(() => {
    if(usaStation6Data.length > 0) {

    const updatedMarkers = [...markers];
  
    // Find the index of the marker to update
    const indexToUpdate = updatedMarkers.findIndex(marker => marker.name === straitOfJDF);
  
    if (indexToUpdate !== -1) {
      const data = usaStation6Data; 
  
      updatedMarkers[indexToUpdate] = {
        ...updatedMarkers[indexToUpdate],
        waveHeight: [data.map((item) => item.waveheight)],
        wavePeriod: ['N/A'],
        windSpeed: [data.map((item) => item.windspeed)],
        windDirection: [data.map((item) => item.winddirection)],
        gustSpeed: ['N/A'],
        airTemp: ['N/A'],
        waterTemp: ['N/A'],
        barometer: ['N/A'],
        updateTime: [data.map((item) => item.created_at)],
      };
  
      setMarkers(updatedMarkers);
    }
  } 
  }, [usaStation6Data]);

  // set buoy data after axios call
  useEffect(() => {
    if(weatherDataSentryShoal.length > 0) {

    const updatedMarkers = [...markers];
  
    // Find the index of the marker to update
    const indexToUpdate = updatedMarkers.findIndex(marker => marker.name === sentryShoal);
  
    if (indexToUpdate !== -1) {
      const data = weatherDataSentryShoal; 
  
      updatedMarkers[indexToUpdate] = {
        ...updatedMarkers[indexToUpdate],
        waveHeight: [data.map((item) => dbWaveToFeet(item.waveheight))],
        wavePeriod: [data.map((item) => item.waveperiod)],
        windSpeed: [data.map((item) => item.speed)],
        windDirection: [data.map((item) => item.direction)],
        gustSpeed: [data.map((item) => item.gustspeed)],
        airTemp: [data.map((item) => item.airtemp)],
        waterTemp: [data.map((item) => item.watertemp)],
        barometer: [data.map((item) => item.barometer)],
        updateTime: [data.map((item) => convertTime(item.created_at))],
      };
  
      setMarkers(updatedMarkers);
    }
  }
  }, [weatherDataSentryShoal]);

  useEffect(() => {
    if(weatherDataGeorgiaStrait.length > 0) {

    const updatedMarkers = [...markers];
  
    // Find the index of the marker to update
    const indexToUpdate = updatedMarkers.findIndex(marker => marker.name === georgiaStrait);
  
    if (indexToUpdate !== -1) {
      const data = weatherDataGeorgiaStrait; 
  
      updatedMarkers[indexToUpdate] = {
        ...updatedMarkers[indexToUpdate],
        waveHeight: [data.map((item) => dbWaveToFeet(item.waveheight))],
        wavePeriod: [data.map((item) => item.waveperiod)],
        windSpeed: [data.map((item) => item.speed)],
        windDirection: [data.map((item) => item.direction)],
        gustSpeed: [data.map((item) => item.gustspeed)],
        airTemp: [data.map((item) => item.airtemp)],
        waterTemp: [data.map((item) => item.watertemp)],
        barometer: [data.map((item) => item.barometer)],
        updateTime: [data.map((item) => convertTime(item.created_at))],
      };
  
      setMarkers(updatedMarkers);
    }
  }
  }, [weatherDataGeorgiaStrait]);

  // set data for graph
  const updateGraph = (data) => {

    windData = {
      labels: [changeTime(data.updateTime[0][5]), changeTime(data.updateTime[0][4]), changeTime(data.updateTime[0][3]), changeTime(data.updateTime[0][2]), changeTime(data.updateTime[0][1]), changeTime(data.updateTime[0][0])],
      datasets: [
        {
          label: "Wind Data",
          data: [convertUnitsUserSettings(data.windSpeed[0][5], userSettingsObject.wind_speed), convertUnitsUserSettings(data.windSpeed[0][4], userSettingsObject.wind_speed),convertUnitsUserSettings(data.windSpeed[0][3], userSettingsObject.wind_speed),convertUnitsUserSettings(data.windSpeed[0][2], userSettingsObject.wind_speed),convertUnitsUserSettings(data.windSpeed[0][1], userSettingsObject.wind_speed),convertUnitsUserSettings(data.windSpeed[0][0], userSettingsObject.wind_speed)],
          borderColor: "rgba(75,182,192,1)"
        },
      ]
    };
    
    if(data.waveHeight) {
    waveData = {
      labels: [changeTime(data.updateTime[0][5]), changeTime(data.updateTime[0][4]), changeTime(data.updateTime[0][3]), changeTime(data.updateTime[0][2]), changeTime(data.updateTime[0][1]), changeTime(data.updateTime[0][0])],
      datasets: [
        {
          label: "First dataset",
          data: [convertUnitsUserSettings(data.waveHeight[0][5], userSettingsObject.wave_height), convertUnitsUserSettings(data.waveHeight[0][4], userSettingsObject.wave_height), convertUnitsUserSettings(data.waveHeight[0][3], userSettingsObject.wave_height), convertUnitsUserSettings(data.waveHeight[0][2], userSettingsObject.wave_height), convertUnitsUserSettings(data.waveHeight[0][1], userSettingsObject.wave_height), convertUnitsUserSettings(data.waveHeight[0][0], userSettingsObject.wave_height)],
          borderColor: "rgba(75,202,192,1)",
        },
      ],
    };
    }
    }

  // show infowindow and give ability to close it
  const showInfoWindowFunction = () => {
    setShowInfoWindow(true)
  }

  const closeInfoWindowFunction = () => {
    setShowInfoWindow(false)
  }

  // show webcam view
  const showWebCam = () => {
    setWebCam(true)
  }

  const closeWebCam = () => {
    setWebCam(false)
  }

  // show history page for weather details in infowindow
  const showHistoryPage = () => {
    setHistoryPage(true)
    setHideBtn('Wind Table')
    closeInfoWindowFunction()
    handleGraphClose()
    }

  const closeHistoryPage = () => {
    setHistoryPage(false)
    setHideBtn('-')
    showInfoWindowFunction()
    setShowGraph(true);
  }

  // normalize wind speed for neural network
  const normalizeWindSpeed = (windSpeed) => {
    return (windSpeed - minWinSpeed) / (maxWindSpeed - minWinSpeed);
  }

  // set object of wind values for neural network
  const windDirectionValues = {
    'N': 0,
    'NNE': 0.0625,
    'NE': 0.125,
    'ENE': 0.1875,
    'E': 0.25,
    'ESE': 0.3125,
    'SE': 0.375,
    'SSE': 0.4375,
    'S': 0.5,
    'SSW': 0.5625,
    'SW': 0.625,
    'WSW': 0.6875,
    'W': 0.75,
    'WNW': 0.8125,
    'NW': 0.875,
    'NNW': 0.9375,
  }

  // normalize wind direction for neural network
  const normalizeWindDirection = (windDirection) => {
    return windDirectionValues[windDirection]
  }

  // get wind direction from weather stations or buoys
  const getWindDirection = (stationName, weatherStationArray) => {
    const station = weatherStationArray.find(station => station.name === stationName);

    if (station) {
      return station.windDirection[0][0];
    } else {
      return 'Station not found';
    }
  }
  
  // get wind speed from weather stations or buoys (for last 3 entries/hours), remove any non numeric values
  // const getWindSpeedValue = (stationName, weatherStationArray) => {
  //   const station = weatherStationArray.find(station => station.name === stationName);
  
  //   if (station) {
  //     // Use slice to get the last 3 entries of the windSpeed array
  //     const lastThreeWindSpeedEntries = station.windSpeed.slice(-3);

  //     console.log(lastThreeWindSpeedEntries)
  
  //     // Flatten the last three entries into a single array of values
  //     const windSpeedValues = lastThreeWindSpeedEntries.flatMap(entry => Object.values(entry));
  
  //     // Filter out "N/A" and non-numeric values
  //     const filteredWindSpeedValues = windSpeedValues.filter(value => !isNaN(value));
  
  //     // Check if there are valid values left
  //     if (filteredWindSpeedValues.length > 0) {
  //       return Math.max(...filteredWindSpeedValues);
  //     } else {
  //       return 'No valid wind speed data available';
  //     }
  //   } else {
  //     return 'Station not found';
  //   }
  // }

  // JUST RETURN THE LAST ENTRY FOR NOW.. UNTIL MOBILE APP ALSO TAKES AN ARRAY AND RETURNS THE HIGHEST OF THE LAST 3 VALUES
  const getWindSpeedValue = (stationName, weatherStationArray) => {
    const station = weatherStationArray.find(station => station.name === stationName);
  
    if (station && station.windSpeed && Array.isArray(station.windSpeed) && station.windSpeed.length > 0) {
      // Use slice to get the last entry of the windSpeed array
      const lastWindSpeedEntry = station.windSpeed.slice(-1)[0];
  
      if (lastWindSpeedEntry && typeof lastWindSpeedEntry === 'object') {
        const windSpeedValue = Object.values(lastWindSpeedEntry)[0];
  
        // Check if the value is numeric
        if (!isNaN(windSpeedValue)) {
          return windSpeedValue;
        } else {
          return 'Invalid wind speed value';
        }
      } else {
        return 'No valid wind speed data available';
      }
    } else {
      return 'Station or wind speed data not found';
    }
  };
  
  

  const setColorOfPolygon = (waveHeight) => {

    const waveHeightNumber = parseInt(waveHeight);

    if (waveHeightNumber < 1) {
      return `purple`;
    } else if (waveHeightNumber < 2) {
      return `blue`;
    } else if (waveHeightNumber < 3) {
      return `green`;
    } else if (waveHeightNumber < 4) {
      return `orange`;
    } else if (waveHeightNumber >= 4) {
      return `red`;
    } else {
      return 'rgba(0, 0, 0, 0)';
    }
  };

  const setColorOfPolygonConnector = (waveHeight1, waveHeight2) => {
    if(waveHeight1 < 2 && waveHeight2 < 3) {
      return gradientColors;
    }
  }
  
  const gradientColors = [
    '#00FF00', // Green
    '#FFFF00', // Yellow
    '#FF0000', // Red
  ];

  // update landstation data after axios call
  const updateLandStationMarkers = (landStationData, landStationName) => {
    if(landStationData.length > 0) {
  
      const updatedWeather = [...weatherStation];
  
      const indexToUpdate = updatedWeather.findIndex(station => station.name === landStationName);
  
      if (indexToUpdate !== -1) {
  
        const data = landStationData;
  
        updatedWeather[indexToUpdate] = {
          ...updatedWeather[indexToUpdate],
          windDirection: [data.map((item) => item.wind_direction)],
          windSpeed: [data.map((item) => item.wind_speed)],
          updateTime: [data.map((item) => convertTime(item.created_at))],
        };
    
        setWeatherStation(updatedWeather);
      }
    }
    }
  
    // run the update land station markers function for each land station
    useEffect(() => {
  updateLandStationMarkers(ballenasIslandData, ballenasIslands)
    }, [ballenasIslandData]);
  
    useEffect(() => {
  updateLandStationMarkers(campbellRiverAirportData, campbellRiverAirport)
  
    }, [campbellRiverAirportData]);
  
    useEffect(() => {
  updateLandStationMarkers(comoxAirportData, comoxAirport)
  
    }, [comoxAirportData]);
  
    useEffect(() => {
    updateLandStationMarkers(entranceIslanData, entranceIsland)
  
    }, [entranceIslanData]);
  
    useEffect(() => {
  updateLandStationMarkers(ferryTerminalData, ferryTerminal)
  
    }, [ferryTerminalData]);
  
    useEffect(() => {
  updateLandStationMarkers(griefPointData, griefPoint)
  
    }, [griefPointData]);
  
    useEffect(() => {
  updateLandStationMarkers(howeSoundData, howeSound)
  
    }, [howeSoundData]);
  
    useEffect(() => {
  updateLandStationMarkers(pointAtkinsonData, pointAtkinson)
  
    }, [pointAtkinsonData]);
  
    useEffect(() => {
  updateLandStationMarkers(sandheadsData, sandheads)
  
    }, [sandheadsData]);
  
    useEffect(() => {
  updateLandStationMarkers(sisterIsletsData, sisterIslets)
  
    }, [sisterIsletsData]);
  
    useEffect(() => {
  updateLandStationMarkers(vancouverAirportData, vancouverAirport)
  
    }, [vancouverAirportData]);
  
    useEffect(() => {
  updateLandStationMarkers(vancouverHarbourData, vancouverHarbour)
  
    }, [vancouverHarbourData]);
  
    useEffect(() => {
  updateLandStationMarkers(westVancouverData, westVancouver)
  
    }, [westVancouverData]);

    useEffect(() => {
  updateLandStationMarkers(discoveryIslanData, discoveryIsland)

    }, [discoveryIslanData]);

    useEffect(() => {
  updateLandStationMarkers(esquimaltHarbourData, esquimaltHarbour)
      
    }, [esquimaltHarbourData]);

    useEffect(() => {
  updateLandStationMarkers(kelpReefsData, kelpReefs)

    }, [kelpReefsData]);

    useEffect(() => {
  updateLandStationMarkers(nanaimoAirportData, nanaimoAirport)

    }, [nanaimoAirportData]);

    useEffect(() => {
  updateLandStationMarkers(raceRocksLightstationData, raceRocksLightstation)

    }, [raceRocksLightstationData]);

    useEffect(() => {
  updateLandStationMarkers(secheltData, sechelt)

    }, [secheltData]);

    useEffect(() => {
  updateLandStationMarkers(sheringhamPointData, sheringhamPoint)

    }, [sheringhamPointData]);

    useEffect(() => {
  updateLandStationMarkers(squamishAirportData, squamishAirport)

    }, [squamishAirportData]);

    useEffect(() => {
  updateLandStationMarkers(victoriaGonzalesData, victoriaGonzales)

    }, [victoriaGonzalesData]);

    useEffect(() => {
  updateLandStationMarkers(victoriaIntlAirportData, victoriaIntlAirport)

    }, [victoriaIntlAirportData]);

    useEffect(() => {
  updateLandStationMarkers(usaStation1Data, usaStation1)

    }, [usaStation1Data]);

    useEffect(() => {
  updateLandStationMarkers(usaStation2Data, usaStation2)

    }, [usaStation2Data]);

    useEffect(() => {
  updateLandStationMarkers(usaStation3Data, usaStation3)

    }, [usaStation3Data]);

    useEffect(() => {
  updateLandStationMarkers(usaStation4Data, usaStation4)

    }, [usaStation4Data]);

    useEffect(() => {
  updateLandStationMarkers(usaStation5Data, usaStation5)

    }, [usaStation5Data]);

    useEffect(() => {
  updateLandStationMarkers(usaStation6Data, usaStation6)

    }, [usaStation6Data]);

network.train(trainingData)
  
  // convert wave height from meters to feet, multipl by 2 as the buoys do not take full wave height into account
  const dbWaveToFeet = (dbWave) => {
    if(dbWave === 'N/A' || dbWave === '') return ('N/A')
    return ((dbWave * 3.28084) * 2).toFixed(1)
  }

  const englishBayWaveHeight = markers.find(marker => marker.name === englishBay).waveHeight[0][0];
  const sentryShoalWaveHeight = markers.find(marker => marker.name === sentryShoal).waveHeight[0][0];
  const georgiaStraitWaveHeight = markers.find(marker => marker.name === georgiaStrait).waveHeight[0][0];
  let straitOfJDFWaveHeight = null;
  let southernSOGWaveHeight = null;

  // let straitOfJDFWaveHeight = markers.find(marker => marker.name === straitOfJDF)?.waveHeight[0][0];
  // console.log("strait of jdf1: ",straitOfJDFWaveHeight)

  // if(straitOfJDFWaveHeight === '-' || straitOfJDFWaveHeight === ' - ') {
  //   console.log("strait of jdf2: ",straitOfJDFWaveHeight)
  //   straitOfJDFWaveHeight = markers.find(marker => marker.name === straitOfJDF)?.waveHeight[0][1];
  // }

  // if(straitOfJDFWaveHeight === '-' || straitOfJDFWaveHeight === ' - ') {
  //   console.log("strait of jdf3: ",straitOfJDFWaveHeight)
  //   straitOfJDFWaveHeight = markers.find(marker => marker.name === straitOfJDF)?.waveHeight[0][2];
  // }
  
  // Define an array of possible indices to check
  const indicesToCheck = [0, 1, 2, 3];
  
  // Function to set the wave height and return the updated value
  const setWaveHeight = (waveHeightVariable, location) => {
    for (const index of indicesToCheck) {
      const potentialWaveHeight = markers.find(marker => marker.name === location)?.waveHeight[0][index];
      if (potentialWaveHeight && potentialWaveHeight !== '-' && potentialWaveHeight !== ' - ') {
        return potentialWaveHeight;
      }
    }
    // If no valid value is found, return the original value
    return waveHeightVariable;
  }
  
  // Assign the updated values to your variables
  straitOfJDFWaveHeight = setWaveHeight(straitOfJDFWaveHeight, straitOfJDF);
  southernSOGWaveHeight = setWaveHeight(southernSOGWaveHeight, southernSOG);
  // console.log("strait of jdf: ",straitOfJDFWaveHeight, "southern sog: ",southernSOGWaveHeight)

  const reRenderPolygonColor = (location) => {
    setColorOfPolygon(location)
  }

  useEffect((straitOfJDFWaveHeight) => {
    reRenderPolygonColor(straitOfJDFWaveHeight)
  }, [straitOfJDFWaveHeight])

  // run wind speed through the neural network to predict wave height
  const resultStraitOfGeorgia = network.run([normalizeWindSpeed(getWindSpeedValue('Sandheads', weatherStation)),normalizeWindDirection(getWindDirection('Sandheads', weatherStation))]);
  const resultSunshineCoast = network.run([normalizeWindSpeed(getWindSpeedValue(ballenasIslands, weatherStation)),normalizeWindDirection(getWindDirection(ballenasIslands, weatherStation))]);
  const resultTexadaIsland = network.run([normalizeWindSpeed(getWindSpeedValue(sisterIslets, weatherStation)),normalizeWindDirection(sisterIslets, weatherStation)]);
  
  // multipling by 4 to get to what I expect.. output waveheight seems to be way too low otherwise
  const waveHeightFormula = (waveHeight) => {
    return ((waveHeight * (14.5 - .01)) + .01) * 4
  }

  const predictedWaveStraitOfGeorgia = !isNaN(georgiaStraitWaveHeight) ? georgiaStraitWaveHeight : waveHeightFormula(resultStraitOfGeorgia);
  const predictedWaveSunshineCoast = waveHeightFormula(resultSunshineCoast)
  const predictedWaveTexadaIsland = waveHeightFormula(resultTexadaIsland)

  // handle click of buoy of weather station to show graph, set marker/buoy dataa and show graph
  const handleMarkerClick = (marker) => {
    updateGraph(marker);
    setSelectedMarker(marker);
    setShowGraph(true);
  };

  // close graph
  const handleGraphClose = () => {
    setShowGraph(false);
  };

  // reset data when closing graph
  const handleMarkerClose = () => {
    setSelectedMarker(null);
  };

    // convert time to PST
    const convertTime = (updateTime) => {
      const pstTime = new Date(updateTime);
      
      // Create a formatter for the "America/Los_Angeles" time zone
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        hour12: false,
      });
      
      // Format the date and time in the desired time zone
      const pstTimeFormatted = formatter.format(pstTime);
      
      return pstTimeFormatted;
    }

    const convertTimeWithHrChange = (updateTime) => {
      const pstTime = new Date(updateTime);
    
      // Add 8 hours to the time
      pstTime.setHours(pstTime.getHours() + 8);
    
      // Format date and time separately
      const formattedDate = pstTime.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    
      const formattedTime = pstTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });
    
      // Combine formatted date and time
      const pstTimeFormatted = `${formattedDate} ${formattedTime}`;
    
      return pstTimeFormatted;
    };
    
    
    
    // hide wind table
    const hideTable = () => {
      if(hideBtn === '-') {
        setHideBtn('Wind Table')
      } else {
        setHideBtn('-')
      }
    }

    // hide layer checkboxes
    const hideLayerCheckboxes = () => {
      
      if(hideLayerBtn === '-') {
        setHideLayerBtn('Layers')
      } else {
        setHideLayerBtn('-')
      }
    }

    // handle hover over

    const handleHoverOver = (marker) => {
      setHoveredOver(true)
      setHoveredMarker(marker)
    }

    const handleHoverOut = () => {
      setHoveredOver(false)
      setHoveredMarker(null)
    }

    // highlight polygon on hover
    const handleMouseOver = (id) => {
      if (highlightFishingAreas === false) {
      setPolygonHoverStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));
      }
    };
  
    const handleMouseOut = (id) => {
      if (highlightFishingAreas === false) {
      setPolygonHoverStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
      }
    };

    const findValidWaveHeight = (data) => {
      for (const row of data) {
        for (const value of row) {
          if (value !== '-' && value !== 'N/A') {
            return convertUnitsUserSettings(value, userSettingsObject.wave_height);
          }
        }
      }
      return 'N/A'; // Return 'N/A' if no valid value is found
    };

  const showLoader = (boolean) => {
    setLoading(boolean);
  };

  const handleLoginData = (userData) => {
    setLoading(true);
    setDisplayBanner(true);
    setIsLoggedIn(true);
    setDisplayBannerTimeout();
    setUserInfo({
      id: userData.id,
      firstName: userData.first_name,
      lastName: userData.last_name,
      email: userData.email
    });
  
    // Make sure userInfo.id is available
    if (userData.id) {
      fetchUserSettings(`${userData.id}`);
    } else {
      // Handle the case where userInfo.id is not available
      console.error('userInfo.id is not available');
      setLoading(false);
    }
  
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userInfo', JSON.stringify(userData));
  };
  

const handleFeedback = () => {
  setLoading(true);
  // setDisplayBanner(true);
  // setDisplayBannerTimeout()
  setLoading(false);
}

const handleLogout = () => {
  setLoading(true);
  setDisplayBanner(true);
  setIsLoggedIn(false);
  setUserInfo({});
  setDisplayBannerTimeout()
  const initialSettings = {
    "id": "",
    "user_id": "",
    "wave_height": "feet",
    "temperature": "celsius",
    "wind_speed": "knots",
    "wave_height_layer": "on",
    "fishing_area": "on",
    "webcam": "on",
    "wave_buoy": "on",
    "wind_station": "on",
    "rca": "on",
    "sponge_reef": "on",
    "fishing_hotspots": "on",
    "catch_log": "on",
  };

  setUserSettingsObject(initialSettings);
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('userSettings');
  setLoading(false);
  // storedSettings = null;
};

// LOGIN AND SETTINGS

const fetchUserSettings = async (userId) => {
  try {
  const response = await axios.get(API_BASE_URL + '/getUserSettings', {
      params: {
        userId: userId,
      },
    });
    console.log("RESULTZ: ", response)
    localStorage.setItem('userSettings', JSON.stringify(response.data.rows[0]));
    setUserSettingsObject(response.data.rows[0]);
    setUserSettingsObjectDB(response.data.rows[0]);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
  } 
};

const handleSettingsCheckBoxChange = (event) => {
  // localStorage.setItem('userSettings', JSON.stringify(response.data[0]));
  const { name, checked } = event.target;
  setUserSettingsObject((prevSettings) => ({
    ...prevSettings,
    [name]: checked ? 'on' : 'off'
  }));
};

// When a radio button is selected, make a POST request with the updated setting.
const handleSettingsRadioBtnChange = (event) => {


  const { name, value } = event.target;

  setUserSettingsObjectDB((prevSettings) => ({
    ...prevSettings,
    [name]: value,
  }));

  // set the main user settings object to reflect changes made in the user settings module (ex. if they change wind speed from knots to KMH it should show on the map right away)
  setUserSettingsObject((prevSettings) => ({
    ...prevSettings,
    [name]: value,
  }));

  axios.post(API_BASE_URL + `/userSettings/${userInfo.id}`, { name, value })
    .then((response) => {
      // Handle success
      // console.log('User settings updated successfully:', response.data);
    })
    .catch((error) => {
      // Handle error
      console.error('Error updating user settings:', error);
    });
};

useEffect(() => {
  if (userSettingsObjectDB) {
    localStorage.setItem('userSettings', JSON.stringify(userSettingsObjectDB));
  }
}, [userSettingsObjectDB]);

// FUNCTION TO CONVERT UNITS 
const convertUnitsUserSettings = (value, unit) => {
  if (value === 'N/A' || value === '-' || value === ' - ' || value === 'NA') {
    return value;
  } else if (unit === 'feet') {
    return value;
  } else if (unit === 'meters') {
    return (value * 0.3048).toFixed(1);
  } else if (unit === 'celsius') {
    return value;
  } else if (unit === 'fahrenheit') {
    return ((value * 1.8) + 32).toFixed(0);
  } else if (unit === 'knots') {
    return value;
  } else if (unit === 'kmh') {
    return (value * 1.852).toFixed(0);
  } else if (unit === 'mph') {
    return (value * 1.15078).toFixed(0);
  } else {
    return value;
  }
};

// TIMEOUT FOR BANNER MSG

const setDisplayBannerTimeout = () => {
setTimeout(() => {
  setDisplayBanner(false)
}, 3000);
}

  setTimeout(() => {
    setDelay(true)
  }, 1000);

  // seting modal for catch log

  const openCatchLogModal = () => {
    setCatchLogModal(true)
  }

  const closeCatchLogModal = () => {
    setCatchLogModal(false);
  }

  const handleMapClickableForLocation = () => {
  setMapClickableForLocation(false)
}

  const prepareMapForCatchLogLocation = () => {
    setMapClickableForLocation(true);
    setUserSettingsObject((prevSettings) => ({
      ...prevSettings,
      fishing_area: 'off',
      wave_height_layer: 'off',
    }));
  }

  const getLocationOnMap = (e) => {
    setUserSelectedMarker(e.latLng.toJSON());
    openCatchLogModal();
  }

  const setUserLocationBackToOriginal = () => {
    setMapClickableForLocation(false);
    setUserSelectedMarker({
      lat: userLocation?.lat,
      lng: userLocation?.lng,
    })
    // setSubAreaLabel(result);
    // setUserSettingsObject((prevSettings) => ({
    //   ...prevSettings,
    //   fishing_area: 'on',
    //   wave_height_layer: 'on',
    // }));
  }

  // setting modal for sign up and login

  const openLoginModal = () => {
    setParentLoginModalOpen(true)
  }

  const closeLoginModalFromParent = () => {
    setParentLoginModalOpen(false);
  }

  const openSignUpModal = () => {
    setParentSignUpModalOpen(true)
  }

  const closeSignUpModalFromParent = () => {
    setParentSignUpModalOpen(false);
  }  

  let dynamicValue = currentFishingAreaLink;

// Map the values based on your conditions
if (dynamicValue === 101) {
  dynamicValue = 1;
} else if (dynamicValue === 102 || dynamicValue === 130 || dynamicValue === 142) {
  dynamicValue = 2;
} else if (dynamicValue === 103) {
  dynamicValue = 3;
} else if (dynamicValue === 104) {
  dynamicValue = 4;
} else if (dynamicValue === 105) {
  dynamicValue = 5;
} else if (dynamicValue === 106) {
  dynamicValue = 6;
} else if (dynamicValue === 107) {
  dynamicValue = 7;
} else if (dynamicValue === 108) {
  dynamicValue = 8;
} else if (dynamicValue === 109) {
  dynamicValue = 9;
} else if (dynamicValue === 110) {
  dynamicValue = 10;
} else if (dynamicValue === 111) {
  dynamicValue = 11;
} else if (dynamicValue === 22) {
  dynamicValue = 21;
} else if (dynamicValue === 123) {
  dynamicValue = 23;
} else if (dynamicValue === 124) {
  dynamicValue = 24;
} else if (dynamicValue === 125) {
  dynamicValue = 25;
} else if (dynamicValue === 126) {
  dynamicValue = 26;
} else if (dynamicValue === 127) {
  dynamicValue = 27;
}

const url = `https://www.pac.dfo-mpo.gc.ca/fm-gp/rec/tidal-maree/a-s${dynamicValue}-eng.html`;

// axios call to get fishing regulations

useEffect(() => {
  // call axios for fishing regs
if (currentFishingArea) {
  console.log('current fishing area: ', currentFishingArea)
  axios.get(API_BASE_URL + `/getFishingAreaRegulations?currentFishingArea=${currentFishingArea}`)
  .then((response) => {
    console.log("fishing regs response: ", response)
    // Handle success
    setFishingAreaRegulations(response.data.rows[0]);
  })
  .catch((error) => {
    // Handle error
    console.error('Error getting regs:', error.response.data);
  });
}
  
}, [currentFishingArea]);

// DATA TABLE FOR REGS

  const data = React.useMemo(() => fishingAreaRegulations, [fishingAreaRegulations]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "species_type",
        Filter: TableFilterForm,
      },
      {
        Header: "Species",
        accessor: "species_name",
        Filter: TableFilterForm,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: TableFilterForm,
      },
      {
        Header: "Min Size",
        accessor: "min_size",
        Filter: TableFilterForm,
      },
      {
        Header: "Max Size",
        accessor: "max_size",
        Filter: TableFilterForm,
      },
      {
        Header: "Daily Limit",
        accessor: "daily_limit",
        Filter: TableFilterForm,
      },
      {
        Header: "Possession Limit",
        accessor: "possession_limit",
        Filter: TableFilterForm,
      },
      {
        Header: "Annual Limit",
        accessor: "annual_limit",
        Filter: TableFilterForm,
      },
      {
        Header: "Gear",
        accessor: "gear",
        Filter: TableFilterForm,
      },
    ],
    []
  );

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    rows, 
    state,
    setGlobalFilter,
    prepareRow 
  } =
    useTable({ 
      columns, 
      data 
    }, useFilters, useGlobalFilter);

    const { globalFilter } = state;
  
  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries} mapIds={mapId}>
    {newSiteMsg && 
    <div className="new-site-message">
      <span onClick={() => setNewSiteMsg(false)} className="new-site-close-btn">X</span>
      <h3>We have a new site!</h3>
      <a href="www.sportyapp.ca" className="visit-site-btn">
        visit SportyApp.ca
      </a>
    </div>
    }
    {isLoggedInCheck && !catchLogModal && !mapClickableForLocation && !highlightFishingAreas &&
    <button
      className="catch-log-btn"
      onClick={openCatchLogModal}
    >Log a Catch
    </button>
    }
    {isLoggedInCheck && <CatchLog 
        userLocation={userLocation}
        userInfo={userInfo}
        catchLogModal={catchLogModal}
        closeCatchLogModal={closeCatchLogModal}
        subAreaLabel={subAreaLabel}
        prepareMapForCatchLogLocation={prepareMapForCatchLogLocation}
        userSelectedMarker={userSelectedMarker}
        handleMapClickableForLocation={handleMapClickableForLocation}
        mapClickableForLocation={mapClickableForLocation}
        setUserLocationBackToOriginal={setUserLocationBackToOriginal}
      />
    }
    {!isMobile &&
      <div className={highlightFishingAreas ? `polygon-id-banner-search` : `polygon-id-banner`}>
      {Object.entries(polygonHoverStates).map(([key, value]) => (
          value && key.length > 1 && 
          <p key={key}>
            Area: {key}
          </p>          
        ))
      }</div>
    }
      {highlightFishingAreas && 
      <div className="sub-area-search-content">    
        {Object.entries(searchSubAreaContent).map(([key, value]) => (
          <p key={key}>
            <b>{key}:</b> {value}
          </p>
        ))
        }
      </div>
      }
      {!catchLogModal && !mapClickableForLocation &&
      <button 
      className={
        highlightFishingAreas && isMobile && hideBtn === "Wind Table"
          ? "clear-area-btn-high"
          : highlightFishingAreas && !isMobile
          ? "sub-area-search-btn"
          : highlightFishingAreas && isMobile && hideBtn === "-"
          ? "clear-area-btn-low"
          : !highlightFishingAreas && !isMobile
          ? "sub-area-search-btn"
          : !highlightFishingAreas && isMobile && hideBtn === "Wind Table"
          ? "clear-area-btn-high"
          : !highlightFishingAreas && isMobile && hideBtn === "-"
          ? "clear-area-btn-low"
          : "sub-area-search-btn"
      }
        onClick={() => {
        highlightFishingAreas ? 
        handleClearSubAreaSearch() : 
        handleShowSubAreaSearch()
      }}>{highlightFishingAreas ? 'Clear Map' : 'Search Map'}</button>
      }
      {/* <TideStatus /> */}
      <SubAreaSearch handleSearchForSubAreasContent={handleSearchForSubAreasContent} handleShowHighlightedFishingAreas={handleShowHighlightedFishingAreas} handleUserSettingsObject={handleUserSettingsObject} handleShowSubAreaSearch={handleShowSubAreaSearch} showSubAreaSearch={showSubAreaSearch} handleSearchForSubAreas={handleSearchForSubAreas} handleClearSubAreaSearch={handleClearSubAreaSearch} />
      {/* {!isLoggedInCheck && <SignUpLoginBtn className={"signUp-homepage-btn"} btnText={"Sign Up"} onBtnClick={openSignUpModal} openSignUpModal={openSignUpModal} /> } */}
      {!isLoggedInCheck && <SignUpLoginBtn className={"login-homepage-btn"} btnText={"Login"} onBtnClick={openLoginModal} openLoginModal={openLoginModal} /> }
      <UserSettingsBar userSettingsObject={userSettingsObject} />
      {currentFishingArea && 
      <div className="fishing-area-info-box">
      <h3>Area {currentFishingArea}</h3>
      <span className="fishing-area-info-box-close" onClick={() => setCurrentFishingArea(null)}>X</span>
      <div className="search-container">
        Search all fields:{' '}
        <input
            type="text"
            value={globalFilter || ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>
      <table className="fishing-area-info-box-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{ position: "sticky", top: "0", background: "white" }}>
                {headerGroup.headers.map((column) => (
                  <th className={column.id === "min_size"
                  || column.id === "species_type"
                  || column.id === "max_size"
                  || column.id === "daily_limit"
                  || column.id === "possession_limit"
                  || column.id === "annual_limit"
                  ? "small-input" : ""} {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    color: row.cells.some((cell) => /Open/.test(cell.value)) ? "black" : "#626262"
                  }}
                >
                  {row.cells.map((cell) => (
                    <td className="test-class" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      }
      {displayBanner && <BannerMsg msg={isLoggedIn ? `Welcome ${userInfo.firstName}!` : 'Signed Out!'} />}
      <span className="layer-hide" onClick={hideLayerCheckboxes}>{hideLayerBtn}</span>
      <Sidebar 
        showLoader={showLoader}
        handleLoginData={handleLoginData} 
        handleLogout={handleLogout} 
        handleFeedback={handleFeedback}
        isLoggedIn={isLoggedInCheck} 
        userInfo={userInfo} 
        userSettingsObjectDB={userSettingsObjectDB}
        handleSettingsRadioBtnChange={handleSettingsRadioBtnChange}
        setUserLocation={setUserLocation}
        userLocation={userLocation}
        nearestCity={nearestCity}
        parentLoginModalOpen={parentLoginModalOpen}
        parentSignUpModalOpen={parentSignUpModalOpen}
        closeSignUpModalFromParent={closeSignUpModalFromParent}
        closeLoginModalFromParent={closeLoginModalFromParent}
      />
      {hideLayerBtn === '-' &&
      <CheckboxMenu
        handleHoverOut={handleHoverOut}
        handleHoverOver={handleHoverOver}
        hoveredOver={hoveredOver}
        userSettingsObject={userSettingsObject}
        handleSettingsCheckBoxChange={handleSettingsCheckBoxChange}
        isLoggedInCheck={isLoggedInCheck}
      />
      }
      {webCam && 
        <WebcamIframe closeWebCam={closeWebCam} webCamUrl={webCamUrl} />
      }
      {historyPage && 
        <HistoryWindow closeHistoryPage={closeHistoryPage} selectedMarker={selectedMarker} userSettingsObject={userSettingsObject} convertUnitsUserSettings={convertUnitsUserSettings} />
      }
      <div style={{ height: '100vh', width: '100%' }}>
        {selectedMarker && showGraph && !isMobile &&
          <HistoryGraph handleGraphClose={handleGraphClose} selectedMarker={selectedMarker} windData={windData} chartOptionsWind={chartOptionsWind} waveData={waveData} chartOptionsWaves={chartOptionsWaves} />
        }
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={{ height: '100vh', width: '100vw' }}
          center={mapCenter} // Use user's location if available, otherwise use default center
          onLoad={onLoad}
          onClick={mapClickableForLocation && getLocationOnMap}
          onTouchEnd={mapClickableForLocation && getLocationOnMap}
          zoom={zoomLevel}
          onBoundsChanged={onBoundsChanged}
          options={
            {
              draggableCursor: mapClickableForLocation && 'crosshair',
              fullscreenControl: false,
              mapTypeControl: false, 
              mapId: mapId 
            }
            }
        >
        {render && userSettingsObject.rca === 'on' &&
          closureAreas.map((area, index) => {
          if(area.type === 'rockfish closure') {
          return (
          <Polyline
            key={index}
            path={area.coordinates}
            options={{
                strokeColor: 'rgb(250, 159, 152)',
                strokeOpacity: 1,
                strokeWeight: 1,
              }}
          />
            )}
          })
        }
        {render && userSettingsObject.sponge_reef === 'on' &&
          closureAreas.map((area, index) => {
          if(area.type === 'sponge reef closure') {
          return (
          <Polyline
            key={index}
            path={area.coordinates}
            options={{
                strokeColor: 'rgb(247, 228, 96)',
                strokeOpacity: .5,
                strokeWeight: 1,
              }}
          />
            )}
          })
        }
        <HideButton hideTable={hideTable} hideBtn={hideBtn} />
        {hideBtn === '-' &&
          <WindTable weatherStation={weatherStation} handleMarkerClick={handleMarkerClick} userSettingsObject={userSettingsObject} convertUnitsUserSettings={convertUnitsUserSettings} />
        }
        {loading && 
          <Loader />
        }
        {render && userCatchLogData && userSettingsObject.catch_log === 'on' && zoomLevel >= 7 && isLoggedInCheck &&
            userCatchLogData.map((marker, index) => (
              <Marker
              key={marker.id}
              position={{
                lat: parseFloat(marker.location_lat),
                lng: parseFloat(marker.location_lng),
              }}
              icon={
                marker.species === 'Salmon' ? require("../src/images/fish (4).png") :
                marker.species === 'Other Finfish' ? require("../src/images/fish (4).png") :
                marker.species === 'Crab' ? require("../src/images/crab (2).png") :
                marker.species === 'Invertebrates' && require("../src/images/shrimp (6).png")
              }   
              onClick={() => handleHoverOver(marker)}
              // onMouseOut={handleHoverOut}                     
            />
            ))
          }
        {
          render && userSettingsObject.fishing_hotspots === 'on' && zoomLevel >= 7 &&
          fishingHotSpotsArray.map((hotSpot, index) => (
            <Marker
              key={hotSpot.id}
              position={{ lat: hotSpot.coordinates.lat, lng: hotSpot.coordinates.lng }}
              icon={require("../src/images/fishWhite.png")}
              onClick={() => handleHoverOver(hotSpot)}
              // onMouseOut={handleHoverOut}
              >                
              </Marker>
          ))
        }
        {hoveredOver && hoveredMarker.type === "fishingHotSpot" && (
          <InfoWindow
            position={hoveredMarker.coordinates}
            onCloseClick={handleHoverOut}
            disableAutoPan
            disablePanOnClick // Disable panning when the info window is opened
            zIndex={899}
          >
            <div>
              <h3>{hoveredMarker.name}</h3>
            </div>
          </InfoWindow>
        )}
        {hoveredOver && hoveredMarker.species && (
          <InfoWindow
            position={{lat: parseFloat(hoveredMarker.location_lat), lng: parseFloat(hoveredMarker.location_lng)}}
            onCloseClick={handleHoverOut}
            disableAutoPan
            disablePanOnClick // Disable panning when the info window is opened
            index={900}
          >
            <div className="catch-log-infowindow">
              <table>
                <tbody>
                  <tr>
                    <th>Species:</th>
                    <td>{hoveredMarker.species}</td>
                  </tr>
                  <tr>
                    <th>Subspecies:</th>
                    <td>{hoveredMarker.sub_species}</td>
                  </tr>
                  <tr>
                    <th>Date:</th>
                    <td>{convertTimeWithHrChange(hoveredMarker.date_time)}</td>
                  </tr>
                  <tr>
                    <th>Depth:</th>
                    <td>{hoveredMarker.depth} ft</td>
                  </tr>
                  <tr>
                    <th>Lure/Bait:</th>
                    <td>{hoveredMarker.lure}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </InfoWindow>
        )}
        {render && userSettingsObject.webcam === 'on' &&
          webCamArray.map((webcam, index) => (
            <Marker
              key={index}
              position={{ lat: webcam.coordinates.lat, lng: webcam.coordinates.lng }}
              onClick={() => {
              showWebCam()
              setWebCamUrl(webcam.url)
              }
              }
              icon={require("../src/images/webcam4.png")}
            >
            </Marker>
          ))}
          {render && userSettingsObject.wave_buoy === 'on' &&
          markers.map((marker, index) => (            
            <Marker
              key={index}
              position={{ lat: marker.coordinates.lat, lng: marker.coordinates.lng }}
              onClick={() => {
                handleMarkerClick(marker)
                showInfoWindowFunction();
              }
                }
              icon={require("../src/images/whiteSpace.png")}
              label={
                findValidWaveHeight(marker.waveHeight)
              }
            >
            </Marker>
          ))}
          {render &&
            <Marker
              position={userLocation}
              icon={require("../src/images/userIcon.png")}
            >
            </Marker>
          }
          {render && userSettingsObject.wind_station === 'on' && zoomLevel >= 7 &&
            weatherStation.map((marker, index) => {
              let labelText = '';

              if (
                marker.windSpeed[0][0] !== null &&
                typeof marker.windSpeed[0][0] === 'number' &&
                !isNaN(marker.windSpeed[0][0]) &&
                marker.windSpeed[0][0] >= 0
              ) {
                labelText = convertUnitsUserSettings(marker.windSpeed[0][0], userSettingsObject.wind_speed);
              } else if (marker.windDirection[0][0] === 'calm') {
                labelText = 'calm';
              } else if (isNaN(marker.windSpeed[0][0])) {
                labelText = '...';
              } else {
                labelText = convertUnitsUserSettings(marker.windSpeed[0][0], userSettingsObject.wind_speed);
              }
              return (
                <div key={index}>
                <Marker
                  key={index}
                  position={{ lat: marker.coordinates.lat, lng: marker.coordinates.lng }}
                  onClick={() => {
                    handleMarkerClick(marker);
                    showInfoWindowFunction();
                    }
                  }
                  icon={
                    marker.windSpeed[0][0] < 1 ? require("../src/images/calm.png") :
                    marker.windDirection[0][0] === 'calm' ? require("../src/images/calm.png") :
                    marker.windDirection[0][0] === 'N' ? require("../src/images/arrow1N.png") :
                    marker.windDirection[0][0] === 'NNE' ? require("../src/images/arrow1NE.png") :
                    marker.windDirection[0][0] === 'NE' ? require("../src/images/arrow1NE.png") :
                    marker.windDirection[0][0] === 'ENE' ? require("../src/images/arrow1NE.png") :
                    marker.windDirection[0][0] === 'E' ? require("../src/images/arrow1E.png") :
                    marker.windDirection[0][0] === 'ESE' ? require("../src/images/arrow1SE.png") :
                    marker.windDirection[0][0] === 'SE' ? require("../src/images/arrow1SE.png") :
                    marker.windDirection[0][0] === 'SSE' ? require("../src/images/arrow1SE.png") :
                    marker.windDirection[0][0] === 'S' ? require("../src/images/arrow1S.png") :
                    marker.windDirection[0][0] === 'SSW' ? require("../src/images/arrow1SW.png") :
                    marker.windDirection[0][0] === 'SW' ? require("../src/images/arrow1SW.png") :
                    marker.windDirection[0][0] === 'WSW' ? require("../src/images/arrow1SW.png") :
                    marker.windDirection[0][0] === 'W' ? require("../src/images/arrow1W.png") :
                    marker.windDirection[0][0] === 'WNW' ? require("../src/images/arrow1NW.png") :
                    marker.windDirection[0][0] === 'NW' ? require("../src/images/arrow1NW.png") :
                    marker.windDirection[0][0] === 'NNW' ? require("../src/images/arrow1NW.png") :
                    require("../src/images/calm.png")
                    }
                    label={{
                      text: labelText,                        
                      color: 'lightGreen',
                      fontSize: '18px',
                    }}
                >
                </Marker>
              </div>
              )
          })
            }
          {selectedMarker && showInfoWindow && (
            <InfoWindowF
              position={selectedMarker.coordinates}
              onCloseClick={handleMarkerClose}
              disableAutoPan
              disablePanOnClick // Disable panning when the info window is opened
            >
              <div>
                <h3>{selectedMarker.name}</h3>
                <p>{selectedMarker.type === 'weatherStation' ? '' : `Wave Height: ${findValidWaveHeight(selectedMarker.waveHeight[0][0])} ${userSettingsObject.wave_height}`}</p>
                <p>{selectedMarker.windDirection[0][0] === `calm` ? `Wind Speed: calm` : `Wind Speed: ${convertUnitsUserSettings(selectedMarker.windSpeed[0][0], userSettingsObject.wind_speed)} ${userSettingsObject.wind_speed}`}</p>
                <p>{selectedMarker.windDirection[0][0] === `calm` ? '' : `Wind Direction: ${selectedMarker.windDirection[0][0]}`}</p>
                <p>
                  {selectedMarker.type === 'weatherStation' ? (
                    ''
                  ) : (
                    `Air Temp: ${convertUnitsUserSettings(selectedMarker.airTemp[0][0], userSettingsObject.temperature)}${userSettingsObject.temperature === 'celsius' ? ' C' : ' F'}`
                  )}
                </p>
                <p>
                  {selectedMarker.type === 'weatherStation' ? (
                    ''
                  ) : (
                    `Water Temp: ${convertUnitsUserSettings(selectedMarker.waterTemp[0][0], userSettingsObject.temperature)}${userSettingsObject.temperature === 'celsius' ? ' C' : ' F'}`
                  )}
                </p>
                <p>Updated: {(selectedMarker.updateTime[0][0])}</p>
                <p className="show-history-btn" onClick={showHistoryPage}>Show History</p>
              </div>
            </InfoWindowF>
          )}
          {/* COMMENT OUT TO AVOID KEY ERROR AND LOOK FOR SERVER ERROS ON SETTINGS */}
          {render && delay && userSettingsObject.fishing_area === 'on' && newBounds && renderFishingAreas &&
            newBounds.map((area, index) => (
              <>
              <Polygon
                path={area.coordinates}
                zIndex={2}
                key={area.properties.LABEL}
                onMouseOver={() => handleMouseOver(area.properties.LABEL)}
                onMouseOut={() => handleMouseOut(area.properties.LABEL)}
                onClick={() => {
                    setCurrentFishingArea(area.properties.LABEL); 
                    setCurrentFishingAreaLink(area.properties.MANAGEMENT_AREA)               
                  }
                }
                options={{
                  fillColor: highlightFishingAreas ? '#85F99C' : 'white',
                  fillOpacity: polygonHoverStates[area.properties.LABEL] ? 0.25 : 0,
                  strokeColor: 'rgb(44,51,64)',
                  strokeOpacity: .5,
                  strokeWeight: .8,
                }}
              />
              {area.properties.LABEL && zoomLevel >= 9 && (
                <Marker
                  key={area.properties.LABEL}
                  position={area.centerPoint}
                  icon={require("../src/images/blank.png")}
                  label={{
                    text: area.properties.LABEL,
                    color: 'white',
                    fontSize: '11px',
                    fontWeight: '400',
                  }}
                />
              )}
              </>
            ))
          }
          {render && predictedWaveStraitOfGeorgia && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={straitOfGeorgiaCoords}
            key={0}
            zIndex={1}
            options={{
              fillColor: setColorOfPolygon(predictedWaveStraitOfGeorgia),     
              fillOpacity: .3,        
              strokeOpacity: 0,
            }}
          >
          </Polygon>
          }
          {render && straitOfJDFWaveHeight && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={straitOfJDFCoords}
            key={2}
            zIndex={1}
            options={{
              fillColor: setColorOfPolygon(straitOfJDFWaveHeight),     
              fillOpacity:.3,        
              strokeOpacity: 0,
            }}
          >
          </Polygon>
          }
          {render && southernSOGWaveHeight && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={southernSOGCoords}
            key={3}
            zIndex={1}
            options={{
              fillColor: setColorOfPolygon(southernSOGWaveHeight),     
              fillOpacity:.3,        
              strokeOpacity: 0,
            }}
          >
          </Polygon>
          }
          {render && englishBayWaveHeight && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={vancouverHarbourCoords}
            zIndex={1}
            key={4}
            options={{
              fillColor: setColorOfPolygon(englishBayWaveHeight),  
              fillOpacity:.3,        
              strokeOpacity: 0,
            }}
          />
          }
          {render && predictedWaveSunshineCoast && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={sunshineCoastCoords}
            zIndex={1}
            key={5}
            options={{
              fillColor: setColorOfPolygon(predictedWaveSunshineCoast), 
              fillOpacity:.3,        
              strokeOpacity: 0,
            }}
          />
          }
          {render && predictedWaveTexadaIsland && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={texadaIslandCoords}
            zIndex={1}
            key={6}
            options={{
              fillColor: setColorOfPolygon(predictedWaveTexadaIsland),       
              fillOpacity:.3,        
              strokeOpacity: 0,
            }}
          />
          }
          {render && sentryShoalWaveHeight && userSettingsObject.wave_height_layer === 'on' &&
          <Polygon
            path={campbellRiverCoords}
            zIndex={1}
            key={7}
            options={{
              fillColor: setColorOfPolygon(sentryShoalWaveHeight),     
              fillOpacity:.3,        
              strokeOpacity: 0,
            }}
          />
          }
          <div className="colour-legend-container">
            <div className="colour-legend">
              <div className="colour-calm">
              calm
              </div>
              <div className="colour-1-2">
              1-2 feet
              </div>
              <div className="colour-2-3">
              2-3 feet
              </div>
              <div className="colour-3-4">
              3-4 feet
              </div>
              <div className="colour-4-plus">
              4+ feet
              </div>
            </div>
          </div>
        </GoogleMap>
      </div>
      {/* <span onClick={toggleLinkButton} className="show-link-button">{showLinks ? "Hide Links" : "Show Links"}</span>
      {showLinks &&
      <div>
        <a href="https://www.flaticon.com/free-icons/buoy" title="buoy icons">Buoy icons created by Freepik - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/arrow" title="arrow icons">Arrow icons created by Handicon - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/arrow" title="arrow icons">Arrow icons created by Catalin Fertu - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/arrow" title="arrow icons">Arrow icons created by Kirill Kazachek - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/minus" title="minus icons">Minus icons created by Becris - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/dot" title="dot icons">Dot icons created by Hadiii - Flaticon</a>
        <a href="https://www.flaticon.com/free-icons/webcam" title="webcam icons">Webcam icons created by Pixel perfect - Flaticon</a>
      </div>
      } */}
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/resetPassword/:userId/:token' element={<ResetPassword />} />
      </Routes>
    </LoadScript>
  );
}

export default App;
