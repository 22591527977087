

const SimpleCloseBtn = ({ onClick, className, text }) => {
    return (

    <button className={`close-btn ${className}`} type='button' onClick={onClick}>
      {text}
    </button>

    )
}

export default SimpleCloseBtn;