import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

const params = new URLSearchParams(window.location.search);
const userId = params.get('userId');
const token = params.get('token');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // React strictmode causing double infowindow bug
  // https://github.com/JustFly1984/react-google-maps-api/issues/86
  // <React.StrictMode>
  <Router>
    <App userId={userId} token={token} />
  </Router>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
