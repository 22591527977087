export const trainingData = [
    {input: [0.185, 0.408333333333333], output: [0.0128939594761905]},
    {input: [0.168125, 0.952777777777778], output: [0.00383794075578853]},
    {input: [0.145625, 0.897222222222222], output: [0.00383794075578853]},
    {input: [0.179375, 0.194444444444444], output: [0.00383794075578853]},
    {input: [0.111875, 0.427777777777778], output: [0.00836595011598949]},
    {input: [0.168125, 0.35], output: [0.0174219688363914]},
    {input: [0.190625, 0.366666666666667], output: [0.00836595011598949]},
    {input: [0.168125, 0.383333333333333], output: [0.00836595011598949]},
    {input: [0.15125, 0.375], output: [0.00383794075578853]},
    {input: [0.134375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.363888888888889], output: [0.00383794075578853]},
    {input: [0.12875, 0.405555555555556], output: [0.00383794075578853]},
    {input: [0.23, 0.158333333333333], output: [0.00383794075578853]},
    {input: [0.14, 0.344444444444444], output: [0.0264779875567933]},
    {input: [0.258125, 0.283333333333333], output: [0.212126371325033]},
    {input: [0.275, 0.222222222222222], output: [0.121566184121014]},
    {input: [0.3425, 0.2], output: [0.162318268362822]},
    {input: [0.325625, 0.161111111111111], output: [0.166846277723023]},
    {input: [0.2975, 0.147222222222222], output: [0.15326224964242]},
    {input: [0.275, 0.15], output: [0.162318268362822]},
    {input: [0.21875, 0.166666666666667], output: [0.148734240282219]},
    {input: [0.156875, 0.180555555555556], output: [0.10345414668021]},
    {input: [0.17375, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.10625, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.134375, 0.269444444444444], output: [0.062702062438401]},
    {input: [0.134375, 0.244444444444444], output: [0.0491180343577981]},
    {input: [0.168125, 0.25], output: [0.0581740530782001]},
    {input: [0.156875, 0.275], output: [0.0491180343577981]},
    {input: [0.168125, 0.219444444444444], output: [0.0400620156373962]},
    {input: [0.168125, 0.238888888888889], output: [0.0310059969169943]},
    {input: [0.095, 0.319444444444444], output: [0.0400620156373962]},
    {input: [0.05, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.08375, 0.0611111111111111], output: [0.0174219688363914]},
    {input: [0.055625, 0.252777777777778], output: [0.0310059969169943]},
    {input: [0.033125, 0.191666666666667], output: [0.0174219688363914]},
    {input: [0.055625, 0.730555555555556], output: [0.0264779875567933]},
    {input: [0.055625, 0.713888888888889], output: [0.0400620156373962]},
    {input: [0.0725, 0.677777777777778], output: [0.0264779875567933]},
    {input: [0.0725, 0.655555555555556], output: [0.0355340062771953]},
    {input: [0.14, 0.713888888888889], output: [0.0400620156373962]},
    {input: [0.06125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.021875, 0.858333333333333], output: [0.0491180343577981]},
    {input: [0.021875, 0.75], output: [0.0355340062771953]},
    {input: [0.066875, 0.636111111111111], output: [0.0491180343577981]},
    {input: [0.066875, 0.530555555555556], output: [0.0355340062771953]},
    {input: [0.06125, 0.358333333333333], output: [0.0310059969169943]},
    {input: [0.08375, 0.344444444444444], output: [0.0491180343577981]},
    {input: [0.123125, 0.305555555555556], output: [0.0310059969169943]},
    {input: [0.134375, 0.336111111111111], output: [0.0310059969169943]},
    {input: [0.134375, 0.263888888888889], output: [0.0355340062771953]},
    {input: [0.123125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.12875, 0.272222222222222], output: [0.0400620156373962]},
    {input: [0.14, 0.347222222222222], output: [0.0264779875567933]},
    {input: [0.100625, 0.305555555555556], output: [0.0174219688363914]},
    {input: [0.095, 0.347222222222222], output: [0.0536460437179991]},
    {input: [0.055625, 0.816666666666667], output: [0.0310059969169943]},
    {input: [0.213125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.235625, 0.161111111111111], output: [0.0581740530782001]},
    {input: [0.17375, 0.147222222222222], output: [0.0762860905190039]},
    {input: [0.168125, 0.113888888888889], output: [0.0400620156373962]},
    {input: [0.123125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.08375, 0.136111111111111], output: [0.0355340062771953]},
    {input: [0.095, 0.161111111111111], output: [0.0491180343577981]},
    {input: [0.089375, 0.216666666666667], output: [0.0536460437179991]},
    {input: [0.089375, 0.194444444444444], output: [0.0355340062771953]},
    {input: [0.033125, 0.280555555555556], output: [0.0491180343577981]},
    {input: [0.01625, 0.391666666666667], output: [0.0310059969169943]},
    {input: [0.03875, 0.641666666666667], output: [0.0355340062771953]},
    {input: [0.010625, 0.547222222222222], output: [0.0536460437179991]},
    {input: [0.01625, 0.908333333333333], output: [0.0355340062771953]},
    {input: [0.100625, 0.919444444444444], output: [0.0581740530782001]},
    {input: [0.12875, 0.952777777777778], output: [0.0355340062771953]},
    {input: [0.021875, 0.986111111111111], output: [0.0310059969169943]},
    {input: [0.010625, 0.0222222222222222], output: [0.0310059969169943]},
    {input: [0.03875, 0.0388888888888889], output: [0.0400620156373962]},
    {input: [0.089375, 0.141666666666667], output: [0.0310059969169943]},
    {input: [0.06125, 0.252777777777778], output: [0.0174219688363914]},
    {input: [0.12875, 0.130555555555556], output: [0.0264779875567933]},
    {input: [0.185, 0.177777777777778], output: [0.0264779875567933]},
    {input: [0.280625, 0.197222222222222], output: [0.0310059969169943]},
    {input: [0.33125, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.39875, 0.213888888888889], output: [0.126094193481215]},
    {input: [0.426875, 0.216666666666667], output: [0.207598361964832]},
    {input: [0.483125, 0.247222222222222], output: [0.24835044620664]},
    {input: [0.426875, 0.25], output: [0.239294427486239]},
    {input: [0.46625, 0.330555555555556], output: [0.261934474287243]},
    {input: [0.415625, 0.322222222222222], output: [0.221182390045435]},
    {input: [0.449375, 0.319444444444444], output: [0.230238408765837]},
    {input: [0.404375, 0.330555555555556], output: [0.225710399405636]},
    {input: [0.44375, 0.319444444444444], output: [0.266462483647444]},
    {input: [0.516875, 0.380555555555556], output: [0.33891063341066]},
    {input: [0.348125, 0.438888888888889], output: [0.298158549168851]},
    {input: [0.348125, 0.469444444444444], output: [0.239294427486239]},
    {input: [0.415625, 0.488888888888889], output: [0.252878455566841]},
    {input: [0.41, 0.497222222222222], output: [0.24835044620664]},
    {input: [0.3875, 0.65], output: [0.203070352604631]},
    {input: [0.44375, 0.766666666666667], output: [0.270990493007645]},
    {input: [0.415625, 0.777777777777778], output: [0.266462483647444]},
    {input: [0.4775, 0.775], output: [0.275518502367846]},
    {input: [0.460625, 0.758333333333333], output: [0.384190727012669]},
    {input: [0.539375, 0.772222222222222], output: [0.438526839335081]},
    {input: [0.55625, 0.763888888888889], output: [0.447582858055483]},
    {input: [0.5, 0.761111111111111], output: [0.424942811254478]},
    {input: [0.404375, 0.747222222222222], output: [0.397774755093272]},
    {input: [0.2975, 0.725], output: [0.320798595969856]},
    {input: [0.291875, 0.761111111111111], output: [0.311742577249454]},
    {input: [0.246875, 0.702777777777778], output: [0.24835044620664]},
    {input: [0.35375, 0.733333333333333], output: [0.230238408765837]},
    {input: [0.57875, 0.838888888888889], output: [0.43399882997488]},
    {input: [0.4325, 0.8], output: [0.452110867415684]},
    {input: [0.325625, 0.769444444444444], output: [0.280046511728047]},
    {input: [0.33125, 0.736111111111111], output: [0.275518502367846]},
    {input: [0.483125, 0.780555555555556], output: [0.252878455566841]},
    {input: [0.651875, 0.797222222222222], output: [0.542671054619703]},
    {input: [0.618125, 0.827777777777778], output: [0.47927892357689]},
    {input: [0.573125, 0.827777777777778], output: [0.547199063979904]},
    {input: [0.60125, 0.833333333333333], output: [0.48380693293709]},
    {input: [0.449375, 0.838888888888889], output: [0.447582858055483]},
    {input: [0.494375, 0.802777777777778], output: [0.461166886136086]},
    {input: [0.4325, 0.777777777777778], output: [0.456638876775885]},
    {input: [0.561875, 0.811111111111111], output: [0.520031007818698]},
    {input: [0.505625, 0.175], output: [0.379662717652468]},
    {input: [0.393125, 0.261111111111111], output: [0.307214567889253]},
    {input: [0.258125, 0.336111111111111], output: [0.216654380685234]},
    {input: [0.185, 0.277777777777778], output: [0.148734240282219]},
    {input: [0.123125, 0.308333333333333], output: [0.144206230922018]},
    {input: [0.033125, 0.441666666666667], output: [0.135150212201616]},
    {input: [0.1175, 0.375], output: [0.194014333884229]},
    {input: [0.21875, 0.180555555555556], output: [0.275518502367846]},
    {input: [0.23, 0.138888888888889], output: [0.266462483647444]},
    {input: [0.235625, 0.172222222222222], output: [0.234766418126038]},
    {input: [0.32, 0.172222222222222], output: [0.203070352604631]},
    {input: [0.3425, 0.169444444444444], output: [0.203070352604631]},
    {input: [0.314375, 0.136111111111111], output: [0.221182390045435]},
    {input: [0.348125, 0.15], output: [0.19854234324443]},
    {input: [0.28625, 0.141666666666667], output: [0.194014333884229]},
    {input: [0.179375, 0.0944444444444444], output: [0.189486324524028]},
    {input: [0.044375, 0.0444444444444444], output: [0.207598361964832]},
    {input: [0.05, 0.0861111111111111], output: [0.184958315163827]},
    {input: [0.033125, 0.586111111111111], output: [0.157790259002621]},
    {input: [0.0725, 0.405555555555556], output: [0.117038174760813]},
    {input: [0.078125, 0.413888888888889], output: [0.10345414668021]},
    {input: [0.010625, 0.969444444444444], output: [0.0853421092394058]},
    {input: [0.055625, 0.108333333333333], output: [0.0762860905190039]},
    {input: [0.05, 0.980555555555556], output: [0.067230071798602]},
    {input: [0.03875, 0.525], output: [0.067230071798602]},
    {input: [0.05, 0.988888888888889], output: [0.0581740530782001]},
    {input: [0.0725, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.686111111111111], output: [0.0536460437179991]},
    {input: [0.111875, 0.730555555555556], output: [0.0400620156373962]},
    {input: [0.06125, 0.227777777777778], output: [0.0310059969169943]},
    {input: [0.190625, 0.202777777777778], output: [0.0400620156373962]},
    {input: [0.145625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.1175, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.12875, 0.4], output: [0.0808140998792049]},
    {input: [0.179375, 0.241666666666667], output: [0.062702062438401]},
    {input: [0.19625, 0.305555555555556], output: [0.071758081158803]},
    {input: [0.168125, 0.288888888888889], output: [0.0989261373200087]},
    {input: [0.134375, 0.252777777777778], output: [0.0989261373200087]},
    {input: [0.12875, 0.358333333333333], output: [0.117038174760813]},
    {input: [0.145625, 0.336111111111111], output: [0.130622202841415]},
    {input: [0.1175, 0.355555555555556], output: [0.117038174760813]},
    {input: [0.100625, 0.322222222222222], output: [0.10345414668021]},
    {input: [0.066875, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.05, 0.227777777777778], output: [0.071758081158803]},
    {input: [0.033125, 0.105555555555556], output: [0.0581740530782001]},
    {input: [0.06125, 0.205555555555556], output: [0.0400620156373962]},
    {input: [0.033125, 0.972222222222222], output: [0.0400620156373962]},
    {input: [0.03875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.055625, 0.827777777777778], output: [0.0264779875567933]},
    {input: [0.044375, 0.777777777777778], output: [0.0491180343577981]},
    {input: [0.066875, 0.872222222222222], output: [0.0355340062771953]},
    {input: [0.1625, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.185, 0.875], output: [0.0310059969169943]},
    {input: [0.213125, 0.944444444444444], output: [0.0491180343577981]},
    {input: [0.1175, 0.866666666666667], output: [0.071758081158803]},
    {input: [0.168125, 0.8], output: [0.0581740530782001]},
    {input: [0.19625, 0.777777777777778], output: [0.10345414668021]},
    {input: [0.111875, 0.85], output: [0.0853421092394058]},
    {input: [0.145625, 0.736111111111111], output: [0.071758081158803]},
    {input: [0.156875, 0.75], output: [0.0762860905190039]},
    {input: [0.12875, 0.0277777777777778], output: [0.0853421092394058]},
    {input: [0.12875, 0.1], output: [0.0762860905190039]},
    {input: [0.044375, 0.161111111111111], output: [0.0491180343577981]},
    {input: [0.010625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.0275, 0.611111111111111], output: [0.0491180343577981]},
    {input: [0.01625, 0.0805555555555556], output: [0.0581740530782001]},
    {input: [0.044375, 0.0111111111111111], output: [0.0491180343577981]},
    {input: [0.05, 0.983333333333333], output: [0.0581740530782001]},
    {input: [0.044375, 0.230555555555556], output: [0.062702062438401]},
    {input: [0.03875, 0.252777777777778], output: [0.0355340062771953]},
    {input: [0.01625, 0.741666666666667], output: [0.0491180343577981]},
    {input: [0.0725, 0.683333333333333], output: [0.0355340062771953]},
    {input: [0.134375, 0.780555555555556], output: [0.0491180343577981]},
    {input: [0.095, 0.844444444444444], output: [0.0355340062771953]},
    {input: [0.1175, 0.811111111111111], output: [0.0400620156373962]},
    {input: [0.089375, 0.769444444444444], output: [0.0355340062771953]},
    {input: [0.10625, 0.763888888888889], output: [0.0310059969169943]},
    {input: [0.08375, 0.855555555555556], output: [0.0264779875567933]},
    {input: [0.123125, 0.966666666666667], output: [0.0264779875567933]},
    {input: [0.111875, 0.930555555555556], output: [0.0264779875567933]},
    {input: [0.2075, 0.808333333333333], output: [0.0174219688363914]},
    {input: [0.224375, 0.838888888888889], output: [0.0762860905190039]},
    {input: [0.235625, 0.85], output: [0.0943981279598077]},
    {input: [0.33125, 0.85], output: [0.135150212201616]},
    {input: [0.35375, 0.855555555555556], output: [0.184958315163827]},
    {input: [0.275, 0.816666666666667], output: [0.19854234324443]},
    {input: [0.235625, 0.819444444444444], output: [0.189486324524028]},
    {input: [0.201875, 0.861111111111111], output: [0.166846277723023]},
    {input: [0.224375, 0.869444444444444], output: [0.135150212201616]},
    {input: [0.258125, 0.841666666666667], output: [0.130622202841415]},
    {input: [0.291875, 0.811111111111111], output: [0.148734240282219]},
    {input: [0.325625, 0.797222222222222], output: [0.194014333884229]},
    {input: [0.26375, 0.805555555555556], output: [0.19854234324443]},
    {input: [0.2975, 0.836111111111111], output: [0.171374287083224]},
    {input: [0.258125, 0.825], output: [0.212126371325033]},
    {input: [0.24125, 0.830555555555556], output: [0.203070352604631]},
    {input: [0.2525, 0.813888888888889], output: [0.225710399405636]},
    {input: [0.2975, 0.813888888888889], output: [0.234766418126038]},
    {input: [0.23, 0.794444444444444], output: [0.252878455566841]},
    {input: [0.14, 0.747222222222222], output: [0.239294427486239]},
    {input: [0.269375, 0.825], output: [0.203070352604631]},
    {input: [0.28625, 0.822222222222222], output: [0.166846277723023]},
    {input: [0.275, 0.819444444444444], output: [0.230238408765837]},
    {input: [0.30875, 0.805555555555556], output: [0.252878455566841]},
    {input: [0.303125, 0.813888888888889], output: [0.24382243684644]},
    {input: [0.314375, 0.819444444444444], output: [0.24835044620664]},
    {input: [0.2975, 0.827777777777778], output: [0.225710399405636]},
    {input: [0.336875, 0.822222222222222], output: [0.270990493007645]},
    {input: [0.303125, 0.802777777777778], output: [0.252878455566841]},
    {input: [0.41, 0.830555555555556], output: [0.280046511728047]},
    {input: [0.381875, 0.841666666666667], output: [0.234766418126038]},
    {input: [0.348125, 0.85], output: [0.261934474287243]},
    {input: [0.336875, 0.863888888888889], output: [0.225710399405636]},
    {input: [0.336875, 0.861111111111111], output: [0.252878455566841]},
    {input: [0.269375, 0.830555555555556], output: [0.216654380685234]},
    {input: [0.23, 0.811111111111111], output: [0.207598361964832]},
    {input: [0.15125, 0.822222222222222], output: [0.184958315163827]},
    {input: [0.055625, 0.538888888888889], output: [0.107982156040411]},
    {input: [0.033125, 0.141666666666667], output: [0.10345414668021]},
    {input: [0.10625, 0.463888888888889], output: [0.067230071798602]},
    {input: [0.1175, 0.572222222222222], output: [0.0400620156373962]},
    {input: [0.078125, 0.625], output: [0.0491180343577981]},
    {input: [0.134375, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.100625, 0.597222222222222], output: [0.0400620156373962]},
    {input: [0.033125, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.01625, 0.719444444444444], output: [0.0355340062771953]},
    {input: [0.0275, 0.0694444444444444], output: [0.0310059969169943]},
    {input: [0.100625, 0.183333333333333], output: [0.0264779875567933]},
    {input: [0.10625, 0.141666666666667], output: [0.0310059969169943]},
    {input: [0.111875, 0.169444444444444], output: [0.0310059969169943]},
    {input: [0.089375, 0.186111111111111], output: [0.0355340062771953]},
    {input: [0.066875, 0.244444444444444], output: [0.0355340062771953]},
    {input: [0.055625, 0.188888888888889], output: [0.0400620156373962]},
    {input: [0.111875, 0.322222222222222], output: [0.0491180343577981]},
    {input: [0.190625, 0.213888888888889], output: [0.0310059969169943]},
    {input: [0.12875, 0.325], output: [0.0581740530782001]},
    {input: [0.156875, 0.255555555555556], output: [0.071758081158803]},
    {input: [0.156875, 0.355555555555556], output: [0.0536460437179991]},
    {input: [0.100625, 0.330555555555556], output: [0.0536460437179991]},
    {input: [0.111875, 0.258333333333333], output: [0.0491180343577981]},
    {input: [0.179375, 0.288888888888889], output: [0.0581740530782001]},
    {input: [0.123125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.1175, 0.444444444444444], output: [0.0310059969169943]},
    {input: [0.0725, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.12875, 0.122222222222222], output: [0.0355340062771953]},
    {input: [0.055625, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.066875, 0.302777777777778], output: [0.0264779875567933]},
    {input: [0.066875, 0.719444444444444], output: [0.0310059969169943]},
    {input: [0.033125, 0.0888888888888889], output: [0.0310059969169943]},
    {input: [0.17375, 0.138888888888889], output: [0.0310059969169943]},
    {input: [0.01625, 0.208333333333333], output: [0.071758081158803]},
    {input: [0.0725, 0.375], output: [0.0219499781965924]},
    {input: [0.078125, 0.136111111111111], output: [0.0264779875567933]},
    {input: [0.078125, 0.0972222222222222], output: [0.0536460437179991]},
    {input: [0.078125, 0.202777777777778], output: [0.0536460437179991]},
    {input: [0.095, 0.241666666666667], output: [0.0581740530782001]},
    {input: [0.17375, 0.319444444444444], output: [0.062702062438401]},
    {input: [0.17375, 0.336111111111111], output: [0.071758081158803]},
    {input: [0.17375, 0.394444444444444], output: [0.0762860905190039]},
    {input: [0.1175, 0.113888888888889], output: [0.0581740530782001]},
    {input: [0.2975, 0.166666666666667], output: [0.062702062438401]},
    {input: [0.41, 0.105555555555556], output: [0.0898701185996068]},
    {input: [0.426875, 0.102777777777778], output: [0.162318268362822]},
    {input: [0.348125, 0.130555555555556], output: [0.19854234324443]},
    {input: [0.314375, 0.122222222222222], output: [0.207598361964832]},
    {input: [0.28625, 0.127777777777778], output: [0.230238408765837]},
    {input: [0.3425, 0.119444444444444], output: [0.270990493007645]},
    {input: [0.314375, 0.116666666666667], output: [0.225710399405636]},
    {input: [0.33125, 0.108333333333333], output: [0.19854234324443]},
    {input: [0.325625, 0.113888888888889], output: [0.194014333884229]},
    {input: [0.4325, 0.119444444444444], output: [0.221182390045435]},
    {input: [0.505625, 0.119444444444444], output: [0.257406464927042]},
    {input: [0.460625, 0.113888888888889], output: [0.311742577249454]},
    {input: [0.3875, 0.105555555555556], output: [0.275518502367846]},
    {input: [0.3875, 0.130555555555556], output: [0.230238408765837]},
    {input: [0.5225, 0.141666666666667], output: [0.280046511728047]},
    {input: [0.53375, 0.138888888888889], output: [0.361550680211665]},
    {input: [0.5, 0.144444444444444], output: [0.352494661491263]},
    {input: [0.55625, 0.202777777777778], output: [0.424942811254478]},
    {input: [0.415625, 0.169444444444444], output: [0.379662717652468]},
    {input: [0.5225, 0.25], output: [0.397774755093272]},
    {input: [0.455, 0.297222222222222], output: [0.361550680211665]},
    {input: [0.59, 0.288888888888889], output: [0.461166886136086]},
    {input: [0.4775, 0.277777777777778], output: [0.384190727012669]},
    {input: [0.539375, 0.308333333333333], output: [0.402302764453473]},
    {input: [0.57875, 0.363888888888889], output: [0.497390961017693]},
    {input: [0.370625, 0.369444444444444], output: [0.411358783173875]},
    {input: [0.359375, 0.411111111111111], output: [0.375134708292267]},
    {input: [0.359375, 0.311111111111111], output: [0.307214567889253]},
    {input: [0.2075, 0.319444444444444], output: [0.275518502367846]},
    {input: [0.23, 0.472222222222222], output: [0.19854234324443]},
    {input: [0.21875, 0.575], output: [0.166846277723023]},
    {input: [0.201875, 0.630555555555556], output: [0.117038174760813]},
    {input: [0.134375, 0.466666666666667], output: [0.071758081158803]},
    {input: [0.246875, 0.491666666666667], output: [0.067230071798602]},
    {input: [0.201875, 0.361111111111111], output: [0.062702062438401]},
    {input: [0.39875, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.30875, 0.3], output: [0.130622202841415]},
    {input: [0.426875, 0.316666666666667], output: [0.184958315163827]},
    {input: [0.33125, 0.358333333333333], output: [0.203070352604631]},
    {input: [0.3875, 0.275], output: [0.19854234324443]},
    {input: [0.460625, 0.308333333333333], output: [0.221182390045435]},
    {input: [0.48875, 0.305555555555556], output: [0.225710399405636]},
    {input: [0.41, 0.327777777777778], output: [0.24382243684644]},
    {input: [0.505625, 0.330555555555556], output: [0.261934474287243]},
    {input: [0.46625, 0.344444444444444], output: [0.298158549168851]},
    {input: [0.5, 0.416666666666667], output: [0.316270586609655]},
    {input: [0.460625, 0.416666666666667], output: [0.298158549168851]},
    {input: [0.3875, 0.361111111111111], output: [0.316270586609655]},
    {input: [0.455, 0.394444444444444], output: [0.284574521088248]},
    {input: [0.3875, 0.327777777777778], output: [0.298158549168851]},
    {input: [0.35375, 0.280555555555556], output: [0.302686558529052]},
    {input: [0.539375, 0.391666666666667], output: [0.320798595969856]},
    {input: [0.42125, 0.413888888888889], output: [0.24835044620664]},
    {input: [0.3425, 0.483333333333333], output: [0.207598361964832]},
    {input: [0.39875, 0.488888888888889], output: [0.139678221561817]},
    {input: [0.30875, 0.480555555555556], output: [0.148734240282219]},
    {input: [0.30875, 0.413888888888889], output: [0.157790259002621]},
    {input: [0.35375, 0.505555555555556], output: [0.148734240282219]},
    {input: [0.30875, 0.458333333333333], output: [0.148734240282219]},
    {input: [0.39875, 0.513888888888889], output: [0.162318268362822]},
    {input: [0.460625, 0.597222222222222], output: [0.162318268362822]},
    {input: [0.449375, 0.577777777777778], output: [0.180430305803626]},
    {input: [0.365, 0.580555555555556], output: [0.144206230922018]},
    {input: [0.404375, 0.513888888888889], output: [0.117038174760813]},
    {input: [0.348125, 0.494444444444444], output: [0.148734240282219]},
    {input: [0.381875, 0.372222222222222], output: [0.15326224964242]},
    {input: [0.415625, 0.461111111111111], output: [0.189486324524028]},
    {input: [0.48875, 0.427777777777778], output: [0.19854234324443]},
    {input: [0.393125, 0.386111111111111], output: [0.212126371325033]},
    {input: [0.516875, 0.413888888888889], output: [0.24835044620664]},
    {input: [0.39875, 0.341666666666667], output: [0.230238408765837]},
    {input: [0.42125, 0.352777777777778], output: [0.230238408765837]},
    {input: [0.505625, 0.425], output: [0.298158549168851]},
    {input: [0.41, 0.383333333333333], output: [0.298158549168851]},
    {input: [0.39875, 0.380555555555556], output: [0.298158549168851]},
    {input: [0.438125, 0.408333333333333], output: [0.280046511728047]},
    {input: [0.460625, 0.430555555555556], output: [0.212126371325033]},
    {input: [0.460625, 0.45], output: [0.207598361964832]},
    {input: [0.314375, 0.388888888888889], output: [0.207598361964832]},
    {input: [0.314375, 0.383333333333333], output: [0.175902296443425]},
    {input: [0.42125, 0.475], output: [0.175902296443425]},
    {input: [0.41, 0.422222222222222], output: [0.180430305803626]},
    {input: [0.26375, 0.336111111111111], output: [0.148734240282219]},
    {input: [0.303125, 0.297222222222222], output: [0.135150212201616]},
    {input: [0.303125, 0.286111111111111], output: [0.162318268362822]},
    {input: [0.19625, 0.219444444444444], output: [0.203070352604631]},
    {input: [0.32, 0.0972222222222222], output: [0.207598361964832]},
    {input: [0.325625, 0.0944444444444444], output: [0.230238408765837]},
    {input: [0.303125, 0.141666666666667], output: [0.203070352604631]},
    {input: [0.359375, 0.122222222222222], output: [0.216654380685234]},
    {input: [0.23, 0.130555555555556], output: [0.171374287083224]},
    {input: [0.19625, 0.188888888888889], output: [0.139678221561817]},
    {input: [0.123125, 0.208333333333333], output: [0.117038174760813]},
    {input: [0.12875, 0.0638888888888889], output: [0.0989261373200087]},
    {input: [0.0725, 0.15], output: [0.0808140998792049]},
    {input: [0.179375, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.4325, 0.858333333333333], output: [0.207598361964832]},
    {input: [0.393125, 0.841666666666667], output: [0.270990493007645]},
    {input: [0.42125, 0.827777777777778], output: [0.252878455566841]},
    {input: [0.325625, 0.794444444444444], output: [0.261934474287243]},
    {input: [0.3875, 0.752777777777778], output: [0.180430305803626]},
    {input: [0.33125, 0.741666666666667], output: [0.239294427486239]},
    {input: [0.258125, 0.716666666666667], output: [0.266462483647444]},
    {input: [0.2075, 0.661111111111111], output: [0.261934474287243]},
    {input: [0.185, 0.569444444444444], output: [0.189486324524028]},
    {input: [0.1625, 0.494444444444444], output: [0.126094193481215]},
    {input: [0.1175, 0.466666666666667], output: [0.107982156040411]},
    {input: [0.269375, 0.408333333333333], output: [0.0898701185996068]},
    {input: [0.179375, 0.380555555555556], output: [0.0989261373200087]},
    {input: [0.095, 0.605555555555556], output: [0.062702062438401]},
    {input: [0.078125, 0.444444444444444], output: [0.062702062438401]},
    {input: [0.145625, 0.502777777777778], output: [0.0491180343577981]},
    {input: [0.404375, 0.475], output: [0.117038174760813]},
    {input: [0.404375, 0.397222222222222], output: [0.148734240282219]},
    {input: [0.336875, 0.333333333333333], output: [0.175902296443425]},
    {input: [0.44375, 0.347222222222222], output: [0.203070352604631]},
    {input: [0.449375, 0.319444444444444], output: [0.230238408765837]},
    {input: [0.573125, 0.294444444444444], output: [0.29363053980865]},
    {input: [0.51125, 0.227777777777778], output: [0.379662717652468]},
    {input: [0.62375, 0.222222222222222], output: [0.429470820614679]},
    {input: [0.483125, 0.216666666666667], output: [0.420414801894277]},
    {input: [0.37625, 0.225], output: [0.393246745733071]},
    {input: [0.370625, 0.258333333333333], output: [0.311742577249454]},
    {input: [0.24125, 0.338888888888889], output: [0.252878455566841]},
    {input: [0.15125, 0.638888888888889], output: [0.230238408765837]},
    {input: [0.2975, 0.0916666666666667], output: [0.234766418126038]},
    {input: [0.10625, 0.0388888888888889], output: [0.19854234324443]},
    {input: [0.179375, 0.05], output: [0.162318268362822]},
    {input: [0.123125, 0.141666666666667], output: [0.121566184121014]},
    {input: [0.078125, 0.238888888888889], output: [0.107982156040411]},
    {input: [0.2075, 0.130555555555556], output: [0.0853421092394058]},
    {input: [0.280625, 0.15], output: [0.067230071798602]},
    {input: [0.26375, 0.141666666666667], output: [0.0943981279598077]},
    {input: [0.336875, 0.147222222222222], output: [0.0762860905190039]},
    {input: [0.325625, 0.163888888888889], output: [0.144206230922018]},
    {input: [0.235625, 0.344444444444444], output: [0.10345414668021]},
    {input: [0.303125, 0.388888888888889], output: [0.0808140998792049]},
    {input: [0.258125, 0.302777777777778], output: [0.0808140998792049]},
    {input: [0.37625, 0.383333333333333], output: [0.130622202841415]},
    {input: [0.359375, 0.347222222222222], output: [0.144206230922018]},
    {input: [0.404375, 0.288888888888889], output: [0.175902296443425]},
    {input: [0.460625, 0.297222222222222], output: [0.257406464927042]},
    {input: [0.404375, 0.263888888888889], output: [0.24382243684644]},
    {input: [0.37625, 0.238888888888889], output: [0.225710399405636]},
    {input: [0.426875, 0.227777777777778], output: [0.298158549168851]},
    {input: [0.381875, 0.194444444444444], output: [0.284574521088248]},
    {input: [0.4325, 0.169444444444444], output: [0.270990493007645]},
    {input: [0.438125, 0.116666666666667], output: [0.275518502367846]},
    {input: [0.528125, 0.141666666666667], output: [0.302686558529052]},
    {input: [0.449375, 0.130555555555556], output: [0.33891063341066]},
    {input: [0.438125, 0.127777777777778], output: [0.343438642770861]},
    {input: [0.415625, 0.108333333333333], output: [0.325326605330057]},
    {input: [0.2975, 0.0888888888888889], output: [0.239294427486239]},
    {input: [0.235625, 0.0944444444444444], output: [0.19854234324443]},
    {input: [0.17375, 0.105555555555556], output: [0.148734240282219]},
    {input: [0.1625, 0.0972222222222222], output: [0.157790259002621]},
    {input: [0.0725, 0.155555555555556], output: [0.139678221561817]},
    {input: [0.066875, 0.916666666666667], output: [0.107982156040411]},
    {input: [0.06125, 0.791666666666667], output: [0.0853421092394058]},
    {input: [0.134375, 0.761111111111111], output: [0.0581740530782001]},
    {input: [0.168125, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.269375, 0.666666666666667], output: [0.067230071798602]},
    {input: [0.30875, 0.788888888888889], output: [0.112510165400612]},
    {input: [0.2975, 0.763888888888889], output: [0.24835044620664]},
    {input: [0.246875, 0.619444444444444], output: [0.234766418126038]},
    {input: [0.201875, 0.644444444444444], output: [0.239294427486239]},
    {input: [0.201875, 0.644444444444444], output: [0.194014333884229]},
    {input: [0.275, 0.7], output: [0.189486324524028]},
    {input: [0.235625, 0.727777777777778], output: [0.189486324524028]},
    {input: [0.275, 0.777777777777778], output: [0.194014333884229]},
    {input: [0.393125, 0.827777777777778], output: [0.280046511728047]},
    {input: [0.3425, 0.786111111111111], output: [0.257406464927042]},
    {input: [0.303125, 0.702777777777778], output: [0.234766418126038]},
    {input: [0.32, 0.708333333333333], output: [0.212126371325033]},
    {input: [0.2525, 0.65], output: [0.203070352604631]},
    {input: [0.365, 0.716666666666667], output: [0.19854234324443]},
    {input: [0.41, 0.716666666666667], output: [0.221182390045435]},
    {input: [0.37625, 0.713888888888889], output: [0.239294427486239]},
    {input: [0.37625, 0.766666666666667], output: [0.24382243684644]},
    {input: [0.325625, 0.752777777777778], output: [0.24835044620664]},
    {input: [0.30875, 0.802777777777778], output: [0.216654380685234]},
    {input: [0.246875, 0.825], output: [0.207598361964832]},
    {input: [0.2525, 0.844444444444444], output: [0.166846277723023]},
    {input: [0.303125, 0.777777777777778], output: [0.148734240282219]},
    {input: [0.2975, 0.863888888888889], output: [0.184958315163827]},
    {input: [0.258125, 0.877777777777778], output: [0.121566184121014]},
    {input: [0.246875, 0.858333333333333], output: [0.121566184121014]},
    {input: [0.23, 0.861111111111111], output: [0.0989261373200087]},
    {input: [0.134375, 0.0222222222222222], output: [0.0808140998792049]},
    {input: [0.14, 0.988888888888889], output: [0.0762860905190039]},
    {input: [0.055625, 0.941666666666667], output: [0.067230071798602]},
    {input: [0.05, 0.961111111111111], output: [0.0581740530782001]},
    {input: [0.10625, 0.763888888888889], output: [0.0581740530782001]},
    {input: [0.10625, 0.844444444444444], output: [0.0536460437179991]},
    {input: [0.111875, 0.888888888888889], output: [0.0400620156373962]},
    {input: [0.179375, 0.85], output: [0.0491180343577981]},
    {input: [0.168125, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.156875, 0.863888888888889], output: [0.0491180343577981]},
    {input: [0.145625, 0.813888888888889], output: [0.062702062438401]},
    {input: [0.055625, 0.658333333333333], output: [0.0536460437179991]},
    {input: [0.06125, 0.744444444444444], output: [0.0762860905190039]},
    {input: [0.021875, 0.727777777777778], output: [0.0491180343577981]},
    {input: [0.05, 0.577777777777778], output: [0.0491180343577981]},
    {input: [0.055625, 0.341666666666667], output: [0.0400620156373962]},
    {input: [0.0725, 0.552777777777778], output: [0.0264779875567933]},
    {input: [0.078125, 0.430555555555556], output: [0.0264779875567933]},
    {input: [0.0725, 0.436111111111111], output: [0.0310059969169943]},
    {input: [0.145625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.185, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.179375, 0.186111111111111], output: [0.0264779875567933]},
    {input: [0.201875, 0.194444444444444], output: [0.0264779875567933]},
    {input: [0.17375, 0.127777777777778], output: [0.062702062438401]},
    {input: [0.24125, 0.183333333333333], output: [0.0355340062771953]},
    {input: [0.2525, 0.241666666666667], output: [0.0355340062771953]},
    {input: [0.201875, 0.25], output: [0.0853421092394058]},
    {input: [0.235625, 0.225], output: [0.0536460437179991]},
    {input: [0.235625, 0.225], output: [0.0762860905190039]},
    {input: [0.26375, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.21875, 0.225], output: [0.0898701185996068]},
    {input: [0.235625, 0.283333333333333], output: [0.0989261373200087]},
    {input: [0.2075, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.213125, 0.230555555555556], output: [0.0762860905190039]},
    {input: [0.246875, 0.236111111111111], output: [0.0989261373200087]},
    {input: [0.201875, 0.341666666666667], output: [0.0943981279598077]},
    {input: [0.19625, 0.255555555555556], output: [0.107982156040411]},
    {input: [0.2075, 0.161111111111111], output: [0.071758081158803]},
    {input: [0.291875, 0.161111111111111], output: [0.0943981279598077]},
    {input: [0.291875, 0.180555555555556], output: [0.117038174760813]},
    {input: [0.348125, 0.183333333333333], output: [0.0989261373200087]},
    {input: [0.275, 0.180555555555556], output: [0.107982156040411]},
    {input: [0.33125, 0.202777777777778], output: [0.135150212201616]},
    {input: [0.2525, 0.258333333333333], output: [0.135150212201616]},
    {input: [0.3875, 0.375], output: [0.203070352604631]},
    {input: [0.460625, 0.363888888888889], output: [0.252878455566841]},
    {input: [0.51125, 0.425], output: [0.252878455566841]},
    {input: [0.561875, 0.805555555555556], output: [0.175902296443425]},
    {input: [0.640625, 0.780555555555556], output: [0.397774755093272]},
    {input: [0.62375, 0.772222222222222], output: [0.443054848695282]},
    {input: [0.545, 0.763888888888889], output: [0.524559017178899]},
    {input: [0.674375, 0.775], output: [0.592479157581914]},
    {input: [0.696875, 0.786111111111111], output: [0.696623372866536]},
    {input: [0.71375, 0.786111111111111], output: [0.741903466468545]},
    {input: [0.798125, 0.772222222222222], output: [0.750959485188947]},
    {input: [0.764375, 0.780555555555556], output: [0.769071522629751]},
    {input: [0.730625, 0.797222222222222], output: [0.728319438387942]},
    {input: [0.7025, 0.8], output: [0.963775925118392]},
    {input: [0.831875, 0.805555555555556], output: [0.923023840876584]},
    {input: [0.815, 0.816666666666667], output: [0.882271756634775]},
    {input: [0.764375, 0.813888888888889], output: [0.846047681753167]},
    {input: [0.786875, 0.816666666666667], output: [0.782655550710354]},
    {input: [0.708125, 0.816666666666667], output: [0.723791429027742]},
    {input: [0.55625, 0.827777777777778], output: [0.660399297984928]},
    {input: [0.53375, 0.819444444444444], output: [0.587951148221713]},
    {input: [0.584375, 0.822222222222222], output: [0.583423138861512]},
    {input: [0.55625, 0.841666666666667], output: [0.5290870265391]},
    {input: [0.4325, 0.861111111111111], output: [0.397774755093272]},
    {input: [0.359375, 0.866666666666667], output: [0.329854614690258]},
    {input: [0.438125, 0.875], output: [0.320798595969856]},
    {input: [0.35375, 0.883333333333333], output: [0.270990493007645]},
    {input: [0.26375, 0.894444444444444], output: [0.203070352604631]},
    {input: [0.42125, 0.877777777777778], output: [0.280046511728047]},
    {input: [0.48875, 0.872222222222222], output: [0.302686558529052]},
    {input: [0.606875, 0.841666666666667], output: [0.415886792534076]},
    {input: [0.62375, 0.838888888888889], output: [0.515502998458497]},
    {input: [0.573125, 0.847222222222222], output: [0.488334942297291]},
    {input: [0.449375, 0.841666666666667], output: [0.379662717652468]},
    {input: [0.42125, 0.855555555555556], output: [0.343438642770861]},
    {input: [0.35375, 0.838888888888889], output: [0.307214567889253]},
    {input: [0.39875, 0.855555555555556], output: [0.298158549168851]},
    {input: [0.28625, 0.861111111111111], output: [0.230238408765837]},
    {input: [0.33125, 0.894444444444444], output: [0.171374287083224]},
    {input: [0.05, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.1625, 0.761111111111111], output: [0.130622202841415]},
    {input: [0.213125, 0.8], output: [0.135150212201616]},
    {input: [0.280625, 0.833333333333333], output: [0.126094193481215]},
    {input: [0.325625, 0.852777777777778], output: [0.107982156040411]},
    {input: [0.201875, 0.816666666666667], output: [0.112510165400612]},
    {input: [0.23, 0.838888888888889], output: [0.112510165400612]},
    {input: [0.21875, 0.808333333333333], output: [0.10345414668021]},
    {input: [0.111875, 0.755555555555556], output: [0.10345414668021]},
    {input: [0.145625, 0.725], output: [0.0898701185996068]},
    {input: [0.17375, 0.813888888888889], output: [0.0762860905190039]},
    {input: [0.2525, 0.841666666666667], output: [0.0853421092394058]},
    {input: [0.168125, 0.0194444444444444], output: [0.0762860905190039]},
    {input: [0.066875, 0.775], output: [0.0536460437179991]},
    {input: [0.14, 0.833333333333333], output: [0.0943981279598077]},
    {input: [0.17375, 0.863888888888889], output: [0.10345414668021]},
    {input: [0.12875, 0.875], output: [0.067230071798602]},
    {input: [0.089375, 0.836111111111111], output: [0.0491180343577981]},
    {input: [0.100625, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.12875, 0.833333333333333], output: [0.0491180343577981]},
    {input: [0.17375, 0.825], output: [0.062702062438401]},
    {input: [0.1175, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.095, 0.475], output: [0.0943981279598077]},
    {input: [0.055625, 0.483333333333333], output: [0.10345414668021]},
    {input: [0.111875, 0.583333333333333], output: [0.0989261373200087]},
    {input: [0.111875, 0.625], output: [0.0989261373200087]},
    {input: [0.145625, 0.769444444444444], output: [0.0808140998792049]},
    {input: [0.224375, 0.661111111111111], output: [0.0853421092394058]},
    {input: [0.201875, 0.711111111111111], output: [0.0581740530782001]},
    {input: [0.21875, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.235625, 0.797222222222222], output: [0.0581740530782001]},
    {input: [0.100625, 0.997222222222222], output: [0.0491180343577981]},
    {input: [0.111875, 0.0722222222222222], output: [0.0400620156373962]},
    {input: [0.089375, 0.147222222222222], output: [0.062702062438401]},
    {input: [0.134375, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.12875, 0.130555555555556], output: [0.0989261373200087]},
    {input: [0.0725, 0.0972222222222222], output: [0.0853421092394058]},
    {input: [0.12875, 0.1], output: [0.0898701185996068]},
    {input: [0.01625, 0.280555555555556], output: [0.0762860905190039]},
    {input: [0.055625, 0.383333333333333], output: [0.0898701185996068]},
    {input: [0.01625, 0.980555555555556], output: [0.071758081158803]},
    {input: [0.314375, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.35375, 0.855555555555556], output: [0.184958315163827]},
    {input: [0.30875, 0.863888888888889], output: [0.180430305803626]},
    {input: [0.37625, 0.855555555555556], output: [0.234766418126038]},
    {input: [0.336875, 0.847222222222222], output: [0.270990493007645]},
    {input: [0.32, 0.852777777777778], output: [0.275518502367846]},
    {input: [0.185, 0.841666666666667], output: [0.24382243684644]},
    {input: [0.185, 0.916666666666667], output: [0.184958315163827]},
    {input: [0.12875, 0.897222222222222], output: [0.144206230922018]},
    {input: [0.12875, 0.222222222222222], output: [0.107982156040411]},
    {input: [0.37625, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.55625, 0.147222222222222], output: [0.189486324524028]},
    {input: [0.59, 0.15], output: [0.438526839335081]},
    {input: [0.573125, 0.163888888888889], output: [0.443054848695282]},
    {input: [0.60125, 0.136111111111111], output: [0.456638876775885]},
    {input: [0.516875, 0.158333333333333], output: [0.406830773813674]},
    {input: [0.483125, 0.194444444444444], output: [0.361550680211665]},
    {input: [0.53375, 0.180555555555556], output: [0.366078689571866]},
    {input: [0.483125, 0.197222222222222], output: [0.343438642770861]},
    {input: [0.455, 0.238888888888889], output: [0.302686558529052]},
    {input: [0.5225, 0.25], output: [0.429470820614679]},
    {input: [0.370625, 0.25], output: [0.424942811254478]},
    {input: [0.258125, 0.341666666666667], output: [0.280046511728047]},
    {input: [0.258125, 0.522222222222222], output: [0.184958315163827]},
    {input: [0.190625, 0.466666666666667], output: [0.135150212201616]},
    {input: [0.12875, 0.402777777777778], output: [0.112510165400612]},
    {input: [0.0725, 0.216666666666667], output: [0.071758081158803]},
    {input: [0.08375, 0.219444444444444], output: [0.0762860905190039]},
    {input: [0.10625, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.252777777777778], output: [0.0400620156373962]},
    {input: [0.08375, 0.291666666666667], output: [0.0355340062771953]},
    {input: [0.0725, 0.233333333333333], output: [0.0310059969169943]},
    {input: [0.12875, 0.0944444444444444], output: [0.0400620156373962]},
    {input: [0.134375, 0.125], output: [0.0355340062771953]},
    {input: [0.134375, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.168125, 0.0861111111111111], output: [0.0264779875567933]},
    {input: [0.134375, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.123125, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.130555555555556], output: [0.0400620156373962]},
    {input: [0.123125, 0.0833333333333333], output: [0.0400620156373962]},
    {input: [0.08375, 0.138888888888889], output: [0.0491180343577981]},
    {input: [0.033125, 0.0111111111111111], output: [0.071758081158803]},
    {input: [0.078125, 0.947222222222222], output: [0.0536460437179991]},
    {input: [0.044375, 0.819444444444444], output: [0.0491180343577981]},
    {input: [0.055625, 0.808333333333333], output: [0.0808140998792049]},
    {input: [0.021875, 0.972222222222222], output: [0.067230071798602]},
    {input: [0.100625, 0.769444444444444], output: [0.071758081158803]},
    {input: [0.095, 0.833333333333333], output: [0.062702062438401]},
    {input: [0.10625, 0.825], output: [0.062702062438401]},
    {input: [0.123125, 0.9], output: [0.0581740530782001]},
    {input: [0.08375, 0.869444444444444], output: [0.0400620156373962]},
    {input: [0.01625, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.01625, 0.5], output: [0.0536460437179991]},
    {input: [0.066875, 0.444444444444444], output: [0.0400620156373962]},
    {input: [0.111875, 0.505555555555556], output: [0.0491180343577981]},
    {input: [0.055625, 0.558333333333333], output: [0.0491180343577981]},
    {input: [0.033125, 0.625], output: [0.0491180343577981]},
    {input: [0.089375, 0.661111111111111], output: [0.0400620156373962]},
    {input: [0.10625, 0.680555555555556], output: [0.0400620156373962]},
    {input: [0.066875, 0.541666666666667], output: [0.0264779875567933]},
    {input: [0.134375, 0.572222222222222], output: [0.0264779875567933]},
    {input: [0.078125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.095, 0.291666666666667], output: [0.0310059969169943]},
    {input: [0.033125, 0.294444444444444], output: [0.0355340062771953]},
    {input: [0.044375, 0.125], output: [0.0355340062771953]},
    {input: [0.17375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.177777777777778], output: [0.0581740530782001]},
    {input: [0.185, 0.202777777777778], output: [0.0536460437179991]},
    {input: [0.123125, 0.208333333333333], output: [0.0581740530782001]},
    {input: [0.123125, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.0725, 0.211111111111111], output: [0.0491180343577981]},
    {input: [0.08375, 0.344444444444444], output: [0.0400620156373962]},
    {input: [0.0725, 0.338888888888889], output: [0.0491180343577981]},
    {input: [0.055625, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.033125, 0.516666666666667], output: [0.0400620156373962]},
    {input: [0.021875, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.010625, 0.266666666666667], output: [0.0264779875567933]},
    {input: [0.01625, 0.497222222222222], output: [0.0310059969169943]},
    {input: [0.06125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.095, 0.836111111111111], output: [0.0400620156373962]},
    {input: [0.10625, 0.897222222222222], output: [0.0310059969169943]},
    {input: [0.123125, 0.936111111111111], output: [0.0264779875567933]},
    {input: [0.17375, 0.969444444444444], output: [0.0355340062771953]},
    {input: [0.179375, 0.0416666666666667], output: [0.0581740530782001]},
    {input: [0.100625, 0.0277777777777778], output: [0.0264779875567933]},
    {input: [0.033125, 0.861111111111111], output: [0.0400620156373962]},
    {input: [0.055625, 0.694444444444444], output: [0.0355340062771953]},
    {input: [0.089375, 0.869444444444444], output: [0.0536460437179991]},
    {input: [0.123125, 0.869444444444444], output: [0.0581740530782001]},
    {input: [0.21875, 0.913888888888889], output: [0.0400620156373962]},
    {input: [0.145625, 0.975], output: [0.0536460437179991]},
    {input: [0.2075, 0.969444444444444], output: [0.0536460437179991]},
    {input: [0.12875, 0.958333333333333], output: [0.0581740530782001]},
    {input: [0.1625, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.134375, 0.886111111111111], output: [0.071758081158803]},
    {input: [0.19625, 0.872222222222222], output: [0.0581740530782001]},
    {input: [0.134375, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.190625, 0.869444444444444], output: [0.067230071798602]},
    {input: [0.190625, 0.877777777777778], output: [0.0853421092394058]},
    {input: [0.2525, 0.858333333333333], output: [0.139678221561817]},
    {input: [0.2075, 0.841666666666667], output: [0.162318268362822]},
    {input: [0.24125, 0.855555555555556], output: [0.175902296443425]},
    {input: [0.2525, 0.830555555555556], output: [0.203070352604631]},
    {input: [0.185, 0.794444444444444], output: [0.189486324524028]},
    {input: [0.213125, 0.836111111111111], output: [0.166846277723023]},
    {input: [0.246875, 0.852777777777778], output: [0.157790259002621]},
    {input: [0.19625, 0.852777777777778], output: [0.130622202841415]},
    {input: [0.100625, 0.719444444444444], output: [0.117038174760813]},
    {input: [0.066875, 0.733333333333333], output: [0.071758081158803]},
    {input: [0.066875, 0.0277777777777778], output: [0.0536460437179991]},
    {input: [0.033125, 0.813888888888889], output: [0.0491180343577981]},
    {input: [0.044375, 0.65], output: [0.0400620156373962]},
    {input: [0.033125, 0.852777777777778], output: [0.0536460437179991]},
    {input: [0.0275, 0.255555555555556], output: [0.0310059969169943]},
    {input: [0.0725, 0.113888888888889], output: [0.0264779875567933]},
    {input: [0.033125, 0.775], output: [0.0219499781965924]},
    {input: [0.055625, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1175, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.03875, 0.736111111111111], output: [0.0310059969169943]},
    {input: [0.0275, 0.908333333333333], output: [0.0355340062771953]},
    {input: [0.05, 0.708333333333333], output: [0.0264779875567933]},
    {input: [0.0275, 0.661111111111111], output: [0.0264779875567933]},
    {input: [0.05, 0.766666666666667], output: [0.0400620156373962]},
    {input: [0.078125, 0.852777777777778], output: [0.0355340062771953]},
    {input: [0.06125, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.0725, 0.1], output: [0.0898701185996068]},
    {input: [0.03875, 0.736111111111111], output: [0.0808140998792049]},
    {input: [0.089375, 0.536111111111111], output: [0.0581740530782001]},
    {input: [0.089375, 0.586111111111111], output: [0.0491180343577981]},
    {input: [0.17375, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.089375, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.134375, 0.0833333333333333], output: [0.0400620156373962]},
    {input: [0.095, 0.133333333333333], output: [0.0310059969169943]},
    {input: [0.156875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.144444444444444], output: [0.0264779875567933]},
    {input: [0.100625, 0.133333333333333], output: [0.0310059969169943]},
    {input: [0.111875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.125], output: [0.0174219688363914]},
    {input: [0.095, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.066875, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.044375, 0.291666666666667], output: [0.0581740530782001]},
    {input: [0.01625, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.055625, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.033125, 0.625], output: [0.0264779875567933]},
    {input: [0.055625, 0.838888888888889], output: [0.0310059969169943]},
    {input: [0.010625, 0.322222222222222], output: [0.0400620156373962]},
    {input: [0.0275, 0.433333333333333], output: [0.0264779875567933]},
    {input: [0.066875, 0.230555555555556], output: [0.0400620156373962]},
    {input: [0.08375, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.145625, 0.602777777777778], output: [0.0264779875567933]},
    {input: [0.078125, 0.6], output: [0.0355340062771953]},
    {input: [0.033125, 0.194444444444444], output: [0.0355340062771953]},
    {input: [0.05, 0.497222222222222], output: [0.0310059969169943]},
    {input: [0.111875, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.111875, 0.111111111111111], output: [0.0355340062771953]},
    {input: [0.089375, 0.169444444444444], output: [0.0310059969169943]},
    {input: [0.156875, 0.111111111111111], output: [0.0310059969169943]},
    {input: [0.1175, 0.136111111111111], output: [0.0400620156373962]},
    {input: [0.179375, 0.1], output: [0.0219499781965924]},
    {input: [0.145625, 0.133333333333333], output: [0.0355340062771953]},
    {input: [0.1625, 0.102777777777778], output: [0.0310059969169943]},
    {input: [0.123125, 0.116666666666667], output: [0.0491180343577981]},
    {input: [0.066875, 0.355555555555556], output: [0.0310059969169943]},
    {input: [0.089375, 0.163888888888889], output: [0.0355340062771953]},
    {input: [0.055625, 0.305555555555556], output: [0.0355340062771953]},
    {input: [0.078125, 0.869444444444444], output: [0.0491180343577981]},
    {input: [0.0275, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.05, 0.880555555555556], output: [0.0355340062771953]},
    {input: [0.089375, 0.547222222222222], output: [0.0400620156373962]},
    {input: [0.066875, 0.391666666666667], output: [0.0310059969169943]},
    {input: [0.089375, 0.419444444444444], output: [0.0310059969169943]},
    {input: [0.078125, 0.391666666666667], output: [0.0264779875567933]},
    {input: [0.0725, 0.419444444444444], output: [0.0264779875567933]},
    {input: [0.100625, 0.125], output: [0.0264779875567933]},
    {input: [0.0725, 0.216666666666667], output: [0.0174219688363914]},
    {input: [0.066875, 0.141666666666667], output: [0.0310059969169943]},
    {input: [0.12875, 0.113888888888889], output: [0.0310059969169943]},
    {input: [0.145625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.089375, 0.102777777777778], output: [0.0310059969169943]},
    {input: [0.055625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.08375, 0.161111111111111], output: [0.0264779875567933]},
    {input: [0.033125, 0.025], output: [0.0219499781965924]},
    {input: [0.100625, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.06125, 0.0277777777777778], output: [0.0174219688363914]},
    {input: [0.078125, 0.0694444444444444], output: [0.0264779875567933]},
    {input: [0.066875, 0.05], output: [0.0264779875567933]},
    {input: [0.066875, 0.122222222222222], output: [0.0264779875567933]},
    {input: [0.033125, 0.6], output: [0.0310059969169943]},
    {input: [0.033125, 0.0194444444444444], output: [0.0310059969169943]},
    {input: [0.078125, 0.827777777777778], output: [0.0491180343577981]},
    {input: [0.055625, 0.797222222222222], output: [0.0310059969169943]},
    {input: [0.14, 0.708333333333333], output: [0.0310059969169943]},
    {input: [0.111875, 0.836111111111111], output: [0.0400620156373962]},
    {input: [0.201875, 0.855555555555556], output: [0.0264779875567933]},
    {input: [0.190625, 0.811111111111111], output: [0.0491180343577981]},
    {input: [0.21875, 0.838888888888889], output: [0.0310059969169943]},
    {input: [0.201875, 0.825], output: [0.062702062438401]},
    {input: [0.24125, 0.8], output: [0.0989261373200087]},
    {input: [0.246875, 0.783333333333333], output: [0.117038174760813]},
    {input: [0.235625, 0.811111111111111], output: [0.117038174760813]},
    {input: [0.190625, 0.877777777777778], output: [0.117038174760813]},
    {input: [0.156875, 0.869444444444444], output: [0.0762860905190039]},
    {input: [0.21875, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.269375, 0.908333333333333], output: [0.121566184121014]},
    {input: [0.32, 0.866666666666667], output: [0.157790259002621]},
    {input: [0.3425, 0.861111111111111], output: [0.175902296443425]},
    {input: [0.381875, 0.861111111111111], output: [0.266462483647444]},
    {input: [0.426875, 0.855555555555556], output: [0.325326605330057]},
    {input: [0.41, 0.855555555555556], output: [0.361550680211665]},
    {input: [0.41, 0.852777777777778], output: [0.347966652131062]},
    {input: [0.460625, 0.830555555555556], output: [0.334382624050459]},
    {input: [0.348125, 0.852777777777778], output: [0.329854614690258]},
    {input: [0.348125, 0.830555555555556], output: [0.275518502367846]},
    {input: [0.370625, 0.827777777777778], output: [0.252878455566841]},
    {input: [0.41, 0.844444444444444], output: [0.289102530448449]},
    {input: [0.404375, 0.844444444444444], output: [0.325326605330057]},
    {input: [0.3425, 0.844444444444444], output: [0.302686558529052]},
    {input: [0.348125, 0.852777777777778], output: [0.284574521088248]},
    {input: [0.314375, 0.844444444444444], output: [0.261934474287243]},
    {input: [0.33125, 0.813888888888889], output: [0.252878455566841]},
    {input: [0.224375, 0.802777777777778], output: [0.216654380685234]},
    {input: [0.32, 0.8], output: [0.189486324524028]},
    {input: [0.269375, 0.791666666666667], output: [0.184958315163827]},
    {input: [0.201875, 0.797222222222222], output: [0.157790259002621]},
    {input: [0.179375, 0.775], output: [0.126094193481215]},
    {input: [0.17375, 0.719444444444444], output: [0.10345414668021]},
    {input: [0.10625, 0.691666666666667], output: [0.0898701185996068]},
    {input: [0.10625, 0.8], output: [0.0808140998792049]},
    {input: [0.095, 0.738888888888889], output: [0.062702062438401]},
    {input: [0.0725, 0.694444444444444], output: [0.0536460437179991]},
    {input: [0.089375, 0.777777777777778], output: [0.0581740530782001]},
    {input: [0.145625, 0.783333333333333], output: [0.0400620156373962]},
    {input: [0.078125, 0.738888888888889], output: [0.0400620156373962]},
    {input: [0.066875, 0.866666666666667], output: [0.0355340062771953]},
    {input: [0.055625, 0.0694444444444444], output: [0.0310059969169943]},
    {input: [0.033125, 0.216666666666667], output: [0.0400620156373962]},
    {input: [0.010625, 0.502777777777778], output: [0.0264779875567933]},
    {input: [0.06125, 0.841666666666667], output: [0.0400620156373962]},
    {input: [0.066875, 0.95], output: [0.0310059969169943]},
    {input: [0.08375, 0.769444444444444], output: [0.0310059969169943]},
    {input: [0.021875, 0], output: [0.0355340062771953]},
    {input: [0.0275, 0.00277777777777778], output: [0.0355340062771953]},
    {input: [0.06125, 0.647222222222222], output: [0.0310059969169943]},
    {input: [0.066875, 0.661111111111111], output: [0.0174219688363914]},
    {input: [0.066875, 0.808333333333333], output: [0.0264779875567933]},
    {input: [0.095, 0.694444444444444], output: [0.0310059969169943]},
    {input: [0.12875, 0.758333333333333], output: [0.0174219688363914]},
    {input: [0.123125, 0.672222222222222], output: [0.0355340062771953]},
    {input: [0.095, 0.7], output: [0.0400620156373962]},
    {input: [0.08375, 0.705555555555556], output: [0.0264779875567933]},
    {input: [0.10625, 0.672222222222222], output: [0.0355340062771953]},
    {input: [0.10625, 0.763888888888889], output: [0.0264779875567933]},
    {input: [0.12875, 0.747222222222222], output: [0.0310059969169943]},
    {input: [0.08375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.0275, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.010625, 0.966666666666667], output: [0.0310059969169943]},
    {input: [0.03875, 0.733333333333333], output: [0.0355340062771953]},
    {input: [0.01625, 0.363888888888889], output: [0.0310059969169943]},
    {input: [0.06125, 0.341666666666667], output: [0.0310059969169943]},
    {input: [0.0725, 0.252777777777778], output: [0.0355340062771953]},
    {input: [0.10625, 0.286111111111111], output: [0.0264779875567933]},
    {input: [0.08375, 0.247222222222222], output: [0.0310059969169943]},
    {input: [0.095, 0.261111111111111], output: [0.0310059969169943]},
    {input: [0.185, 0.361111111111111], output: [0.0400620156373962]},
    {input: [0.134375, 0.183333333333333], output: [0.0536460437179991]},
    {input: [0.224375, 0.286111111111111], output: [0.0808140998792049]},
    {input: [0.1625, 0.202777777777778], output: [0.071758081158803]},
    {input: [0.145625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.123125, 0.166666666666667], output: [0.0491180343577981]},
    {input: [0.145625, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.078125, 0.627777777777778], output: [0.0264779875567933]},
    {input: [0.05, 0.988888888888889], output: [0.0400620156373962]},
    {input: [0.05, 0.95], output: [0.0491180343577981]},
    {input: [0.05, 0.355555555555556], output: [0.0310059969169943]},
    {input: [0.089375, 0.388888888888889], output: [0.0355340062771953]},
    {input: [0.0725, 0.158333333333333], output: [0.0355340062771953]},
    {input: [0.05, 0.188888888888889], output: [0.0400620156373962]},
    {input: [0.10625, 0.183333333333333], output: [0.0310059969169943]},
    {input: [0.19625, 0.186111111111111], output: [0.0264779875567933]},
    {input: [0.185, 0.158333333333333], output: [0.0400620156373962]},
    {input: [0.156875, 0.161111111111111], output: [0.0400620156373962]},
    {input: [0.19625, 0.180555555555556], output: [0.0264779875567933]},
    {input: [0.19625, 0.138888888888889], output: [0.0264779875567933]},
    {input: [0.156875, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.168125, 0.175], output: [0.0400620156373962]},
    {input: [0.201875, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.185, 0.180555555555556], output: [0.0355340062771953]},
    {input: [0.19625, 0.186111111111111], output: [0.0400620156373962]},
    {input: [0.179375, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.185, 0.175], output: [0.0445900249975972]},
    {input: [0.156875, 0.183333333333333], output: [0.0491180343577981]},
    {input: [0.185, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.12875, 0.172222222222222], output: [0.0400620156373962]},
    {input: [0.179375, 0.130555555555556], output: [0.0400620156373962]},
    {input: [0.123125, 0.183333333333333], output: [0.0264779875567933]},
    {input: [0.095, 0.188888888888889], output: [0.0355340062771953]},
    {input: [0.100625, 0.230555555555556], output: [0.0310059969169943]},
    {input: [0.055625, 0.286111111111111], output: [0.0355340062771953]},
    {input: [0.08375, 0.513888888888889], output: [0.0400620156373962]},
    {input: [0.044375, 0.0944444444444444], output: [0.0310059969169943]},
    {input: [0.08375, 0.919444444444444], output: [0.0491180343577981]},
    {input: [0.044375, 0.836111111111111], output: [0.0400620156373962]},
    {input: [0.055625, 0.758333333333333], output: [0.0310059969169943]},
    {input: [0.055625, 0.825], output: [0.0310059969169943]},
    {input: [0.05, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.9], output: [0.0219499781965924]},
    {input: [0.055625, 0.755555555555556], output: [0.0128939594761905]},
    {input: [0.185, 0.797222222222222], output: [0.0174219688363914]},
    {input: [0.291875, 0.861111111111111], output: [0.0581740530782001]},
    {input: [0.21875, 0.877777777777778], output: [0.117038174760813]},
    {input: [0.17375, 0.872222222222222], output: [0.112510165400612]},
    {input: [0.19625, 0.830555555555556], output: [0.121566184121014]},
    {input: [0.156875, 0.827777777777778], output: [0.126094193481215]},
    {input: [0.123125, 0.808333333333333], output: [0.0943981279598077]},
    {input: [0.12875, 0.811111111111111], output: [0.10345414668021]},
    {input: [0.156875, 0.794444444444444], output: [0.0989261373200087]},
    {input: [0.10625, 0.7], output: [0.112510165400612]},
    {input: [0.044375, 0.875], output: [0.112510165400612]},
    {input: [0.10625, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.168125, 0.772222222222222], output: [0.071758081158803]},
    {input: [0.17375, 0.822222222222222], output: [0.0581740530782001]},
    {input: [0.123125, 0.905555555555556], output: [0.0400620156373962]},
    {input: [0.06125, 0.922222222222222], output: [0.0400620156373962]},
    {input: [0.095, 0.775], output: [0.0400620156373962]},
    {input: [0.08375, 0.766666666666667], output: [0.0310059969169943]},
    {input: [0.123125, 0.719444444444444], output: [0.0355340062771953]},
    {input: [0.05, 0.808333333333333], output: [0.0264779875567933]},
    {input: [0.044375, 0.841666666666667], output: [0.0264779875567933]},
    {input: [0.066875, 0.688888888888889], output: [0.0400620156373962]},
    {input: [0.021875, 0.25], output: [0.0355340062771953]},
    {input: [0.0725, 0.25], output: [0.0355340062771953]},
    {input: [0.03875, 0.236111111111111], output: [0.0400620156373962]},
    {input: [0.03875, 0.669444444444444], output: [0.0491180343577981]},
    {input: [0.08375, 0.172222222222222], output: [0.0536460437179991]},
    {input: [0.06125, 0.969444444444444], output: [0.0536460437179991]},
    {input: [0.078125, 0.997222222222222], output: [0.0536460437179991]},
    {input: [0.0725, 0.719444444444444], output: [0.0581740530782001]},
    {input: [0.03875, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.100625, 0.741666666666667], output: [0.0400620156373962]},
    {input: [0.033125, 0.169444444444444], output: [0.0264779875567933]},
    {input: [0.078125, 0.544444444444444], output: [0.0264779875567933]},
    {input: [0.100625, 0.791666666666667], output: [0.0536460437179991]},
    {input: [0.2075, 0.830555555555556], output: [0.0310059969169943]},
    {input: [0.291875, 0.836111111111111], output: [0.0943981279598077]},
    {input: [0.2525, 0.866666666666667], output: [0.121566184121014]},
    {input: [0.21875, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.1175, 0.747222222222222], output: [0.0853421092394058]},
    {input: [0.0725, 0.347222222222222], output: [0.071758081158803]},
    {input: [0.089375, 0.522222222222222], output: [0.067230071798602]},
    {input: [0.14, 0.708333333333333], output: [0.0581740530782001]},
    {input: [0.044375, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.095, 0.377777777777778], output: [0.0400620156373962]},
    {input: [0.1625, 0.255555555555556], output: [0.0581740530782001]},
    {input: [0.21875, 0.286111111111111], output: [0.0355340062771953]},
    {input: [0.246875, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.275, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.23, 0.180555555555556], output: [0.0989261373200087]},
    {input: [0.28625, 0.191666666666667], output: [0.117038174760813]},
    {input: [0.23, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.23, 0.236111111111111], output: [0.0989261373200087]},
    {input: [0.2075, 0.166666666666667], output: [0.107982156040411]},
    {input: [0.269375, 0.177777777777778], output: [0.0762860905190039]},
    {input: [0.258125, 0.216666666666667], output: [0.0853421092394058]},
    {input: [0.235625, 0.216666666666667], output: [0.071758081158803]},
    {input: [0.17375, 0.188888888888889], output: [0.062702062438401]},
    {input: [0.10625, 0.2], output: [0.0400620156373962]},
    {input: [0.201875, 0.102777777777778], output: [0.0355340062771953]},
    {input: [0.123125, 0.0972222222222222], output: [0.0400620156373962]},
    {input: [0.134375, 0.0611111111111111], output: [0.0400620156373962]},
    {input: [0.12875, 0.025], output: [0.0264779875567933]},
    {input: [0.066875, 0.144444444444444], output: [0.0400620156373962]},
    {input: [0.055625, 0.880555555555556], output: [0.0355340062771953]},
    {input: [0.044375, 0.969444444444444], output: [0.0355340062771953]},
    {input: [0.044375, 0.902777777777778], output: [0.0491180343577981]},
    {input: [0.0725, 0.963888888888889], output: [0.0536460437179991]},
    {input: [0.0725, 0.0527777777777778], output: [0.0264779875567933]},
    {input: [0.044375, 0.0166666666666667], output: [0.0355340062771953]},
    {input: [0.066875, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.0725, 0.738888888888889], output: [0.0174219688363914]},
    {input: [0.095, 0.891666666666667], output: [0.0536460437179991]},
    {input: [0.10625, 0.883333333333333], output: [0.0310059969169943]},
    {input: [0.08375, 0.872222222222222], output: [0.0400620156373962]},
    {input: [0.08375, 0.897222222222222], output: [0.0355340062771953]},
    {input: [0.1175, 0.691666666666667], output: [0.0355340062771953]},
    {input: [0.134375, 0.75], output: [0.0445900249975972]},
    {input: [0.1175, 0.808333333333333], output: [0.0264779875567933]},
    {input: [0.100625, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.111875, 0.708333333333333], output: [0.0310059969169943]},
    {input: [0.123125, 0.758333333333333], output: [0.0400620156373962]},
    {input: [0.145625, 0.794444444444444], output: [0.0491180343577981]},
    {input: [0.111875, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.111875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.713888888888889], output: [0.0491180343577981]},
    {input: [0.123125, 0.752777777777778], output: [0.0536460437179991]},
    {input: [0.1175, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.03875, 0.122222222222222], output: [0.0400620156373962]},
    {input: [0.10625, 0.633333333333333], output: [0.0310059969169943]},
    {input: [0.111875, 0.708333333333333], output: [0.0310059969169943]},
    {input: [0.044375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.03875, 0.530555555555556], output: [0.0400620156373962]},
    {input: [0.055625, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.0725, 0.411111111111111], output: [0.0264779875567933]},
    {input: [0.06125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.08375, 0.175], output: [0.0400620156373962]},
    {input: [0.100625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.055625, 0.230555555555556], output: [0.0310059969169943]},
    {input: [0.055625, 0.230555555555556], output: [0.0355340062771953]},
    {input: [0.033125, 0.633333333333333], output: [0.0264779875567933]},
    {input: [0.021875, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.03875, 0.133333333333333], output: [0.0310059969169943]},
    {input: [0.089375, 0.113888888888889], output: [0.0536460437179991]},
    {input: [0.1175, 0.177777777777778], output: [0.0310059969169943]},
    {input: [0.14, 0.247222222222222], output: [0.0355340062771953]},
    {input: [0.078125, 0.325], output: [0.0310059969169943]},
    {input: [0.10625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.14, 0.233333333333333], output: [0.0310059969169943]},
    {input: [0.185, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.055625, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.213125, 0.191666666666667], output: [0.0310059969169943]},
    {input: [0.201875, 0.241666666666667], output: [0.0174219688363914]},
    {input: [0.275, 0.275], output: [0.0536460437179991]},
    {input: [0.28625, 0.263888888888889], output: [0.062702062438401]},
    {input: [0.381875, 0.288888888888889], output: [0.10345414668021]},
    {input: [0.21875, 0.305555555555556], output: [0.107982156040411]},
    {input: [0.280625, 0.369444444444444], output: [0.130622202841415]},
    {input: [0.3875, 0.405555555555556], output: [0.180430305803626]},
    {input: [0.51125, 0.425], output: [0.221182390045435]},
    {input: [0.37625, 0.475], output: [0.261934474287243]},
    {input: [0.21875, 0.427777777777778], output: [0.207598361964832]},
    {input: [0.100625, 0.588888888888889], output: [0.225710399405636]},
    {input: [0.145625, 0.513888888888889], output: [0.15326224964242]},
    {input: [0.17375, 0.997222222222222], output: [0.166846277723023]},
    {input: [0.0275, 0.277777777777778], output: [0.162318268362822]},
    {input: [0.123125, 0.536111111111111], output: [0.0989261373200087]},
    {input: [0.08375, 0.772222222222222], output: [0.071758081158803]},
    {input: [0.05, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.08375, 0.711111111111111], output: [0.0536460437179991]},
    {input: [0.111875, 0.811111111111111], output: [0.0536460437179991]},
    {input: [0.100625, 0.805555555555556], output: [0.0491180343577981]},
    {input: [0.05, 0.986111111111111], output: [0.0310059969169943]},
    {input: [0.066875, 0.438888888888889], output: [0.0400620156373962]},
    {input: [0.095, 0.913888888888889], output: [0.0536460437179991]},
    {input: [0.111875, 0.166666666666667], output: [0.0264779875567933]},
    {input: [0.033125, 0.236111111111111], output: [0.071758081158803]},
    {input: [0.055625, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.419444444444444], output: [0.0174219688363914]},
    {input: [0.08375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.288888888888889], output: [0.0581740530782001]},
    {input: [0.17375, 0.241666666666667], output: [0.062702062438401]},
    {input: [0.246875, 0.261111111111111], output: [0.107982156040411]},
    {input: [0.35375, 0.330555555555556], output: [0.148734240282219]},
    {input: [0.35375, 0.3], output: [0.144206230922018]},
    {input: [0.359375, 0.241666666666667], output: [0.166846277723023]},
    {input: [0.275, 0.219444444444444], output: [0.139678221561817]},
    {input: [0.24125, 0.208333333333333], output: [0.121566184121014]},
    {input: [0.26375, 0.175], output: [0.144206230922018]},
    {input: [0.314375, 0.172222222222222], output: [0.162318268362822]},
    {input: [0.365, 0.177777777777778], output: [0.157790259002621]},
    {input: [0.365, 0.188888888888889], output: [0.166846277723023]},
    {input: [0.269375, 0.216666666666667], output: [0.175902296443425]},
    {input: [0.30875, 0.286111111111111], output: [0.157790259002621]},
    {input: [0.348125, 0.263888888888889], output: [0.171374287083224]},
    {input: [0.393125, 0.288888888888889], output: [0.212126371325033]},
    {input: [0.449375, 0.269444444444444], output: [0.234766418126038]},
    {input: [0.35375, 0.227777777777778], output: [0.221182390045435]},
    {input: [0.348125, 0.25], output: [0.270990493007645]},
    {input: [0.516875, 0.361111111111111], output: [0.375134708292267]},
    {input: [0.449375, 0.336111111111111], output: [0.320798595969856]},
    {input: [0.471875, 0.308333333333333], output: [0.38871873637287]},
    {input: [0.426875, 0.316666666666667], output: [0.379662717652468]},
    {input: [0.5225, 0.288888888888889], output: [0.402302764453473]},
    {input: [0.426875, 0.277777777777778], output: [0.325326605330057]},
    {input: [0.4325, 0.286111111111111], output: [0.343438642770861]},
    {input: [0.483125, 0.286111111111111], output: [0.316270586609655]},
    {input: [0.314375, 0.277777777777778], output: [0.261934474287243]},
    {input: [0.35375, 0.344444444444444], output: [0.261934474287243]},
    {input: [0.3875, 0.369444444444444], output: [0.261934474287243]},
    {input: [0.1625, 0.997222222222222], output: [0.307214567889253]},
    {input: [0.095, 0.819444444444444], output: [0.280046511728047]},
    {input: [0.14, 0.963888888888889], output: [0.234766418126038]},
    {input: [0.179375, 0.758333333333333], output: [0.207598361964832]},
    {input: [0.19625, 0.75], output: [0.175902296443425]},
    {input: [0.213125, 0.680555555555556], output: [0.121566184121014]},
    {input: [0.14, 0.686111111111111], output: [0.0898701185996068]},
    {input: [0.179375, 0.697222222222222], output: [0.0808140998792049]},
    {input: [0.111875, 0.686111111111111], output: [0.0853421092394058]},
    {input: [0.089375, 0.713888888888889], output: [0.0808140998792049]},
    {input: [0.08375, 0.0805555555555556], output: [0.0536460437179991]},
    {input: [0.14, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.066875, 0.330555555555556], output: [0.0491180343577981]},
    {input: [0.044375, 0.661111111111111], output: [0.0400620156373962]},
    {input: [0.033125, 0.0222222222222222], output: [0.0400620156373962]},
    {input: [0.190625, 0.116666666666667], output: [0.0264779875567933]},
    {input: [0.12875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.0916666666666667], output: [0.0174219688363914]},
    {input: [0.0725, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.17375, 0.169444444444444], output: [0.0400620156373962]},
    {input: [0.213125, 0.197222222222222], output: [0.0400620156373962]},
    {input: [0.280625, 0.336111111111111], output: [0.0808140998792049]},
    {input: [0.095, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.10625, 0.986111111111111], output: [0.067230071798602]},
    {input: [0.0275, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.03875, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.033125, 0.936111111111111], output: [0.0491180343577981]},
    {input: [0.089375, 0.997222222222222], output: [0.062702062438401]},
    {input: [0.08375, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.1175, 0.988888888888889], output: [0.0491180343577981]},
    {input: [0.10625, 0.908333333333333], output: [0.0264779875567933]},
    {input: [0.15125, 0.944444444444444], output: [0.0264779875567933]},
    {input: [0.224375, 0.886111111111111], output: [0.071758081158803]},
    {input: [0.269375, 0.861111111111111], output: [0.0581740530782001]},
    {input: [0.314375, 0.866666666666667], output: [0.121566184121014]},
    {input: [0.303125, 0.844444444444444], output: [0.166846277723023]},
    {input: [0.370625, 0.841666666666667], output: [0.225710399405636]},
    {input: [0.37625, 0.794444444444444], output: [0.239294427486239]},
    {input: [0.35375, 0.763888888888889], output: [0.225710399405636]},
    {input: [0.246875, 0.763888888888889], output: [0.216654380685234]},
    {input: [0.37625, 0.797222222222222], output: [0.194014333884229]},
    {input: [0.48875, 0.833333333333333], output: [0.397774755093272]},
    {input: [0.516875, 0.808333333333333], output: [0.411358783173875]},
    {input: [0.5, 0.777777777777778], output: [0.370606698932066]},
    {input: [0.449375, 0.797222222222222], output: [0.379662717652468]},
    {input: [0.449375, 0.802777777777778], output: [0.325326605330057]},
    {input: [0.53375, 0.805555555555556], output: [0.370606698932066]},
    {input: [0.53375, 0.830555555555556], output: [0.447582858055483]},
    {input: [0.55625, 0.827777777777778], output: [0.443054848695282]},
    {input: [0.663125, 0.841666666666667], output: [0.515502998458497]},
    {input: [0.5, 0.822222222222222], output: [0.447582858055483]},
    {input: [0.5, 0.833333333333333], output: [0.420414801894277]},
    {input: [0.584375, 0.822222222222222], output: [0.420414801894277]},
    {input: [0.460625, 0.813888888888889], output: [0.347966652131062]},
    {input: [0.426875, 0.825], output: [0.316270586609655]},
    {input: [0.494375, 0.836111111111111], output: [0.33891063341066]},
    {input: [0.365, 0.825], output: [0.320798595969856]},
    {input: [0.32, 0.822222222222222], output: [0.252878455566841]},
    {input: [0.4325, 0.827777777777778], output: [0.270990493007645]},
    {input: [0.35375, 0.880555555555556], output: [0.230238408765837]},
    {input: [0.3425, 0.894444444444444], output: [0.212126371325033]},
    {input: [0.336875, 0.827777777777778], output: [0.207598361964832]},
    {input: [0.19625, 0.905555555555556], output: [0.15326224964242]},
    {input: [0.19625, 0.855555555555556], output: [0.107982156040411]},
    {input: [0.28625, 0.841666666666667], output: [0.121566184121014]},
    {input: [0.213125, 0.852777777777778], output: [0.107982156040411]},
    {input: [0.123125, 0.980555555555556], output: [0.0943981279598077]},
    {input: [0.033125, 0.927777777777778], output: [0.071758081158803]},
    {input: [0.275, 0.891666666666667], output: [0.071758081158803]},
    {input: [0.179375, 0.886111111111111], output: [0.0943981279598077]},
    {input: [0.213125, 0.861111111111111], output: [0.071758081158803]},
    {input: [0.269375, 0.855555555555556], output: [0.0898701185996068]},
    {input: [0.24125, 0.866666666666667], output: [0.10345414668021]},
    {input: [0.258125, 0.855555555555556], output: [0.0943981279598077]},
    {input: [0.224375, 0.855555555555556], output: [0.0989261373200087]},
    {input: [0.2075, 0.872222222222222], output: [0.071758081158803]},
    {input: [0.24125, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.213125, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.145625, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.213125, 0.766666666666667], output: [0.0898701185996068]},
    {input: [0.1625, 0.702777777777778], output: [0.0581740530782001]},
    {input: [0.21875, 0.738888888888889], output: [0.071758081158803]},
    {input: [0.14, 0.772222222222222], output: [0.062702062438401]},
    {input: [0.1175, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.12875, 0.838888888888889], output: [0.0400620156373962]},
    {input: [0.1175, 0.769444444444444], output: [0.0400620156373962]},
    {input: [0.095, 0.777777777777778], output: [0.0264779875567933]},
    {input: [0.089375, 0.788888888888889], output: [0.0355340062771953]},
    {input: [0.134375, 0.75], output: [0.0355340062771953]},
    {input: [0.123125, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.14, 0.725], output: [0.062702062438401]},
    {input: [0.10625, 0.936111111111111], output: [0.0310059969169943]},
    {input: [0.066875, 0.0388888888888889], output: [0.0264779875567933]},
    {input: [0.055625, 0.336111111111111], output: [0.062702062438401]},
    {input: [0.021875, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.033125, 0.677777777777778], output: [0.0581740530782001]},
    {input: [0.033125, 0.227777777777778], output: [0.0310059969169943]},
    {input: [0.055625, 0.463888888888889], output: [0.0264779875567933]},
    {input: [0.134375, 0.244444444444444], output: [0.0310059969169943]},
    {input: [0.089375, 0.261111111111111], output: [0.0310059969169943]},
    {input: [0.123125, 0.222222222222222], output: [0.0400620156373962]},
    {input: [0.14, 0.147222222222222], output: [0.0355340062771953]},
    {input: [0.0275, 0.369444444444444], output: [0.0264779875567933]},
    {input: [0.0725, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.0725, 0.163888888888889], output: [0.0264779875567933]},
    {input: [0.06125, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.794444444444444], output: [0.0355340062771953]},
    {input: [0.066875, 0.991666666666667], output: [0.0310059969169943]},
    {input: [0.01625, 0.841666666666667], output: [0.0310059969169943]},
    {input: [0.06125, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.219444444444444], output: [0.0310059969169943]},
    {input: [0.095, 0.230555555555556], output: [0.0310059969169943]},
    {input: [0.089375, 0.361111111111111], output: [0.0355340062771953]},
    {input: [0.1175, 0.286111111111111], output: [0.0400620156373962]},
    {input: [0.05, 0.266666666666667], output: [0.0400620156373962]},
    {input: [0.089375, 0.166666666666667], output: [0.0264779875567933]},
    {input: [0.0725, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.095, 0.119444444444444], output: [0.071758081158803]},
    {input: [0.145625, 0.208333333333333], output: [0.0264779875567933]},
    {input: [0.15125, 0.186111111111111], output: [0.0762860905190039]},
    {input: [0.145625, 0.188888888888889], output: [0.0536460437179991]},
    {input: [0.15125, 0.141666666666667], output: [0.0400620156373962]},
    {input: [0.17375, 0.197222222222222], output: [0.0808140998792049]},
    {input: [0.179375, 0.191666666666667], output: [0.0400620156373962]},
    {input: [0.201875, 0.188888888888889], output: [0.0762860905190039]},
    {input: [0.235625, 0.161111111111111], output: [0.0491180343577981]},
    {input: [0.275, 0.197222222222222], output: [0.0762860905190039]},
    {input: [0.24125, 0.163888888888889], output: [0.107982156040411]},
    {input: [0.2525, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.303125, 0.213888888888889], output: [0.117038174760813]},
    {input: [0.235625, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.314375, 0.25], output: [0.0762860905190039]},
    {input: [0.201875, 0.291666666666667], output: [0.107982156040411]},
    {input: [0.201875, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.269375, 0.275], output: [0.10345414668021]},
    {input: [0.28625, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.291875, 0.261111111111111], output: [0.130622202841415]},
    {input: [0.2975, 0.177777777777778], output: [0.135150212201616]},
    {input: [0.258125, 0.275], output: [0.162318268362822]},
    {input: [0.4325, 0.119444444444444], output: [0.171374287083224]},
    {input: [0.393125, 0.158333333333333], output: [0.225710399405636]},
    {input: [0.41, 0.297222222222222], output: [0.216654380685234]},
    {input: [0.415625, 0.297222222222222], output: [0.221182390045435]},
    {input: [0.370625, 0.308333333333333], output: [0.24382243684644]},
    {input: [0.449375, 0.344444444444444], output: [0.252878455566841]},
    {input: [0.449375, 0.347222222222222], output: [0.311742577249454]},
    {input: [0.5675, 0.405555555555556], output: [0.320798595969856]},
    {input: [0.48875, 0.361111111111111], output: [0.311742577249454]},
    {input: [0.57875, 0.375], output: [0.343438642770861]},
    {input: [0.539375, 0.316666666666667], output: [0.424942811254478]},
    {input: [0.573125, 0.361111111111111], output: [0.47927892357689]},
    {input: [0.53375, 0.347222222222222], output: [0.538143045259502]},
    {input: [0.584375, 0.402777777777778], output: [0.470222904856488]},
    {input: [0.663125, 0.422222222222222], output: [0.5290870265391]},
    {input: [0.528125, 0.466666666666667], output: [0.474750914216689]},
    {input: [0.6125, 0.794444444444444], output: [0.424942811254478]},
    {input: [0.618125, 0.788888888888889], output: [0.443054848695282]},
    {input: [0.561875, 0.791666666666667], output: [0.402302764453473]},
    {input: [0.460625, 0.783333333333333], output: [0.370606698932066]},
    {input: [0.48875, 0.758333333333333], output: [0.352494661491263]},
    {input: [0.449375, 0.761111111111111], output: [0.429470820614679]},
    {input: [0.455, 0.755555555555556], output: [0.411358783173875]},
    {input: [0.314375, 0.738888888888889], output: [0.352494661491263]},
    {input: [0.246875, 0.686111111111111], output: [0.261934474287243]},
    {input: [0.2075, 0.591666666666667], output: [0.230238408765837]},
    {input: [0.1625, 0.552777777777778], output: [0.15326224964242]},
    {input: [0.100625, 0.483333333333333], output: [0.144206230922018]},
    {input: [0.0725, 0.644444444444444], output: [0.112510165400612]},
    {input: [0.05, 0.680555555555556], output: [0.0898701185996068]},
    {input: [0.10625, 0.833333333333333], output: [0.0853421092394058]},
    {input: [0.066875, 0.944444444444444], output: [0.0853421092394058]},
    {input: [0.05, 0.530555555555556], output: [0.071758081158803]},
    {input: [0.06125, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.021875, 0.325], output: [0.067230071798602]},
    {input: [0.1175, 0.466666666666667], output: [0.071758081158803]},
    {input: [0.111875, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.044375, 0.588888888888889], output: [0.0491180343577981]},
    {input: [0.1625, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.123125, 0.661111111111111], output: [0.0310059969169943]},
    {input: [0.483125, 0.844444444444444], output: [0.257406464927042]},
    {input: [0.35375, 0.769444444444444], output: [0.252878455566841]},
    {input: [0.44375, 0.802777777777778], output: [0.230238408765837]},
    {input: [0.39875, 0.777777777777778], output: [0.239294427486239]},
    {input: [0.46625, 0.805555555555556], output: [0.311742577249454]},
    {input: [0.471875, 0.772222222222222], output: [0.379662717652468]},
    {input: [0.46625, 0.777777777777778], output: [0.361550680211665]},
    {input: [0.48875, 0.788888888888889], output: [0.402302764453473]},
    {input: [0.5, 0.763888888888889], output: [0.424942811254478]},
    {input: [0.550625, 0.769444444444444], output: [0.429470820614679]},
    {input: [0.59, 0.813888888888889], output: [0.474750914216689]},
    {input: [0.494375, 0.805555555555556], output: [0.424942811254478]},
    {input: [0.4775, 0.797222222222222], output: [0.443054848695282]},
    {input: [0.516875, 0.825], output: [0.357022670851464]},
    {input: [0.460625, 0.794444444444444], output: [0.402302764453473]},
    {input: [0.449375, 0.844444444444444], output: [0.393246745733071]},
    {input: [0.483125, 0.791666666666667], output: [0.375134708292267]},
    {input: [0.3875, 0.786111111111111], output: [0.379662717652468]},
    {input: [0.370625, 0.75], output: [0.307214567889253]},
    {input: [0.39875, 0.769444444444444], output: [0.275518502367846]},
    {input: [0.336875, 0.752777777777778], output: [0.24382243684644]},
    {input: [0.3875, 0.752777777777778], output: [0.189486324524028]},
    {input: [0.404375, 0.775], output: [0.171374287083224]},
    {input: [0.3875, 0.775], output: [0.162318268362822]},
    {input: [0.37625, 0.794444444444444], output: [0.139678221561817]},
    {input: [0.325625, 0.811111111111111], output: [0.139678221561817]},
    {input: [0.2525, 0.827777777777778], output: [0.10345414668021]},
    {input: [0.269375, 0.788888888888889], output: [0.0808140998792049]},
    {input: [0.185, 0.775], output: [0.0853421092394058]},
    {input: [0.168125, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.14, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.06125, 0.208333333333333], output: [0.0536460437179991]},
    {input: [0.0725, 0.619444444444444], output: [0.0536460437179991]},
    {input: [0.17375, 0.105555555555556], output: [0.0536460437179991]},
    {input: [0.505625, 0.0722222222222222], output: [0.19854234324443]},
    {input: [0.269375, 0.0666666666666667], output: [0.175902296443425]},
    {input: [0.19625, 0.983333333333333], output: [0.0853421092394058]},
    {input: [0.21875, 0.930555555555556], output: [0.0989261373200087]},
    {input: [0.2975, 0.902777777777778], output: [0.10345414668021]},
    {input: [0.33125, 0.888888888888889], output: [0.162318268362822]},
    {input: [0.404375, 0.872222222222222], output: [0.194014333884229]},
    {input: [0.449375, 0.875], output: [0.270990493007645]},
    {input: [0.449375, 0.875], output: [0.284574521088248]},
    {input: [0.280625, 0.00277777777777778], output: [0.24835044620664]},
    {input: [0.28625, 0.0166666666666667], output: [0.171374287083224]},
    {input: [0.381875, 0.0361111111111111], output: [0.162318268362822]},
    {input: [0.41, 0.0111111111111111], output: [0.148734240282219]},
    {input: [0.494375, 0.00555555555555556], output: [0.203070352604631]},
    {input: [0.494375, 1], output: [0.239294427486239]},
    {input: [0.381875, 0.997222222222222], output: [0.230238408765837]},
    {input: [0.37625, 0.994444444444444], output: [0.24835044620664]},
    {input: [0.33125, 0.0222222222222222], output: [0.325326605330057]},
    {input: [0.37625, 0.0277777777777778], output: [0.302686558529052]},
    {input: [0.35375, 0.025], output: [0.298158549168851]},
    {input: [0.3425, 0.0138888888888889], output: [0.325326605330057]},
    {input: [0.336875, 0.0111111111111111], output: [0.307214567889253]},
    {input: [0.28625, 0.00833333333333333], output: [0.270990493007645]},
    {input: [0.2975, 0.0444444444444444], output: [0.270990493007645]},
    {input: [0.291875, 0.0583333333333333], output: [0.280046511728047]},
    {input: [0.39875, 0.0444444444444444], output: [0.334382624050459]},
    {input: [0.505625, 0.0583333333333333], output: [0.357022670851464]},
    {input: [0.539375, 0.0583333333333333], output: [0.379662717652468]},
    {input: [0.51125, 0.0833333333333333], output: [0.43399882997488]},
    {input: [0.46625, 0.0555555555555556], output: [0.488334942297291]},
    {input: [0.4325, 0.133333333333333], output: [0.443054848695282]},
    {input: [0.37625, 0.113888888888889], output: [0.406830773813674]},
    {input: [0.37625, 0.0805555555555556], output: [0.38871873637287]},
    {input: [0.528125, 0.0666666666666667], output: [0.357022670851464]},
    {input: [0.505625, 0.0361111111111111], output: [0.393246745733071]},
    {input: [0.41, 0.0166666666666667], output: [0.393246745733071]},
    {input: [0.460625, 0.00277777777777778], output: [0.443054848695282]},
    {input: [0.55625, 0.969444444444444], output: [0.411358783173875]},
    {input: [0.37625, 0.0333333333333333], output: [0.397774755093272]},
    {input: [0.224375, 0.05], output: [0.320798595969856]},
    {input: [0.24125, 0.00277777777777778], output: [0.302686558529052]},
    {input: [0.39875, 0.972222222222222], output: [0.33891063341066]},
    {input: [0.23, 0.119444444444444], output: [0.320798595969856]},
    {input: [0.213125, 0.105555555555556], output: [0.24835044620664]},
    {input: [0.145625, 0.119444444444444], output: [0.230238408765837]},
    {input: [0.426875, 0.961111111111111], output: [0.307214567889253]},
    {input: [0.201875, 0.133333333333333], output: [0.352494661491263]},
    {input: [0.246875, 0.158333333333333], output: [0.24382243684644]},
    {input: [0.179375, 0.0333333333333333], output: [0.207598361964832]},
    {input: [0.06125, 0.205555555555556], output: [0.184958315163827]},
    {input: [0.03875, 0.8], output: [0.171374287083224]},
    {input: [0.08375, 0.85], output: [0.148734240282219]},
    {input: [0.2075, 0.872222222222222], output: [0.130622202841415]},
    {input: [0.168125, 0.872222222222222], output: [0.130622202841415]},
    {input: [0.168125, 0.877777777777778], output: [0.126094193481215]},
    {input: [0.17375, 0.877777777777778], output: [0.0989261373200087]},
    {input: [0.224375, 0.811111111111111], output: [0.107982156040411]},
    {input: [0.224375, 0.808333333333333], output: [0.112510165400612]},
    {input: [0.258125, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.19625, 0.936111111111111], output: [0.10345414668021]},
    {input: [0.26375, 0.219444444444444], output: [0.10345414668021]},
    {input: [0.224375, 0.211111111111111], output: [0.10345414668021]},
    {input: [0.213125, 0.138888888888889], output: [0.144206230922018]},
    {input: [0.168125, 0.130555555555556], output: [0.157790259002621]},
    {input: [0.1625, 0.163888888888889], output: [0.171374287083224]},
    {input: [0.08375, 0.0888888888888889], output: [0.166846277723023]},
    {input: [0.055625, 0.519444444444444], output: [0.175902296443425]},
    {input: [0.08375, 0.597222222222222], output: [0.189486324524028]},
    {input: [0.055625, 0.3], output: [0.135150212201616]},
    {input: [0.06125, 0.516666666666667], output: [0.112510165400612]},
    {input: [0.2075, 0.0555555555555556], output: [0.126094193481215]},
    {input: [0.179375, 0.0611111111111111], output: [0.107982156040411]},
    {input: [0.134375, 0.075], output: [0.0898701185996068]},
    {input: [0.08375, 0.0472222222222222], output: [0.0853421092394058]},
    {input: [0.033125, 0.191666666666667], output: [0.0853421092394058]},
    {input: [0.100625, 0.788888888888889], output: [0.0853421092394058]},
    {input: [0.1175, 0.811111111111111], output: [0.0762860905190039]},
    {input: [0.14, 0.877777777777778], output: [0.062702062438401]},
    {input: [0.17375, 0.844444444444444], output: [0.062702062438401]},
    {input: [0.190625, 0.916666666666667], output: [0.062702062438401]},
    {input: [0.12875, 0.955555555555556], output: [0.0581740530782001]},
    {input: [0.145625, 0.883333333333333], output: [0.0581740530782001]},
    {input: [0.134375, 0.952777777777778], output: [0.0536460437179991]},
    {input: [0.100625, 0.988888888888889], output: [0.0581740530782001]},
    {input: [0.100625, 0.986111111111111], output: [0.071758081158803]},
    {input: [0.044375, 0.958333333333333], output: [0.0581740530782001]},
    {input: [0.089375, 0.705555555555556], output: [0.0491180343577981]},
    {input: [0.10625, 0.702777777777778], output: [0.0400620156373962]},
    {input: [0.123125, 0.738888888888889], output: [0.0355340062771953]},
    {input: [0.123125, 0.675], output: [0.0943981279598077]},
    {input: [0.0725, 0.613888888888889], output: [0.135150212201616]},
    {input: [0.190625, 0.211111111111111], output: [0.184958315163827]},
    {input: [0.23, 0.177777777777778], output: [0.144206230922018]},
    {input: [0.14, 0.141666666666667], output: [0.157790259002621]},
    {input: [0.111875, 0.0916666666666667], output: [0.112510165400612]},
    {input: [0.12875, 0.0916666666666667], output: [0.0853421092394058]},
    {input: [0.05, 0.158333333333333], output: [0.062702062438401]},
    {input: [0.021875, 0.0166666666666667], output: [0.067230071798602]},
    {input: [0.0725, 0.863888888888889], output: [0.071758081158803]},
    {input: [0.0725, 0.961111111111111], output: [0.0762860905190039]},
    {input: [0.078125, 0.919444444444444], output: [0.0762860905190039]},
    {input: [0.10625, 0.927777777777778], output: [0.071758081158803]},
    {input: [0.14, 0.933333333333333], output: [0.062702062438401]},
    {input: [0.1625, 0.938888888888889], output: [0.0491180343577981]},
    {input: [0.19625, 0.886111111111111], output: [0.0355340062771953]},
    {input: [0.179375, 0.922222222222222], output: [0.0491180343577981]},
    {input: [0.190625, 0.858333333333333], output: [0.0536460437179991]},
    {input: [0.24125, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.23, 0.813888888888889], output: [0.121566184121014]},
    {input: [0.21875, 0.822222222222222], output: [0.144206230922018]},
    {input: [0.213125, 0.775], output: [0.194014333884229]},
    {input: [0.246875, 0.833333333333333], output: [0.203070352604631]},
    {input: [0.224375, 0.758333333333333], output: [0.189486324524028]},
    {input: [0.100625, 0.938888888888889], output: [0.148734240282219]},
    {input: [0.0725, 0.952777777777778], output: [0.139678221561817]},
    {input: [0.08375, 0.0777777777777778], output: [0.121566184121014]},
    {input: [0.145625, 0.025], output: [0.107982156040411]},
    {input: [0.0275, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.08375, 0.238888888888889], output: [0.071758081158803]},
    {input: [0.010625, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.100625, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.246875, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.213125, 0.916666666666667], output: [0.0762860905190039]},
    {input: [0.23, 0.969444444444444], output: [0.0898701185996068]},
    {input: [0.168125, 0.908333333333333], output: [0.10345414668021]},
    {input: [0.095, 0.0111111111111111], output: [0.130622202841415]},
    {input: [0.0725, 0.927777777777778], output: [0.117038174760813]},
    {input: [0.235625, 0.888888888888889], output: [0.139678221561817]},
    {input: [0.201875, 0.880555555555556], output: [0.148734240282219]},
    {input: [0.190625, 0.0527777777777778], output: [0.157790259002621]},
    {input: [0.14, 0.972222222222222], output: [0.117038174760813]},
    {input: [0.1175, 0.0888888888888889], output: [0.0943981279598077]},
    {input: [0.15125, 0.183333333333333], output: [0.071758081158803]},
    {input: [0.14, 0.0527777777777778], output: [0.0536460437179991]},
    {input: [0.134375, 0.0194444444444444], output: [0.0808140998792049]},
    {input: [0.044375, 0.0305555555555556], output: [0.0400620156373962]},
    {input: [0.03875, 0.0416666666666667], output: [0.0400620156373962]},
    {input: [0.044375, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.03875, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.134375, 0.105555555555556], output: [0.0898701185996068]},
    {input: [0.08375, 0.111111111111111], output: [0.0536460437179991]},
    {input: [0.078125, 0.0777777777777778], output: [0.0400620156373962]},
    {input: [0.100625, 0.191666666666667], output: [0.0491180343577981]},
    {input: [0.089375, 0.197222222222222], output: [0.0536460437179991]},
    {input: [0.078125, 0.211111111111111], output: [0.0355340062771953]},
    {input: [0.066875, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.089375, 0.125], output: [0.0355340062771953]},
    {input: [0.0725, 0.141666666666667], output: [0.0400620156373962]},
    {input: [0.066875, 0.269444444444444], output: [0.0536460437179991]},
    {input: [0.078125, 0.252777777777778], output: [0.0536460437179991]},
    {input: [0.123125, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.145625, 0.277777777777778], output: [0.0400620156373962]},
    {input: [0.156875, 0.252777777777778], output: [0.0264779875567933]},
    {input: [0.19625, 0.219444444444444], output: [0.0491180343577981]},
    {input: [0.2075, 0.161111111111111], output: [0.0400620156373962]},
    {input: [0.2525, 0.0944444444444444], output: [0.0853421092394058]},
    {input: [0.24125, 0.15], output: [0.0853421092394058]},
    {input: [0.258125, 0.130555555555556], output: [0.10345414668021]},
    {input: [0.246875, 0.158333333333333], output: [0.0989261373200087]},
    {input: [0.269375, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.201875, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.201875, 0.163888888888889], output: [0.0943981279598077]},
    {input: [0.26375, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.280625, 0.130555555555556], output: [0.121566184121014]},
    {input: [0.2525, 0.175], output: [0.121566184121014]},
    {input: [0.168125, 0.369444444444444], output: [0.0943981279598077]},
    {input: [0.156875, 0.377777777777778], output: [0.130622202841415]},
    {input: [0.100625, 0.183333333333333], output: [0.130622202841415]},
    {input: [0.033125, 0.994444444444444], output: [0.130622202841415]},
    {input: [0.078125, 0.208333333333333], output: [0.135150212201616]},
    {input: [0.303125, 0.333333333333333], output: [0.166846277723023]},
    {input: [0.46625, 0.347222222222222], output: [0.184958315163827]},
    {input: [0.37625, 0.322222222222222], output: [0.180430305803626]},
    {input: [0.303125, 0.308333333333333], output: [0.144206230922018]},
    {input: [0.269375, 0.288888888888889], output: [0.162318268362822]},
    {input: [0.33125, 0.305555555555556], output: [0.175902296443425]},
    {input: [0.269375, 0.194444444444444], output: [0.194014333884229]},
    {input: [0.2525, 0.247222222222222], output: [0.216654380685234]},
    {input: [0.2975, 0.308333333333333], output: [0.212126371325033]},
    {input: [0.280625, 0.277777777777778], output: [0.207598361964832]},
    {input: [0.348125, 0.358333333333333], output: [0.194014333884229]},
    {input: [0.41, 0.436111111111111], output: [0.189486324524028]},
    {input: [0.35375, 0.397222222222222], output: [0.148734240282219]},
    {input: [0.3425, 0.4], output: [0.171374287083224]},
    {input: [0.325625, 0.388888888888889], output: [0.162318268362822]},
    {input: [0.303125, 0.436111111111111], output: [0.157790259002621]},
    {input: [0.2075, 0.711111111111111], output: [0.130622202841415]},
    {input: [0.37625, 0.858333333333333], output: [0.126094193481215]},
    {input: [0.404375, 0.855555555555556], output: [0.19854234324443]},
    {input: [0.26375, 0.861111111111111], output: [0.225710399405636]},
    {input: [0.37625, 0.813888888888889], output: [0.29363053980865]},
    {input: [0.37625, 0.816666666666667], output: [0.352494661491263]},
    {input: [0.438125, 0.819444444444444], output: [0.424942811254478]},
    {input: [0.370625, 0.827777777777778], output: [0.357022670851464]},
    {input: [0.455, 0.816666666666667], output: [0.397774755093272]},
    {input: [0.4325, 0.816666666666667], output: [0.443054848695282]},
    {input: [0.381875, 0.819444444444444], output: [0.438526839335081]},
    {input: [0.325625, 0.813888888888889], output: [0.443054848695282]},
    {input: [0.505625, 0.858333333333333], output: [0.429470820614679]},
    {input: [0.438125, 0.858333333333333], output: [0.429470820614679]},
    {input: [0.39875, 0.833333333333333], output: [0.33891063341066]},
    {input: [0.415625, 0.844444444444444], output: [0.298158549168851]},
    {input: [0.41, 0.841666666666667], output: [0.357022670851464]},
    {input: [0.3425, 0.816666666666667], output: [0.29363053980865]},
    {input: [0.39875, 0.830555555555556], output: [0.29363053980865]},
    {input: [0.3875, 0.827777777777778], output: [0.29363053980865]},
    {input: [0.393125, 0.833333333333333], output: [0.257406464927042]},
    {input: [0.365, 0.830555555555556], output: [0.24835044620664]},
    {input: [0.3875, 0.85], output: [0.225710399405636]},
    {input: [0.42125, 0.844444444444444], output: [0.234766418126038]},
    {input: [0.359375, 0.841666666666667], output: [0.230238408765837]},
    {input: [0.269375, 0.852777777777778], output: [0.216654380685234]},
    {input: [0.3425, 0.872222222222222], output: [0.194014333884229]},
    {input: [0.303125, 0.844444444444444], output: [0.166846277723023]},
    {input: [0.235625, 0.838888888888889], output: [0.144206230922018]},
    {input: [0.224375, 0.833333333333333], output: [0.121566184121014]},
    {input: [0.123125, 0.816666666666667], output: [0.0943981279598077]},
    {input: [0.134375, 0.847222222222222], output: [0.121566184121014]},
    {input: [0.1175, 0.85], output: [0.0943981279598077]},
    {input: [0.08375, 0.916666666666667], output: [0.0808140998792049]},
    {input: [0.044375, 0.866666666666667], output: [0.062702062438401]},
    {input: [0.0725, 0.847222222222222], output: [0.0536460437179991]},
    {input: [0.055625, 0.602777777777778], output: [0.067230071798602]},
    {input: [0.08375, 0.547222222222222], output: [0.0491180343577981]},
    {input: [0.01625, 0.0722222222222222], output: [0.0310059969169943]},
    {input: [0.089375, 0.261111111111111], output: [0.0581740530782001]},
    {input: [0.078125, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.06125, 0.375], output: [0.0581740530782001]},
    {input: [0.08375, 0.15], output: [0.0400620156373962]},
    {input: [0.1175, 0.244444444444444], output: [0.0355340062771953]},
    {input: [0.100625, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.055625, 0.116666666666667], output: [0.0536460437179991]},
    {input: [0.05, 0.222222222222222], output: [0.0581740530782001]},
    {input: [0.03875, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.12875, 0.944444444444444], output: [0.0762860905190039]},
    {input: [0.055625, 0.816666666666667], output: [0.0808140998792049]},
    {input: [0.185, 0.769444444444444], output: [0.10345414668021]},
    {input: [0.044375, 0.619444444444444], output: [0.112510165400612]},
    {input: [0.08375, 0.0555555555555556], output: [0.0762860905190039]},
    {input: [0.05, 0.0416666666666667], output: [0.067230071798602]},
    {input: [0.10625, 0.175], output: [0.0853421092394058]},
    {input: [0.095, 0.0277777777777778], output: [0.0898701185996068]},
    {input: [0.10625, 0.0638888888888889], output: [0.0762860905190039]},
    {input: [0.145625, 0.280555555555556], output: [0.071758081158803]},
    {input: [0.14, 0.366666666666667], output: [0.062702062438401]},
    {input: [0.14, 0.347222222222222], output: [0.0310059969169943]},
    {input: [0.123125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.123125, 0.327777777777778], output: [0.0264779875567933]},
    {input: [0.111875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.179375, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.325], output: [0.0445900249975972]},
    {input: [0.168125, 0.366666666666667], output: [0.0400620156373962]},
    {input: [0.201875, 0.291666666666667], output: [0.071758081158803]},
    {input: [0.17375, 0.338888888888889], output: [0.0808140998792049]},
    {input: [0.17375, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.179375, 0.266666666666667], output: [0.0808140998792049]},
    {input: [0.213125, 0.283333333333333], output: [0.0762860905190039]},
    {input: [0.156875, 0.272222222222222], output: [0.0943981279598077]},
    {input: [0.179375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.179375, 0.236111111111111], output: [0.0853421092394058]},
    {input: [0.17375, 0.211111111111111], output: [0.0943981279598077]},
    {input: [0.145625, 0.177777777777778], output: [0.0853421092394058]},
    {input: [0.201875, 0.15], output: [0.0808140998792049]},
    {input: [0.123125, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.08375, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.100625, 0.286111111111111], output: [0.121566184121014]},
    {input: [0.123125, 0.197222222222222], output: [0.126094193481215]},
    {input: [0.134375, 0.238888888888889], output: [0.117038174760813]},
    {input: [0.190625, 0.183333333333333], output: [0.0989261373200087]},
    {input: [0.190625, 0.216666666666667], output: [0.0853421092394058]},
    {input: [0.134375, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.1175, 0.275], output: [0.0581740530782001]},
    {input: [0.08375, 0.0861111111111111], output: [0.0536460437179991]},
    {input: [0.06125, 0.0527777777777778], output: [0.0491180343577981]},
    {input: [0.095, 0.925], output: [0.0400620156373962]},
    {input: [0.12875, 0.877777777777778], output: [0.0310059969169943]},
    {input: [0.246875, 0.844444444444444], output: [0.0400620156373962]},
    {input: [0.30875, 0.847222222222222], output: [0.0989261373200087]},
    {input: [0.348125, 0.836111111111111], output: [0.15326224964242]},
    {input: [0.28625, 0.830555555555556], output: [0.180430305803626]},
    {input: [0.246875, 0.794444444444444], output: [0.184958315163827]},
    {input: [0.348125, 0.822222222222222], output: [0.15326224964242]},
    {input: [0.359375, 0.833333333333333], output: [0.212126371325033]},
    {input: [0.325625, 0.861111111111111], output: [0.225710399405636]},
    {input: [0.35375, 0.855555555555556], output: [0.234766418126038]},
    {input: [0.275, 0.863888888888889], output: [0.230238408765837]},
    {input: [0.24125, 0.897222222222222], output: [0.207598361964832]},
    {input: [0.2525, 0.883333333333333], output: [0.162318268362822]},
    {input: [0.325625, 0.869444444444444], output: [0.189486324524028]},
    {input: [0.359375, 0.852777777777778], output: [0.216654380685234]},
    {input: [0.325625, 0.855555555555556], output: [0.239294427486239]},
    {input: [0.26375, 0.916666666666667], output: [0.207598361964832]},
    {input: [0.2075, 0.838888888888889], output: [0.180430305803626]},
    {input: [0.15125, 0.888888888888889], output: [0.144206230922018]},
    {input: [0.145625, 0.880555555555556], output: [0.139678221561817]},
    {input: [0.1175, 0.827777777777778], output: [0.107982156040411]},
    {input: [0.201875, 0.844444444444444], output: [0.0898701185996068]},
    {input: [0.258125, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.12875, 1], output: [0.0808140998792049]},
    {input: [0.095, 0.00555555555555556], output: [0.0581740530782001]},
    {input: [0.06125, 0.930555555555556], output: [0.062702062438401]},
    {input: [0.095, 0.0472222222222222], output: [0.0581740530782001]},
    {input: [0.19625, 0.102777777777778], output: [0.062702062438401]},
    {input: [0.14, 0.975], output: [0.139678221561817]},
    {input: [0.100625, 0.944444444444444], output: [0.130622202841415]},
    {input: [0.12875, 0.111111111111111], output: [0.107982156040411]},
    {input: [0.15125, 0.172222222222222], output: [0.0943981279598077]},
    {input: [0.156875, 0.211111111111111], output: [0.0943981279598077]},
    {input: [0.1175, 0.258333333333333], output: [0.0808140998792049]},
    {input: [0.0725, 0.136111111111111], output: [0.071758081158803]},
    {input: [0.123125, 0.147222222222222], output: [0.0989261373200087]},
    {input: [0.100625, 0.166666666666667], output: [0.10345414668021]},
    {input: [0.123125, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.1625, 0.3], output: [0.0853421092394058]},
    {input: [0.123125, 0.308333333333333], output: [0.0762860905190039]},
    {input: [0.10625, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.111875, 0.327777777777778], output: [0.0536460437179991]},
    {input: [0.0725, 0.230555555555556], output: [0.0400620156373962]},
    {input: [0.08375, 0.291666666666667], output: [0.0310059969169943]},
    {input: [0.044375, 0.319444444444444], output: [0.0310059969169943]},
    {input: [0.06125, 0.025], output: [0.0445900249975972]},
    {input: [0.0725, 0.213888888888889], output: [0.0264779875567933]},
    {input: [0.10625, 0.205555555555556], output: [0.0355340062771953]},
    {input: [0.078125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.03875, 0.166666666666667], output: [0.0400620156373962]},
    {input: [0.235625, 0.244444444444444], output: [0.0310059969169943]},
    {input: [0.246875, 0.219444444444444], output: [0.0355340062771953]},
    {input: [0.365, 0.208333333333333], output: [0.0989261373200087]},
    {input: [0.258125, 0.211111111111111], output: [0.10345414668021]},
    {input: [0.24125, 0.225], output: [0.0898701185996068]},
    {input: [0.24125, 0.255555555555556], output: [0.0808140998792049]},
    {input: [0.3425, 0.144444444444444], output: [0.162318268362822]},
    {input: [0.33125, 0.15], output: [0.203070352604631]},
    {input: [0.336875, 0.130555555555556], output: [0.194014333884229]},
    {input: [0.381875, 0.122222222222222], output: [0.207598361964832]},
    {input: [0.303125, 0.138888888888889], output: [0.207598361964832]},
    {input: [0.32, 0.152777777777778], output: [0.19854234324443]},
    {input: [0.2975, 0.194444444444444], output: [0.225710399405636]},
    {input: [0.269375, 0.180555555555556], output: [0.189486324524028]},
    {input: [0.32, 0.144444444444444], output: [0.162318268362822]},
    {input: [0.280625, 0.147222222222222], output: [0.162318268362822]},
    {input: [0.235625, 0.147222222222222], output: [0.126094193481215]},
    {input: [0.258125, 0.152777777777778], output: [0.117038174760813]},
    {input: [0.269375, 0.158333333333333], output: [0.117038174760813]},
    {input: [0.24125, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.24125, 0.172222222222222], output: [0.112510165400612]},
    {input: [0.235625, 0.15], output: [0.126094193481215]},
    {input: [0.275, 0.202777777777778], output: [0.0989261373200087]},
    {input: [0.303125, 0.2], output: [0.0898701185996068]},
    {input: [0.2525, 0.172222222222222], output: [0.0762860905190039]},
    {input: [0.235625, 0.166666666666667], output: [0.0853421092394058]},
    {input: [0.246875, 0.15], output: [0.0943981279598077]},
    {input: [0.23, 0.155555555555556], output: [0.10345414668021]},
    {input: [0.258125, 0.161111111111111], output: [0.107982156040411]},
    {input: [0.23, 0.197222222222222], output: [0.112510165400612]},
    {input: [0.280625, 0.191666666666667], output: [0.0989261373200087]},
    {input: [0.3875, 0.138888888888889], output: [0.144206230922018]},
    {input: [0.381875, 0.15], output: [0.175902296443425]},
    {input: [0.35375, 0.141666666666667], output: [0.221182390045435]},
    {input: [0.33125, 0.138888888888889], output: [0.19854234324443]},
    {input: [0.33125, 0.169444444444444], output: [0.166846277723023]},
    {input: [0.314375, 0.183333333333333], output: [0.175902296443425]},
    {input: [0.258125, 0.258333333333333], output: [0.207598361964832]},
    {input: [0.35375, 0.155555555555556], output: [0.203070352604631]},
    {input: [0.5, 0.141666666666667], output: [0.261934474287243]},
    {input: [0.4325, 0.133333333333333], output: [0.266462483647444]},
    {input: [0.258125, 0.219444444444444], output: [0.234766418126038]},
    {input: [0.365, 0.288888888888889], output: [0.24382243684644]},
    {input: [0.415625, 0.241666666666667], output: [0.379662717652468]},
    {input: [0.44375, 0.305555555555556], output: [0.424942811254478]},
    {input: [0.42125, 0.288888888888889], output: [0.443054848695282]},
    {input: [0.455, 0.325], output: [0.424942811254478]},
    {input: [0.426875, 0.330555555555556], output: [0.461166886136086]},
    {input: [0.35375, 0.313888888888889], output: [0.397774755093272]},
    {input: [0.2075, 0.361111111111111], output: [0.298158549168851]},
    {input: [0.134375, 0.35], output: [0.230238408765837]},
    {input: [0.246875, 0.338888888888889], output: [0.162318268362822]},
    {input: [0.179375, 0.255555555555556], output: [0.139678221561817]},
    {input: [0.190625, 0.377777777777778], output: [0.10345414668021]},
    {input: [0.044375, 0.738888888888889], output: [0.0762860905190039]},
    {input: [0.021875, 0.780555555555556], output: [0.071758081158803]},
    {input: [0.185, 0.372222222222222], output: [0.139678221561817]},
    {input: [0.12875, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.10625, 0.369444444444444], output: [0.126094193481215]},
    {input: [0.12875, 0.455555555555556], output: [0.10345414668021]},
    {input: [0.314375, 0.452777777777778], output: [0.0853421092394058]},
    {input: [0.24125, 0.433333333333333], output: [0.107982156040411]},
    {input: [0.100625, 0.586111111111111], output: [0.0898701185996068]},
    {input: [0.033125, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.168125, 0.475], output: [0.071758081158803]},
    {input: [0.08375, 0.35], output: [0.0581740530782001]},
    {input: [0.06125, 0.458333333333333], output: [0.071758081158803]},
    {input: [0.12875, 0.475], output: [0.0491180343577981]},
    {input: [0.066875, 0.508333333333333], output: [0.0536460437179991]},
    {input: [0.179375, 0.511111111111111], output: [0.0762860905190039]},
    {input: [0.246875, 0.494444444444444], output: [0.071758081158803]},
    {input: [0.21875, 0.563888888888889], output: [0.117038174760813]},
    {input: [0.280625, 0.505555555555556], output: [0.126094193481215]},
    {input: [0.291875, 0.516666666666667], output: [0.10345414668021]},
    {input: [0.291875, 0.541666666666667], output: [0.130622202841415]},
    {input: [0.246875, 0.516666666666667], output: [0.117038174760813]},
    {input: [0.05, 0.463888888888889], output: [0.107982156040411]},
    {input: [0.095, 0.830555555555556], output: [0.0989261373200087]},
    {input: [0.03875, 0.916666666666667], output: [0.062702062438401]},
    {input: [0.01625, 0.297222222222222], output: [0.0581740530782001]},
    {input: [0.03875, 0.577777777777778], output: [0.062702062438401]},
    {input: [0.066875, 0.916666666666667], output: [0.0536460437179991]},
    {input: [0.06125, 0.0416666666666667], output: [0.067230071798602]},
    {input: [0.033125, 0.130555555555556], output: [0.071758081158803]},
    {input: [0.111875, 0.358333333333333], output: [0.0808140998792049]},
    {input: [0.055625, 0.297222222222222], output: [0.0898701185996068]},
    {input: [0.095, 0.308333333333333], output: [0.071758081158803]},
    {input: [0.0275, 0.397222222222222], output: [0.0853421092394058]},
    {input: [0.01625, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.06125, 0.561111111111111], output: [0.062702062438401]},
    {input: [0.30875, 0.116666666666667], output: [0.112510165400612]},
    {input: [0.269375, 0.161111111111111], output: [0.126094193481215]},
    {input: [0.17375, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.258125, 0.0694444444444444], output: [0.0808140998792049]},
    {input: [0.213125, 0.136111111111111], output: [0.0536460437179991]},
    {input: [0.066875, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.134375, 0.202777777777778], output: [0.0491180343577981]},
    {input: [0.111875, 0.0361111111111111], output: [0.0491180343577981]},
    {input: [0.14, 0.0916666666666667], output: [0.0491180343577981]},
    {input: [0.134375, 0.147222222222222], output: [0.0536460437179991]},
    {input: [0.134375, 0.127777777777778], output: [0.0355340062771953]},
    {input: [0.100625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.111111111111111], output: [0.0310059969169943]},
    {input: [0.15125, 0.0833333333333333], output: [0.0310059969169943]},
    {input: [0.1175, 0.0861111111111111], output: [0.0536460437179991]},
    {input: [0.066875, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.936111111111111], output: [0.0355340062771953]},
    {input: [0.055625, 0.7], output: [0.0264779875567933]},
    {input: [0.14, 0.855555555555556], output: [0.062702062438401]},
    {input: [0.12875, 0.894444444444444], output: [0.0355340062771953]},
    {input: [0.1175, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.12875, 0.844444444444444], output: [0.0310059969169943]},
    {input: [0.145625, 0.761111111111111], output: [0.0310059969169943]},
    {input: [0.17375, 0.763888888888889], output: [0.0355340062771953]},
    {input: [0.185, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.24125, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.258125, 0.702777777777778], output: [0.10345414668021]},
    {input: [0.185, 0.730555555555556], output: [0.0853421092394058]},
    {input: [0.24125, 0.697222222222222], output: [0.071758081158803]},
    {input: [0.269375, 0.661111111111111], output: [0.0853421092394058]},
    {input: [0.2525, 0.702777777777778], output: [0.107982156040411]},
    {input: [0.28625, 0.705555555555556], output: [0.139678221561817]},
    {input: [0.235625, 0.722222222222222], output: [0.135150212201616]},
    {input: [0.185, 0.658333333333333], output: [0.112510165400612]},
    {input: [0.28625, 0.683333333333333], output: [0.130622202841415]},
    {input: [0.213125, 0.652777777777778], output: [0.135150212201616]},
    {input: [0.224375, 0.758333333333333], output: [0.135150212201616]},
    {input: [0.134375, 0.919444444444444], output: [0.107982156040411]},
    {input: [0.1625, 0.902777777777778], output: [0.0989261373200087]},
    {input: [0.15125, 0.863888888888889], output: [0.0762860905190039]},
    {input: [0.123125, 0.0138888888888889], output: [0.062702062438401]},
    {input: [0.111875, 0.111111111111111], output: [0.0491180343577981]},
    {input: [0.15125, 0.152777777777778], output: [0.0762860905190039]},
    {input: [0.12875, 0.161111111111111], output: [0.0355340062771953]},
    {input: [0.156875, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.233333333333333], output: [0.062702062438401]},
    {input: [0.23, 0.3], output: [0.0989261373200087]},
    {input: [0.275, 0.161111111111111], output: [0.10345414668021]},
    {input: [0.314375, 0.147222222222222], output: [0.139678221561817]},
    {input: [0.365, 0.161111111111111], output: [0.175902296443425]},
    {input: [0.2975, 0.244444444444444], output: [0.189486324524028]},
    {input: [0.325625, 0.133333333333333], output: [0.189486324524028]},
    {input: [0.37625, 0.113888888888889], output: [0.175902296443425]},
    {input: [0.404375, 0.136111111111111], output: [0.171374287083224]},
    {input: [0.455, 0.15], output: [0.230238408765837]},
    {input: [0.460625, 0.155555555555556], output: [0.284574521088248]},
    {input: [0.455, 0.152777777777778], output: [0.289102530448449]},
    {input: [0.449375, 0.152777777777778], output: [0.307214567889253]},
    {input: [0.4325, 0.127777777777778], output: [0.284574521088248]},
    {input: [0.42125, 0.113888888888889], output: [0.261934474287243]},
    {input: [0.48875, 0.108333333333333], output: [0.311742577249454]},
    {input: [0.5, 0.113888888888889], output: [0.357022670851464]},
    {input: [0.5, 0.122222222222222], output: [0.320798595969856]},
    {input: [0.5, 0.122222222222222], output: [0.366078689571866]},
    {input: [0.483125, 0.138888888888889], output: [0.375134708292267]},
    {input: [0.51125, 0.116666666666667], output: [0.384190727012669]},
    {input: [0.4775, 0.127777777777778], output: [0.366078689571866]},
    {input: [0.455, 0.116666666666667], output: [0.366078689571866]},
    {input: [0.426875, 0.125], output: [0.329854614690258]},
    {input: [0.404375, 0.111111111111111], output: [0.311742577249454]},
    {input: [0.438125, 0.125], output: [0.298158549168851]},
    {input: [0.438125, 0.152777777777778], output: [0.289102530448449]},
    {input: [0.426875, 0.158333333333333], output: [0.24835044620664]},
    {input: [0.404375, 0.163888888888889], output: [0.252878455566841]},
    {input: [0.3875, 0.152777777777778], output: [0.252878455566841]},
    {input: [0.2975, 0.138888888888889], output: [0.225710399405636]},
    {input: [0.26375, 0.213888888888889], output: [0.19854234324443]},
    {input: [0.291875, 0.213888888888889], output: [0.184958315163827]},
    {input: [0.17375, 0.241666666666667], output: [0.180430305803626]},
    {input: [0.2525, 0.238888888888889], output: [0.135150212201616]},
    {input: [0.28625, 0.258333333333333], output: [0.166846277723023]},
    {input: [0.32, 0.247222222222222], output: [0.144206230922018]},
    {input: [0.336875, 0.241666666666667], output: [0.175902296443425]},
    {input: [0.30875, 0.227777777777778], output: [0.157790259002621]},
    {input: [0.30875, 0.169444444444444], output: [0.162318268362822]},
    {input: [0.365, 0.166666666666667], output: [0.175902296443425]},
    {input: [0.26375, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.381875, 0.158333333333333], output: [0.126094193481215]},
    {input: [0.303125, 0.163888888888889], output: [0.126094193481215]},
    {input: [0.32, 0.161111111111111], output: [0.139678221561817]},
    {input: [0.28625, 0.130555555555556], output: [0.126094193481215]},
    {input: [0.26375, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.275, 0.161111111111111], output: [0.135150212201616]},
    {input: [0.2075, 0.147222222222222], output: [0.112510165400612]},
    {input: [0.23, 0.197222222222222], output: [0.0808140998792049]},
    {input: [0.213125, 0.183333333333333], output: [0.0762860905190039]},
    {input: [0.2975, 0.152777777777778], output: [0.0989261373200087]},
    {input: [0.348125, 0.155555555555556], output: [0.144206230922018]},
    {input: [0.32, 0.163888888888889], output: [0.157790259002621]},
    {input: [0.291875, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.370625, 0.166666666666667], output: [0.162318268362822]},
    {input: [0.314375, 0.188888888888889], output: [0.175902296443425]},
    {input: [0.494375, 0.211111111111111], output: [0.261934474287243]},
    {input: [0.37625, 0.219444444444444], output: [0.252878455566841]},
    {input: [0.314375, 0.208333333333333], output: [0.239294427486239]},
    {input: [0.275, 0.227777777777778], output: [0.257406464927042]},
    {input: [0.30875, 0.136111111111111], output: [0.216654380685234]},
    {input: [0.2525, 0.197222222222222], output: [0.194014333884229]},
    {input: [0.32, 0.216666666666667], output: [0.166846277723023]},
    {input: [0.325625, 0.230555555555556], output: [0.189486324524028]},
    {input: [0.213125, 0.130555555555556], output: [0.148734240282219]},
    {input: [0.2525, 0.155555555555556], output: [0.121566184121014]},
    {input: [0.185, 0.119444444444444], output: [0.0943981279598077]},
    {input: [0.156875, 0.15], output: [0.0989261373200087]},
    {input: [0.30875, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.37625, 0.191666666666667], output: [0.107982156040411]},
    {input: [0.39875, 0.144444444444444], output: [0.175902296443425]},
    {input: [0.348125, 0.152777777777778], output: [0.166846277723023]},
    {input: [0.449375, 0.144444444444444], output: [0.234766418126038]},
    {input: [0.663125, 0.319444444444444], output: [0.366078689571866]},
    {input: [0.663125, 0.333333333333333], output: [0.470222904856488]},
    {input: [0.528125, 0.363888888888889], output: [0.560783092060507]},
    {input: [0.5, 0.352777777777778], output: [0.542671054619703]},
    {input: [0.494375, 0.380555555555556], output: [0.497390961017693]},
    {input: [0.460625, 0.383333333333333], output: [0.443054848695282]},
    {input: [0.336875, 0.433333333333333], output: [0.424942811254478]},
    {input: [0.426875, 0.433333333333333], output: [0.357022670851464]},
    {input: [0.325625, 0.402777777777778], output: [0.370606698932066]},
    {input: [0.460625, 0.436111111111111], output: [0.397774755093272]},
    {input: [0.370625, 0.330555555555556], output: [0.329854614690258]},
    {input: [0.39875, 0.311111111111111], output: [0.316270586609655]},
    {input: [0.415625, 0.375], output: [0.298158549168851]},
    {input: [0.438125, 0.322222222222222], output: [0.284574521088248]},
    {input: [0.460625, 0.288888888888889], output: [0.429470820614679]},
    {input: [0.53375, 0.202777777777778], output: [0.470222904856488]},
    {input: [0.39875, 0.252777777777778], output: [0.424942811254478]},
    {input: [0.455, 0.194444444444444], output: [0.424942811254478]},
    {input: [0.494375, 0.244444444444444], output: [0.48380693293709]},
    {input: [0.6575, 0.244444444444444], output: [0.57436712014111]},
    {input: [0.66875, 0.241666666666667], output: [0.637759251183923]},
    {input: [0.584375, 0.133333333333333], output: [0.610591195022717]},
    {input: [0.528125, 0.133333333333333], output: [0.565311101420708]},
    {input: [0.426875, 0.222222222222222], output: [0.492862951657492]},
    {input: [0.404375, 0.4], output: [0.402302764453473]},
    {input: [0.30875, 0.522222222222222], output: [0.298158549168851]},
    {input: [0.111875, 0.55], output: [0.302686558529052]},
    {input: [0.055625, 0.713888888888889], output: [0.24382243684644]},
    {input: [0.06125, 0.611111111111111], output: [0.19854234324443]},
    {input: [0.2525, 0.363888888888889], output: [0.162318268362822]},
    {input: [0.303125, 0.291666666666667], output: [0.148734240282219]},
    {input: [0.4325, 0.338888888888889], output: [0.157790259002621]},
    {input: [0.269375, 0.341666666666667], output: [0.175902296443425]},
    {input: [0.2075, 0.458333333333333], output: [0.15326224964242]},
    {input: [0.280625, 0.45], output: [0.166846277723023]},
    {input: [0.26375, 0.488888888888889], output: [0.148734240282219]},
    {input: [0.224375, 0.458333333333333], output: [0.10345414668021]},
    {input: [0.246875, 0.427777777777778], output: [0.0808140998792049]},
    {input: [0.359375, 0.447222222222222], output: [0.112510165400612]},
    {input: [0.21875, 0.402777777777778], output: [0.0943981279598077]},
    {input: [0.258125, 0.336111111111111], output: [0.0989261373200087]},
    {input: [0.275, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.258125, 0.297222222222222], output: [0.130622202841415]},
    {input: [0.269375, 0.233333333333333], output: [0.135150212201616]},
    {input: [0.325625, 0.194444444444444], output: [0.126094193481215]},
    {input: [0.381875, 0.197222222222222], output: [0.166846277723023]},
    {input: [0.32, 0.219444444444444], output: [0.15326224964242]},
    {input: [0.2525, 0.2], output: [0.157790259002621]},
    {input: [0.2525, 0.194444444444444], output: [0.139678221561817]},
    {input: [0.224375, 0.208333333333333], output: [0.139678221561817]},
    {input: [0.23, 0.241666666666667], output: [0.121566184121014]},
    {input: [0.111875, 0.352777777777778], output: [0.0989261373200087]},
    {input: [0.1175, 0.469444444444444], output: [0.067230071798602]},
    {input: [0.134375, 0.391666666666667], output: [0.062702062438401]},
    {input: [0.134375, 0.394444444444444], output: [0.0491180343577981]},
    {input: [0.145625, 0.305555555555556], output: [0.0355340062771953]},
    {input: [0.168125, 0.388888888888889], output: [0.0310059969169943]},
    {input: [0.19625, 0.355555555555556], output: [0.0491180343577981]},
    {input: [0.235625, 0.316666666666667], output: [0.0536460437179991]},
    {input: [0.224375, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.23, 0.383333333333333], output: [0.0989261373200087]},
    {input: [0.168125, 0.305555555555556], output: [0.0762860905190039]},
    {input: [0.168125, 0.336111111111111], output: [0.0943981279598077]},
    {input: [0.23, 0.311111111111111], output: [0.112510165400612]},
    {input: [0.14, 0.325], output: [0.135150212201616]},
    {input: [0.0725, 0.280555555555556], output: [0.126094193481215]},
    {input: [0.19625, 0.252777777777778], output: [0.139678221561817]},
    {input: [0.32, 0.130555555555556], output: [0.148734240282219]},
    {input: [0.35375, 0.119444444444444], output: [0.121566184121014]},
    {input: [0.314375, 0.161111111111111], output: [0.126094193481215]},
    {input: [0.336875, 0.286111111111111], output: [0.157790259002621]},
    {input: [0.336875, 0.277777777777778], output: [0.175902296443425]},
    {input: [0.35375, 0.280555555555556], output: [0.180430305803626]},
    {input: [0.32, 0.261111111111111], output: [0.184958315163827]},
    {input: [0.381875, 0.238888888888889], output: [0.207598361964832]},
    {input: [0.404375, 0.177777777777778], output: [0.257406464927042]},
    {input: [0.39875, 0.186111111111111], output: [0.24835044620664]},
    {input: [0.41, 0.202777777777778], output: [0.266462483647444]},
    {input: [0.393125, 0.216666666666667], output: [0.24382243684644]},
    {input: [0.39875, 0.163888888888889], output: [0.234766418126038]},
    {input: [0.381875, 0.152777777777778], output: [0.266462483647444]},
    {input: [0.365, 0.163888888888889], output: [0.24382243684644]},
    {input: [0.336875, 0.177777777777778], output: [0.221182390045435]},
    {input: [0.269375, 0.180555555555556], output: [0.189486324524028]},
    {input: [0.23, 0.166666666666667], output: [0.171374287083224]},
    {input: [0.19625, 0.202777777777778], output: [0.148734240282219]},
    {input: [0.10625, 0.0888888888888889], output: [0.157790259002621]},
    {input: [0.095, 0.0916666666666667], output: [0.144206230922018]},
    {input: [0.05, 0.972222222222222], output: [0.135150212201616]},
    {input: [0.06125, 0.811111111111111], output: [0.126094193481215]},
    {input: [0.08375, 0.736111111111111], output: [0.10345414668021]},
    {input: [0.078125, 0.811111111111111], output: [0.0808140998792049]},
    {input: [0.168125, 0.825], output: [0.067230071798602]},
    {input: [0.32, 0.844444444444444], output: [0.0808140998792049]},
    {input: [0.303125, 0.802777777777778], output: [0.166846277723023]},
    {input: [0.404375, 0.827777777777778], output: [0.270990493007645]},
    {input: [0.426875, 0.808333333333333], output: [0.334382624050459]},
    {input: [0.483125, 0.822222222222222], output: [0.397774755093272]},
    {input: [0.393125, 0.786111111111111], output: [0.316270586609655]},
    {input: [0.39875, 0.763888888888889], output: [0.284574521088248]},
    {input: [0.3425, 0.761111111111111], output: [0.257406464927042]},
    {input: [0.39875, 0.75], output: [0.275518502367846]},
    {input: [0.348125, 0.772222222222222], output: [0.266462483647444]},
    {input: [0.30875, 0.752777777777778], output: [0.225710399405636]},
    {input: [0.28625, 0.788888888888889], output: [0.194014333884229]},
    {input: [0.275, 0.813888888888889], output: [0.180430305803626]},
    {input: [0.224375, 0.922222222222222], output: [0.148734240282219]},
    {input: [0.15125, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.1175, 0.1], output: [0.0943981279598077]},
    {input: [0.15125, 0.175], output: [0.0762860905190039]},
    {input: [0.17375, 0.125], output: [0.0808140998792049]},
    {input: [0.1625, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.230555555555556], output: [0.0808140998792049]},
    {input: [0.185, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.168125, 0.186111111111111], output: [0.0762860905190039]},
    {input: [0.08375, 0.352777777777778], output: [0.0853421092394058]},
    {input: [0.190625, 0.272222222222222], output: [0.0581740530782001]},
    {input: [0.21875, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.286111111111111], output: [0.0762860905190039]},
    {input: [0.15125, 0.369444444444444], output: [0.062702062438401]},
    {input: [0.201875, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.291875, 0.113888888888889], output: [0.071758081158803]},
    {input: [0.28625, 0.122222222222222], output: [0.0581740530782001]},
    {input: [0.303125, 0.122222222222222], output: [0.0808140998792049]},
    {input: [0.32, 0.144444444444444], output: [0.107982156040411]},
    {input: [0.32, 0.147222222222222], output: [0.126094193481215]},
    {input: [0.35375, 0.15], output: [0.15326224964242]},
    {input: [0.3425, 0.152777777777778], output: [0.148734240282219]},
    {input: [0.314375, 0.152777777777778], output: [0.162318268362822]},
    {input: [0.370625, 0.175], output: [0.19854234324443]},
    {input: [0.35375, 0.166666666666667], output: [0.234766418126038]},
    {input: [0.39875, 0.152777777777778], output: [0.221182390045435]},
    {input: [0.39875, 0.0972222222222222], output: [0.225710399405636]},
    {input: [0.393125, 0.0861111111111111], output: [0.216654380685234]},
    {input: [0.426875, 0.0861111111111111], output: [0.207598361964832]},
    {input: [0.415625, 0.0916666666666667], output: [0.24835044620664]},
    {input: [0.415625, 0.108333333333333], output: [0.280046511728047]},
    {input: [0.370625, 0.138888888888889], output: [0.257406464927042]},
    {input: [0.42125, 0.155555555555556], output: [0.270990493007645]},
    {input: [0.393125, 0.138888888888889], output: [0.24382243684644]},
    {input: [0.2975, 0.108333333333333], output: [0.230238408765837]},
    {input: [0.303125, 0.169444444444444], output: [0.207598361964832]},
    {input: [0.365, 0.125], output: [0.19854234324443]},
    {input: [0.393125, 0.111111111111111], output: [0.221182390045435]},
    {input: [0.42125, 0.0944444444444444], output: [0.24835044620664]},
    {input: [0.455, 0.0472222222222222], output: [0.230238408765837]},
    {input: [0.46625, 0.0527777777777778], output: [0.24835044620664]},
    {input: [0.44375, 0.0555555555555556], output: [0.216654380685234]},
    {input: [0.438125, 0.0638888888888889], output: [0.225710399405636]},
    {input: [0.381875, 0.0555555555555556], output: [0.239294427486239]},
    {input: [0.2975, 0.0555555555555556], output: [0.19854234324443]},
    {input: [0.19625, 0.0277777777777778], output: [0.162318268362822]},
    {input: [0.14, 0.972222222222222], output: [0.107982156040411]},
    {input: [0.10625, 0.122222222222222], output: [0.112510165400612]},
    {input: [0.134375, 0.15], output: [0.112510165400612]},
    {input: [0.0275, 0.805555555555556], output: [0.117038174760813]},
    {input: [0.08375, 0.797222222222222], output: [0.0989261373200087]},
    {input: [0.078125, 0.138888888888889], output: [0.071758081158803]},
    {input: [0.0275, 0.169444444444444], output: [0.071758081158803]},
    {input: [0.089375, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.100625, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.044375, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.078125, 0.808333333333333], output: [0.0581740530782001]},
    {input: [0.145625, 0.777777777777778], output: [0.0581740530782001]},
    {input: [0.190625, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.23, 0.763888888888889], output: [0.0762860905190039]},
    {input: [0.269375, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.280625, 0.794444444444444], output: [0.135150212201616]},
    {input: [0.213125, 0.691666666666667], output: [0.130622202841415]},
    {input: [0.168125, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.19625, 0.947222222222222], output: [0.0762860905190039]},
    {input: [0.168125, 0.972222222222222], output: [0.0581740530782001]},
    {input: [0.21875, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.2075, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.23, 0.238888888888889], output: [0.062702062438401]},
    {input: [0.235625, 0.183333333333333], output: [0.0808140998792049]},
    {input: [0.224375, 0.175], output: [0.135150212201616]},
    {input: [0.23, 0.177777777777778], output: [0.126094193481215]},
    {input: [0.24125, 0.144444444444444], output: [0.117038174760813]},
    {input: [0.2075, 0.108333333333333], output: [0.107982156040411]},
    {input: [0.30875, 0.0666666666666667], output: [0.0989261373200087]},
    {input: [0.404375, 0.113888888888889], output: [0.171374287083224]},
    {input: [0.39875, 0.119444444444444], output: [0.239294427486239]},
    {input: [0.3875, 0.141666666666667], output: [0.252878455566841]},
    {input: [0.4325, 0.158333333333333], output: [0.24382243684644]},
    {input: [0.426875, 0.186111111111111], output: [0.307214567889253]},
    {input: [0.494375, 0.188888888888889], output: [0.316270586609655]},
    {input: [0.5675, 0.155555555555556], output: [0.347966652131062]},
    {input: [0.494375, 0.213888888888889], output: [0.366078689571866]},
    {input: [0.48875, 0.211111111111111], output: [0.325326605330057]},
    {input: [0.48875, 0.138888888888889], output: [0.316270586609655]},
    {input: [0.449375, 0.147222222222222], output: [0.347966652131062]},
    {input: [0.438125, 0.236111111111111], output: [0.29363053980865]},
    {input: [0.275, 0.233333333333333], output: [0.307214567889253]},
    {input: [0.24125, 0.263888888888889], output: [0.302686558529052]},
    {input: [0.471875, 0.0527777777777778], output: [0.429470820614679]},
    {input: [0.4325, 0.0555555555555556], output: [0.424942811254478]},
    {input: [0.303125, 0.0388888888888889], output: [0.302686558529052]},
    {input: [0.275, 0.0222222222222222], output: [0.261934474287243]},
    {input: [0.06125, 0.252777777777778], output: [0.275518502367846]},
    {input: [0.15125, 0.513888888888889], output: [0.252878455566841]},
    {input: [0.05, 0.463888888888889], output: [0.203070352604631]},
    {input: [0.12875, 0.547222222222222], output: [0.194014333884229]},
    {input: [0.0725, 0.580555555555556], output: [0.175902296443425]},
    {input: [0.12875, 0.466666666666667], output: [0.148734240282219]},
    {input: [0.066875, 0.594444444444444], output: [0.121566184121014]},
    {input: [0.03875, 0.291666666666667], output: [0.121566184121014]},
    {input: [0.17375, 0.0416666666666667], output: [0.135150212201616]},
    {input: [0.1625, 0.0333333333333333], output: [0.139678221561817]},
    {input: [0.168125, 0.958333333333333], output: [0.126094193481215]},
    {input: [0.089375, 0.00555555555555556], output: [0.107982156040411]},
    {input: [0.10625, 0.0805555555555556], output: [0.0943981279598077]},
    {input: [0.033125, 0.847222222222222], output: [0.0762860905190039]},
    {input: [0.044375, 0.891666666666667], output: [0.062702062438401]},
    {input: [0.06125, 0.644444444444444], output: [0.0536460437179991]},
    {input: [0.078125, 0.958333333333333], output: [0.0536460437179991]},
    {input: [0.01625, 0.316666666666667], output: [0.062702062438401]},
    {input: [0.17375, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.21875, 0.0388888888888889], output: [0.0400620156373962]},
    {input: [0.258125, 0.0583333333333333], output: [0.0581740530782001]},
    {input: [0.28625, 0.0666666666666667], output: [0.0581740530782001]},
    {input: [0.359375, 0.0666666666666667], output: [0.0989261373200087]},
    {input: [0.365, 0.0833333333333333], output: [0.144206230922018]},
    {input: [0.2975, 0.0694444444444444], output: [0.139678221561817]},
    {input: [0.37625, 0.0944444444444444], output: [0.171374287083224]},
    {input: [0.348125, 0.0888888888888889], output: [0.194014333884229]},
    {input: [0.37625, 0.0833333333333333], output: [0.180430305803626]},
    {input: [0.3425, 0.0861111111111111], output: [0.212126371325033]},
    {input: [0.32, 0.1], output: [0.194014333884229]},
    {input: [0.325625, 0.0888888888888889], output: [0.175902296443425]},
    {input: [0.3875, 0.119444444444444], output: [0.194014333884229]},
    {input: [0.381875, 0.105555555555556], output: [0.225710399405636]},
    {input: [0.314375, 0.122222222222222], output: [0.212126371325033]},
    {input: [0.32, 0.0888888888888889], output: [0.212126371325033]},
    {input: [0.370625, 0.122222222222222], output: [0.225710399405636]},
    {input: [0.325625, 0.116666666666667], output: [0.225710399405636]},
    {input: [0.365, 0.0944444444444444], output: [0.230238408765837]},
    {input: [0.370625, 0.0666666666666667], output: [0.234766418126038]},
    {input: [0.365, 0.0722222222222222], output: [0.230238408765837]},
    {input: [0.348125, 0.0416666666666667], output: [0.207598361964832]},
    {input: [0.275, 0.0222222222222222], output: [0.180430305803626]},
    {input: [0.21875, 0.0694444444444444], output: [0.148734240282219]},
    {input: [0.168125, 0.0888888888888889], output: [0.15326224964242]},
    {input: [0.190625, 0.1], output: [0.171374287083224]},
    {input: [0.213125, 0.105555555555556], output: [0.135150212201616]},
    {input: [0.246875, 0.075], output: [0.148734240282219]},
    {input: [0.213125, 0.122222222222222], output: [0.144206230922018]},
    {input: [0.235625, 0.15], output: [0.144206230922018]},
    {input: [0.2975, 0.158333333333333], output: [0.157790259002621]},
    {input: [0.269375, 0.102777777777778], output: [0.171374287083224]},
    {input: [0.156875, 0.0805555555555556], output: [0.166846277723023]},
    {input: [0.28625, 0.0972222222222222], output: [0.166846277723023]},
    {input: [0.190625, 0.186111111111111], output: [0.180430305803626]},
    {input: [0.23, 0.183333333333333], output: [0.157790259002621]},
    {input: [0.05, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.03875, 0.316666666666667], output: [0.10345414668021]},
    {input: [0.066875, 0.361111111111111], output: [0.130622202841415]},
    {input: [0.044375, 0.988888888888889], output: [0.107982156040411]},
    {input: [0.12875, 0.922222222222222], output: [0.0808140998792049]},
    {input: [0.055625, 0.863888888888889], output: [0.0853421092394058]},
    {input: [0.0275, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.0725, 0.869444444444444], output: [0.071758081158803]},
    {input: [0.089375, 0.511111111111111], output: [0.0491180343577981]},
    {input: [0.0275, 0.0722222222222222], output: [0.0400620156373962]},
    {input: [0.03875, 0.797222222222222], output: [0.0355340062771953]},
    {input: [0.08375, 0.847222222222222], output: [0.0536460437179991]},
    {input: [0.033125, 0.955555555555556], output: [0.0400620156373962]},
    {input: [0.0275, 0.555555555555556], output: [0.0310059969169943]},
    {input: [0.10625, 0.15], output: [0.0400620156373962]},
    {input: [0.14, 0.130555555555556], output: [0.0310059969169943]},
    {input: [0.168125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.14, 0.175], output: [0.0174219688363914]},
    {input: [0.1625, 0.191666666666667], output: [0.0174219688363914]},
    {input: [0.156875, 0.175], output: [0.0400620156373962]},
    {input: [0.15125, 0.225], output: [0.0491180343577981]},
    {input: [0.089375, 0.219444444444444], output: [0.0491180343577981]},
    {input: [0.095, 0.305555555555556], output: [0.0310059969169943]},
    {input: [0.089375, 0.397222222222222], output: [0.0264779875567933]},
    {input: [0.05, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.1175, 0.155555555555556], output: [0.0400620156373962]},
    {input: [0.156875, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.19625, 0.225], output: [0.0581740530782001]},
    {input: [0.1625, 0.355555555555556], output: [0.0581740530782001]},
    {input: [0.275, 0.297222222222222], output: [0.10345414668021]},
    {input: [0.336875, 0.316666666666667], output: [0.144206230922018]},
    {input: [0.359375, 0.288888888888889], output: [0.139678221561817]},
    {input: [0.3875, 0.297222222222222], output: [0.144206230922018]},
    {input: [0.449375, 0.283333333333333], output: [0.19854234324443]},
    {input: [0.3875, 0.238888888888889], output: [0.239294427486239]},
    {input: [0.336875, 0.269444444444444], output: [0.270990493007645]},
    {input: [0.33125, 0.280555555555556], output: [0.225710399405636]},
    {input: [0.393125, 0.272222222222222], output: [0.19854234324443]},
    {input: [0.5, 0.266666666666667], output: [0.275518502367846]},
    {input: [0.4325, 0.233333333333333], output: [0.29363053980865]},
    {input: [0.449375, 0.238888888888889], output: [0.325326605330057]},
    {input: [0.4775, 0.269444444444444], output: [0.329854614690258]},
    {input: [0.48875, 0.238888888888889], output: [0.465694895496287]},
    {input: [0.5675, 0.25], output: [0.497390961017693]},
    {input: [0.55625, 0.244444444444444], output: [0.506446979738095]},
    {input: [0.550625, 0.247222222222222], output: [0.470222904856488]},
    {input: [0.606875, 0.258333333333333], output: [0.465694895496287]},
    {input: [0.640625, 0.227777777777778], output: [0.48380693293709]},
    {input: [0.57875, 0.241666666666667], output: [0.48380693293709]},
    {input: [0.618125, 0.263888888888889], output: [0.461166886136086]},
    {input: [0.573125, 0.241666666666667], output: [0.474750914216689]},
    {input: [0.494375, 0.25], output: [0.443054848695282]},
    {input: [0.415625, 0.308333333333333], output: [0.393246745733071]},
    {input: [0.483125, 0.272222222222222], output: [0.402302764453473]},
    {input: [0.584375, 0.355555555555556], output: [0.393246745733071]},
    {input: [0.516875, 0.336111111111111], output: [0.397774755093272]},
    {input: [0.3875, 0.280555555555556], output: [0.415886792534076]},
    {input: [0.280625, 0.341666666666667], output: [0.393246745733071]},
    {input: [0.460625, 0.433333333333333], output: [0.357022670851464]},
    {input: [0.3875, 0.502777777777778], output: [0.311742577249454]},
    {input: [0.438125, 0.544444444444444], output: [0.252878455566841]},
    {input: [0.37625, 0.536111111111111], output: [0.225710399405636]},
    {input: [0.325625, 0.480555555555556], output: [0.175902296443425]},
    {input: [0.275, 0.508333333333333], output: [0.139678221561817]},
    {input: [0.336875, 0.519444444444444], output: [0.10345414668021]},
    {input: [0.39875, 0.552777777777778], output: [0.166846277723023]},
    {input: [0.348125, 0.513888888888889], output: [0.10345414668021]},
    {input: [0.185, 0.638888888888889], output: [0.0808140998792049]},
    {input: [0.235625, 0.663888888888889], output: [0.0581740530782001]},
    {input: [0.23, 0.683333333333333], output: [0.062702062438401]},
    {input: [0.14, 0.555555555555556], output: [0.0536460437179991]},
    {input: [0.19625, 0.577777777777778], output: [0.0400620156373962]},
    {input: [0.08375, 0.675], output: [0.0355340062771953]},
    {input: [0.0725, 0.858333333333333], output: [0.0989261373200087]},
    {input: [0.12875, 0.122222222222222], output: [0.0808140998792049]},
    {input: [0.089375, 0.247222222222222], output: [0.0762860905190039]},
    {input: [0.46625, 0.602777777777778], output: [0.139678221561817]},
    {input: [0.584375, 0.758333333333333], output: [0.180430305803626]},
    {input: [0.730625, 0.75], output: [0.474750914216689]},
    {input: [0.60125, 0.733333333333333], output: [0.506446979738095]},
    {input: [0.68, 0.733333333333333], output: [0.587951148221713]},
    {input: [0.460625, 0.669444444444444], output: [0.465694895496287]},
    {input: [0.4325, 0.7], output: [0.43399882997488]},
    {input: [0.42125, 0.683333333333333], output: [0.343438642770861]},
    {input: [0.404375, 0.694444444444444], output: [0.320798595969856]},
    {input: [0.336875, 0.677777777777778], output: [0.275518502367846]},
    {input: [0.246875, 0.666666666666667], output: [0.24382243684644]},
    {input: [0.23, 0.616666666666667], output: [0.203070352604631]},
    {input: [0.15125, 0.527777777777778], output: [0.171374287083224]},
    {input: [0.12875, 0.602777777777778], output: [0.126094193481215]},
    {input: [0.123125, 0.463888888888889], output: [0.112510165400612]},
    {input: [0.089375, 0.383333333333333], output: [0.0989261373200087]},
    {input: [0.08375, 0.208333333333333], output: [0.071758081158803]},
    {input: [0.145625, 0.272222222222222], output: [0.062702062438401]},
    {input: [0.17375, 0.405555555555556], output: [0.0536460437179991]},
    {input: [0.168125, 0.372222222222222], output: [0.0491180343577981]},
    {input: [0.23, 0.272222222222222], output: [0.0491180343577981]},
    {input: [0.280625, 0.202777777777778], output: [0.0989261373200087]},
    {input: [0.3875, 0.127777777777778], output: [0.135150212201616]},
    {input: [0.4775, 0.155555555555556], output: [0.252878455566841]},
    {input: [0.53375, 0.136111111111111], output: [0.347966652131062]},
    {input: [0.55625, 0.0861111111111111], output: [0.429470820614679]},
    {input: [0.5, 0.0777777777777778], output: [0.497390961017693]},
    {input: [0.505625, 0.0694444444444444], output: [0.402302764453473]},
    {input: [0.60125, 0.0611111111111111], output: [0.343438642770861]},
    {input: [0.545, 0.0916666666666667], output: [0.406830773813674]},
    {input: [0.359375, 0.163888888888889], output: [0.379662717652468]},
    {input: [0.438125, 0.238888888888889], output: [0.320798595969856]},
    {input: [0.545, 0.227777777777778], output: [0.375134708292267]},
    {input: [0.483125, 0.255555555555556], output: [0.424942811254478]},
    {input: [0.51125, 0.233333333333333], output: [0.452110867415684]},
    {input: [0.57875, 0.238888888888889], output: [0.438526839335081]},
    {input: [0.606875, 0.258333333333333], output: [0.461166886136086]},
    {input: [0.5675, 0.25], output: [0.461166886136086]},
    {input: [0.449375, 0.241666666666667], output: [0.465694895496287]},
    {input: [0.4775, 0.222222222222222], output: [0.510974989098296]},
    {input: [0.618125, 0.230555555555556], output: [0.520031007818698]},
    {input: [0.483125, 0.222222222222222], output: [0.560783092060507]},
    {input: [0.561875, 0.219444444444444], output: [0.515502998458497]},
    {input: [0.561875, 0.183333333333333], output: [0.406830773813674]},
    {input: [0.44375, 0.2], output: [0.316270586609655]},
    {input: [0.438125, 0.258333333333333], output: [0.329854614690258]},
    {input: [0.635, 0.302777777777778], output: [0.357022670851464]},
    {input: [0.41, 0.238888888888889], output: [0.397774755093272]},
    {input: [0.325625, 0.272222222222222], output: [0.424942811254478]},
    {input: [0.393125, 0.338888888888889], output: [0.501918970377894]},
    {input: [0.516875, 0.330555555555556], output: [0.5290870265391]},
    {input: [0.545, 0.486111111111111], output: [0.452110867415684]},
    {input: [0.449375, 0.502777777777778], output: [0.488334942297291]},
    {input: [0.545, 0.536111111111111], output: [0.424942811254478]},
    {input: [0.528125, 0.505555555555556], output: [0.307214567889253]},
    {input: [0.68, 0.530555555555556], output: [0.284574521088248]},
    {input: [0.449375, 0.580555555555556], output: [0.239294427486239]},
    {input: [0.314375, 0.652777777777778], output: [0.144206230922018]},
    {input: [0.629375, 0.652777777777778], output: [0.298158549168851]},
    {input: [0.44375, 0.586111111111111], output: [0.24382243684644]},
    {input: [0.3875, 0.625], output: [0.221182390045435]},
    {input: [0.370625, 0.661111111111111], output: [0.207598361964832]},
    {input: [0.381875, 0.530555555555556], output: [0.194014333884229]},
    {input: [0.348125, 0.455555555555556], output: [0.184958315163827]},
    {input: [0.365, 0.408333333333333], output: [0.166846277723023]},
    {input: [0.528125, 0.461111111111111], output: [0.180430305803626]},
    {input: [0.41, 0.458333333333333], output: [0.216654380685234]},
    {input: [0.449375, 0.377777777777778], output: [0.207598361964832]},
    {input: [0.26375, 0.397222222222222], output: [0.126094193481215]},
    {input: [0.370625, 0.377777777777778], output: [0.117038174760813]},
    {input: [0.28625, 0.386111111111111], output: [0.139678221561817]},
    {input: [0.48875, 0.388888888888889], output: [0.234766418126038]},
    {input: [0.3425, 0.402777777777778], output: [0.189486324524028]},
    {input: [0.3425, 0.275], output: [0.189486324524028]},
    {input: [0.449375, 0.316666666666667], output: [0.184958315163827]},
    {input: [0.426875, 0.297222222222222], output: [0.212126371325033]},
    {input: [0.37625, 0.266666666666667], output: [0.171374287083224]},
    {input: [0.359375, 0.280555555555556], output: [0.19854234324443]},
    {input: [0.4775, 0.283333333333333], output: [0.266462483647444]},
    {input: [0.460625, 0.288888888888889], output: [0.316270586609655]},
    {input: [0.449375, 0.277777777777778], output: [0.270990493007645]},
    {input: [0.438125, 0.277777777777778], output: [0.302686558529052]},
    {input: [0.48875, 0.216666666666667], output: [0.343438642770861]},
    {input: [0.4775, 0.291666666666667], output: [0.370606698932066]},
    {input: [0.46625, 0.302777777777778], output: [0.384190727012669]},
    {input: [0.618125, 0.258333333333333], output: [0.361550680211665]},
    {input: [0.60125, 0.233333333333333], output: [0.438526839335081]},
    {input: [0.629375, 0.258333333333333], output: [0.443054848695282]},
    {input: [0.7475, 0.269444444444444], output: [0.592479157581914]},
    {input: [0.7475, 0.280555555555556], output: [0.62417522310332]},
    {input: [0.674375, 0.361111111111111], output: [0.619647213743119]},
    {input: [0.528125, 0.536111111111111], output: [0.651343279264526]},
    {input: [0.393125, 0.647222222222222], output: [0.424942811254478]},
    {input: [0.640625, 0.766666666666667], output: [0.452110867415684]},
    {input: [0.39875, 0.758333333333333], output: [0.48380693293709]},
    {input: [0.2075, 0.769444444444444], output: [0.370606698932066]},
    {input: [0.1175, 0.488888888888889], output: [0.307214567889253]},
    {input: [0.280625, 0.425], output: [0.203070352604631]},
    {input: [0.365, 0.430555555555556], output: [0.175902296443425]},
    {input: [0.415625, 0.494444444444444], output: [0.162318268362822]},
    {input: [0.438125, 0.427777777777778], output: [0.207598361964832]},
    {input: [0.381875, 0.397222222222222], output: [0.157790259002621]},
    {input: [0.336875, 0.394444444444444], output: [0.194014333884229]},
    {input: [0.246875, 0.308333333333333], output: [0.184958315163827]},
    {input: [0.365, 0.252777777777778], output: [0.194014333884229]},
    {input: [0.365, 0.222222222222222], output: [0.230238408765837]},
    {input: [0.37625, 0.0638888888888889], output: [0.24835044620664]},
    {input: [0.336875, 0.138888888888889], output: [0.203070352604631]},
    {input: [0.4325, 0.227777777777778], output: [0.234766418126038]},
    {input: [0.4325, 0.219444444444444], output: [0.270990493007645]},
    {input: [0.505625, 0.0722222222222222], output: [0.280046511728047]},
    {input: [0.550625, 0.0638888888888889], output: [0.393246745733071]},
    {input: [0.528125, 0.05], output: [0.406830773813674]},
    {input: [0.471875, 0.0444444444444444], output: [0.393246745733071]},
    {input: [0.415625, 0.0527777777777778], output: [0.320798595969856]},
    {input: [0.37625, 0.0416666666666667], output: [0.280046511728047]},
    {input: [0.303125, 0.025], output: [0.252878455566841]},
    {input: [0.303125, 0.05], output: [0.19854234324443]},
    {input: [0.19625, 0.0388888888888889], output: [0.184958315163827]},
    {input: [0.14, 0.0194444444444444], output: [0.144206230922018]},
    {input: [0.12875, 0.997222222222222], output: [0.162318268362822]},
    {input: [0.15125, 0.0583333333333333], output: [0.130622202841415]},
    {input: [0.1175, 0.0888888888888889], output: [0.117038174760813]},
    {input: [0.12875, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.15125, 0.05], output: [0.0808140998792049]},
    {input: [0.185, 0.0638888888888889], output: [0.0853421092394058]},
    {input: [0.15125, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.224375, 0.108333333333333], output: [0.0808140998792049]},
    {input: [0.303125, 0.075], output: [0.107982156040411]},
    {input: [0.291875, 0.0722222222222222], output: [0.166846277723023]},
    {input: [0.26375, 0.0638888888888889], output: [0.144206230922018]},
    {input: [0.168125, 0.258333333333333], output: [0.144206230922018]},
    {input: [0.3875, 0.333333333333333], output: [0.130622202841415]},
    {input: [0.39875, 0.258333333333333], output: [0.15326224964242]},
    {input: [0.44375, 0.225], output: [0.266462483647444]},
    {input: [0.46625, 0.225], output: [0.320798595969856]},
    {input: [0.55625, 0.205555555555556], output: [0.38871873637287]},
    {input: [0.561875, 0.177777777777778], output: [0.406830773813674]},
    {input: [0.584375, 0.263888888888889], output: [0.375134708292267]},
    {input: [0.471875, 0.244444444444444], output: [0.325326605330057]},
    {input: [0.550625, 0.252777777777778], output: [0.38871873637287]},
    {input: [0.41, 0.205555555555556], output: [0.347966652131062]},
    {input: [0.41, 0.211111111111111], output: [0.298158549168851]},
    {input: [0.30875, 0.25], output: [0.29363053980865]},
    {input: [0.336875, 0.236111111111111], output: [0.212126371325033]},
    {input: [0.365, 0.311111111111111], output: [0.225710399405636]},
    {input: [0.336875, 0.466666666666667], output: [0.239294427486239]},
    {input: [0.3425, 0.497222222222222], output: [0.234766418126038]},
    {input: [0.438125, 0.522222222222222], output: [0.234766418126038]},
    {input: [0.449375, 0.727777777777778], output: [0.212126371325033]},
    {input: [0.528125, 0.755555555555556], output: [0.298158549168851]},
    {input: [0.550625, 0.744444444444444], output: [0.443054848695282]},
    {input: [0.449375, 0.711111111111111], output: [0.370606698932066]},
    {input: [0.51125, 0.733333333333333], output: [0.402302764453473]},
    {input: [0.595625, 0.766666666666667], output: [0.38871873637287]},
    {input: [0.5, 0.758333333333333], output: [0.474750914216689]},
    {input: [0.4325, 0.688888888888889], output: [0.406830773813674]},
    {input: [0.438125, 0.663888888888889], output: [0.357022670851464]},
    {input: [0.291875, 0.613888888888889], output: [0.325326605330057]},
    {input: [0.303125, 0.594444444444444], output: [0.24835044620664]},
    {input: [0.314375, 0.638888888888889], output: [0.221182390045435]},
    {input: [0.303125, 0.638888888888889], output: [0.216654380685234]},
    {input: [0.26375, 0.761111111111111], output: [0.225710399405636]},
    {input: [0.2075, 0.677777777777778], output: [0.24382243684644]},
    {input: [0.1175, 0.630555555555556], output: [0.252878455566841]},
    {input: [0.17375, 0.705555555555556], output: [0.225710399405636]},
    {input: [0.1625, 0.588888888888889], output: [0.162318268362822]},
    {input: [0.213125, 0.619444444444444], output: [0.148734240282219]},
    {input: [0.23, 0.655555555555556], output: [0.194014333884229]},
    {input: [0.224375, 0.675], output: [0.216654380685234]},
    {input: [0.291875, 0.688888888888889], output: [0.203070352604631]},
    {input: [0.280625, 0.616666666666667], output: [0.171374287083224]},
    {input: [0.23, 0.683333333333333], output: [0.15326224964242]},
    {input: [0.23, 0.661111111111111], output: [0.157790259002621]},
    {input: [0.1625, 0.0138888888888889], output: [0.135150212201616]},
    {input: [0.089375, 0.0388888888888889], output: [0.0853421092394058]},
    {input: [0.08375, 0.997222222222222], output: [0.071758081158803]},
    {input: [0.08375, 0.922222222222222], output: [0.0581740530782001]},
    {input: [0.055625, 0.841666666666667], output: [0.0581740530782001]},
    {input: [0.12875, 0.744444444444444], output: [0.0400620156373962]},
    {input: [0.426875, 0.736111111111111], output: [0.117038174760813]},
    {input: [0.404375, 0.719444444444444], output: [0.203070352604631]},
    {input: [0.359375, 0.697222222222222], output: [0.221182390045435]},
    {input: [0.5, 0.730555555555556], output: [0.29363053980865]},
    {input: [0.460625, 0.716666666666667], output: [0.488334942297291]},
    {input: [0.438125, 0.725], output: [0.424942811254478]},
    {input: [0.449375, 0.741666666666667], output: [0.411358783173875]},
    {input: [0.393125, 0.747222222222222], output: [0.316270586609655]},
    {input: [0.32, 0.738888888888889], output: [0.280046511728047]},
    {input: [0.314375, 0.744444444444444], output: [0.212126371325033]},
    {input: [0.179375, 0.572222222222222], output: [0.180430305803626]},
    {input: [0.156875, 0.427777777777778], output: [0.126094193481215]},
    {input: [0.280625, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.280625, 0.388888888888889], output: [0.0853421092394058]},
    {input: [0.314375, 0.344444444444444], output: [0.126094193481215]},
    {input: [0.35375, 0.372222222222222], output: [0.157790259002621]},
    {input: [0.314375, 0.283333333333333], output: [0.144206230922018]},
    {input: [0.404375, 0.3], output: [0.189486324524028]},
    {input: [0.404375, 0.327777777777778], output: [0.252878455566841]},
    {input: [0.39875, 0.286111111111111], output: [0.252878455566841]},
    {input: [0.336875, 0.416666666666667], output: [0.284574521088248]},
    {input: [0.415625, 0.280555555555556], output: [0.311742577249454]},
    {input: [0.51125, 0.194444444444444], output: [0.320798595969856]},
    {input: [0.635, 0.155555555555556], output: [0.48380693293709]},
    {input: [0.6575, 0.144444444444444], output: [0.569839110780909]},
    {input: [0.696875, 0.186111111111111], output: [0.542671054619703]},
    {input: [0.7475, 0.202777777777778], output: [0.637759251183923]},
    {input: [0.7025, 0.230555555555556], output: [0.655871288624727]},
    {input: [0.69125, 0.25], output: [0.71926341966754]},
    {input: [0.708125, 0.241666666666667], output: [0.755487494549148]},
    {input: [0.606875, 0.230555555555556], output: [0.642287260544124]},
    {input: [0.550625, 0.222222222222222], output: [0.551727073340105]},
    {input: [0.5675, 0.258333333333333], output: [0.501918970377894]},
    {input: [0.471875, 0.266666666666667], output: [0.492862951657492]},
    {input: [0.438125, 0.291666666666667], output: [0.520031007818698]},
    {input: [0.4325, 0.186111111111111], output: [0.443054848695282]},
    {input: [0.55625, 0.247222222222222], output: [0.393246745733071]},
    {input: [0.6125, 0.219444444444444], output: [0.461166886136086]},
    {input: [0.573125, 0.227777777777778], output: [0.492862951657492]},
    {input: [0.5675, 0.208333333333333], output: [0.470222904856488]},
    {input: [0.545, 0.283333333333333], output: [0.47927892357689]},
    {input: [0.505625, 0.283333333333333], output: [0.515502998458497]},
    {input: [0.455, 0.261111111111111], output: [0.470222904856488]},
    {input: [0.37625, 0.238888888888889], output: [0.38871873637287]},
    {input: [0.201875, 0.244444444444444], output: [0.361550680211665]},
    {input: [0.179375, 0.261111111111111], output: [0.334382624050459]},
    {input: [0.303125, 0], output: [0.302686558529052]},
    {input: [0.2975, 0.0361111111111111], output: [0.252878455566841]},
    {input: [0.26375, 0.983333333333333], output: [0.212126371325033]},
    {input: [0.44375, 0.705555555555556], output: [0.212126371325033]},
    {input: [0.438125, 0.755555555555556], output: [0.24382243684644]},
    {input: [0.46625, 0.722222222222222], output: [0.289102530448449]},
    {input: [0.545, 0.708333333333333], output: [0.343438642770861]},
    {input: [0.6125, 0.719444444444444], output: [0.393246745733071]},
    {input: [0.561875, 0.711111111111111], output: [0.43399882997488]},
    {input: [0.629375, 0.738888888888889], output: [0.547199063979904]},
    {input: [0.57875, 0.725], output: [0.664927307345129]},
    {input: [0.674375, 0.736111111111111], output: [0.664927307345129]},
    {input: [0.60125, 0.705555555555556], output: [0.606063185662516]},
    {input: [0.69125, 0.738888888888889], output: [0.746431475828746]},
    {input: [0.426875, 0.633333333333333], output: [0.592479157581914]},
    {input: [0.190625, 0.525], output: [0.452110867415684]},
    {input: [0.095, 0.338888888888889], output: [0.343438642770861]},
    {input: [0.0725, 0.661111111111111], output: [0.24835044620664]},
    {input: [0.15125, 0.405555555555556], output: [0.207598361964832]},
    {input: [0.258125, 0.305555555555556], output: [0.15326224964242]},
    {input: [0.381875, 0.216666666666667], output: [0.139678221561817]},
    {input: [0.438125, 0.225], output: [0.194014333884229]},
    {input: [0.41, 0.247222222222222], output: [0.184958315163827]},
    {input: [0.595625, 0.219444444444444], output: [0.252878455566841]},
    {input: [0.5225, 0.233333333333333], output: [0.320798595969856]},
    {input: [0.60125, 0.283333333333333], output: [0.406830773813674]},
    {input: [0.5675, 0.327777777777778], output: [0.452110867415684]},
    {input: [0.528125, 0.375], output: [0.415886792534076]},
    {input: [0.550625, 0.419444444444444], output: [0.470222904856488]},
    {input: [0.37625, 0.419444444444444], output: [0.352494661491263]},
    {input: [0.348125, 0.494444444444444], output: [0.280046511728047]},
    {input: [0.30875, 0.519444444444444], output: [0.24382243684644]},
    {input: [0.2525, 0.675], output: [0.19854234324443]},
    {input: [0.516875, 0.758333333333333], output: [0.180430305803626]},
    {input: [0.651875, 0.736111111111111], output: [0.384190727012669]},
    {input: [0.753125, 0.744444444444444], output: [0.520031007818698]},
    {input: [0.719375, 0.736111111111111], output: [0.637759251183923]},
    {input: [0.7475, 0.727777777777778], output: [0.637759251183923]},
    {input: [0.865625, 0.733333333333333], output: [0.741903466468545]},
    {input: [0.86, 0.725], output: [0.909439812795981]},
    {input: [0.966875, 0.736111111111111], output: [0.927551850236785]},
    {input: [0.78125, 0.730555555555556], output: [0.923023840876584]},
    {input: [0.7025, 0.722222222222222], output: [0.673983326065531]},
    {input: [0.640625, 0.741666666666667], output: [0.610591195022717]},
    {input: [0.573125, 0.977777777777778], output: [0.393246745733071]},
    {input: [0.37625, 0.0972222222222222], output: [0.275518502367846]},
    {input: [0.2075, 0.127777777777778], output: [0.252878455566841]},
    {input: [0.235625, 0.133333333333333], output: [0.24835044620664]},
    {input: [0.275, 0.127777777777778], output: [0.329854614690258]},
    {input: [0.33125, 0.108333333333333], output: [0.325326605330057]},
    {input: [0.314375, 0.0944444444444444], output: [0.329854614690258]},
    {input: [0.455, 0.111111111111111], output: [0.411358783173875]},
    {input: [0.5225, 0.0861111111111111], output: [0.488334942297291]},
    {input: [0.539375, 0.0833333333333333], output: [0.48380693293709]},
    {input: [0.51125, 0.0833333333333333], output: [0.38871873637287]},
    {input: [0.494375, 0.075], output: [0.411358783173875]},
    {input: [0.471875, 0.0833333333333333], output: [0.375134708292267]},
    {input: [0.449375, 0.0972222222222222], output: [0.370606698932066]},
    {input: [0.449375, 0.111111111111111], output: [0.379662717652468]},
    {input: [0.42125, 0.105555555555556], output: [0.370606698932066]},
    {input: [0.42125, 0.105555555555556], output: [0.375134708292267]},
    {input: [0.404375, 0.116666666666667], output: [0.384190727012669]},
    {input: [0.3875, 0.111111111111111], output: [0.43399882997488]},
    {input: [0.370625, 0.0916666666666667], output: [0.357022670851464]},
    {input: [0.291875, 0.0861111111111111], output: [0.329854614690258]},
    {input: [0.224375, 0.105555555555556], output: [0.307214567889253]},
    {input: [0.224375, 0.105555555555556], output: [0.284574521088248]},
    {input: [0.19625, 0.0777777777777778], output: [0.261934474287243]},
    {input: [0.2525, 0.0833333333333333], output: [0.261934474287243]},
    {input: [0.269375, 0.0861111111111111], output: [0.298158549168851]},
    {input: [0.280625, 0.0972222222222222], output: [0.307214567889253]},
    {input: [0.303125, 0.0972222222222222], output: [0.298158549168851]},
    {input: [0.280625, 0.102777777777778], output: [0.29363053980865]},
    {input: [0.201875, 0.0777777777777778], output: [0.257406464927042]},
    {input: [0.246875, 0.0944444444444444], output: [0.261934474287243]},
    {input: [0.26375, 0.1], output: [0.225710399405636]},
    {input: [0.2075, 0.127777777777778], output: [0.216654380685234]},
    {input: [0.23, 0.127777777777778], output: [0.212126371325033]},
    {input: [0.325625, 0.130555555555556], output: [0.234766418126038]},
    {input: [0.359375, 0.113888888888889], output: [0.261934474287243]},
    {input: [0.44375, 0.133333333333333], output: [0.284574521088248]},
    {input: [0.42125, 0.130555555555556], output: [0.302686558529052]},
    {input: [0.426875, 0.1], output: [0.334382624050459]},
    {input: [0.37625, 0.113888888888889], output: [0.361550680211665]},
    {input: [0.426875, 0.111111111111111], output: [0.366078689571866]},
    {input: [0.42125, 0.141666666666667], output: [0.375134708292267]},
    {input: [0.42125, 0.116666666666667], output: [0.329854614690258]},
    {input: [0.48875, 0.0888888888888889], output: [0.361550680211665]},
    {input: [0.505625, 0.0888888888888889], output: [0.343438642770861]},
    {input: [0.55625, 0.0722222222222222], output: [0.393246745733071]},
    {input: [0.528125, 0.075], output: [0.384190727012669]},
    {input: [0.4325, 0.0555555555555556], output: [0.397774755093272]},
    {input: [0.314375, 0.0527777777777778], output: [0.393246745733071]},
    {input: [0.348125, 0.0777777777777778], output: [0.33891063341066]},
    {input: [0.3425, 0.0722222222222222], output: [0.334382624050459]},
    {input: [0.359375, 0.0777777777777778], output: [0.325326605330057]},
    {input: [0.393125, 0.0833333333333333], output: [0.311742577249454]},
    {input: [0.370625, 0.1], output: [0.284574521088248]},
    {input: [0.275, 0.0805555555555556], output: [0.289102530448449]},
    {input: [0.32, 0.0305555555555556], output: [0.266462483647444]},
    {input: [0.42125, 0.075], output: [0.261934474287243]},
    {input: [0.5225, 0.0694444444444444], output: [0.334382624050459]},
    {input: [0.51125, 0.0638888888888889], output: [0.347966652131062]},
    {input: [0.561875, 0.0694444444444444], output: [0.438526839335081]},
    {input: [0.69125, 0.0944444444444444], output: [0.723791429027742]},
    {input: [0.494375, 0.0888888888888889], output: [0.583423138861512]},
    {input: [0.455, 0.0388888888888889], output: [0.452110867415684]},
    {input: [0.708125, 0.0583333333333333], output: [0.62417522310332]},
    {input: [0.57875, 0.0888888888888889], output: [0.651343279264526]},
    {input: [0.753125, 0.119444444444444], output: [0.705679391586938]},
    {input: [0.545, 0.111111111111111], output: [0.746431475828746]},
    {input: [0.573125, 0.0833333333333333], output: [0.678511335425732]},
    {input: [0.708125, 0.0833333333333333], output: [0.678511335425732]},
    {input: [0.865625, 0.0944444444444444], output: [0.741903466468545]},
    {input: [0.910625, 0.0916666666666667], output: [0.864159719193971]},
    {input: [0.87125, 0.125], output: [1]},
    {input: [0.80375, 0.127777777777778], output: [0.936607868957187]},
    {input: [0.71375, 0.283333333333333], output: [0.841519672392966]},
    {input: [0.775625, 0.283333333333333], output: [0.673983326065531]},
    {input: [0.635, 0.275], output: [0.728319438387942]},
    {input: [0.415625, 0.263888888888889], output: [0.597007166942115]},
    {input: [0.460625, 0.355555555555556], output: [0.569839110780909]},
    {input: [0.415625, 0.263888888888889], output: [0.5290870265391]},
    {input: [0.48875, 0.252777777777778], output: [0.501918970377894]},
    {input: [0.471875, 0.263888888888889], output: [0.411358783173875]},
    {input: [0.48875, 0.3], output: [0.33891063341066]},
    {input: [0.55625, 0.316666666666667], output: [0.347966652131062]},
    {input: [0.44375, 0.394444444444444], output: [0.316270586609655]},
    {input: [0.37625, 0.477777777777778], output: [0.230238408765837]},
    {input: [0.494375, 0.491666666666667], output: [0.180430305803626]},
    {input: [0.404375, 0.525], output: [0.171374287083224]},
    {input: [0.32, 0.566666666666667], output: [0.166846277723023]},
    {input: [0.471875, 0.591666666666667], output: [0.175902296443425]},
    {input: [0.325625, 0.522222222222222], output: [0.157790259002621]},
    {input: [0.30875, 0.486111111111111], output: [0.135150212201616]},
    {input: [0.2075, 0.472222222222222], output: [0.0898701185996068]},
    {input: [0.2525, 0.463888888888889], output: [0.0581740530782001]},
    {input: [0.26375, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.201875, 0.138888888888889], output: [0.071758081158803]},
    {input: [0.280625, 0.111111111111111], output: [0.139678221561817]},
    {input: [0.291875, 0.113888888888889], output: [0.171374287083224]},
    {input: [0.314375, 0.0777777777777778], output: [0.148734240282219]},
    {input: [0.269375, 0.0944444444444444], output: [0.135150212201616]},
    {input: [0.179375, 0.0722222222222222], output: [0.157790259002621]},
    {input: [0.033125, 0.444444444444444], output: [0.126094193481215]},
    {input: [0.111875, 0.488888888888889], output: [0.0989261373200087]},
    {input: [0.2525, 0.472222222222222], output: [0.0943981279598077]},
    {input: [0.185, 0.402777777777778], output: [0.0762860905190039]},
    {input: [0.21875, 0.0722222222222222], output: [0.0898701185996068]},
    {input: [0.28625, 0.0555555555555556], output: [0.126094193481215]},
    {input: [0.303125, 0.0638888888888889], output: [0.171374287083224]},
    {input: [0.2525, 0.0944444444444444], output: [0.189486324524028]},
    {input: [0.2975, 0.105555555555556], output: [0.194014333884229]},
    {input: [0.3425, 0.0888888888888889], output: [0.207598361964832]},
    {input: [0.3425, 0.0972222222222222], output: [0.189486324524028]},
    {input: [0.370625, 0.0833333333333333], output: [0.175902296443425]},
    {input: [0.30875, 0.0972222222222222], output: [0.19854234324443]},
    {input: [0.404375, 0.0888888888888889], output: [0.184958315163827]},
    {input: [0.455, 0.108333333333333], output: [0.234766418126038]},
    {input: [0.471875, 0.0916666666666667], output: [0.270990493007645]},
    {input: [0.516875, 0.0888888888888889], output: [0.302686558529052]},
    {input: [0.66875, 0.0833333333333333], output: [0.393246745733071]},
    {input: [0.606875, 0.075], output: [0.447582858055483]},
    {input: [0.635, 0.0722222222222222], output: [0.452110867415684]},
    {input: [0.685625, 0.0944444444444444], output: [0.501918970377894]},
    {input: [0.71375, 0.0527777777777778], output: [0.506446979738095]},
    {input: [0.68, 0.0583333333333333], output: [0.470222904856488]},
    {input: [0.7025, 0.0861111111111111], output: [0.524559017178899]},
    {input: [0.725, 0.0944444444444444], output: [0.583423138861512]},
    {input: [0.68, 0.0944444444444444], output: [0.597007166942115]},
    {input: [0.6575, 0.0833333333333333], output: [0.619647213743119]},
    {input: [0.68, 0.0666666666666667], output: [0.542671054619703]},
    {input: [0.640625, 0.105555555555556], output: [0.5290870265391]},
    {input: [0.66875, 0.15], output: [0.547199063979904]},
    {input: [0.57875, 0.141666666666667], output: [0.497390961017693]},
    {input: [0.59, 0.244444444444444], output: [0.569839110780909]},
    {input: [0.528125, 0.269444444444444], output: [0.452110867415684]},
    {input: [0.59, 0.280555555555556], output: [0.443054848695282]},
    {input: [0.46625, 0.255555555555556], output: [0.429470820614679]},
    {input: [0.3875, 0.241666666666667], output: [0.375134708292267]},
    {input: [0.426875, 0.252777777777778], output: [0.375134708292267]},
    {input: [0.381875, 0.197222222222222], output: [0.352494661491263]},
    {input: [0.415625, 0.0944444444444444], output: [0.361550680211665]},
    {input: [0.46625, 0.0833333333333333], output: [0.343438642770861]},
    {input: [0.455, 0.0972222222222222], output: [0.302686558529052]},
    {input: [0.44375, 0.113888888888889], output: [0.302686558529052]},
    {input: [0.471875, 0.0972222222222222], output: [0.289102530448449]},
    {input: [0.426875, 0.0861111111111111], output: [0.275518502367846]},
    {input: [0.438125, 0.0777777777777778], output: [0.261934474287243]},
    {input: [0.3425, 0.1], output: [0.252878455566841]},
    {input: [0.393125, 0.108333333333333], output: [0.270990493007645]},
    {input: [0.415625, 0.0972222222222222], output: [0.261934474287243]},
    {input: [0.37625, 0.0805555555555556], output: [0.221182390045435]},
    {input: [0.365, 0.0916666666666667], output: [0.207598361964832]},
    {input: [0.32, 0.1], output: [0.203070352604631]},
    {input: [0.33125, 0.1], output: [0.184958315163827]},
    {input: [0.370625, 0.05], output: [0.19854234324443]},
    {input: [0.303125, 0.0861111111111111], output: [0.180430305803626]},
    {input: [0.33125, 0.122222222222222], output: [0.180430305803626]},
    {input: [0.32, 0.105555555555556], output: [0.203070352604631]},
    {input: [0.314375, 0.127777777777778], output: [0.212126371325033]},
    {input: [0.168125, 0.216666666666667], output: [0.175902296443425]},
    {input: [0.123125, 0.125], output: [0.130622202841415]},
    {input: [0.246875, 0.172222222222222], output: [0.121566184121014]},
    {input: [0.100625, 0.280555555555556], output: [0.112510165400612]},
    {input: [0.156875, 0.0944444444444444], output: [0.0943981279598077]},
    {input: [0.224375, 0.122222222222222], output: [0.107982156040411]},
    {input: [0.185, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.201875, 0.138888888888889], output: [0.0989261373200087]},
    {input: [0.08375, 0.305555555555556], output: [0.0989261373200087]},
    {input: [0.21875, 0.236111111111111], output: [0.0762860905190039]},
    {input: [0.201875, 0.188888888888889], output: [0.0853421092394058]},
    {input: [0.291875, 0.188888888888889], output: [0.0989261373200087]},
    {input: [0.224375, 0.108333333333333], output: [0.0943981279598077]},
    {input: [0.168125, 0.180555555555556], output: [0.112510165400612]},
    {input: [0.05, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.190625, 0.161111111111111], output: [0.121566184121014]},
    {input: [0.291875, 0.1], output: [0.117038174760813]},
    {input: [0.055625, 0.4], output: [0.107982156040411]},
    {input: [0.095, 0.2], output: [0.112510165400612]},
    {input: [0.2975, 0.105555555555556], output: [0.121566184121014]},
    {input: [0.39875, 0.102777777777778], output: [0.144206230922018]},
    {input: [0.37625, 0.1], output: [0.180430305803626]},
    {input: [0.393125, 0.0805555555555556], output: [0.175902296443425]},
    {input: [0.39875, 0.0972222222222222], output: [0.15326224964242]},
    {input: [0.33125, 0.0861111111111111], output: [0.189486324524028]},
    {input: [0.30875, 0.0666666666666667], output: [0.171374287083224]},
    {input: [0.26375, 0.0777777777777778], output: [0.139678221561817]},
    {input: [0.23, 0.1], output: [0.130622202841415]},
    {input: [0.168125, 0.166666666666667], output: [0.130622202841415]},
    {input: [0.269375, 0.116666666666667], output: [0.135150212201616]},
    {input: [0.246875, 0.161111111111111], output: [0.148734240282219]},
    {input: [0.246875, 0.163888888888889], output: [0.139678221561817]},
    {input: [0.213125, 0.2], output: [0.135150212201616]},
    {input: [0.14, 0.241666666666667], output: [0.126094193481215]},
    {input: [0.10625, 0.236111111111111], output: [0.121566184121014]},
    {input: [0.12875, 0.216666666666667], output: [0.126094193481215]},
    {input: [0.134375, 0.133333333333333], output: [0.126094193481215]},
    {input: [0.145625, 0.161111111111111], output: [0.117038174760813]},
    {input: [0.185, 0.25], output: [0.126094193481215]},
    {input: [0.3875, 0.161111111111111], output: [0.184958315163827]},
    {input: [0.359375, 0.155555555555556], output: [0.19854234324443]},
    {input: [0.51125, 0.127777777777778], output: [0.298158549168851]},
    {input: [0.4325, 0.291666666666667], output: [0.289102530448449]},
    {input: [0.42125, 0.316666666666667], output: [0.38871873637287]},
    {input: [0.39875, 0.247222222222222], output: [0.325326605330057]},
    {input: [0.5225, 0.255555555555556], output: [0.302686558529052]},
    {input: [0.5, 0.286111111111111], output: [0.334382624050459]},
    {input: [0.460625, 0.272222222222222], output: [0.393246745733071]},
    {input: [0.6125, 0.258333333333333], output: [0.38871873637287]},
    {input: [0.471875, 0.322222222222222], output: [0.406830773813674]},
    {input: [0.516875, 0.244444444444444], output: [0.420414801894277]},
    {input: [0.291875, 0.358333333333333], output: [0.384190727012669]},
    {input: [0.3875, 0.294444444444444], output: [0.375134708292267]},
    {input: [0.404375, 0.247222222222222], output: [0.329854614690258]},
    {input: [0.516875, 0.255555555555556], output: [0.366078689571866]},
    {input: [0.365, 0.294444444444444], output: [0.384190727012669]},
    {input: [0.185, 0.336111111111111], output: [0.352494661491263]},
    {input: [0.404375, 0.341666666666667], output: [0.347966652131062]},
    {input: [0.48875, 0.325], output: [0.316270586609655]},
    {input: [0.460625, 0.277777777777778], output: [0.361550680211665]},
    {input: [0.471875, 0.338888888888889], output: [0.307214567889253]},
    {input: [0.370625, 0.275], output: [0.252878455566841]},
    {input: [0.438125, 0.347222222222222], output: [0.221182390045435]},
    {input: [0.415625, 0.363888888888889], output: [0.19854234324443]},
    {input: [0.41, 0.380555555555556], output: [0.189486324524028]},
    {input: [0.325625, 0.369444444444444], output: [0.162318268362822]},
    {input: [0.258125, 0.338888888888889], output: [0.144206230922018]},
    {input: [0.23, 0.291666666666667], output: [0.139678221561817]},
    {input: [0.32, 0.244444444444444], output: [0.171374287083224]},
    {input: [0.275, 0.222222222222222], output: [0.184958315163827]},
    {input: [0.2975, 0.272222222222222], output: [0.194014333884229]},
    {input: [0.2975, 0.208333333333333], output: [0.203070352604631]},
    {input: [0.370625, 0.202777777777778], output: [0.19854234324443]},
    {input: [0.449375, 0.177777777777778], output: [0.221182390045435]},
    {input: [0.584375, 0.138888888888889], output: [0.366078689571866]},
    {input: [0.595625, 0.127777777777778], output: [0.443054848695282]},
    {input: [0.4325, 0.0305555555555556], output: [0.357022670851464]},
    {input: [0.483125, 0.05], output: [0.311742577249454]},
    {input: [0.460625, 0.0555555555555556], output: [0.280046511728047]},
    {input: [0.4325, 0.075], output: [0.284574521088248]},
    {input: [0.545, 0.0805555555555556], output: [0.311742577249454]},
    {input: [0.5675, 0.0694444444444444], output: [0.357022670851464]},
    {input: [0.545, 0.0694444444444444], output: [0.343438642770861]},
    {input: [0.561875, 0.0694444444444444], output: [0.275518502367846]},
    {input: [0.471875, 0.0722222222222222], output: [0.298158549168851]},
    {input: [0.55625, 0.0805555555555556], output: [0.320798595969856]},
    {input: [0.494375, 0.0861111111111111], output: [0.311742577249454]},
    {input: [0.539375, 0.0972222222222222], output: [0.329854614690258]},
    {input: [0.528125, 0.163888888888889], output: [0.320798595969856]},
    {input: [0.494375, 0.233333333333333], output: [0.320798595969856]},
    {input: [0.415625, 0.208333333333333], output: [0.302686558529052]},
    {input: [0.404375, 0.247222222222222], output: [0.24382243684644]},
    {input: [0.393125, 0.202777777777778], output: [0.207598361964832]},
    {input: [0.32, 0.238888888888889], output: [0.239294427486239]},
    {input: [0.3875, 0.255555555555556], output: [0.225710399405636]},
    {input: [0.4325, 0.230555555555556], output: [0.239294427486239]},
    {input: [0.2075, 0.205555555555556], output: [0.212126371325033]},
    {input: [0.314375, 0.2], output: [0.212126371325033]},
    {input: [0.258125, 0.188888888888889], output: [0.189486324524028]},
    {input: [0.404375, 0.275], output: [0.194014333884229]},
    {input: [0.483125, 0.213888888888889], output: [0.225710399405636]},
    {input: [0.258125, 0.180555555555556], output: [0.171374287083224]},
    {input: [0.2075, 0.116666666666667], output: [0.135150212201616]},
    {input: [0.3425, 0.155555555555556], output: [0.144206230922018]},
    {input: [0.314375, 0.261111111111111], output: [0.180430305803626]},
    {input: [0.359375, 0.230555555555556], output: [0.207598361964832]},
    {input: [0.471875, 0.222222222222222], output: [0.24835044620664]},
    {input: [0.426875, 0.236111111111111], output: [0.275518502367846]},
    {input: [0.3875, 0.258333333333333], output: [0.221182390045435]},
    {input: [0.3425, 0.269444444444444], output: [0.212126371325033]},
    {input: [0.303125, 0.25], output: [0.175902296443425]},
    {input: [0.26375, 0.197222222222222], output: [0.148734240282219]},
    {input: [0.224375, 0.205555555555556], output: [0.15326224964242]},
    {input: [0.275, 0.358333333333333], output: [0.112510165400612]},
    {input: [0.336875, 0.380555555555556], output: [0.126094193481215]},
    {input: [0.3425, 0.222222222222222], output: [0.107982156040411]},
    {input: [0.365, 0.175], output: [0.121566184121014]},
    {input: [0.455, 0.183333333333333], output: [0.162318268362822]},
    {input: [0.325625, 0.241666666666667], output: [0.157790259002621]},
    {input: [0.28625, 0.402777777777778], output: [0.203070352604631]},
    {input: [0.280625, 0.277777777777778], output: [0.135150212201616]},
    {input: [0.21875, 0.430555555555556], output: [0.148734240282219]},
    {input: [0.201875, 0.361111111111111], output: [0.112510165400612]},
    {input: [0.100625, 0.213888888888889], output: [0.0989261373200087]},
    {input: [0.269375, 0.383333333333333], output: [0.10345414668021]},
    {input: [0.1625, 0.369444444444444], output: [0.121566184121014]},
    {input: [0.1625, 0.302777777777778], output: [0.10345414668021]},
    {input: [0.275, 0.316666666666667], output: [0.0808140998792049]},
    {input: [0.275, 0.280555555555556], output: [0.0762860905190039]},
    {input: [0.2525, 0.272222222222222], output: [0.0943981279598077]},
    {input: [0.185, 0.233333333333333], output: [0.0581740530782001]},
    {input: [0.1625, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.19625, 0.0888888888888889], output: [0.0581740530782001]},
    {input: [0.201875, 0.116666666666667], output: [0.0491180343577981]},
    {input: [0.33125, 0.0805555555555556], output: [0.0989261373200087]},
    {input: [0.3425, 0.0861111111111111], output: [0.15326224964242]},
    {input: [0.359375, 0.0583333333333333], output: [0.184958315163827]},
    {input: [0.303125, 0.0861111111111111], output: [0.212126371325033]},
    {input: [0.303125, 0.144444444444444], output: [0.19854234324443]},
    {input: [0.325625, 0.111111111111111], output: [0.225710399405636]},
    {input: [0.336875, 0.0583333333333333], output: [0.19854234324443]},
    {input: [0.365, 0.025], output: [0.184958315163827]},
    {input: [0.2975, 0.0666666666666667], output: [0.144206230922018]},
    {input: [0.190625, 0.133333333333333], output: [0.121566184121014]},
    {input: [0.314375, 0.0611111111111111], output: [0.130622202841415]},
    {input: [0.19625, 0.2], output: [0.130622202841415]},
    {input: [0.179375, 0.175], output: [0.117038174760813]},
    {input: [0.1625, 0.191666666666667], output: [0.0808140998792049]},
    {input: [0.185, 0.105555555555556], output: [0.071758081158803]},
    {input: [0.185, 0.0888888888888889], output: [0.0581740530782001]},
    {input: [0.213125, 0.15], output: [0.0581740530782001]},
    {input: [0.15125, 0.180555555555556], output: [0.0491180343577981]},
    {input: [0.033125, 0.177777777777778], output: [0.062702062438401]},
    {input: [0.14, 0.125], output: [0.0581740530782001]},
    {input: [0.168125, 0.0666666666666667], output: [0.071758081158803]},
    {input: [0.23, 0.0833333333333333], output: [0.071758081158803]},
    {input: [0.156875, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.291875, 0.0111111111111111], output: [0.126094193481215]},
    {input: [0.275, 0.0888888888888889], output: [0.139678221561817]},
    {input: [0.269375, 0.222222222222222], output: [0.270990493007645]},
    {input: [0.365, 0.236111111111111], output: [0.266462483647444]},
    {input: [0.438125, 0.147222222222222], output: [0.289102530448449]},
    {input: [0.483125, 0.130555555555556], output: [0.24835044620664]},
    {input: [0.48875, 0.2], output: [0.266462483647444]},
    {input: [0.55625, 0.258333333333333], output: [0.261934474287243]},
    {input: [0.460625, 0.325], output: [0.298158549168851]},
    {input: [0.275, 0.397222222222222], output: [0.257406464927042]},
    {input: [0.145625, 0.297222222222222], output: [0.203070352604631]},
    {input: [0.21875, 0.336111111111111], output: [0.148734240282219]},
    {input: [0.291875, 0.338888888888889], output: [0.135150212201616]},
    {input: [0.33125, 0.291666666666667], output: [0.162318268362822]},
    {input: [0.280625, 0.247222222222222], output: [0.135150212201616]},
    {input: [0.35375, 0.283333333333333], output: [0.148734240282219]},
    {input: [0.258125, 0.25], output: [0.162318268362822]},
    {input: [0.3425, 0.247222222222222], output: [0.15326224964242]},
    {input: [0.303125, 0.141666666666667], output: [0.148734240282219]},
    {input: [0.258125, 0.263888888888889], output: [0.162318268362822]},
    {input: [0.325625, 0.269444444444444], output: [0.221182390045435]},
    {input: [0.280625, 0.283333333333333], output: [0.230238408765837]},
    {input: [0.365, 0.355555555555556], output: [0.252878455566841]},
    {input: [0.336875, 0.325], output: [0.24382243684644]},
    {input: [0.291875, 0.388888888888889], output: [0.216654380685234]},
    {input: [0.359375, 0.402777777777778], output: [0.175902296443425]},
    {input: [0.348125, 0.361111111111111], output: [0.166846277723023]},
    {input: [0.460625, 0.480555555555556], output: [0.162318268362822]},
    {input: [0.455, 0.505555555555556], output: [0.225710399405636]},
    {input: [0.381875, 0.480555555555556], output: [0.171374287083224]},
    {input: [0.044375, 0.263888888888889], output: [0.107982156040411]},
    {input: [0.336875, 0.397222222222222], output: [0.126094193481215]},
    {input: [0.30875, 0.25], output: [0.130622202841415]},
    {input: [0.33125, 0.241666666666667], output: [0.15326224964242]},
    {input: [0.32, 0.241666666666667], output: [0.15326224964242]},
    {input: [0.26375, 0.175], output: [0.139678221561817]},
    {input: [0.213125, 0.147222222222222], output: [0.144206230922018]},
    {input: [0.21875, 0.0861111111111111], output: [0.107982156040411]},
    {input: [0.303125, 0.0861111111111111], output: [0.107982156040411]},
    {input: [0.336875, 0.0944444444444444], output: [0.144206230922018]},
    {input: [0.483125, 0.0722222222222222], output: [0.194014333884229]},
    {input: [0.539375, 0.211111111111111], output: [0.316270586609655]},
    {input: [0.7025, 0.122222222222222], output: [0.461166886136086]},
    {input: [0.573125, 0.102777777777778], output: [0.456638876775885]},
    {input: [0.46625, 0.0861111111111111], output: [0.43399882997488]},
    {input: [0.539375, 0.0722222222222222], output: [0.384190727012669]},
    {input: [0.6125, 0.0972222222222222], output: [0.370606698932066]},
    {input: [0.545, 0.075], output: [0.474750914216689]},
    {input: [0.539375, 0.122222222222222], output: [0.470222904856488]},
    {input: [0.561875, 0.136111111111111], output: [0.375134708292267]},
    {input: [0.404375, 0.0944444444444444], output: [0.325326605330057]},
    {input: [0.291875, 0.0861111111111111], output: [0.302686558529052]},
    {input: [0.2525, 0.0805555555555556], output: [0.284574521088248]},
    {input: [0.3425, 0.0611111111111111], output: [0.216654380685234]},
    {input: [0.3425, 0.075], output: [0.216654380685234]},
    {input: [0.24125, 0.075], output: [0.189486324524028]},
    {input: [0.190625, 0.0722222222222222], output: [0.162318268362822]},
    {input: [0.179375, 0.0527777777777778], output: [0.157790259002621]},
    {input: [0.14, 0.0444444444444444], output: [0.144206230922018]},
    {input: [0.134375, 0.886111111111111], output: [0.15326224964242]},
    {input: [0.10625, 0.85], output: [0.112510165400612]},
    {input: [0.100625, 0.877777777777778], output: [0.0989261373200087]},
    {input: [0.123125, 0.786111111111111], output: [0.0808140998792049]},
    {input: [0.145625, 0.622222222222222], output: [0.0581740530782001]},
    {input: [0.021875, 0.0944444444444444], output: [0.0491180343577981]},
    {input: [0.01625, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.100625, 0.00555555555555556], output: [0.0445900249975972]},
    {input: [0.111875, 0.183333333333333], output: [0.0400620156373962]},
    {input: [0.179375, 0.186111111111111], output: [0.0310059969169943]},
    {input: [0.168125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.291875, 0.122222222222222], output: [0.0310059969169943]},
    {input: [0.24125, 0.0888888888888889], output: [0.0491180343577981]},
    {input: [0.269375, 0.0722222222222222], output: [0.071758081158803]},
    {input: [0.336875, 0.0916666666666667], output: [0.112510165400612]},
    {input: [0.37625, 0.0861111111111111], output: [0.144206230922018]},
    {input: [0.4325, 0.0972222222222222], output: [0.175902296443425]},
    {input: [0.325625, 0.183333333333333], output: [0.171374287083224]},
    {input: [0.314375, 0.238888888888889], output: [0.144206230922018]},
    {input: [0.235625, 0.275], output: [0.207598361964832]},
    {input: [0.359375, 0.275], output: [0.257406464927042]},
    {input: [0.3425, 0.255555555555556], output: [0.216654380685234]},
    {input: [0.460625, 0.225], output: [0.29363053980865]},
    {input: [0.438125, 0.213888888888889], output: [0.361550680211665]},
    {input: [0.44375, 0.330555555555556], output: [0.298158549168851]},
    {input: [0.426875, 0.394444444444444], output: [0.261934474287243]},
    {input: [0.269375, 0.397222222222222], output: [0.266462483647444]},
    {input: [0.111875, 0.444444444444444], output: [0.230238408765837]},
    {input: [0.05, 0.513888888888889], output: [0.184958315163827]},
    {input: [0.055625, 0.594444444444444], output: [0.180430305803626]},
    {input: [0.111875, 0.605555555555556], output: [0.135150212201616]},
    {input: [0.15125, 0.694444444444444], output: [0.0853421092394058]},
    {input: [0.1625, 0.719444444444444], output: [0.0808140998792049]},
    {input: [0.078125, 0.688888888888889], output: [0.0808140998792049]},
    {input: [0.14, 0.775], output: [0.067230071798602]},
    {input: [0.123125, 0.886111111111111], output: [0.0536460437179991]},
    {input: [0.078125, 0.386111111111111], output: [0.0536460437179991]},
    {input: [0.055625, 0.527777777777778], output: [0.0445900249975972]},
    {input: [0.12875, 0.936111111111111], output: [0.0310059969169943]},
    {input: [0.12875, 0.572222222222222], output: [0.0581740530782001]},
    {input: [0.05, 0.561111111111111], output: [0.062702062438401]},
    {input: [0.089375, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.393125, 0.158333333333333], output: [0.10345414668021]},
    {input: [0.325625, 0.166666666666667], output: [0.15326224964242]},
    {input: [0.33125, 0.138888888888889], output: [0.15326224964242]},
    {input: [0.370625, 0.158333333333333], output: [0.15326224964242]},
    {input: [0.42125, 0.15], output: [0.19854234324443]},
    {input: [0.37625, 0.194444444444444], output: [0.184958315163827]},
    {input: [0.33125, 0.172222222222222], output: [0.175902296443425]},
    {input: [0.3425, 0.155555555555556], output: [0.162318268362822]},
    {input: [0.359375, 0.155555555555556], output: [0.175902296443425]},
    {input: [0.32, 0.130555555555556], output: [0.189486324524028]},
    {input: [0.291875, 0.116666666666667], output: [0.162318268362822]},
    {input: [0.494375, 0.105555555555556], output: [0.184958315163827]},
    {input: [0.438125, 0.152777777777778], output: [0.225710399405636]},
    {input: [0.3875, 0.138888888888889], output: [0.24835044620664]},
    {input: [0.415625, 0.125], output: [0.221182390045435]},
    {input: [0.393125, 0.0777777777777778], output: [0.24382243684644]},
    {input: [0.35375, 0.186111111111111], output: [0.261934474287243]},
    {input: [0.348125, 0.169444444444444], output: [0.24382243684644]},
    {input: [0.30875, 0.186111111111111], output: [0.325326605330057]},
    {input: [0.291875, 0.0694444444444444], output: [0.311742577249454]},
    {input: [0.35375, 0.0777777777777778], output: [0.307214567889253]},
    {input: [0.32, 0.0777777777777778], output: [0.270990493007645]},
    {input: [0.269375, 0.0527777777777778], output: [0.24835044620664]},
    {input: [0.258125, 0.1], output: [0.221182390045435]},
    {input: [0.2525, 0.122222222222222], output: [0.184958315163827]},
    {input: [0.246875, 0.194444444444444], output: [0.189486324524028]},
    {input: [0.26375, 0.15], output: [0.166846277723023]},
    {input: [0.381875, 0.186111111111111], output: [0.194014333884229]},
    {input: [0.32, 0.152777777777778], output: [0.180430305803626]},
    {input: [0.224375, 0.152777777777778], output: [0.148734240282219]},
    {input: [0.359375, 0.216666666666667], output: [0.144206230922018]},
    {input: [0.4325, 0.144444444444444], output: [0.162318268362822]},
    {input: [0.39875, 0.169444444444444], output: [0.212126371325033]},
    {input: [0.4775, 0.386111111111111], output: [0.257406464927042]},
    {input: [0.471875, 0.377777777777778], output: [0.289102530448449]},
    {input: [0.60125, 0.488888888888889], output: [0.302686558529052]},
    {input: [0.516875, 0.477777777777778], output: [0.316270586609655]},
    {input: [0.73625, 0.491666666666667], output: [0.261934474287243]},
    {input: [0.60125, 0.502777777777778], output: [0.307214567889253]},
    {input: [0.426875, 0.463888888888889], output: [0.261934474287243]},
    {input: [0.41, 0.408333333333333], output: [0.252878455566841]},
    {input: [0.55625, 0.4], output: [0.261934474287243]},
    {input: [0.57875, 0.333333333333333], output: [0.307214567889253]},
    {input: [0.471875, 0.344444444444444], output: [0.311742577249454]},
    {input: [0.359375, 0.313888888888889], output: [0.252878455566841]},
    {input: [0.37625, 0.355555555555556], output: [0.239294427486239]},
    {input: [0.39875, 0.280555555555556], output: [0.252878455566841]},
    {input: [0.471875, 0.369444444444444], output: [0.284574521088248]},
    {input: [0.291875, 0.483333333333333], output: [0.280046511728047]},
    {input: [0.48875, 0.597222222222222], output: [0.157790259002621]},
    {input: [0.460625, 0.761111111111111], output: [0.175902296443425]},
    {input: [0.561875, 0.730555555555556], output: [0.29363053980865]},
    {input: [0.57875, 0.741666666666667], output: [0.384190727012669]},
    {input: [0.44375, 0.719444444444444], output: [0.492862951657492]},
    {input: [0.573125, 0.741666666666667], output: [0.438526839335081]},
    {input: [0.46625, 0.708333333333333], output: [0.438526839335081]},
    {input: [0.455, 0.75], output: [0.415886792534076]},
    {input: [0.404375, 0.738888888888889], output: [0.361550680211665]},
    {input: [0.26375, 0.694444444444444], output: [0.316270586609655]},
    {input: [0.280625, 0.761111111111111], output: [0.24382243684644]},
    {input: [0.1175, 0.744444444444444], output: [0.221182390045435]},
    {input: [0.168125, 0.547222222222222], output: [0.166846277723023]},
    {input: [0.123125, 0.533333333333333], output: [0.139678221561817]},
    {input: [0.156875, 0.558333333333333], output: [0.10345414668021]},
    {input: [0.134375, 0.602777777777778], output: [0.0762860905190039]},
    {input: [0.1175, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.1175, 0.505555555555556], output: [0.067230071798602]},
    {input: [0.19625, 0.552777777777778], output: [0.0943981279598077]},
    {input: [0.179375, 0.527777777777778], output: [0.112510165400612]},
    {input: [0.23, 0.483333333333333], output: [0.0898701185996068]},
    {input: [0.291875, 0.491666666666667], output: [0.0943981279598077]},
    {input: [0.235625, 0.436111111111111], output: [0.0943981279598077]},
    {input: [0.190625, 0.394444444444444], output: [0.071758081158803]},
    {input: [0.1625, 0.202777777777778], output: [0.0808140998792049]},
    {input: [0.179375, 0.236111111111111], output: [0.107982156040411]},
    {input: [0.359375, 0.352777777777778], output: [0.112510165400612]},
    {input: [0.381875, 0.269444444444444], output: [0.112510165400612]},
    {input: [0.291875, 0.288888888888889], output: [0.112510165400612]},
    {input: [0.37625, 0.255555555555556], output: [0.19854234324443]},
    {input: [0.336875, 0.236111111111111], output: [0.157790259002621]},
    {input: [0.381875, 0.213888888888889], output: [0.175902296443425]},
    {input: [0.359375, 0.208333333333333], output: [0.194014333884229]},
    {input: [0.3425, 0.230555555555556], output: [0.212126371325033]},
    {input: [0.381875, 0.361111111111111], output: [0.203070352604631]},
    {input: [0.246875, 0.322222222222222], output: [0.194014333884229]},
    {input: [0.258125, 0.330555555555556], output: [0.139678221561817]},
    {input: [0.291875, 0.361111111111111], output: [0.166846277723023]},
    {input: [0.33125, 0.638888888888889], output: [0.144206230922018]},
    {input: [0.426875, 0.697222222222222], output: [0.15326224964242]},
    {input: [0.4775, 0.752777777777778], output: [0.216654380685234]},
    {input: [0.438125, 0.725], output: [0.307214567889253]},
    {input: [0.471875, 0.713888888888889], output: [0.266462483647444]},
    {input: [0.483125, 0.733333333333333], output: [0.334382624050459]},
    {input: [0.455, 0.686111111111111], output: [0.38871873637287]},
    {input: [0.5, 0.691666666666667], output: [0.393246745733071]},
    {input: [0.5, 0.683333333333333], output: [0.406830773813674]},
    {input: [0.505625, 0.686111111111111], output: [0.429470820614679]},
    {input: [0.48875, 0.688888888888889], output: [0.43399882997488]},
    {input: [0.471875, 0.725], output: [0.438526839335081]},
    {input: [0.561875, 0.747222222222222], output: [0.429470820614679]},
    {input: [0.539375, 0.769444444444444], output: [0.384190727012669]},
    {input: [0.41, 0.833333333333333], output: [0.384190727012669]},
    {input: [0.41, 0.833333333333333], output: [0.302686558529052]},
    {input: [0.28625, 0.994444444444444], output: [0.311742577249454]},
    {input: [0.111875, 0.8], output: [0.216654380685234]},
    {input: [0.2525, 0.772222222222222], output: [0.162318268362822]},
    {input: [0.246875, 0.833333333333333], output: [0.162318268362822]},
    {input: [0.23, 0.808333333333333], output: [0.135150212201616]},
    {input: [0.336875, 0.788888888888889], output: [0.157790259002621]},
    {input: [0.275, 0.705555555555556], output: [0.139678221561817]},
    {input: [0.314375, 0.705555555555556], output: [0.144206230922018]},
    {input: [0.2525, 0.697222222222222], output: [0.117038174760813]},
    {input: [0.17375, 0.588888888888889], output: [0.0853421092394058]},
    {input: [0.179375, 0.588888888888889], output: [0.071758081158803]},
    {input: [0.15125, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.111875, 0.516666666666667], output: [0.067230071798602]},
    {input: [0.06125, 0.0472222222222222], output: [0.0536460437179991]},
    {input: [0.168125, 0.172222222222222], output: [0.0355340062771953]},
    {input: [0.201875, 0.188888888888889], output: [0.062702062438401]},
    {input: [0.246875, 0.244444444444444], output: [0.0989261373200087]},
    {input: [0.275, 0.394444444444444], output: [0.121566184121014]},
    {input: [0.213125, 0.466666666666667], output: [0.0898701185996068]},
    {input: [0.179375, 0.336111111111111], output: [0.071758081158803]},
    {input: [0.19625, 0.225], output: [0.0762860905190039]},
    {input: [0.348125, 0.188888888888889], output: [0.112510165400612]},
    {input: [0.359375, 0.161111111111111], output: [0.221182390045435]},
    {input: [0.393125, 0.133333333333333], output: [0.207598361964832]},
    {input: [0.438125, 0.163888888888889], output: [0.270990493007645]},
    {input: [0.42125, 0.175], output: [0.24382243684644]},
    {input: [0.426875, 0.183333333333333], output: [0.316270586609655]},
    {input: [0.415625, 0.211111111111111], output: [0.325326605330057]},
    {input: [0.393125, 0.163888888888889], output: [0.329854614690258]},
    {input: [0.455, 0.0833333333333333], output: [0.325326605330057]},
    {input: [0.415625, 0.0861111111111111], output: [0.33891063341066]},
    {input: [0.44375, 0.0805555555555556], output: [0.316270586609655]},
    {input: [0.4775, 0.05], output: [0.316270586609655]},
    {input: [0.455, 0.0611111111111111], output: [0.302686558529052]},
    {input: [0.455, 0.0555555555555556], output: [0.289102530448449]},
    {input: [0.455, 0.0666666666666667], output: [0.284574521088248]},
    {input: [0.4775, 0.0611111111111111], output: [0.284574521088248]},
    {input: [0.44375, 0.0611111111111111], output: [0.270990493007645]},
    {input: [0.46625, 0.0361111111111111], output: [0.284574521088248]},
    {input: [0.438125, 0.0305555555555556], output: [0.24382243684644]},
    {input: [0.359375, 0.0305555555555556], output: [0.212126371325033]},
    {input: [0.30875, 0.0416666666666667], output: [0.19854234324443]},
    {input: [0.26375, 0.0527777777777778], output: [0.180430305803626]},
    {input: [0.224375, 0.05], output: [0.148734240282219]},
    {input: [0.19625, 0.05], output: [0.144206230922018]},
    {input: [0.15125, 0.05], output: [0.126094193481215]},
    {input: [0.0725, 0.961111111111111], output: [0.121566184121014]},
    {input: [0.044375, 0.00555555555555556], output: [0.10345414668021]},
    {input: [0.01625, 0.786111111111111], output: [0.0853421092394058]},
    {input: [0.0725, 0.686111111111111], output: [0.071758081158803]},
    {input: [0.134375, 0.855555555555556], output: [0.0762860905190039]},
    {input: [0.1175, 0.838888888888889], output: [0.0808140998792049]},
    {input: [0.14, 0.869444444444444], output: [0.062702062438401]},
    {input: [0.15125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.727777777777778], output: [0.0400620156373962]},
    {input: [0.2075, 0.741666666666667], output: [0.0536460437179991]},
    {input: [0.17375, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.21875, 0.688888888888889], output: [0.0762860905190039]},
    {input: [0.19625, 0.666666666666667], output: [0.0762860905190039]},
    {input: [0.224375, 0.608333333333333], output: [0.067230071798602]},
    {input: [0.2075, 0.691666666666667], output: [0.0808140998792049]},
    {input: [0.2525, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.269375, 0.702777777777778], output: [0.117038174760813]},
    {input: [0.280625, 0.694444444444444], output: [0.144206230922018]},
    {input: [0.12875, 0.677777777777778], output: [0.15326224964242]},
    {input: [0.2075, 0.825], output: [0.157790259002621]},
    {input: [0.156875, 0.491666666666667], output: [0.130622202841415]},
    {input: [0.01625, 0.977777777777778], output: [0.0943981279598077]},
    {input: [0.12875, 0.986111111111111], output: [0.0853421092394058]},
    {input: [0.089375, 0.0694444444444444], output: [0.071758081158803]},
    {input: [0.0725, 0.0472222222222222], output: [0.0581740530782001]},
    {input: [0.055625, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.089375, 0.075], output: [0.0310059969169943]},
    {input: [0.123125, 0.0777777777777778], output: [0.0355340062771953]},
    {input: [0.17375, 0.980555555555556], output: [0.0491180343577981]},
    {input: [0.145625, 0.00277777777777778], output: [0.0264779875567933]},
    {input: [0.1625, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.938888888888889], output: [0.071758081158803]},
    {input: [0.1175, 0.916666666666667], output: [0.0310059969169943]},
    {input: [0.10625, 0.866666666666667], output: [0.0491180343577981]},
    {input: [0.08375, 0.85], output: [0.0445900249975972]},
    {input: [0.15125, 0.755555555555556], output: [0.0310059969169943]},
    {input: [0.095, 0.694444444444444], output: [0.0310059969169943]},
    {input: [0.111875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.14, 0.697222222222222], output: [0.0400620156373962]},
    {input: [0.168125, 0.858333333333333], output: [0.0581740530782001]},
    {input: [0.134375, 0.891666666666667], output: [0.0581740530782001]},
    {input: [0.123125, 0.905555555555556], output: [0.0491180343577981]},
    {input: [0.05, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.06125, 0.108333333333333], output: [0.0536460437179991]},
    {input: [0.06125, 0.108333333333333], output: [0.0581740530782001]},
    {input: [0.156875, 0.238888888888889], output: [0.0355340062771953]},
    {input: [0.19625, 0.197222222222222], output: [0.0264779875567933]},
    {input: [0.291875, 0.136111111111111], output: [0.0853421092394058]},
    {input: [0.370625, 0.141666666666667], output: [0.148734240282219]},
    {input: [0.42125, 0.155555555555556], output: [0.19854234324443]},
    {input: [0.51125, 0.144444444444444], output: [0.261934474287243]},
    {input: [0.494375, 0.169444444444444], output: [0.29363053980865]},
    {input: [0.42125, 0.236111111111111], output: [0.270990493007645]},
    {input: [0.539375, 0.383333333333333], output: [0.284574521088248]},
    {input: [0.60125, 0.469444444444444], output: [0.316270586609655]},
    {input: [0.3425, 0.466666666666667], output: [0.216654380685234]},
    {input: [0.314375, 0.347222222222222], output: [0.144206230922018]},
    {input: [0.26375, 0.394444444444444], output: [0.117038174760813]},
    {input: [0.32, 0.288888888888889], output: [0.107982156040411]},
    {input: [0.4775, 0.344444444444444], output: [0.189486324524028]},
    {input: [0.57875, 0.333333333333333], output: [0.343438642770861]},
    {input: [0.3425, 0.358333333333333], output: [0.33891063341066]},
    {input: [0.41, 0.741666666666667], output: [0.266462483647444]},
    {input: [0.635, 0.797222222222222], output: [0.29363053980865]},
    {input: [0.635, 0.747222222222222], output: [0.474750914216689]},
    {input: [0.663125, 0.722222222222222], output: [0.560783092060507]},
    {input: [0.71375, 0.730555555555556], output: [0.57436712014111]},
    {input: [0.696875, 0.738888888888889], output: [0.610591195022717]},
    {input: [0.62375, 0.741666666666667], output: [0.673983326065531]},
    {input: [0.62375, 0.738888888888889], output: [0.583423138861512]},
    {input: [0.663125, 0.722222222222222], output: [0.569839110780909]},
    {input: [0.68, 0.761111111111111], output: [0.664927307345129]},
    {input: [0.62375, 0.763888888888889], output: [0.615119204382918]},
    {input: [0.66875, 0.772222222222222], output: [0.615119204382918]},
    {input: [0.725, 0.763888888888889], output: [0.542671054619703]},
    {input: [0.64625, 0.766666666666667], output: [0.515502998458497]},
    {input: [0.584375, 0.775], output: [0.424942811254478]},
    {input: [0.5675, 0.772222222222222], output: [0.384190727012669]},
    {input: [0.494375, 0.763888888888889], output: [0.384190727012669]},
    {input: [0.449375, 0.741666666666667], output: [0.311742577249454]},
    {input: [0.539375, 0.744444444444444], output: [0.397774755093272]},
    {input: [0.528125, 0.758333333333333], output: [0.406830773813674]},
    {input: [0.5675, 0.761111111111111], output: [0.43399882997488]},
    {input: [0.545, 0.758333333333333], output: [0.370606698932066]},
    {input: [0.516875, 0.775], output: [0.375134708292267]},
    {input: [0.505625, 0.786111111111111], output: [0.33891063341066]},
    {input: [0.449375, 0.761111111111111], output: [0.316270586609655]},
    {input: [0.393125, 0.741666666666667], output: [0.257406464927042]},
    {input: [0.314375, 0.744444444444444], output: [0.207598361964832]},
    {input: [0.10625, 0.844444444444444], output: [0.117038174760813]},
    {input: [0.17375, 0.855555555555556], output: [0.10345414668021]},
    {input: [0.224375, 0.736111111111111], output: [0.148734240282219]},
    {input: [0.2075, 0.741666666666667], output: [0.15326224964242]},
    {input: [0.190625, 0.902777777777778], output: [0.166846277723023]},
    {input: [0.095, 0.952777777777778], output: [0.121566184121014]},
    {input: [0.033125, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.03875, 0.719444444444444], output: [0.071758081158803]},
    {input: [0.033125, 0.741666666666667], output: [0.0581740530782001]},
    {input: [0.0275, 0.827777777777778], output: [0.0400620156373962]},
    {input: [0.021875, 0.15], output: [0.0581740530782001]},
    {input: [0.123125, 0.00277777777777778], output: [0.0445900249975972]},
    {input: [0.044375, 0.227777777777778], output: [0.0581740530782001]},
    {input: [0.078125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.15125, 0.205555555555556], output: [0.0310059969169943]},
    {input: [0.15125, 0.255555555555556], output: [0.0264779875567933]},
    {input: [0.201875, 0.197222222222222], output: [0.0310059969169943]},
    {input: [0.156875, 0.225], output: [0.0355340062771953]},
    {input: [0.055625, 0.222222222222222], output: [0.0400620156373962]},
    {input: [0.2525, 0.122222222222222], output: [0.0310059969169943]},
    {input: [0.275, 0.0444444444444444], output: [0.0989261373200087]},
    {input: [0.2525, 0.0472222222222222], output: [0.0943981279598077]},
    {input: [0.235625, 0.0583333333333333], output: [0.0989261373200087]},
    {input: [0.235625, 0.0722222222222222], output: [0.0898701185996068]},
    {input: [0.1625, 0.0861111111111111], output: [0.071758081158803]},
    {input: [0.14, 0.00833333333333333], output: [0.0762860905190039]},
    {input: [0.100625, 0.0194444444444444], output: [0.0581740530782001]},
    {input: [0.089375, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.134375, 0.994444444444444], output: [0.117038174760813]},
    {input: [0.14, 0.916666666666667], output: [0.148734240282219]},
    {input: [0.17375, 0.944444444444444], output: [0.126094193481215]},
    {input: [0.044375, 0.0527777777777778], output: [0.189486324524028]},
    {input: [0.033125, 0.563888888888889], output: [0.139678221561817]},
    {input: [0.021875, 0.547222222222222], output: [0.0898701185996068]},
    {input: [0.021875, 0.694444444444444], output: [0.071758081158803]},
    {input: [0.01625, 0.0833333333333333], output: [0.0581740530782001]},
    {input: [0.03875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.352777777777778], output: [0.0536460437179991]},
    {input: [0.089375, 0.458333333333333], output: [0.0264779875567933]},
    {input: [0.044375, 0.427777777777778], output: [0.0400620156373962]},
    {input: [0.033125, 0.588888888888889], output: [0.062702062438401]},
    {input: [0.044375, 0.411111111111111], output: [0.0355340062771953]},
    {input: [0.055625, 0.511111111111111], output: [0.0400620156373962]},
    {input: [0.06125, 0.286111111111111], output: [0.0355340062771953]},
    {input: [0.14, 0.075], output: [0.0310059969169943]},
    {input: [0.14, 0.147222222222222], output: [0.0264779875567933]},
    {input: [0.2975, 0.169444444444444], output: [0.0310059969169943]},
    {input: [0.359375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.303125, 0.238888888888889], output: [0.121566184121014]},
    {input: [0.12875, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.190625, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.1175, 0.513888888888889], output: [0.067230071798602]},
    {input: [0.0725, 0.941666666666667], output: [0.071758081158803]},
    {input: [0.123125, 0.263888888888889], output: [0.0581740530782001]},
    {input: [0.1625, 0.391666666666667], output: [0.062702062438401]},
    {input: [0.0725, 0.708333333333333], output: [0.0400620156373962]},
    {input: [0.089375, 0.0111111111111111], output: [0.0491180343577981]},
    {input: [0.12875, 0.108333333333333], output: [0.0536460437179991]},
    {input: [0.089375, 0.763888888888889], output: [0.0559100483980996]},
    {input: [0.291875, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.2975, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.780555555555556], output: [0.0355340062771953]},
    {input: [0.134375, 0.913888888888889], output: [0.0377980109572957]},
    {input: [0.1625, 0.758333333333333], output: [0.0128939594761905]},
    {input: [0.14, 0.772222222222222], output: [0.0174219688363914]},
    {input: [0.1175, 0.788888888888889], output: [0.0174219688363914]},
    {input: [0.100625, 0.838888888888889], output: [0.0264779875567933]},
    {input: [0.066875, 0.966666666666667], output: [0.0242139828766929]},
    {input: [0.044375, 0.119444444444444], output: [0.0151579641562909]},
    {input: [0.078125, 0.327777777777778], output: [0.0128939594761905]},
    {input: [0.213125, 0.175], output: [0.0151579641562909]},
    {input: [0.235625, 0.225], output: [0.0128939594761905]},
    {input: [0.24125, 0.2], output: [0.0287419922368938]},
    {input: [0.280625, 0.233333333333333], output: [0.0310059969169943]},
    {input: [0.41, 0.238888888888889], output: [0.0559100483980996]},
    {input: [0.359375, 0.222222222222222], output: [0.0694940764787025]},
    {input: [0.3875, 0.258333333333333], output: [0.0853421092394058]},
    {input: [0.3425, 0.291666666666667], output: [0.0853421092394058]},
    {input: [0.438125, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.5225, 0.352777777777778], output: [0.107982156040411]},
    {input: [0.438125, 0.494444444444444], output: [0.10571815136031]},
    {input: [0.51125, 0.561111111111111], output: [0.121566184121014]},
    {input: [0.269375, 0.572222222222222], output: [0.121566184121014]},
    {input: [0.21875, 0.502777777777778], output: [0.0559100483980996]},
    {input: [0.30875, 0.508333333333333], output: [0.0649660671185015]},
    {input: [0.19625, 0.516666666666667], output: [0.0536460437179991]},
    {input: [0.17375, 0.547222222222222], output: [0.0310059969169943]},
    {input: [0.14, 0.591666666666667], output: [0.0242139828766929]},
    {input: [0.235625, 0.588888888888889], output: [0.0400620156373962]},
    {input: [0.17375, 0.708333333333333], output: [0.0287419922368938]},
    {input: [0.275, 0.675], output: [0.0423260203174967]},
    {input: [0.291875, 0.730555555555556], output: [0.0604380577583006]},
    {input: [0.145625, 0.688888888888889], output: [0.0604380577583006]},
    {input: [0.06125, 0.677777777777778], output: [0.067230071798602]},
    {input: [0.10625, 0.0722222222222222], output: [0.062702062438401]},
    {input: [0.123125, 0.986111111111111], output: [0.0513820390378986]},
    {input: [0.15125, 0.930555555555556], output: [0.0377980109572957]},
    {input: [0.066875, 0.480555555555556], output: [0.0264779875567933]},
    {input: [0.325625, 0.480555555555556], output: [0.0355340062771953]},
    {input: [0.426875, 0.522222222222222], output: [0.0853421092394058]},
    {input: [0.269375, 0.45], output: [0.0468540296776977]},
    {input: [0.2975, 0.333333333333333], output: [0.0355340062771953]},
    {input: [0.33125, 0.341666666666667], output: [0.0649660671185015]},
    {input: [0.381875, 0.361111111111111], output: [0.062702062438401]},
    {input: [0.359375, 0.269444444444444], output: [0.0604380577583006]},
    {input: [0.46625, 0.275], output: [0.0785500951991044]},
    {input: [0.370625, 0.258333333333333], output: [0.0966621326399082]},
    {input: [0.359375, 0.230555555555556], output: [0.0921341232797073]},
    {input: [0.393125, 0.219444444444444], output: [0.0989261373200087]},
    {input: [0.3875, 0.247222222222222], output: [0.10345414668021]},
    {input: [0.303125, 0.269444444444444], output: [0.117038174760813]},
    {input: [0.303125, 0.236111111111111], output: [0.128358198161315]},
    {input: [0.314375, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.33125, 0.202777777777778], output: [0.101190142000109]},
    {input: [0.37625, 0.258333333333333], output: [0.0966621326399082]},
    {input: [0.3875, 0.252777777777778], output: [0.10345414668021]},
    {input: [0.32, 0.252777777777778], output: [0.117038174760813]},
    {input: [0.185, 0.261111111111111], output: [0.0966621326399082]},
    {input: [0.10625, 0.272222222222222], output: [0.0989261373200087]},
    {input: [0.1625, 0.911111111111111], output: [0.0876061139195063]},
    {input: [0.145625, 0.880555555555556], output: [0.0785500951991044]},
    {input: [0.078125, 0.855555555555556], output: [0.062702062438401]},
    {input: [0.185, 0.519444444444444], output: [0.062702062438401]},
    {input: [0.393125, 0.716666666666667], output: [0.0559100483980996]},
    {input: [0.325625, 0.669444444444444], output: [0.0943981279598077]},
    {input: [0.30875, 0.683333333333333], output: [0.0740220858389034]},
    {input: [0.460625, 0.691666666666667], output: [0.10571815136031]},
    {input: [0.41, 0.705555555555556], output: [0.164582273042923]},
    {input: [0.2975, 0.688888888888889], output: [0.141942226241918]},
    {input: [0.365, 0.683333333333333], output: [0.128358198161315]},
    {input: [0.365, 0.658333333333333], output: [0.146470235602119]},
    {input: [0.325625, 0.658333333333333], output: [0.146470235602119]},
    {input: [0.275, 0.605555555555556], output: [0.130622202841415]},
    {input: [0.123125, 0.722222222222222], output: [0.0808140998792049]},
    {input: [0.089375, 0.441666666666667], output: [0.0762860905190039]},
    {input: [0.275, 0.394444444444444], output: [0.0423260203174967]},
    {input: [0.1625, 0.275], output: [0.0310059969169943]},
    {input: [0.078125, 0.0444444444444444], output: [0.0287419922368938]},
    {input: [0.201875, 0.883333333333333], output: [0.0264779875567933]},
    {input: [0.089375, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.606875, 0.752777777777778], output: [0.0989261373200087]},
    {input: [0.381875, 0.65], output: [0.139678221561817]},
    {input: [0.359375, 0.661111111111111], output: [0.123830188801114]},
    {input: [0.393125, 0.647222222222222], output: [0.114774170080712]},
    {input: [0.336875, 0.697222222222222], output: [0.121566184121014]},
    {input: [0.438125, 0.705555555555556], output: [0.137414216881717]},
    {input: [0.4325, 0.719444444444444], output: [0.169110282403124]},
    {input: [0.393125, 0.7], output: [0.128358198161315]},
    {input: [0.39875, 0.677777777777778], output: [0.135150212201616]},
    {input: [0.39875, 0.675], output: [0.130622202841415]},
    {input: [0.37625, 0.683333333333333], output: [0.119302179440913]},
    {input: [0.393125, 0.686111111111111], output: [0.126094193481215]},
    {input: [0.30875, 0.702777777777778], output: [0.114774170080712]},
    {input: [0.291875, 0.716666666666667], output: [0.126094193481215]},
    {input: [0.19625, 0.808333333333333], output: [0.10345414668021]},
    {input: [0.224375, 0.755555555555556], output: [0.0876061139195063]},
    {input: [0.190625, 0.847222222222222], output: [0.0785500951991044]},
    {input: [0.30875, 0.805555555555556], output: [0.0649660671185015]},
    {input: [0.35375, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.797222222222222], output: [0.062702062438401]},
    {input: [0.21875, 0.783333333333333], output: [0.0581740530782001]},
    {input: [0.19625, 0.777777777777778], output: [0.0491180343577981]},
    {input: [0.17375, 0.769444444444444], output: [0.0377980109572957]},
    {input: [0.156875, 0.861111111111111], output: [0.0310059969169943]},
    {input: [0.055625, 0.636111111111111], output: [0.0242139828766929]},
    {input: [0.0725, 0.669444444444444], output: [0.0174219688363914]},
    {input: [0.089375, 0.980555555555556], output: [0.0174219688363914]},
    {input: [0.134375, 0.0388888888888889], output: [0.0151579641562909]},
    {input: [0.111875, 0.944444444444444], output: [0.0310059969169943]},
    {input: [0.14, 0.0805555555555556], output: [0.0332700015970948]},
    {input: [0.100625, 0.105555555555556], output: [0.0355340062771953]},
    {input: [0.12875, 0.941666666666667], output: [0.0287419922368938]},
    {input: [0.24125, 0.972222222222222], output: [0.0559100483980996]},
    {input: [0.19625, 0.927777777777778], output: [0.0740220858389034]},
    {input: [0.168125, 0.761111111111111], output: [0.0468540296776977]},
    {input: [0.123125, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.258125, 0.794444444444444], output: [0.0377980109572957]},
    {input: [0.280625, 0.8], output: [0.0423260203174967]},
    {input: [0.28625, 0.783333333333333], output: [0.0604380577583006]},
    {input: [0.30875, 0.780555555555556], output: [0.0740220858389034]},
    {input: [0.348125, 0.783333333333333], output: [0.0762860905190039]},
    {input: [0.359375, 0.786111111111111], output: [0.0943981279598077]},
    {input: [0.359375, 0.786111111111111], output: [0.107982156040411]},
    {input: [0.325625, 0.761111111111111], output: [0.121566184121014]},
    {input: [0.280625, 0.755555555555556], output: [0.0989261373200087]},
    {input: [0.32, 0.75], output: [0.0762860905190039]},
    {input: [0.145625, 0.880555555555556], output: [0.0536460437179991]},
    {input: [0.15125, 0.861111111111111], output: [0.0559100483980996]},
    {input: [0.14, 0.830555555555556], output: [0.0491180343577981]},
    {input: [0.15125, 0.75], output: [0.0491180343577981]},
    {input: [0.168125, 0.794444444444444], output: [0.0513820390378986]},
    {input: [0.134375, 0.766666666666667], output: [0.0377980109572957]},
    {input: [0.10625, 0.944444444444444], output: [0.0287419922368938]},
    {input: [0.100625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.066875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.010625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.055625, 0.638888888888889], output: [0.0242139828766929]},
    {input: [0.078125, 0.786111111111111], output: [0.0264779875567933]},
    {input: [0.111875, 0.847222222222222], output: [0.0310059969169943]},
    {input: [0.10625, 0.797222222222222], output: [0.0310059969169943]},
    {input: [0.06125, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.08375, 0.672222222222222], output: [0.0242139828766929]},
    {input: [0.100625, 0.691666666666667], output: [0.0128939594761905]},
    {input: [0.03875, 0.838888888888889], output: [0.0128939594761905]},
    {input: [0.12875, 0.772222222222222], output: [0.0174219688363914]},
    {input: [0.1625, 0.772222222222222], output: [0.0174219688363914]},
    {input: [0.10625, 0.736111111111111], output: [0.0196859735164919]},
    {input: [0.100625, 0.661111111111111], output: [0.0174219688363914]},
    {input: [0.095, 0.688888888888889], output: [0.0196859735164919]},
    {input: [0.100625, 0.766666666666667], output: [0.0264779875567933]},
    {input: [0.078125, 0.0638888888888889], output: [0.0400620156373962]},
    {input: [0.010625, 0.266666666666667], output: [0.0377980109572957]},
    {input: [0.095, 0.997222222222222], output: [0.0287419922368938]},
    {input: [0.055625, 0.108333333333333], output: [0.0287419922368938]},
    {input: [0.044375, 0.672222222222222], output: [0.0242139828766929]},
    {input: [0.100625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.066875, 0.988888888888889], output: [0.0377980109572957]},
    {input: [0.01625, 0.191666666666667], output: [0.0264779875567933]},
    {input: [0.01625, 0.719444444444444], output: [0.0174219688363914]},
    {input: [0.010625, 0.622222222222222], output: [0.0196859735164919]},
    {input: [0.03875, 0.658333333333333], output: [0.0128939594761905]},
    {input: [0.111875, 0.911111111111111], output: [0.0196859735164919]},
    {input: [0.05, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.630555555555556], output: [0.0151579641562909]},
    {input: [0.044375, 0.05], output: [0.0128939594761905]},
    {input: [0.303125, 0.161111111111111], output: [0.0287419922368938]},
    {input: [0.303125, 0.141666666666667], output: [0.0604380577583006]},
    {input: [0.14, 0.208333333333333], output: [0.0377980109572957]},
    {input: [0.28625, 0.227777777777778], output: [0.0355340062771953]},
    {input: [0.314375, 0.205555555555556], output: [0.0536460437179991]},
    {input: [0.359375, 0.236111111111111], output: [0.062702062438401]},
    {input: [0.32, 0.147222222222222], output: [0.0808140998792049]},
    {input: [0.303125, 0.147222222222222], output: [0.0694940764787025]},
    {input: [0.3425, 0.152777777777778], output: [0.062702062438401]},
    {input: [0.325625, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.21875, 0.155555555555556], output: [0.0559100483980996]},
    {input: [0.1625, 0.155555555555556], output: [0.0513820390378986]},
    {input: [0.15125, 0.255555555555556], output: [0.0355340062771953]},
    {input: [0.17375, 0.3], output: [0.0310059969169943]},
    {input: [0.26375, 0.202777777777778], output: [0.0355340062771953]},
    {input: [0.2525, 0.191666666666667], output: [0.0423260203174967]},
    {input: [0.23, 0.263888888888889], output: [0.0513820390378986]},
    {input: [0.325625, 0.261111111111111], output: [0.0468540296776977]},
    {input: [0.359375, 0.683333333333333], output: [0.0694940764787025]},
    {input: [0.381875, 0.727777777777778], output: [0.135150212201616]},
    {input: [0.404375, 0.75], output: [0.128358198161315]},
    {input: [0.381875, 0.802777777777778], output: [0.114774170080712]},
    {input: [0.35375, 0.802777777777778], output: [0.135150212201616]},
    {input: [0.33125, 0.797222222222222], output: [0.123830188801114]},
    {input: [0.235625, 0.777777777777778], output: [0.0921341232797073]},
    {input: [0.15125, 0.825], output: [0.0876061139195063]},
    {input: [0.095, 0.822222222222222], output: [0.0694940764787025]},
    {input: [0.134375, 0.294444444444444], output: [0.0468540296776977]},
    {input: [0.17375, 0.272222222222222], output: [0.0174219688363914]},
    {input: [0.275, 0.341666666666667], output: [0.0264779875567933]},
    {input: [0.28625, 0.325], output: [0.0536460437179991]},
    {input: [0.359375, 0.175], output: [0.0491180343577981]},
    {input: [0.41, 0.177777777777778], output: [0.0921341232797073]},
    {input: [0.5, 0.166666666666667], output: [0.139678221561817]},
    {input: [0.5675, 0.113888888888889], output: [0.184958315163827]},
    {input: [0.725, 0.0833333333333333], output: [0.225710399405636]},
    {input: [0.57875, 0.119444444444444], output: [0.216654380685234]},
    {input: [0.426875, 0.486111111111111], output: [0.216654380685234]},
    {input: [0.3875, 0.472222222222222], output: [0.15326224964242]},
    {input: [0.26375, 0.466666666666667], output: [0.10345414668021]},
    {input: [0.275, 0.4], output: [0.0785500951991044]},
    {input: [0.336875, 0.452777777777778], output: [0.0694940764787025]},
    {input: [0.44375, 0.297222222222222], output: [0.0943981279598077]},
    {input: [0.393125, 0.352777777777778], output: [0.119302179440913]},
    {input: [0.404375, 0.369444444444444], output: [0.119302179440913]},
    {input: [0.37625, 0.425], output: [0.130622202841415]},
    {input: [0.370625, 0.475], output: [0.135150212201616]},
    {input: [0.3425, 0.541666666666667], output: [0.130622202841415]},
    {input: [0.2525, 0.65], output: [0.0830781045593054]},
    {input: [0.48875, 0.7], output: [0.112510165400612]},
    {input: [0.545, 0.738888888888889], output: [0.148734240282219]},
    {input: [0.4325, 0.711111111111111], output: [0.162318268362822]},
    {input: [0.314375, 0.672222222222222], output: [0.162318268362822]},
    {input: [0.393125, 0.730555555555556], output: [0.164582273042923]},
    {input: [0.494375, 0.755555555555556], output: [0.128358198161315]},
    {input: [0.460625, 0.780555555555556], output: [0.180430305803626]},
    {input: [0.528125, 0.788888888888889], output: [0.218918385365334]},
    {input: [0.5, 0.763888888888889], output: [0.207598361964832]},
    {input: [0.561875, 0.766666666666667], output: [0.241558432166339]},
    {input: [0.426875, 0.7], output: [0.230238408765837]},
    {input: [0.426875, 0.677777777777778], output: [0.162318268362822]},
    {input: [0.381875, 0.666666666666667], output: [0.128358198161315]},
    {input: [0.32, 0.716666666666667], output: [0.121566184121014]},
    {input: [0.348125, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.258125, 0.730555555555556], output: [0.128358198161315]},
    {input: [0.213125, 0.75], output: [0.114774170080712]},
    {input: [0.26375, 0.813888888888889], output: [0.0921341232797073]},
    {input: [0.258125, 0.830555555555556], output: [0.0876061139195063]},
    {input: [0.2525, 0.808333333333333], output: [0.0785500951991044]},
    {input: [0.14, 0.797222222222222], output: [0.0694940764787025]},
    {input: [0.044375, 0.733333333333333], output: [0.0581740530782001]},
    {input: [0.078125, 0.427777777777778], output: [0.0423260203174967]},
    {input: [0.066875, 0.35], output: [0.0219499781965924]},
    {input: [0.35375, 0.133333333333333], output: [0.0287419922368938]},
    {input: [0.370625, 0.0833333333333333], output: [0.0513820390378986]},
    {input: [0.365, 0.0805555555555556], output: [0.062702062438401]},
    {input: [0.35375, 0.0666666666666667], output: [0.0876061139195063]},
    {input: [0.393125, 0.0833333333333333], output: [0.0966621326399082]},
    {input: [0.37625, 0.0972222222222222], output: [0.101190142000109]},
    {input: [0.359375, 0.0972222222222222], output: [0.10345414668021]},
    {input: [0.365, 0.105555555555556], output: [0.0966621326399082]},
    {input: [0.35375, 0.0972222222222222], output: [0.0921341232797073]},
    {input: [0.32, 0.0916666666666667], output: [0.0989261373200087]},
    {input: [0.17375, 0.186111111111111], output: [0.0808140998792049]},
    {input: [0.15125, 0.172222222222222], output: [0.0694940764787025]},
    {input: [0.2525, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.201875, 0.197222222222222], output: [0.0581740530782001]},
    {input: [0.156875, 0.211111111111111], output: [0.0581740530782001]},
    {input: [0.280625, 0.0916666666666667], output: [0.0513820390378986]},
    {input: [0.258125, 0.122222222222222], output: [0.0581740530782001]},
    {input: [0.2525, 0.125], output: [0.0559100483980996]},
    {input: [0.258125, 0.0916666666666667], output: [0.0513820390378986]},
    {input: [0.275, 0.0861111111111111], output: [0.0491180343577981]},
    {input: [0.190625, 0.0666666666666667], output: [0.0468540296776977]},
    {input: [0.19625, 0.1], output: [0.0400620156373962]},
    {input: [0.23, 0.0861111111111111], output: [0.0468540296776977]},
    {input: [0.19625, 0.0472222222222222], output: [0.0377980109572957]},
    {input: [0.179375, 0.0583333333333333], output: [0.0264779875567933]},
    {input: [0.145625, 0.141666666666667], output: [0.0242139828766929]},
    {input: [0.12875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.123125, 0.302777777777778], output: [0.0151579641562909]},
    {input: [0.044375, 0.411111111111111], output: [0.01062995479609]},
    {input: [0.19625, 0.0416666666666667], output: [0.0151579641562909]},
    {input: [0.190625, 0.147222222222222], output: [0.0287419922368938]},
    {input: [0.1175, 0.563888888888889], output: [0.0264779875567933]},
    {input: [0.145625, 0.136111111111111], output: [0.0242139828766929]},
    {input: [0.201875, 0.0583333333333333], output: [0.0264779875567933]},
    {input: [0.224375, 0.0583333333333333], output: [0.0242139828766929]},
    {input: [0.156875, 0.144444444444444], output: [0.0196859735164919]},
    {input: [0.21875, 0.155555555555556], output: [0.0128939594761905]},
    {input: [0.201875, 0.127777777777778], output: [0.0196859735164919]},
    {input: [0.224375, 0.102777777777778], output: [0.0128939594761905]},
    {input: [0.2075, 0.125], output: [0.0151579641562909]},
    {input: [0.224375, 0.105555555555556], output: [0.0310059969169943]},
    {input: [0.213125, 0.136111111111111], output: [0.0332700015970948]},
    {input: [0.2975, 0.0638888888888889], output: [0.0423260203174967]},
    {input: [0.26375, 0.0944444444444444], output: [0.0423260203174967]},
    {input: [0.325625, 0.102777777777778], output: [0.0400620156373962]},
    {input: [0.26375, 0.194444444444444], output: [0.0310059969169943]},
    {input: [0.145625, 0.102777777777778], output: [0.0332700015970948]},
    {input: [0.26375, 0.191666666666667], output: [0.0400620156373962]},
    {input: [0.336875, 0.238888888888889], output: [0.062702062438401]},
    {input: [0.280625, 0.216666666666667], output: [0.0649660671185015]},
    {input: [0.2975, 0.183333333333333], output: [0.0649660671185015]},
    {input: [0.28625, 0.116666666666667], output: [0.062702062438401]},
    {input: [0.3425, 0.119444444444444], output: [0.0853421092394058]},
    {input: [0.348125, 0.138888888888889], output: [0.0876061139195063]},
    {input: [0.303125, 0.102777777777778], output: [0.0740220858389034]},
    {input: [0.258125, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.21875, 0.125], output: [0.0445900249975972]},
    {input: [0.26875, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.4265625, 0.2], output: [0.067230071798602]},
    {input: [0.49375, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.421875, 0.325], output: [0.067230071798602]},
    {input: [0.4375, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.296875, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.309375, 0.475], output: [0.0445900249975972]},
    {input: [0.28125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.415625, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.3765625, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.641666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.359375, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.641666666666667], output: [0.067230071798602]},
    {input: [0.2296875, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.725], output: [0.0898701185996068]},
    {input: [0.309375, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.275, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.26875, 0.775], output: [0.067230071798602]},
    {input: [0.240625, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.246875, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.4265625, 0.722222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.478125, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.3984375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.2921875, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.708333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.7], output: [0.0445900249975972]},
    {input: [0.365625, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.3375, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.353125, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.33125, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.246875, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.1234375, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.4828125, 0.3], output: [0.0445900249975972]},
    {input: [0.478125, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.3375, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.296875, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.28125, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.296875, 0.225], output: [0.067230071798602]},
    {input: [0.2640625, 0.225], output: [0.0898701185996068]},
    {input: [0.3203125, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.196875, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.1734375, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.1453125, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.0890625, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.1734375, 0.616666666666667], output: [0.067230071798602]},
    {input: [0.2640625, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.1125, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.1625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.3421875, 0.4], output: [0.0445900249975972]},
    {input: [0.4609375, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.4546875, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.5109375, 0.388888888888889], output: [0.0898701185996068]},
    {input: [0.5453125, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.415625, 0.555555555555556], output: [0.067230071798602]},
    {input: [0.7421875, 0.605555555555556], output: [0.0898701185996068]},
    {input: [0.7140625, 0.547222222222222], output: [0.0898701185996068]},
    {input: [0.578125, 0.6], output: [0.0898701185996068]},
    {input: [0.4828125, 0.577777777777778], output: [0.067230071798602]},
    {input: [0.2921875, 0.541666666666667], output: [0.0898701185996068]},
    {input: [0.3765625, 0.577777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.586111111111111], output: [0.0898701185996068]},
    {input: [0.296875, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.28125, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.633333333333333], output: [0.067230071798602]},
    {input: [0.1171875, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.071875, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.325, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.15], output: [0.0445900249975972]},
    {input: [0.246875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.38125, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.4546875, 0.108333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.102777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.415625, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.4265625, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.465625, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.3765625, 0.161111111111111], output: [0.0898701185996068]},
    {input: [0.2296875, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.253125, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.353125, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.25], output: [0.067230071798602]},
    {input: [0.2640625, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.44375, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.409375, 0.527777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.825], output: [0.0219499781965924]},
    {input: [0.0953125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.134375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.175], output: [0.0219499781965924]},
    {input: [0.190625, 0.988888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.95], output: [0.0445900249975972]},
    {input: [0.325, 0.05], output: [0.0445900249975972]},
    {input: [0.1796875, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.409375, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.246875, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.303125, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.1125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.8], output: [0.0219499781965924]},
    {input: [0.071875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.7], output: [0.0219499781965924]},
    {input: [0.2015625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.275], output: [0.0219499781965924]},
    {input: [0.16875, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.21875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.3484375, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.471875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.4890625, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.409375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.365625, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.465625, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.478125, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.3421875, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.38125, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.353125, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.5109375, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.49375, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.465625, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.50625, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.409375, 0.35], output: [0.067230071798602]},
    {input: [0.3703125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.325, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.413888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.475], output: [0.067230071798602]},
    {input: [0.3484375, 0.475], output: [0.067230071798602]},
    {input: [0.528125, 0.7], output: [0.0445900249975972]},
    {input: [0.640625, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.55625, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.60625, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.55625, 0.713888888888889], output: [0.112510165400612]},
    {input: [0.471875, 0.675], output: [0.112510165400612]},
    {input: [0.5625, 0.619444444444444], output: [0.112510165400612]},
    {input: [0.528125, 0.708333333333333], output: [0.135150212201616]},
    {input: [0.6234375, 0.75], output: [0.135150212201616]},
    {input: [0.634375, 0.733333333333333], output: [0.157790259002621]},
    {input: [0.5734375, 0.744444444444444], output: [0.180430305803626]},
    {input: [0.55625, 0.761111111111111], output: [0.180430305803626]},
    {input: [0.55, 0.777777777777778], output: [0.157790259002621]},
    {input: [0.5, 0.761111111111111], output: [0.135150212201616]},
    {input: [0.240625, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.55, 0.725], output: [0.112510165400612]},
    {input: [0.55625, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.578125, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.528125, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.49375, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.55, 0.736111111111111], output: [0.112510165400612]},
    {input: [0.521875, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.49375, 0.716666666666667], output: [0.135150212201616]},
    {input: [0.49375, 0.716666666666667], output: [0.135150212201616]},
    {input: [0.3765625, 0.702777777777778], output: [0.112510165400612]},
    {input: [0.3375, 0.633333333333333], output: [0.0898701185996068]},
    {input: [0.3703125, 0.688888888888889], output: [0.0898701185996068]},
    {input: [0.33125, 0.7], output: [0.0898701185996068]},
    {input: [0.325, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.365625, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.359375, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.3421875, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.50625, 0.769444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.788888888888889], output: [0.112510165400612]},
    {input: [0.44375, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.309375, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.1796875, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.0953125, 0.719444444444444], output: [0.067230071798602]},
    {input: [0.128125, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.134375, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.071875, 0.8], output: [0.067230071798602]},
    {input: [0.0890625, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.0527777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.309375, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.253125, 0.00833333333333333], output: [0.0898701185996068]},
    {input: [0.309375, 0.0222222222222222], output: [0.0898701185996068]},
    {input: [0.50625, 0.0638888888888889], output: [0.0898701185996068]},
    {input: [0.49375, 0.0638888888888889], output: [0.112510165400612]},
    {input: [0.3421875, 0.977777777777778], output: [0.112510165400612]},
    {input: [0.3203125, 0.95], output: [0.135150212201616]},
    {input: [0.365625, 0.0138888888888889], output: [0.135150212201616]},
    {input: [0.45, 0.997222222222222], output: [0.157790259002621]},
    {input: [0.309375, 0.0222222222222222], output: [0.157790259002621]},
    {input: [0.465625, 0.919444444444444], output: [0.157790259002621]},
    {input: [0.4609375, 0.913888888888889], output: [0.157790259002621]},
    {input: [0.4046875, 0.0222222222222222], output: [0.135150212201616]},
    {input: [0.4546875, 0.969444444444444], output: [0.157790259002621]},
    {input: [0.421875, 0.991666666666667], output: [0.157790259002621]},
    {input: [0.5390625, 0.927777777777778], output: [0.157790259002621]},
    {input: [0.5171875, 0.95], output: [0.180430305803626]},
    {input: [0.471875, 0.00555555555555556], output: [0.180430305803626]},
    {input: [0.478125, 0.0277777777777778], output: [0.135150212201616]},
    {input: [0.44375, 0.0138888888888889], output: [0.157790259002621]},
    {input: [0.409375, 0.0527777777777778], output: [0.157790259002621]},
    {input: [0.359375, 0.0805555555555556], output: [0.135150212201616]},
    {input: [0.45, 0.0611111111111111], output: [0.157790259002621]},
    {input: [0.5109375, 0.0805555555555556], output: [0.157790259002621]},
    {input: [0.45, 0.0805555555555556], output: [0.157790259002621]},
    {input: [0.4265625, 0.0527777777777778], output: [0.157790259002621]},
    {input: [0.4265625, 0.0111111111111111], output: [0.180430305803626]},
    {input: [0.55, 0.0972222222222222], output: [0.180430305803626]},
    {input: [0.584375, 0.0944444444444444], output: [0.180430305803626]},
    {input: [0.634375, 0.0777777777777778], output: [0.180430305803626]},
    {input: [0.61875, 0.0694444444444444], output: [0.180430305803626]},
    {input: [0.584375, 0.0666666666666667], output: [0.157790259002621]},
    {input: [0.471875, 0.0694444444444444], output: [0.157790259002621]},
    {input: [0.409375, 0.0555555555555556], output: [0.157790259002621]},
    {input: [0.4609375, 0.05], output: [0.135150212201616]},
    {input: [0.4609375, 0.0472222222222222], output: [0.157790259002621]},
    {input: [0.4328125, 0.05], output: [0.135150212201616]},
    {input: [0.365625, 0.0361111111111111], output: [0.135150212201616]},
    {input: [0.353125, 0.0222222222222222], output: [0.112510165400612]},
    {input: [0.3140625, 0.0166666666666667], output: [0.0898701185996068]},
    {input: [0.15625, 0.991666666666667], output: [0.0898701185996068]},
    {input: [0.1453125, 0.977777777777778], output: [0.112510165400612]},
    {input: [0.1796875, 0], output: [0.135150212201616]},
    {input: [0.1171875, 0.963888888888889], output: [0.112510165400612]},
    {input: [0.5109375, 0.861111111111111], output: [0.112510165400612]},
    {input: [0.4046875, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.409375, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.4546875, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.5109375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.6015625, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.578125, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.534375, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.528125, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.5, 0.730555555555556], output: [0.112510165400612]},
    {input: [0.4546875, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.415625, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.3375, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.309375, 0.75], output: [0.0898701185996068]},
    {input: [0.253125, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.85], output: [0.0445900249975972]},
    {input: [0.0953125, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.775], output: [0.0219499781965924]},
    {input: [0.28125, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.365625, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.528125, 0.8], output: [0.0898701185996068]},
    {input: [0.471875, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.4375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.2078125, 0.8], output: [0.0898701185996068]},
    {input: [0.2640625, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.2640625, 0.8], output: [0.067230071798602]},
    {input: [0.1796875, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.078125, 0.491666666666667], output: [0.067230071798602]},
    {input: [0.1, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.05625, 0.025], output: [0.0219499781965924]},
    {input: [0.0328125, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.075], output: [-0.000690068604412431]},
    {input: [0.078125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0109375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.021875, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.25], output: [0.0219499781965924]},
    {input: [0.1234375, 0.111111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.494444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.788888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.575], output: [0.0219499781965924]},
    {input: [0.134375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.647222222222222], output: [-0.000690068604412431]},
    {input: [0.15625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.652777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.705555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.625], output: [0.0219499781965924]},
    {input: [0.0390625, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.486111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.075], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.05], output: [0.0219499781965924]},
    {input: [0.140625, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.134375, 0.869444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.95], output: [-0.000690068604412431]},
    {input: [0.05, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.078125, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.825], output: [0.0445900249975972]},
    {input: [0.16875, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.05625, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.071875, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.125], output: [0.0219499781965924]},
    {input: [0.184375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.3484375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.33125, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.39375, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.4828125, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.5109375, 0.530555555555556], output: [0.067230071798602]},
    {input: [0.38125, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.3484375, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.3421875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.365625, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.4828125, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.528125, 0.458333333333333], output: [0.067230071798602]},
    {input: [0.4609375, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.415625, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.3203125, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.3484375, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.325, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.3421875, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.3765625, 0.075], output: [0.067230071798602]},
    {input: [0.359375, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.3140625, 0.0777777777777778], output: [0.067230071798602]},
    {input: [0.2359375, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.1, 0.075], output: [0.0219499781965924]},
    {input: [0.078125, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.825], output: [0.0219499781965924]},
    {input: [0.1625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.8], output: [0.0445900249975972]},
    {input: [0.2296875, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.240625, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.85], output: [0.0445900249975972]},
    {input: [0.05625, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.325], output: [0.0219499781965924]},
    {input: [0.16875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.15], output: [0.0219499781965924]},
    {input: [0.04375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.3], output: [0.0445900249975972]},
    {input: [0.2640625, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.35], output: [0.0445900249975972]},
    {input: [0.296875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.35], output: [0.0445900249975972]},
    {input: [0.325, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.3], output: [0.0445900249975972]},
    {input: [0.309375, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.303125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.33125, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.3984375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.38125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.3984375, 0.25], output: [0.067230071798602]},
    {input: [0.409375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.3984375, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.4546875, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.4265625, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.471875, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.471875, 0.25], output: [0.0898701185996068]},
    {input: [0.4265625, 0.25], output: [0.112510165400612]},
    {input: [0.421875, 0.238888888888889], output: [0.112510165400612]},
    {input: [0.45, 0.25], output: [0.112510165400612]},
    {input: [0.4328125, 0.269444444444444], output: [0.112510165400612]},
    {input: [0.3984375, 0.286111111111111], output: [0.112510165400612]},
    {input: [0.4046875, 0.297222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.333333333333333], output: [0.0898701185996068]},
    {input: [0.3421875, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.275], output: [0.0445900249975972]},
    {input: [0.2359375, 0.35], output: [0.0445900249975972]},
    {input: [0.296875, 0.4], output: [0.0445900249975972]},
    {input: [0.253125, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.4046875, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.4546875, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.4828125, 0.488888888888889], output: [0.067230071798602]},
    {input: [0.3984375, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.365625, 0.513888888888889], output: [0.0445900249975972]},
    {input: [0.3703125, 0.55], output: [0.0445900249975972]},
    {input: [0.21875, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.3375, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.375], output: [0.0219499781965924]},
    {input: [0.1625, 0.25], output: [0.0219499781965924]},
    {input: [0.196875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.39375, 0.175], output: [0.0219499781965924]},
    {input: [0.5109375, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.55, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.60625, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.696875, 0.244444444444444], output: [0.157790259002621]},
    {input: [0.6015625, 0.305555555555556], output: [0.180430305803626]},
    {input: [0.6578125, 0.386111111111111], output: [0.180430305803626]},
    {input: [0.521875, 0.494444444444444], output: [0.157790259002621]},
    {input: [0.6125, 0.491666666666667], output: [0.135150212201616]},
    {input: [0.4046875, 0.486111111111111], output: [0.112510165400612]},
    {input: [0.3984375, 0.552777777777778], output: [0.112510165400612]},
    {input: [0.4265625, 0.475], output: [0.067230071798602]},
    {input: [0.409375, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.534375, 0.475], output: [0.067230071798602]},
    {input: [0.4609375, 0.5], output: [0.067230071798602]},
    {input: [0.33125, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.425], output: [0.0445900249975972]},
    {input: [0.4375, 0.461111111111111], output: [0.067230071798602]},
    {input: [0.4046875, 0.455555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.3765625, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.478125, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.38125, 0.422222222222222], output: [0.0445900249975972]},
    {input: [0.3984375, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.0361111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.175], output: [0.0219499781965924]},
    {input: [0.28125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.3875, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.38125, 0.0777777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.471875, 0.105555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.125], output: [0.067230071798602]},
    {input: [0.2578125, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.16875, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.078125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.2], output: [0.0219499781965924]},
    {input: [0.409375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.3875, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.415625, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.119444444444444], output: [0.112510165400612]},
    {input: [0.409375, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.415625, 0.127777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.105555555555556], output: [0.0898701185996068]},
    {input: [0.3984375, 0.0777777777777778], output: [0.0898701185996068]},
    {input: [0.3375, 0.0972222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.0609375, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.1625, 0.575], output: [0.0219499781965924]},
    {input: [0.1625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.309375, 0.55], output: [0.0219499781965924]},
    {input: [0.253125, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.2578125, 0.705555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.8], output: [0.067230071798602]},
    {input: [0.21875, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.522222222222222], output: [0.067230071798602]},
    {input: [0.2921875, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.547222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.625], output: [0.0219499781965924]},
    {input: [0.04375, 0.05], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.4046875, 0.075], output: [0.067230071798602]},
    {input: [0.45, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.4609375, 0.0833333333333333], output: [0.0898701185996068]},
    {input: [0.4546875, 0.0472222222222222], output: [0.0898701185996068]},
    {input: [0.10625, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.4265625, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.33125, 0.127777777777778], output: [0.0898701185996068]},
    {input: [0.26875, 0.111111111111111], output: [0.067230071798602]},
    {input: [0.190625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.05, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.3484375, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.4328125, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.5453125, 0.775], output: [0.0898701185996068]},
    {input: [0.5109375, 0.752777777777778], output: [0.112510165400612]},
    {input: [0.4546875, 0.752777777777778], output: [0.112510165400612]},
    {input: [0.4265625, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.44375, 0.775], output: [0.112510165400612]},
    {input: [0.4609375, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.4609375, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.4828125, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.44375, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.4546875, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.4546875, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.44375, 0.788888888888889], output: [0.112510165400612]},
    {input: [0.4265625, 0.788888888888889], output: [0.112510165400612]},
    {input: [0.359375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.3375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.75], output: [0.0445900249975972]},
    {input: [0.2359375, 0.775], output: [0.0219499781965924]},
    {input: [0.1734375, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.33125, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.365625, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.38125, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.33125, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.225, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.10625, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.0671875, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.975], output: [0.0219499781965924]},
    {input: [0.0390625, 0.75], output: [0.0219499781965924]},
    {input: [0.0390625, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.402777777777778], output: [-0.000690068604412431]},
    {input: [0.1125, 0.486111111111111], output: [-0.000690068604412431]},
    {input: [0.15625, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.05625, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.688888888888889], output: [-0.000690068604412431]},
    {input: [0.275, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.3703125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.246875, 0.611111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.691666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.38125, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.2578125, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.21875, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.2125, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.084375, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.763888888888889], output: [-0.000690068604412431]},
    {input: [0.4046875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.16875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.365625, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.3765625, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.2078125, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.775], output: [0.0219499781965924]},
    {input: [0.16875, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.475], output: [0.0219499781965924]},
    {input: [0.0890625, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.775], output: [0.0219499781965924]},
    {input: [0.1171875, 0.75], output: [0.0219499781965924]},
    {input: [0.1453125, 0.6], output: [0.0219499781965924]},
    {input: [0.140625, 0.75], output: [-0.000690068604412431]},
    {input: [0.1625, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.05625, 0.025], output: [0.0219499781965924]},
    {input: [0.078125, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.95], output: [0.0219499781965924]},
    {input: [0.084375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.275], output: [0.0219499781965924]},
    {input: [0.0671875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.430555555555556], output: [-0.000690068604412431]},
    {input: [0.10625, 0.575], output: [0.0219499781965924]},
    {input: [0.05625, 0.644444444444444], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.652777777777778], output: [-0.000690068604412431]},
    {input: [0.465625, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.4546875, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.365625, 0.675], output: [0.067230071798602]},
    {input: [0.5734375, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.5, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.55, 0.747222222222222], output: [0.157790259002621]},
    {input: [0.6578125, 0.772222222222222], output: [0.180430305803626]},
    {input: [0.5453125, 0.786111111111111], output: [0.157790259002621]},
    {input: [0.4046875, 0.805555555555556], output: [0.135150212201616]},
    {input: [0.4375, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.3203125, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.2078125, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.16875, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.28125, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.21875, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.775], output: [0.0445900249975972]},
    {input: [0.2125, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.469444444444444], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.663888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.619444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.525], output: [-0.000690068604412431]},
    {input: [0.10625, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.10625, 0.275], output: [0.0219499781965924]},
    {input: [0.1796875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.2], output: [0.0219499781965924]},
    {input: [0.084375, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.04375, 0.333333333333333], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.511111111111111], output: [-0.000690068604412431]},
    {input: [0.10625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0555555555555556], output: [-0.000690068604412431]},
    {input: [0.028125, 0.963888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.638888888888889], output: [-0.000690068604412431]},
    {input: [0.1, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.16875, 0.752777777777778], output: [-0.000690068604412431]},
    {input: [0.084375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.15], output: [-0.000690068604412431]},
    {input: [0.071875, 0.955555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.991666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.708333333333333], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.833333333333333], output: [-0.000690068604412431]},
    {input: [0.184375, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.44375, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.134375, 0.822222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.0328125, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.0609375, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.0328125, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.1125, 0.933333333333333], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.619444444444444], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.0046875, 0.0666666666666667], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.05625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.255555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.852777777777778], output: [-0.000690068604412431]},
    {input: [0.15625, 0.111111111111111], output: [-0.000690068604412431]},
    {input: [0.021875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.627777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.05], output: [-0.000690068604412431]},
    {input: [0.128125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.175], output: [-0.000690068604412431]},
    {input: [0.1125, 0.880555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.491666666666667], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.10625, 0.694444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.497222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.15625, 0.844444444444444], output: [-0.000690068604412431]},
    {input: [0.184375, 0.747222222222222], output: [-0.000690068604412431]},
    {input: [0.325, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.775], output: [0.067230071798602]},
    {input: [0.2296875, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.1734375, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.2], output: [0.0219499781965924]},
    {input: [0.240625, 0.15], output: [0.0219499781965924]},
    {input: [0.2296875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.04375, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.275], output: [0.0219499781965924]},
    {input: [0.071875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.078125, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.144444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.319444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.944444444444444], output: [-0.000690068604412431]},
    {input: [0.028125, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.078125, 0.777777777777778], output: [-0.000690068604412431]},
    {input: [0.021875, 0.316666666666667], output: [-0.000690068604412431]},
    {input: [0.15625, 0.2], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.125], output: [0.0219499781965924]},
    {input: [0.1453125, 0.225], output: [0.0219499781965924]},
    {input: [0.134375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.427777777777778], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.386111111111111], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.9], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.380555555555556], output: [-0.000690068604412431]},
    {input: [0.05, 0.702777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.338888888888889], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.536111111111111], output: [-0.000690068604412431]},
    {input: [0.2125, 0.544444444444444], output: [-0.000690068604412431]},
    {input: [0.225, 0.477777777777778], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.516666666666667], output: [-0.000690068604412431]},
    {input: [0.05625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.2], output: [0.0219499781965924]},
    {input: [0.2640625, 0.2], output: [0.0445900249975972]},
    {input: [0.365625, 0.175], output: [0.0445900249975972]},
    {input: [0.39375, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.44375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.4265625, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.3984375, 0.35], output: [0.067230071798602]},
    {input: [0.2859375, 0.35], output: [0.0445900249975972]},
    {input: [0.2078125, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.184375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.1], output: [0.0445900249975972]},
    {input: [0.2640625, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.666666666666667], output: [-0.000690068604412431]},
    {input: [0.140625, 0.636111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.588888888888889], output: [-0.000690068604412431]},
    {input: [0.1, 0.677777777777778], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.541666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.45], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.480555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.0109375, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.10625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.190625, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.15625, 0.2], output: [0.0219499781965924]},
    {input: [0.196875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.525], output: [0.0219499781965924]},
    {input: [0.1625, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.55], output: [0.0219499781965924]},
    {input: [0.0671875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.1125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.325], output: [0.0219499781965924]},
    {input: [0.078125, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.071875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.308333333333333], output: [-0.000690068604412431]},
    {input: [0.134375, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.25], output: [-0.000690068604412431]},
    {input: [0.184375, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.078125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.2], output: [0.0219499781965924]},
    {input: [0.240625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.4609375, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.391666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.530555555555556], output: [0.067230071798602]},
    {input: [0.4890625, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.6234375, 0.6], output: [0.0898701185996068]},
    {input: [0.415625, 0.569444444444444], output: [0.067230071798602]},
    {input: [0.3484375, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.4265625, 0.525], output: [0.0445900249975972]},
    {input: [0.275, 0.5], output: [0.0445900249975972]},
    {input: [0.4828125, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.528125, 0.525], output: [0.0445900249975972]},
    {input: [0.359375, 0.516666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.527777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.3], output: [0.0219499781965924]},
    {input: [0.2015625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.175], output: [0.0219499781965924]},
    {input: [0.325, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.175], output: [0.0219499781965924]},
    {input: [0.296875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.075], output: [-0.000690068604412431]},
    {input: [0.325, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.4265625, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.5671875, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.421875, 0.277777777777778], output: [0.0898701185996068]},
    {input: [0.303125, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.415625, 0.458333333333333], output: [0.067230071798602]},
    {input: [0.303125, 0.541666666666667], output: [0.067230071798602]},
    {input: [0.196875, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.45], output: [0.0219499781965924]},
    {input: [0.2640625, 0.525], output: [0.0219499781965924]},
    {input: [0.2640625, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.3203125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.353125, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.55], output: [0.0219499781965924]},
    {input: [0.246875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.95], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.1], output: [0.0219499781965924]},
    {input: [0.16875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.175], output: [0.0219499781965924]},
    {input: [0.0671875, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.75], output: [0.0219499781965924]},
    {input: [0.1, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.15625, 0.7], output: [0.0445900249975972]},
    {input: [0.16875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.65], output: [0.0219499781965924]},
    {input: [0.1515625, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.3203125, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.0609375, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.125], output: [0.0219499781965924]},
    {input: [0.128125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.347222222222222], output: [-0.000690068604412431]},
    {input: [0.26875, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.196875, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.4546875, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.44375, 0.525], output: [0.0445900249975972]},
    {input: [0.3875, 0.55], output: [0.0445900249975972]},
    {input: [0.44375, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.55], output: [0.0445900249975972]},
    {input: [0.39375, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.309375, 0.5], output: [0.0219499781965924]},
    {input: [0.2359375, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.7], output: [0.0219499781965924]},
    {input: [0.071875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.2859375, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.359375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.184375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.325], output: [0.0219499781965924]},
    {input: [0.1796875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.380555555555556], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.147222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.297222222222222], output: [-0.000690068604412431]},
    {input: [0.10625, 0.294444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.134375, 0.225], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.111111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.0722222222222222], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.958333333333333], output: [-0.000690068604412431]},
    {input: [0.1625, 0.888888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.963888888888889], output: [-0.000690068604412431]},
    {input: [0.128125, 0.6], output: [0.0219499781965924]},
    {input: [0.0671875, 0.7], output: [0.0219499781965924]},
    {input: [0.0671875, 0.938888888888889], output: [-0.000690068604412431]},
    {input: [0.26875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.415625, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.175], output: [0.067230071798602]},
    {input: [0.26875, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.325, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.35], output: [0.0445900249975972]},
    {input: [0.2015625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.359375, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.39375, 0.505555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.4375, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.541666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.561111111111111], output: [0.0445900249975972]},
    {input: [0.3703125, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.409375, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.409375, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.45, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.45, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.4328125, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.309375, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.3875, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.415625, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.353125, 0.25], output: [0.0445900249975972]},
    {input: [0.3765625, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.325], output: [0.0445900249975972]},
    {input: [0.21875, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.4046875, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.1453125, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.25], output: [0.0219499781965924]},
    {input: [0.1625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.15], output: [0.0445900249975972]},
    {input: [0.26875, 0.125], output: [0.0445900249975972]},
    {input: [0.196875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.0953125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.25], output: [0.0219499781965924]},
    {input: [0.225, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.05, 0.675], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.577777777777778], output: [-0.000690068604412431]},
    {input: [0.128125, 0.833333333333333], output: [-0.000690068604412431]},
    {input: [0.190625, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.16875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.975], output: [0.0219499781965924]},
    {input: [0.134375, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.4375, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.572222222222222], output: [0.067230071798602]},
    {input: [0.3375, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.325, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.775], output: [0.0898701185996068]},
    {input: [0.16875, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.10625, 0.775], output: [0.067230071798602]},
    {input: [0.078125, 0.608333333333333], output: [0.067230071798602]},
    {input: [0.184375, 0.725], output: [0.0445900249975972]},
    {input: [0.2578125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.65], output: [0.0445900249975972]},
    {input: [0.3421875, 0.613888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.633333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.225, 0.644444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.697222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.705555555555556], output: [0.067230071798602]},
    {input: [0.2296875, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.28125, 0.95], output: [0.0445900249975972]},
    {input: [0.184375, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.775], output: [0.0445900249975972]},
    {input: [0.303125, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.296875, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.3203125, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.3875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.409375, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.421875, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.296875, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.8], output: [0.0445900249975972]},
    {input: [0.2125, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.8], output: [0.0445900249975972]},
    {input: [0.4375, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.38125, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.38125, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.296875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.725], output: [0.0219499781965924]},
    {input: [0.1625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.75], output: [0.0219499781965924]},
    {input: [0.196875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.925], output: [0.0219499781965924]},
    {input: [0.10625, 0.85], output: [0.0219499781965924]},
    {input: [0.196875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.85], output: [0.0219499781965924]},
    {input: [0.1734375, 0.6], output: [0.0219499781965924]},
    {input: [0.190625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.783333333333333], output: [-0.000690068604412431]},
    {input: [0.15625, 0.75], output: [0.0219499781965924]},
    {input: [0.184375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.65], output: [0.0219499781965924]},
    {input: [0.1734375, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.775], output: [0.0219499781965924]},
    {input: [0.2078125, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.775], output: [0.0219499781965924]},
    {input: [0.1, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.3], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.575], output: [0.0219499781965924]},
    {input: [0.1125, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.625], output: [-0.000690068604412431]},
    {input: [0.078125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.797222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.813888888888889], output: [-0.000690068604412431]},
    {input: [0.128125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.941666666666667], output: [-0.000690068604412431]},
    {input: [0.10625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.359375, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.296875, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.196875, 0.25], output: [0.0445900249975972]},
    {input: [0.1453125, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.469444444444444], output: [-0.000690068604412431]},
    {input: [0.1125, 0.755555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.719444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.75], output: [0.0219499781965924]},
    {input: [0.0953125, 0.75], output: [-0.000690068604412431]},
    {input: [0.05625, 0.688888888888889], output: [-0.000690068604412431]},
    {input: [0.246875, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.3421875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.365625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.3703125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.0328125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.45], output: [0.0219499781965924]},
    {input: [0.2015625, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.683333333333333], output: [-0.000690068604412431]},
    {input: [0.1125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.525], output: [0.0219499781965924]},
    {input: [0.04375, 0.575], output: [-0.000690068604412431]},
    {input: [0.078125, 0.727777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.633333333333333], output: [-0.000690068604412431]},
    {input: [0.05625, 0.822222222222222], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.591666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.675], output: [-0.000690068604412431]},
    {input: [0.05625, 0.00833333333333333], output: [-0.000690068604412431]},
    {input: [0.1, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.225], output: [0.0219499781965924]},
    {input: [0.1515625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.325], output: [0.0219499781965924]},
    {input: [0.2078125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.3], output: [-0.000690068604412431]},
    {input: [0.1, 0.283333333333333], output: [-0.000690068604412431]},
    {input: [0.084375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.894444444444444], output: [-0.000690068604412431]},
    {input: [0.140625, 0.0166666666666667], output: [-0.000690068604412431]},
    {input: [0.225, 0.05], output: [0.0219499781965924]},
    {input: [0.134375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.65], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.1], output: [0.0219499781965924]},
    {input: [0.2578125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.3703125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.4046875, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.4046875, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.325, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.296875, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.190625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.3421875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.365625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.0527777777777778], output: [0.067230071798602]},
    {input: [0.2640625, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.1234375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.33125, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.4546875, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.296875, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.2578125, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.175], output: [0.0445900249975972]},
    {input: [0.15625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.0916666666666667], output: [-0.000690068604412431]},
    {input: [0.253125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.538888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.355555555555556], output: [-0.000690068604412431]},
    {input: [0.10625, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.508333333333333], output: [-0.000690068604412431]},
    {input: [0.05625, 0.6], output: [0.0219499781965924]},
    {input: [0.10625, 0.588888888888889], output: [-0.000690068604412431]},
    {input: [0.10625, 0.630555555555556], output: [-0.000690068604412431]},
    {input: [0.078125, 0.541666666666667], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.555555555555556], output: [-0.000690068604412431]},
    {input: [0.140625, 0.591666666666667], output: [-0.000690068604412431]},
    {input: [0.1625, 0.6], output: [-0.000690068604412431]},
    {input: [0.134375, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.128125, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.365625, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.8], output: [0.0445900249975972]},
    {input: [0.10625, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.7], output: [0.067230071798602]},
    {input: [0.1, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.4], output: [0.0445900249975972]},
    {input: [0.10625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.05], output: [0.0219499781965924]},
    {input: [0.084375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.2], output: [0.0219499781965924]},
    {input: [0.134375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.05], output: [0.0219499781965924]},
    {input: [0.0390625, 0.175], output: [0.0219499781965924]},
    {input: [0.1796875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.025], output: [0.0219499781965924]},
    {input: [0.028125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.175], output: [0.0445900249975972]},
    {input: [0.26875, 0.125], output: [0.0445900249975972]},
    {input: [0.253125, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.10625, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.3765625, 0.525], output: [0.0219499781965924]},
    {input: [0.3375, 0.575], output: [0.0445900249975972]},
    {input: [0.3484375, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.3984375, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.3765625, 0.677777777777778], output: [0.0898701185996068]},
    {input: [0.365625, 0.688888888888889], output: [0.112510165400612]},
    {input: [0.2859375, 0.655555555555556], output: [0.112510165400612]},
    {input: [0.1515625, 0.513888888888889], output: [0.0898701185996068]},
    {input: [0.2640625, 0.655555555555556], output: [0.067230071798602]},
    {input: [0.2359375, 0.591666666666667], output: [0.067230071798602]},
    {input: [0.184375, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.2015625, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.65], output: [0.0445900249975972]},
    {input: [0.0953125, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.05, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.375], output: [0.0445900249975972]},
    {input: [0.2296875, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.365625, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.6], output: [0.067230071798602]},
    {input: [0.2859375, 0.583333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.644444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.3421875, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.325, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.3], output: [0.067230071798602]},
    {input: [0.21875, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.125], output: [0.0445900249975972]},
    {input: [0.2296875, 0.05], output: [0.0445900249975972]},
    {input: [0.196875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.6], output: [0.0219499781965924]},
    {input: [0.253125, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.65], output: [0.0219499781965924]},
    {input: [0.078125, 0.925], output: [0.0219499781965924]},
    {input: [0.1125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.3484375, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.25], output: [0.0219499781965924]},
    {input: [0.1625, 0.475], output: [0.0219499781965924]},
    {input: [0.084375, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.15], output: [0.0219499781965924]},
    {input: [0.078125, 0.225], output: [0.0219499781965924]},
    {input: [0.0890625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.169444444444444], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.15], output: [0.0219499781965924]},
    {input: [0.1515625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.15], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.16875, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.05, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.10625, 0.319444444444444], output: [-0.000690068604412431]},
    {input: [0.10625, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.3375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.3484375, 0.225], output: [0.0445900249975972]},
    {input: [0.2859375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.275], output: [0.0445900249975972]},
    {input: [0.3203125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.33125, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.175], output: [0.0445900249975972]},
    {input: [0.240625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.2], output: [0.0445900249975972]},
    {input: [0.1796875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.45, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.44375, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.38125, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.38125, 0.688888888888889], output: [0.0898701185996068]},
    {input: [0.353125, 0.680555555555556], output: [0.0898701185996068]},
    {input: [0.409375, 0.697222222222222], output: [0.135150212201616]},
    {input: [0.33125, 0.666666666666667], output: [0.157790259002621]},
    {input: [0.3984375, 0.719444444444444], output: [0.135150212201616]},
    {input: [0.49375, 0.752777777777778], output: [0.157790259002621]},
    {input: [0.4546875, 0.747222222222222], output: [0.135150212201616]},
    {input: [0.4046875, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.359375, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.3203125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.1171875, 0.677777777777778], output: [0.067230071798602]},
    {input: [0.0890625, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.134375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.475], output: [0.0219499781965924]},
    {input: [0.246875, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.025], output: [0.0219499781965924]},
    {input: [0.0109375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.25], output: [0.0219499781965924]},
    {input: [0.0671875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.45], output: [0.0219499781965924]},
    {input: [0.021875, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.522222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.605555555555556], output: [-0.000690068604412431]},
    {input: [0.071875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.780555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.680555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.536111111111111], output: [-0.000690068604412431]},
    {input: [0.128125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.16875, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.525], output: [0.0219499781965924]},
    {input: [0.0671875, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0046875, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.569444444444444], output: [-0.000690068604412431]},
    {input: [0.10625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.591666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.502777777777778], output: [-0.000690068604412431]},
    {input: [0.184375, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.966666666666667], output: [-0.000690068604412431]},
    {input: [0.3703125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.04375, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.8], output: [0.0219499781965924]},
    {input: [0.084375, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.375], output: [0.0219499781965924]},
    {input: [0.084375, 0.4], output: [0.0219499781965924]},
    {input: [0.10625, 0.475], output: [0.0219499781965924]},
    {input: [0.05625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.927777777777778], output: [-0.000690068604412431]},
    {input: [0.128125, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.1125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.213888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.583333333333333], output: [0.0898701185996068]},
    {input: [0.325, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.3984375, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.409375, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.359375, 0.727777777777778], output: [0.0898701185996068]},
    {input: [0.128125, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.409375, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.3421875, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.359375, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.7], output: [0.0219499781965924]},
    {input: [0.353125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.05], output: [0.0219499781965924]},
    {input: [0.2078125, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.1], output: [0.0219499781965924]},
    {input: [0.296875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.16875, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.25], output: [0.0219499781965924]},
    {input: [0.1171875, 0.275], output: [0.0219499781965924]},
    {input: [0.1, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.275], output: [0.0219499781965924]},
    {input: [0.246875, 0.25], output: [0.0219499781965924]},
    {input: [0.246875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.25], output: [0.0445900249975972]},
    {input: [0.0953125, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.028125, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.15], output: [0.0219499781965924]},
    {input: [0.365625, 0.15], output: [0.0445900249975972]},
    {input: [0.415625, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.409375, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.39375, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.15], output: [0.0445900249975972]},
    {input: [0.2640625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.230555555555556], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.078125, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.85], output: [0.0219499781965924]},
    {input: [0.1734375, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.16875, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.1171875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.084375, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.1, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.028125, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.35], output: [0.0219499781965924]},
    {input: [0.078125, 1], output: [0.0219499781965924]},
    {input: [0.1515625, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.365625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.353125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.415625, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.3875, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.3703125, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.3140625, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.465625, 0.772222222222222], output: [0.112510165400612]},
    {input: [0.4546875, 0.75], output: [0.0898701185996068]},
    {input: [0.409375, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.3375, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.3203125, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.225, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.196875, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.602777777777778], output: [-0.000690068604412431]},
    {input: [0.240625, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.625], output: [0.0445900249975972]},
    {input: [0.275, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.2078125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.1796875, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.028125, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.625], output: [0.0219499781965924]},
    {input: [0.2359375, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.309375, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.3421875, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.633333333333333], output: [0.067230071798602]},
    {input: [0.2578125, 0.675], output: [0.067230071798602]},
    {input: [0.3703125, 0.7], output: [0.067230071798602]},
    {input: [0.3875, 0.719444444444444], output: [0.067230071798602]},
    {input: [0.4609375, 0.75], output: [0.0898701185996068]},
    {input: [0.4328125, 0.786111111111111], output: [0.0898701185996068]},
    {input: [0.44375, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.4265625, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.3421875, 0.8], output: [0.067230071798602]},
    {input: [0.2578125, 0.8], output: [0.067230071798602]},
    {input: [0.196875, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.1125, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.05625, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.675], output: [0.0445900249975972]},
    {input: [0.2078125, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.1734375, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.55], output: [0.0219499781965924]},
    {input: [0.1796875, 0.6], output: [0.0219499781965924]},
    {input: [0.16875, 0.525], output: [0.0219499781965924]},
    {input: [0.10625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.625], output: [0.0219499781965924]},
    {input: [0.0109375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.44375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.5171875, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.6015625, 0.197222222222222], output: [0.112510165400612]},
    {input: [0.6625, 0.152777777777778], output: [0.135150212201616]},
    {input: [0.6234375, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.50625, 0.0888888888888889], output: [0.135150212201616]},
    {input: [0.584375, 0.108333333333333], output: [0.135150212201616]},
    {input: [0.55, 0.0916666666666667], output: [0.135150212201616]},
    {input: [0.5390625, 0.105555555555556], output: [0.112510165400612]},
    {input: [0.49375, 0.116666666666667], output: [0.112510165400612]},
    {input: [0.478125, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.44375, 0.119444444444444], output: [0.112510165400612]},
    {input: [0.4046875, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.3703125, 0.125], output: [0.0898701185996068]},
    {input: [0.246875, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.1734375, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.119444444444444], output: [-0.000690068604412431]},
    {input: [0.28125, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.225, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.461111111111111], output: [-0.000690068604412431]},
    {input: [0.071875, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.558333333333333], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.469444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.727777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.875], output: [-0.000690068604412431]},
    {input: [0.05625, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.1, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.10625, 0.35], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.425], output: [-0.000690068604412431]},
    {input: [0.1, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.0916666666666667], output: [-0.000690068604412431]},
    {input: [0.04375, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.359375, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0861111111111111], output: [-0.000690068604412431]},
    {input: [0.15625, 0.00555555555555556], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.45], output: [0.0219499781965924]},
    {input: [0.071875, 0.575], output: [0.0219499781965924]},
    {input: [0.0953125, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.65], output: [0.0219499781965924]},
    {input: [0.015625, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.444444444444444], output: [-0.000690068604412431]},
    {input: [0.240625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.421875, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.44375, 0.194444444444444], output: [0.0898701185996068]},
    {input: [0.38125, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.309375, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.325, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.33125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.488888888888889], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.527777777777778], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.622222222222222], output: [-0.000690068604412431]},
    {input: [0.015625, 0.794444444444444], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.372222222222222], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.775], output: [0.0219499781965924]},
    {input: [0.071875, 0.836111111111111], output: [-0.000690068604412431]},
    {input: [0.015625, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.541666666666667], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.45], output: [0.0219499781965924]},
    {input: [0.1234375, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.353125, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.3203125, 0.25], output: [0.067230071798602]},
    {input: [0.365625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.3421875, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.33125, 0.25], output: [0.067230071798602]},
    {input: [0.2296875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.3], output: [0.0445900249975972]},
    {input: [0.1515625, 0.3], output: [0.0219499781965924]},
    {input: [0.2078125, 0.2], output: [0.0219499781965924]},
    {input: [0.28125, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.3421875, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.125], output: [0.0445900249975972]},
    {input: [0.3375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.3875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.3875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.409375, 0.175], output: [0.067230071798602]},
    {input: [0.39375, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.3484375, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.3484375, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.3375, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.175], output: [0.067230071798602]},
    {input: [0.353125, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.33125, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.303125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.425], output: [0.0219499781965924]},
    {input: [0.071875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.694444444444444], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.622222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.246875, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.2640625, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.140625, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.0390625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.825], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.969444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.246875, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.025], output: [0.0219499781965924]},
    {input: [0.2078125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0916666666666667], output: [-0.000690068604412431]},
    {input: [0.190625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.15], output: [0.0219499781965924]},
    {input: [0.1796875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.761111111111111], output: [-0.000690068604412431]},
    {input: [0.1125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.725], output: [-0.000690068604412431]},
    {input: [0.04375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.04375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.21875, 0.15], output: [-0.000690068604412431]},
    {input: [0.303125, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.196875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.3484375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.309375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.3421875, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.15625, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.1171875, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.05625, 0.525], output: [0.0219499781965924]},
    {input: [0.05625, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.353125, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.15], output: [0.0445900249975972]},
    {input: [0.303125, 0.15], output: [0.0445900249975972]},
    {input: [0.2640625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.225], output: [0.0445900249975972]},
    {input: [0.2859375, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.33125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3765625, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.775], output: [0.0219499781965924]},
    {input: [0.1125, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.675], output: [0.0219499781965924]},
    {input: [0.1234375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.444444444444444], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.015625, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.078125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.075], output: [-0.000690068604412431]},
    {input: [0.04375, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.338888888888889], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.930555555555556], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.028125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.552777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.3484375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.409375, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.4828125, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.50625, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.55, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.4828125, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.4375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.4265625, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.2015625, 0.8], output: [0.067230071798602]},
    {input: [0.2640625, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.1734375, 0.85], output: [0.0445900249975972]},
    {input: [0.325, 0.697222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.3765625, 0.772222222222222], output: [0.112510165400612]},
    {input: [0.471875, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.55, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.5734375, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.55625, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.50625, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.49375, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.584375, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.6515625, 0.797222222222222], output: [0.135150212201616]},
    {input: [0.60625, 0.8], output: [0.135150212201616]},
    {input: [0.521875, 0.794444444444444], output: [0.135150212201616]},
    {input: [0.534375, 0.775], output: [0.135150212201616]},
    {input: [0.534375, 0.775], output: [0.135150212201616]},
    {input: [0.534375, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.50625, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.44375, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.3984375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.275, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.325, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.3984375, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.296875, 0.766666666666667], output: [0.112510165400612]},
    {input: [0.5171875, 0.786111111111111], output: [0.135150212201616]},
    {input: [0.5671875, 0.777777777777778], output: [0.135150212201616]},
    {input: [0.61875, 0.783333333333333], output: [0.157790259002621]},
    {input: [0.6296875, 0.780555555555556], output: [0.157790259002621]},
    {input: [0.6234375, 0.788888888888889], output: [0.135150212201616]},
    {input: [0.5625, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.6015625, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.60625, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.578125, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.415625, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.50625, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.822222222222222], output: [0.123830188801114]},
    {input: [0.3375, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.095, 0.25], output: [0.0604380577583006]},
    {input: [0.1234375, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.21875, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.05], output: [0.0219499781965924]},
    {input: [0.2359375, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.471875, 0.05], output: [0.0445900249975972]},
    {input: [0.2078125, 0.0138888888888889], output: [0.0445900249975972]},
    {input: [0.128125, 0.025], output: [0.0219499781965924]},
    {input: [0.2078125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.190625, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.38125, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.4890625, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.415625, 0.161111111111111], output: [0.0898701185996068]},
    {input: [0.4046875, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.3875, 0.15], output: [0.067230071798602]},
    {input: [0.2296875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.075], output: [0.0219499781965924]},
    {input: [0.2359375, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.825], output: [0.0445900249975972]},
    {input: [0.021875, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.303125, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.813888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.196875, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.253125, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.275, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.1515625, 0.641666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.3375, 0.8], output: [0.0219499781965924]},
    {input: [0.3765625, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.3203125, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.2859375, 0.825], output: [0.067230071798602]},
    {input: [0.084375, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.05, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.25], output: [0.0219499781965924]},
    {input: [0.0609375, 0.525], output: [0.0219499781965924]},
    {input: [0.05625, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.38125, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.584375, 0.694444444444444], output: [0.180430305803626]},
    {input: [0.0953125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.327777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.483333333333333], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.927777777777778], output: [-0.000690068604412431]},
    {input: [0.134375, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.925], output: [-0.000690068604412431]},
    {input: [0.071875, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.597222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.658333333333333], output: [-0.000690068604412431]},
    {input: [0.071875, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.872222222222222], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.95], output: [0.0219499781965924]},
    {input: [0.0671875, 0.925], output: [0.0219499781965924]},
    {input: [0.26875, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 1], output: [0.0219499781965924]},
    {input: [0.10625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.775], output: [0.0219499781965924]},
    {input: [0.128125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.75], output: [0.0219499781965924]},
    {input: [0.1625, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.246875, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.2640625, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.05625, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.00555555555555556], output: [0.0445900249975972]},
    {input: [0.028125, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.0890625, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.7], output: [0.0219499781965924]},
    {input: [0.134375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.663888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.825], output: [-0.000690068604412431]},
    {input: [0.05, 0.025], output: [-0.000690068604412431]},
    {input: [0.084375, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.078125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.725], output: [0.0219499781965924]},
    {input: [0.196875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.763888888888889], output: [-0.000690068604412431]},
    {input: [0.084375, 0.680555555555556], output: [-0.000690068604412431]},
    {input: [0.140625, 0.702777777777778], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.602777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.708333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.716666666666667], output: [-0.000690068604412431]},
    {input: [0.140625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.75], output: [0.0219499781965924]},
    {input: [0.15625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.194444444444444], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.0944444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.0046875, 0.780555555555556], output: [-0.000690068604412431]},
    {input: [0.05625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.015625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.380555555555556], output: [-0.000690068604412431]},
    {input: [0.05, 0.944444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.175], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.15], output: [0.0219499781965924]},
    {input: [0.1234375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.15], output: [0.0219499781965924]},
    {input: [0.225, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.15], output: [0.0219499781965924]},
    {input: [0.15625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.25], output: [0.0219499781965924]},
    {input: [0.1234375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.0611111111111111], output: [-0.000690068604412431]},
    {input: [0.1, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.10625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.275], output: [0.0219499781965924]},
    {input: [0.04375, 0.175], output: [0.0219499781965924]},
    {input: [0.1234375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.441666666666667], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.04375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.369444444444444], output: [-0.000690068604412431]},
    {input: [0.028125, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.028125, 0.111111111111111], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.436111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.805555555555556], output: [-0.000690068604412431]},
    {input: [0.10625, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.688888888888889], output: [-0.000690068604412431]},
    {input: [0.1, 0.636111111111111], output: [-0.000690068604412431]},
    {input: [0.1125, 0.575], output: [0.0219499781965924]},
    {input: [0.021875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.95], output: [0.0219499781965924]},
    {input: [0.0390625, 0.911111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.327777777777778], output: [-0.000690068604412431]},
    {input: [0.05625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.311111111111111], output: [-0.000690068604412431]},
    {input: [0.071875, 0.569444444444444], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.675], output: [0.0219499781965924]},
    {input: [0.05625, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.872222222222222], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.397222222222222], output: [-0.000690068604412431]},
    {input: [0.021875, 0.830555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.441666666666667], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.608333333333333], output: [-0.000690068604412431]},
    {input: [0.084375, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.775], output: [0.0219499781965924]},
    {input: [0.15625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.630555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.663888888888889], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.1], output: [0.0445900249975972]},
    {input: [0.05625, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.184375, 0.8], output: [0.0445900249975972]},
    {input: [0.16875, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.825], output: [0.0445900249975972]},
    {input: [0.2921875, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.3203125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.353125, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.409375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.359375, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.3375, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.33125, 0.775], output: [0.067230071798602]},
    {input: [0.28125, 0.786111111111111], output: [0.0898701185996068]},
    {input: [0.2578125, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.184375, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.128125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.0328125, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.0953125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.4265625, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.4375, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.465625, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.4375, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.55625, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.3140625, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.38125, 0.419444444444444], output: [0.0898701185996068]},
    {input: [0.33125, 0.561111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.547222222222222], output: [0.067230071798602]},
    {input: [0.1796875, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.421875, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.296875, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.575], output: [0.0445900249975972]},
    {input: [0.1734375, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.1734375, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.45], output: [0.0445900249975972]},
    {input: [0.190625, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.3984375, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.478125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.49375, 0.225], output: [0.067230071798602]},
    {input: [0.465625, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.5625, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.55625, 0.108333333333333], output: [0.135150212201616]},
    {input: [0.6125, 0.0611111111111111], output: [0.135150212201616]},
    {input: [0.6234375, 0.0833333333333333], output: [0.157790259002621]},
    {input: [0.578125, 0.0944444444444444], output: [0.157790259002621]},
    {input: [0.55625, 0.0916666666666667], output: [0.157790259002621]},
    {input: [0.5109375, 0.0805555555555556], output: [0.135150212201616]},
    {input: [0.409375, 0.0583333333333333], output: [0.135150212201616]},
    {input: [0.240625, 0.883333333333333], output: [0.135150212201616]},
    {input: [0.55, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.640625, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.640625, 0.727777777777778], output: [0.157790259002621]},
    {input: [0.578125, 0.697222222222222], output: [0.180430305803626]},
    {input: [0.55625, 0.694444444444444], output: [0.157790259002621]},
    {input: [0.5390625, 0.680555555555556], output: [0.135150212201616]},
    {input: [0.528125, 0.666666666666667], output: [0.112510165400612]},
    {input: [0.478125, 0.638888888888889], output: [0.135150212201616]},
    {input: [0.38125, 0.625], output: [0.135150212201616]},
    {input: [0.44375, 0.661111111111111], output: [0.135150212201616]},
    {input: [0.3875, 0.625], output: [0.112510165400612]},
    {input: [0.3375, 0.611111111111111], output: [0.0898701185996068]},
    {input: [0.1515625, 0.675], output: [0.0898701185996068]},
    {input: [0.0890625, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.0609375, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.196875, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.4546875, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.44375, 0.0611111111111111], output: [0.067230071798602]},
    {input: [0.4890625, 0.05], output: [0.067230071798602]},
    {input: [0.5671875, 0.0694444444444444], output: [0.0898701185996068]},
    {input: [0.696875, 0.075], output: [0.0898701185996068]},
    {input: [0.7421875, 0.113888888888889], output: [0.135150212201616]},
    {input: [0.675, 0.0944444444444444], output: [0.157790259002621]},
    {input: [0.6125, 0.102777777777778], output: [0.203070352604631]},
    {input: [0.5671875, 0.127777777777778], output: [0.180430305803626]},
    {input: [0.5953125, 0.169444444444444], output: [0.180430305803626]},
    {input: [0.5453125, 0.372222222222222], output: [0.180430305803626]},
    {input: [0.5453125, 0.475], output: [0.157790259002621]},
    {input: [0.5625, 0.469444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.538888888888889], output: [0.112510165400612]},
    {input: [0.471875, 0.488888888888889], output: [0.112510165400612]},
    {input: [0.45, 0.522222222222222], output: [0.112510165400612]},
    {input: [0.5675, 0.602777777777778], output: [0.119302179440913]},
    {input: [0.521875, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.534375, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.608333333333333], output: [0.067230071798602]},
    {input: [0.071875, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.16875, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.196875, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.7], output: [0.0445900249975972]},
    {input: [0.1515625, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.0671875, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.05, 0.375], output: [0.0445900249975972]},
    {input: [0.1, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.611111111111111], output: [0.067230071798602]},
    {input: [0.3765625, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.675], output: [0.067230071798602]},
    {input: [0.2859375, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.652777777777778], output: [0.067230071798602]},
    {input: [0.2078125, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.2078125, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.184375, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.140625, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.0328125, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.23, 0.247222222222222], output: [0.0196859735164919]},
    {input: [0.2015625, 0.280555555555556], output: [-0.000690068604412431]},
    {input: [0.2640625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.325], output: [0.0219499781965924]},
    {input: [0.296875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.39375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.478125, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.421875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.5109375, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.534375, 0.388888888888889], output: [0.0898701185996068]},
    {input: [0.415625, 0.4], output: [0.0898701185996068]},
    {input: [0.275, 0.419444444444444], output: [0.112510165400612]},
    {input: [0.421875, 0.975], output: [0.112510165400612]},
    {input: [0.21875, 0.983333333333333], output: [0.0898701185996068]},
    {input: [0.1515625, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.1, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.196875, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.3703125, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.3875, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.465625, 0.730555555555556], output: [0.112510165400612]},
    {input: [0.39375, 0.711111111111111], output: [0.112510165400612]},
    {input: [0.415625, 0.705555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.359375, 0.672222222222222], output: [0.112510165400612]},
    {input: [0.3421875, 0.65], output: [0.112510165400612]},
    {input: [0.336875, 0.641666666666667], output: [0.162318268362822]},
    {input: [0.3484375, 0.672222222222222], output: [0.0898701185996068]},
    {input: [0.253125, 0.663888888888889], output: [0.0898701185996068]},
    {input: [0.21875, 0.644444444444444], output: [0.0898701185996068]},
    {input: [0.1734375, 0.675], output: [0.067230071798602]},
    {input: [0.1234375, 0.652777777777778], output: [0.067230071798602]},
    {input: [0.084375, 0.0222222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.5], output: [0.0219499781965924]},
    {input: [0.1734375, 0.383333333333333], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.2], output: [0.0219499781965924]},
    {input: [0.3203125, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.353125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.33125, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.125], output: [0.067230071798602]},
    {input: [0.16875, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.1453125, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.4], output: [0.0219499781965924]},
    {input: [0.015625, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.630555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.988888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.128125, 0.433333333333333], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.861111111111111], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.777777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.525], output: [-0.000690068604412431]},
    {input: [0.1125, 0.572222222222222], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.538888888888889], output: [-0.000690068604412431]},
    {input: [0.190625, 0.658333333333333], output: [-0.000690068604412431]},
    {input: [0.16875, 0.533333333333333], output: [-0.000690068604412431]},
    {input: [0.05, 0.558333333333333], output: [0.00836595011598949]},
    {input: [0.078125, 0.588888888888889], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.190625, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.134375, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.078125, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.0890625, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.575], output: [0.0445900249975972]},
    {input: [0.190625, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.625], output: [0.0445900249975972]},
    {input: [0.1234375, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.0725, 0.294444444444444], output: [0.0332700015970948]},
    {input: [0.078125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.686111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.669444444444444], output: [-0.000690068604412431]},
    {input: [0.353125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.3984375, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.694444444444444], output: [0.0898701185996068]},
    {input: [0.3703125, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.4546875, 0.677777777777778], output: [0.0898701185996068]},
    {input: [0.494375, 0.672222222222222], output: [0.141942226241918]},
    {input: [0.50625, 0.65], output: [0.112510165400612]},
    {input: [0.465625, 0.666666666666667], output: [0.112510165400612]},
    {input: [0.521875, 0.711111111111111], output: [0.112510165400612]},
    {input: [0.5953125, 0.741666666666667], output: [0.157790259002621]},
    {input: [0.6515625, 0.758333333333333], output: [0.180430305803626]},
    {input: [0.55, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.528125, 0.766666666666667], output: [0.180430305803626]},
    {input: [0.5109375, 0.766666666666667], output: [0.157790259002621]},
    {input: [0.4546875, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.3421875, 0.741666666666667], output: [0.135150212201616]},
    {input: [0.32, 0.727777777777778], output: [0.137414216881717]},
    {input: [0.353125, 0.705555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.275, 0.7], output: [0.0445900249975972]},
    {input: [0.314375, 0.716666666666667], output: [0.0830781045593054]},
    {input: [0.2921875, 0.719444444444444], output: [0.0445900249975972]},
    {input: [0.4046875, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.1796875, 0.575], output: [0.0445900249975972]},
    {input: [0.134375, 0.55], output: [0.0445900249975972]},
    {input: [0.04375, 0.6], output: [0.0219499781965924]},
    {input: [0.05625, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.028125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.05, 0.225], output: [0.0219499781965924]},
    {input: [0.05, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.275], output: [0.0219499781965924]},
    {input: [0.1453125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.15], output: [0.0219499781965924]},
    {input: [0.2640625, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.33125, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.3875, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.4046875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.3203125, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.3421875, 0.15], output: [0.067230071798602]},
    {input: [0.45, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.5734375, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.5671875, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.521875, 0.0944444444444444], output: [0.135150212201616]},
    {input: [0.5953125, 0.0861111111111111], output: [0.157790259002621]},
    {input: [0.6015625, 0.0611111111111111], output: [0.157790259002621]},
    {input: [0.590625, 0.0444444444444444], output: [0.135150212201616]},
    {input: [0.5671875, 0.0333333333333333], output: [0.157790259002621]},
    {input: [0.478125, 0.0444444444444444], output: [0.135150212201616]},
    {input: [0.3375, 0.0166666666666667], output: [0.135150212201616]},
    {input: [0.2921875, 0.958333333333333], output: [0.112510165400612]},
    {input: [0.184375, 0.872222222222222], output: [0.0898701185996068]},
    {input: [0.196875, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.365625, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.4375, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.44375, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.50625, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.805555555555556], output: [0.112510165400612]},
    {input: [0.409375, 0.816666666666667], output: [0.112510165400612]},
    {input: [0.4046875, 0.847222222222222], output: [0.0898701185996068]},
    {input: [0.365625, 0.833333333333333], output: [0.0898701185996068]},
    {input: [0.38125, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.1453125, 0.025], output: [0.0898701185996068]},
    {input: [0.1234375, 0.0222222222222222], output: [0.067230071798602]},
    {input: [0.184375, 0.991666666666667], output: [0.067230071798602]},
    {input: [0.2125, 0.0444444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.0166666666666667], output: [0.067230071798602]},
    {input: [0.2296875, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.246875, 0.975], output: [0.067230071798602]},
    {input: [0.2359375, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.1734375, 0.961111111111111], output: [0.067230071798602]},
    {input: [0.1796875, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.05, 0.986111111111111], output: [0.067230071798602]},
    {input: [0.140625, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.1515625, 0.825], output: [0.067230071798602]},
    {input: [0.415625, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.465625, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.5109375, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.675, 0.1], output: [0.112510165400612]},
    {input: [0.6515625, 0.0972222222222222], output: [0.180430305803626]},
    {input: [0.6234375, 0.1], output: [0.180430305803626]},
    {input: [0.578125, 0.102777777777778], output: [0.180430305803626]},
    {input: [0.4375, 0.0888888888888889], output: [0.203070352604631]},
    {input: [0.584375, 0.0861111111111111], output: [0.157790259002621]},
    {input: [0.528125, 0.0666666666666667], output: [0.225710399405636]},
    {input: [0.578125, 0.0694444444444444], output: [0.180430305803626]},
    {input: [0.5, 0.0722222222222222], output: [0.180430305803626]},
    {input: [0.465625, 0.1], output: [0.180430305803626]},
    {input: [0.50625, 0.105555555555556], output: [0.157790259002621]},
    {input: [0.365625, 0.0888888888888889], output: [0.135150212201616]},
    {input: [0.3484375, 0.105555555555556], output: [0.135150212201616]},
    {input: [0.26875, 0.138888888888889], output: [0.112510165400612]},
    {input: [0.1125, 0.122222222222222], output: [0.112510165400612]},
    {input: [0.078125, 0.0777777777777778], output: [0.0898701185996068]},
    {input: [0.1, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.0609375, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.071875, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.825], output: [0.0445900249975972]},
    {input: [0.10625, 1], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.275], output: [0.0219499781965924]},
    {input: [0.071875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.05], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0555555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.0805555555555556], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.383333333333333], output: [-0.000690068604412431]},
    {input: [0.1, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.225], output: [0.0219499781965924]},
    {input: [0.190625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.125], output: [0.0219499781965924]},
    {input: [0.190625, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.0861111111111111], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.075], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.0277777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.05], output: [0.0219499781965924]},
    {input: [0.10625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0638888888888889], output: [-0.000690068604412431]},
    {input: [0.128125, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.1625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.0833333333333333], output: [-0.000690068604412431]},
    {input: [0.253125, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.1], output: [0.0219499781965924]},
    {input: [0.2578125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.3484375, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.38125, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.353125, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.3875, 0.075], output: [0.0898701185996068]},
    {input: [0.3984375, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.3765625, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.296875, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.296875, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.078125, 0.125], output: [0.0445900249975972]},
    {input: [0.04375, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.0953125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.028125, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.16875, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.4546875, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.4265625, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.365625, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.246875, 0.416666666666667], output: [0.0898701185996068]},
    {input: [0.415625, 0.394444444444444], output: [0.112510165400612]},
    {input: [0.4890625, 0.397222222222222], output: [0.112510165400612]},
    {input: [0.4265625, 0.372222222222222], output: [0.135150212201616]},
    {input: [0.2578125, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.2125, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.246875, 0.108333333333333], output: [0.112510165400612]},
    {input: [0.134375, 0.175], output: [0.0898701185996068]},
    {input: [0.1734375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.184375, 0.277777777777778], output: [0.0898701185996068]},
    {input: [0.365625, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.3375, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.1234375, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.421875, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.365625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.3421875, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.2078125, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.04375, 0.225], output: [0.067230071798602]},
    {input: [0.1171875, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.2], output: [0.0445900249975972]},
    {input: [0.296875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.2525, 0.330555555555556], output: [0.0762860905190039]},
    {input: [0.296875, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.275], output: [0.067230071798602]},
    {input: [0.296875, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.246875, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.3984375, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.316666666666667], output: [0.0943981279598077]},
    {input: [0.246875, 0.35], output: [0.0445900249975972]},
    {input: [0.3421875, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.4046875, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.4046875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.5171875, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.50625, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.5, 0.291666666666667], output: [0.112510165400612]},
    {input: [0.6296875, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.4609375, 0.280555555555556], output: [0.112510165400612]},
    {input: [0.5, 0.536111111111111], output: [0.135150212201616]},
    {input: [0.39375, 0.591666666666667], output: [0.0898701185996068]},
    {input: [0.5171875, 0.536111111111111], output: [0.067230071798602]},
    {input: [0.3875, 0.566666666666667], output: [0.067230071798602]},
    {input: [0.578125, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.55625, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.49375, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.471875, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.3375, 0.686111111111111], output: [0.135150212201616]},
    {input: [0.309375, 0.75], output: [0.0898701185996068]},
    {input: [0.3421875, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.3140625, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.2578125, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.2015625, 0.666666666666667], output: [0.0898701185996068]},
    {input: [0.2359375, 0.663888888888889], output: [0.0898701185996068]},
    {input: [0.2921875, 0.630555555555556], output: [0.0898701185996068]},
    {input: [0.3703125, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.348125, 0.666666666666667], output: [0.107982156040411]},
    {input: [0.325, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.2015625, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.2015625, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.1515625, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.100625, 0.7], output: [0.0151579641562909]},
    {input: [0.140625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.7], output: [0.0445900249975972]},
    {input: [0.184375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.8], output: [0.0219499781965924]},
    {input: [0.028125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.666666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.025], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.4], output: [-0.000690068604412431]},
    {input: [0.028125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.205555555555556], output: [-0.000690068604412431]},
    {input: [0.1125, 0.0611111111111111], output: [-0.000690068604412431]},
    {input: [0.134375, 0.15], output: [-0.000690068604412431]},
    {input: [0.1625, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.194444444444444], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.2], output: [0.0219499781965924]},
    {input: [0.184375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.191666666666667], output: [-0.000690068604412431]},
    {input: [0.21875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.219444444444444], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.330555555555556], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.1625, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.353125, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.33125, 0.9], output: [0.0445900249975972]},
    {input: [0.253125, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.15625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.33125, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.359375, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.28625, 0.175], output: [0.10345414668021]},
    {input: [0.39375, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.2921875, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.240625, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.15], output: [0.0898701185996068]},
    {input: [0.2296875, 0.125], output: [0.067230071798602]},
    {input: [0.3203125, 0.075], output: [0.067230071798602]},
    {input: [0.3375, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.175], output: [0.0445900249975972]},
    {input: [0.309375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.4265625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.25], output: [0.0445900249975972]},
    {input: [0.4328125, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.359375, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.50625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.50625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.5, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.4046875, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.3765625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.5390625, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.60625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.634375, 0.35], output: [0.0898701185996068]},
    {input: [0.49375, 0.35], output: [0.0898701185996068]},
    {input: [0.534375, 0.497222222222222], output: [0.0898701185996068]},
    {input: [0.4890625, 0.541666666666667], output: [0.067230071798602]},
    {input: [0.4890625, 0.55], output: [0.0445900249975972]},
    {input: [0.73125, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.5390625, 0.575], output: [0.067230071798602]},
    {input: [0.421875, 0.530555555555556], output: [0.0898701185996068]},
    {input: [0.465625, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.421875, 0.525], output: [0.0445900249975972]},
    {input: [0.465625, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.4375, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.55], output: [0.0445900249975972]},
    {input: [0.309375, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.3875, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.75], output: [0.0445900249975972]},
    {input: [0.1453125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.8], output: [0.0219499781965924]},
    {input: [0.5, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.49375, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.6015625, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.6859375, 0.761111111111111], output: [0.135150212201616]},
    {input: [0.78125, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.803125, 0.761111111111111], output: [0.180430305803626]},
    {input: [0.8265625, 0.763888888888889], output: [0.203070352604631]},
    {input: [0.775, 0.761111111111111], output: [0.180430305803626]},
    {input: [0.7875, 0.755555555555556], output: [0.203070352604631]},
    {input: [0.7921875, 0.738888888888889], output: [0.180430305803626]},
    {input: [0.746875, 0.736111111111111], output: [0.203070352604631]},
    {input: [0.7078125, 0.744444444444444], output: [0.180430305803626]},
    {input: [0.7359375, 0.75], output: [0.180430305803626]},
    {input: [0.71875, 0.752777777777778], output: [0.180430305803626]},
    {input: [0.759375, 0.761111111111111], output: [0.203070352604631]},
    {input: [0.696875, 0.755555555555556], output: [0.180430305803626]},
    {input: [0.6296875, 0.761111111111111], output: [0.180430305803626]},
    {input: [0.6515625, 0.758333333333333], output: [0.157790259002621]},
    {input: [0.634375, 0.752777777777778], output: [0.157790259002621]},
    {input: [0.5734375, 0.736111111111111], output: [0.157790259002621]},
    {input: [0.6125, 0.741666666666667], output: [0.157790259002621]},
    {input: [0.703125, 0.75], output: [0.157790259002621]},
    {input: [0.6234375, 0.727777777777778], output: [0.180430305803626]},
    {input: [0.60625, 0.722222222222222], output: [0.157790259002621]},
    {input: [0.584375, 0.738888888888889], output: [0.135150212201616]},
    {input: [0.6125, 0.727777777777778], output: [0.135150212201616]},
    {input: [0.61875, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.6296875, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.61875, 0.725], output: [0.135150212201616]},
    {input: [0.55625, 0.725], output: [0.135150212201616]},
    {input: [0.584375, 0.716666666666667], output: [0.135150212201616]},
    {input: [0.471875, 0.688888888888889], output: [0.135150212201616]},
    {input: [0.5734375, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.55625, 0.752777777777778], output: [0.135150212201616]},
    {input: [0.465625, 0.772222222222222], output: [0.135150212201616]},
    {input: [0.3484375, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.3140625, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.4046875, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.55, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.55625, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.5453125, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.61875, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.841666666666667], output: [0.112510165400612]},
    {input: [0.7875, 0.911111111111111], output: [0.157790259002621]},
    {input: [0.7421875, 0.897222222222222], output: [0.180430305803626]},
    {input: [0.746875, 0.894444444444444], output: [0.225710399405636]},
    {input: [0.60625, 0.905555555555556], output: [0.180430305803626]},
    {input: [0.5953125, 0.880555555555556], output: [0.180430305803626]},
    {input: [0.5734375, 0.877777777777778], output: [0.157790259002621]},
    {input: [0.61875, 0.869444444444444], output: [0.157790259002621]},
    {input: [0.44375, 0.847222222222222], output: [0.135150212201616]},
    {input: [0.471875, 0.872222222222222], output: [0.135150212201616]},
    {input: [0.38125, 0.847222222222222], output: [0.135150212201616]},
    {input: [0.4046875, 0.808333333333333], output: [0.135150212201616]},
    {input: [0.4328125, 0.775], output: [0.112510165400612]},
    {input: [0.33125, 0.805555555555556], output: [0.135150212201616]},
    {input: [0.3140625, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.196875, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.1796875, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.140625, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.196875, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.184375, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.275], output: [0.067230071798602]},
    {input: [0.128125, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.325], output: [0.0445900249975972]},
    {input: [0.1796875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.190625, 0.325], output: [0.0445900249975972]},
    {input: [0.140625, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.309375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.359375, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.275, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.0890625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.0361111111111111], output: [0.0445900249975972]},
    {input: [0.465625, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.5109375, 0.0833333333333333], output: [0.0898701185996068]},
    {input: [0.3875, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.421875, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.50625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.4890625, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.465625, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.5625, 0.2], output: [0.112510165400612]},
    {input: [0.5953125, 0.0777777777777778], output: [0.135150212201616]},
    {input: [0.5390625, 0.0666666666666667], output: [0.157790259002621]},
    {input: [0.5109375, 0.0388888888888889], output: [0.157790259002621]},
    {input: [0.55, 0.025], output: [0.180430305803626]},
    {input: [0.409375, 0.994444444444444], output: [0.157790259002621]},
    {input: [0.2578125, 0.955555555555556], output: [0.135150212201616]},
    {input: [0.134375, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.1, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.0328125, 0.941666666666667], output: [0.067230071798602]},
    {input: [0.078125, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.10625, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.0609375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.3], output: [0.0219499781965924]},
    {input: [0.078125, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.594444444444444], output: [-0.000690068604412431]},
    {input: [0.3375, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.3375, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.359375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.3703125, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.38125, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.3875, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.4375, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.4828125, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.4609375, 0.125], output: [0.0898701185996068]},
    {input: [0.50625, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.45, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.49375, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.521875, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.55, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.534375, 0.347222222222222], output: [0.112510165400612]},
    {input: [0.584375, 0.316666666666667], output: [0.112510165400612]},
    {input: [0.640625, 0.297222222222222], output: [0.112510165400612]},
    {input: [0.6125, 0.313888888888889], output: [0.157790259002621]},
    {input: [0.6796875, 0.319444444444444], output: [0.135150212201616]},
    {input: [0.6578125, 0.327777777777778], output: [0.180430305803626]},
    {input: [0.60625, 0.336111111111111], output: [0.180430305803626]},
    {input: [0.478125, 0.3], output: [0.157790259002621]},
    {input: [0.534375, 0.305555555555556], output: [0.135150212201616]},
    {input: [0.5, 0.288888888888889], output: [0.135150212201616]},
    {input: [0.478125, 0.316666666666667], output: [0.135150212201616]},
    {input: [0.5171875, 0.275], output: [0.135150212201616]},
    {input: [0.4546875, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.102777777777778], output: [0.135150212201616]},
    {input: [0.5171875, 0.122222222222222], output: [0.135150212201616]},
    {input: [0.5671875, 0.1], output: [0.135150212201616]},
    {input: [0.4046875, 0.102777777777778], output: [0.135150212201616]},
    {input: [0.196875, 0.508333333333333], output: [0.112510165400612]},
    {input: [0.240625, 0.475], output: [0.0898701185996068]},
    {input: [0.303125, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.7140625, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.6296875, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.528125, 0.788888888888889], output: [0.135150212201616]},
    {input: [0.33125, 0.8], output: [0.135150212201616]},
    {input: [0.240625, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.16875, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.33125, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.2125, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.253125, 0.680555555555556], output: [0.0898701185996068]},
    {input: [0.2125, 0.655555555555556], output: [0.067230071798602]},
    {input: [0.1, 0.45], output: [0.067230071798602]},
    {input: [0.184375, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.591666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.365625, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.4828125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.528125, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.4890625, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.5671875, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.66875, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.865625, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.7140625, 0.15], output: [0.24835044620664]},
    {input: [0.696875, 0.147222222222222], output: [0.203070352604631]},
    {input: [0.61875, 0.169444444444444], output: [0.180430305803626]},
    {input: [0.5671875, 0.172222222222222], output: [0.180430305803626]},
    {input: [0.590625, 0.261111111111111], output: [0.157790259002621]},
    {input: [0.5171875, 0.361111111111111], output: [0.157790259002621]},
    {input: [0.5171875, 0.377777777777778], output: [0.157790259002621]},
    {input: [0.55625, 0.369444444444444], output: [0.157790259002621]},
    {input: [0.44375, 0.413888888888889], output: [0.135150212201616]},
    {input: [0.584375, 0.669444444444444], output: [0.135150212201616]},
    {input: [0.55625, 0.569444444444444], output: [0.0898701185996068]},
    {input: [0.521875, 0.55], output: [0.112510165400612]},
    {input: [0.584375, 0.511111111111111], output: [0.0898701185996068]},
    {input: [0.49375, 0.547222222222222], output: [0.0898701185996068]},
    {input: [0.478125, 0.516666666666667], output: [0.067230071798602]},
    {input: [0.415625, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.513888888888889], output: [0.0445900249975972]},
    {input: [0.415625, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.4046875, 0.513888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.433333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.3421875, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.45], output: [0.0445900249975972]},
    {input: [0.225, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.3765625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.4265625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.3703125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.3375, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.225, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.0361111111111111], output: [0.0445900249975972]},
    {input: [0.2125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.196875, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.16875, 0], output: [0.067230071798602]},
    {input: [0.1734375, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.4609375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.2640625, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.1796875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.26875, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.275], output: [0.067230071798602]},
    {input: [0.2078125, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.275], output: [0.0445900249975972]},
    {input: [0.3484375, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.2640625, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.325], output: [0.067230071798602]},
    {input: [0.48875, 0.319444444444444], output: [0.0762860905190039]},
    {input: [0.5734375, 0.441666666666667], output: [0.157790259002621]},
    {input: [0.50625, 0.416666666666667], output: [0.157790259002621]},
    {input: [0.28125, 0.436111111111111], output: [0.157790259002621]},
    {input: [0.1, 0.319444444444444], output: [0.157790259002621]},
    {input: [0.275, 0.436111111111111], output: [0.112510165400612]},
    {input: [0.3765625, 0.444444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.3875, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.419444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.421875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.4328125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.3484375, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.253125, 0.275], output: [0.067230071798602]},
    {input: [0.309375, 0.325], output: [0.067230071798602]},
    {input: [0.3375, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.37625, 0.227777777777778], output: [0.144206230922018]},
    {input: [0.534375, 0.15], output: [0.0898701185996068]},
    {input: [0.5453125, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.125], output: [0.0898701185996068]},
    {input: [0.3765625, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.2921875, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.1453125, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.084375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.525], output: [0.0445900249975972]},
    {input: [0.3203125, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.184375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.3765625, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.3765625, 0.325], output: [0.0445900249975972]},
    {input: [0.3765625, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.4046875, 0.422222222222222], output: [0.067230071798602]},
    {input: [0.3484375, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.33125, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.38125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.4046875, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.26875, 0.35], output: [0.0898701185996068]},
    {input: [0.359375, 0.419444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.408333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.38125, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.3203125, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.3375, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.39375, 0.325], output: [0.067230071798602]},
    {input: [0.4265625, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.4609375, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.5109375, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.297222222222222], output: [0.0898701185996068]},
    {input: [0.3703125, 0.380555555555556], output: [0.0898701185996068]},
    {input: [0.3203125, 0.397222222222222], output: [0.0898701185996068]},
    {input: [0.2359375, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.2921875, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.1234375, 0.425], output: [0.067230071798602]},
    {input: [0.1453125, 0.7], output: [0.0445900249975972]},
    {input: [0.2078125, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.10625, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.65], output: [0.0219499781965924]},
    {input: [0.1234375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0], output: [0.0219499781965924]},
    {input: [0.1453125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.05], output: [0.0219499781965924]},
    {input: [0.1, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.225], output: [0.0445900249975972]},
    {input: [0.15625, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.2], output: [0.0219499781965924]},
    {input: [0.1453125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.125], output: [0.0219499781965924]},
    {input: [0.134375, 0.225], output: [0.0219499781965924]},
    {input: [0.190625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.2], output: [0.0445900249975972]},
    {input: [0.1625, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.353125, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.1515625, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.2359375, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.1625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.190625, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.201875, 0.227777777777778], output: [0.0377980109572957]},
    {input: [0.2125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.19625, 0.194444444444444], output: [0.0242139828766929]},
    {input: [0.2578125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.409375, 0.3], output: [0.0898701185996068]},
    {input: [0.483125, 0.3], output: [0.135150212201616]},
    {input: [0.578125, 0.294444444444444], output: [0.112510165400612]},
    {input: [0.66875, 0.302777777777778], output: [0.157790259002621]},
    {input: [0.7140625, 0.286111111111111], output: [0.180430305803626]},
    {input: [0.690625, 0.291666666666667], output: [0.203070352604631]},
    {input: [0.6578125, 0.313888888888889], output: [0.225710399405636]},
    {input: [0.478125, 0.408333333333333], output: [0.225710399405636]},
    {input: [0.275, 0.65], output: [0.180430305803626]},
    {input: [0.415625, 0.544444444444444], output: [0.112510165400612]},
    {input: [0.5671875, 0.519444444444444], output: [0.112510165400612]},
    {input: [0.38125, 0.588888888888889], output: [0.0898701185996068]},
    {input: [0.21875, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.196875, 0.561111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.513888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.55], output: [0.0445900249975972]},
    {input: [0.246875, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.1], output: [0.0445900249975972]},
    {input: [0.2359375, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.125], output: [0.0219499781965924]},
    {input: [0.128125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.75], output: [0.0219499781965924]},
    {input: [0.1171875, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.811111111111111], output: [-0.000690068604412431]},
    {input: [0.015625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.427777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.644444444444444], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.430555555555556], output: [-0.000690068604412431]},
    {input: [0.05625, 0.363888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.2], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.236111111111111], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.166666666666667], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.2125, 0.158333333333333], output: [-0.000690068604412431]},
    {input: [0.26875, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.225, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.365625, 0.375], output: [0.0219499781965924]},
    {input: [0.45, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.4328125, 0.375], output: [0.067230071798602]},
    {input: [0.471875, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.49375, 0.4], output: [0.067230071798602]},
    {input: [0.4046875, 0.375], output: [0.067230071798602]},
    {input: [0.3984375, 0.35], output: [0.067230071798602]},
    {input: [0.4265625, 0.375], output: [0.067230071798602]},
    {input: [0.365625, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.4265625, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.471875, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.5390625, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.521875, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.471875, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.409375, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.4828125, 0.305555555555556], output: [0.112510165400612]},
    {input: [0.4609375, 0.294444444444444], output: [0.112510165400612]},
    {input: [0.3984375, 0.308333333333333], output: [0.112510165400612]},
    {input: [0.4328125, 0.294444444444444], output: [0.112510165400612]},
    {input: [0.4046875, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.45, 0.280555555555556], output: [0.112510165400612]},
    {input: [0.521875, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.5734375, 0.375], output: [0.112510165400612]},
    {input: [0.5, 0.366666666666667], output: [0.135150212201616]},
    {input: [0.3140625, 0.313888888888889], output: [0.112510165400612]},
    {input: [0.471875, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.45, 0.305555555555556], output: [0.112510165400612]},
    {input: [0.3984375, 0.3], output: [0.112510165400612]},
    {input: [0.471875, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.60625, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.49375, 0.286111111111111], output: [0.112510165400612]},
    {input: [0.3765625, 0.397222222222222], output: [0.112510165400612]},
    {input: [0.28125, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.347222222222222], output: [0.0898701185996068]},
    {input: [0.3140625, 0.530555555555556], output: [0.0898701185996068]},
    {input: [0.2015625, 0.586111111111111], output: [0.067230071798602]},
    {input: [0.1453125, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.071875, 0.633333333333333], output: [0.067230071798602]},
    {input: [0.1, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.4265625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.309375, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.296875, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.05, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.852777777777778], output: [0.067230071798602]},
    {input: [0.253125, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.1125, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.0671875, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.05625, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.425], output: [0.0219499781965924]},
    {input: [0.0328125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.05], output: [0.0219499781965924]},
    {input: [0.0953125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.380555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.75], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.816666666666667], output: [-0.000690068604412431]},
    {input: [0.2125, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.175], output: [0.0219499781965924]},
    {input: [0.140625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.15], output: [0.0219499781965924]},
    {input: [0.1453125, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.125], output: [0.0445900249975972]},
    {input: [0.3375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.55625, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.452777777777778], output: [0.112510165400612]},
    {input: [0.3140625, 0.277777777777778], output: [0.135150212201616]},
    {input: [0.6796875, 0.288888888888889], output: [0.0898701185996068]},
    {input: [0.6578125, 0.311111111111111], output: [0.112510165400612]},
    {input: [0.5390625, 0.325], output: [0.157790259002621]},
    {input: [0.5671875, 0.397222222222222], output: [0.157790259002621]},
    {input: [0.534375, 0.422222222222222], output: [0.135150212201616]},
    {input: [0.7140625, 0.75], output: [0.157790259002621]},
    {input: [0.6125, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.528125, 0.769444444444444], output: [0.135150212201616]},
    {input: [0.4375, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.4546875, 0.75], output: [0.135150212201616]},
    {input: [0.3765625, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.325, 0.772222222222222], output: [0.135150212201616]},
    {input: [0.2859375, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.3375, 0.775], output: [0.0898701185996068]},
    {input: [0.3703125, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.421875, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.4609375, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.33125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.303125, 0.7], output: [0.067230071798602]},
    {input: [0.3421875, 0.730555555555556], output: [0.067230071798602]},
    {input: [0.2359375, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.1515625, 0.0333333333333333], output: [0.0445900249975972]},
    {input: [0.084375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.95], output: [0.0219499781965924]},
    {input: [0.2578125, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.175], output: [0.0219499781965924]},
    {input: [0.05625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.875], output: [0.0219499781965924]},
    {input: [0.1796875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.3375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.3484375, 0.825], output: [0.0445900249975972]},
    {input: [0.3875, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.359375, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.813888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.325, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.296875, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.833333333333333], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.8], output: [0.0219499781965924]},
    {input: [0.196875, 0.85], output: [0.0219499781965924]},
    {input: [0.225, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.825], output: [0.0219499781965924]},
    {input: [0.1453125, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.775], output: [0.0219499781965924]},
    {input: [0.071875, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.372222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.261111111111111], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.184375, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.196875, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.333333333333333], output: [-0.000690068604412431]},
    {input: [0.1625, 0.308333333333333], output: [-0.000690068604412431]},
    {input: [0.1125, 0.233333333333333], output: [-0.000690068604412431]},
    {input: [0.05625, 0.375], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.140625, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.366666666666667], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.536111111111111], output: [-0.000690068604412431]},
    {input: [0.140625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.275], output: [0.0219499781965924]},
    {input: [0.0609375, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.6], output: [0.0219499781965924]},
    {input: [0.071875, 0.752777777777778], output: [-0.000690068604412431]},
    {input: [0.084375, 0.680555555555556], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.594444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.922222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.797222222222222], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.25], output: [0.0219499781965924]},
    {input: [0.0953125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.128125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.275], output: [0.0219499781965924]},
    {input: [0.140625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.1], output: [0.0219499781965924]},
    {input: [0.2078125, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.409375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.359375, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.39375, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.275], output: [0.067230071798602]},
    {input: [0.325, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.3484375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.075], output: [0.0219499781965924]},
    {input: [0.2359375, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.45, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.4328125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.359375, 0.15], output: [0.067230071798602]},
    {input: [0.4890625, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.471875, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.521875, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.4890625, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.3765625, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.465625, 0.35], output: [0.135150212201616]},
    {input: [0.5734375, 0.436111111111111], output: [0.112510165400612]},
    {input: [0.44375, 0.383333333333333], output: [0.135150212201616]},
    {input: [0.534375, 0.433333333333333], output: [0.112510165400612]},
    {input: [0.45, 0.388888888888889], output: [0.112510165400612]},
    {input: [0.2296875, 0.158333333333333], output: [0.112510165400612]},
    {input: [0.2921875, 0.288888888888889], output: [0.0898701185996068]},
    {input: [0.1234375, 0.0277777777777778], output: [0.0898701185996068]},
    {input: [0.296875, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.253125, 0.438888888888889], output: [0.0898701185996068]},
    {input: [0.184375, 0.441666666666667], output: [0.067230071798602]},
    {input: [0.2125, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.1453125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.0609375, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.0953125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.8], output: [0.0219499781965924]},
    {input: [0.1171875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.95], output: [0.0445900249975972]},
    {input: [0.1, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.45], output: [0.0445900249975972]},
    {input: [0.084375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.2], output: [0.0445900249975972]},
    {input: [0.2578125, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.309375, 0.325], output: [0.0445900249975972]},
    {input: [0.3703125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.409375, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.2859375, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.1515625, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.1453125, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.359375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.3984375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.359375, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.85], output: [0.0445900249975972]},
    {input: [0.421875, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.2359375, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.15], output: [0.0445900249975972]},
    {input: [0.2015625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.303125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.409375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.5625, 0.258333333333333], output: [0.112510165400612]},
    {input: [0.5953125, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.534375, 0.111111111111111], output: [0.112510165400612]},
    {input: [0.4328125, 0.1], output: [0.112510165400612]},
    {input: [0.325, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.3703125, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.3421875, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.325, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.3765625, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.50625, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.4375, 0.144444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.33125, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.15], output: [0.067230071798602]},
    {input: [0.275, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.021875, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.275], output: [0.0445900249975972]},
    {input: [0.39375, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.465625, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.4609375, 0.288888888888889], output: [0.0898701185996068]},
    {input: [0.534375, 0.275], output: [0.112510165400612]},
    {input: [0.606875, 0.294444444444444], output: [0.218918385365334]},
    {input: [0.5390625, 0.294444444444444], output: [0.157790259002621]},
    {input: [0.5734375, 0.291666666666667], output: [0.157790259002621]},
    {input: [0.634375, 0.302777777777778], output: [0.180430305803626]},
    {input: [0.5671875, 0.291666666666667], output: [0.157790259002621]},
    {input: [0.534375, 0.322222222222222], output: [0.135150212201616]},
    {input: [0.5171875, 0.35], output: [0.135150212201616]},
    {input: [0.5625, 0.4], output: [0.135150212201616]},
    {input: [0.590625, 0.361111111111111], output: [0.135150212201616]},
    {input: [0.421875, 0.422222222222222], output: [0.135150212201616]},
    {input: [0.409375, 0.463888888888889], output: [0.112510165400612]},
    {input: [0.4828125, 0.522222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.513888888888889], output: [0.0898701185996068]},
    {input: [0.4890625, 0.541666666666667], output: [0.067230071798602]},
    {input: [0.33125, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.2078125, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.5], output: [0.0219499781965924]},
    {input: [0.44375, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.4609375, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.3203125, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.296875, 0.375], output: [0.0445900249975972]},
    {input: [0.3421875, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.4609375, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.235625, 0.369444444444444], output: [0.0989261373200087]},
    {input: [0.296875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.309375, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.38125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.50625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.5, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.471875, 0.161111111111111], output: [0.112510165400612]},
    {input: [0.5390625, 0.138888888888889], output: [0.112510165400612]},
    {input: [0.5953125, 0.147222222222222], output: [0.135150212201616]},
    {input: [0.703125, 0.141666666666667], output: [0.135150212201616]},
    {input: [0.6125, 0.144444444444444], output: [0.157790259002621]},
    {input: [0.5453125, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.5453125, 0.172222222222222], output: [0.157790259002621]},
    {input: [0.45, 0.261111111111111], output: [0.135150212201616]},
    {input: [0.471875, 0.316666666666667], output: [0.135150212201616]},
    {input: [0.4375, 0.316666666666667], output: [0.135150212201616]},
    {input: [0.4046875, 0.397222222222222], output: [0.135150212201616]},
    {input: [0.5171875, 0.438888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.391666666666667], output: [0.0898701185996068]},
    {input: [0.190625, 0.352777777777778], output: [0.0898701185996068]},
    {input: [0.28125, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.1796875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.196875, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.4375, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.3984375, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.7], output: [0.067230071798602]},
    {input: [0.225, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.1796875, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.078125, 0.65], output: [0.0445900249975972]},
    {input: [0.0390625, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.05, 0.7], output: [0.0219499781965924]},
    {input: [0.021875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.325], output: [0.0219499781965924]},
    {input: [0.184375, 0.3], output: [0.0219499781965924]},
    {input: [0.3375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.3484375, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.375], output: [0.0219499781965924]},
    {input: [0.38125, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.39375, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.465625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.4328125, 0.25], output: [0.067230071798602]},
    {input: [0.4609375, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.5109375, 0.275], output: [0.067230071798602]},
    {input: [0.5171875, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.675, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.6578125, 0.3], output: [0.157790259002621]},
    {input: [0.7140625, 0.294444444444444], output: [0.157790259002621]},
    {input: [0.690625, 0.316666666666667], output: [0.180430305803626]},
    {input: [0.61875, 0.336111111111111], output: [0.225710399405636]},
    {input: [0.6796875, 0.333333333333333], output: [0.203070352604631]},
    {input: [0.5734375, 0.330555555555556], output: [0.225710399405636]},
    {input: [0.5171875, 0.383333333333333], output: [0.180430305803626]},
    {input: [0.5953125, 0.425], output: [0.180430305803626]},
    {input: [0.4890625, 0.655555555555556], output: [0.157790259002621]},
    {input: [0.55625, 0.713888888888889], output: [0.135150212201616]},
    {input: [0.5671875, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.409375, 0.722222222222222], output: [0.157790259002621]},
    {input: [0.3984375, 0.747222222222222], output: [0.135150212201616]},
    {input: [0.2921875, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.1625, 0.536111111111111], output: [0.0898701185996068]},
    {input: [0.2296875, 0.447222222222222], output: [0.067230071798602]},
    {input: [0.2015625, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.430555555555556], output: [0.0445900249975972]},
    {input: [0.39375, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.4], output: [0.0219499781965924]},
    {input: [0.3875, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.39375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.4328125, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.5, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.50625, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.230555555555556], output: [0.135150212201616]},
    {input: [0.365625, 0.225], output: [0.135150212201616]},
    {input: [0.28125, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.4046875, 0.313888888888889], output: [0.112510165400612]},
    {input: [0.5953125, 0.366666666666667], output: [0.135150212201616]},
    {input: [0.478125, 0.363888888888889], output: [0.135150212201616]},
    {input: [0.353125, 0.394444444444444], output: [0.112510165400612]},
    {input: [0.471875, 0.558333333333333], output: [0.112510165400612]},
    {input: [0.590625, 0.586111111111111], output: [0.0898701185996068]},
    {input: [0.5109375, 0.586111111111111], output: [0.0898701185996068]},
    {input: [0.415625, 0.613888888888889], output: [0.0898701185996068]},
    {input: [0.33125, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.275, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.38125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.359375, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.3765625, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.725], output: [0.0219499781965924]},
    {input: [0.128125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.025], output: [0.0219499781965924]},
    {input: [0.071875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.421875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.465625, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.578125, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.534375, 0.769444444444444], output: [0.112510165400612]},
    {input: [0.55625, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.5953125, 0.747222222222222], output: [0.135150212201616]},
    {input: [0.61875, 0.75], output: [0.112510165400612]},
    {input: [0.60625, 0.738888888888889], output: [0.135150212201616]},
    {input: [0.675, 0.8], output: [0.157790259002621]},
    {input: [0.7421875, 0.816666666666667], output: [0.135150212201616]},
    {input: [0.753125, 0.816666666666667], output: [0.135150212201616]},
    {input: [0.7640625, 0.813888888888889], output: [0.180430305803626]},
    {input: [0.7984375, 0.802777777777778], output: [0.203070352604631]},
    {input: [0.8203125, 0.794444444444444], output: [0.203070352604631]},
    {input: [0.6125, 0.747222222222222], output: [0.203070352604631]},
    {input: [0.6859375, 0.780555555555556], output: [0.180430305803626]},
    {input: [0.69125, 0.780555555555556], output: [0.327590610010157]},
    {input: [0.759375, 0.791666666666667], output: [0.225710399405636]},
    {input: [0.7359375, 0.791666666666667], output: [0.203070352604631]},
    {input: [0.4046875, 0.727777777777778], output: [0.180430305803626]},
    {input: [0.521875, 0.783333333333333], output: [0.157790259002621]},
    {input: [0.49375, 0.805555555555556], output: [0.157790259002621]},
    {input: [0.39375, 0.822222222222222], output: [0.135150212201616]},
    {input: [0.134375, 0.0583333333333333], output: [0.112510165400612]},
    {input: [0.16875, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.134375, 0.05], output: [0.0445900249975972]},
    {input: [0.1, 0.125], output: [0.0445900249975972]},
    {input: [0.1, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.3484375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.3484375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.125], output: [0.0445900249975972]},
    {input: [0.303125, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.1796875, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.196875, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.1453125, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.1234375, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.0953125, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.028125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.0109375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.125], output: [0.0219499781965924]},
    {input: [0.1796875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.225], output: [0.0219499781965924]},
    {input: [0.140625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.225], output: [0.0219499781965924]},
    {input: [0.15625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.1625, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.4265625, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.3765625, 0.125], output: [0.0445900249975972]},
    {input: [0.26875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.1515625, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.05625, 0.516666666666667], output: [0.067230071798602]},
    {input: [0.0609375, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.04375, 0.988888888888889], output: [0.0898701185996068]},
    {input: [0.0609375, 0.0416666666666667], output: [0.067230071798602]},
    {input: [0.078125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.125], output: [0.0445900249975972]},
    {input: [0.33125, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.44375, 0.15], output: [0.0445900249975972]},
    {input: [0.528125, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.44375, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.478125, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.3703125, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.5, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.44375, 0.347222222222222], output: [0.112510165400612]},
    {input: [0.465625, 0.402777777777778], output: [0.135150212201616]},
    {input: [0.590625, 0.394444444444444], output: [0.135150212201616]},
    {input: [0.5390625, 0.4], output: [0.135150212201616]},
    {input: [0.421875, 0.436111111111111], output: [0.112510165400612]},
    {input: [0.39375, 0.380555555555556], output: [0.112510165400612]},
    {input: [0.3765625, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.4375, 0.513888888888889], output: [0.0898701185996068]},
    {input: [0.303125, 0.463888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.196875, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.3703125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.3875, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.415625, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.4546875, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.4265625, 0.194444444444444], output: [0.0898701185996068]},
    {input: [0.3484375, 0.194444444444444], output: [0.112510165400612]},
    {input: [0.2296875, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.125], output: [0.067230071798602]},
    {input: [0.38125, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.38125, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.125], output: [0.067230071798602]},
    {input: [0.415625, 0.15], output: [0.0898701185996068]},
    {input: [0.353125, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.3140625, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.28125, 0.0972222222222222], output: [0.067230071798602]},
    {input: [0.275, 0.025], output: [0.067230071798602]},
    {input: [0.1234375, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.0953125, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.825], output: [0.0219499781965924]},
    {input: [0.190625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.825], output: [0.0219499781965924]},
    {input: [0.184375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.28125, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.775], output: [0.0445900249975972]},
    {input: [0.303125, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.3984375, 0.822222222222222], output: [0.067230071798602]},
    {input: [0.4828125, 0.825], output: [0.0898701185996068]},
    {input: [0.5390625, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.3765625, 0.822222222222222], output: [0.0898701185996068]},
    {input: [0.3703125, 0.833333333333333], output: [0.0898701185996068]},
    {input: [0.2359375, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.2640625, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.75], output: [0.0219499781965924]},
    {input: [0.1734375, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.409375, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.275, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.125], output: [0.0445900249975972]},
    {input: [0.246875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.359375, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.4328125, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.4328125, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.415625, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.421875, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.125], output: [0.0898701185996068]},
    {input: [0.5171875, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.534375, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.5390625, 0.133333333333333], output: [0.135150212201616]},
    {input: [0.55625, 0.122222222222222], output: [0.157790259002621]},
    {input: [0.6125, 0.136111111111111], output: [0.135150212201616]},
    {input: [0.5390625, 0.144444444444444], output: [0.135150212201616]},
    {input: [0.528125, 0.161111111111111], output: [0.135150212201616]},
    {input: [0.55, 0.252777777777778], output: [0.157790259002621]},
    {input: [0.6296875, 0.286111111111111], output: [0.135150212201616]},
    {input: [0.534375, 0.252777777777778], output: [0.157790259002621]},
    {input: [0.634375, 0.288888888888889], output: [0.180430305803626]},
    {input: [0.6515625, 0.261111111111111], output: [0.180430305803626]},
    {input: [0.5953125, 0.286111111111111], output: [0.180430305803626]},
    {input: [0.6578125, 0.325], output: [0.157790259002621]},
    {input: [0.6296875, 0.330555555555556], output: [0.180430305803626]},
    {input: [0.55625, 0.302777777777778], output: [0.180430305803626]},
    {input: [0.5625, 0.277777777777778], output: [0.203070352604631]},
    {input: [0.6515625, 0.319444444444444], output: [0.203070352604631]},
    {input: [0.6296875, 0.327777777777778], output: [0.225710399405636]},
    {input: [0.528125, 0.336111111111111], output: [0.203070352604631]},
    {input: [0.421875, 0.347222222222222], output: [0.157790259002621]},
    {input: [0.5453125, 0.280555555555556], output: [0.135150212201616]},
    {input: [0.55625, 0.277777777777778], output: [0.135150212201616]},
    {input: [0.534375, 0.291666666666667], output: [0.157790259002621]},
    {input: [0.50625, 0.294444444444444], output: [0.135150212201616]},
    {input: [0.3703125, 0.219444444444444], output: [0.135150212201616]},
    {input: [0.49375, 0.136111111111111], output: [0.112510165400612]},
    {input: [0.5453125, 0.127777777777778], output: [0.135150212201616]},
    {input: [0.4609375, 0.102777777777778], output: [0.112510165400612]},
    {input: [0.39375, 0.0416666666666667], output: [0.112510165400612]},
    {input: [0.253125, 0.0111111111111111], output: [0.0898701185996068]},
    {input: [0.15625, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.1796875, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.1515625, 0.7], output: [0.0445900249975972]},
    {input: [0.0953125, 0.75], output: [0.0445900249975972]},
    {input: [0.1453125, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.325, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.15], output: [0.0445900249975972]},
    {input: [0.3875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.4828125, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.5453125, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.528125, 0.147222222222222], output: [0.112510165400612]},
    {input: [0.534375, 0.166666666666667], output: [0.112510165400612]},
    {input: [0.6125, 0.191666666666667], output: [0.112510165400612]},
    {input: [0.5625, 0.191666666666667], output: [0.135150212201616]},
    {input: [0.5390625, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.5171875, 0.238888888888889], output: [0.112510165400612]},
    {input: [0.5625, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.4828125, 0.280555555555556], output: [0.135150212201616]},
    {input: [0.359375, 0.288888888888889], output: [0.135150212201616]},
    {input: [0.365625, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.4609375, 0.297222222222222], output: [0.112510165400612]},
    {input: [0.5109375, 0.475], output: [0.0898701185996068]},
    {input: [0.3484375, 0.488888888888889], output: [0.0898701185996068]},
    {input: [0.28125, 0.444444444444444], output: [0.0898701185996068]},
    {input: [0.1796875, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.33125, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.4375, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.5, 0.375], output: [0.0445900249975972]},
    {input: [0.465625, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.38125, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.5109375, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.5109375, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.55625, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.528125, 0.311111111111111], output: [0.112510165400612]},
    {input: [0.534375, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.6234375, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.5390625, 0.308333333333333], output: [0.112510165400612]},
    {input: [0.5390625, 0.275], output: [0.112510165400612]},
    {input: [0.55, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.5625, 0.280555555555556], output: [0.112510165400612]},
    {input: [0.61875, 0.308333333333333], output: [0.112510165400612]},
    {input: [0.646875, 0.355555555555556], output: [0.157790259002621]},
    {input: [0.6625, 0.352777777777778], output: [0.157790259002621]},
    {input: [0.6625, 0.419444444444444], output: [0.157790259002621]},
    {input: [0.4828125, 0.422222222222222], output: [0.180430305803626]},
    {input: [0.4546875, 0.572222222222222], output: [0.112510165400612]},
    {input: [0.4375, 0.541666666666667], output: [0.0898701185996068]},
    {input: [0.471875, 0.494444444444444], output: [0.0898701185996068]},
    {input: [0.3421875, 0.488888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.5], output: [0.0445900249975972]},
    {input: [0.246875, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.359375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.4609375, 0.425], output: [0.0445900249975972]},
    {input: [0.6125, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.55625, 0.333333333333333], output: [0.0898701185996068]},
    {input: [0.44375, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.369444444444444], output: [0.0898701185996068]},
    {input: [0.353125, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.3984375, 0.405555555555556], output: [0.0898701185996068]},
    {input: [0.3984375, 0.419444444444444], output: [0.0898701185996068]},
    {input: [0.4328125, 0.430555555555556], output: [0.0898701185996068]},
    {input: [0.421875, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.359375, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.4375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.471875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.172222222222222], output: [0.112510165400612]},
    {input: [0.4265625, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.5671875, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.634375, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.6625, 0.125], output: [0.135150212201616]},
    {input: [0.640625, 0.152777777777778], output: [0.135150212201616]},
    {input: [0.5734375, 0.15], output: [0.135150212201616]},
    {input: [0.6515625, 0.144444444444444], output: [0.157790259002621]},
    {input: [0.578125, 0.15], output: [0.180430305803626]},
    {input: [0.3140625, 0.25], output: [0.157790259002621]},
    {input: [0.3421875, 0.119444444444444], output: [0.135150212201616]},
    {input: [0.2859375, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.2078125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.1171875, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.425], output: [0.0219499781965924]},
    {input: [0.0953125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.309375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.365625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.465625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.3984375, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.5171875, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.5671875, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.6296875, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.6515625, 0.280555555555556], output: [0.135150212201616]},
    {input: [0.6515625, 0.294444444444444], output: [0.157790259002621]},
    {input: [0.753125, 0.291666666666667], output: [0.180430305803626]},
    {input: [0.746875, 0.291666666666667], output: [0.225710399405636]},
    {input: [0.759375, 0.266666666666667], output: [0.270990493007645]},
    {input: [0.6296875, 0.288888888888889], output: [0.29363053980865]},
    {input: [0.696875, 0.330555555555556], output: [0.29363053980865]},
    {input: [0.60625, 0.336111111111111], output: [0.225710399405636]},
    {input: [0.5, 0.355555555555556], output: [0.225710399405636]},
    {input: [0.521875, 0.358333333333333], output: [0.203070352604631]},
    {input: [0.4375, 0.302777777777778], output: [0.203070352604631]},
    {input: [0.5, 0.316666666666667], output: [0.225710399405636]},
    {input: [0.478125, 0.25], output: [0.225710399405636]},
    {input: [0.4609375, 0.294444444444444], output: [0.157790259002621]},
    {input: [0.415625, 0.463888888888889], output: [0.135150212201616]},
    {input: [0.3375, 0.275], output: [0.112510165400612]},
    {input: [0.353125, 0.325], output: [0.112510165400612]},
    {input: [0.3875, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.377777777777778], output: [0.112510165400612]},
    {input: [0.50625, 0.358333333333333], output: [0.0898701185996068]},
    {input: [0.478125, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.45, 0.430555555555556], output: [0.0898701185996068]},
    {input: [0.415625, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.325, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.125], output: [0.0219499781965924]},
    {input: [0.05625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.15], output: [0.0219499781965924]},
    {input: [0.415625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.4265625, 0.2], output: [0.0445900249975972]},
    {input: [0.2859375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.1625, 0.175], output: [0.067230071798602]},
    {input: [0.196875, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.225], output: [0.0445900249975972]},
    {input: [0.0953125, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.25], output: [0.067230071798602]},
    {input: [0.3984375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.471875, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4328125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.465625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.5, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.3765625, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.4828125, 0.158333333333333], output: [0.0898701185996068]},
    {input: [0.6015625, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.2859375, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.1625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.1796875, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.0416666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.0953125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.04375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.05], output: [-0.000690068604412431]},
    {input: [0.184375, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.1], output: [-0.000690068604412431]},
    {input: [0.15625, 0.15], output: [0.0219499781965924]},
    {input: [0.1796875, 0.075], output: [0.0219499781965924]},
    {input: [0.2359375, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.2], output: [0.0219499781965924]},
    {input: [0.196875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.15625, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.16875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.0390625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.3703125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.415625, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.2296875, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.3875, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.45, 0.116666666666667], output: [0.0898701185996068]},
    {input: [0.4609375, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.415625, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.4609375, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.4609375, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.4046875, 0.161111111111111], output: [0.0898701185996068]},
    {input: [0.39375, 0.188888888888889], output: [0.112510165400612]},
    {input: [0.3703125, 0.183333333333333], output: [0.112510165400612]},
    {input: [0.3765625, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.33125, 0.183333333333333], output: [0.0898701185996068]},
    {input: [0.2578125, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.196875, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.1625, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.3765625, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.240625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.25], output: [0.0445900249975972]},
    {input: [0.325, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.44375, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.3421875, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.3375, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.309375, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.16875, 0.405555555555556], output: [0.0898701185996068]},
    {input: [0.478125, 0.333333333333333], output: [0.0898701185996068]},
    {input: [0.38125, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.45, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.50625, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.3375, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.365625, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.2859375, 0.177777777777778], output: [0.0898701185996068]},
    {input: [0.3984375, 0.205555555555556], output: [0.112510165400612]},
    {input: [0.3203125, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.465625, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.4890625, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.60625, 0.136111111111111], output: [0.135150212201616]},
    {input: [0.590625, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.61875, 0.133333333333333], output: [0.135150212201616]},
    {input: [0.534375, 0.147222222222222], output: [0.135150212201616]},
    {input: [0.55625, 0.138888888888889], output: [0.135150212201616]},
    {input: [0.528125, 0.161111111111111], output: [0.135150212201616]},
    {input: [0.44375, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.258333333333333], output: [0.135150212201616]},
    {input: [0.478125, 0.280555555555556], output: [0.157790259002621]},
    {input: [0.455, 0.319444444444444], output: [0.20080634792453]},
    {input: [0.45, 0.302777777777778], output: [0.135150212201616]},
    {input: [0.478125, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.409375, 0.338888888888889], output: [0.112510165400612]},
    {input: [0.415625, 0.405555555555556], output: [0.112510165400612]},
    {input: [0.4546875, 0.397222222222222], output: [0.0898701185996068]},
    {input: [0.4546875, 0.433333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.35], output: [0.067230071798602]},
    {input: [0.4828125, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.35], output: [0.067230071798602]},
    {input: [0.359375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.4609375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3421875, 0.2], output: [0.067230071798602]},
    {input: [0.5109375, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.49375, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.5390625, 0.338888888888889], output: [0.135150212201616]},
    {input: [0.646875, 0.408333333333333], output: [0.135150212201616]},
    {input: [0.690625, 0.669444444444444], output: [0.157790259002621]},
    {input: [0.66875, 0.652777777777778], output: [0.135150212201616]},
    {input: [0.5625, 0.630555555555556], output: [0.135150212201616]},
    {input: [0.6515625, 0.605555555555556], output: [0.112510165400612]},
    {input: [0.521875, 0.627777777777778], output: [0.112510165400612]},
    {input: [0.39375, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.3421875, 0.647222222222222], output: [0.0898701185996068]},
    {input: [0.196875, 0.677777777777778], output: [0.0898701185996068]},
    {input: [0.415625, 0.594444444444444], output: [0.0898701185996068]},
    {input: [0.28125, 0.6], output: [0.0898701185996068]},
    {input: [0.2640625, 0.594444444444444], output: [0.0898701185996068]},
    {input: [0.084375, 0.497222222222222], output: [0.0898701185996068]},
    {input: [0.1515625, 0.997222222222222], output: [0.0898701185996068]},
    {input: [0.0890625, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.084375, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.0953125, 0.375], output: [0.0445900249975972]},
    {input: [0.1171875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.175], output: [0.0219499781965924]},
    {input: [0.140625, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.1], output: [0.0219499781965924]},
    {input: [0.296875, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.39375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.38125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.084375, 0.075], output: [0.0445900249975972]},
    {input: [0.05625, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.0328125, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.084375, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.025], output: [0.0219499781965924]},
    {input: [0.128125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.4375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.3984375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.3765625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.075], output: [0.067230071798602]},
    {input: [0.296875, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.2921875, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.1796875, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.0953125, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.0953125, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.0671875, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.675], output: [0.0445900249975972]},
    {input: [0.05625, 0.7], output: [0.0219499781965924]},
    {input: [0.0109375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.075], output: [0.0219499781965924]},
    {input: [0.04375, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.65], output: [0.0219499781965924]},
    {input: [0.05625, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.19625, 0.0944444444444444], output: [0.0196859735164919]},
    {input: [0.1234375, 0.0944444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.00277777777777778], output: [-0.000690068604412431]},
    {input: [0.140625, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.021875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.2125, 0.0777777777777778], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.516666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.575], output: [0.0219499781965924]},
    {input: [0.05, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.175], output: [0.0219499781965924]},
    {input: [0.0671875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.166666666666667], output: [-0.000690068604412431]},
    {input: [0.184375, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.205555555555556], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.2], output: [0.0219499781965924]},
    {input: [0.275, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.15], output: [0.0219499781965924]},
    {input: [0.128125, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.675], output: [0.0219499781965924]},
    {input: [0.1515625, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.05625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.7], output: [0.0445900249975972]},
    {input: [0.240625, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.05, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.2125, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.947222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.975], output: [0.0219499781965924]},
    {input: [0.1515625, 0.997222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.9], output: [0.0445900249975972]},
    {input: [0.1, 0.975], output: [0.0219499781965924]},
    {input: [0.084375, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.675], output: [0.0219499781965924]},
    {input: [0.1, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.85], output: [0.0219499781965924]},
    {input: [0.184375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.775], output: [0.0219499781965924]},
    {input: [0.1734375, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.08375, 0.125], output: [0.0128939594761905]},
    {input: [0.128125, 0.233333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.311111111111111], output: [-0.000690068604412431]},
    {input: [0.190625, 0.3], output: [0.0219499781965924]},
    {input: [0.26875, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.309375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.359375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.875], output: [0.0445900249975972]},
    {input: [0.253125, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.875], output: [0.0219499781965924]},
    {input: [0.1796875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.365625, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.39375, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.4265625, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.478125, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.471875, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.49375, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.45, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.465625, 0.825], output: [0.0898701185996068]},
    {input: [0.44375, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.4328125, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.421875, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.3875, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.875], output: [0.067230071798602]},
    {input: [0.309375, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.997222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.3875, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.353125, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.45, 0.822222222222222], output: [0.067230071798602]},
    {input: [0.478125, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.5109375, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.833333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.819444444444444], output: [0.112510165400612]},
    {input: [0.4375, 0.816666666666667], output: [0.112510165400612]},
    {input: [0.4328125, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.3765625, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.2921875, 0.833333333333333], output: [0.0898701185996068]},
    {input: [0.275, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.2296875, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.85], output: [0.0445900249975972]},
    {input: [0.2296875, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.478125, 0.122222222222222], output: [0.112510165400612]},
    {input: [0.471875, 0.119444444444444], output: [0.0898701185996068]},
    {input: [0.4546875, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.465625, 0.119444444444444], output: [0.0898701185996068]},
    {input: [0.415625, 0.116666666666667], output: [0.0898701185996068]},
    {input: [0.415625, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.415625, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.1796875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.6], output: [0.0445900249975972]},
    {input: [0.0890625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.28125, 0.5], output: [0.067230071798602]},
    {input: [0.1625, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.071875, 0.625], output: [0.0445900249975972]},
    {input: [0.0953125, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.084375, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.4328125, 0.2], output: [0.0445900249975972]},
    {input: [0.296875, 0.347222222222222], output: [0.0898701185996068]},
    {input: [0.365625, 0.35], output: [0.0898701185996068]},
    {input: [0.4890625, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.5734375, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.5, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.521875, 0.266666666666667], output: [0.112510165400612]},
    {input: [0.6125, 0.258333333333333], output: [0.184958315163827]},
    {input: [0.6125, 0.280555555555556], output: [0.135150212201616]},
    {input: [0.590625, 0.183333333333333], output: [0.157790259002621]},
    {input: [0.55, 0.147222222222222], output: [0.157790259002621]},
    {input: [0.5734375, 0.144444444444444], output: [0.135150212201616]},
    {input: [0.5171875, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.471875, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.55625, 0.166666666666667], output: [0.135150212201616]},
    {input: [0.5953125, 0.175], output: [0.135150212201616]},
    {input: [0.6015625, 0.147222222222222], output: [0.135150212201616]},
    {input: [0.590625, 0.136111111111111], output: [0.135150212201616]},
    {input: [0.6015625, 0.133333333333333], output: [0.157790259002621]},
    {input: [0.584375, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.5671875, 0.133333333333333], output: [0.135150212201616]},
    {input: [0.584375, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.646875, 0.2], output: [0.135150212201616]},
    {input: [0.534375, 0.158333333333333], output: [0.157790259002621]},
    {input: [0.5625, 0.141666666666667], output: [0.157790259002621]},
    {input: [0.4546875, 0.0805555555555556], output: [0.157790259002621]},
    {input: [0.421875, 0.075], output: [0.135150212201616]},
    {input: [0.246875, 0.0388888888888889], output: [0.135150212201616]},
    {input: [0.2921875, 0.00833333333333333], output: [0.112510165400612]},
    {input: [0.2921875, 0.05], output: [0.067230071798602]},
    {input: [0.2859375, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.4609375, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.4375, 0.0805555555555556], output: [0.0898701185996068]},
    {input: [0.4609375, 0.111111111111111], output: [0.112510165400612]},
    {input: [0.49375, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.50625, 0.125], output: [0.112510165400612]},
    {input: [0.5, 0.125], output: [0.112510165400612]},
    {input: [0.5, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.409375, 0.136111111111111], output: [0.112510165400612]},
    {input: [0.3421875, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.303125, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.2578125, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.1234375, 0.0388888888888889], output: [0.067230071798602]},
    {input: [0.2015625, 0.0333333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.303125, 0.1], output: [0.067230071798602]},
    {input: [0.365625, 0.125], output: [0.067230071798602]},
    {input: [0.1515625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.0953125, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.1171875, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.3375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.2578125, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.825], output: [0.0219499781965924]},
    {input: [0.26875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.8], output: [0.0445900249975972]},
    {input: [0.190625, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.494444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.225], output: [0.0445900249975972]},
    {input: [0.3203125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.3484375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.3765625, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.3875, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.4328125, 0.275], output: [0.067230071798602]},
    {input: [0.415625, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.471875, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.5, 0.280555555555556], output: [0.135150212201616]},
    {input: [0.60625, 0.238888888888889], output: [0.180430305803626]},
    {input: [0.60625, 0.247222222222222], output: [0.157790259002621]},
    {input: [0.49375, 0.208333333333333], output: [0.157790259002621]},
    {input: [0.646875, 0.291666666666667], output: [0.135150212201616]},
    {input: [0.584375, 0.291666666666667], output: [0.157790259002621]},
    {input: [0.4375, 0.205555555555556], output: [0.135150212201616]},
    {input: [0.4375, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.421875, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.39375, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.471875, 0.119444444444444], output: [0.0898701185996068]},
    {input: [0.3484375, 0.275], output: [0.0898701185996068]},
    {input: [0.2015625, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.246875, 0.225], output: [0.067230071798602]},
    {input: [0.4890625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.4265625, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.325, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.471875, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.471875, 0.125], output: [0.0898701185996068]},
    {input: [0.5625, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.5671875, 0.2], output: [0.112510165400612]},
    {input: [0.50625, 0.175], output: [0.135150212201616]},
    {input: [0.55, 0.197222222222222], output: [0.157790259002621]},
    {input: [0.4375, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.3984375, 0.136111111111111], output: [0.157790259002621]},
    {input: [0.421875, 0.15], output: [0.112510165400612]},
    {input: [0.2859375, 0.2], output: [0.0445900249975972]},
    {input: [0.359375, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.125], output: [0.0445900249975972]},
    {input: [0.365625, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.4046875, 0.125], output: [0.0445900249975972]},
    {input: [0.4890625, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.4328125, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.359375, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.353125, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.175], output: [0.0445900249975972]},
    {input: [0.3203125, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.3203125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.3375, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.125], output: [0.067230071798602]},
    {input: [0.359375, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.3375, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.28125, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.309375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.0609375, 0.325], output: [0.067230071798602]},
    {input: [0.16875, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.39375, 0.275], output: [0.067230071798602]},
    {input: [0.4375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.534375, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.590625, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.534375, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.5109375, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.3765625, 0.119444444444444], output: [0.135150212201616]},
    {input: [0.415625, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.275, 0.0916666666666667], output: [0.0898701185996068]},
    {input: [0.2359375, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.2359375, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.2125, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.353125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.4828125, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.941666666666667], output: [0.112510165400612]},
    {input: [0.3203125, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.2640625, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.1515625, 0.875], output: [0.067230071798602]},
    {input: [0.225, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.196875, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.0390625, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.0328125, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.0953125, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.444444444444444], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.95], output: [0.0219499781965924]},
    {input: [0.028125, 0.675], output: [-0.000690068604412431]},
    {input: [0.084375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.075], output: [0.0219499781965924]},
    {input: [0.0890625, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.134375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.133333333333333], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.325], output: [-0.000690068604412431]},
    {input: [0.409375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.33125, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.078125, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.1, 0.075], output: [0.0445900249975972]},
    {input: [0.240625, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.140625, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.1625, 0.125], output: [0.067230071798602]},
    {input: [0.1734375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.128125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.3703125, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.4328125, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.49375, 0.75], output: [0.0219499781965924]},
    {input: [0.415625, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.6296875, 0.763888888888889], output: [0.112510165400612]},
    {input: [0.6296875, 0.772222222222222], output: [0.157790259002621]},
    {input: [0.578125, 0.788888888888889], output: [0.203070352604631]},
    {input: [0.6015625, 0.780555555555556], output: [0.180430305803626]},
    {input: [0.465625, 0.769444444444444], output: [0.180430305803626]},
    {input: [0.4046875, 0.805555555555556], output: [0.135150212201616]},
    {input: [0.359375, 0.827777777777778], output: [0.112510165400612]},
    {input: [0.3203125, 0.861111111111111], output: [0.112510165400612]},
    {input: [0.1515625, 0.863888888888889], output: [0.0898701185996068]},
    {input: [0.1125, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.1234375, 0.225], output: [0.0445900249975972]},
    {input: [0.084375, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.05, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.3], output: [0.0219499781965924]},
    {input: [0.071875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.409375, 0.525], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.409375, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.8], output: [0.0445900249975972]},
    {input: [0.4609375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.2640625, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.590625, 0.75], output: [0.112510165400612]},
    {input: [0.5109375, 0.755555555555556], output: [0.135150212201616]},
    {input: [0.5953125, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.6234375, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.6625, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.55625, 0.727777777777778], output: [0.180430305803626]},
    {input: [0.6796875, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.578125, 0.727777777777778], output: [0.157790259002621]},
    {input: [0.634375, 0.733333333333333], output: [0.157790259002621]},
    {input: [0.6015625, 0.733333333333333], output: [0.157790259002621]},
    {input: [0.521875, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.4375, 0.725], output: [0.157790259002621]},
    {input: [0.4375, 0.719444444444444], output: [0.180430305803626]},
    {input: [0.3203125, 0.705555555555556], output: [0.135150212201616]},
    {input: [0.303125, 0.661111111111111], output: [0.112510165400612]},
    {input: [0.2640625, 0.561111111111111], output: [0.157790259002621]},
    {input: [0.2125, 0.655555555555556], output: [0.157790259002621]},
    {input: [0.3421875, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.325, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.353125, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.2859375, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.2921875, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.409375, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.2859375, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.666666666666667], output: [0.067230071798602]},
    {input: [0.3765625, 0.697222222222222], output: [0.067230071798602]},
    {input: [0.3703125, 0.7], output: [0.0898701185996068]},
    {input: [0.45, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.5, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.4828125, 0.713888888888889], output: [0.135150212201616]},
    {input: [0.50625, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.4890625, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.45, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.719444444444444], output: [0.112510165400612]},
    {input: [0.409375, 0.694444444444444], output: [0.112510165400612]},
    {input: [0.3875, 0.719444444444444], output: [0.112510165400612]},
    {input: [0.3375, 0.708333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.359375, 0.736111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.1796875, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.0333333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.4046875, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.4546875, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.44375, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.38125, 0.15], output: [0.0898701185996068]},
    {input: [0.415625, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.155555555555556], output: [0.112510165400612]},
    {input: [0.3984375, 0.155555555555556], output: [0.112510165400612]},
    {input: [0.325, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.240625, 0.172222222222222], output: [0.112510165400612]},
    {input: [0.240625, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.275, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.28125, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.21875, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.2296875, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.1796875, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.10625, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.078125, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.0671875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.275], output: [0.0445900249975972]},
    {input: [0.1, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.325], output: [0.0219499781965924]},
    {input: [0.2015625, 0.3], output: [0.0219499781965924]},
    {input: [0.196875, 0.325], output: [0.0219499781965924]},
    {input: [0.2125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.3140625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.409375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.161111111111111], output: [0.0898701185996068]},
    {input: [0.578125, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.5453125, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.471875, 0.175], output: [0.135150212201616]},
    {input: [0.465625, 0.175], output: [0.157790259002621]},
    {input: [0.4609375, 0.180555555555556], output: [0.135150212201616]},
    {input: [0.4265625, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.3875, 0.172222222222222], output: [0.112510165400612]},
    {input: [0.4328125, 0.163888888888889], output: [0.112510165400612]},
    {input: [0.4546875, 0.169444444444444], output: [0.112510165400612]},
    {input: [0.421875, 0.166666666666667], output: [0.135150212201616]},
    {input: [0.421875, 0.166666666666667], output: [0.135150212201616]},
    {input: [0.50625, 0.158333333333333], output: [0.135150212201616]},
    {input: [0.5, 0.147222222222222], output: [0.112510165400612]},
    {input: [0.5109375, 0.152777777777778], output: [0.135150212201616]},
    {input: [0.5171875, 0.138888888888889], output: [0.135150212201616]},
    {input: [0.534375, 0.152777777777778], output: [0.135150212201616]},
    {input: [0.5171875, 0.155555555555556], output: [0.135150212201616]},
    {input: [0.5, 0.15], output: [0.135150212201616]},
    {input: [0.5171875, 0.144444444444444], output: [0.135150212201616]},
    {input: [0.5453125, 0.144444444444444], output: [0.135150212201616]},
    {input: [0.5734375, 0.138888888888889], output: [0.135150212201616]},
    {input: [0.4890625, 0.144444444444444], output: [0.135150212201616]},
    {input: [0.4890625, 0.144444444444444], output: [0.135150212201616]},
    {input: [0.45, 0.130555555555556], output: [0.135150212201616]},
    {input: [0.33125, 0.111111111111111], output: [0.157790259002621]},
    {input: [0.1125, 0.0833333333333333], output: [0.135150212201616]},
    {input: [0.078125, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.1234375, 0.177777777777778], output: [0.112510165400612]},
    {input: [0.10625, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.138888888888889], output: [0.112510165400612]},
    {input: [0.5390625, 0.141666666666667], output: [0.135150212201616]},
    {input: [0.60625, 0.147222222222222], output: [0.135150212201616]},
    {input: [0.6015625, 0.163888888888889], output: [0.157790259002621]},
    {input: [0.6015625, 0.166666666666667], output: [0.135150212201616]},
    {input: [0.61875, 0.175], output: [0.135150212201616]},
    {input: [0.590625, 0.186111111111111], output: [0.180430305803626]},
    {input: [0.55625, 0.205555555555556], output: [0.157790259002621]},
    {input: [0.6015625, 0.152777777777778], output: [0.180430305803626]},
    {input: [0.634375, 0.172222222222222], output: [0.157790259002621]},
    {input: [0.584375, 0.169444444444444], output: [0.203070352604631]},
    {input: [0.6015625, 0.161111111111111], output: [0.180430305803626]},
    {input: [0.5453125, 0.147222222222222], output: [0.180430305803626]},
    {input: [0.4609375, 0.130555555555556], output: [0.180430305803626]},
    {input: [0.471875, 0.133333333333333], output: [0.157790259002621]},
    {input: [0.3984375, 0.116666666666667], output: [0.135150212201616]},
    {input: [0.39375, 0.141666666666667], output: [0.112510165400612]},
    {input: [0.4609375, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.44375, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.465625, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.15], output: [0.0898701185996068]},
    {input: [0.325, 0.175], output: [0.067230071798602]},
    {input: [0.309375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.175], output: [0.0445900249975972]},
    {input: [0.253125, 0.2], output: [0.0445900249975972]},
    {input: [0.3140625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.3375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.4046875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.3984375, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.39375, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.28125, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.2640625, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.365625, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.303125, 0.175], output: [0.067230071798602]},
    {input: [0.33125, 0.175], output: [0.067230071798602]},
    {input: [0.303125, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.2921875, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.409375, 0.127777777777778], output: [0.0898701185996068]},
    {input: [0.359375, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.3421875, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.26875, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.3203125, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.45, 0.158333333333333], output: [0.0898701185996068]},
    {input: [0.4375, 0.15], output: [0.0898701185996068]},
    {input: [0.409375, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.3765625, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.4265625, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.3484375, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.2078125, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.1453125, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.2359375, 0.641666666666667], output: [0.112510165400612]},
    {input: [0.253125, 0.613888888888889], output: [0.112510165400612]},
    {input: [0.10625, 0.516666666666667], output: [0.0898701185996068]},
    {input: [0.1625, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.04375, 0.825], output: [0.067230071798602]},
    {input: [0.0671875, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.078125, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.527777777777778], output: [0.0445900249975972]},
    {input: [0.0671875, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.625], output: [0.0219499781965924]},
    {input: [0.246875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.591666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.6], output: [0.0445900249975972]},
    {input: [0.190625, 0.583333333333333], output: [0.067230071798602]},
    {input: [0.134375, 0.575], output: [0.067230071798602]},
    {input: [0.071875, 0.622222222222222], output: [0.067230071798602]},
    {input: [0.1, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.7], output: [0.0219499781965924]},
    {input: [0.38125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.45, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.690625, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.6515625, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.775, 0.752777777777778], output: [0.180430305803626]},
    {input: [0.640625, 0.758333333333333], output: [0.225710399405636]},
    {input: [0.60625, 0.744444444444444], output: [0.225710399405636]},
    {input: [0.5734375, 0.730555555555556], output: [0.203070352604631]},
    {input: [0.50625, 0.733333333333333], output: [0.203070352604631]},
    {input: [0.45, 0.747222222222222], output: [0.157790259002621]},
    {input: [0.4609375, 0.736111111111111], output: [0.157790259002621]},
    {input: [0.415625, 0.725], output: [0.157790259002621]},
    {input: [0.409375, 0.741666666666667], output: [0.135150212201616]},
    {input: [0.4546875, 0.788888888888889], output: [0.112510165400612]},
    {input: [0.325, 0.830555555555556], output: [0.112510165400612]},
    {input: [0.196875, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.1, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.0890625, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.3484375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.353125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.3765625, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.409375, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.15], output: [0.0898701185996068]},
    {input: [0.3984375, 0.138888888888889], output: [0.112510165400612]},
    {input: [0.4328125, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.359375, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.409375, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.28125, 0.2], output: [0.0898701185996068]},
    {input: [0.3375, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.365625, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.50625, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.5734375, 0.111111111111111], output: [0.112510165400612]},
    {input: [0.5390625, 0.119444444444444], output: [0.112510165400612]},
    {input: [0.55625, 0.1], output: [0.135150212201616]},
    {input: [0.4890625, 0.113888888888889], output: [0.135150212201616]},
    {input: [0.471875, 0.111111111111111], output: [0.112510165400612]},
    {input: [0.478125, 0.122222222222222], output: [0.112510165400612]},
    {input: [0.465625, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.465625, 0.119444444444444], output: [0.112510165400612]},
    {input: [0.4375, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.26875, 0.302777777777778], output: [0.112510165400612]},
    {input: [0.296875, 0.458333333333333], output: [0.0898701185996068]},
    {input: [0.359375, 0.55], output: [0.0445900249975972]},
    {input: [0.240625, 0.525], output: [0.0445900249975972]},
    {input: [0.1234375, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.525], output: [0.0445900249975972]},
    {input: [0.2859375, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.3875, 0.452777777777778], output: [0.0445900249975972]},
    {input: [0.359375, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.26875, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.4], output: [0.0219499781965924]},
    {input: [0.421875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.415625, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.21875, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.5], output: [0.0445900249975972]},
    {input: [0.2640625, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.6], output: [0.0445900249975972]},
    {input: [0.1796875, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.3], output: [0.0219499781965924]},
    {input: [0.1734375, 0.525], output: [0.0219499781965924]},
    {input: [0.140625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.4], output: [0.0219499781965924]},
    {input: [0.309375, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.415625, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.45, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.4546875, 0.302777777777778], output: [0.0898701185996068]},
    {input: [0.38125, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.3765625, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.5109375, 0.408333333333333], output: [0.0898701185996068]},
    {input: [0.4828125, 0.411111111111111], output: [0.0898701185996068]},
    {input: [0.5, 0.394444444444444], output: [0.112510165400612]},
    {input: [0.528125, 0.375], output: [0.112510165400612]},
    {input: [0.409375, 0.416666666666667], output: [0.112510165400612]},
    {input: [0.45, 0.375], output: [0.0898701185996068]},
    {input: [0.3765625, 0.366666666666667], output: [0.0898701185996068]},
    {input: [0.4890625, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.2296875, 0.472222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.55], output: [0.067230071798602]},
    {input: [0.3703125, 0.522222222222222], output: [0.0898701185996068]},
    {input: [0.3484375, 0.491666666666667], output: [0.0898701185996068]},
    {input: [0.275, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.3203125, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.375], output: [0.0898701185996068]},
    {input: [0.38125, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.35], output: [0.067230071798602]},
    {input: [0.365625, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.44375, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.409375, 0.55], output: [0.067230071798602]},
    {input: [0.3703125, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.591666666666667], output: [0.067230071798602]},
    {input: [0.246875, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.409375, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.3984375, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.353125, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.421875, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.4265625, 0.75], output: [0.067230071798602]},
    {input: [0.359375, 0.752777777777778], output: [0.112510165400612]},
    {input: [0.21875, 0.605555555555556], output: [0.112510165400612]},
    {input: [0.128125, 0.533333333333333], output: [0.0898701185996068]},
    {input: [0.1515625, 0.447222222222222], output: [0.067230071798602]},
    {input: [0.16875, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.1453125, 0.511111111111111], output: [0.067230071798602]},
    {input: [0.16875, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.1453125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.534375, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.3765625, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.825], output: [0.0445900249975972]},
    {input: [0.44375, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.3984375, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.578125, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.690625, 0.761111111111111], output: [0.135150212201616]},
    {input: [0.7140625, 0.777777777777778], output: [0.180430305803626]},
    {input: [0.7421875, 0.780555555555556], output: [0.203070352604631]},
    {input: [0.746875, 0.791666666666667], output: [0.225710399405636]},
    {input: [0.6859375, 0.791666666666667], output: [0.225710399405636]},
    {input: [0.5953125, 0.777777777777778], output: [0.180430305803626]},
    {input: [0.584375, 0.783333333333333], output: [0.157790259002621]},
    {input: [0.584375, 0.780555555555556], output: [0.157790259002621]},
    {input: [0.578125, 0.791666666666667], output: [0.135150212201616]},
    {input: [0.5390625, 0.808333333333333], output: [0.157790259002621]},
    {input: [0.4265625, 0.838888888888889], output: [0.135150212201616]},
    {input: [0.3375, 0.836111111111111], output: [0.112510165400612]},
    {input: [0.2125, 0.969444444444444], output: [0.0898701185996068]},
    {input: [0.1734375, 0.00555555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.175], output: [0.0219499781965924]},
    {input: [0.325, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.325, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.3484375, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.3875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.471875, 0.3], output: [0.067230071798602]},
    {input: [0.471875, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.39375, 0.3], output: [0.112510165400612]},
    {input: [0.21875, 0.369444444444444], output: [0.112510165400612]},
    {input: [0.33125, 0.369444444444444], output: [0.0898701185996068]},
    {input: [0.3140625, 0.452777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.55], output: [0.112510165400612]},
    {input: [0.15625, 0.580555555555556], output: [0.112510165400612]},
    {input: [0.2125, 0.55], output: [0.0898701185996068]},
    {input: [0.3375, 0.55], output: [0.067230071798602]},
    {input: [0.4328125, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.471875, 0.577777777777778], output: [0.067230071798602]},
    {input: [0.4265625, 0.608333333333333], output: [0.067230071798602]},
    {input: [0.44375, 0.577777777777778], output: [0.067230071798602]},
    {input: [0.296875, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.3875, 0.588888888888889], output: [0.067230071798602]},
    {input: [0.4375, 0.569444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.5, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.528125, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.675, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.73125, 0.769444444444444], output: [0.180430305803626]},
    {input: [0.78125, 0.766666666666667], output: [0.203070352604631]},
    {input: [0.7359375, 0.783333333333333], output: [0.225710399405636]},
    {input: [0.6515625, 0.791666666666667], output: [0.203070352604631]},
    {input: [0.6796875, 0.766666666666667], output: [0.225710399405636]},
    {input: [0.5109375, 0.783333333333333], output: [0.180430305803626]},
    {input: [0.703125, 0.761111111111111], output: [0.180430305803626]},
    {input: [0.6796875, 0.755555555555556], output: [0.203070352604631]},
    {input: [0.590625, 0.747222222222222], output: [0.203070352604631]},
    {input: [0.753125, 0.758333333333333], output: [0.180430305803626]},
    {input: [0.7984375, 0.786111111111111], output: [0.203070352604631]},
    {input: [0.809375, 0.786111111111111], output: [0.225710399405636]},
    {input: [0.8484375, 0.777777777777778], output: [0.24835044620664]},
    {input: [0.7984375, 0.769444444444444], output: [0.270990493007645]},
    {input: [0.696875, 0.752777777777778], output: [0.24835044620664]},
    {input: [0.6859375, 0.758333333333333], output: [0.225710399405636]},
    {input: [0.7140625, 0.780555555555556], output: [0.225710399405636]},
    {input: [0.646875, 0.769444444444444], output: [0.203070352604631]},
    {input: [0.4828125, 0.736111111111111], output: [0.203070352604631]},
    {input: [0.5953125, 0.763888888888889], output: [0.180430305803626]},
    {input: [0.55625, 0.769444444444444], output: [0.180430305803626]},
    {input: [0.4890625, 0.716666666666667], output: [0.157790259002621]},
    {input: [0.38125, 0.783333333333333], output: [0.135150212201616]},
    {input: [0.3984375, 0.816666666666667], output: [0.112510165400612]},
    {input: [0.190625, 0.1], output: [0.112510165400612]},
    {input: [0.1, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.0953125, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.084375, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.25], output: [0.0219499781965924]},
    {input: [0.1, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.175], output: [0.0219499781965924]},
    {input: [0.1453125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.325], output: [0.0219499781965924]},
    {input: [0.225, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.3984375, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.409375, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.303125, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.3703125, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.33125, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.3375, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.3375, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.175], output: [0.067230071798602]},
    {input: [0.3875, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.15], output: [0.067230071798602]},
    {input: [0.415625, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.3203125, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.309375, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.2125, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.3765625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.4046875, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.225], output: [0.0898701185996068]},
    {input: [0.2921875, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.2640625, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.296875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.2921875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.775], output: [0.0445900249975972]},
    {input: [0.225, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.134375, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.577777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.1, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.225], output: [0.0219499781965924]},
    {input: [0.0671875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.1], output: [0.0219499781965924]},
    {input: [0.16875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.525], output: [0.0219499781965924]},
    {input: [0.2015625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.25], output: [0.0445900249975972]},
    {input: [0.225, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3203125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.55], output: [0.0219499781965924]},
    {input: [0.0671875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0109375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.219444444444444], output: [-0.000690068604412431]},
    {input: [0.1125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.194444444444444], output: [-0.000690068604412431]},
    {input: [0.296875, 0.2], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.1], output: [0.0445900249975972]},
    {input: [0.15625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.3], output: [0.0445900249975972]},
    {input: [0.2640625, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.3765625, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.45, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.3875, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.3140625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.534375, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.4609375, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.3984375, 0.136111111111111], output: [0.112510165400612]},
    {input: [0.2640625, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.3421875, 0.2], output: [0.0898701185996068]},
    {input: [0.33125, 0.3], output: [0.0898701185996068]},
    {input: [0.3765625, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.28125, 0.302777777777778], output: [0.135150212201616]},
    {input: [0.478125, 0.330555555555556], output: [0.135150212201616]},
    {input: [0.746875, 0.322222222222222], output: [0.157790259002621]},
    {input: [0.6796875, 0.411111111111111], output: [0.225710399405636]},
    {input: [0.4046875, 0.480555555555556], output: [0.203070352604631]},
    {input: [0.4046875, 0.527777777777778], output: [0.135150212201616]},
    {input: [0.3875, 0.602777777777778], output: [0.0898701185996068]},
    {input: [0.55625, 0.608333333333333], output: [0.0898701185996068]},
    {input: [0.2296875, 0.538888888888889], output: [0.0898701185996068]},
    {input: [0.253125, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.1734375, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.353125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.3765625, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.4328125, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.44375, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.45, 0.363888888888889], output: [0.0898701185996068]},
    {input: [0.5, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.2859375, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.16875, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.275, 0.45], output: [0.0445900249975972]},
    {input: [0.253125, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.28125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.196875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.359375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.4046875, 0.294444444444444], output: [0.0898701185996068]},
    {input: [0.534375, 0.277777777777778], output: [0.0898701185996068]},
    {input: [0.4890625, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.528125, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.5625, 0.208333333333333], output: [0.135150212201616]},
    {input: [0.634375, 0.3], output: [0.135150212201616]},
    {input: [0.6234375, 0.372222222222222], output: [0.135150212201616]},
    {input: [0.309375, 0.497222222222222], output: [0.157790259002621]},
    {input: [0.409375, 0.4], output: [0.112510165400612]},
    {input: [0.33125, 0.308333333333333], output: [0.112510165400612]},
    {input: [0.365625, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.3875, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.2640625, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.438888888888889], output: [0.0898701185996068]},
    {input: [0.3765625, 0.45], output: [0.067230071798602]},
    {input: [0.26875, 0.475], output: [0.067230071798602]},
    {input: [0.5171875, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.4328125, 0.355555555555556], output: [0.0898701185996068]},
    {input: [0.3375, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.275], output: [0.067230071798602]},
    {input: [0.39375, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.2578125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.33125, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.4046875, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.3703125, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.15625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.084375, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.084375, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.775], output: [0.0219499781965924]},
    {input: [0.1625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.365625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.421875, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.415625, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.421875, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.4328125, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.4328125, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.478125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.309375, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.1625, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.240625, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.1, 0.2], output: [0.067230071798602]},
    {input: [0.0890625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.134375, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.15625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.10625, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.675], output: [0.0219499781965924]},
    {input: [0.2296875, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.75], output: [0.0445900249975972]},
    {input: [0.2125, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.240625, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.246875, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.8], output: [0.0219499781965924]},
    {input: [0.15625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.0609375, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.190625, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.75], output: [0.0445900249975972]},
    {input: [0.16875, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.0390625, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.015625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.15], output: [0.0219499781965924]},
    {input: [0.084375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.372222222222222], output: [-0.000690068604412431]},
    {input: [0.10625, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.134375, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.0833333333333333], output: [-0.000690068604412431]},
    {input: [0.1125, 0.275], output: [0.0219499781965924]},
    {input: [0.0109375, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.175], output: [0.0445900249975972]},
    {input: [0.240625, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.0609375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.8], output: [0.0219499781965924]},
    {input: [0.10625, 0.588888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.705555555555556], output: [-0.000690068604412431]},
    {input: [0.15625, 0.722222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.55], output: [0.0219499781965924]},
    {input: [0.2359375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.21875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.25], output: [0.0219499781965924]},
    {input: [0.2078125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.141666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.455555555555556], output: [-0.000690068604412431]},
    {input: [0.1125, 0.15], output: [0.0219499781965924]},
    {input: [0.184375, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.275, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.25], output: [0.0219499781965924]},
    {input: [0.3203125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.409375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.28125, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.3203125, 0.2], output: [0.0898701185996068]},
    {input: [0.2921875, 0.225], output: [0.0898701185996068]},
    {input: [0.309375, 0.347222222222222], output: [0.0898701185996068]},
    {input: [0.28125, 0.572222222222222], output: [0.0898701185996068]},
    {input: [0.240625, 0.630555555555556], output: [0.112510165400612]},
    {input: [0.61875, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.646875, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.55625, 0.763888888888889], output: [0.157790259002621]},
    {input: [0.578125, 0.766666666666667], output: [0.157790259002621]},
    {input: [0.478125, 0.725], output: [0.157790259002621]},
    {input: [0.471875, 0.722222222222222], output: [0.157790259002621]},
    {input: [0.3703125, 0.705555555555556], output: [0.112510165400612]},
    {input: [0.409375, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.4828125, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.44375, 0.725], output: [0.135150212201616]},
    {input: [0.45, 0.730555555555556], output: [0.112510165400612]},
    {input: [0.421875, 0.725], output: [0.0898701185996068]},
    {input: [0.4328125, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.4546875, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.45, 0.727777777777778], output: [0.0898701185996068]},
    {input: [0.50625, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.21875, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.26875, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.49375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.534375, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.8], output: [0.112510165400612]},
    {input: [0.5625, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.5, 0.811111111111111], output: [0.135150212201616]},
    {input: [0.478125, 0.797222222222222], output: [0.135150212201616]},
    {input: [0.471875, 0.797222222222222], output: [0.135150212201616]},
    {input: [0.3421875, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.275, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.196875, 0.708333333333333], output: [0.0898701185996068]},
    {input: [0.190625, 0.627777777777778], output: [0.067230071798602]},
    {input: [0.184375, 0.605555555555556], output: [0.067230071798602]},
    {input: [0.21875, 0.65], output: [0.0445900249975972]},
    {input: [0.2296875, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.2296875, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.725], output: [0.0445900249975972]},
    {input: [0.0953125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.140625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.366666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.213888888888889], output: [-0.000690068604412431]},
    {input: [0.15625, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.2], output: [0.0219499781965924]},
    {input: [0.15625, 0.375], output: [0.0219499781965924]},
    {input: [0.1734375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.119444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.438888888888889], output: [-0.000690068604412431]},
    {input: [0.04375, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.169444444444444], output: [-0.000690068604412431]},
    {input: [0.021875, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.691666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.738888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.55], output: [0.0219499781965924]},
    {input: [0.190625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.3484375, 0.225], output: [0.0445900249975972]},
    {input: [0.3203125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.4046875, 0.111111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.246875, 0.419444444444444], output: [0.067230071798602]},
    {input: [0.196875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.2125, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.3875, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.39375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.5171875, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.3875, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.4046875, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.39375, 0.15], output: [0.0898701185996068]},
    {input: [0.3984375, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.465625, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.3140625, 0.3], output: [0.112510165400612]},
    {input: [0.309375, 0.411111111111111], output: [0.112510165400612]},
    {input: [0.4046875, 0.322222222222222], output: [0.112510165400612]},
    {input: [0.4609375, 0.302777777777778], output: [0.135150212201616]},
    {input: [0.415625, 0.297222222222222], output: [0.135150212201616]},
    {input: [0.521875, 0.327777777777778], output: [0.135150212201616]},
    {input: [0.6015625, 0.308333333333333], output: [0.157790259002621]},
    {input: [0.61875, 0.327777777777778], output: [0.135150212201616]},
    {input: [0.5625, 0.291666666666667], output: [0.157790259002621]},
    {input: [0.5109375, 0.302777777777778], output: [0.157790259002621]},
    {input: [0.365625, 0.283333333333333], output: [0.157790259002621]},
    {input: [0.1515625, 0.363888888888889], output: [0.135150212201616]},
    {input: [0.38125, 0.363888888888889], output: [0.112510165400612]},
    {input: [0.5109375, 0.275], output: [0.135150212201616]},
    {input: [0.4265625, 0.294444444444444], output: [0.135150212201616]},
    {input: [0.5453125, 0.266666666666667], output: [0.112510165400612]},
    {input: [0.3421875, 0.280555555555556], output: [0.112510165400612]},
    {input: [0.1625, 0.280555555555556], output: [0.157790259002621]},
    {input: [0.253125, 0.288888888888889], output: [0.180430305803626]},
    {input: [0.2015625, 0.341666666666667], output: [0.135150212201616]},
    {input: [0.2578125, 0.325], output: [0.112510165400612]},
    {input: [0.2578125, 0.286111111111111], output: [0.112510165400612]},
    {input: [0.26875, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.2125, 0.436111111111111], output: [0.0898701185996068]},
    {input: [0.21875, 0.472222222222222], output: [0.067230071798602]},
    {input: [0.0890625, 0.641666666666667], output: [0.067230071798602]},
    {input: [0.2015625, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.2015625, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.325, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.190625, 0.727777777777778], output: [0.0898701185996068]},
    {input: [0.2125, 0.625], output: [0.112510165400612]},
    {input: [0.2125, 0.555555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.569444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.575], output: [0.0445900249975972]},
    {input: [0.3140625, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.625], output: [0.0445900249975972]},
    {input: [0.190625, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.4], output: [0.0445900249975972]},
    {input: [0.134375, 0.525], output: [0.0445900249975972]},
    {input: [0.0953125, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.5], output: [0.0219499781965924]},
    {input: [0.309375, 0.3], output: [0.0219499781965924]},
    {input: [0.4328125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.5390625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.534375, 0.35], output: [0.067230071798602]},
    {input: [0.478125, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.478125, 0.3], output: [0.0898701185996068]},
    {input: [0.534375, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.5453125, 0.297222222222222], output: [0.0898701185996068]},
    {input: [0.528125, 0.277777777777778], output: [0.112510165400612]},
    {input: [0.528125, 0.336111111111111], output: [0.135150212201616]},
    {input: [0.4046875, 0.308333333333333], output: [0.112510165400612]},
    {input: [0.3765625, 0.336111111111111], output: [0.112510165400612]},
    {input: [0.3765625, 0.344444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.4890625, 0.369444444444444], output: [0.112510165400612]},
    {input: [0.4890625, 0.416666666666667], output: [0.135150212201616]},
    {input: [0.415625, 0.486111111111111], output: [0.135150212201616]},
    {input: [0.325, 0.622222222222222], output: [0.112510165400612]},
    {input: [0.634375, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.5109375, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.365625, 0.686111111111111], output: [0.0898701185996068]},
    {input: [0.3421875, 0.647222222222222], output: [0.0898701185996068]},
    {input: [0.296875, 0.663888888888889], output: [0.0898701185996068]},
    {input: [0.2921875, 0.725], output: [0.0898701185996068]},
    {input: [0.3703125, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.38125, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.44375, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.421875, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.836111111111111], output: [0.112510165400612]},
    {input: [0.4609375, 0.813888888888889], output: [0.112510165400612]},
    {input: [0.409375, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.3875, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.303125, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.190625, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.1796875, 0.65], output: [0.067230071798602]},
    {input: [0.15625, 0.622222222222222], output: [0.067230071798602]},
    {input: [0.21875, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.140625, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.1515625, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.875], output: [0.0219499781965924]},
    {input: [0.04375, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.1], output: [0.0219499781965924]},
    {input: [0.190625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.425], output: [0.0219499781965924]},
    {input: [0.26875, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.3875, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.4], output: [0.0445900249975972]},
    {input: [0.4609375, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.478125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.421875, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.4328125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.44375, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.1796875, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.1234375, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.0416666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.240625, 0.00277777777777778], output: [0.0898701185996068]},
    {input: [0.1171875, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.10625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.05, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.4546875, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.528125, 0.75], output: [0.0445900249975972]},
    {input: [0.478125, 0.75], output: [0.067230071798602]},
    {input: [0.521875, 0.772222222222222], output: [0.112510165400612]},
    {input: [0.584375, 0.780555555555556], output: [0.135150212201616]},
    {input: [0.590625, 0.752777777777778], output: [0.135150212201616]},
    {input: [0.634375, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.590625, 0.761111111111111], output: [0.157790259002621]},
    {input: [0.5390625, 0.761111111111111], output: [0.135150212201616]},
    {input: [0.49375, 0.772222222222222], output: [0.135150212201616]},
    {input: [0.4328125, 0.769444444444444], output: [0.135150212201616]},
    {input: [0.5171875, 0.791666666666667], output: [0.135150212201616]},
    {input: [0.465625, 0.797222222222222], output: [0.135150212201616]},
    {input: [0.5, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.4265625, 0.8], output: [0.112510165400612]},
    {input: [0.3203125, 0.811111111111111], output: [0.112510165400612]},
    {input: [0.2859375, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.1734375, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.071875, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.1734375, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.38125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.683333333333333], output: [0.0898701185996068]},
    {input: [0.071875, 0.483333333333333], output: [0.0898701185996068]},
    {input: [0.0890625, 0.605555555555556], output: [0.067230071798602]},
    {input: [0.128125, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.071875, 0.602777777777778], output: [0.067230071798602]},
    {input: [0.071875, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.0390625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.125], output: [0.0219499781965924]},
    {input: [0.084375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.463888888888889], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.1734375, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.325], output: [0.0219499781965924]},
    {input: [0.225, 0.375], output: [0.0219499781965924]},
    {input: [0.084375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.4046875, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.39375, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.353125, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.38125, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.4046875, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.49375, 0.4], output: [0.067230071798602]},
    {input: [0.5390625, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.753125, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.66875, 0.391666666666667], output: [0.135150212201616]},
    {input: [0.696875, 0.425], output: [0.135150212201616]},
    {input: [0.5109375, 0.477777777777778], output: [0.135150212201616]},
    {input: [0.33125, 0.552777777777778], output: [0.135150212201616]},
    {input: [0.578125, 0.605555555555556], output: [0.135150212201616]},
    {input: [0.6515625, 0.763888888888889], output: [0.135150212201616]},
    {input: [0.696875, 0.663888888888889], output: [0.180430305803626]},
    {input: [0.815625, 0.736111111111111], output: [0.157790259002621]},
    {input: [0.71875, 0.725], output: [0.180430305803626]},
    {input: [0.9328125, 0.75], output: [0.29363053980865]},
    {input: [0.9671875, 0.763888888888889], output: [0.316270586609655]},
    {input: [1, 0.783333333333333], output: [0.316270586609655]},
    {input: [0.859375, 0.772222222222222], output: [0.29363053980865]},
    {input: [0.775, 0.758333333333333], output: [0.29363053980865]},
    {input: [0.7140625, 0.772222222222222], output: [0.24835044620664]},
    {input: [0.7875, 0.780555555555556], output: [0.24835044620664]},
    {input: [0.696875, 0.775], output: [0.24835044620664]},
    {input: [0.78125, 0.766666666666667], output: [0.24835044620664]},
    {input: [0.7984375, 0.775], output: [0.225710399405636]},
    {input: [0.759375, 0.769444444444444], output: [0.225710399405636]},
    {input: [0.690625, 0.780555555555556], output: [0.203070352604631]},
    {input: [0.7640625, 0.788888888888889], output: [0.203070352604631]},
    {input: [0.6234375, 0.769444444444444], output: [0.203070352604631]},
    {input: [0.6578125, 0.783333333333333], output: [0.203070352604631]},
    {input: [0.640625, 0.777777777777778], output: [0.180430305803626]},
    {input: [0.590625, 0.797222222222222], output: [0.180430305803626]},
    {input: [0.5671875, 0.797222222222222], output: [0.157790259002621]},
    {input: [0.6125, 0.788888888888889], output: [0.157790259002621]},
    {input: [0.521875, 0.786111111111111], output: [0.157790259002621]},
    {input: [0.4265625, 0.783333333333333], output: [0.135150212201616]},
    {input: [0.44375, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.4609375, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.4828125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.4375, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.39375, 0.694444444444444], output: [0.0898701185996068]},
    {input: [0.478125, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.521875, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.55, 0.725], output: [0.0898701185996068]},
    {input: [0.4328125, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.4375, 0.736111111111111], output: [0.112510165400612]},
    {input: [0.421875, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.3703125, 0.708333333333333], output: [0.0898701185996068]},
    {input: [0.3484375, 0.725], output: [0.067230071798602]},
    {input: [0.2921875, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.2078125, 0.983333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.527777777777778], output: [-0.000690068604412431]},
    {input: [0.015625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0638888888888889], output: [-0.000690068604412431]},
    {input: [0.134375, 0.075], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.144444444444444], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.813888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.261111111111111], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.266666666666667], output: [-0.000690068604412431]},
    {input: [0.21875, 0.225], output: [0.0219499781965924]},
    {input: [0.275, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.303125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.85], output: [0.0445900249975972]},
    {input: [0.10625, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.725], output: [0.0219499781965924]},
    {input: [0.04375, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.422222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.6], output: [0.0219499781965924]},
    {input: [0.1796875, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.6], output: [0.0219499781965924]},
    {input: [0.2015625, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.5], output: [0.0219499781965924]},
    {input: [0.134375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.45], output: [0.0219499781965924]},
    {input: [0.3375, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.2078125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.3875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.359375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.4046875, 0.175], output: [0.067230071798602]},
    {input: [0.3875, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.175], output: [0.0219499781965924]},
    {input: [0.3484375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.3703125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.415625, 0.225], output: [0.067230071798602]},
    {input: [0.409375, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.39375, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.38125, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.325, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.3375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.5390625, 0.8], output: [0.0219499781965924]},
    {input: [0.4046875, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.4328125, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.478125, 0.725], output: [0.112510165400612]},
    {input: [0.534375, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.521875, 0.711111111111111], output: [0.135150212201616]},
    {input: [0.528125, 0.705555555555556], output: [0.135150212201616]},
    {input: [0.50625, 0.7], output: [0.135150212201616]},
    {input: [0.5390625, 0.716666666666667], output: [0.135150212201616]},
    {input: [0.4890625, 0.736111111111111], output: [0.157790259002621]},
    {input: [0.4890625, 0.75], output: [0.157790259002621]},
    {input: [0.5671875, 0.755555555555556], output: [0.157790259002621]},
    {input: [0.6125, 0.761111111111111], output: [0.157790259002621]},
    {input: [0.5953125, 0.766666666666667], output: [0.180430305803626]},
    {input: [0.6296875, 0.780555555555556], output: [0.203070352604631]},
    {input: [0.584375, 0.783333333333333], output: [0.203070352604631]},
    {input: [0.5109375, 0.794444444444444], output: [0.225710399405636]},
    {input: [0.4890625, 0.791666666666667], output: [0.180430305803626]},
    {input: [0.275, 0.755555555555556], output: [0.135150212201616]},
    {input: [0.325, 0.727777777777778], output: [0.112510165400612]},
    {input: [0.38125, 0.711111111111111], output: [0.0898701185996068]},
    {input: [0.409375, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.534375, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.528125, 0.761111111111111], output: [0.135150212201616]},
    {input: [0.5171875, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.521875, 0.758333333333333], output: [0.135150212201616]},
    {input: [0.4890625, 0.752777777777778], output: [0.135150212201616]},
    {input: [0.49375, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.4265625, 0.766666666666667], output: [0.157790259002621]},
    {input: [0.44375, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.359375, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.365625, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.39375, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.3484375, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.275, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.253125, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.1453125, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.134375, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.078125, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.0671875, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.0609375, 0.513888888888889], output: [0.0445900249975972]},
    {input: [0.0671875, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.325], output: [0.0219499781965924]},
    {input: [0.0390625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.875], output: [0.0219499781965924]},
    {input: [0.04375, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.16875, 0.141666666666667], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.125], output: [0.0219499781965924]},
    {input: [0.2015625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.15], output: [0.0219499781965924]},
    {input: [0.2015625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.625], output: [0.0219499781965924]},
    {input: [0.240625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.365625, 0.45], output: [0.0219499781965924]},
    {input: [0.471875, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.309375, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.365625, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.3984375, 0.472222222222222], output: [0.067230071798602]},
    {input: [0.4328125, 0.483333333333333], output: [0.0898701185996068]},
    {input: [0.2921875, 0.519444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.2125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.1453125, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.521875, 0.775], output: [0.0445900249975972]},
    {input: [0.49375, 0.719444444444444], output: [0.067230071798602]},
    {input: [0.5109375, 0.702777777777778], output: [0.135150212201616]},
    {input: [0.521875, 0.655555555555556], output: [0.135150212201616]},
    {input: [0.44375, 0.688888888888889], output: [0.112510165400612]},
    {input: [0.528125, 0.694444444444444], output: [0.0898701185996068]},
    {input: [0.465625, 0.694444444444444], output: [0.135150212201616]},
    {input: [0.5109375, 0.691666666666667], output: [0.135150212201616]},
    {input: [0.534375, 0.683333333333333], output: [0.157790259002621]},
    {input: [0.4609375, 0.7], output: [0.180430305803626]},
    {input: [0.5671875, 0.752777777777778], output: [0.157790259002621]},
    {input: [0.4890625, 0.752777777777778], output: [0.135150212201616]},
    {input: [0.45, 0.794444444444444], output: [0.135150212201616]},
    {input: [0.3421875, 0.825], output: [0.112510165400612]},
    {input: [0.2640625, 0.830555555555556], output: [0.112510165400612]},
    {input: [0.0953125, 0.872222222222222], output: [0.0898701185996068]},
    {input: [0.1796875, 0.1], output: [0.067230071798602]},
    {input: [0.196875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.1734375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.5], output: [0.0219499781965924]},
    {input: [0.05625, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.33125, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.4046875, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.3875, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.50625, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.50625, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.6234375, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.61875, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.521875, 0.316666666666667], output: [0.112510165400612]},
    {input: [0.6015625, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.6015625, 0.372222222222222], output: [0.112510165400612]},
    {input: [0.6796875, 0.377777777777778], output: [0.135150212201616]},
    {input: [0.4265625, 0.430555555555556], output: [0.112510165400612]},
    {input: [0.478125, 0.405555555555556], output: [0.0898701185996068]},
    {input: [0.584375, 0.444444444444444], output: [0.0898701185996068]},
    {input: [0.415625, 0.516666666666667], output: [0.0898701185996068]},
    {input: [0.296875, 0.505555555555556], output: [0.112510165400612]},
    {input: [0.4046875, 0.597222222222222], output: [0.0898701185996068]},
    {input: [0.478125, 0.575], output: [0.0898701185996068]},
    {input: [0.4890625, 0.6], output: [0.0898701185996068]},
    {input: [0.4328125, 0.594444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.6015625, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.5390625, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.578125, 0.713888888888889], output: [0.180430305803626]},
    {input: [0.521875, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.5671875, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.409375, 0.713888888888889], output: [0.112510165400612]},
    {input: [0.50625, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.5171875, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.6515625, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.5171875, 0.794444444444444], output: [0.135150212201616]},
    {input: [0.634375, 0.783333333333333], output: [0.135150212201616]},
    {input: [0.584375, 0.772222222222222], output: [0.157790259002621]},
    {input: [0.6125, 0.777777777777778], output: [0.157790259002621]},
    {input: [0.5171875, 0.766666666666667], output: [0.157790259002621]},
    {input: [0.465625, 0.752777777777778], output: [0.135150212201616]},
    {input: [0.39375, 0.716666666666667], output: [0.135150212201616]},
    {input: [0.421875, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.3421875, 0.688888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.359375, 0.75], output: [0.067230071798602]},
    {input: [0.4546875, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.2921875, 0.8], output: [0.0898701185996068]},
    {input: [0.353125, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.75], output: [0.0898701185996068]},
    {input: [0.3140625, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.325, 0.75], output: [0.067230071798602]},
    {input: [0.365625, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.1625, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.0527777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.1], output: [0.0445900249975972]},
    {input: [0.1, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.028125, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.2578125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.33125, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.325, 0.8], output: [0.067230071798602]},
    {input: [0.253125, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.253125, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.16875, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.465625, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.5109375, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.534375, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.5390625, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.5453125, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.5453125, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.5671875, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.8], output: [0.112510165400612]},
    {input: [0.49375, 0.811111111111111], output: [0.112510165400612]},
    {input: [0.4890625, 0.830555555555556], output: [0.112510165400612]},
    {input: [0.4265625, 0.808333333333333], output: [0.112510165400612]},
    {input: [0.3765625, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.3140625, 0.8], output: [0.0898701185996068]},
    {input: [0.275, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.2078125, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.415625, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.841666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.825], output: [0.0898701185996068]},
    {input: [0.5390625, 0.822222222222222], output: [0.0898701185996068]},
    {input: [0.5390625, 0.811111111111111], output: [0.135150212201616]},
    {input: [0.4328125, 0.827777777777778], output: [0.112510165400612]},
    {input: [0.44375, 0.816666666666667], output: [0.112510165400612]},
    {input: [0.3765625, 0.830555555555556], output: [0.112510165400612]},
    {input: [0.421875, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.4265625, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.38125, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.39375, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.3875, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.3875, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.3140625, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.28125, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.45, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.478125, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.4890625, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.471875, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.4375, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.421875, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.421875, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.44375, 0.8], output: [0.0898701185996068]},
    {input: [0.421875, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.4046875, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.4609375, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.44375, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.353125, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.3375, 0.822222222222222], output: [0.0898701185996068]},
    {input: [0.196875, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.325, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.359375, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.3703125, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.225, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.2125, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.1515625, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.75], output: [0.0219499781965924]},
    {input: [0.05625, 0.725], output: [0.0445900249975972]},
    {input: [0.021875, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.725], output: [0.0219499781965924]},
    {input: [0.2921875, 0.911111111111111], output: [-0.000690068604412431]},
    {input: [0.196875, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.875], output: [0.0445900249975972]},
    {input: [0.1453125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.05625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.65], output: [0.0219499781965924]},
    {input: [0.1, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.7], output: [0.0219499781965924]},
    {input: [0.084375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.625], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.680555555555556], output: [-0.000690068604412431]},
    {input: [0.2125, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.240625, 0.844444444444444], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.772222222222222], output: [-0.000690068604412431]},
    {input: [0.140625, 0.891666666666667], output: [-0.000690068604412431]},
    {input: [0.10625, 0.025], output: [0.0219499781965924]},
    {input: [0.05, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.075], output: [0.0219499781965924]},
    {input: [0.0109375, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.35], output: [0.0219499781965924]},
    {input: [0.0671875, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.10625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.755555555555556], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.841666666666667], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.536111111111111], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.491666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.594444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.819444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.425], output: [0.0219499781965924]},
    {input: [0.0609375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.719444444444444], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.0109375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.352777777777778], output: [-0.000690068604412431]},
    {input: [0.05, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.65], output: [-0.000690068604412431]},
    {input: [0.184375, 0.694444444444444], output: [-0.000690068604412431]},
    {input: [0.15625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.3484375, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.44375, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.3765625, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.44375, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.811111111111111], output: [0.112510165400612]},
    {input: [0.4328125, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.825], output: [0.0898701185996068]},
    {input: [0.3421875, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.2640625, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.2640625, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.2296875, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.39375, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.225, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.1171875, 0.702777777777778], output: [0.067230071798602]},
    {input: [0.084375, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.1125, 0.45], output: [0.0445900249975972]},
    {input: [0.253125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.634375, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.6125, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.55, 0.741666666666667], output: [0.135150212201616]},
    {input: [0.49375, 0.75], output: [0.135150212201616]},
    {input: [0.5953125, 0.761111111111111], output: [0.157790259002621]},
    {input: [0.6234375, 0.772222222222222], output: [0.157790259002621]},
    {input: [0.528125, 0.763888888888889], output: [0.157790259002621]},
    {input: [0.471875, 0.780555555555556], output: [0.135150212201616]},
    {input: [0.55625, 0.797222222222222], output: [0.135150212201616]},
    {input: [0.5671875, 0.811111111111111], output: [0.135150212201616]},
    {input: [0.528125, 0.811111111111111], output: [0.135150212201616]},
    {input: [0.5, 0.813888888888889], output: [0.112510165400612]},
    {input: [0.44375, 0.819444444444444], output: [0.112510165400612]},
    {input: [0.465625, 0.825], output: [0.112510165400612]},
    {input: [0.3203125, 0.827777777777778], output: [0.112510165400612]},
    {input: [0.26875, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.2015625, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.134375, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.0953125, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.25], output: [0.0219499781965924]},
    {input: [0.1734375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.3], output: [0.0219499781965924]},
    {input: [0.1453125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.33125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.3203125, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.3375, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.3484375, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.3140625, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.2578125, 0.15], output: [0.067230071798602]},
    {input: [0.240625, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.4], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.444444444444444], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.361111111111111], output: [-0.000690068604412431]},
    {input: [0.190625, 0.394444444444444], output: [-0.000690068604412431]},
    {input: [0.16875, 0.35], output: [0.0219499781965924]},
    {input: [0.2015625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.275], output: [0.0219499781965924]},
    {input: [0.2359375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.394444444444444], output: [-0.000690068604412431]},
    {input: [0.296875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.3], output: [0.0219499781965924]},
    {input: [0.2125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.353125, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.4265625, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.194444444444444], output: [0.0898701185996068]},
    {input: [0.3703125, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.253125, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.196875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.1515625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.15625, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.05, 0.525], output: [0.0219499781965924]},
    {input: [0.0890625, 0.930555555555556], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.963888888888889], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.075], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.858333333333333], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.552777777777778], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.358333333333333], output: [-0.000690068604412431]},
    {input: [0.078125, 0.3], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.333333333333333], output: [-0.000690068604412431]},
    {input: [0.084375, 0.530555555555556], output: [-0.000690068604412431]},
    {input: [0.1625, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.961111111111111], output: [-0.000690068604412431]},
    {input: [0.10625, 0.938888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.725], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.588888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.616666666666667], output: [-0.000690068604412431]},
    {input: [0.078125, 0.6], output: [0.0219499781965924]},
    {input: [0.1515625, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.125], output: [0.0219499781965924]},
    {input: [0.1734375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.2578125, 0.2], output: [0.0219499781965924]},
    {input: [0.2640625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.359375, 0.275], output: [0.0445900249975972]},
    {input: [0.296875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.6], output: [0.0219499781965924]},
    {input: [0.1625, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.575], output: [0.0219499781965924]},
    {input: [0.028125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.158333333333333], output: [-0.000690068604412431]},
    {input: [0.084375, 0.205555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.336111111111111], output: [-0.000690068604412431]},
    {input: [0.15625, 0.319444444444444], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.352777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.297222222222222], output: [-0.000690068604412431]},
    {input: [0.15625, 0.927777777777778], output: [-0.000690068604412431]},
    {input: [0.134375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.85], output: [0.0219499781965924]},
    {input: [0.140625, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.7], output: [0.0445900249975972]},
    {input: [0.1234375, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.025], output: [-0.000690068604412431]},
    {input: [0.05625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.00833333333333333], output: [-0.000690068604412431]},
    {input: [0.140625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.38125, 0.538888888888889], output: [-0.000690068604412431]},
    {input: [0.2859375, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.125], output: [0.0219499781965924]},
    {input: [0.2125, 0.297222222222222], output: [-0.000690068604412431]},
    {input: [0.16875, 0.236111111111111], output: [-0.000690068604412431]},
    {input: [0.246875, 0.133333333333333], output: [-0.000690068604412431]},
    {input: [0.190625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.3421875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.05, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.1453125, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.1125, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.0609375, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.0328125, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.3703125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.253125, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.1625, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.078125, 0.841666666666667], output: [0.067230071798602]},
    {input: [0.04375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.425], output: [0.0219499781965924]},
    {input: [0.246875, 0.525], output: [0.0219499781965924]},
    {input: [0.246875, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.697222222222222], output: [0.067230071798602]},
    {input: [0.253125, 0.675], output: [0.0898701185996068]},
    {input: [0.1734375, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.2078125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.986111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.975], output: [0.0219499781965924]},
    {input: [0.084375, 0.925], output: [0.0219499781965924]},
    {input: [0.071875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.325], output: [0.0219499781965924]},
    {input: [0.1, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.4], output: [0.0445900249975972]},
    {input: [0.134375, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.2], output: [0.0219499781965924]},
    {input: [0.225, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.05], output: [0.0219499781965924]},
    {input: [0.10625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.65], output: [0.0219499781965924]},
    {input: [0.0953125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.663888888888889], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.547222222222222], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.611111111111111], output: [-0.000690068604412431]},
    {input: [0.05625, 0.647222222222222], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.084375, 0.05], output: [0.0445900249975972]},
    {input: [0.0609375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.0609375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0], output: [0.0219499781965924]},
    {input: [0.0671875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.133333333333333], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.644444444444444], output: [-0.000690068604412431]},
    {input: [0.015625, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0555555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.825], output: [0.0219499781965924]},
    {input: [0.10625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.246875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.775], output: [0.0445900249975972]},
    {input: [0.1515625, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.3421875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.3], output: [0.0219499781965924]},
    {input: [0.253125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.478125, 0.544444444444444], output: [0.067230071798602]},
    {input: [0.55, 0.6], output: [0.0898701185996068]},
    {input: [0.4265625, 0.605555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.6], output: [0.067230071798602]},
    {input: [0.2359375, 0.55], output: [0.0445900249975972]},
    {input: [0.3375, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.3375, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.541666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.575], output: [0.0898701185996068]},
    {input: [0.1734375, 0.605555555555556], output: [0.067230071798602]},
    {input: [0.15625, 0.652777777777778], output: [0.067230071798602]},
    {input: [0.16875, 0.488888888888889], output: [0.067230071798602]},
    {input: [0.2078125, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.0609375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.1], output: [0.0219499781965924]},
    {input: [0.21875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.275], output: [0.0219499781965924]},
    {input: [0.2578125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.475], output: [0.0219499781965924]},
    {input: [0.071875, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.5], output: [0.0219499781965924]},
    {input: [0.1234375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.196875, 0.0694444444444444], output: [-0.000690068604412431]},
    {input: [0.246875, 0.0722222222222222], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.416666666666667], output: [-0.000690068604412431]},
    {input: [0.05625, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.447222222222222], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.7], output: [0.0219499781965924]},
    {input: [0.1453125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.33125, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.3375, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.3875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.26875, 0.813888888888889], output: [0.067230071798602]},
    {input: [0.2078125, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.128125, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.028125, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.7], output: [0.0219499781965924]},
    {input: [0.0890625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.633333333333333], output: [-0.000690068604412431]},
    {input: [0.140625, 0.627777777777778], output: [-0.000690068604412431]},
    {input: [0.15625, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.4], output: [0.0219499781965924]},
    {input: [0.071875, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.6], output: [0.0219499781965924]},
    {input: [0.1796875, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.196875, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.140625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.8], output: [0.0219499781965924]},
    {input: [0.3203125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.190625, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.078125, 0.0194444444444444], output: [0.067230071798602]},
    {input: [0.0671875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.05], output: [0.0219499781965924]},
    {input: [0.1796875, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.975], output: [0.0219499781965924]},
    {input: [0.0953125, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.8], output: [0.0219499781965924]},
    {input: [0.084375, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.015625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.95], output: [-0.000690068604412431]},
    {input: [0.078125, 0.791666666666667], output: [-0.000690068604412431]},
    {input: [0.05625, 0.902777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.605555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.686111111111111], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.722222222222222], output: [-0.000690068604412431]},
    {input: [0.140625, 0.688888888888889], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.738888888888889], output: [-0.000690068604412431]},
    {input: [0.309375, 0.8], output: [-0.000690068604412431]},
    {input: [0.3140625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.775], output: [0.0445900249975972]},
    {input: [0.2921875, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.65], output: [0.0219499781965924]},
    {input: [0.1125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.85], output: [0.0219499781965924]},
    {input: [0.478125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.5171875, 0.8], output: [0.0898701185996068]},
    {input: [0.521875, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.5171875, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.786111111111111], output: [0.135150212201616]},
    {input: [0.415625, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.4328125, 0.811111111111111], output: [0.112510165400612]},
    {input: [0.39375, 0.825], output: [0.0898701185996068]},
    {input: [0.3203125, 0.836111111111111], output: [0.0898701185996068]},
    {input: [0.33125, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.26875, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.2578125, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.2578125, 0.825], output: [0.067230071798602]},
    {input: [0.225, 0.822222222222222], output: [0.067230071798602]},
    {input: [0.1234375, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.421875, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.421875, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.705555555555556], output: [0.0898701185996068]},
    {input: [0.478125, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.50625, 0.791666666666667], output: [0.135150212201616]},
    {input: [0.55625, 0.783333333333333], output: [0.135150212201616]},
    {input: [0.640625, 0.775], output: [0.135150212201616]},
    {input: [0.590625, 0.786111111111111], output: [0.180430305803626]},
    {input: [0.584375, 0.783333333333333], output: [0.157790259002621]},
    {input: [0.578125, 0.783333333333333], output: [0.157790259002621]},
    {input: [0.5109375, 0.8], output: [0.135150212201616]},
    {input: [0.5, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.4609375, 0.808333333333333], output: [0.112510165400612]},
    {input: [0.4265625, 0.808333333333333], output: [0.112510165400612]},
    {input: [0.33125, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.2859375, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.303125, 0.813888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.16875, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.15625, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.303125, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.75], output: [0.0219499781965924]},
    {input: [0.26875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.4375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.44375, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.3984375, 0.8], output: [0.0898701185996068]},
    {input: [0.33125, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.4546875, 0.8], output: [0.067230071798602]},
    {input: [0.3765625, 0.825], output: [0.067230071798602]},
    {input: [0.3140625, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.275, 0.8], output: [0.067230071798602]},
    {input: [0.1734375, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.028125, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.0328125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.475], output: [0.0219499781965924]},
    {input: [0.0671875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.325, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.475], output: [0.0445900249975972]},
    {input: [0.196875, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.415625, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.5109375, 0.322222222222222], output: [0.0898701185996068]},
    {input: [0.409375, 0.425], output: [0.0898701185996068]},
    {input: [0.303125, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.21875, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.325, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.5671875, 0.430555555555556], output: [0.0445900249975972]},
    {input: [0.521875, 0.522222222222222], output: [0.067230071798602]},
    {input: [0.646875, 0.586111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.580555555555556], output: [0.112510165400612]},
    {input: [0.303125, 0.577777777777778], output: [0.0898701185996068]},
    {input: [0.240625, 0.55], output: [0.0445900249975972]},
    {input: [0.2578125, 0.55], output: [0.0445900249975972]},
    {input: [0.3140625, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.38125, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.3875, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.4046875, 0.516666666666667], output: [0.067230071798602]},
    {input: [0.415625, 0.511111111111111], output: [0.067230071798602]},
    {input: [0.38125, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.4828125, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0], output: [0.0219499781965924]},
    {input: [0.0671875, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.3875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.640625, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.6015625, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.521875, 0.755555555555556], output: [0.135150212201616]},
    {input: [0.44375, 0.719444444444444], output: [0.157790259002621]},
    {input: [0.478125, 0.727777777777778], output: [0.112510165400612]},
    {input: [0.45, 0.725], output: [0.112510165400612]},
    {input: [0.4609375, 0.711111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.736111111111111], output: [0.0898701185996068]},
    {input: [0.4890625, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.49375, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.478125, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.471875, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.4890625, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.5171875, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.50625, 0.8], output: [0.112510165400612]},
    {input: [0.4890625, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.55, 0.813888888888889], output: [0.112510165400612]},
    {input: [0.3703125, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.3203125, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.253125, 0.705555555555556], output: [0.0898701185996068]},
    {input: [0.1734375, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.196875, 0.641666666666667], output: [0.067230071798602]},
    {input: [0.4375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.190625, 0.933333333333333], output: [0.112510165400612]},
    {input: [0.296875, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.44375, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.421875, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.465625, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.421875, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.409375, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.325, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.2125, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.10625, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.078125, 0.625], output: [0.0219499781965924]},
    {input: [0.0390625, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.015625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.184375, 0.980555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.0638888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.2], output: [0.0219499781965924]},
    {input: [0.04375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.7], output: [-0.000690068604412431]},
    {input: [0.253125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.15], output: [0.0219499781965924]},
    {input: [0.2078125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.190625, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.4046875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.296875, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.246875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.2125, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.05, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.1171875, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.125], output: [0.0219499781965924]},
    {input: [0.0890625, 0.175], output: [0.0219499781965924]},
    {input: [0.0953125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.25], output: [0.0219499781965924]},
    {input: [0.2296875, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.1], output: [0.0219499781965924]},
    {input: [0.16875, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.55], output: [0.0219499781965924]},
    {input: [0.1796875, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.4], output: [0.0219499781965924]},
    {input: [0.21875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.415625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.3875, 0.225], output: [0.067230071798602]},
    {input: [0.353125, 0.225], output: [0.067230071798602]},
    {input: [0.3140625, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.184375, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.125], output: [0.0219499781965924]},
    {input: [0.1625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.486111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.028125, 0.763888888888889], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.875], output: [-0.000690068604412431]},
    {input: [0.05625, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.891666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.04375, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.975], output: [-0.000690068604412431]},
    {input: [0.05, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.025], output: [0.0219499781965924]},
    {input: [0.1171875, 0.125], output: [0.0219499781965924]},
    {input: [0.16875, 0.191666666666667], output: [-0.000690068604412431]},
    {input: [0.140625, 0.255555555555556], output: [-0.000690068604412431]},
    {input: [0.240625, 0.280555555555556], output: [-0.000690068604412431]},
    {input: [0.253125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.3484375, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.4265625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.472222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.35], output: [0.0219499781965924]},
    {input: [0.353125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.158333333333333], output: [-0.000690068604412431]},
    {input: [0.084375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.4046875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.33125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.353125, 0.175], output: [0.0445900249975972]},
    {input: [0.4828125, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.471875, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.3984375, 0.25], output: [0.067230071798602]},
    {input: [0.3703125, 0.35], output: [0.067230071798602]},
    {input: [0.2359375, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.275], output: [0.0898701185996068]},
    {input: [0.2578125, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.2859375, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.28125, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.421875, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.4046875, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.561111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.75], output: [0.0219499781965924]},
    {input: [0.3203125, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.666666666666667], output: [0.067230071798602]},
    {input: [0.225, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.65], output: [0.0445900249975972]},
    {input: [0.190625, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.625], output: [0.0445900249975972]},
    {input: [0.1515625, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.7], output: [0.0445900249975972]},
    {input: [0.2015625, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.0328125, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.1171875, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.275], output: [0.0219499781965924]},
    {input: [0.190625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.166666666666667], output: [-0.000690068604412431]},
    {input: [0.078125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.85], output: [0.0219499781965924]},
    {input: [0.078125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.825], output: [0.0219499781965924]},
    {input: [0.0109375, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.0109375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.575], output: [0.0219499781965924]},
    {input: [0.05, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.925], output: [-0.000690068604412431]},
    {input: [0.04375, 0.386111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.994444444444444], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.134375, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.791666666666667], output: [-0.000690068604412431]},
    {input: [0.21875, 0.780555555555556], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.8], output: [0.0219499781965924]},
    {input: [0.1234375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.75], output: [0.0219499781965924]},
    {input: [0.0953125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.75], output: [0.0219499781965924]},
    {input: [0.084375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.725], output: [0.0219499781965924]},
    {input: [0.084375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.775], output: [0.0219499781965924]},
    {input: [0.240625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.85], output: [0.0445900249975972]},
    {input: [0.184375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.8], output: [0.0219499781965924]},
    {input: [0.128125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.755555555555556], output: [-0.000690068604412431]},
    {input: [0.015625, 0.433333333333333], output: [-0.000690068604412431]},
    {input: [0.05, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.575], output: [0.0219499781965924]},
    {input: [0.05625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.569444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.5], output: [0.0219499781965924]},
    {input: [0.0671875, 0.630555555555556], output: [-0.000690068604412431]},
    {input: [0.15625, 0.388888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.444444444444444], output: [-0.000690068604412431]},
    {input: [0.140625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.6], output: [0.0219499781965924]},
    {input: [0.1, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.15], output: [0.0219499781965924]},
    {input: [0.2859375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.969444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.15], output: [0.0445900249975972]},
    {input: [0.1734375, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.1171875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.084375, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.1], output: [0.0219499781965924]},
    {input: [0.0890625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.45], output: [0.0219499781965924]},
    {input: [0.240625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.3484375, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.975], output: [0.0445900249975972]},
    {input: [0.078125, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.3484375, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.422222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.225], output: [0.0219499781965924]},
    {input: [0.26875, 0.275], output: [0.0445900249975972]},
    {input: [0.0328125, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.975], output: [0.0445900249975972]},
    {input: [0.1234375, 0.997222222222222], output: [0.0445900249975972]},
    {input: [0.071875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.319444444444444], output: [-0.000690068604412431]},
    {input: [0.49375, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.4375, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.4328125, 0.488888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.4328125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.50625, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.4046875, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.309375, 0.552777777777778], output: [0.067230071798602]},
    {input: [0.2859375, 0.569444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.525], output: [0.067230071798602]},
    {input: [0.2578125, 0.538888888888889], output: [0.0898701185996068]},
    {input: [0.3140625, 0.469444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.513888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.365625, 0.425], output: [0.0445900249975972]},
    {input: [0.2640625, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.2578125, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.49375, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.225], output: [0.067230071798602]},
    {input: [0.365625, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.365625, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.3765625, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.38125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.421875, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.421875, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.415625, 0.0805555555555556], output: [0.112510165400612]},
    {input: [0.39375, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.26875, 0.3], output: [0.0898701185996068]},
    {input: [0.2078125, 0.655555555555556], output: [0.0898701185996068]},
    {input: [0.084375, 0.627777777777778], output: [0.067230071798602]},
    {input: [0.04375, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.25], output: [0.0219499781965924]},
    {input: [0.2015625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.725], output: [0.0219499781965924]},
    {input: [0.2359375, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.240625, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.8], output: [0.0219499781965924]},
    {input: [0.10625, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.175], output: [0.0219499781965924]},
    {input: [0.0390625, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.725], output: [0.0219499781965924]},
    {input: [0.325, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.55], output: [0.0445900249975972]},
    {input: [0.078125, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.4], output: [0.0219499781965924]},
    {input: [0.1171875, 0.35], output: [0.0219499781965924]},
    {input: [0.0609375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.575], output: [0.0219499781965924]},
    {input: [0.140625, 0.608333333333333], output: [-0.000690068604412431]},
    {input: [0.2125, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.45], output: [0.0219499781965924]},
    {input: [0.078125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.38125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.28125, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.119444444444444], output: [-0.000690068604412431]},
    {input: [0.3984375, 0.108333333333333], output: [-0.000690068604412431]},
    {input: [0.3421875, 0.125], output: [0.0445900249975972]},
    {input: [0.3140625, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.125], output: [0.0445900249975972]},
    {input: [0.1796875, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.3984375, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.3484375, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.33125, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.5453125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.325, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.38125, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.2078125, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.1, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.071875, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.015625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.0046875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.680555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.644444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.625], output: [0.0219499781965924]},
    {input: [0.1625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.2578125, 0.25], output: [0.0219499781965924]},
    {input: [0.2359375, 0.263888888888889], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.3375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.275], output: [0.0219499781965924]},
    {input: [0.2859375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.361111111111111], output: [-0.000690068604412431]},
    {input: [0.184375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.213888888888889], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.26875, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.275, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.605555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.644444444444444], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.225, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.15], output: [0.0219499781965924]},
    {input: [0.26875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.175], output: [0.0219499781965924]},
    {input: [0.1625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.447222222222222], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.3], output: [-0.000690068604412431]},
    {input: [0.21875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.336111111111111], output: [-0.000690068604412431]},
    {input: [0.1125, 0.35], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.111111111111111], output: [-0.000690068604412431]},
    {input: [0.303125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.3203125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.225], output: [0.0445900249975972]},
    {input: [0.190625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.05625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.3421875, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.3703125, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.425], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.386111111111111], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.416666666666667], output: [-0.000690068604412431]},
    {input: [0.1125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.825], output: [0.0219499781965924]},
    {input: [0.05625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.65], output: [0.0219499781965924]},
    {input: [0.0953125, 0.719444444444444], output: [-0.000690068604412431]},
    {input: [0.10625, 0.641666666666667], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.128125, 0.686111111111111], output: [-0.000690068604412431]},
    {input: [0.1125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0], output: [0.0219499781965924]},
    {input: [0.1125, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.15], output: [0.0219499781965924]},
    {input: [0.1796875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.175], output: [0.0219499781965924]},
    {input: [0.16875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.455555555555556], output: [-0.000690068604412431]},
    {input: [0.10625, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.05], output: [0.0219499781965924]},
    {input: [0.134375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.3703125, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.421875, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.39375, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.8], output: [0.0898701185996068]},
    {input: [0.3375, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.28125, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.1171875, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.04375, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.05625, 0.7], output: [0.0445900249975972]},
    {input: [0.028125, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.05, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.134375, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.0390625, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.0671875, 0.519444444444444], output: [0.067230071798602]},
    {input: [0.140625, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.2078125, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.05, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.021875, 0.0416666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.3140625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.21875, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.128125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.015625, 0.0138888888888889], output: [0.0445900249975972]},
    {input: [0.0609375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.85], output: [0.0219499781965924]},
    {input: [0.071875, 0.775], output: [0.0219499781965924]},
    {input: [0.1234375, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.2296875, 0.9], output: [0.0445900249975972]},
    {input: [0.2125, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.359375, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.5, 0.8], output: [0.0445900249975972]},
    {input: [0.5390625, 0.8], output: [0.067230071798602]},
    {input: [0.5171875, 0.786111111111111], output: [0.0898701185996068]},
    {input: [0.5, 0.788888888888889], output: [0.112510165400612]},
    {input: [0.478125, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.421875, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.415625, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.3984375, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.365625, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.3765625, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.3140625, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.2578125, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.49375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.55, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.471875, 0.775], output: [0.112510165400612]},
    {input: [0.4828125, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.49375, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.44375, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.4265625, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.4328125, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.421875, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.421875, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.3484375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.296875, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.26875, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.275, 0.813888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.05625, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.875], output: [0.0898701185996068]},
    {input: [0.3875, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.3984375, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.4328125, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.421875, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.1171875, 0.825], output: [0.0445900249975972]},
    {input: [0.1, 0.880555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.075], output: [0.0219499781965924]},
    {input: [0.04375, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.108333333333333], output: [-0.000690068604412431]},
    {input: [0.16875, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.1], output: [0.0219499781965924]},
    {input: [0.1625, 0.0111111111111111], output: [0.0898701185996068]},
    {input: [0.246875, 0.0305555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.04375, 0.925], output: [0.0445900249975972]},
    {input: [0.0609375, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.10625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.225], output: [0.0219499781965924]},
    {input: [0.1125, 0.3], output: [0.0219499781965924]},
    {input: [0.084375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.15], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.05], output: [0.0219499781965924]},
    {input: [0.078125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.675], output: [-0.000690068604412431]},
    {input: [0.3484375, 0.8], output: [0.0219499781965924]},
    {input: [0.409375, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.38125, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.8], output: [0.067230071798602]},
    {input: [0.2296875, 0.8], output: [0.0898701185996068]},
    {input: [0.21875, 0.775], output: [0.067230071798602]},
    {input: [0.2859375, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.253125, 0.766666666666667], output: [0.0898701185996068]},
    {input: [0.33125, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.353125, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.246875, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.2078125, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.1796875, 0.644444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.638888888888889], output: [0.0898701185996068]},
    {input: [0.05, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.4609375, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.5625, 0.775], output: [0.0898701185996068]},
    {input: [0.534375, 0.777777777777778], output: [0.135150212201616]},
    {input: [0.4609375, 0.788888888888889], output: [0.135150212201616]},
    {input: [0.465625, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.50625, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.5453125, 0.783333333333333], output: [0.135150212201616]},
    {input: [0.534375, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.45, 0.775], output: [0.112510165400612]},
    {input: [0.478125, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.38125, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.415625, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.3984375, 0.816666666666667], output: [0.135150212201616]},
    {input: [0.253125, 0.811111111111111], output: [0.112510165400612]},
    {input: [0.2015625, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.409375, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.55], output: [0.0219499781965924]},
    {input: [0.1125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.775], output: [0.0219499781965924]},
    {input: [0.071875, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.605555555555556], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.1, 0.738888888888889], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.7], output: [0.0219499781965924]},
    {input: [0.1, 0.738888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.875], output: [0.0219499781965924]},
    {input: [0.2078125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.8], output: [0.0219499781965924]},
    {input: [0.3984375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.3140625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.75], output: [0.0219499781965924]},
    {input: [0.253125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.353125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.15625, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.716666666666667], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.8], output: [0.0219499781965924]},
    {input: [0.275, 0.775], output: [0.0445900249975972]},
    {input: [0.1734375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.15], output: [0.0219499781965924]},
    {input: [0.2078125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.184375, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.409375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.39375, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.44375, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.45, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.4375, 0.125], output: [0.0898701185996068]},
    {input: [0.3140625, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.125], output: [0.067230071798602]},
    {input: [0.39375, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.478125, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.45, 0.175], output: [0.112510165400612]},
    {input: [0.39375, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.3765625, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.409375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.3984375, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.359375, 0.188888888888889], output: [0.0898701185996068]},
    {input: [0.365625, 0.197222222222222], output: [0.0898701185996068]},
    {input: [0.33125, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.33125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.125], output: [0.0445900249975972]},
    {input: [0.196875, 0.125], output: [0.0445900249975972]},
    {input: [0.2859375, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.325], output: [0.0219499781965924]},
    {input: [0.1, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.175], output: [0.0219499781965924]},
    {input: [0.128125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.4046875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.4265625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.725], output: [0.0898701185996068]},
    {input: [0.465625, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.5171875, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.465625, 0.75], output: [0.135150212201616]},
    {input: [0.45, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.309375, 0.8], output: [0.112510165400612]},
    {input: [0.3421875, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.253125, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.134375, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.078125, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.084375, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.44375, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.478125, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.44375, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.5171875, 0.752777777777778], output: [0.112510165400612]},
    {input: [0.4890625, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.45, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.465625, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.45, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.44375, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.4046875, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.3703125, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.240625, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.21875, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.1453125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.125], output: [0.0219499781965924]},
    {input: [0.246875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.190625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.3484375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.5390625, 0.183333333333333], output: [0.0898701185996068]},
    {input: [0.5625, 0.166666666666667], output: [0.112510165400612]},
    {input: [0.4890625, 0.180555555555556], output: [0.112510165400612]},
    {input: [0.478125, 0.216666666666667], output: [0.112510165400612]},
    {input: [0.5171875, 0.205555555555556], output: [0.112510165400612]},
    {input: [0.55, 0.172222222222222], output: [0.112510165400612]},
    {input: [0.421875, 0.183333333333333], output: [0.112510165400612]},
    {input: [0.359375, 0.180555555555556], output: [0.112510165400612]},
    {input: [0.26875, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.1796875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.190625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.15], output: [0.0219499781965924]},
    {input: [0.10625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.175], output: [0.0219499781965924]},
    {input: [0.05, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.2], output: [0.0219499781965924]},
    {input: [0.2578125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.25], output: [0.0219499781965924]},
    {input: [0.15625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.15], output: [0.0219499781965924]},
    {input: [0.246875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.15], output: [0.0219499781965924]},
    {input: [0.084375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.125], output: [0.0219499781965924]},
    {input: [0.3421875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.175], output: [0.0219499781965924]},
    {input: [0.1171875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.725], output: [0.0219499781965924]},
    {input: [0.021875, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.021875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.471875, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.4609375, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.44375, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.4265625, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.786111111111111], output: [0.112510165400612]},
    {input: [0.4046875, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.275, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.1796875, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.021875, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.3484375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.1453125, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.140625, 0.00833333333333333], output: [0.067230071798602]},
    {input: [0.1515625, 0.05], output: [0.0445900249975972]},
    {input: [0.084375, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.0390625, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.028125, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.2], output: [0.0219499781965924]},
    {input: [0.21875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.075], output: [0.0445900249975972]},
    {input: [0.2359375, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.225], output: [0.0445900249975972]},
    {input: [0.140625, 0.225], output: [0.0219499781965924]},
    {input: [0.1625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.3203125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.2078125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.303125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.3140625, 0.136111111111111], output: [0.0898701185996068]},
    {input: [0.3484375, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.3875, 0.15], output: [0.067230071798602]},
    {input: [0.325, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.15], output: [0.0445900249975972]},
    {input: [0.3421875, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.3765625, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.3703125, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.353125, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.409375, 0.302777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.35], output: [0.0898701185996068]},
    {input: [0.296875, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.438888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.35], output: [0.0219499781965924]},
    {input: [0.225, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.35], output: [0.0219499781965924]},
    {input: [0.240625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.365625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.15], output: [0.0445900249975972]},
    {input: [0.3140625, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.65], output: [-0.000690068604412431]},
    {input: [0.1125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.05], output: [0.0219499781965924]},
    {input: [0.140625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.4], output: [-0.000690068604412431]},
    {input: [0.1, 0.388888888888889], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.641666666666667], output: [-0.000690068604412431]},
    {input: [0.190625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.7], output: [0.0219499781965924]},
    {input: [0.184375, 0.705555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.05], output: [0.0219499781965924]},
    {input: [0.1515625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.5], output: [0.0219499781965924]},
    {input: [0.26875, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.1796875, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.1625, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.0328125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.3140625, 0.175], output: [0.0445900249975972]},
    {input: [0.39375, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.3703125, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.3875, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.3703125, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.253125, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.486111111111111], output: [0.067230071798602]},
    {input: [0.240625, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.4046875, 0.725], output: [0.0445900249975972]},
    {input: [0.39375, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.140625, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.084375, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.025], output: [0.0219499781965924]},
    {input: [0.1515625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.2], output: [0.0219499781965924]},
    {input: [0.3375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.3484375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.2125, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.0390625, 0.525], output: [0.0445900249975972]},
    {input: [0.140625, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.3484375, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.33125, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.184375, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.625], output: [0.067230071798602]},
    {input: [0.2359375, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.225, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.184375, 0.675], output: [0.0445900249975972]},
    {input: [0.078125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.075], output: [0.0219499781965924]},
    {input: [0.1125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.3], output: [0.0219499781965924]},
    {input: [0.078125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.133333333333333], output: [-0.000690068604412431]},
    {input: [0.1625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.33125, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.15], output: [0.0445900249975972]},
    {input: [0.28125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.175], output: [0.0445900249975972]},
    {input: [0.2296875, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.1515625, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.3], output: [0.0219499781965924]},
    {input: [0.1125, 0.325], output: [-0.000690068604412431]},
    {input: [0.021875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.2], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.075], output: [0.0219499781965924]},
    {input: [0.1, 0], output: [0.0219499781965924]},
    {input: [0.10625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0], output: [0.0219499781965924]},
    {input: [0.16875, 0.175], output: [0.0219499781965924]},
    {input: [0.184375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.125], output: [0.0445900249975972]},
    {input: [0.1734375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.015625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.25], output: [0.0219499781965924]},
    {input: [0.078125, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.4890625, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.5109375, 0.736111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.5, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.465625, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.4328125, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.3984375, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.409375, 0.805555555555556], output: [0.112510165400612]},
    {input: [0.3203125, 0.813888888888889], output: [0.112510165400612]},
    {input: [0.2359375, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.184375, 0.8], output: [0.067230071798602]},
    {input: [0.0671875, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.015625, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.015625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.125], output: [0.0219499781965924]},
    {input: [0.2015625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.125], output: [0.0445900249975972]},
    {input: [0.1625, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.04375, 0.15], output: [0.0445900249975972]},
    {input: [0.0890625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.0416666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.0277777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.075], output: [0.0219499781965924]},
    {input: [0.078125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.95], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.075], output: [0.0219499781965924]},
    {input: [0.0953125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.128125, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.0390625, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.05, 0.625], output: [0.0445900249975972]},
    {input: [0.071875, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.05625, 0.5], output: [0.0219499781965924]},
    {input: [0.1234375, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.5], output: [0.0219499781965924]},
    {input: [0.0953125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.45, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.38125, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.421875, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.3984375, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.26875, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.240625, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.275, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.196875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.875], output: [0.0445900249975972]},
    {input: [0.1171875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.144444444444444], output: [-0.000690068604412431]},
    {input: [0.3203125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.0416666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.025], output: [0.0445900249975972]},
    {input: [0.2078125, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.00277777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.0222222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.175], output: [0.0219499781965924]},
    {input: [0.190625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.253125, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.28125, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.125], output: [0.0445900249975972]},
    {input: [0.1453125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.1], output: [0.0445900249975972]},
    {input: [0.0890625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.95], output: [0.0219499781965924]},
    {input: [0.04375, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.0109375, 0.411111111111111], output: [-0.000690068604412431]},
    {input: [0.10625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.421875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.4890625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.5109375, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.5, 0.763888888888889], output: [0.112510165400612]},
    {input: [0.4828125, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.44375, 0.75], output: [0.112510165400612]},
    {input: [0.45, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.4828125, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.421875, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.3703125, 0.802777777777778], output: [0.112510165400612]},
    {input: [0.3375, 0.8], output: [0.112510165400612]},
    {input: [0.325, 0.8], output: [0.0898701185996068]},
    {input: [0.2921875, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.140625, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.028125, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.175], output: [0.0445900249975972]},
    {input: [0.2015625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.175], output: [0.067230071798602]},
    {input: [0.2359375, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.0609375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.0609375, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.225, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.084375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.04375, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.0671875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.0390625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.125], output: [0.067230071798602]},
    {input: [0.3203125, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.196875, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.1625, 0.0972222222222222], output: [0.067230071798602]},
    {input: [0.325, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.2640625, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.05, 0.175], output: [0.0445900249975972]},
    {input: [0.0609375, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.028125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.3375, 0.75], output: [0.0898701185996068]},
    {input: [0.2015625, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.140625, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.0671875, 0.861111111111111], output: [0.0898701185996068]},
    {input: [0.1234375, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.1125, 0.075], output: [0.0445900249975972]},
    {input: [0.196875, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.134375, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.0138888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.3703125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.4046875, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.4265625, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.4828125, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.3875, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.4046875, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.3984375, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.4046875, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.2], output: [0.0445900249975972]},
    {input: [0.4828125, 0.569444444444444], output: [0.067230071798602]},
    {input: [0.50625, 0.633333333333333], output: [0.067230071798602]},
    {input: [0.534375, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.50625, 0.708333333333333], output: [0.067230071798602]},
    {input: [0.421875, 0.675], output: [0.0898701185996068]},
    {input: [0.309375, 0.677777777777778], output: [0.135150212201616]},
    {input: [0.359375, 0.669444444444444], output: [0.135150212201616]},
    {input: [0.2859375, 0.647222222222222], output: [0.135150212201616]},
    {input: [0.421875, 0.691666666666667], output: [0.112510165400612]},
    {input: [0.421875, 0.661111111111111], output: [0.112510165400612]},
    {input: [0.421875, 0.663888888888889], output: [0.112510165400612]},
    {input: [0.3984375, 0.691666666666667], output: [0.112510165400612]},
    {input: [0.4046875, 0.694444444444444], output: [0.135150212201616]},
    {input: [0.409375, 0.713888888888889], output: [0.112510165400612]},
    {input: [0.409375, 0.686111111111111], output: [0.0898701185996068]},
    {input: [0.2921875, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.3703125, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.246875, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.240625, 0.844444444444444], output: [0.0898701185996068]},
    {input: [0.078125, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.1625, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.3203125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.25], output: [0.0445900249975972]},
    {input: [0.309375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.0609375, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.0109375, 0.3], output: [0.0219499781965924]},
    {input: [0.0390625, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.4], output: [0.0219499781965924]},
    {input: [0.0390625, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.75], output: [0.0219499781965924]},
    {input: [0.0890625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.366666666666667], output: [-0.000690068604412431]},
    {input: [0.3421875, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.3703125, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.4375, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.3875, 0.8], output: [0.0898701185996068]},
    {input: [0.303125, 0.825], output: [0.0898701185996068]},
    {input: [0.2296875, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.1171875, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.04375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.078125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.2578125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.4609375, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.4328125, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.3984375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.4328125, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.421875, 0.8], output: [0.067230071798602]},
    {input: [0.4609375, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.4890625, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.50625, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.45, 0.797222222222222], output: [0.135150212201616]},
    {input: [0.4328125, 0.805555555555556], output: [0.112510165400612]},
    {input: [0.4328125, 0.822222222222222], output: [0.112510165400612]},
    {input: [0.325, 0.85], output: [0.0898701185996068]},
    {input: [0.4328125, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.2125, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.49375, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.590625, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.61875, 0.763888888888889], output: [0.112510165400612]},
    {input: [0.60625, 0.766666666666667], output: [0.112510165400612]},
    {input: [0.6125, 0.772222222222222], output: [0.112510165400612]},
    {input: [0.6125, 0.769444444444444], output: [0.135150212201616]},
    {input: [0.5625, 0.775], output: [0.135150212201616]},
    {input: [0.55, 0.769444444444444], output: [0.135150212201616]},
    {input: [0.50625, 0.775], output: [0.135150212201616]},
    {input: [0.521875, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.4609375, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.5, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.4546875, 0.780555555555556], output: [0.112510165400612]},
    {input: [0.44375, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.4609375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.4546875, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.3984375, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.675], output: [-0.000690068604412431]},
    {input: [0.05625, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.630555555555556], output: [-0.000690068604412431]},
    {input: [0.05, 0.772222222222222], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.705555555555556], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.65], output: [0.0219499781965924]},
    {input: [0.16875, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.15], output: [0.0219499781965924]},
    {input: [0.21875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.15625, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.2], output: [0.0219499781965924]},
    {input: [0.303125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.3421875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.15], output: [0.067230071798602]},
    {input: [0.309375, 0.175], output: [0.067230071798602]},
    {input: [0.2859375, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.225, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.375], output: [0.0219499781965924]},
    {input: [0.0328125, 0.25], output: [0.0219499781965924]},
    {input: [0.0890625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.1], output: [0.0219499781965924]},
    {input: [0.1125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.725], output: [0.0219499781965924]},
    {input: [0.353125, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.675], output: [0.0445900249975972]},
    {input: [0.2640625, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.39375, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.44375, 0.719444444444444], output: [0.0445900249975972]},
    {input: [0.471875, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.4546875, 0.713888888888889], output: [0.112510165400612]},
    {input: [0.465625, 0.702777777777778], output: [0.135150212201616]},
    {input: [0.521875, 0.713888888888889], output: [0.135150212201616]},
    {input: [0.4546875, 0.713888888888889], output: [0.135150212201616]},
    {input: [0.4609375, 0.727777777777778], output: [0.135150212201616]},
    {input: [0.421875, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.39375, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.415625, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.3421875, 0.822222222222222], output: [0.0898701185996068]},
    {input: [0.2015625, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.0890625, 0.925], output: [0.067230071798602]},
    {input: [0.028125, 0.0527777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.2296875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.33125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.125], output: [0.0445900249975972]},
    {input: [0.2359375, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.2], output: [0.0219499781965924]},
    {input: [0.1515625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.775], output: [0.0219499781965924]},
    {input: [0.2125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.0390625, 0.6], output: [0.0445900249975972]},
    {input: [0.1171875, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.021875, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.4046875, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.4546875, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.4375, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.3375, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.26875, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.5453125, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.534375, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.44375, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.4546875, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.415625, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.2640625, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.2015625, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.128125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.4], output: [0.0219499781965924]},
    {input: [0.1515625, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.353125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.44375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.4265625, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.471875, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.4375, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.365625, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.253125, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.125], output: [0.0219499781965924]},
    {input: [0.184375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.175], output: [0.0219499781965924]},
    {input: [0.071875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.25], output: [0.0219499781965924]},
    {input: [0.0953125, 0.444444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.452777777777778], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.433333333333333], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.45], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.519444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.494444444444444], output: [-0.000690068604412431]},
    {input: [0.021875, 0.0694444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.225, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.1171875, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.421875, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.519444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.680555555555556], output: [0.067230071798602]},
    {input: [0.3984375, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.38125, 0.644444444444444], output: [0.0898701185996068]},
    {input: [0.33125, 0.702777777777778], output: [0.112510165400612]},
    {input: [0.275, 0.622222222222222], output: [0.112510165400612]},
    {input: [0.1515625, 0.644444444444444], output: [0.112510165400612]},
    {input: [0.16875, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.0328125, 0.575], output: [0.0898701185996068]},
    {input: [0.225, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.3375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.8], output: [0.0219499781965924]},
    {input: [0.1796875, 0.05], output: [0.0219499781965924]},
    {input: [0.4546875, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.15625, 0.0333333333333333], output: [0.0898701185996068]},
    {input: [0.1453125, 0.0166666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.1453125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.0111111111111111], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.0277777777777778], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.511111111111111], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.16875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.8], output: [0.0219499781965924]},
    {input: [0.2015625, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.725], output: [0.0219499781965924]},
    {input: [0.0953125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.583333333333333], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.638888888888889], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.125], output: [0.0219499781965924]},
    {input: [0.2296875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.45], output: [0.0219499781965924]},
    {input: [0.225, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.35], output: [0.0445900249975972]},
    {input: [0.2359375, 0.3], output: [0.0445900249975972]},
    {input: [0.3484375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.421875, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.3375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.415625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.3421875, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.3421875, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.26875, 0.225], output: [0.067230071798602]},
    {input: [0.3203125, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.309375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.2921875, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.15625, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.85], output: [0.0219499781965924]},
    {input: [0.0890625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.575], output: [0.0219499781965924]},
    {input: [0.21875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.2859375, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.725], output: [0.067230071798602]},
    {input: [0.2125, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.10625, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.05625, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.071875, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.625], output: [0.0219499781965924]},
    {input: [0.078125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.0609375, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.4046875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.4265625, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.4046875, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.3703125, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.39375, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.409375, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.2921875, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.184375, 0.766666666666667], output: [0.0898701185996068]},
    {input: [0.2078125, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.078125, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.078125, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.015625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.0109375, 0.1], output: [0.0219499781965924]},
    {input: [0.05, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.359375, 0.775], output: [0.0898701185996068]},
    {input: [0.296875, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.26875, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.461111111111111], output: [0.067230071798602]},
    {input: [0.2296875, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.15625, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.986111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.5625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.3765625, 0.730555555555556], output: [0.067230071798602]},
    {input: [0.21875, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.2578125, 0.7], output: [0.0445900249975972]},
    {input: [0.28125, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.7], output: [0.067230071798602]},
    {input: [0.2015625, 0.611111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.028125, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.10625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.1], output: [0.0219499781965924]},
    {input: [0.2640625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.1171875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.0328125, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.8], output: [0.0219499781965924]},
    {input: [0.16875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.45, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.5453125, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.528125, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.45, 0.775], output: [0.112510165400612]},
    {input: [0.478125, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.415625, 0.802777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.359375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.28125, 0.8], output: [0.067230071798602]},
    {input: [0.196875, 0.813888888888889], output: [0.067230071798602]},
    {input: [0.0953125, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.028125, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.078125, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.225, 0.15], output: [0.0219499781965924]},
    {input: [0.253125, 0.175], output: [0.0219499781965924]},
    {input: [0.225, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.0328125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.65], output: [0.0219499781965924]},
    {input: [0.134375, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.275], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.16875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.3140625, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.1796875, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.071875, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.275, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.15], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.075], output: [-0.000690068604412431]},
    {input: [0.071875, 0.125], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.196875, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.196875, 0.636111111111111], output: [-0.000690068604412431]},
    {input: [0.184375, 0.788888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.863888888888889], output: [-0.000690068604412431]},
    {input: [0.10625, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.855555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.163888888888889], output: [-0.000690068604412431]},
    {input: [0.246875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.190625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.725], output: [0.0219499781965924]},
    {input: [0.05625, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.028125, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.625], output: [0.0219499781965924]},
    {input: [0.084375, 0.65], output: [0.0219499781965924]},
    {input: [0.128125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.775], output: [0.0445900249975972]},
    {input: [0.2578125, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.078125, 0.975], output: [0.0445900249975972]},
    {input: [0.1, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.15], output: [0.0445900249975972]},
    {input: [0.1, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.084375, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.175], output: [0.0219499781965924]},
    {input: [0.1, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.3765625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.25], output: [0.0219499781965924]},
    {input: [0.1125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.175], output: [0.0219499781965924]},
    {input: [0.1796875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.230555555555556], output: [-0.000690068604412431]},
    {input: [0.15625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.25], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.169444444444444], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.125], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.758333333333333], output: [-0.000690068604412431]},
    {input: [0.05, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.533333333333333], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.35], output: [0.0219499781965924]},
    {input: [0.028125, 0.361111111111111], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0638888888888889], output: [-0.000690068604412431]},
    {input: [0, 0.333333333333333], output: [-0.000690068604412431]},
    {input: [0.1625, 0.00833333333333333], output: [-0.000690068604412431]},
    {input: [0.071875, 0.25], output: [-0.000690068604412431]},
    {input: [0.078125, 0.7], output: [0.0219499781965924]},
    {input: [0.1625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.1], output: [0.0219499781965924]},
    {input: [0.1234375, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.275], output: [0.0219499781965924]},
    {input: [0.21875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.309375, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.125], output: [0.0445900249975972]},
    {input: [0.3203125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.4265625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.3875, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.2296875, 0.225], output: [0.067230071798602]},
    {input: [0.196875, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.175], output: [0.0219499781965924]},
    {input: [0.1453125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.478125, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.7], output: [0.067230071798602]},
    {input: [0.275, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.28125, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.15625, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.38125, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.415625, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.3140625, 0.775], output: [0.112510165400612]},
    {input: [0.2921875, 0.775], output: [0.0898701185996068]},
    {input: [0.1125, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.0609375, 0.325], output: [0.067230071798602]},
    {input: [0.1796875, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.2], output: [0.0219499781965924]},
    {input: [0.0671875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.65], output: [0.0219499781965924]},
    {input: [0.2578125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.359375, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.184375, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.1125, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.225], output: [0.0219499781965924]},
    {input: [0.1234375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.2], output: [0.0219499781965924]},
    {input: [0.2015625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.55], output: [0.0219499781965924]},
    {input: [0.353125, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.4546875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.5390625, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.55625, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.5453125, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.6015625, 0.672222222222222], output: [0.135150212201616]},
    {input: [0.478125, 0.644444444444444], output: [0.135150212201616]},
    {input: [0.45, 0.666666666666667], output: [0.135150212201616]},
    {input: [0.4265625, 0.658333333333333], output: [0.157790259002621]},
    {input: [0.49375, 0.680555555555556], output: [0.135150212201616]},
    {input: [0.5, 0.7], output: [0.157790259002621]},
    {input: [0.521875, 0.730555555555556], output: [0.180430305803626]},
    {input: [0.590625, 0.758333333333333], output: [0.180430305803626]},
    {input: [0.471875, 0.766666666666667], output: [0.180430305803626]},
    {input: [0.3421875, 0.727777777777778], output: [0.157790259002621]},
    {input: [0.365625, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.3484375, 0.708333333333333], output: [0.135150212201616]},
    {input: [0.38125, 0.661111111111111], output: [0.112510165400612]},
    {input: [0.4046875, 0.644444444444444], output: [0.112510165400612]},
    {input: [0.4046875, 0.655555555555556], output: [0.0898701185996068]},
    {input: [0.4328125, 0.65], output: [0.0898701185996068]},
    {input: [0.4828125, 0.688888888888889], output: [0.0898701185996068]},
    {input: [0.49375, 0.772222222222222], output: [0.112510165400612]},
    {input: [0.5625, 0.794444444444444], output: [0.135150212201616]},
    {input: [0.275, 0.0166666666666667], output: [0.157790259002621]},
    {input: [0.1734375, 0.958333333333333], output: [0.112510165400612]},
    {input: [0.084375, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.0953125, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.2125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.325], output: [0.067230071798602]},
    {input: [0.240625, 0.3], output: [0.0445900249975972]},
    {input: [0.2640625, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.275], output: [0.0445900249975972]},
    {input: [0.2921875, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.05, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.021875, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.075], output: [0.0219499781965924]},
    {input: [0.05625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.447222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.325], output: [0.0219499781965924]},
    {input: [0.0390625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.125], output: [0.0219499781965924]},
    {input: [0.5390625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.5, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.5953125, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.5671875, 0.194444444444444], output: [0.135150212201616]},
    {input: [0.584375, 0.2], output: [0.157790259002621]},
    {input: [0.60625, 0.227777777777778], output: [0.180430305803626]},
    {input: [0.6515625, 0.236111111111111], output: [0.180430305803626]},
    {input: [0.6296875, 0.238888888888889], output: [0.203070352604631]},
    {input: [0.675, 0.219444444444444], output: [0.203070352604631]},
    {input: [0.83125, 0.216666666666667], output: [0.180430305803626]},
    {input: [0.6859375, 0.280555555555556], output: [0.225710399405636]},
    {input: [0.5453125, 0.291666666666667], output: [0.180430305803626]},
    {input: [0.39375, 0.277777777777778], output: [0.157790259002621]},
    {input: [0.3875, 0.441666666666667], output: [0.135150212201616]},
    {input: [0.2640625, 0.380555555555556], output: [0.112510165400612]},
    {input: [0.253125, 0.419444444444444], output: [0.0898701185996068]},
    {input: [0.2640625, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.3484375, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.4609375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.4328125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.45, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.3875, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.3140625, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.3484375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.275, 0.15], output: [0.067230071798602]},
    {input: [0.1734375, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.25], output: [0.0219499781965924]},
    {input: [0.26875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.3], output: [0.0219499781965924]},
    {input: [0.240625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.3421875, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.246875, 0.25], output: [0.067230071798602]},
    {input: [0.078125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.675], output: [0.0219499781965924]},
    {input: [0.0953125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.5], output: [0.0219499781965924]},
    {input: [0.0890625, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.15], output: [0.0219499781965924]},
    {input: [0.078125, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.372222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.425], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.375], output: [0.0219499781965924]},
    {input: [0.0671875, 0.325], output: [-0.000690068604412431]},
    {input: [0.028125, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.725], output: [0.0219499781965924]},
    {input: [0.1171875, 0.75], output: [0.0219499781965924]},
    {input: [0.0609375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.35], output: [0.0219499781965924]},
    {input: [0.0671875, 0.225], output: [0.0219499781965924]},
    {input: [0.1, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.694444444444444], output: [-0.000690068604412431]},
    {input: [0.409375, 0.736111111111111], output: [-0.000690068604412431]},
    {input: [0.39375, 0.75], output: [0.0219499781965924]},
    {input: [0.246875, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.296875, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.705555555555556], output: [0.067230071798602]},
    {input: [0.2296875, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.719444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.196875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.184375, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.28125, 0.2], output: [0.0445900249975972]},
    {input: [0.0890625, 0.375], output: [0.0445900249975972]},
    {input: [0.1515625, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.140625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.125], output: [0.0219499781965924]},
    {input: [0.10625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.1], output: [0.0219499781965924]},
    {input: [0.0328125, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.775], output: [0.0219499781965924]},
    {input: [0.16875, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.26875, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.015625, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.015625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.1, 0.586111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.677777777777778], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.2], output: [-0.000690068604412431]},
    {input: [0.16875, 0.236111111111111], output: [-0.000690068604412431]},
    {input: [0.184375, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.196875, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.35], output: [0.0219499781965924]},
    {input: [0.2578125, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.45, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.225], output: [0.067230071798602]},
    {input: [0.359375, 0.175], output: [0.0445900249975972]},
    {input: [0.365625, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.3140625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.365625, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.5671875, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.5625, 0.0944444444444444], output: [0.112510165400612]},
    {input: [0.4609375, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.4375, 0.25], output: [0.112510165400612]},
    {input: [0.5390625, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.55, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.521875, 0.330555555555556], output: [0.112510165400612]},
    {input: [0.5453125, 0.480555555555556], output: [0.135150212201616]},
    {input: [0.1171875, 0.486111111111111], output: [0.112510165400612]},
    {input: [0.246875, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.21875, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.3375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.39375, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.325, 0.25], output: [0.0898701185996068]},
    {input: [0.4375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.5, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.4046875, 0.225], output: [0.0898701185996068]},
    {input: [0.39375, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.184375, 0.508333333333333], output: [0.067230071798602]},
    {input: [0.2578125, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.2640625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.275, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.44375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.44375, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.45, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.4609375, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.409375, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.4328125, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.4265625, 0.288888888888889], output: [0.0898701185996068]},
    {input: [0.49375, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.415625, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.38125, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.2921875, 0.452777777777778], output: [0.0898701185996068]},
    {input: [0.196875, 0.45], output: [0.067230071798602]},
    {input: [0.05625, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.838888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.9], output: [0.0445900249975972]},
    {input: [0.0671875, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.4890625, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.478125, 0.5], output: [0.0445900249975972]},
    {input: [0.421875, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.4265625, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.409375, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.421875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.409375, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.3984375, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.3984375, 0.197222222222222], output: [0.0898701185996068]},
    {input: [0.471875, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.534375, 0.175], output: [0.0898701185996068]},
    {input: [0.5625, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.5953125, 0.15], output: [0.135150212201616]},
    {input: [0.6515625, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.55625, 0.163888888888889], output: [0.180430305803626]},
    {input: [0.4546875, 0.141666666666667], output: [0.157790259002621]},
    {input: [0.4828125, 0.119444444444444], output: [0.135150212201616]},
    {input: [0.478125, 0.111111111111111], output: [0.135150212201616]},
    {input: [0.4609375, 0.147222222222222], output: [0.112510165400612]},
    {input: [0.5171875, 0.138888888888889], output: [0.112510165400612]},
    {input: [0.478125, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.4609375, 0.163888888888889], output: [0.135150212201616]},
    {input: [0.5671875, 0.191666666666667], output: [0.135150212201616]},
    {input: [0.4328125, 0.172222222222222], output: [0.157790259002621]},
    {input: [0.5, 0.186111111111111], output: [0.135150212201616]},
    {input: [0.4265625, 0.197222222222222], output: [0.135150212201616]},
    {input: [0.6234375, 0.275], output: [0.112510165400612]},
    {input: [0.634375, 0.288888888888889], output: [0.135150212201616]},
    {input: [0.5625, 0.291666666666667], output: [0.157790259002621]},
    {input: [0.640625, 0.286111111111111], output: [0.180430305803626]},
    {input: [0.5109375, 0.313888888888889], output: [0.180430305803626]},
    {input: [0.415625, 0.302777777777778], output: [0.180430305803626]},
    {input: [0.2578125, 0.363888888888889], output: [0.180430305803626]},
    {input: [0.421875, 0.436111111111111], output: [0.135150212201616]},
    {input: [0.3140625, 0.436111111111111], output: [0.112510165400612]},
    {input: [0.365625, 0.463888888888889], output: [0.0898701185996068]},
    {input: [0.415625, 0.438888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.409375, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.4609375, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.4265625, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.2578125, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.0671875, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.925], output: [0.0219499781965924]},
    {input: [0.10625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.113888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.175], output: [0.0219499781965924]},
    {input: [0.140625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.3375, 0.2], output: [0.0219499781965924]},
    {input: [0.190625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.3484375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.3703125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.125], output: [0.0445900249975972]},
    {input: [0.296875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.421875, 0.275], output: [0.0445900249975972]},
    {input: [0.365625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.175], output: [0.0445900249975972]},
    {input: [0.3765625, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.39375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.05, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.4265625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.4546875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.4609375, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.49375, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.49375, 0.75], output: [0.0898701185996068]},
    {input: [0.471875, 0.783333333333333], output: [0.112510165400612]},
    {input: [0.3875, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.353125, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.2859375, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.275, 0.744444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.140625, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.05625, 0.525], output: [0.0445900249975972]},
    {input: [0.190625, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.7], output: [0.0219499781965924]},
    {input: [0.196875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.7], output: [0.0445900249975972]},
    {input: [0.21875, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.3703125, 0.722222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.725], output: [0.067230071798602]},
    {input: [0.2578125, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.21875, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.225, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.16875, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.925], output: [0.0219499781965924]},
    {input: [0.15625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.55, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.38125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.6125, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.6515625, 0.433333333333333], output: [0.112510165400612]},
    {input: [0.7078125, 0.569444444444444], output: [0.157790259002621]},
    {input: [0.5953125, 0.569444444444444], output: [0.135150212201616]},
    {input: [0.353125, 0.516666666666667], output: [0.135150212201616]},
    {input: [0.365625, 0.505555555555556], output: [0.0898701185996068]},
    {input: [0.2359375, 0.516666666666667], output: [0.0898701185996068]},
    {input: [0.246875, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.4265625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.44375, 0.0972222222222222], output: [0.067230071798602]},
    {input: [0.50625, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.471875, 0.0916666666666667], output: [0.0898701185996068]},
    {input: [0.5109375, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.303125, 0.363888888888889], output: [0.112510165400612]},
    {input: [0.33125, 0.294444444444444], output: [0.0898701185996068]},
    {input: [0.3140625, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.625], output: [0.0445900249975972]},
    {input: [0.0953125, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.425], output: [0.0219499781965924]},
    {input: [0.0390625, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.7], output: [0.0219499781965924]},
    {input: [0.1171875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.566666666666667], output: [-0.000690068604412431]},
    {input: [0.21875, 0.552777777777778], output: [-0.000690068604412431]},
    {input: [0.16875, 0.555555555555556], output: [-0.000690068604412431]},
    {input: [0.134375, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.10625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.0805555555555556], output: [-0.000690068604412431]},
    {input: [0.1125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.0777777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.0194444444444444], output: [-0.000690068604412431]},
    {input: [0.1515625, 0], output: [0.0219499781965924]},
    {input: [0.15625, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0109375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.325], output: [0.0219499781965924]},
    {input: [0.084375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.325], output: [0.0219499781965924]},
    {input: [0.0953125, 0.297222222222222], output: [-0.000690068604412431]},
    {input: [0.1, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.225, 0.119444444444444], output: [-0.000690068604412431]},
    {input: [0.2125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.3375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.409375, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.38125, 0.225], output: [0.0445900249975972]},
    {input: [0.4375, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.4546875, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.465625, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.5390625, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.4890625, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.4046875, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.4328125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.49375, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.286111111111111], output: [0.0898701185996068]},
    {input: [0.4546875, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.4890625, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.240625, 0.558333333333333], output: [0.0898701185996068]},
    {input: [0.6859375, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.5453125, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.4265625, 0.677777777777778], output: [0.135150212201616]},
    {input: [0.365625, 0.647222222222222], output: [0.112510165400612]},
    {input: [0.33125, 0.655555555555556], output: [0.0898701185996068]},
    {input: [0.309375, 0.613888888888889], output: [0.0898701185996068]},
    {input: [0.2859375, 0.577777777777778], output: [0.0898701185996068]},
    {input: [0.225, 0.627777777777778], output: [0.067230071798602]},
    {input: [0.2296875, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.21875, 0.536111111111111], output: [0.067230071798602]},
    {input: [0.140625, 0.591666666666667], output: [0.067230071798602]},
    {input: [0.0953125, 0.725], output: [0.0445900249975972]},
    {input: [0.1453125, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.4609375, 0.725], output: [0.0219499781965924]},
    {input: [0.33125, 0.65], output: [0.0445900249975972]},
    {input: [0.38125, 0.6], output: [0.067230071798602]},
    {input: [0.4375, 0.622222222222222], output: [0.0898701185996068]},
    {input: [0.421875, 0.627777777777778], output: [0.0898701185996068]},
    {input: [0.44375, 0.65], output: [0.0898701185996068]},
    {input: [0.39375, 0.65], output: [0.112510165400612]},
    {input: [0.3984375, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.4609375, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.4375, 0.683333333333333], output: [0.112510165400612]},
    {input: [0.4265625, 0.691666666666667], output: [0.112510165400612]},
    {input: [0.415625, 0.7], output: [0.0898701185996068]},
    {input: [0.4046875, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.33125, 0.705555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.705555555555556], output: [0.0898701185996068]},
    {input: [0.33125, 0.719444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.296875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.2640625, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.21875, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.196875, 0.8], output: [0.0445900249975972]},
    {input: [0.184375, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.825], output: [0.0445900249975972]},
    {input: [0.1, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.9], output: [0.0219499781965924]},
    {input: [0.05625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.138888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.280555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.275, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.3140625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.3984375, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.4046875, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.4328125, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.3765625, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.2], output: [0.0898701185996068]},
    {input: [0.646875, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.646875, 0.152777777777778], output: [0.157790259002621]},
    {input: [0.6515625, 0.158333333333333], output: [0.203070352604631]},
    {input: [0.634375, 0.133333333333333], output: [0.225710399405636]},
    {input: [0.584375, 0.0305555555555556], output: [0.180430305803626]},
    {input: [0.5390625, 0.0527777777777778], output: [0.157790259002621]},
    {input: [0.50625, 0.0277777777777778], output: [0.135150212201616]},
    {input: [0.465625, 0.994444444444444], output: [0.135150212201616]},
    {input: [0.38125, 0.966666666666667], output: [0.112510165400612]},
    {input: [0.309375, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.1515625, 0.925], output: [0.067230071798602]},
    {input: [0.05, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.2125, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.591666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.0328125, 0.875], output: [0.0219499781965924]},
    {input: [0.078125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.25], output: [0.0219499781965924]},
    {input: [0.28125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.3375, 0.3], output: [0.0219499781965924]},
    {input: [0.3140625, 0.225], output: [0.0445900249975972]},
    {input: [0.303125, 0.175], output: [0.0445900249975972]},
    {input: [0.3765625, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.415625, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.4328125, 0.0777777777777778], output: [0.067230071798602]},
    {input: [0.44375, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.4265625, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.4609375, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.4546875, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.465625, 0.0833333333333333], output: [0.0898701185996068]},
    {input: [0.3984375, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.359375, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.39375, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.0888888888888889], output: [0.0898701185996068]},
    {input: [0.33125, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.2921875, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.3140625, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.409375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.353125, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.45, 0.2], output: [0.067230071798602]},
    {input: [0.4375, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.365625, 0.188888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.353125, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.25], output: [0.067230071798602]},
    {input: [0.5109375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.409375, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.3765625, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.3875, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.2859375, 0.161111111111111], output: [0.0898701185996068]},
    {input: [0.325, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.4046875, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.365625, 0.225], output: [0.0898701185996068]},
    {input: [0.471875, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.45, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.359375, 0.172222222222222], output: [0.135150212201616]},
    {input: [0.471875, 0.191666666666667], output: [0.112510165400612]},
    {input: [0.4328125, 0.169444444444444], output: [0.112510165400612]},
    {input: [0.465625, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.521875, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.5671875, 0.261111111111111], output: [0.135150212201616]},
    {input: [0.50625, 0.266666666666667], output: [0.157790259002621]},
    {input: [0.4265625, 0.255555555555556], output: [0.135150212201616]},
    {input: [0.471875, 0.258333333333333], output: [0.135150212201616]},
    {input: [0.3984375, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.45, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.4546875, 0.263888888888889], output: [0.112510165400612]},
    {input: [0.5171875, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.2859375, 0.366666666666667], output: [0.0898701185996068]},
    {input: [0.2078125, 0.85], output: [0.067230071798602]},
    {input: [0.1625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.225], output: [0.0219499781965924]},
    {input: [0.0671875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.2], output: [0.0219499781965924]},
    {input: [0.134375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.255555555555556], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.213888888888889], output: [-0.000690068604412431]},
    {input: [0.240625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.275], output: [0.0219499781965924]},
    {input: [0.415625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.421875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.4890625, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.534375, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.45, 0.727777777777778], output: [0.112510165400612]},
    {input: [0.3703125, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.38125, 0.683333333333333], output: [0.112510165400612]},
    {input: [0.3765625, 0.705555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.3375, 0.686111111111111], output: [0.0898701185996068]},
    {input: [0.3375, 0.7], output: [0.0898701185996068]},
    {input: [0.28125, 0.669444444444444], output: [0.0898701185996068]},
    {input: [0.2921875, 0.683333333333333], output: [0.0898701185996068]},
    {input: [0.2859375, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.325, 0.702777777777778], output: [0.067230071798602]},
    {input: [0.2125, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.184375, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.1625, 0.625], output: [0.067230071798602]},
    {input: [0.084375, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.15625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.158333333333333], output: [-0.000690068604412431]},
    {input: [0.15625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.0328125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.675], output: [0.0219499781965924]},
    {input: [0.1171875, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.736111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.175], output: [0.0219499781965924]},
    {input: [0.3140625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.075], output: [0.0219499781965924]},
    {input: [0.3484375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.4546875, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.4375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.353125, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.28125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.225], output: [0.0219499781965924]},
    {input: [0.365625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.3421875, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.102777777777778], output: [0.0898701185996068]},
    {input: [0.465625, 0.0916666666666667], output: [0.0898701185996068]},
    {input: [0.39375, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.3703125, 0.175], output: [0.067230071798602]},
    {input: [0.3375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.15625, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.365625, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.2859375, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.359375, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.415625, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.2640625, 0.177777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.478125, 0.15], output: [0.112510165400612]},
    {input: [0.4890625, 0.133333333333333], output: [0.157790259002621]},
    {input: [0.578125, 0.108333333333333], output: [0.157790259002621]},
    {input: [0.3875, 0.219444444444444], output: [0.135150212201616]},
    {input: [0.4375, 0.252777777777778], output: [0.157790259002621]},
    {input: [0.1234375, 0.577777777777778], output: [0.0898701185996068]},
    {input: [0.2359375, 0.0527777777777778], output: [0.067230071798602]},
    {input: [0.365625, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.4546875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.2359375, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.471875, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.359375, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.409375, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.26875, 0.25], output: [0.0898701185996068]},
    {input: [0.3421875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.325], output: [0.0445900249975972]},
    {input: [0.2125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.25], output: [0.0219499781965924]},
    {input: [0.15625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.35], output: [0.0219499781965924]},
    {input: [0.303125, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.275], output: [0.0219499781965924]},
    {input: [0.3140625, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.303125, 0.2], output: [0.0219499781965924]},
    {input: [0.3984375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.3765625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.421875, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.421875, 0.0916666666666667], output: [0.0898701185996068]},
    {input: [0.4375, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.465625, 0.108333333333333], output: [0.0898701185996068]},
    {input: [0.4546875, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.55, 0.127777777777778], output: [0.0898701185996068]},
    {input: [0.521875, 0.119444444444444], output: [0.112510165400612]},
    {input: [0.465625, 0.111111111111111], output: [0.135150212201616]},
    {input: [0.3984375, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.38125, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.39375, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.365625, 0.0805555555555556], output: [0.0898701185996068]},
    {input: [0.3140625, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.3140625, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.415625, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.45, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.3765625, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.465625, 0.0555555555555556], output: [0.067230071798602]},
    {input: [0.4609375, 0.0611111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.5953125, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.478125, 0.116666666666667], output: [0.112510165400612]},
    {input: [0.578125, 0.136111111111111], output: [0.112510165400612]},
    {input: [0.590625, 0.0777777777777778], output: [0.112510165400612]},
    {input: [0.584375, 0.0527777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.111111111111111], output: [0.135150212201616]},
    {input: [0.521875, 0.238888888888889], output: [0.135150212201616]},
    {input: [0.55, 0.141666666666667], output: [0.135150212201616]},
    {input: [0.55, 0.122222222222222], output: [0.135150212201616]},
    {input: [0.55625, 0.161111111111111], output: [0.135150212201616]},
    {input: [0.5671875, 0.211111111111111], output: [0.135150212201616]},
    {input: [0.196875, 0.325], output: [0.157790259002621]},
    {input: [0.3875, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.3703125, 0.177777777777778], output: [0.112510165400612]},
    {input: [0.534375, 0.163888888888889], output: [0.112510165400612]},
    {input: [0.6234375, 0.0833333333333333], output: [0.180430305803626]},
    {input: [0.6515625, 0.111111111111111], output: [0.180430305803626]},
    {input: [0.646875, 0.119444444444444], output: [0.157790259002621]},
    {input: [0.7875, 0.233333333333333], output: [0.180430305803626]},
    {input: [0.759375, 0.236111111111111], output: [0.225710399405636]},
    {input: [0.696875, 0.236111111111111], output: [0.225710399405636]},
    {input: [0.634375, 0.258333333333333], output: [0.225710399405636]},
    {input: [0.746875, 0.241666666666667], output: [0.24835044620664]},
    {input: [0.753125, 0.255555555555556], output: [0.24835044620664]},
    {input: [0.6515625, 0.269444444444444], output: [0.316270586609655]},
    {input: [0.725, 0.263888888888889], output: [0.24835044620664]},
    {input: [0.746875, 0.266666666666667], output: [0.24835044620664]},
    {input: [0.7078125, 0.258333333333333], output: [0.270990493007645]},
    {input: [0.7703125, 0.233333333333333], output: [0.24835044620664]},
    {input: [0.71875, 0.233333333333333], output: [0.270990493007645]},
    {input: [0.71875, 0.25], output: [0.225710399405636]},
    {input: [0.746875, 0.286111111111111], output: [0.225710399405636]},
    {input: [0.7984375, 0.261111111111111], output: [0.24835044620664]},
    {input: [0.7640625, 0.277777777777778], output: [0.225710399405636]},
    {input: [0.590625, 0.258333333333333], output: [0.225710399405636]},
    {input: [0.6125, 0.266666666666667], output: [0.180430305803626]},
    {input: [0.6015625, 0.258333333333333], output: [0.180430305803626]},
    {input: [0.534375, 0.272222222222222], output: [0.180430305803626]},
    {input: [0.5, 0.3], output: [0.157790259002621]},
    {input: [0.471875, 0.327777777777778], output: [0.135150212201616]},
    {input: [0.4546875, 0.336111111111111], output: [0.112510165400612]},
    {input: [0.409375, 0.302777777777778], output: [0.112510165400612]},
    {input: [0.365625, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.3375, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.296875, 0.375], output: [0.067230071798602]},
    {input: [0.28125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.359375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.4046875, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.353125, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.55, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.45, 0.211111111111111], output: [0.112510165400612]},
    {input: [0.50625, 0.213888888888889], output: [0.112510165400612]},
    {input: [0.3875, 0.188888888888889], output: [0.112510165400612]},
    {input: [0.590625, 0.275], output: [0.135150212201616]},
    {input: [0.55, 0.35], output: [0.112510165400612]},
    {input: [0.471875, 0.430555555555556], output: [0.112510165400612]},
    {input: [0.3765625, 0.461111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.45], output: [0.067230071798602]},
    {input: [0.2015625, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.3484375, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.4328125, 0.4], output: [0.0445900249975972]},
    {input: [0.478125, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.4375, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.4609375, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.4328125, 0.375], output: [0.067230071798602]},
    {input: [0.521875, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.50625, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.415625, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.359375, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.3], output: [0.0445900249975972]},
    {input: [0.359375, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.309375, 0.325], output: [0.067230071798602]},
    {input: [0.353125, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.26875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.375], output: [0.0445900249975972]},
    {input: [0.2359375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.38125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.4328125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.465625, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.465625, 0.0277777777777778], output: [0.0898701185996068]},
    {input: [0.415625, 0.0166666666666667], output: [0.112510165400612]},
    {input: [0.2578125, 0.0222222222222222], output: [0.0898701185996068]},
    {input: [0.140625, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.1515625, 0.0472222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.0138888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.75], output: [0.0445900249975972]},
    {input: [0.26875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.359375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.3875, 0.705555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.677777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.688888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.666666666666667], output: [0.0898701185996068]},
    {input: [0.50625, 0.686111111111111], output: [0.0898701185996068]},
    {input: [0.534375, 0.677777777777778], output: [0.0898701185996068]},
    {input: [0.471875, 0.680555555555556], output: [0.0898701185996068]},
    {input: [0.4890625, 0.658333333333333], output: [0.0898701185996068]},
    {input: [0.50625, 0.669444444444444], output: [0.0898701185996068]},
    {input: [0.5453125, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.534375, 0.697222222222222], output: [0.112510165400612]},
    {input: [0.5953125, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.50625, 0.688888888888889], output: [0.112510165400612]},
    {input: [0.55, 0.683333333333333], output: [0.135150212201616]},
    {input: [0.44375, 0.7], output: [0.112510165400612]},
    {input: [0.39375, 0.722222222222222], output: [0.112510165400612]},
    {input: [0.534375, 0.736111111111111], output: [0.112510165400612]},
    {input: [0.38125, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.3421875, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.421875, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.3984375, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.3765625, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.471875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.471875, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.44375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.45, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.421875, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.4046875, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.4546875, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.5, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.5171875, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.465625, 0.769444444444444], output: [0.0898701185996068]},
    {input: [0.50625, 0.775], output: [0.0898701185996068]},
    {input: [0.50625, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.4890625, 0.766666666666667], output: [0.0898701185996068]},
    {input: [0.4890625, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.780555555555556], output: [0.0898701185996068]},
    {input: [0.3875, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.775], output: [0.067230071798602]},
    {input: [0.26875, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.75], output: [0.0219499781965924]},
    {input: [0.1515625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.75], output: [0.0219499781965924]},
    {input: [0.1453125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.405555555555556], output: [-0.000690068604412431]},
    {input: [0.1, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.175], output: [0.0445900249975972]},
    {input: [0.128125, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.33125, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.421875, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.471875, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.55625, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.6015625, 0.0944444444444444], output: [0.135150212201616]},
    {input: [0.55625, 0.0833333333333333], output: [0.135150212201616]},
    {input: [0.6296875, 0.0833333333333333], output: [0.135150212201616]},
    {input: [0.6015625, 0.1], output: [0.157790259002621]},
    {input: [0.590625, 0.0888888888888889], output: [0.157790259002621]},
    {input: [0.55625, 0.0944444444444444], output: [0.157790259002621]},
    {input: [0.5734375, 0.1], output: [0.135150212201616]},
    {input: [0.521875, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.534375, 0.0972222222222222], output: [0.135150212201616]},
    {input: [0.4265625, 0.0833333333333333], output: [0.112510165400612]},
    {input: [0.33125, 0.113888888888889], output: [0.0898701185996068]},
    {input: [0.26875, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.465625, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.0609375, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.1625, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.1734375, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.140625, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.1125, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.2125, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.0972222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.225], output: [0.067230071798602]},
    {input: [0.128125, 0.25], output: [0.0445900249975972]},
    {input: [0.134375, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3875, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.415625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.33125, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.478125, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.50625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.4609375, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.5, 0.516666666666667], output: [0.0898701185996068]},
    {input: [0.465625, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.5953125, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.441666666666667], output: [0.0898701185996068]},
    {input: [0.4046875, 0.508333333333333], output: [0.067230071798602]},
    {input: [0.1234375, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.39375, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.49375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.5390625, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.5453125, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.225, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.5109375, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.134375, 0.366666666666667], output: [0.112510165400612]},
    {input: [0.184375, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.4375, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.471875, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.640625, 0.347222222222222], output: [0.0898701185996068]},
    {input: [0.50625, 0.361111111111111], output: [0.112510165400612]},
    {input: [0.471875, 0.366666666666667], output: [0.112510165400612]},
    {input: [0.3875, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.33125, 0.394444444444444], output: [0.0898701185996068]},
    {input: [0.471875, 0.375], output: [0.0898701185996068]},
    {input: [0.415625, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.4828125, 0.35], output: [0.0898701185996068]},
    {input: [0.478125, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.415625, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.5, 0.302777777777778], output: [0.0898701185996068]},
    {input: [0.49375, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.5109375, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.578125, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.6015625, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.521875, 0.288888888888889], output: [0.112510165400612]},
    {input: [0.6125, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.5734375, 0.266666666666667], output: [0.112510165400612]},
    {input: [0.590625, 0.305555555555556], output: [0.135150212201616]},
    {input: [0.359375, 0.461111111111111], output: [0.135150212201616]},
    {input: [0.49375, 0.338888888888889], output: [0.112510165400612]},
    {input: [0.3703125, 0.352777777777778], output: [0.112510165400612]},
    {input: [0.325, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.2859375, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.471875, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.45], output: [0.067230071798602]},
    {input: [0.415625, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.275, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.3], output: [0.067230071798602]},
    {input: [0.3703125, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.3984375, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.421875, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.415625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.3984375, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.5171875, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.5453125, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.50625, 0.180555555555556], output: [0.112510165400612]},
    {input: [0.5625, 0.163888888888889], output: [0.112510165400612]},
    {input: [0.534375, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.4828125, 0.113888888888889], output: [0.112510165400612]},
    {input: [0.5109375, 0.0944444444444444], output: [0.112510165400612]},
    {input: [0.5, 0.0916666666666667], output: [0.112510165400612]},
    {input: [0.5109375, 0.0972222222222222], output: [0.112510165400612]},
    {input: [0.4828125, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.4890625, 0.116666666666667], output: [0.0898701185996068]},
    {input: [0.478125, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.6296875, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.528125, 0.105555555555556], output: [0.135150212201616]},
    {input: [0.465625, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.55625, 0.0972222222222222], output: [0.112510165400612]},
    {input: [0.6578125, 0.15], output: [0.135150212201616]},
    {input: [0.528125, 0.2], output: [0.135150212201616]},
    {input: [0.7140625, 0.194444444444444], output: [0.157790259002621]},
    {input: [0.809375, 0.166666666666667], output: [0.135150212201616]},
    {input: [0.7140625, 0.0861111111111111], output: [0.180430305803626]},
    {input: [0.584375, 0.183333333333333], output: [0.225710399405636]},
    {input: [0.528125, 0.566666666666667], output: [0.157790259002621]},
    {input: [0.49375, 0.547222222222222], output: [0.112510165400612]},
    {input: [0.3203125, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.4546875, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.703125, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.634375, 0.338888888888889], output: [0.112510165400612]},
    {input: [0.6796875, 0.325], output: [0.112510165400612]},
    {input: [0.703125, 0.388888888888889], output: [0.112510165400612]},
    {input: [0.4890625, 0.377777777777778], output: [0.157790259002621]},
    {input: [0.578125, 0.397222222222222], output: [0.135150212201616]},
    {input: [0.365625, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.325, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.33125, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.375], output: [0.067230071798602]},
    {input: [0.2640625, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.430555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.375], output: [0.0445900249975972]},
    {input: [0.296875, 0.4], output: [0.0445900249975972]},
    {input: [0.3375, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.3484375, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.3375, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.447222222222222], output: [0.067230071798602]},
    {input: [0.1625, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.15625, 0.438888888888889], output: [0.067230071798602]},
    {input: [0.134375, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.275, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.25], output: [0.067230071798602]},
    {input: [0.2359375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.1], output: [0.0219499781965924]},
    {input: [0.38125, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.415625, 0.0444444444444444], output: [0.067230071798602]},
    {input: [0.421875, 0.075], output: [0.0445900249975972]},
    {input: [0.5109375, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.534375, 0.0833333333333333], output: [0.0898701185996068]},
    {input: [0.49375, 0.105555555555556], output: [0.112510165400612]},
    {input: [0.39375, 0.0694444444444444], output: [0.112510165400612]},
    {input: [0.365625, 0.0416666666666667], output: [0.112510165400612]},
    {input: [0.3203125, 0.0361111111111111], output: [0.067230071798602]},
    {input: [0.2296875, 0.0527777777777778], output: [0.067230071798602]},
    {input: [0.2359375, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.3140625, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.409375, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.38125, 0.1], output: [0.0898701185996068]},
    {input: [0.4375, 0.116666666666667], output: [0.0898701185996068]},
    {input: [0.4265625, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.421875, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.359375, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.2921875, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.3140625, 0.2], output: [0.067230071798602]},
    {input: [0.39375, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.408333333333333], output: [0.067230071798602]},
    {input: [0.303125, 0.375], output: [0.0445900249975972]},
    {input: [0.26875, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.3875, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.3765625, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.359375, 0.35], output: [0.0445900249975972]},
    {input: [0.359375, 0.35], output: [0.0445900249975972]},
    {input: [0.359375, 0.375], output: [0.0219499781965924]},
    {input: [0.296875, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.465625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.38125, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.45], output: [0.0219499781965924]},
    {input: [0.0609375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.3984375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.5671875, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.5953125, 0.0444444444444444], output: [0.112510165400612]},
    {input: [0.5625, 0.0472222222222222], output: [0.112510165400612]},
    {input: [0.528125, 0.0638888888888889], output: [0.112510165400612]},
    {input: [0.534375, 0.0444444444444444], output: [0.112510165400612]},
    {input: [0.5, 0.075], output: [0.112510165400612]},
    {input: [0.4375, 0.075], output: [0.112510165400612]},
    {input: [0.50625, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.465625, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.365625, 0.172222222222222], output: [0.112510165400612]},
    {input: [0.4328125, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.3484375, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.134375, 0.427777777777778], output: [0.0898701185996068]},
    {input: [0.1625, 0.708333333333333], output: [0.067230071798602]},
    {input: [0.16875, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.0609375, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.0609375, 0.725], output: [0.0219499781965924]},
    {input: [0.05625, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.7359375, 0.694444444444444], output: [0.203070352604631]},
    {input: [0.6296875, 0.672222222222222], output: [0.203070352604631]},
    {input: [0.6625, 0.711111111111111], output: [0.180430305803626]},
    {input: [0.7140625, 0.741666666666667], output: [0.225710399405636]},
    {input: [0.421875, 0.680555555555556], output: [0.203070352604631]},
    {input: [0.2359375, 0.616666666666667], output: [0.157790259002621]},
    {input: [0.240625, 0.694444444444444], output: [0.135150212201616]},
    {input: [0.225, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.365625, 0.683333333333333], output: [0.112510165400612]},
    {input: [0.2578125, 0.675], output: [0.067230071798602]},
    {input: [0.16875, 0.869444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0], output: [0.0219499781965924]},
    {input: [0.0671875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.128125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.825], output: [0.0219499781965924]},
    {input: [0.253125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.421875, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.3703125, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.4609375, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.50625, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.534375, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.528125, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.471875, 0.730555555555556], output: [0.112510165400612]},
    {input: [0.471875, 0.702777777777778], output: [0.112510165400612]},
    {input: [0.478125, 0.725], output: [0.112510165400612]},
    {input: [0.5453125, 0.725], output: [0.112510165400612]},
    {input: [0.5953125, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.6625, 0.744444444444444], output: [0.157790259002621]},
    {input: [0.703125, 0.741666666666667], output: [0.157790259002621]},
    {input: [0.60625, 0.741666666666667], output: [0.180430305803626]},
    {input: [0.465625, 0.702777777777778], output: [0.135150212201616]},
    {input: [0.084375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.3], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.166666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.26875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.3875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.4265625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.409375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.275], output: [0.067230071798602]},
    {input: [0.4328125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.38125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.44375, 0.1], output: [0.0445900249975972]},
    {input: [0.39375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.4546875, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.4828125, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.534375, 0.225], output: [0.067230071798602]},
    {input: [0.3140625, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.5109375, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.5109375, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.465625, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.478125, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.409375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.365625, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.26875, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.528125, 0.675], output: [0.0898701185996068]},
    {input: [0.55625, 0.663888888888889], output: [0.0898701185996068]},
    {input: [0.5671875, 0.666666666666667], output: [0.112510165400612]},
    {input: [0.5109375, 0.672222222222222], output: [0.135150212201616]},
    {input: [0.471875, 0.647222222222222], output: [0.135150212201616]},
    {input: [0.5453125, 0.694444444444444], output: [0.135150212201616]},
    {input: [0.55625, 0.65], output: [0.203070352604631]},
    {input: [0.5, 0.683333333333333], output: [0.157790259002621]},
    {input: [0.4546875, 0.669444444444444], output: [0.135150212201616]},
    {input: [0.309375, 0.619444444444444], output: [0.135150212201616]},
    {input: [0.2359375, 0.65], output: [0.112510165400612]},
    {input: [0.26875, 0.594444444444444], output: [0.0898701185996068]},
    {input: [0.253125, 0.630555555555556], output: [0.0898701185996068]},
    {input: [0.15625, 0.538888888888889], output: [0.067230071798602]},
    {input: [0.1734375, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.084375, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.45, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.3765625, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.177777777777778], output: [0.0898701185996068]},
    {input: [0.2578125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.325, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.39375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.4546875, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.39375, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.45, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.5390625, 0.197222222222222], output: [0.0898701185996068]},
    {input: [0.6015625, 0.183333333333333], output: [0.112510165400612]},
    {input: [0.5953125, 0.180555555555556], output: [0.157790259002621]},
    {input: [0.6296875, 0.172222222222222], output: [0.157790259002621]},
    {input: [0.590625, 0.183333333333333], output: [0.135150212201616]},
    {input: [0.590625, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.4828125, 0.227777777777778], output: [0.157790259002621]},
    {input: [0.5, 0.219444444444444], output: [0.135150212201616]},
    {input: [0.44375, 0.238888888888889], output: [0.157790259002621]},
    {input: [0.4546875, 0.197222222222222], output: [0.135150212201616]},
    {input: [0.471875, 0.152777777777778], output: [0.135150212201616]},
    {input: [0.3875, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.197222222222222], output: [0.135150212201616]},
    {input: [0.3703125, 0.238888888888889], output: [0.135150212201616]},
    {input: [0.3421875, 0.247222222222222], output: [0.135150212201616]},
    {input: [0.309375, 0.191666666666667], output: [0.135150212201616]},
    {input: [0.3703125, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.4328125, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.359375, 0.261111111111111], output: [0.135150212201616]},
    {input: [0.2859375, 0.338888888888889], output: [0.135150212201616]},
    {input: [0.4828125, 0.405555555555556], output: [0.135150212201616]},
    {input: [0.38125, 0.380555555555556], output: [0.112510165400612]},
    {input: [0.39375, 0.441666666666667], output: [0.112510165400612]},
    {input: [0.05625, 0.669444444444444], output: [0.0898701185996068]},
    {input: [0.140625, 0.552777777777778], output: [0.067230071798602]},
    {input: [0.10625, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.1625, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.078125, 0.0611111111111111], output: [0.067230071798602]},
    {input: [0.078125, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.55], output: [0.0219499781965924]},
    {input: [0.078125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.415625, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.521875, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.5, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.478125, 0.0722222222222222], output: [0.0898701185996068]},
    {input: [0.4546875, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.4546875, 0.0527777777777778], output: [0.0898701185996068]},
    {input: [0.3984375, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.365625, 0.05], output: [0.0898701185996068]},
    {input: [0.4265625, 0.075], output: [0.0898701185996068]},
    {input: [0.4609375, 0.1], output: [0.0898701185996068]},
    {input: [0.3703125, 0.0972222222222222], output: [0.0898701185996068]},
    {input: [0.275, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.3203125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.4046875, 0.275], output: [0.0898701185996068]},
    {input: [0.325, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.353125, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.275, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.1625, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.0328125, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.2921875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.365625, 0.275], output: [0.0445900249975972]},
    {input: [0.2640625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.353125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.325, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.3765625, 0.425], output: [0.0445900249975972]},
    {input: [0.246875, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.16875, 0.4], output: [0.0445900249975972]},
    {input: [0.028125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.325], output: [0.0219499781965924]},
    {input: [0.325, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.4046875, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.421875, 0.375], output: [0.0445900249975972]},
    {input: [0.3140625, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.225, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.325], output: [0.0445900249975972]},
    {input: [0.6234375, 0.313888888888889], output: [0.135150212201616]},
    {input: [0.5109375, 0.283333333333333], output: [0.135150212201616]},
    {input: [0.471875, 0.341666666666667], output: [0.135150212201616]},
    {input: [0.4546875, 0.311111111111111], output: [0.112510165400612]},
    {input: [0.39375, 0.3], output: [0.112510165400612]},
    {input: [0.240625, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.1796875, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.3484375, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.365625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.475], output: [0.0219499781965924]},
    {input: [0.2859375, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.5, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.478125, 0.702777777777778], output: [0.067230071798602]},
    {input: [0.465625, 0.686111111111111], output: [0.112510165400612]},
    {input: [0.4546875, 0.658333333333333], output: [0.112510165400612]},
    {input: [0.5, 0.658333333333333], output: [0.112510165400612]},
    {input: [0.5390625, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.5171875, 0.669444444444444], output: [0.135150212201616]},
    {input: [0.49375, 0.669444444444444], output: [0.112510165400612]},
    {input: [0.584375, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.680555555555556], output: [0.135150212201616]},
    {input: [0.471875, 0.702777777777778], output: [0.135150212201616]},
    {input: [0.5453125, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.5625, 0.758333333333333], output: [0.135150212201616]},
    {input: [0.4609375, 0.691666666666667], output: [0.135150212201616]},
    {input: [0.4828125, 0.658333333333333], output: [0.135150212201616]},
    {input: [0.4546875, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.5171875, 0.708333333333333], output: [0.0898701185996068]},
    {input: [0.5, 0.705555555555556], output: [0.112510165400612]},
    {input: [0.4890625, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.4546875, 0.694444444444444], output: [0.112510165400612]},
    {input: [0.471875, 0.663888888888889], output: [0.112510165400612]},
    {input: [0.4328125, 0.672222222222222], output: [0.135150212201616]},
    {input: [0.415625, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.409375, 0.683333333333333], output: [0.0898701185996068]},
    {input: [0.4375, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.4375, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.763888888888889], output: [0.112510165400612]},
    {input: [0.39375, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.4265625, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.38125, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.409375, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.3984375, 0.75], output: [0.0898701185996068]},
    {input: [0.4375, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.465625, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.5, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.4546875, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.4609375, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.3421875, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.2640625, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.75], output: [0.0445900249975972]},
    {input: [0.21875, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.415625, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.45, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.49375, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.4890625, 0.65], output: [0.067230071798602]},
    {input: [0.471875, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.49375, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.465625, 0.658333333333333], output: [0.0898701185996068]},
    {input: [0.55, 0.7], output: [0.112510165400612]},
    {input: [0.5453125, 0.677777777777778], output: [0.0898701185996068]},
    {input: [0.528125, 0.713888888888889], output: [0.0898701185996068]},
    {input: [0.590625, 0.763888888888889], output: [0.112510165400612]},
    {input: [0.45, 0.691666666666667], output: [0.135150212201616]},
    {input: [0.471875, 0.722222222222222], output: [0.112510165400612]},
    {input: [0.21875, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.16875, 0.0305555555555556], output: [0.067230071798602]},
    {input: [0.1171875, 0.0583333333333333], output: [0.067230071798602]},
    {input: [0.0953125, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.075], output: [0.0219499781965924]},
    {input: [0.1453125, 0.1], output: [0.0219499781965924]},
    {input: [0.0953125, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.125], output: [0.0219499781965924]},
    {input: [0.3421875, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.45, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.478125, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.4265625, 0.0388888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.0305555555555556], output: [0.067230071798602]},
    {input: [0.409375, 0.0444444444444444], output: [0.067230071798602]},
    {input: [0.44375, 0.0361111111111111], output: [0.067230071798602]},
    {input: [0.5171875, 0.0472222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.05], output: [0.0898701185996068]},
    {input: [0.45, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.0472222222222222], output: [0.0898701185996068]},
    {input: [0.275, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.2015625, 0.075], output: [0.067230071798602]},
    {input: [0.128125, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.028125, 0.983333333333333], output: [0.067230071798602]},
    {input: [0.021875, 0.075], output: [0.0445900249975972]},
    {input: [0.0328125, 0.4], output: [0.0445900249975972]},
    {input: [0.071875, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.084375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.05], output: [0.0219499781965924]},
    {input: [0.1625, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.983333333333333], output: [-0.000690068604412431]},
    {input: [0.1, 0.1], output: [0.0219499781965924]},
    {input: [0.1515625, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.15], output: [0.0219499781965924]},
    {input: [0.3375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.38125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.39375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.39375, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.45, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.4828125, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.4328125, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.38125, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.478125, 0.502777777777778], output: [0.067230071798602]},
    {input: [0.5109375, 0.541666666666667], output: [0.0445900249975972]},
    {input: [0.640625, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.7140625, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.465625, 0.675], output: [0.203070352604631]},
    {input: [0.4046875, 0.669444444444444], output: [0.157790259002621]},
    {input: [0.3875, 0.647222222222222], output: [0.157790259002621]},
    {input: [0.39375, 0.641666666666667], output: [0.112510165400612]},
    {input: [0.3421875, 0.672222222222222], output: [0.0898701185996068]},
    {input: [0.309375, 0.661111111111111], output: [0.0898701185996068]},
    {input: [0.184375, 0.658333333333333], output: [0.0898701185996068]},
    {input: [0.1453125, 0.675], output: [0.0898701185996068]},
    {input: [0.0671875, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.128125, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.028125, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.303125, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.45], output: [0.0219499781965924]},
    {input: [0.1515625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.35], output: [0.0219499781965924]},
    {input: [0.0953125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.7], output: [0.0445900249975972]},
    {input: [0.1734375, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.021875, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.075], output: [0.0219499781965924]},
    {input: [0.1625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.225], output: [0.0219499781965924]},
    {input: [0.296875, 0.225], output: [0.0219499781965924]},
    {input: [0.3203125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.471875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.521875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.5453125, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.55, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.55625, 0.25], output: [0.112510165400612]},
    {input: [0.703125, 0.25], output: [0.157790259002621]},
    {input: [0.5671875, 0.3], output: [0.180430305803626]},
    {input: [0.73125, 0.266666666666667], output: [0.180430305803626]},
    {input: [0.584375, 0.233333333333333], output: [0.180430305803626]},
    {input: [0.50625, 0.302777777777778], output: [0.180430305803626]},
    {input: [0.6125, 0.327777777777778], output: [0.135150212201616]},
    {input: [0.6859375, 0.330555555555556], output: [0.157790259002621]},
    {input: [0.725, 0.366666666666667], output: [0.157790259002621]},
    {input: [0.83125, 0.366666666666667], output: [0.180430305803626]},
    {input: [0.6859375, 0.375], output: [0.180430305803626]},
    {input: [0.521875, 0.413888888888889], output: [0.180430305803626]},
    {input: [0.55, 0.4], output: [0.157790259002621]},
    {input: [0.5109375, 0.416666666666667], output: [0.180430305803626]},
    {input: [0.3484375, 0.588888888888889], output: [0.180430305803626]},
    {input: [0.196875, 0.538888888888889], output: [0.135150212201616]},
    {input: [0.415625, 0.516666666666667], output: [0.112510165400612]},
    {input: [0.725, 0.505555555555556], output: [0.0898701185996068]},
    {input: [0.4890625, 0.491666666666667], output: [0.112510165400612]},
    {input: [0.45, 0.466666666666667], output: [0.067230071798602]},
    {input: [0.465625, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.5453125, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.4046875, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.646875, 0.541666666666667], output: [0.067230071798602]},
    {input: [0.409375, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.5671875, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.465625, 0.558333333333333], output: [0.067230071798602]},
    {input: [0.2640625, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.4328125, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.60625, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.4265625, 0.358333333333333], output: [0.0898701185996068]},
    {input: [0.5453125, 0.477777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.444444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.33125, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.5109375, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.44375, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.5, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.5109375, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.4046875, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.38125, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.465625, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.478125, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.4828125, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.49375, 0.166666666666667], output: [0.112510165400612]},
    {input: [0.60625, 0.0694444444444444], output: [0.112510165400612]},
    {input: [0.5734375, 0.0777777777777778], output: [0.112510165400612]},
    {input: [0.5734375, 0.0888888888888889], output: [0.135150212201616]},
    {input: [0.5953125, 0.125], output: [0.157790259002621]},
    {input: [0.590625, 0.0972222222222222], output: [0.135150212201616]},
    {input: [0.6796875, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.6515625, 0.238888888888889], output: [0.157790259002621]},
    {input: [0.5390625, 0.222222222222222], output: [0.180430305803626]},
    {input: [0.4328125, 0.225], output: [0.157790259002621]},
    {input: [0.4046875, 0.197222222222222], output: [0.135150212201616]},
    {input: [0.421875, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.28125, 0.0472222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.95], output: [0.067230071798602]},
    {input: [0.3984375, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.3203125, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.2578125, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.134375, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.084375, 0.725], output: [0.0445900249975972]},
    {input: [0.10625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.675], output: [0.0219499781965924]},
    {input: [0.128125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.3], output: [0.0445900249975972]},
    {input: [0.240625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.1234375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.465625, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.528125, 0.125], output: [0.067230071798602]},
    {input: [0.415625, 0.1], output: [0.112510165400612]},
    {input: [0.3203125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.2640625, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.325, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.25], output: [0.067230071798602]},
    {input: [0.4328125, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.49375, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.4609375, 0.211111111111111], output: [0.112510165400612]},
    {input: [0.3984375, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.3984375, 0.197222222222222], output: [0.112510165400612]},
    {input: [0.521875, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.45, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.5734375, 0.0833333333333333], output: [0.135150212201616]},
    {input: [0.6578125, 0.163888888888889], output: [0.180430305803626]},
    {input: [0.6125, 0.186111111111111], output: [0.203070352604631]},
    {input: [0.60625, 0.191666666666667], output: [0.180430305803626]},
    {input: [0.640625, 0.152777777777778], output: [0.180430305803626]},
    {input: [0.6515625, 0.0805555555555556], output: [0.180430305803626]},
    {input: [0.66875, 0.0722222222222222], output: [0.203070352604631]},
    {input: [0.6625, 0.075], output: [0.180430305803626]},
    {input: [0.6515625, 0.0805555555555556], output: [0.157790259002621]},
    {input: [0.640625, 0.0833333333333333], output: [0.157790259002621]},
    {input: [0.5625, 0.0805555555555556], output: [0.180430305803626]},
    {input: [0.365625, 0.113888888888889], output: [0.157790259002621]},
    {input: [0.4328125, 0.108333333333333], output: [0.112510165400612]},
    {input: [0.44375, 0.075], output: [0.112510165400612]},
    {input: [0.353125, 0.0666666666666667], output: [0.0898701185996068]},
    {input: [0.26875, 0.0555555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.0555555555555556], output: [0.067230071798602]},
    {input: [0.2015625, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.0638888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.075], output: [0.0445900249975972]},
    {input: [0.1234375, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.028125, 0.325], output: [0.0445900249975972]},
    {input: [0.021875, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.8], output: [0.0219499781965924]},
    {input: [0.05, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.38125, 0.725], output: [0.0445900249975972]},
    {input: [0.33125, 0.675], output: [0.0445900249975972]},
    {input: [0.44375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.5171875, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.61875, 0.713888888888889], output: [0.112510165400612]},
    {input: [0.5625, 0.702777777777778], output: [0.135150212201616]},
    {input: [0.521875, 0.691666666666667], output: [0.135150212201616]},
    {input: [0.478125, 0.755555555555556], output: [0.135150212201616]},
    {input: [0.3703125, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.253125, 0.0388888888888889], output: [0.0898701185996068]},
    {input: [0.26875, 0.0472222222222222], output: [0.067230071798602]},
    {input: [0.21875, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.246875, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.075], output: [0.067230071798602]},
    {input: [0.3765625, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.365625, 0.075], output: [0.0898701185996068]},
    {input: [0.415625, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.3203125, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.39375, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.28125, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.3375, 0.0972222222222222], output: [0.067230071798602]},
    {input: [0.365625, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.111111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.075], output: [0.067230071798602]},
    {input: [0.5671875, 0.0666666666666667], output: [0.0898701185996068]},
    {input: [0.634375, 0.0861111111111111], output: [0.112510165400612]},
    {input: [0.640625, 0.0861111111111111], output: [0.157790259002621]},
    {input: [0.6125, 0.0833333333333333], output: [0.180430305803626]},
    {input: [0.646875, 0.0888888888888889], output: [0.135150212201616]},
    {input: [0.66875, 0.0833333333333333], output: [0.180430305803626]},
    {input: [0.640625, 0.0805555555555556], output: [0.180430305803626]},
    {input: [0.690625, 0.0833333333333333], output: [0.157790259002621]},
    {input: [0.66875, 0.0944444444444444], output: [0.180430305803626]},
    {input: [0.6296875, 0.194444444444444], output: [0.157790259002621]},
    {input: [0.578125, 0.208333333333333], output: [0.180430305803626]},
    {input: [0.66875, 0.236111111111111], output: [0.180430305803626]},
    {input: [0.6234375, 0.263888888888889], output: [0.180430305803626]},
    {input: [0.4265625, 0.352777777777778], output: [0.203070352604631]},
    {input: [0.409375, 0.433333333333333], output: [0.180430305803626]},
    {input: [0.4546875, 0.452777777777778], output: [0.135150212201616]},
    {input: [0.471875, 0.486111111111111], output: [0.112510165400612]},
    {input: [0.4375, 0.494444444444444], output: [0.112510165400612]},
    {input: [0.38125, 0.6], output: [0.112510165400612]},
    {input: [0.303125, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.44375, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.33125, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.452777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.675], output: [0.0219499781965924]},
    {input: [0.140625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.75], output: [0.0219499781965924]},
    {input: [0.253125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.925], output: [0.0219499781965924]},
    {input: [0.16875, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.955555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.3421875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.3765625, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.421875, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.1125, 0.925], output: [0.067230071798602]},
    {input: [0.2125, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.05, 0.125], output: [0.0445900249975972]},
    {input: [0.2125, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0527777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.365625, 0.925], output: [0.0445900249975972]},
    {input: [0.240625, 0.95], output: [0.067230071798602]},
    {input: [0.225, 0.0222222222222222], output: [0.067230071798602]},
    {input: [0.15625, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.1734375, 0.0638888888888889], output: [0.0445900249975972]},
    {input: [0.1625, 0.0305555555555556], output: [0.067230071798602]},
    {input: [0.1453125, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.05, 0.725], output: [0.0445900249975972]},
    {input: [0.16875, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0], output: [0.0219499781965924]},
    {input: [0.190625, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.825], output: [0.0219499781965924]},
    {input: [0.15625, 0.725], output: [0.0219499781965924]},
    {input: [0.2078125, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.175], output: [0.0219499781965924]},
    {input: [0.240625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.3703125, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.0722222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.3703125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.4828125, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.578125, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.421875, 0.313888888888889], output: [0.0898701185996068]},
    {input: [0.409375, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.3875, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.49375, 0.313888888888889], output: [0.0898701185996068]},
    {input: [0.4328125, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.4375, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.33125, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.359375, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.4546875, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.4890625, 0.244444444444444], output: [0.112510165400612]},
    {input: [0.3875, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.4328125, 0.269444444444444], output: [0.112510165400612]},
    {input: [0.478125, 0.330555555555556], output: [0.112510165400612]},
    {input: [0.325, 0.536111111111111], output: [0.157790259002621]},
    {input: [0.240625, 0.638888888888889], output: [0.112510165400612]},
    {input: [0.190625, 0.466666666666667], output: [0.0898701185996068]},
    {input: [0.3203125, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.4328125, 0.722222222222222], output: [0.067230071798602]},
    {input: [0.38125, 0.688888888888889], output: [0.0898701185996068]},
    {input: [0.303125, 0.65], output: [0.112510165400612]},
    {input: [0.28125, 0.630555555555556], output: [0.0898701185996068]},
    {input: [0.1734375, 0.508333333333333], output: [0.067230071798602]},
    {input: [0.1796875, 0.527777777777778], output: [0.067230071798602]},
    {input: [0.2125, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.638888888888889], output: [0.067230071798602]},
    {input: [0.2125, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.28125, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.2859375, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.128125, 0.611111111111111], output: [0.0898701185996068]},
    {input: [0.2578125, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.353125, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.50625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.6625, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.634375, 0.0722222222222222], output: [0.112510165400612]},
    {input: [0.6015625, 0.0694444444444444], output: [0.135150212201616]},
    {input: [0.66875, 0.0722222222222222], output: [0.157790259002621]},
    {input: [0.640625, 0.102777777777778], output: [0.157790259002621]},
    {input: [0.534375, 0.05], output: [0.157790259002621]},
    {input: [0.4609375, 0.263888888888889], output: [0.157790259002621]},
    {input: [0.45, 0.244444444444444], output: [0.112510165400612]},
    {input: [0.4546875, 0.208333333333333], output: [0.112510165400612]},
    {input: [0.578125, 0.0888888888888889], output: [0.112510165400612]},
    {input: [0.325, 0.1], output: [0.067230071798602]},
    {input: [0.3375, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.0916666666666667], output: [0.0898701185996068]},
    {input: [0.3375, 0.133333333333333], output: [0.0898701185996068]},
    {input: [0.3203125, 0.0638888888888889], output: [0.0898701185996068]},
    {input: [0.3203125, 0.0472222222222222], output: [0.067230071798602]},
    {input: [0.5109375, 0.0694444444444444], output: [0.0898701185996068]},
    {input: [0.4890625, 0.0861111111111111], output: [0.112510165400612]},
    {input: [0.49375, 0.102777777777778], output: [0.0898701185996068]},
    {input: [0.528125, 0.0694444444444444], output: [0.112510165400612]},
    {input: [0.4046875, 0.0944444444444444], output: [0.112510165400612]},
    {input: [0.275, 0.0138888888888889], output: [0.112510165400612]},
    {input: [0.2859375, 0.025], output: [0.112510165400612]},
    {input: [0.2359375, 0.0833333333333333], output: [0.112510165400612]},
    {input: [0.365625, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.353125, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.4609375, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.471875, 0.113888888888889], output: [0.112510165400612]},
    {input: [0.5453125, 0.0944444444444444], output: [0.112510165400612]},
    {input: [0.3765625, 0.1], output: [0.135150212201616]},
    {input: [0.3421875, 0.0972222222222222], output: [0.112510165400612]},
    {input: [0.296875, 0.0694444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.138888888888889], output: [0.0898701185996068]},
    {input: [0.3203125, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.478125, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.38125, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.3203125, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.196875, 0.275], output: [0.067230071798602]},
    {input: [0.33125, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.415625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.246875, 0.0805555555555556], output: [0.0898701185996068]},
    {input: [0.190625, 0.936111111111111], output: [0.0898701185996068]},
    {input: [0.21875, 0.994444444444444], output: [0.067230071798602]},
    {input: [0.078125, 0.05], output: [0.0445900249975972]},
    {input: [0.078125, 0.825], output: [0.067230071798602]},
    {input: [0.3140625, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.725], output: [0.067230071798602]},
    {input: [0.6296875, 0.722222222222222], output: [0.067230071798602]},
    {input: [0.640625, 0.711111111111111], output: [0.112510165400612]},
    {input: [0.7640625, 0.705555555555556], output: [0.157790259002621]},
    {input: [0.7921875, 0.691666666666667], output: [0.180430305803626]},
    {input: [0.859375, 0.686111111111111], output: [0.225710399405636]},
    {input: [0.7921875, 0.713888888888889], output: [0.270990493007645]},
    {input: [0.7921875, 0.7], output: [0.270990493007645]},
    {input: [0.675, 0.691666666666667], output: [0.24835044620664]},
    {input: [0.6625, 0.691666666666667], output: [0.24835044620664]},
    {input: [0.578125, 0.7], output: [0.270990493007645]},
    {input: [0.4828125, 0.741666666666667], output: [0.203070352604631]},
    {input: [0.325, 0.658333333333333], output: [0.157790259002621]},
    {input: [0.359375, 0.705555555555556], output: [0.157790259002621]},
    {input: [0.2078125, 0.636111111111111], output: [0.112510165400612]},
    {input: [0.184375, 0.691666666666667], output: [0.0898701185996068]},
    {input: [0.253125, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.275, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.0953125, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.071875, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.3703125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.4328125, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.4609375, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.4546875, 0.225], output: [0.067230071798602]},
    {input: [0.3765625, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.4890625, 0.0694444444444444], output: [0.0898701185996068]},
    {input: [0.471875, 0.0777777777777778], output: [0.112510165400612]},
    {input: [0.465625, 0.102777777777778], output: [0.0898701185996068]},
    {input: [0.421875, 0.105555555555556], output: [0.112510165400612]},
    {input: [0.365625, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.075], output: [0.0898701185996068]},
    {input: [0.3421875, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.0583333333333333], output: [0.067230071798602]},
    {input: [0.309375, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.075], output: [0.067230071798602]},
    {input: [0.2015625, 0.125], output: [0.0445900249975972]},
    {input: [0.16875, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.21875, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.10625, 0.983333333333333], output: [0.067230071798602]},
    {input: [0.1734375, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.0166666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0], output: [0.0219499781965924]},
    {input: [0.071875, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.275], output: [0.0445900249975972]},
    {input: [0.05, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.475], output: [0.0219499781965924]},
    {input: [0.078125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.10625, 0.075], output: [0.0219499781965924]},
    {input: [0.10625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.3765625, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.375], output: [0.067230071798602]},
    {input: [0.2640625, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.39375, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.44375, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.49375, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.3703125, 0.188888888888889], output: [0.0898701185996068]},
    {input: [0.3421875, 0.0972222222222222], output: [0.112510165400612]},
    {input: [0.303125, 0.0388888888888889], output: [0.112510165400612]},
    {input: [0.2859375, 0.116666666666667], output: [0.0898701185996068]},
    {input: [0.2078125, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.39375, 0.0805555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.0694444444444444], output: [0.0898701185996068]},
    {input: [0.33125, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.5171875, 0.188888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.45, 0.180555555555556], output: [0.135150212201616]},
    {input: [0.3703125, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.409375, 0.225], output: [0.0898701185996068]},
    {input: [0.415625, 0.194444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.225], output: [0.0898701185996068]},
    {input: [0.3484375, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.4265625, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.421875, 0.244444444444444], output: [0.135150212201616]},
    {input: [0.365625, 0.216666666666667], output: [0.112510165400612]},
    {input: [0.353125, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.2859375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.2125, 0.2], output: [0.067230071798602]},
    {input: [0.246875, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.5171875, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.275, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.253125, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.38125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.353125, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.075], output: [0.0445900249975972]},
    {input: [0.4609375, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.39375, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.309375, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.240625, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.1625, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.0328125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.0671875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.21875, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.1171875, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.55], output: [0.0219499781965924]},
    {input: [0.2078125, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.925], output: [0.0445900249975972]},
    {input: [0.134375, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.6], output: [0.0219499781965924]},
    {input: [0.1171875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.597222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.2125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.175], output: [0.0219499781965924]},
    {input: [0.184375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.10625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.140625, 0.108333333333333], output: [-0.000690068604412431]},
    {input: [0.240625, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.1625, 0.0944444444444444], output: [-0.000690068604412431]},
    {input: [0.128125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.0916666666666667], output: [-0.000690068604412431]},
    {input: [0.196875, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.569444444444444], output: [-0.000690068604412431]},
    {input: [0.10625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.569444444444444], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.65], output: [0.0219499781965924]},
    {input: [0.0671875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.75], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.547222222222222], output: [-0.000690068604412431]},
    {input: [0.04375, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.028125, 0.519444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.822222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.722222222222222], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.908333333333333], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.930555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.925], output: [-0.000690068604412431]},
    {input: [0.04375, 0.783333333333333], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.0194444444444444], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.205555555555556], output: [-0.000690068604412431]},
    {input: [0.05, 0.372222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.461111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.477777777777778], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.255555555555556], output: [-0.000690068604412431]},
    {input: [0.140625, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.190625, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.133333333333333], output: [-0.000690068604412431]},
    {input: [0.225, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.2], output: [0.0219499781965924]},
    {input: [0.1234375, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.266666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.15], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.0305555555555556], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.0888888888888889], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.225, 0.147222222222222], output: [-0.000690068604412431]},
    {input: [0.2125, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.2640625, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.275, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.21875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.125], output: [0.0219499781965924]},
    {input: [0.21875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.15], output: [0.0219499781965924]},
    {input: [0.190625, 0.15], output: [0.0219499781965924]},
    {input: [0.2078125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.075], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.325], output: [0.0219499781965924]},
    {input: [0.0953125, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.888888888888889], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.986111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.947222222222222], output: [-0.000690068604412431]},
    {input: [0.028125, 0.583333333333333], output: [-0.000690068604412431]},
    {input: [0.015625, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.55], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.025], output: [-0.000690068604412431]},
    {input: [0.078125, 0.0277777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0805555555555556], output: [-0.000690068604412431]},
    {input: [0.1125, 0.0916666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.1], output: [-0.000690068604412431]},
    {input: [0.184375, 0.0666666666666667], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.0666666666666667], output: [-0.000690068604412431]},
    {input: [0.1625, 0.108333333333333], output: [-0.000690068604412431]},
    {input: [0.10625, 0.275], output: [-0.000690068604412431]},
    {input: [0.134375, 0.230555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.188888888888889], output: [-0.000690068604412431]},
    {input: [0.134375, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0888888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.175], output: [0.0219499781965924]},
    {input: [0.1, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.0109375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.296875, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.303125, 0.125], output: [0.0445900249975972]},
    {input: [0.253125, 0.225], output: [0.0445900249975972]},
    {input: [0.4546875, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.4609375, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.071875, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.8], output: [0.0219499781965924]},
    {input: [0.04375, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.875], output: [0.0219499781965924]},
    {input: [0.3421875, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.655555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.680555555555556], output: [0.067230071798602]},
    {input: [0.2859375, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.555555555555556], output: [0.067230071798602]},
    {input: [0.225, 0.588888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.641666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.725], output: [0.0445900249975972]},
    {input: [0.05, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.4], output: [0.0219499781965924]},
    {input: [0.1, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.95], output: [0.0219499781965924]},
    {input: [0.0609375, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.05], output: [0.0219499781965924]},
    {input: [0.071875, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.797222222222222], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.819444444444444], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.128125, 0.747222222222222], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.688888888888889], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.16875, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.190625, 0.666666666666667], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.75], output: [-0.000690068604412431]},
    {input: [0.190625, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.16875, 0.725], output: [0.0219499781965924]},
    {input: [0.128125, 0.725], output: [0.0219499781965924]},
    {input: [0.225, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.3375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.39375, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.33125, 0.736111111111111], output: [0.0898701185996068]},
    {input: [0.3484375, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.3375, 0.730555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.309375, 0.730555555555556], output: [0.067230071798602]},
    {input: [0.3140625, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.2640625, 0.719444444444444], output: [0.067230071798602]},
    {input: [0.184375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.190625, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.8], output: [0.0219499781965924]},
    {input: [0.16875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.758333333333333], output: [-0.000690068604412431]},
    {input: [0.325, 0.736111111111111], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.775], output: [0.0445900249975972]},
    {input: [0.196875, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.975], output: [0.0219499781965924]},
    {input: [0.1125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.911111111111111], output: [-0.000690068604412431]},
    {input: [0.16875, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.958333333333333], output: [-0.000690068604412431]},
    {input: [0.184375, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.9], output: [0.0219499781965924]},
    {input: [0.1125, 0.925], output: [0.0219499781965924]},
    {input: [0.1625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.775], output: [0.0219499781965924]},
    {input: [0.303125, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.725], output: [0.0445900249975972]},
    {input: [0.275, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.2078125, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.1171875, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.9], output: [0.0219499781965924]},
    {input: [0.1, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.9], output: [0.0219499781965924]},
    {input: [0.078125, 0.875], output: [0.0219499781965924]},
    {input: [0.0671875, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.15625, 0.8], output: [0.0219499781965924]},
    {input: [0.196875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.675], output: [0.0219499781965924]},
    {input: [0.2359375, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.75], output: [0.0445900249975972]},
    {input: [0.225, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.825], output: [0.0445900249975972]},
    {input: [0.21875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.75], output: [0.0219499781965924]},
    {input: [0.1234375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.777777777777778], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.7], output: [-0.000690068604412431]},
    {input: [0.10625, 0.772222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.75], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.825], output: [0.0219499781965924]},
    {input: [0.015625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.658333333333333], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.311111111111111], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.16875, 0.158333333333333], output: [-0.000690068604412431]},
    {input: [0.078125, 0.275], output: [-0.000690068604412431]},
    {input: [0.15625, 0.202777777777778], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.0194444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.0888888888888889], output: [-0.000690068604412431]},
    {input: [0.04375, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.65], output: [0.0219499781965924]},
    {input: [0.1234375, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.3375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.225], output: [0.0445900249975972]},
    {input: [0.2640625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.225], output: [0.0219499781965924]},
    {input: [0.2015625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.16875, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.196875, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.33125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.0777777777777778], output: [0.067230071798602]},
    {input: [0.3984375, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.4609375, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.144444444444444], output: [0.0898701185996068]},
    {input: [0.3765625, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.4328125, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.5109375, 0.183333333333333], output: [0.0898701185996068]},
    {input: [0.5, 0.2], output: [0.112510165400612]},
    {input: [0.5, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.4890625, 0.352777777777778], output: [0.135150212201616]},
    {input: [0.3203125, 0.691666666666667], output: [0.0898701185996068]},
    {input: [0.4828125, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.55625, 0.725], output: [0.112510165400612]},
    {input: [0.5671875, 0.755555555555556], output: [0.135150212201616]},
    {input: [0.5453125, 0.711111111111111], output: [0.157790259002621]},
    {input: [0.409375, 0.613888888888889], output: [0.157790259002621]},
    {input: [0.3140625, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.296875, 0.641666666666667], output: [0.112510165400612]},
    {input: [0.225, 0.555555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.561111111111111], output: [0.0898701185996068]},
    {input: [0.2296875, 0.555555555555556], output: [0.0898701185996068]},
    {input: [0.1515625, 0.505555555555556], output: [0.112510165400612]},
    {input: [0.1453125, 0.472222222222222], output: [0.0898701185996068]},
    {input: [0.1453125, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.128125, 0.422222222222222], output: [0.067230071798602]},
    {input: [0.0953125, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.084375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.725], output: [0.0219499781965924]},
    {input: [0.296875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.3875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.666666666666667], output: [0.067230071798602]},
    {input: [0.2296875, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.613888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.580555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.613888888888889], output: [0.067230071798602]},
    {input: [0.246875, 0.625], output: [0.067230071798602]},
    {input: [0.1734375, 0.591666666666667], output: [0.067230071798602]},
    {input: [0.2015625, 0.602777777777778], output: [0.067230071798602]},
    {input: [0.2078125, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.2015625, 0.575], output: [0.0445900249975972]},
    {input: [0.225, 0.65], output: [0.067230071798602]},
    {input: [0.26875, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.421875, 0.655555555555556], output: [0.067230071798602]},
    {input: [0.4609375, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.661111111111111], output: [0.0898701185996068]},
    {input: [0.471875, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.4546875, 0.708333333333333], output: [0.135150212201616]},
    {input: [0.4609375, 0.708333333333333], output: [0.112510165400612]},
    {input: [0.4609375, 0.725], output: [0.0898701185996068]},
    {input: [0.4828125, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.4375, 0.736111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.409375, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.3765625, 0.719444444444444], output: [0.112510165400612]},
    {input: [0.2859375, 0.683333333333333], output: [0.0898701185996068]},
    {input: [0.303125, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.2640625, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.0277777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.1], output: [0.0219499781965924]},
    {input: [0.10625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.2921875, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.296875, 0.0666666666666667], output: [0.0898701185996068]},
    {input: [0.2296875, 0.075], output: [0.0898701185996068]},
    {input: [0.26875, 0.0888888888888889], output: [0.0898701185996068]},
    {input: [0.309375, 0.0916666666666667], output: [0.0898701185996068]},
    {input: [0.39375, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.2859375, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.4546875, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.0444444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.4265625, 0.0888888888888889], output: [0.0898701185996068]},
    {input: [0.4328125, 0.0777777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.4265625, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.4328125, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.39375, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.415625, 0.05], output: [0.067230071798602]},
    {input: [0.3875, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.365625, 0.0361111111111111], output: [0.0898701185996068]},
    {input: [0.3421875, 0.0388888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.296875, 0.0166666666666667], output: [0.0898701185996068]},
    {input: [0.303125, 0.0305555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.05], output: [0.067230071798602]},
    {input: [0.3484375, 0.0527777777777778], output: [0.067230071798602]},
    {input: [0.365625, 0.075], output: [0.067230071798602]},
    {input: [0.325, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.075], output: [0.067230071798602]},
    {input: [0.3375, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.39375, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.0611111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.3203125, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.125], output: [0.0445900249975972]},
    {input: [0.190625, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.2578125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.2], output: [0.0445900249975972]},
    {input: [0.325, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.45, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.55, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.4328125, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.4265625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.4546875, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.5171875, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.325, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.325, 0.519444444444444], output: [0.0898701185996068]},
    {input: [0.16875, 0.536111111111111], output: [0.0898701185996068]},
    {input: [0.184375, 0.516666666666667], output: [0.067230071798602]},
    {input: [0.190625, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.475], output: [0.0445900249975972]},
    {input: [0.1734375, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.134375, 0.694444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.45], output: [0.0445900249975972]},
    {input: [0.1234375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.327777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.4], output: [0.0219499781965924]},
    {input: [0.071875, 0.508333333333333], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.05625, 0.986111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.813888888888889], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.594444444444444], output: [-0.000690068604412431]},
    {input: [0.1125, 0.488888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.0109375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.725], output: [-0.000690068604412431]},
    {input: [0.05, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.261111111111111], output: [-0.000690068604412431]},
    {input: [0.21875, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.3875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.634375, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.5, 0.294444444444444], output: [0.0898701185996068]},
    {input: [0.409375, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.3140625, 0.425], output: [0.067230071798602]},
    {input: [0.240625, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.425], output: [0.0445900249975972]},
    {input: [0.3703125, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.522222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.55], output: [0.0219499781965924]},
    {input: [0.10625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.2], output: [0.0219499781965924]},
    {input: [0.1796875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.125], output: [0.0219499781965924]},
    {input: [0.26875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.1], output: [0.0219499781965924]},
    {input: [0.3765625, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.719444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.663888888888889], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.736111111111111], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.647222222222222], output: [-0.000690068604412431]},
    {input: [0.10625, 0.644444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.727777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.727777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.694444444444444], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.641666666666667], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.547222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.6], output: [0.0219499781965924]},
    {input: [0.1234375, 0.527777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.413888888888889], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.327777777777778], output: [-0.000690068604412431]},
    {input: [0.021875, 0.225], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.577777777777778], output: [-0.000690068604412431]},
    {input: [0.1125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.986111111111111], output: [-0.000690068604412431]},
    {input: [0.015625, 0.111111111111111], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.10625, 0.758333333333333], output: [-0.000690068604412431]},
    {input: [0.184375, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.719444444444444], output: [-0.000690068604412431]},
    {input: [0.3703125, 0.763888888888889], output: [-0.000690068604412431]},
    {input: [0.4328125, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.4609375, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.3765625, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.4046875, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.736111111111111], output: [0.0898701185996068]},
    {input: [0.465625, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.471875, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.471875, 0.763888888888889], output: [0.112510165400612]},
    {input: [0.3765625, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.3703125, 0.75], output: [0.112510165400612]},
    {input: [0.4375, 0.766666666666667], output: [0.0898701185996068]},
    {input: [0.365625, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.415625, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.4265625, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.3484375, 0.727777777777778], output: [0.112510165400612]},
    {input: [0.3984375, 0.722222222222222], output: [0.112510165400612]},
    {input: [0.3484375, 0.730555555555556], output: [0.112510165400612]},
    {input: [0.38125, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.3421875, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.309375, 0.775], output: [0.0898701185996068]},
    {input: [0.39375, 0.8], output: [0.0898701185996068]},
    {input: [0.33125, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.38125, 0.777777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.75], output: [0.0898701185996068]},
    {input: [0.3875, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.309375, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.303125, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.3421875, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.359375, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.2859375, 0.777777777777778], output: [0.067230071798602]},
    {input: [0.28125, 0.761111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.3375, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.3484375, 0.775], output: [0.067230071798602]},
    {input: [0.275, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.2359375, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.10625, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.55], output: [0.0219499781965924]},
    {input: [0.10625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.525], output: [0.0219499781965924]},
    {input: [0.028125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.0109375, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.1], output: [0.0219499781965924]},
    {input: [0.1234375, 0.15], output: [-0.000690068604412431]},
    {input: [0.078125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.125], output: [-0.000690068604412431]},
    {input: [0.134375, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.0777777777777778], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.194444444444444], output: [-0.000690068604412431]},
    {input: [0.184375, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.184375, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.0777777777777778], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.133333333333333], output: [-0.000690068604412431]},
    {input: [0.2859375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.2], output: [0.0219499781965924]},
    {input: [0.1734375, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.025], output: [0.0219499781965924]},
    {input: [0.16875, 0.875], output: [0.0219499781965924]},
    {input: [0.2921875, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.415625, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.4046875, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.661111111111111], output: [0.0898701185996068]},
    {input: [0.184375, 0.666666666666667], output: [0.067230071798602]},
    {input: [0.2015625, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.184375, 0.647222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.16875, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.1171875, 0.3], output: [0.0219499781965924]},
    {input: [0.10625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.325, 0.6], output: [0.0219499781965924]},
    {input: [0.33125, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.33125, 0.675], output: [0.0219499781965924]},
    {input: [0.4375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.775], output: [0.067230071798602]},
    {input: [0.471875, 0.722222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.725], output: [0.0898701185996068]},
    {input: [0.3703125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.353125, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.2921875, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.196875, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.841666666666667], output: [0.067230071798602]},
    {input: [0.190625, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.025], output: [0.0219499781965924]},
    {input: [0.1125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.113888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.05, 0.502777777777778], output: [-0.000690068604412431]},
    {input: [0.05, 0.702777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.666666666666667], output: [-0.000690068604412431]},
    {input: [0.1, 0.55], output: [0.0219499781965924]},
    {input: [0.084375, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.522222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.827777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.891666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.625], output: [-0.000690068604412431]},
    {input: [0.1125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.425], output: [0.0219499781965924]},
    {input: [0.0609375, 0.675], output: [0.0219499781965924]},
    {input: [0.04375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.55], output: [0.0219499781965924]},
    {input: [0.071875, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.447222222222222], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.725], output: [-0.000690068604412431]},
    {input: [0.028125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.1], output: [0.0219499781965924]},
    {input: [0.1, 0.0777777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.311111111111111], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.988888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.0611111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.183333333333333], output: [-0.000690068604412431]},
    {input: [0.10625, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.28125, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.184375, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.1, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.4], output: [0.0219499781965924]},
    {input: [0.309375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.38125, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.421875, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.2015625, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.134375, 0.65], output: [0.067230071798602]},
    {input: [0.078125, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.644444444444444], output: [0.067230071798602]},
    {input: [0.2578125, 0.647222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.636111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.33125, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.49375, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.39375, 0.663888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.641666666666667], output: [0.0898701185996068]},
    {input: [0.5171875, 0.672222222222222], output: [0.112510165400612]},
    {input: [0.478125, 0.694444444444444], output: [0.112510165400612]},
    {input: [0.590625, 0.713888888888889], output: [0.135150212201616]},
    {input: [0.5390625, 0.713888888888889], output: [0.157790259002621]},
    {input: [0.528125, 0.697222222222222], output: [0.135150212201616]},
    {input: [0.528125, 0.708333333333333], output: [0.135150212201616]},
    {input: [0.415625, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.365625, 0.686111111111111], output: [0.112510165400612]},
    {input: [0.2578125, 0.583333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.616666666666667], output: [0.0898701185996068]},
    {input: [0.3375, 0.625], output: [0.0898701185996068]},
    {input: [0.3984375, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.471875, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.465625, 0.7], output: [0.0898701185996068]},
    {input: [0.4890625, 0.691666666666667], output: [0.0898701185996068]},
    {input: [0.4328125, 0.719444444444444], output: [0.112510165400612]},
    {input: [0.1796875, 0.936111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.997222222222222], output: [0.0898701185996068]},
    {input: [0.66875, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.2359375, 0.0527777777777778], output: [0.135150212201616]},
    {input: [0.05, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.0390625, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.1125, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.16875, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.38125, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.6234375, 0.0916666666666667], output: [0.112510165400612]},
    {input: [0.703125, 0.075], output: [0.135150212201616]},
    {input: [0.6796875, 0.0722222222222222], output: [0.157790259002621]},
    {input: [0.6234375, 0.0611111111111111], output: [0.157790259002621]},
    {input: [0.584375, 0.05], output: [0.180430305803626]},
    {input: [0.534375, 0.0444444444444444], output: [0.203070352604631]},
    {input: [0.521875, 0.0277777777777778], output: [0.203070352604631]},
    {input: [0.5109375, 0.0277777777777778], output: [0.180430305803626]},
    {input: [0.45, 0.00555555555555556], output: [0.157790259002621]},
    {input: [0.45, 0.0305555555555556], output: [0.157790259002621]},
    {input: [0.465625, 0.0166666666666667], output: [0.157790259002621]},
    {input: [0.4046875, 0.0222222222222222], output: [0.157790259002621]},
    {input: [0.49375, 0.0333333333333333], output: [0.157790259002621]},
    {input: [0.49375, 0.0444444444444444], output: [0.157790259002621]},
    {input: [0.5171875, 0.0361111111111111], output: [0.157790259002621]},
    {input: [0.49375, 0.0472222222222222], output: [0.135150212201616]},
    {input: [0.359375, 0.0277777777777778], output: [0.135150212201616]},
    {input: [0.3484375, 0.0611111111111111], output: [0.112510165400612]},
    {input: [0.26875, 0.0638888888888889], output: [0.112510165400612]},
    {input: [0.303125, 0.111111111111111], output: [0.0898701185996068]},
    {input: [0.28125, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.2296875, 0.116666666666667], output: [0.0898701185996068]},
    {input: [0.128125, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.45, 0.0805555555555556], output: [0.0898701185996068]},
    {input: [0.646875, 0.0805555555555556], output: [0.112510165400612]},
    {input: [0.6296875, 0.0777777777777778], output: [0.135150212201616]},
    {input: [0.6625, 0.0666666666666667], output: [0.135150212201616]},
    {input: [0.6625, 0.0638888888888889], output: [0.157790259002621]},
    {input: [0.6515625, 0.0694444444444444], output: [0.157790259002621]},
    {input: [0.578125, 0.0777777777777778], output: [0.157790259002621]},
    {input: [0.534375, 0.0694444444444444], output: [0.157790259002621]},
    {input: [0.521875, 0.0638888888888889], output: [0.135150212201616]},
    {input: [0.44375, 0.075], output: [0.112510165400612]},
    {input: [0.296875, 0.125], output: [0.112510165400612]},
    {input: [0.3140625, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.246875, 0.163888888888889], output: [0.112510165400612]},
    {input: [0.2015625, 0.15], output: [0.0898701185996068]},
    {input: [0.26875, 0.119444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.144444444444444], output: [0.112510165400612]},
    {input: [0.3375, 0.169444444444444], output: [0.112510165400612]},
    {input: [0.275, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.2125, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.2078125, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.415625, 0.00277777777777778], output: [0.0445900249975972]},
    {input: [0.4546875, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.465625, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.2921875, 0.0583333333333333], output: [0.0898701185996068]},
    {input: [0.2859375, 0.0555555555555556], output: [0.067230071798602]},
    {input: [0.2859375, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.0611111111111111], output: [0.067230071798602]},
    {input: [0.2296875, 0.05], output: [0.0445900249975972]},
    {input: [0.1734375, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.425], output: [0.0219499781965924]},
    {input: [0.0390625, 0.925], output: [0.0219499781965924]},
    {input: [0.10625, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.225], output: [-0.000690068604412431]},
    {input: [0.1625, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.184375, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.075], output: [0.0445900249975972]},
    {input: [0.3375, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.3375, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.353125, 0.1], output: [0.067230071798602]},
    {input: [0.3375, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.15625, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.0328125, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.925], output: [-0.000690068604412431]},
    {input: [0.05625, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.0861111111111111], output: [-0.000690068604412431]},
    {input: [0.1625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.190625, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.225, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.180555555555556], output: [0.112510165400612]},
    {input: [0.33125, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.2921875, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.05, 0.2], output: [0.067230071798602]},
    {input: [0.0953125, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.15625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.521875, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.122222222222222], output: [0.112510165400612]},
    {input: [0.521875, 0.0638888888888889], output: [0.135150212201616]},
    {input: [0.50625, 0.0888888888888889], output: [0.135150212201616]},
    {input: [0.4828125, 0.0944444444444444], output: [0.135150212201616]},
    {input: [0.45, 0.0972222222222222], output: [0.135150212201616]},
    {input: [0.49375, 0.15], output: [0.112510165400612]},
    {input: [0.5109375, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.478125, 0.180555555555556], output: [0.112510165400612]},
    {input: [0.415625, 0.25], output: [0.112510165400612]},
    {input: [0.421875, 0.291666666666667], output: [0.135150212201616]},
    {input: [0.4265625, 0.302777777777778], output: [0.112510165400612]},
    {input: [0.309375, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.4328125, 0.216666666666667], output: [0.112510165400612]},
    {input: [0.478125, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.3140625, 0.475], output: [0.112510165400612]},
    {input: [0.325, 0.513888888888889], output: [0.112510165400612]},
    {input: [0.2296875, 0.411111111111111], output: [0.0898701185996068]},
    {input: [0.1453125, 0.113888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.3484375, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.190625, 0.2], output: [0.0445900249975972]},
    {input: [0.3984375, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.4265625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.4375, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.4546875, 0.188888888888889], output: [0.112510165400612]},
    {input: [0.3484375, 0.147222222222222], output: [0.135150212201616]},
    {input: [0.39375, 0.208333333333333], output: [0.135150212201616]},
    {input: [0.409375, 0.208333333333333], output: [0.135150212201616]},
    {input: [0.4046875, 0.102777777777778], output: [0.135150212201616]},
    {input: [0.409375, 0.102777777777778], output: [0.135150212201616]},
    {input: [0.365625, 0.208333333333333], output: [0.112510165400612]},
    {input: [0.3984375, 0.105555555555556], output: [0.0898701185996068]},
    {input: [0.4890625, 0.075], output: [0.0898701185996068]},
    {input: [0.5453125, 0.075], output: [0.0898701185996068]},
    {input: [0.4828125, 0.116666666666667], output: [0.112510165400612]},
    {input: [0.421875, 0.0861111111111111], output: [0.0898701185996068]},
    {input: [0.3421875, 0.1], output: [0.0898701185996068]},
    {input: [0.3484375, 0.122222222222222], output: [0.0898701185996068]},
    {input: [0.4828125, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.3484375, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.246875, 0.125], output: [0.067230071798602]},
    {input: [0.2578125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.2359375, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.04375, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.3984375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.4328125, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.39375, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.980555555555556], output: [0.0445900249975972]},
    {input: [0.15625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.3421875, 0.25], output: [0.0445900249975972]},
    {input: [0.325, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.3375, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.175], output: [0.0219499781965924]},
    {input: [0.2578125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.3], output: [0.0219499781965924]},
    {input: [0.2296875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.225], output: [0.067230071798602]},
    {input: [0.2078125, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.246875, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.625], output: [0.0219499781965924]},
    {input: [0.190625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.825], output: [0.0445900249975972]},
    {input: [0.05, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0109375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.852777777777778], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.3765625, 0.727777777777778], output: [-0.000690068604412431]},
    {input: [0.465625, 0.75], output: [0.0219499781965924]},
    {input: [0.4328125, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.39375, 0.716666666666667], output: [0.0898701185996068]},
    {input: [0.5171875, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.521875, 0.727777777777778], output: [0.157790259002621]},
    {input: [0.534375, 0.719444444444444], output: [0.157790259002621]},
    {input: [0.4890625, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.421875, 0.697222222222222], output: [0.135150212201616]},
    {input: [0.4328125, 0.675], output: [0.135150212201616]},
    {input: [0.4265625, 0.672222222222222], output: [0.112510165400612]},
    {input: [0.409375, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.4890625, 0.705555555555556], output: [0.112510165400612]},
    {input: [0.590625, 0.722222222222222], output: [0.135150212201616]},
    {input: [0.578125, 0.722222222222222], output: [0.157790259002621]},
    {input: [0.521875, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.6515625, 0.747222222222222], output: [0.135150212201616]},
    {input: [0.66875, 0.738888888888889], output: [0.135150212201616]},
    {input: [0.6125, 0.725], output: [0.135150212201616]},
    {input: [0.584375, 0.713888888888889], output: [0.135150212201616]},
    {input: [0.584375, 0.716666666666667], output: [0.157790259002621]},
    {input: [0.5734375, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.5671875, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.5390625, 0.738888888888889], output: [0.135150212201616]},
    {input: [0.528125, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.5390625, 0.758333333333333], output: [0.135150212201616]},
    {input: [0.465625, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.534375, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.478125, 0.75], output: [0.112510165400612]},
    {input: [0.5, 0.75], output: [0.112510165400612]},
    {input: [0.26875, 0.616666666666667], output: [0.0898701185996068]},
    {input: [0.3875, 0.666666666666667], output: [0.067230071798602]},
    {input: [0.353125, 0.655555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.4890625, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.534375, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.5453125, 0.738888888888889], output: [0.135150212201616]},
    {input: [0.5671875, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.5453125, 0.736111111111111], output: [0.112510165400612]},
    {input: [0.50625, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.5171875, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.465625, 0.752777777777778], output: [0.112510165400612]},
    {input: [0.409375, 0.758333333333333], output: [0.112510165400612]},
    {input: [0.409375, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.39375, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.3765625, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.75], output: [0.0445900249975972]},
    {input: [0.1234375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.125], output: [0.0219499781965924]},
    {input: [0.196875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.2], output: [0.0219499781965924]},
    {input: [0.1515625, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.409375, 0.725], output: [0.0219499781965924]},
    {input: [0.3875, 0.75], output: [0.0445900249975972]},
    {input: [0.303125, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.2359375, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.3703125, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.4890625, 0.0527777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.0388888888888889], output: [0.112510165400612]},
    {input: [0.4328125, 0.0444444444444444], output: [0.0898701185996068]},
    {input: [0.4046875, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.3984375, 0.0333333333333333], output: [0.0898701185996068]},
    {input: [0.353125, 0.025], output: [0.0898701185996068]},
    {input: [0.3765625, 0.0222222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.2859375, 0.0388888888888889], output: [0.0898701185996068]},
    {input: [0.21875, 0.997222222222222], output: [0.0898701185996068]},
    {input: [0.0671875, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.134375, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.140625, 0.675], output: [0.0445900249975972]},
    {input: [0.2640625, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.675], output: [0.067230071798602]},
    {input: [0.2078125, 0.6], output: [0.0445900249975972]},
    {input: [0.1734375, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.2125, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.625], output: [0.0219499781965924]},
    {input: [0.0890625, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.4], output: [0.0219499781965924]},
    {input: [0.196875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.3], output: [0.0219499781965924]},
    {input: [0.3375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.365625, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.240625, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0527777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.325, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.38125, 0.075], output: [0.0445900249975972]},
    {input: [0.4046875, 0.0388888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.0222222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.00555555555555556], output: [0.0898701185996068]},
    {input: [0.296875, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.2859375, 0.075], output: [0.0898701185996068]},
    {input: [0.2296875, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.2078125, 0.0583333333333333], output: [0.067230071798602]},
    {input: [0.05625, 0.0611111111111111], output: [0.067230071798602]},
    {input: [0.134375, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.2359375, 0.625], output: [0.0445900249975972]},
    {input: [0.3375, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.1796875, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.3875, 0.0527777777777778], output: [0.0445900249975972]},
    {input: [0.5, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.5171875, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.3765625, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.39375, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.3203125, 0.0527777777777778], output: [0.067230071798602]},
    {input: [0.2015625, 0.0777777777777778], output: [0.067230071798602]},
    {input: [0.078125, 0.961111111111111], output: [0.067230071798602]},
    {input: [0.04375, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.084375, 0.530555555555556], output: [0.067230071798602]},
    {input: [0.0390625, 0.15], output: [0.0445900249975972]},
    {input: [0.0671875, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.55], output: [0.0445900249975972]},
    {input: [0.0671875, 0.3], output: [0.0445900249975972]},
    {input: [0.05625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.3421875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.415625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.3421875, 0.441666666666667], output: [0.067230071798602]},
    {input: [0.584375, 0.483333333333333], output: [0.067230071798602]},
    {input: [0.365625, 0.444444444444444], output: [0.067230071798602]},
    {input: [0.2578125, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.421875, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.3703125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.4375, 0.225], output: [0.067230071798602]},
    {input: [0.5453125, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.202777777777778], output: [0.135150212201616]},
    {input: [0.5, 0.216666666666667], output: [0.157790259002621]},
    {input: [0.50625, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.4828125, 0.236111111111111], output: [0.157790259002621]},
    {input: [0.39375, 0.191666666666667], output: [0.135150212201616]},
    {input: [0.3765625, 0.15], output: [0.157790259002621]},
    {input: [0.365625, 0.108333333333333], output: [0.135150212201616]},
    {input: [0.33125, 0.108333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.15], output: [0.112510165400612]},
    {input: [0.38125, 0.133333333333333], output: [0.112510165400612]},
    {input: [0.2921875, 0.127777777777778], output: [0.0898701185996068]},
    {input: [0.44375, 0.172222222222222], output: [0.0898701185996068]},
    {input: [0.471875, 0.213888888888889], output: [0.112510165400612]},
    {input: [0.4609375, 0.219444444444444], output: [0.135150212201616]},
    {input: [0.3875, 0.205555555555556], output: [0.157790259002621]},
    {input: [0.409375, 0.141666666666667], output: [0.157790259002621]},
    {input: [0.39375, 0.186111111111111], output: [0.135150212201616]},
    {input: [0.49375, 0.244444444444444], output: [0.135150212201616]},
    {input: [0.5, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.45, 0.230555555555556], output: [0.135150212201616]},
    {input: [0.3421875, 0.261111111111111], output: [0.135150212201616]},
    {input: [0.39375, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.463888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.3875, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.4890625, 0.45], output: [0.067230071798602]},
    {input: [0.3875, 0.452777777777778], output: [0.0445900249975972]},
    {input: [0.3703125, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.3703125, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.38125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.225, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.3203125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.415625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.33125, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.2125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.2921875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.225], output: [0.0445900249975972]},
    {input: [0.2578125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.365625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.3984375, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.39375, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.33125, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.26875, 0.147222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.144444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.3421875, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.0916666666666667], output: [0.067230071798602]},
    {input: [0.325, 0.0888888888888889], output: [0.067230071798602]},
    {input: [0.28125, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.125], output: [0.0445900249975972]},
    {input: [0.33125, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.3421875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.38125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.125], output: [0.067230071798602]},
    {input: [0.296875, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.1171875, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.33125, 0.0861111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.303125, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.38125, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.309375, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.26875, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.1515625, 0.458333333333333], output: [0.067230071798602]},
    {input: [0.2859375, 0.530555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.38125, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.39375, 0.711111111111111], output: [0.0898701185996068]},
    {input: [0.3140625, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.246875, 0.8], output: [0.0898701185996068]},
    {input: [0.196875, 0.722222222222222], output: [0.067230071798602]},
    {input: [0.1, 0.775], output: [0.067230071798602]},
    {input: [0.1171875, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.38125, 0.45], output: [0.0219499781965924]},
    {input: [0.325, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.475], output: [0.0219499781965924]},
    {input: [0.359375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.471875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.5109375, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.4265625, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.3765625, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.3484375, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.45, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.421875, 0.225], output: [0.0898701185996068]},
    {input: [0.4328125, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.421875, 0.175], output: [0.0898701185996068]},
    {input: [0.3984375, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.421875, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.3703125, 0.225], output: [0.0898701185996068]},
    {input: [0.3875, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.3703125, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.421875, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.409375, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.465625, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.4375, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.4265625, 0.188888888888889], output: [0.157790259002621]},
    {input: [0.4375, 0.169444444444444], output: [0.135150212201616]},
    {input: [0.415625, 0.155555555555556], output: [0.157790259002621]},
    {input: [0.4375, 0.113888888888889], output: [0.135150212201616]},
    {input: [0.421875, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.421875, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.3484375, 0.0666666666666667], output: [0.112510165400612]},
    {input: [0.325, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.1734375, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.3421875, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.0527777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.0472222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.0444444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.1], output: [0.0445900249975972]},
    {input: [0.303125, 0.1], output: [0.067230071798602]},
    {input: [0.2921875, 0.0944444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.0833333333333333], output: [0.067230071798602]},
    {input: [0.325, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.33125, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.0944444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.05625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.15], output: [0.0219499781965924]},
    {input: [0.2359375, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.125], output: [0.0219499781965924]},
    {input: [0.1625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.528125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.50625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.49375, 0.736111111111111], output: [0.0898701185996068]},
    {input: [0.471875, 0.686111111111111], output: [0.112510165400612]},
    {input: [0.246875, 0.633333333333333], output: [0.0898701185996068]},
    {input: [0.26875, 0.572222222222222], output: [0.0898701185996068]},
    {input: [0.21875, 0.527777777777778], output: [0.067230071798602]},
    {input: [0.2296875, 0.536111111111111], output: [0.067230071798602]},
    {input: [0.2921875, 0.563888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.725], output: [0.0445900249975972]},
    {input: [0.0953125, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.925], output: [0.0445900249975972]},
    {input: [0.28125, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.10625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.8], output: [0.0219499781965924]},
    {input: [0.1515625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.7], output: [0.0219499781965924]},
    {input: [0.240625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.675], output: [0.0219499781965924]},
    {input: [0.084375, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.658333333333333], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.078125, 0.475], output: [0.0219499781965924]},
    {input: [0.084375, 0.655555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.897222222222222], output: [-0.000690068604412431]},
    {input: [0.1, 0.7], output: [0.0219499781965924]},
    {input: [0.140625, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.134375, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.0671875, 0.475], output: [0.0219499781965924]},
    {input: [0.04375, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.8], output: [0.0219499781965924]},
    {input: [0.1515625, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.2921875, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.0361111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.591666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.296875, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.75], output: [0.0445900249975972]},
    {input: [0.253125, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.134375, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.691666666666667], output: [0.0898701185996068]},
    {input: [0.3875, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.421875, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.5390625, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.465625, 0.75], output: [0.112510165400612]},
    {input: [0.415625, 0.766666666666667], output: [0.112510165400612]},
    {input: [0.3984375, 0.747222222222222], output: [0.112510165400612]},
    {input: [0.353125, 0.775], output: [0.112510165400612]},
    {input: [0.190625, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.1234375, 0.725], output: [0.0445900249975972]},
    {input: [0.05, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.028125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.525], output: [0.0219499781965924]},
    {input: [0.134375, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.627777777777778], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.1625, 0.675], output: [0.0219499781965924]},
    {input: [0.1453125, 0.75], output: [-0.000690068604412431]},
    {input: [0.10625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.05], output: [0.0219499781965924]},
    {input: [0.196875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.3140625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.475], output: [0.0219499781965924]},
    {input: [0.21875, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.875], output: [0.0219499781965924]},
    {input: [0.1171875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.597222222222222], output: [0.0898701185996068]},
    {input: [0.303125, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.3203125, 0.588888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.625], output: [0.067230071798602]},
    {input: [0.2859375, 0.619444444444444], output: [0.0898701185996068]},
    {input: [0.26875, 0.655555555555556], output: [0.0898701185996068]},
    {input: [0.3484375, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.184375, 0.797222222222222], output: [0.067230071798602]},
    {input: [0.0671875, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.05625, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.386111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.33125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.2], output: [0.0445900249975972]},
    {input: [0.3421875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.15], output: [0.067230071798602]},
    {input: [0.3140625, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.25], output: [0.0219499781965924]},
    {input: [0.1, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.575], output: [0.0219499781965924]},
    {input: [0.190625, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.505555555555556], output: [-0.000690068604412431]},
    {input: [0.16875, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.175], output: [0.0219499781965924]},
    {input: [0.45, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.409375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.325, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.353125, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.3703125, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.44375, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.5, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.5, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.5, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.471875, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.675, 0.25], output: [0.112510165400612]},
    {input: [0.55, 0.258333333333333], output: [0.157790259002621]},
    {input: [0.578125, 0.258333333333333], output: [0.157790259002621]},
    {input: [0.4546875, 0.25], output: [0.180430305803626]},
    {input: [0.296875, 0.35], output: [0.157790259002621]},
    {input: [0.1734375, 0.327777777777778], output: [0.157790259002621]},
    {input: [0.2921875, 0.3], output: [0.112510165400612]},
    {input: [0.1171875, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.2578125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.4546875, 0.075], output: [0.0445900249975972]},
    {input: [0.45, 0.15], output: [0.0445900249975972]},
    {input: [0.55, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.6125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.578125, 0.236111111111111], output: [0.135150212201616]},
    {input: [0.6296875, 0.269444444444444], output: [0.157790259002621]},
    {input: [0.675, 0.302777777777778], output: [0.203070352604631]},
    {input: [0.5734375, 0.411111111111111], output: [0.180430305803626]},
    {input: [0.478125, 0.486111111111111], output: [0.135150212201616]},
    {input: [0.3984375, 0.563888888888889], output: [0.0898701185996068]},
    {input: [0.83125, 0.527777777777778], output: [0.067230071798602]},
    {input: [0.5734375, 0.497222222222222], output: [0.135150212201616]},
    {input: [0.528125, 0.525], output: [0.0898701185996068]},
    {input: [0.5171875, 0.491666666666667], output: [0.112510165400612]},
    {input: [0.49375, 0.491666666666667], output: [0.112510165400612]},
    {input: [0.5, 0.466666666666667], output: [0.0898701185996068]},
    {input: [0.590625, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.425], output: [0.112510165400612]},
    {input: [0.365625, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.3984375, 0.469444444444444], output: [0.067230071798602]},
    {input: [0.584375, 0.447222222222222], output: [0.067230071798602]},
    {input: [0.4828125, 0.45], output: [0.067230071798602]},
    {input: [0.471875, 0.413888888888889], output: [0.067230071798602]},
    {input: [0.521875, 0.469444444444444], output: [0.067230071798602]},
    {input: [0.5734375, 0.444444444444444], output: [0.067230071798602]},
    {input: [0.5453125, 0.441666666666667], output: [0.0898701185996068]},
    {input: [0.55, 0.441666666666667], output: [0.0898701185996068]},
    {input: [0.471875, 0.463888888888889], output: [0.0898701185996068]},
    {input: [0.528125, 0.469444444444444], output: [0.0898701185996068]},
    {input: [0.303125, 0.575], output: [0.0898701185996068]},
    {input: [0.325, 0.655555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.1515625, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.3484375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.44375, 0.755555555555556], output: [0.067230071798602]},
    {input: [0.471875, 0.752777777777778], output: [0.135150212201616]},
    {input: [0.421875, 0.761111111111111], output: [0.112510165400612]},
    {input: [0.3140625, 0.713888888888889], output: [0.112510165400612]},
    {input: [0.0953125, 0.702777777777778], output: [0.0898701185996068]},
    {input: [0.16875, 0.608333333333333], output: [0.067230071798602]},
    {input: [0.0953125, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.028125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.084375, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.641666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.775], output: [0.0445900249975972]},
    {input: [0.275, 0.0361111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.05], output: [0.0219499781965924]},
    {input: [0.0890625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.0666666666666667], output: [0.067230071798602]},
    {input: [0.0890625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.1, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.844444444444444], output: [-0.000690068604412431]},
    {input: [0.05, 0.797222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.908333333333333], output: [-0.000690068604412431]},
    {input: [0.078125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.50625, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.5453125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.3875, 0.438888888888889], output: [0.0898701185996068]},
    {input: [0.4265625, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.521875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.521875, 0.35], output: [0.067230071798602]},
    {input: [0.534375, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.309375, 0.486111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.441666666666667], output: [0.112510165400612]},
    {input: [0.325, 0.436111111111111], output: [0.0898701185996068]},
    {input: [0.365625, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.478125, 0.736111111111111], output: [0.067230071798602]},
    {input: [0.1734375, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.225, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.471875, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.505555555555556], output: [0.112510165400612]},
    {input: [0.2859375, 0.494444444444444], output: [0.0898701185996068]},
    {input: [0.296875, 0.527777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.471875, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.415625, 0.611111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.630555555555556], output: [0.0898701185996068]},
    {input: [0.359375, 0.627777777777778], output: [0.0898701185996068]},
    {input: [0.4328125, 0.65], output: [0.0898701185996068]},
    {input: [0.3203125, 0.677777777777778], output: [0.0898701185996068]},
    {input: [0.1734375, 0.541666666666667], output: [0.112510165400612]},
    {input: [0.16875, 0.497222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.641666666666667], output: [0.067230071798602]},
    {input: [0.5625, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.353125, 0.725], output: [0.067230071798602]},
    {input: [0.3140625, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.15625, 0.513888888888889], output: [0.0898701185996068]},
    {input: [0.021875, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.0609375, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.0390625, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.190625, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.4609375, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.525], output: [0.0219499781965924]},
    {input: [0.1796875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.563888888888889], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.516666666666667], output: [-0.000690068604412431]},
    {input: [0.1, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.0953125, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.196875, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.2921875, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.647222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.2015625, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.15625, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.8], output: [0.0445900249975972]},
    {input: [0.16875, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.1], output: [0.0219499781965924]},
    {input: [0.071875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.825], output: [0.0219499781965924]},
    {input: [0.353125, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.5625, 0.719444444444444], output: [0.0445900249975972]},
    {input: [0.6796875, 0.733333333333333], output: [0.0898701185996068]},
    {input: [0.7359375, 0.733333333333333], output: [0.135150212201616]},
    {input: [0.7359375, 0.736111111111111], output: [0.180430305803626]},
    {input: [0.7359375, 0.727777777777778], output: [0.180430305803626]},
    {input: [0.696875, 0.747222222222222], output: [0.157790259002621]},
    {input: [0.6578125, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.640625, 0.725], output: [0.135150212201616]},
    {input: [0.61875, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.590625, 0.725], output: [0.135150212201616]},
    {input: [0.55, 0.725], output: [0.135150212201616]},
    {input: [0.5453125, 0.736111111111111], output: [0.135150212201616]},
    {input: [0.4828125, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.409375, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.3765625, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.275, 0.763888888888889], output: [0.0898701185996068]},
    {input: [0.190625, 0.580555555555556], output: [0.067230071798602]},
    {input: [0.2296875, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.465625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.49375, 0.0388888888888889], output: [0.067230071798602]},
    {input: [0.478125, 0.358333333333333], output: [0.0898701185996068]},
    {input: [0.38125, 0.372222222222222], output: [0.112510165400612]},
    {input: [0.3140625, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.190625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.155555555555556], output: [0.0445900249975972]},
    {input: [0.359375, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.465625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.5625, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.55625, 0.152777777777778], output: [0.157790259002621]},
    {input: [0.5171875, 0.191666666666667], output: [0.203070352604631]},
    {input: [0.4890625, 0.205555555555556], output: [0.180430305803626]},
    {input: [0.478125, 0.194444444444444], output: [0.180430305803626]},
    {input: [0.5109375, 0.191666666666667], output: [0.135150212201616]},
    {input: [0.5, 0.0833333333333333], output: [0.157790259002621]},
    {input: [0.4375, 0.0972222222222222], output: [0.135150212201616]},
    {input: [0.38125, 0.0527777777777778], output: [0.112510165400612]},
    {input: [0.325, 0.0277777777777778], output: [0.0898701185996068]},
    {input: [0.2578125, 0.0388888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.969444444444444], output: [0.067230071798602]},
    {input: [0.225, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.025], output: [0.0445900249975972]},
    {input: [0.1515625, 0.0222222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.980555555555556], output: [0.0445900249975972]},
    {input: [0.05, 0.7], output: [0.0445900249975972]},
    {input: [0.1453125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.825], output: [0.0219499781965924]},
    {input: [0.190625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.3421875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.4546875, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.4265625, 0.708333333333333], output: [0.0898701185996068]},
    {input: [0.49375, 0.738888888888889], output: [0.112510165400612]},
    {input: [0.49375, 0.738888888888889], output: [0.135150212201616]},
    {input: [0.4890625, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.465625, 0.755555555555556], output: [0.112510165400612]},
    {input: [0.471875, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.359375, 0.75], output: [0.112510165400612]},
    {input: [0.2859375, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.21875, 0.711111111111111], output: [0.0898701185996068]},
    {input: [0.184375, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.2578125, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.2078125, 0.725], output: [0.0445900249975972]},
    {input: [0.1796875, 0.675], output: [0.0445900249975972]},
    {input: [0.1625, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.527777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.527777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.2], output: [0.0219499781965924]},
    {input: [0.39375, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.45, 0.925], output: [0.0445900249975972]},
    {input: [0.359375, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.1625, 0.0638888888888889], output: [0.067230071798602]},
    {input: [0.0953125, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.85], output: [0.0219499781965924]},
    {input: [0.1796875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.716666666666667], output: [-0.000690068604412431]},
    {input: [0.16875, 0.9], output: [0.0219499781965924]},
    {input: [0.253125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.38125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.359375, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.697222222222222], output: [0.067230071798602]},
    {input: [0.4546875, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.5171875, 0.733333333333333], output: [0.067230071798602]},
    {input: [0.5109375, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.5171875, 0.741666666666667], output: [0.112510165400612]},
    {input: [0.5, 0.730555555555556], output: [0.112510165400612]},
    {input: [0.5171875, 0.744444444444444], output: [0.135150212201616]},
    {input: [0.478125, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.4375, 0.730555555555556], output: [0.135150212201616]},
    {input: [0.325, 0.75], output: [0.112510165400612]},
    {input: [0.2296875, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.0890625, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.246875, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.2125, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.55], output: [0.0219499781965924]},
    {input: [0.184375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.303125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.353125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.38125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.2296875, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.4265625, 0.15], output: [0.0219499781965924]},
    {input: [0.61875, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.815625, 0.152777777777778], output: [0.112510165400612]},
    {input: [0.7078125, 0.152777777777778], output: [0.157790259002621]},
    {input: [0.71875, 0.108333333333333], output: [0.203070352604631]},
    {input: [0.66875, 0.075], output: [0.203070352604631]},
    {input: [0.703125, 0.0333333333333333], output: [0.203070352604631]},
    {input: [0.6859375, 0.05], output: [0.180430305803626]},
    {input: [0.5625, 0.0583333333333333], output: [0.180430305803626]},
    {input: [0.66875, 0.0583333333333333], output: [0.180430305803626]},
    {input: [0.55625, 0.0666666666666667], output: [0.157790259002621]},
    {input: [0.534375, 0.0722222222222222], output: [0.135150212201616]},
    {input: [0.5390625, 0.0638888888888889], output: [0.135150212201616]},
    {input: [0.5, 0.075], output: [0.112510165400612]},
    {input: [0.49375, 0.0694444444444444], output: [0.112510165400612]},
    {input: [0.465625, 0.108333333333333], output: [0.112510165400612]},
    {input: [0.6125, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.409375, 0.480555555555556], output: [0.112510165400612]},
    {input: [0.421875, 0.541666666666667], output: [0.0898701185996068]},
    {input: [0.309375, 0.483333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.4328125, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.4546875, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.471875, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.425], output: [0.0445900249975972]},
    {input: [0.4046875, 0.425], output: [0.0445900249975972]},
    {input: [0.3140625, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.415625, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.2859375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.177777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.078125, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.05, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.225], output: [0.0219499781965924]},
    {input: [0.128125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.225], output: [0.0219499781965924]},
    {input: [0.1625, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.353125, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.421875, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.39375, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.3703125, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.0972222222222222], output: [0.0898701185996068]},
    {input: [0.409375, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.5109375, 0.0888888888888889], output: [0.0898701185996068]},
    {input: [0.3984375, 0.0944444444444444], output: [0.112510165400612]},
    {input: [0.465625, 0.130555555555556], output: [0.112510165400612]},
    {input: [0.3875, 0.127777777777778], output: [0.112510165400612]},
    {input: [0.3765625, 0.0944444444444444], output: [0.112510165400612]},
    {input: [0.296875, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.190625, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.296875, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.2640625, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.2859375, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.60625, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.5671875, 0.122222222222222], output: [0.135150212201616]},
    {input: [0.50625, 0.0472222222222222], output: [0.135150212201616]},
    {input: [0.521875, 0.05], output: [0.112510165400612]},
    {input: [0.50625, 0.119444444444444], output: [0.112510165400612]},
    {input: [0.4265625, 0.275], output: [0.112510165400612]},
    {input: [0.253125, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.1796875, 0.15], output: [0.067230071798602]},
    {input: [0.196875, 0.0638888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0138888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.3984375, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.3984375, 0.0722222222222222], output: [0.067230071798602]},
    {input: [0.365625, 0.05], output: [0.067230071798602]},
    {input: [0.3875, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.246875, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.28125, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.2359375, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.071875, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.7], output: [0.0219499781965924]},
    {input: [0.1171875, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.925], output: [0.0219499781965924]},
    {input: [0.04375, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.04375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.775], output: [0.0219499781965924]},
    {input: [0.1625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.7], output: [0.0219499781965924]},
    {input: [0.0328125, 0.469444444444444], output: [-0.000690068604412431]},
    {input: [0.04375, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.894444444444444], output: [-0.000690068604412431]},
    {input: [0.028125, 0.894444444444444], output: [-0.000690068604412431]},
    {input: [0.028125, 0.319444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.0046875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.775], output: [0.0219499781965924]},
    {input: [0.1453125, 0.983333333333333], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.0277777777777778], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.190625, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.0953125, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.3421875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.15], output: [0.0219499781965924]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.125], output: [0.0219499781965924]},
    {input: [0.225, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.253125, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.136111111111111], output: [-0.000690068604412431]},
    {input: [0.28125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.3703125, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.4375, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.15], output: [0.067230071798602]},
    {input: [0.2859375, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.175], output: [0.0445900249975972]},
    {input: [0.2015625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.0972222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.1], output: [0.0219499781965924]},
    {input: [0.2296875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.3984375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2859375, 0.325], output: [0.0445900249975972]},
    {input: [0.190625, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.4265625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.3484375, 0.163888888888889], output: [-0.000690068604412431]},
    {input: [0.21875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.325], output: [0.0219499781965924]},
    {input: [0.246875, 0.294444444444444], output: [-0.000690068604412431]},
    {input: [0.2921875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.39375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.4], output: [0.067230071798602]},
    {input: [0.2015625, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.4328125, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.359375, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.625], output: [0.0219499781965924]},
    {input: [0.1234375, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.625], output: [0.0219499781965924]},
    {input: [0.1515625, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.0046875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.0109375, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.1], output: [0.0219499781965924]},
    {input: [0.05, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.516666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.775], output: [0.0219499781965924]},
    {input: [0.10625, 0.591666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.669444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.00555555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.491666666666667], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.35], output: [0.0219499781965924]},
    {input: [0.10625, 0.363888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.425], output: [0.0219499781965924]},
    {input: [0.1515625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.3484375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.3421875, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.240625, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.1796875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.3984375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.2015625, 0.0361111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.0611111111111111], output: [0.0445900249975972]},
    {input: [0.415625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.4828125, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.5171875, 0.0194444444444444], output: [0.067230071798602]},
    {input: [0.4265625, 0.0222222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.986111111111111], output: [0.067230071798602]},
    {input: [0.28125, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.2296875, 0.0277777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.0166666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.525], output: [0.0219499781965924]},
    {input: [0.190625, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.416666666666667], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.283333333333333], output: [-0.000690068604412431]},
    {input: [0.078125, 0.0611111111111111], output: [-0.000690068604412431]},
    {input: [0.15625, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.175], output: [0.0219499781965924]},
    {input: [0.2015625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.125], output: [0.0219499781965924]},
    {input: [0.2859375, 0.125], output: [0.0219499781965924]},
    {input: [0.3203125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.152777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.136111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.122222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.35], output: [0.0219499781965924]},
    {input: [0.0671875, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.766666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.775], output: [0.0219499781965924]},
    {input: [0.15625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.947222222222222], output: [-0.000690068604412431]},
    {input: [0.140625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.190625, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.50625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.49375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.465625, 0.711111111111111], output: [0.112510165400612]},
    {input: [0.465625, 0.702777777777778], output: [0.112510165400612]},
    {input: [0.4375, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.3703125, 0.716666666666667], output: [0.112510165400612]},
    {input: [0.3421875, 0.722222222222222], output: [0.112510165400612]},
    {input: [0.1734375, 0.75], output: [0.0898701185996068]},
    {input: [0.1515625, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.04375, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.196875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.425], output: [0.0219499781965924]},
    {input: [0.04375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.6], output: [0.0219499781965924]},
    {input: [0.1, 0.925], output: [0.0219499781965924]},
    {input: [0.10625, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.983333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.997222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.225], output: [0.0219499781965924]},
    {input: [0.240625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.3203125, 0.3], output: [0.0219499781965924]},
    {input: [0.309375, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.471875, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.3984375, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.3875, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.309375, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.3765625, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.38125, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.409375, 0.461111111111111], output: [0.067230071798602]},
    {input: [0.359375, 0.444444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.4], output: [0.0445900249975972]},
    {input: [0.359375, 0.425], output: [0.0445900249975972]},
    {input: [0.3375, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.575], output: [0.0445900249975972]},
    {input: [0.246875, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.38125, 0.627777777777778], output: [0.067230071798602]},
    {input: [0.415625, 0.633333333333333], output: [0.112510165400612]},
    {input: [0.3984375, 0.636111111111111], output: [0.112510165400612]},
    {input: [0.3984375, 0.652777777777778], output: [0.112510165400612]},
    {input: [0.296875, 0.636111111111111], output: [0.112510165400612]},
    {input: [0.16875, 0.725], output: [0.0898701185996068]},
    {input: [0.196875, 0.761111111111111], output: [0.0898701185996068]},
    {input: [0.16875, 0.880555555555556], output: [0.0898701185996068]},
    {input: [0.1625, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.1125, 0.9], output: [0.0445900249975972]},
    {input: [0.0953125, 0.983333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.116666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.5734375, 0.525], output: [0.0219499781965924]},
    {input: [0.4546875, 0.5], output: [0.0445900249975972]},
    {input: [0.33125, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.525], output: [0.0445900249975972]},
    {input: [0.2578125, 0.475], output: [0.0219499781965924]},
    {input: [0.15625, 0.5], output: [0.0219499781965924]},
    {input: [0.1234375, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.184375, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.583333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.622222222222222], output: [0.067230071798602]},
    {input: [0.2921875, 0.641666666666667], output: [0.067230071798602]},
    {input: [0.2640625, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.0390625, 0.025], output: [0.0445900249975972]},
    {input: [0.028125, 0.225], output: [0.0219499781965924]},
    {input: [0.0953125, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.55], output: [0.0219499781965924]},
    {input: [0.1734375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.561111111111111], output: [0.067230071798602]},
    {input: [0.078125, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.0390625, 0.577777777777778], output: [0.0445900249975972]},
    {input: [0.078125, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.119444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.527777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.5], output: [0.0219499781965924]},
    {input: [0.190625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.5], output: [0.0445900249975972]},
    {input: [0.1171875, 0.425], output: [0.0219499781965924]},
    {input: [0.0328125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.33125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.3875, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.353125, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.1453125, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.2125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.3421875, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.365625, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.409375, 0.141666666666667], output: [0.0445900249975972]},
    {input: [0.409375, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.240625, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.15625, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.5171875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.6296875, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.5953125, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.634375, 0.255555555555556], output: [0.135150212201616]},
    {input: [0.66875, 0.225], output: [0.157790259002621]},
    {input: [0.6125, 0.213888888888889], output: [0.180430305803626]},
    {input: [0.55, 0.236111111111111], output: [0.180430305803626]},
    {input: [0.55625, 0.2], output: [0.180430305803626]},
    {input: [0.55625, 0.197222222222222], output: [0.203070352604631]},
    {input: [0.6296875, 0.230555555555556], output: [0.203070352604631]},
    {input: [0.578125, 0.225], output: [0.203070352604631]},
    {input: [0.746875, 0.252777777777778], output: [0.203070352604631]},
    {input: [0.753125, 0.25], output: [0.203070352604631]},
    {input: [0.2359375, 0.280555555555556], output: [0.157790259002621]},
    {input: [0.409375, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.3375, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.246875, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.8], output: [0.067230071798602]},
    {input: [0.3140625, 0.85], output: [0.067230071798602]},
    {input: [0.21875, 0.497222222222222], output: [0.0898701185996068]},
    {input: [0.140625, 0.591666666666667], output: [0.067230071798602]},
    {input: [0.1625, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.675], output: [0.0219499781965924]},
    {input: [0.240625, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.409375, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.45], output: [0.0445900249975972]},
    {input: [0.3421875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.16875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.191666666666667], output: [-0.000690068604412431]},
    {input: [0.246875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.39375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.359375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.4546875, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.38125, 0.175], output: [0.067230071798602]},
    {input: [0.4046875, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.365625, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.296875, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.33125, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.296875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.0671875, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.275], output: [0.0219499781965924]},
    {input: [0.1171875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.283333333333333], output: [-0.000690068604412431]},
    {input: [0.196875, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.2640625, 0.280555555555556], output: [-0.000690068604412431]},
    {input: [0.196875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.3421875, 0.275], output: [0.0219499781965924]},
    {input: [0.3484375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2578125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.365625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.3140625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.3765625, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.3703125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.409375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.175], output: [0.0219499781965924]},
    {input: [0.10625, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.725], output: [0.0219499781965924]},
    {input: [0.1734375, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.3421875, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.275, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.365625, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.3984375, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.383333333333333], output: [-0.000690068604412431]},
    {input: [0.3703125, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.55625, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.7421875, 0.4], output: [0.067230071798602]},
    {input: [0.7421875, 0.413888888888889], output: [0.135150212201616]},
    {input: [0.50625, 0.402777777777778], output: [0.180430305803626]},
    {input: [0.7703125, 0.680555555555556], output: [0.135150212201616]},
    {input: [0.815625, 0.691666666666667], output: [0.157790259002621]},
    {input: [0.7984375, 0.683333333333333], output: [0.157790259002621]},
    {input: [0.865625, 0.686111111111111], output: [0.157790259002621]},
    {input: [0.578125, 0.713888888888889], output: [0.157790259002621]},
    {input: [0.6578125, 0.686111111111111], output: [0.135150212201616]},
    {input: [0.478125, 0.680555555555556], output: [0.112510165400612]},
    {input: [0.45, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.5, 0.675], output: [0.067230071798602]},
    {input: [0.5671875, 0.683333333333333], output: [0.0898701185996068]},
    {input: [0.240625, 0.747222222222222], output: [0.0898701185996068]},
    {input: [0.4546875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.3875, 0.783333333333333], output: [0.067230071798602]},
    {input: [0.2078125, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.7], output: [0.067230071798602]},
    {input: [0.2015625, 0.725], output: [0.112510165400612]},
    {input: [0.184375, 0.797222222222222], output: [0.0898701185996068]},
    {input: [0.2359375, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.2078125, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.21875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.805555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.4609375, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.5, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.50625, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.5390625, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.49375, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.528125, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.4265625, 0.869444444444444], output: [0.112510165400612]},
    {input: [0.15625, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.240625, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.05, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.525], output: [0.0445900249975972]},
    {input: [0.0890625, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.05], output: [0.067230071798602]},
    {input: [0.2015625, 0.0583333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.078125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.1171875, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.190625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.3], output: [0.0219499781965924]},
    {input: [0.225, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.302777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.35], output: [-0.000690068604412431]},
    {input: [0.134375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.875], output: [0.0219499781965924]},
    {input: [0.0671875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.586111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.475], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.202777777777778], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.261111111111111], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2921875, 0.3], output: [0.0219499781965924]},
    {input: [0.225, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.303125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.1, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.075], output: [0.0219499781965924]},
    {input: [0.078125, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.015625, 0.155555555555556], output: [-0.000690068604412431]},
    {input: [0.021875, 0.427777777777778], output: [-0.000690068604412431]},
    {input: [0.05625, 0.997222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.188888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.577777777777778], output: [0.0445900249975972]},
    {input: [0.071875, 0.422222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.325], output: [0.0219499781965924]},
    {input: [0.10625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.230555555555556], output: [-0.000690068604412431]},
    {input: [0.246875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.35], output: [0.0219499781965924]},
    {input: [0.4046875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.409375, 0.3], output: [0.0445900249975972]},
    {input: [0.39375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.28125, 0.325], output: [0.0445900249975972]},
    {input: [0.2359375, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.355555555555556], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.330555555555556], output: [-0.000690068604412431]},
    {input: [0.3484375, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.33125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.4265625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.359375, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.411111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.353125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.275], output: [0.0219499781965924]},
    {input: [0.134375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.291666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.425], output: [0.0219499781965924]},
    {input: [0.05625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.2859375, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.463888888888889], output: [-0.000690068604412431]},
    {input: [0.15625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.55], output: [-0.000690068604412431]},
    {input: [0.2578125, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.7], output: [0.0219499781965924]},
    {input: [0.28125, 0.641666666666667], output: [0.067230071798602]},
    {input: [0.2921875, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.421875, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.3875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.33125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.3484375, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.240625, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.309375, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.303125, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.647222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.5], output: [0.0219499781965924]},
    {input: [0.21875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.916666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.0361111111111111], output: [-0.000690068604412431]},
    {input: [0.10625, 0.35], output: [0.0219499781965924]},
    {input: [0.05625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.891666666666667], output: [-0.000690068604412431]},
    {input: [0.309375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.196875, 0.725], output: [0.0445900249975972]},
    {input: [0.4828125, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.45, 0.855555555555556], output: [0.112510165400612]},
    {input: [0.4609375, 0.852777777777778], output: [0.112510165400612]},
    {input: [0.4609375, 0.855555555555556], output: [0.112510165400612]},
    {input: [0.4375, 0.863888888888889], output: [0.112510165400612]},
    {input: [0.409375, 0.847222222222222], output: [0.0898701185996068]},
    {input: [0.33125, 0.825], output: [0.0898701185996068]},
    {input: [0.1515625, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.253125, 0.925], output: [0.067230071798602]},
    {input: [0.225, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.0609375, 0.075], output: [0.0445900249975972]},
    {input: [0.084375, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.625], output: [0.0219499781965924]},
    {input: [0.296875, 0.65], output: [0.0219499781965924]},
    {input: [0.253125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.725], output: [0.0219499781965924]},
    {input: [0.16875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.75], output: [0.0219499781965924]},
    {input: [0.0609375, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.071875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.369444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.380555555555556], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.0916666666666667], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.119444444444444], output: [-0.000690068604412431]},
    {input: [0.1125, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.04375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.913888888888889], output: [-0.000690068604412431]},
    {input: [0.084375, 0.830555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.85], output: [-0.000690068604412431]},
    {input: [0.1, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.309375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.9], output: [0.0219499781965924]},
    {input: [0.275, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.925], output: [0.0219499781965924]},
    {input: [0.2578125, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.925], output: [0.0219499781965924]},
    {input: [0.240625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.65], output: [0.0219499781965924]},
    {input: [0.0609375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.952777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.925], output: [0.0219499781965924]},
    {input: [0.10625, 0.841666666666667], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.944444444444444], output: [-0.000690068604412431]},
    {input: [0.05, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.1, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.861111111111111], output: [-0.000690068604412431]},
    {input: [0.10625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.152777777777778], output: [-0.000690068604412431]},
    {input: [0.1625, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.16875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.35], output: [0.0445900249975972]},
    {input: [0.240625, 0.35], output: [0.0445900249975972]},
    {input: [0.240625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.3421875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.325, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.4], output: [0.0219499781965924]},
    {input: [0.26875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.3], output: [0.0445900249975972]},
    {input: [0.3140625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.38125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.4328125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3703125, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.3875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.2640625, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.4375, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.4], output: [0.0219499781965924]},
    {input: [0.28125, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.65], output: [0.0219499781965924]},
    {input: [0.1734375, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.325], output: [0.0219499781965924]},
    {input: [0.2359375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.427777777777778], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0138888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.888888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.00555555555555556], output: [-0.000690068604412431]},
    {input: [0.16875, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.519444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.472222222222222], output: [-0.000690068604412431]},
    {input: [0.078125, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.266666666666667], output: [-0.000690068604412431]},
    {input: [0.10625, 0.3], output: [0.0219499781965924]},
    {input: [0.2078125, 0.366666666666667], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.486111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.425], output: [0.0219499781965924]},
    {input: [0.275, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.1625, 0.158333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.225], output: [0.0219499781965924]},
    {input: [0.078125, 0.375], output: [0.0219499781965924]},
    {input: [0.0609375, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.0722222222222222], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.309375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.375], output: [0.0219499781965924]},
    {input: [0.084375, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.0472222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.0527777777777778], output: [-0.000690068604412431]},
    {input: [0.140625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.291666666666667], output: [-0.000690068604412431]},
    {input: [0.16875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.3], output: [-0.000690068604412431]},
    {input: [0.190625, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.16875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.3984375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.4828125, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.4546875, 0.3], output: [0.0898701185996068]},
    {input: [0.3875, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.55625, 0.294444444444444], output: [0.0898701185996068]},
    {input: [0.465625, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.4375, 0.297222222222222], output: [0.0898701185996068]},
    {input: [0.26875, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.2296875, 0.35], output: [0.067230071798602]},
    {input: [0.365625, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.325, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.0890625, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.421875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.375], output: [0.0219499781965924]},
    {input: [0.275, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.55], output: [0.0219499781965924]},
    {input: [0.2359375, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.55], output: [0.0219499781965924]},
    {input: [0.190625, 0.575], output: [0.0219499781965924]},
    {input: [0.1625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.3375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.359375, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.190625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.303125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.190625, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.15625, 0.708333333333333], output: [0.067230071798602]},
    {input: [0.1453125, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.85], output: [0.067230071798602]},
    {input: [0.190625, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.1625, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.0609375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.021875, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.425], output: [0.0219499781965924]},
    {input: [0.196875, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.3984375, 0.761111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.738888888888889], output: [0.0898701185996068]},
    {input: [0.3703125, 0.825], output: [0.0898701185996068]},
    {input: [0.3421875, 0.880555555555556], output: [0.0898701185996068]},
    {input: [0.325, 0.838888888888889], output: [0.112510165400612]},
    {input: [0.415625, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.415625, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.353125, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.958333333333333], output: [0.067230071798602]},
    {input: [0.1453125, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.4375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.4609375, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.409375, 0.688888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.808333333333333], output: [0.067230071798602]},
    {input: [0.325, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.3703125, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.713888888888889], output: [0.067230071798602]},
    {input: [0.365625, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.3984375, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.3484375, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.1, 0.9], output: [0.067230071798602]},
    {input: [0.16875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.35], output: [0.0219499781965924]},
    {input: [0.078125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.944444444444444], output: [-0.000690068604412431]},
    {input: [0.240625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.409375, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.4046875, 0.852777777777778], output: [0.067230071798602]},
    {input: [0.409375, 0.85], output: [0.067230071798602]},
    {input: [0.4546875, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.409375, 0.844444444444444], output: [0.112510165400612]},
    {input: [0.45, 0.858333333333333], output: [0.112510165400612]},
    {input: [0.6234375, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.5671875, 0.911111111111111], output: [0.135150212201616]},
    {input: [0.6578125, 0.9], output: [0.135150212201616]},
    {input: [0.6125, 0.908333333333333], output: [0.135150212201616]},
    {input: [0.521875, 0.927777777777778], output: [0.135150212201616]},
    {input: [0.415625, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.3984375, 0.927777777777778], output: [0.112510165400612]},
    {input: [0.196875, 0.9], output: [0.0898701185996068]},
    {input: [0.2125, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.2359375, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.275], output: [0.0219499781965924]},
    {input: [0.26875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.275], output: [0.0445900249975972]},
    {input: [0.2078125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.775], output: [0.0219499781965924]},
    {input: [0.1453125, 0.805555555555556], output: [-0.000690068604412431]},
    {input: [0.1, 0.780555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.819444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.911111111111111], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.10625, 0.25], output: [0.0219499781965924]},
    {input: [0.128125, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.275], output: [0.0219499781965924]},
    {input: [0.26875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.325], output: [0.0219499781965924]},
    {input: [0.21875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.325], output: [0.0219499781965924]},
    {input: [0.253125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.275], output: [0.0219499781965924]},
    {input: [0.2859375, 0.275], output: [0.0219499781965924]},
    {input: [0.33125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2296875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.309375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.3], output: [0.0219499781965924]},
    {input: [0.240625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.275], output: [0.0219499781965924]},
    {input: [0.2640625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.619444444444444], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.675], output: [0.0219499781965924]},
    {input: [0.1515625, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.861111111111111], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.833333333333333], output: [-0.000690068604412431]},
    {input: [0.2859375, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.875], output: [0.0898701185996068]},
    {input: [0.3765625, 0.880555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.883333333333333], output: [0.112510165400612]},
    {input: [0.359375, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.309375, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.225, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.190625, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.2640625, 0.925], output: [0.0445900249975972]},
    {input: [0.353125, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.4046875, 0.861111111111111], output: [0.067230071798602]},
    {input: [0.521875, 0.833333333333333], output: [0.0898701185996068]},
    {input: [0.55, 0.825], output: [0.112510165400612]},
    {input: [0.690625, 0.855555555555556], output: [0.135150212201616]},
    {input: [0.4890625, 0.866666666666667], output: [0.135150212201616]},
    {input: [0.5390625, 0.844444444444444], output: [0.157790259002621]},
    {input: [0.4890625, 0.797222222222222], output: [0.157790259002621]},
    {input: [0.4890625, 0.811111111111111], output: [0.157790259002621]},
    {input: [0.4328125, 0.788888888888889], output: [0.135150212201616]},
    {input: [0.4046875, 0.791666666666667], output: [0.135150212201616]},
    {input: [0.478125, 0.830555555555556], output: [0.112510165400612]},
    {input: [0.415625, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.4265625, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.4265625, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.365625, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.275, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.1234375, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.025], output: [0.0219499781965924]},
    {input: [0.10625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0], output: [0.0219499781965924]},
    {input: [0.184375, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.309375, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.955555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.925], output: [0.0445900249975972]},
    {input: [0.240625, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.325], output: [0.0219499781965924]},
    {input: [0.0953125, 0.855555555555556], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.680555555555556], output: [-0.000690068604412431]},
    {input: [0.021875, 0.694444444444444], output: [-0.000690068604412431]},
    {input: [0.015625, 0.958333333333333], output: [-0.000690068604412431]},
    {input: [0.225, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.2296875, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.425], output: [0.0219499781965924]},
    {input: [0.128125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.15625, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.35], output: [0.0445900249975972]},
    {input: [0.190625, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.3984375, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.3484375, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.3984375, 0.375], output: [0.067230071798602]},
    {input: [0.3703125, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.253125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.35], output: [0.0445900249975972]},
    {input: [0.2078125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.35], output: [0.0219499781965924]},
    {input: [0.071875, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.325], output: [-0.000690068604412431]},
    {input: [0.015625, 0.394444444444444], output: [-0.000690068604412431]},
    {input: [0.04375, 0.655555555555556], output: [-0.000690068604412431]},
    {input: [0.05625, 0.75], output: [-0.000690068604412431]},
    {input: [0.05, 0.969444444444444], output: [-0.000690068604412431]},
    {input: [0.021875, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.0555555555555556], output: [-0.000690068604412431]},
    {input: [0.028125, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.875], output: [-0.000690068604412431]},
    {input: [0.1125, 0.866666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.291666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.2], output: [0.0219499781965924]},
    {input: [0.1125, 0.383333333333333], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.0722222222222222], output: [-0.000690068604412431]},
    {input: [0.15625, 0.355555555555556], output: [-0.000690068604412431]},
    {input: [0.16875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.383333333333333], output: [-0.000690068604412431]},
    {input: [0.253125, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.3140625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.359375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.33125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.8], output: [0.0219499781965924]},
    {input: [0.2078125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.980555555555556], output: [-0.000690068604412431]},
    {input: [0.246875, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.880555555555556], output: [-0.000690068604412431]},
    {input: [0.2859375, 0.875], output: [0.0445900249975972]},
    {input: [0.275, 0.880555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.025], output: [0.0445900249975972]},
    {input: [0.0609375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.0390625, 0.2], output: [0.0219499781965924]},
    {input: [0.1625, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.425], output: [0.0219499781965924]},
    {input: [0.0953125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.786111111111111], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.975], output: [0.0219499781965924]},
    {input: [0.1171875, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.071875, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.688888888888889], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.325], output: [0.0219499781965924]},
    {input: [0.2296875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.45], output: [0.0219499781965924]},
    {input: [0.1171875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.883333333333333], output: [-0.000690068604412431]},
    {input: [0.140625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0583333333333333], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.75], output: [0.0219499781965924]},
    {input: [0.05, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.25], output: [0.0219499781965924]},
    {input: [0.078125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.677777777777778], output: [-0.000690068604412431]},
    {input: [0.05, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.541666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.00555555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.0222222222222222], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.0833333333333333], output: [-0.000690068604412431]},
    {input: [0.1125, 0.188888888888889], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.255555555555556], output: [-0.000690068604412431]},
    {input: [0.1, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.0328125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.028125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.015625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.39375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.875], output: [0.0445900249975972]},
    {input: [0.303125, 0.841666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.296875, 0.775], output: [0.0898701185996068]},
    {input: [0.3375, 0.766666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.39375, 0.786111111111111], output: [0.0898701185996068]},
    {input: [0.421875, 0.775], output: [0.112510165400612]},
    {input: [0.4546875, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.5, 0.805555555555556], output: [0.135150212201616]},
    {input: [0.359375, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.296875, 0.325], output: [0.067230071798602]},
    {input: [0.39375, 0.3], output: [0.0445900249975972]},
    {input: [0.365625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.3484375, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.365625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.2640625, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2578125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.3765625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.3984375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.3484375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.365625, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.3484375, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.275, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.28125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.1453125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.675], output: [0.0219499781965924]},
    {input: [0.0609375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.261111111111111], output: [-0.000690068604412431]},
    {input: [0.1, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.16875, 0.244444444444444], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.3], output: [0.0219499781965924]},
    {input: [0.2078125, 0.294444444444444], output: [-0.000690068604412431]},
    {input: [0.134375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.266666666666667], output: [-0.000690068604412431]},
    {input: [0.16875, 0.391666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.383333333333333], output: [-0.000690068604412431]},
    {input: [0.275, 0.308333333333333], output: [-0.000690068604412431]},
    {input: [0.303125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.3375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.359375, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.359375, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.95], output: [0.0219499781965924]},
    {input: [0.359375, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.841666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.777777777777778], output: [0.112510165400612]},
    {input: [0.2640625, 0.752777777777778], output: [0.0898701185996068]},
    {input: [0.2640625, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.2359375, 0.775], output: [0.0445900249975972]},
    {input: [0.21875, 0.755555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.10625, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.021875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.125], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.4], output: [0.0219499781965924]},
    {input: [0.240625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.1796875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.35], output: [0.0219499781965924]},
    {input: [0.0953125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.636111111111111], output: [-0.000690068604412431]},
    {input: [0.015625, 0.652777777777778], output: [-0.000690068604412431]},
    {input: [0.05, 0.0555555555555556], output: [-0.000690068604412431]},
    {input: [0.021875, 0.0694444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.208333333333333], output: [-0.000690068604412431]},
    {input: [0.028125, 0.0333333333333333], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.447222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.125], output: [0.0219499781965924]},
    {input: [0.04375, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.021875, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.677777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.416666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.869444444444444], output: [-0.000690068604412431]},
    {input: [0.2125, 0.811111111111111], output: [-0.000690068604412431]},
    {input: [0.33125, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.084375, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.0609375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.025], output: [0.0219499781965924]},
    {input: [0.1171875, 0.516666666666667], output: [-0.000690068604412431]},
    {input: [0.134375, 0.463888888888889], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.980555555555556], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.863888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.944444444444444], output: [-0.000690068604412431]},
    {input: [0.240625, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.246875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.108333333333333], output: [0.0445900249975972]},
    {input: [0.084375, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.5], output: [0.0219499781965924]},
    {input: [0.015625, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.1, 0.175], output: [0.0219499781965924]},
    {input: [0.15625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.2], output: [0.0219499781965924]},
    {input: [0.2078125, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.555555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.55], output: [0.0445900249975972]},
    {input: [0.225, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.35], output: [0.067230071798602]},
    {input: [0.353125, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.3484375, 0.325], output: [0.0898701185996068]},
    {input: [0.26875, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.240625, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.2125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.25], output: [0.0219499781965924]},
    {input: [0.2078125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.2], output: [0.0219499781965924]},
    {input: [0.2015625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.15], output: [0.0219499781965924]},
    {input: [0.1625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.0390625, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.015625, 0.175], output: [0.0445900249975972]},
    {input: [0.04375, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.021875, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.015625, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.45], output: [0.0219499781965924]},
    {input: [0.2078125, 0.325], output: [0.0219499781965924]},
    {input: [0.15625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.15], output: [0.0219499781965924]},
    {input: [0.078125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.309375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.38125, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.275, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.421875, 0.925], output: [0.067230071798602]},
    {input: [0.3703125, 0.925], output: [0.067230071798602]},
    {input: [0.3765625, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.3203125, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.39375, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.3421875, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.38125, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.359375, 0.883333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.9], output: [0.0898701185996068]},
    {input: [0.3765625, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.4265625, 0.9], output: [0.0898701185996068]},
    {input: [0.478125, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.4375, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.4546875, 0.925], output: [0.112510165400612]},
    {input: [0.4609375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.5, 0.925], output: [0.0898701185996068]},
    {input: [0.528125, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.465625, 0.936111111111111], output: [0.112510165400612]},
    {input: [0.4375, 0.9], output: [0.112510165400612]},
    {input: [0.409375, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.465625, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.44375, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.44375, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.521875, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.4609375, 0.886111111111111], output: [0.112510165400612]},
    {input: [0.3203125, 0.805555555555556], output: [0.112510165400612]},
    {input: [0.16875, 0.791666666666667], output: [0.0898701185996068]},
    {input: [0.225, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.1796875, 0], output: [0.067230071798602]},
    {input: [0.3203125, 0.955555555555556], output: [0.0445900249975972]},
    {input: [0.421875, 0.95], output: [0.067230071798602]},
    {input: [0.478125, 0.925], output: [0.0898701185996068]},
    {input: [0.4890625, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.4828125, 0.9], output: [0.0898701185996068]},
    {input: [0.3875, 0.925], output: [0.0898701185996068]},
    {input: [0.3765625, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.421875, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.365625, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.303125, 0.95], output: [0.067230071798602]},
    {input: [0.28125, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.15625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.219444444444444], output: [-0.000690068604412431]},
    {input: [0.140625, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.184375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.225], output: [0.0219499781965924]},
    {input: [0.21875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.3], output: [0.0219499781965924]},
    {input: [0.246875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.408333333333333], output: [-0.000690068604412431]},
    {input: [0.04375, 0.916666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.302777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.283333333333333], output: [-0.000690068604412431]},
    {input: [0.028125, 0.0777777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.3], output: [-0.000690068604412431]},
    {input: [0.05625, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.3], output: [-0.000690068604412431]},
    {input: [0.028125, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.375], output: [0.0219499781965924]},
    {input: [0.15625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.308333333333333], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.15625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.9], output: [-0.000690068604412431]},
    {input: [0.309375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.925], output: [0.067230071798602]},
    {input: [0.2640625, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.925], output: [0.0445900249975972]},
    {input: [0.184375, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.015625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.55], output: [0.0219499781965924]},
    {input: [0.078125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.478125, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.5, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.478125, 0.872222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.415625, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.3421875, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.33125, 0.925], output: [0.0898701185996068]},
    {input: [0.3203125, 0.95], output: [0.067230071798602]},
    {input: [0.26875, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.246875, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.04375, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.775], output: [0.0219499781965924]},
    {input: [0.04375, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.525], output: [0.0219499781965924]},
    {input: [0.084375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.841666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.497222222222222], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.527777777777778], output: [0.067230071798602]},
    {input: [0.1515625, 0.830555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.184375, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.15625, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.1171875, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.04375, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.028125, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.3421875, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.4328125, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.39375, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.3421875, 0.877777777777778], output: [0.112510165400612]},
    {input: [0.325, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.309375, 0.872222222222222], output: [0.0898701185996068]},
    {input: [0.246875, 0.827777777777778], output: [0.0898701185996068]},
    {input: [0.128125, 0.752777777777778], output: [0.067230071798602]},
    {input: [0.2296875, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.2578125, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.309375, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.1734375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.4328125, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.45, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.409375, 0.330555555555556], output: [0.112510165400612]},
    {input: [0.3984375, 0.330555555555556], output: [0.112510165400612]},
    {input: [0.309375, 0.313888888888889], output: [0.0898701185996068]},
    {input: [0.246875, 0.325], output: [0.0898701185996068]},
    {input: [0.1234375, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.05625, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.0390625, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.128125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.5], output: [0.0219499781965924]},
    {input: [0.196875, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.6], output: [0.0219499781965924]},
    {input: [0.3203125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.4828125, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.575], output: [0.0445900249975972]},
    {input: [0.21875, 0.625], output: [0.0219499781965924]},
    {input: [0.2125, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.825], output: [0.0219499781965924]},
    {input: [0.2078125, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.184375, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.021875, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.0390625, 0.00277777777777778], output: [0.0445900249975972]},
    {input: [0.0390625, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.75], output: [0.0219499781965924]},
    {input: [0.0953125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.225], output: [0.067230071798602]},
    {input: [0.1234375, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.4265625, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.465625, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.4828125, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.5, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.5734375, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.5171875, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.49375, 0.902777777777778], output: [0.135150212201616]},
    {input: [0.5453125, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.4890625, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.4328125, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.471875, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.465625, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.85], output: [0.112510165400612]},
    {input: [0.3484375, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.28125, 0.825], output: [0.067230071798602]},
    {input: [0.2078125, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.409375, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.584375, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.5953125, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.61875, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.55625, 0.894444444444444], output: [0.135150212201616]},
    {input: [0.55625, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.534375, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.49375, 0.913888888888889], output: [0.135150212201616]},
    {input: [0.45, 0.925], output: [0.112510165400612]},
    {input: [0.3765625, 0.925], output: [0.112510165400612]},
    {input: [0.3875, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.3140625, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.3140625, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.21875, 0.872222222222222], output: [0.067230071798602]},
    {input: [0.1734375, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.875], output: [0.0445900249975972]},
    {input: [0.184375, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.0671875, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.128125, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.95], output: [0.0219499781965924]},
    {input: [0.415625, 0.925], output: [0.0219499781965924]},
    {input: [0.4046875, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.4609375, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.45, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.4546875, 0.925], output: [0.067230071798602]},
    {input: [0.4375, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.44375, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.421875, 0.925], output: [0.0898701185996068]},
    {input: [0.353125, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.2640625, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.246875, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.240625, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.925], output: [0.067230071798602]},
    {input: [0.184375, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.541666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.763888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.246875, 0.958333333333333], output: [0.067230071798602]},
    {input: [0.44375, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.5109375, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.4546875, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.4609375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.50625, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.471875, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.4609375, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.4546875, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.4265625, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.33125, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.246875, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.1125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.409375, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.4328125, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.4546875, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.75], output: [0.0219499781965924]},
    {input: [0.028125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.0046875, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.028125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.225], output: [0.0219499781965924]},
    {input: [0.015625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.3140625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.325, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.421875, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.3703125, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.359375, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.3875, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.3984375, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.325], output: [0.067230071798602]},
    {input: [0.240625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.3], output: [0.0219499781965924]},
    {input: [0.2859375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.875], output: [0.0219499781965924]},
    {input: [0.1, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.6], output: [0.0219499781965924]},
    {input: [0.0671875, 0.4], output: [0.0219499781965924]},
    {input: [0.1, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.325], output: [0.0219499781965924]},
    {input: [0.084375, 0.213888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.825], output: [0.0219499781965924]},
    {input: [0.071875, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.1, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.425], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.922222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.316666666666667], output: [-0.000690068604412431]},
    {input: [0.071875, 0.2], output: [0.0219499781965924]},
    {input: [0.04375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.35], output: [0.0219499781965924]},
    {input: [0.2578125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.3140625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.575], output: [0.0219499781965924]},
    {input: [0.190625, 0.625], output: [0.0219499781965924]},
    {input: [0.2640625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.359375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.409375, 0.491666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.38125, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.38125, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.425], output: [0.0445900249975972]},
    {input: [0.3203125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.463888888888889], output: [-0.000690068604412431]},
    {input: [0.021875, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.3], output: [0.0219499781965924]},
    {input: [0.05, 0.561111111111111], output: [-0.000690068604412431]},
    {input: [0.1, 0.736111111111111], output: [-0.000690068604412431]},
    {input: [0.1125, 0.75], output: [-0.000690068604412431]},
    {input: [0.128125, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.134375, 0.747222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.447222222222222], output: [-0.000690068604412431]},
    {input: [0.140625, 0.388888888888889], output: [-0.000690068604412431]},
    {input: [0.134375, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.625], output: [0.0219499781965924]},
    {input: [0.05, 0.586111111111111], output: [-0.000690068604412431]},
    {input: [0.04375, 0.466666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.128125, 0.330555555555556], output: [-0.000690068604412431]},
    {input: [0.16875, 0.35], output: [0.0219499781965924]},
    {input: [0.1734375, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.325], output: [0.0219499781965924]},
    {input: [0.1734375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.25], output: [0.0219499781965924]},
    {input: [0.184375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.325], output: [0.0219499781965924]},
    {input: [0.0671875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.225], output: [0.0219499781965924]},
    {input: [0.184375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.275], output: [0.0219499781965924]},
    {input: [0.2125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.9], output: [0.0219499781965924]},
    {input: [0.1171875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.725], output: [0.0219499781965924]},
    {input: [0.084375, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.95], output: [0.0219499781965924]},
    {input: [0.071875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.975], output: [0.0219499781965924]},
    {input: [0.3375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.3703125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.4609375, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.3984375, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.45, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.925], output: [0.0898701185996068]},
    {input: [0.4046875, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.4890625, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.4828125, 0.9], output: [0.0898701185996068]},
    {input: [0.478125, 0.9], output: [0.112510165400612]},
    {input: [0.3484375, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.3421875, 0.875], output: [0.067230071798602]},
    {input: [0.2359375, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.2015625, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2578125, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.353125, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.4046875, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.3765625, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.3484375, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.296875, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.3140625, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.190625, 0.961111111111111], output: [0.067230071798602]},
    {input: [0.0328125, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.078125, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.028125, 0.561111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.4], output: [0.0219499781965924]},
    {input: [0.1, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.525], output: [0.0219499781965924]},
    {input: [0.0609375, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.25], output: [0.0219499781965924]},
    {input: [0.2015625, 0.35], output: [0.0219499781965924]},
    {input: [0.16875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.275], output: [0.0445900249975972]},
    {input: [0.196875, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.1125, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.3], output: [0.0445900249975972]},
    {input: [0.225, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.325], output: [0.0219499781965924]},
    {input: [0.21875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.246875, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.275, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.475], output: [0.0219499781965924]},
    {input: [0.1, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.325], output: [0.0219499781965924]},
    {input: [0.05, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.021875, 0.577777777777778], output: [-0.000690068604412431]},
    {input: [0.021875, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.35], output: [0.0219499781965924]},
    {input: [0.0609375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.15], output: [0.0219499781965924]},
    {input: [0.140625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.35], output: [0.0219499781965924]},
    {input: [0.071875, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.128125, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.175], output: [-0.000690068604412431]},
    {input: [0.1625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.0671875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.925], output: [0.0219499781965924]},
    {input: [0.225, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.1125, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.16875, 0.177777777777778], output: [0.067230071798602]},
    {input: [0.1125, 0.375], output: [0.0445900249975972]},
    {input: [0.1234375, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.1234375, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.3765625, 0.925], output: [0.0219499781965924]},
    {input: [0.38125, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.465625, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.415625, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.4375, 0.922222222222222], output: [0.0898701185996068]},
    {input: [0.4046875, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.3765625, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.325, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.325, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.95], output: [0.067230071798602]},
    {input: [0.2640625, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.1171875, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.04375, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.95], output: [0.0219499781965924]},
    {input: [0.28125, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.975], output: [0.0219499781965924]},
    {input: [0.1, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.075], output: [0.0219499781965924]},
    {input: [0.04375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.425], output: [0.0219499781965924]},
    {input: [0.0328125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.225], output: [-0.000690068604412431]},
    {input: [0.26875, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.95], output: [0.0445900249975972]},
    {input: [0.028125, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.475], output: [0.067230071798602]},
    {input: [0.2578125, 0.475], output: [0.067230071798602]},
    {input: [0.184375, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.140625, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.775], output: [0.0219499781965924]},
    {input: [0.5453125, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.5390625, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.5109375, 0.825], output: [0.135150212201616]},
    {input: [0.5734375, 0.844444444444444], output: [0.135150212201616]},
    {input: [0.521875, 0.819444444444444], output: [0.157790259002621]},
    {input: [0.4375, 0.855555555555556], output: [0.135150212201616]},
    {input: [0.2859375, 0.797222222222222], output: [0.112510165400612]},
    {input: [0.303125, 0.819444444444444], output: [0.0898701185996068]},
    {input: [0.196875, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.134375, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.452777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.425], output: [0.0445900249975972]},
    {input: [0.303125, 0.480555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.502777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.05625, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.071875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.425], output: [0.0219499781965924]},
    {input: [0.0953125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.275], output: [0.0219499781965924]},
    {input: [0.240625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.196875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.4], output: [0.0219499781965924]},
    {input: [0.10625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.125], output: [0.0219499781965924]},
    {input: [0.078125, 0.675], output: [0.0219499781965924]},
    {input: [0.1125, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.836111111111111], output: [-0.000690068604412431]},
    {input: [0.246875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.309375, 0.883333333333333], output: [0.067230071798602]},
    {input: [0.225, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.325, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.3203125, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.925], output: [0.0445900249975972]},
    {input: [0.1171875, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.078125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.925], output: [0.0219499781965924]},
    {input: [0.1453125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.85], output: [0.0445900249975972]},
    {input: [0.1796875, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.015625, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.084375, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.3], output: [0.0445900249975972]},
    {input: [0.1, 0.575], output: [0.067230071798602]},
    {input: [0.2015625, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.190625, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.128125, 0.375], output: [0.0445900249975972]},
    {input: [0.225, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.2578125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.325], output: [0.0219499781965924]},
    {input: [0.128125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.336111111111111], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.325], output: [0.0219499781965924]},
    {input: [0.1125, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.225], output: [-0.000690068604412431]},
    {input: [0.10625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.794444444444444], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.816666666666667], output: [-0.000690068604412431]},
    {input: [0.04375, 0.783333333333333], output: [-0.000690068604412431]},
    {input: [0.04375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.309375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.33125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.359375, 0.275], output: [0.0445900249975972]},
    {input: [0.33125, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.0671875, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.1453125, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.0671875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.04375, 0.0222222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.325, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.275], output: [0.0445900249975972]},
    {input: [0.3765625, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.44375, 0.463888888888889], output: [0.067230071798602]},
    {input: [0.33125, 0.408333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.275], output: [0.067230071798602]},
    {input: [0.0890625, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.409375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.4046875, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.33125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.3484375, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.625], output: [0.0219499781965924]},
    {input: [0.2015625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.647222222222222], output: [-0.000690068604412431]},
    {input: [0.078125, 0.6], output: [0.0219499781965924]},
    {input: [0.028125, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.078125, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.1125, 0.9], output: [0.0445900249975972]},
    {input: [0.04375, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.028125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.55], output: [0.0219499781965924]},
    {input: [0.078125, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.9], output: [0.0219499781965924]},
    {input: [0.2125, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.325, 0.875], output: [0.0219499781965924]},
    {input: [0.353125, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.872222222222222], output: [0.067230071798602]},
    {input: [0.253125, 0.861111111111111], output: [0.067230071798602]},
    {input: [0.2859375, 0.875], output: [0.067230071798602]},
    {input: [0.190625, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.85], output: [0.0219499781965924]},
    {input: [0.05, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.686111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.225, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.5109375, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.528125, 0.844444444444444], output: [0.112510165400612]},
    {input: [0.5453125, 0.841666666666667], output: [0.157790259002621]},
    {input: [0.4828125, 0.838888888888889], output: [0.157790259002621]},
    {input: [0.5734375, 0.863888888888889], output: [0.157790259002621]},
    {input: [0.703125, 0.855555555555556], output: [0.157790259002621]},
    {input: [0.634375, 0.875], output: [0.180430305803626]},
    {input: [0.6234375, 0.852777777777778], output: [0.157790259002621]},
    {input: [0.5734375, 0.844444444444444], output: [0.180430305803626]},
    {input: [0.421875, 0.797222222222222], output: [0.180430305803626]},
    {input: [0.5671875, 0.866666666666667], output: [0.180430305803626]},
    {input: [0.5390625, 0.858333333333333], output: [0.135150212201616]},
    {input: [0.675, 0.841666666666667], output: [0.135150212201616]},
    {input: [0.703125, 0.866666666666667], output: [0.135150212201616]},
    {input: [0.6296875, 0.852777777777778], output: [0.157790259002621]},
    {input: [0.61875, 0.830555555555556], output: [0.180430305803626]},
    {input: [0.5625, 0.844444444444444], output: [0.180430305803626]},
    {input: [0.6015625, 0.838888888888889], output: [0.203070352604631]},
    {input: [0.584375, 0.822222222222222], output: [0.157790259002621]},
    {input: [0.5453125, 0.85], output: [0.157790259002621]},
    {input: [0.528125, 0.855555555555556], output: [0.135150212201616]},
    {input: [0.590625, 0.897222222222222], output: [0.157790259002621]},
    {input: [0.55625, 0.913888888888889], output: [0.157790259002621]},
    {input: [0.478125, 0.927777777777778], output: [0.112510165400612]},
    {input: [0.521875, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.5109375, 0.922222222222222], output: [0.0898701185996068]},
    {input: [0.5390625, 0.936111111111111], output: [0.0898701185996068]},
    {input: [0.5625, 0.925], output: [0.112510165400612]},
    {input: [0.528125, 0.919444444444444], output: [0.112510165400612]},
    {input: [0.5625, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.528125, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.4375, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.875], output: [0.0898701185996068]},
    {input: [0.325, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.852777777777778], output: [0.067230071798602]},
    {input: [0.478125, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.5625, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.50625, 0.925], output: [0.135150212201616]},
    {input: [0.55625, 0.916666666666667], output: [0.135150212201616]},
    {input: [0.55625, 0.908333333333333], output: [0.135150212201616]},
    {input: [0.5390625, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.61875, 0.913888888888889], output: [0.135150212201616]},
    {input: [0.6125, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.5390625, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.5, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.528125, 0.922222222222222], output: [0.0898701185996068]},
    {input: [0.4546875, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.409375, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.478125, 0.925], output: [0.067230071798602]},
    {input: [0.409375, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.39375, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.3421875, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.3765625, 0.925], output: [0.067230071798602]},
    {input: [0.2359375, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.2640625, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.875], output: [0.0219499781965924]},
    {input: [0.0953125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.347222222222222], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.683333333333333], output: [-0.000690068604412431]},
    {input: [0.10625, 0.127777777777778], output: [-0.000690068604412431]},
    {input: [0.140625, 0.15], output: [0.0219499781965924]},
    {input: [0.0671875, 0.347222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.402777777777778], output: [-0.000690068604412431]},
    {input: [0.39375, 0.15], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.458333333333333], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.347222222222222], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.386111111111111], output: [-0.000690068604412431]},
    {input: [0.10625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.38125, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.3], output: [0.0219499781965924]},
    {input: [0.240625, 0.3], output: [0.0445900249975972]},
    {input: [0.2015625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.375], output: [0.0445900249975972]},
    {input: [0.2640625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.325], output: [0.0219499781965924]},
    {input: [0.2015625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.205555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.05625, 0.263888888888889], output: [-0.000690068604412431]},
    {input: [0.084375, 0.4], output: [0.0219499781965924]},
    {input: [0.0890625, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1796875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.191666666666667], output: [-0.000690068604412431]},
    {input: [0.021875, 0.261111111111111], output: [-0.000690068604412431]},
    {input: [0.15625, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.944444444444444], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.905555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.763888888888889], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.775], output: [-0.000690068604412431]},
    {input: [0.128125, 0.825], output: [-0.000690068604412431]},
    {input: [0.10625, 0.05], output: [-0.000690068604412431]},
    {input: [0.10625, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.413888888888889], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.275], output: [0.0219499781965924]},
    {input: [0.1515625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.725], output: [0.0219499781965924]},
    {input: [0.16875, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.3140625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.3703125, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.675], output: [0.0445900249975972]},
    {input: [0.15625, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.788888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.85], output: [0.067230071798602]},
    {input: [0.3765625, 0.9], output: [0.0898701185996068]},
    {input: [0.309375, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.2296875, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.128125, 0.961111111111111], output: [0.067230071798602]},
    {input: [0.05, 0.375], output: [0.0445900249975972]},
    {input: [0.021875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.1, 0.55], output: [0.0219499781965924]},
    {input: [0.0671875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.415625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.4328125, 0.9], output: [0.067230071798602]},
    {input: [0.4609375, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.471875, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.50625, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.471875, 0.877777777777778], output: [0.112510165400612]},
    {input: [0.45, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.4046875, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.38125, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.4609375, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.5453125, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.6125, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.61875, 0.897222222222222], output: [0.135150212201616]},
    {input: [0.6125, 0.9], output: [0.135150212201616]},
    {input: [0.590625, 0.908333333333333], output: [0.135150212201616]},
    {input: [0.590625, 0.9], output: [0.135150212201616]},
    {input: [0.634375, 0.905555555555556], output: [0.135150212201616]},
    {input: [0.6296875, 0.897222222222222], output: [0.135150212201616]},
    {input: [0.6015625, 0.9], output: [0.135150212201616]},
    {input: [0.534375, 0.894444444444444], output: [0.135150212201616]},
    {input: [0.4890625, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.5390625, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.465625, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.353125, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.2125, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.2015625, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.39375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.3875, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.465625, 0.925], output: [0.067230071798602]},
    {input: [0.471875, 0.925], output: [0.067230071798602]},
    {input: [0.4046875, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.359375, 0.963888888888889], output: [0.112510165400612]},
    {input: [0.3375, 0.961111111111111], output: [0.0898701185996068]},
    {input: [0.365625, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.38125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.3140625, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.246875, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.275, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.1171875, 0.713888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.741666666666667], output: [-0.000690068604412431]},
    {input: [0.140625, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.9], output: [0.0219499781965924]},
    {input: [0.296875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.3421875, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.95], output: [-0.000690068604412431]},
    {input: [0.128125, 0.777777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.85], output: [0.0219499781965924]},
    {input: [0.1796875, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.825], output: [0.0219499781965924]},
    {input: [0.10625, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.275], output: [0.0219499781965924]},
    {input: [0.2078125, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.3703125, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.3765625, 0.333333333333333], output: [0.0898701185996068]},
    {input: [0.3984375, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.38125, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.353125, 0.325], output: [0.0898701185996068]},
    {input: [0.365625, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.4046875, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.33125, 0.605555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.641666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.619444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.184375, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.6], output: [0.0219499781965924]},
    {input: [0.1625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.3484375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.325], output: [0.0445900249975972]},
    {input: [0.28125, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.95], output: [0.0219499781965924]},
    {input: [0.1171875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.875], output: [0.0219499781965924]},
    {input: [0.10625, 0.825], output: [0.0219499781965924]},
    {input: [0.2921875, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.9], output: [0.0219499781965924]},
    {input: [0.2640625, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.225, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.9], output: [0.0445900249975972]},
    {input: [0.196875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.2125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.95], output: [0.0219499781965924]},
    {input: [0.1515625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.775], output: [0.0219499781965924]},
    {input: [0.1, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.808333333333333], output: [-0.000690068604412431]},
    {input: [0.2125, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.875], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.327777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.525], output: [0.0219499781965924]},
    {input: [0.0671875, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.338888888888889], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.361111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.205555555555556], output: [-0.000690068604412431]},
    {input: [0.021875, 0.130555555555556], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.166666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.988888888888889], output: [-0.000690068604412431]},
    {input: [0.021875, 0.872222222222222], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.280555555555556], output: [-0.000690068604412431]},
    {input: [0.253125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.275], output: [0.0219499781965924]},
    {input: [0.3203125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.325, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.421875, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.4265625, 0.325], output: [0.067230071798602]},
    {input: [0.4546875, 0.35], output: [0.067230071798602]},
    {input: [0.478125, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.33125, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.246875, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.196875, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.8], output: [0.0219499781965924]},
    {input: [0.1515625, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.863888888888889], output: [-0.000690068604412431]},
    {input: [0.2125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.05625, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.04375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.325], output: [0.0219499781965924]},
    {input: [0.134375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.4], output: [0.0219499781965924]},
    {input: [0.05, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.811111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.877777777777778], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.916666666666667], output: [-0.000690068604412431]},
    {input: [0.134375, 0.844444444444444], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.028125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.015625, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.938888888888889], output: [-0.000690068604412431]},
    {input: [0.021875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.0333333333333333], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.9], output: [0.0219499781965924]},
    {input: [0.240625, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.16875, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.541666666666667], output: [0.0445900249975972]},
    {input: [0.1171875, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.0671875, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.875], output: [0.0219499781965924]},
    {input: [0.1171875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.2921875, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.1796875, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.1796875, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.2015625, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.1171875, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.084375, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.084375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.0046875, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.0166666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.894444444444444], output: [-0.000690068604412431]},
    {input: [0.05, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.0472222222222222], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.0833333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.85], output: [-0.000690068604412431]},
    {input: [0.04375, 0.713888888888889], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.816666666666667], output: [-0.000690068604412431]},
    {input: [0.078125, 0.941666666666667], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.875], output: [0.0219499781965924]},
    {input: [0.0328125, 0.805555555555556], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.875], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.0109375, 0.672222222222222], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.6], output: [0.0219499781965924]},
    {input: [0.04375, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.925], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.134375, 0.291666666666667], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.3], output: [0.0219499781965924]},
    {input: [0.3140625, 0.275], output: [0.0445900249975972]},
    {input: [0.2640625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.275], output: [0.0445900249975972]},
    {input: [0.3703125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.1234375, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.2359375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.1125, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.958333333333333], output: [-0.000690068604412431]},
    {input: [0.071875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.4], output: [0.0445900249975972]},
    {input: [0.1, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.725], output: [0.0445900249975972]},
    {input: [0.134375, 0.8], output: [0.0219499781965924]},
    {input: [0.1515625, 0.825], output: [0.0219499781965924]},
    {input: [0.071875, 0.95], output: [0.0219499781965924]},
    {input: [0.140625, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.925], output: [0.0219499781965924]},
    {input: [0.1625, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.791666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.866666666666667], output: [-0.000690068604412431]},
    {input: [0.128125, 0.811111111111111], output: [-0.000690068604412431]},
    {input: [0.1, 0.855555555555556], output: [-0.000690068604412431]},
    {input: [0.078125, 0.908333333333333], output: [-0.000690068604412431]},
    {input: [0.05625, 0.925], output: [-0.000690068604412431]},
    {input: [0.05625, 0.85], output: [-0.000690068604412431]},
    {input: [0.1, 0.855555555555556], output: [-0.000690068604412431]},
    {input: [0.071875, 0.833333333333333], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.805555555555556], output: [-0.000690068604412431]},
    {input: [0.05625, 0.822222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.75], output: [0.0219499781965924]},
    {input: [0.0046875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.652777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.925], output: [0.0219499781965924]},
    {input: [0.2296875, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.2921875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.128125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.1734375, 0.969444444444444], output: [0.0445900249975972]},
    {input: [0.15625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.0166666666666667], output: [0.0445900249975972]},
    {input: [0.0390625, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.575], output: [0.0445900249975972]},
    {input: [0.04375, 0.325], output: [0.0219499781965924]},
    {input: [0.0671875, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.3984375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.465625, 0.925], output: [0.0445900249975972]},
    {input: [0.4046875, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.4328125, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.421875, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.4046875, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.3875, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.421875, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.3875, 0.9], output: [0.0898701185996068]},
    {input: [0.38125, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.353125, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.2640625, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.2015625, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.184375, 0.955555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.021875, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.15], output: [0.0219499781965924]},
    {input: [0.04375, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.521875, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.6234375, 0.877777777777778], output: [0.135150212201616]},
    {input: [0.6296875, 0.866666666666667], output: [0.135150212201616]},
    {input: [0.73125, 0.869444444444444], output: [0.135150212201616]},
    {input: [0.50625, 0.825], output: [0.157790259002621]},
    {input: [0.5109375, 0.822222222222222], output: [0.157790259002621]},
    {input: [0.5171875, 0.841666666666667], output: [0.157790259002621]},
    {input: [0.528125, 0.833333333333333], output: [0.135150212201616]},
    {input: [0.521875, 0.85], output: [0.157790259002621]},
    {input: [0.4328125, 0.866666666666667], output: [0.157790259002621]},
    {input: [0.4375, 0.855555555555556], output: [0.135150212201616]},
    {input: [0.3703125, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.4328125, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.415625, 0.861111111111111], output: [0.0898701185996068]},
    {input: [0.296875, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.303125, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.983333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.875], output: [0.0219499781965924]},
    {input: [0.2359375, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.8], output: [0.0219499781965924]},
    {input: [0.2359375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.675], output: [0.0219499781965924]},
    {input: [0.128125, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.015625, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.16875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.04375, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.9], output: [0.0219499781965924]},
    {input: [0.071875, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.855555555555556], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.866666666666667], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.925], output: [0.0219499781965924]},
    {input: [0.1234375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.825], output: [0.0219499781965924]},
    {input: [0.1625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.4], output: [0.0219499781965924]},
    {input: [0.078125, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.869444444444444], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.872222222222222], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.0416666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.075], output: [0.0445900249975972]},
    {input: [0.140625, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0416666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.253125, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.3140625, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.253125, 0.925], output: [0.067230071798602]},
    {input: [0.1734375, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.246875, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.9], output: [0.0445900249975972]},
    {input: [0.1734375, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.1, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.925], output: [0.0219499781965924]},
    {input: [0.1515625, 0.9], output: [0.0219499781965924]},
    {input: [0.1, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.863888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.822222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.883333333333333], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.9], output: [-0.000690068604412431]},
    {input: [0.1625, 0.822222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.708333333333333], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.858333333333333], output: [-0.000690068604412431]},
    {input: [0.04375, 0.875], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.875], output: [-0.000690068604412431]},
    {input: [0.071875, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.675], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.078125, 0.866666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.508333333333333], output: [-0.000690068604412431]},
    {input: [0.084375, 0.602777777777778], output: [-0.000690068604412431]},
    {input: [0.128125, 0.525], output: [0.0219499781965924]},
    {input: [0.134375, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.26875, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.296875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.4], output: [0.067230071798602]},
    {input: [0.38125, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.413888888888889], output: [0.0898701185996068]},
    {input: [0.184375, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.2359375, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.2859375, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.369444444444444], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.355555555555556], output: [-0.000690068604412431]},
    {input: [0.05625, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.15], output: [-0.000690068604412431]},
    {input: [0.1, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.425], output: [0.0219499781965924]},
    {input: [0.028125, 0.163888888888889], output: [-0.000690068604412431]},
    {input: [0.04375, 0.172222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.825], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.916666666666667], output: [-0.000690068604412431]},
    {input: [0.04375, 0.166666666666667], output: [-0.000690068604412431]},
    {input: [0.028125, 0.875], output: [0.0219499781965924]},
    {input: [0.071875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.95], output: [-0.000690068604412431]},
    {input: [0.028125, 0.988888888888889], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.958333333333333], output: [-0.000690068604412431]},
    {input: [0.05, 0.0583333333333333], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.875], output: [-0.000690068604412431]},
    {input: [0.015625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.797222222222222], output: [-0.000690068604412431]},
    {input: [0.084375, 0.752777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.8], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.744444444444444], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.911111111111111], output: [-0.000690068604412431]},
    {input: [0.05, 0.947222222222222], output: [-0.000690068604412431]},
    {input: [0.04375, 0.977777777777778], output: [-0.000690068604412431]},
    {input: [0.128125, 0.883333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.919444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.897222222222222], output: [-0.000690068604412431]},
    {input: [0.05, 0.95], output: [0.0219499781965924]},
    {input: [0.0328125, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.275], output: [0.0219499781965924]},
    {input: [0.10625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.425], output: [0.0219499781965924]},
    {input: [0.0671875, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.175], output: [0.0219499781965924]},
    {input: [0.0890625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.1, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.078125, 0.813888888888889], output: [-0.000690068604412431]},
    {input: [0.05625, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.04375, 0.525], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.972222222222222], output: [-0.000690068604412431]},
    {input: [0.21875, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.028125, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.3765625, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.44375, 0.825], output: [0.112510165400612]},
    {input: [0.365625, 0.816666666666667], output: [0.112510165400612]},
    {input: [0.521875, 0.905555555555556], output: [0.135150212201616]},
    {input: [0.50625, 0.847222222222222], output: [0.135150212201616]},
    {input: [0.5671875, 0.847222222222222], output: [0.135150212201616]},
    {input: [0.4609375, 0.852777777777778], output: [0.157790259002621]},
    {input: [0.325, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.2859375, 0.744444444444444], output: [0.112510165400612]},
    {input: [0.190625, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.16875, 0.85], output: [0.067230071798602]},
    {input: [0.2578125, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.2359375, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.983333333333333], output: [0.067230071798602]},
    {input: [0.0609375, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.04375, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.808333333333333], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.85], output: [0.0219499781965924]},
    {input: [0.3484375, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.303125, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.296875, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.988888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.95], output: [0.0445900249975972]},
    {input: [0.3203125, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.44375, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.409375, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.409375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.2078125, 0.0444444444444444], output: [0.0898701185996068]},
    {input: [0.1734375, 0.0277777777777778], output: [0.067230071798602]},
    {input: [0.1625, 0], output: [0.0445900249975972]},
    {input: [0.16875, 0.0222222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.752777777777778], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.85], output: [-0.000690068604412431]},
    {input: [0.49375, 0.9], output: [-0.000690068604412431]},
    {input: [0.44375, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.409375, 0.925], output: [0.0898701185996068]},
    {input: [0.3484375, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.309375, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.3], output: [0.0219499781965924]},
    {input: [0.1796875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.303125, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.359375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.38125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.4375, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.409375, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.3984375, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.421875, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.5109375, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.5390625, 0.4], output: [0.0898701185996068]},
    {input: [0.465625, 0.386111111111111], output: [0.135150212201616]},
    {input: [0.5390625, 0.408333333333333], output: [0.112510165400612]},
    {input: [0.365625, 0.405555555555556], output: [0.157790259002621]},
    {input: [0.2921875, 0.477777777777778], output: [0.135150212201616]},
    {input: [0.2078125, 0.655555555555556], output: [0.112510165400612]},
    {input: [0.140625, 0.733333333333333], output: [0.112510165400612]},
    {input: [0.1125, 0.925], output: [0.0898701185996068]},
    {input: [0.0890625, 0.986111111111111], output: [0.0898701185996068]},
    {input: [0.1, 0.861111111111111], output: [0.0898701185996068]},
    {input: [0.0328125, 0.2], output: [0.0898701185996068]},
    {input: [0.0890625, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.1125, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.1171875, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0.85], output: [0.0219499781965924]},
    {input: [0.521875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.296875, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.359375, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.686111111111111], output: [0.067230071798602]},
    {input: [0.084375, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.140625, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.3703125, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.415625, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.4828125, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.5109375, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.590625, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.5453125, 0.372222222222222], output: [0.112510165400612]},
    {input: [0.521875, 0.413888888888889], output: [0.112510165400612]},
    {input: [0.528125, 0.408333333333333], output: [0.135150212201616]},
    {input: [0.590625, 0.422222222222222], output: [0.135150212201616]},
    {input: [0.4328125, 0.444444444444444], output: [0.157790259002621]},
    {input: [0.2921875, 0.6], output: [0.135150212201616]},
    {input: [0.45, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.4890625, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.478125, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.45], output: [0.0898701185996068]},
    {input: [0.4828125, 0.477777777777778], output: [0.0898701185996068]},
    {input: [0.4546875, 0.466666666666667], output: [0.067230071798602]},
    {input: [0.45, 0.488888888888889], output: [0.0898701185996068]},
    {input: [0.478125, 0.447222222222222], output: [0.0898701185996068]},
    {input: [0.5171875, 0.491666666666667], output: [0.112510165400612]},
    {input: [0.4375, 0.402777777777778], output: [0.112510165400612]},
    {input: [0.5171875, 0.413888888888889], output: [0.112510165400612]},
    {input: [0.5953125, 0.422222222222222], output: [0.112510165400612]},
    {input: [0.584375, 0.4], output: [0.112510165400612]},
    {input: [0.640625, 0.369444444444444], output: [0.135150212201616]},
    {input: [0.7140625, 0.341666666666667], output: [0.135150212201616]},
    {input: [0.7140625, 0.338888888888889], output: [0.157790259002621]},
    {input: [0.6859375, 0.316666666666667], output: [0.180430305803626]},
    {input: [0.696875, 0.302777777777778], output: [0.180430305803626]},
    {input: [0.5171875, 0.347222222222222], output: [0.180430305803626]},
    {input: [0.45, 0.35], output: [0.180430305803626]},
    {input: [0.3875, 0.458333333333333], output: [0.157790259002621]},
    {input: [0.3421875, 0.480555555555556], output: [0.157790259002621]},
    {input: [0.21875, 0.394444444444444], output: [0.135150212201616]},
    {input: [0.28125, 0.0416666666666667], output: [0.112510165400612]},
    {input: [0.3140625, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.140625, 0.416666666666667], output: [0.0898701185996068]},
    {input: [0.071875, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.1515625, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.533333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.561111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.35], output: [0.0219499781965924]},
    {input: [0.3140625, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.33125, 0.530555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.561111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.525], output: [0.0219499781965924]},
    {input: [0.196875, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.39375, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.478125, 0.433333333333333], output: [0.0445900249975972]},
    {input: [0.5625, 0.486111111111111], output: [0.067230071798602]},
    {input: [0.521875, 0.45], output: [0.0898701185996068]},
    {input: [0.421875, 0.447222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.436111111111111], output: [0.0898701185996068]},
    {input: [0.4328125, 0.408333333333333], output: [0.067230071798602]},
    {input: [0.4046875, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.4375, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.4046875, 0.419444444444444], output: [0.0898701185996068]},
    {input: [0.421875, 0.447222222222222], output: [0.0898701185996068]},
    {input: [0.4046875, 0.461111111111111], output: [0.0898701185996068]},
    {input: [0.4828125, 0.458333333333333], output: [0.067230071798602]},
    {input: [0.4546875, 0.458333333333333], output: [0.067230071798602]},
    {input: [0.45, 0.475], output: [0.067230071798602]},
    {input: [0.325, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.415625, 0.497222222222222], output: [0.067230071798602]},
    {input: [0.5171875, 0.438888888888889], output: [0.067230071798602]},
    {input: [0.534375, 0.394444444444444], output: [0.0898701185996068]},
    {input: [0.5625, 0.380555555555556], output: [0.0898701185996068]},
    {input: [0.521875, 0.394444444444444], output: [0.0898701185996068]},
    {input: [0.50625, 0.4], output: [0.112510165400612]},
    {input: [0.478125, 0.394444444444444], output: [0.112510165400612]},
    {input: [0.534375, 0.397222222222222], output: [0.0898701185996068]},
    {input: [0.5, 0.391666666666667], output: [0.112510165400612]},
    {input: [0.45, 0.397222222222222], output: [0.135150212201616]},
    {input: [0.4828125, 0.408333333333333], output: [0.135150212201616]},
    {input: [0.6796875, 0.516666666666667], output: [0.135150212201616]},
    {input: [0.5734375, 0.5], output: [0.157790259002621]},
    {input: [0.44375, 0.427777777777778], output: [0.135150212201616]},
    {input: [0.3421875, 0.461111111111111], output: [0.135150212201616]},
    {input: [0.296875, 0.433333333333333], output: [0.112510165400612]},
    {input: [0.2578125, 0.447222222222222], output: [0.112510165400612]},
    {input: [0.2640625, 0.466666666666667], output: [0.067230071798602]},
    {input: [0.190625, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.2578125, 0.472222222222222], output: [0.0445900249975972]},
    {input: [0.409375, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.365625, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.353125, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.309375, 0.4], output: [0.067230071798602]},
    {input: [0.38125, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.409375, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.415625, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.502777777777778], output: [0.067230071798602]},
    {input: [0.21875, 0.527777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.722222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.75], output: [0.0445900249975972]},
    {input: [0.28125, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.3875, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.2921875, 0.925], output: [0.0219499781965924]},
    {input: [0.2578125, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.1234375, 0.661111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.65], output: [0.0445900249975972]},
    {input: [0.16875, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.2078125, 0.65], output: [0.0219499781965924]},
    {input: [0.26875, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.675], output: [0.0219499781965924]},
    {input: [0.071875, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.021875, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.246875, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.39375, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.359375, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.421875, 0.95], output: [0.067230071798602]},
    {input: [0.49375, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.5171875, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.55, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.4609375, 0.936111111111111], output: [0.112510165400612]},
    {input: [0.4265625, 0.952777777777778], output: [0.0898701185996068]},
    {input: [0.26875, 0.0888888888888889], output: [0.0898701185996068]},
    {input: [0.1625, 0.15], output: [0.067230071798602]},
    {input: [0.196875, 0.183333333333333], output: [0.067230071798602]},
    {input: [0.10625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.0390625, 0.0305555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.955555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.365625, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.38125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.3203125, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.2078125, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.575], output: [0.0219499781965924]},
    {input: [0.1171875, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.3140625, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.3484375, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.3875, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.3375, 0.427777777777778], output: [0.0898701185996068]},
    {input: [0.3140625, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.409375, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.38125, 0.422222222222222], output: [0.067230071798602]},
    {input: [0.3984375, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.4828125, 0.383333333333333], output: [0.0898701185996068]},
    {input: [0.44375, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.6015625, 0.325], output: [0.112510165400612]},
    {input: [0.6015625, 0.325], output: [0.157790259002621]},
    {input: [0.55, 0.333333333333333], output: [0.180430305803626]},
    {input: [0.534375, 0.338888888888889], output: [0.203070352604631]},
    {input: [0.5453125, 0.266666666666667], output: [0.180430305803626]},
    {input: [0.5953125, 0.202777777777778], output: [0.24835044620664]},
    {input: [0.6015625, 0.205555555555556], output: [0.225710399405636]},
    {input: [0.61875, 0.219444444444444], output: [0.203070352604631]},
    {input: [0.5953125, 0.197222222222222], output: [0.180430305803626]},
    {input: [0.6015625, 0.2], output: [0.157790259002621]},
    {input: [0.578125, 0.211111111111111], output: [0.157790259002621]},
    {input: [0.5453125, 0.205555555555556], output: [0.157790259002621]},
    {input: [0.521875, 0.202777777777778], output: [0.135150212201616]},
    {input: [0.4828125, 0.183333333333333], output: [0.135150212201616]},
    {input: [0.4375, 0.208333333333333], output: [0.135150212201616]},
    {input: [0.4046875, 0.197222222222222], output: [0.135150212201616]},
    {input: [0.4046875, 0.2], output: [0.112510165400612]},
    {input: [0.353125, 0.194444444444444], output: [0.112510165400612]},
    {input: [0.296875, 0.169444444444444], output: [0.112510165400612]},
    {input: [0.2859375, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.26875, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.240625, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.2015625, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.05, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.5453125, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.9671875, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.6515625, 0.833333333333333], output: [0.203070352604631]},
    {input: [0.60625, 0.813888888888889], output: [0.225710399405636]},
    {input: [0.5671875, 0.827777777777778], output: [0.225710399405636]},
    {input: [0.5, 0.85], output: [0.180430305803626]},
    {input: [0.421875, 0.838888888888889], output: [0.180430305803626]},
    {input: [0.325, 0.827777777777778], output: [0.135150212201616]},
    {input: [0.296875, 0.822222222222222], output: [0.112510165400612]},
    {input: [0.296875, 0.838888888888889], output: [0.112510165400612]},
    {input: [0.190625, 0.7], output: [0.112510165400612]},
    {input: [0.134375, 0.755555555555556], output: [0.0898701185996068]},
    {input: [0.2359375, 0.825], output: [0.067230071798602]},
    {input: [0.33125, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.875], output: [0.0445900249975972]},
    {input: [0.190625, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.1515625, 0.986111111111111], output: [0.067230071798602]},
    {input: [0.1125, 0.0472222222222222], output: [0.067230071798602]},
    {input: [0.0609375, 0.0388888888888889], output: [0.0445900249975972]},
    {input: [0.028125, 0.15], output: [0.0445900249975972]},
    {input: [0.0328125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.3140625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.6], output: [0.0219499781965924]},
    {input: [0.38125, 0.475], output: [0.0219499781965924]},
    {input: [0.44375, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.353125, 0.486111111111111], output: [0.067230071798602]},
    {input: [0.6625, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.775, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.7421875, 0.236111111111111], output: [0.29363053980865]},
    {input: [0.7078125, 0.25], output: [0.29363053980865]},
    {input: [0.696875, 0.236111111111111], output: [0.270990493007645]},
    {input: [0.73125, 0.227777777777778], output: [0.225710399405636]},
    {input: [0.7921875, 0.244444444444444], output: [0.203070352604631]},
    {input: [0.71875, 0.252777777777778], output: [0.225710399405636]},
    {input: [0.646875, 0.244444444444444], output: [0.225710399405636]},
    {input: [0.55625, 0.286111111111111], output: [0.180430305803626]},
    {input: [0.590625, 0.277777777777778], output: [0.157790259002621]},
    {input: [0.303125, 0.330555555555556], output: [0.157790259002621]},
    {input: [0.2296875, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.39375, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.33125, 0.2], output: [0.067230071798602]},
    {input: [0.421875, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.4046875, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.105555555555556], output: [0.067230071798602]},
    {input: [0.253125, 0.0361111111111111], output: [0.067230071798602]},
    {input: [0.534375, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.4890625, 0.869444444444444], output: [0.067230071798602]},
    {input: [0.521875, 0.869444444444444], output: [0.0898701185996068]},
    {input: [0.60625, 0.886111111111111], output: [0.135150212201616]},
    {input: [0.61875, 0.880555555555556], output: [0.157790259002621]},
    {input: [0.6296875, 0.875], output: [0.180430305803626]},
    {input: [0.634375, 0.872222222222222], output: [0.157790259002621]},
    {input: [0.590625, 0.877777777777778], output: [0.180430305803626]},
    {input: [0.55625, 0.905555555555556], output: [0.157790259002621]},
    {input: [0.5625, 0.891666666666667], output: [0.157790259002621]},
    {input: [0.3203125, 0.208333333333333], output: [0.157790259002621]},
    {input: [0.2921875, 0.291666666666667], output: [0.135150212201616]},
    {input: [0.21875, 0.241666666666667], output: [0.0898701185996068]},
    {input: [0.38125, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.163888888888889], output: [0.0898701185996068]},
    {input: [0.184375, 0.075], output: [0.0898701185996068]},
    {input: [0.184375, 0.0166666666666667], output: [0.067230071798602]},
    {input: [0.1, 0.0361111111111111], output: [0.067230071798602]},
    {input: [0.38125, 0.0333333333333333], output: [0.0898701185996068]},
    {input: [0.3421875, 0.0472222222222222], output: [0.135150212201616]},
    {input: [0.26875, 0.305555555555556], output: [0.135150212201616]},
    {input: [0.2359375, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.16875, 0.275], output: [0.0898701185996068]},
    {input: [0.1625, 0.275], output: [0.067230071798602]},
    {input: [0.1453125, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.28125, 0.0277777777777778], output: [0.0898701185996068]},
    {input: [0.4375, 0.0444444444444444], output: [0.067230071798602]},
    {input: [0.534375, 0.0333333333333333], output: [0.135150212201616]},
    {input: [0.55, 0.0361111111111111], output: [0.180430305803626]},
    {input: [0.528125, 0.0472222222222222], output: [0.135150212201616]},
    {input: [0.421875, 0.0166666666666667], output: [0.135150212201616]},
    {input: [0.3875, 0.0388888888888889], output: [0.112510165400612]},
    {input: [0.4546875, 0.025], output: [0.112510165400612]},
    {input: [0.365625, 0.0277777777777778], output: [0.112510165400612]},
    {input: [0.4375, 0.0416666666666667], output: [0.112510165400612]},
    {input: [0.3765625, 0.0444444444444444], output: [0.112510165400612]},
    {input: [0.415625, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.303125, 0.952777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.936111111111111], output: [0.0898701185996068]},
    {input: [0.4890625, 0.925], output: [0.067230071798602]},
    {input: [0.5171875, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.4828125, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.49375, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.534375, 0.886111111111111], output: [0.112510165400612]},
    {input: [0.5, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.478125, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.465625, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.4609375, 0.922222222222222], output: [0.0898701185996068]},
    {input: [0.4265625, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.44375, 0.925], output: [0.0898701185996068]},
    {input: [0.4375, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.45, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.359375, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.246875, 0.983333333333333], output: [0.0898701185996068]},
    {input: [0.246875, 0.95], output: [0.067230071798602]},
    {input: [0.16875, 0.122222222222222], output: [0.067230071798602]},
    {input: [0.1125, 0.133333333333333], output: [0.067230071798602]},
    {input: [0.128125, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.0671875, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.0390625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.359375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.409375, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.45, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.38125, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.3875, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.353125, 0.180555555555556], output: [0.0898701185996068]},
    {input: [0.309375, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.28125, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.2359375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.28125, 0.25], output: [0.0445900249975972]},
    {input: [0.2359375, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.21875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.196875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.2078125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.275], output: [0.0445900249975972]},
    {input: [0.196875, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.16875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.2125, 0.2], output: [0.067230071798602]},
    {input: [0.2078125, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.071875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.1, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.0953125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.078125, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.0890625, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.9], output: [0.0219499781965924]},
    {input: [0.190625, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.3703125, 0.9], output: [0.0445900249975972]},
    {input: [0.3140625, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.3703125, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.3421875, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.353125, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.3765625, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.3203125, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.3421875, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.309375, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.869444444444444], output: [0.067230071798602]},
    {input: [0.2296875, 0.9], output: [0.067230071798602]},
    {input: [0.2078125, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.184375, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.875], output: [0.0219499781965924]},
    {input: [0.246875, 0.875], output: [0.0219499781965924]},
    {input: [0.28125, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.983333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.997222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.975], output: [0.0445900249975972]},
    {input: [0.2921875, 0.961111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.33125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.3140625, 0.875], output: [0.067230071798602]},
    {input: [0.409375, 0.9], output: [0.0898701185996068]},
    {input: [0.38125, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.3984375, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.409375, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.4265625, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.4609375, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.4609375, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.3984375, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.39375, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.3765625, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.3703125, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.3765625, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.353125, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.3203125, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.240625, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.2359375, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.225, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.9], output: [0.067230071798602]},
    {input: [0.353125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.38125, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.9], output: [0.067230071798602]},
    {input: [0.325, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.2359375, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.2859375, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.1515625, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.1734375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.875], output: [0.0219499781965924]},
    {input: [0.15625, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.9], output: [0.0219499781965924]},
    {input: [0.2640625, 0.85], output: [0.0219499781965924]},
    {input: [0.1625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.875], output: [0.0219499781965924]},
    {input: [0.28125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.825], output: [0.0445900249975972]},
    {input: [0.21875, 0.875], output: [0.0445900249975972]},
    {input: [0.2296875, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.85], output: [0.0219499781965924]},
    {input: [0.128125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.925], output: [0.0219499781965924]},
    {input: [0.2015625, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.825], output: [0.0445900249975972]},
    {input: [0.296875, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.253125, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.1171875, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.04375, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.275, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.3703125, 0.286111111111111], output: [0.0898701185996068]},
    {input: [0.309375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.2921875, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.21875, 0.130555555555556], output: [0.067230071798602]},
    {input: [0.10625, 0.0416666666666667], output: [0.0898701185996068]},
    {input: [0.1171875, 0.0611111111111111], output: [0.112510165400612]},
    {input: [0.1125, 0.0277777777777778], output: [0.0898701185996068]},
    {input: [0.128125, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.1515625, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.1625, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.225, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.2921875, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.9], output: [0.067230071798602]},
    {input: [0.275, 0.883333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.225, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.969444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.9], output: [0.0219499781965924]},
    {input: [0.128125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.925], output: [0.0219499781965924]},
    {input: [0.05625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.925], output: [0.0219499781965924]},
    {input: [0.1234375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.225], output: [0.0219499781965924]},
    {input: [0.0953125, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.925], output: [0.0219499781965924]},
    {input: [0.196875, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.0671875, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.134375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.95], output: [0.0219499781965924]},
    {input: [0.1625, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.911111111111111], output: [-0.000690068604412431]},
    {input: [0.33125, 0.888888888888889], output: [-0.000690068604412431]},
    {input: [0.246875, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.184375, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.05, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.5], output: [0.0219499781965924]},
    {input: [0.0390625, 0.422222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.763888888888889], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.805555555555556], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.05, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.966666666666667], output: [-0.000690068604412431]},
    {input: [0.1125, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.311111111111111], output: [-0.000690068604412431]},
    {input: [0.190625, 0.366666666666667], output: [-0.000690068604412431]},
    {input: [0.190625, 0.288888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.425], output: [0.0219499781965924]},
    {input: [0.184375, 0.358333333333333], output: [-0.000690068604412431]},
    {input: [0.190625, 0.316666666666667], output: [-0.000690068604412431]},
    {input: [0.196875, 0.355555555555556], output: [-0.000690068604412431]},
    {input: [0.275, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.296875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.421875, 0.3], output: [0.0219499781965924]},
    {input: [0.45, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.409375, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.4375, 0.358333333333333], output: [0.0898701185996068]},
    {input: [0.2296875, 0.344444444444444], output: [0.112510165400612]},
    {input: [0.1171875, 0.333333333333333], output: [0.0898701185996068]},
    {input: [0.184375, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.128125, 0.0138888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.822222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.240625, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.303125, 0.708333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.727777777777778], output: [0.067230071798602]},
    {input: [0.1171875, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.084375, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.0805555555555556], output: [0.0445900249975972]},
    {input: [0.1515625, 0.15], output: [0.0445900249975972]},
    {input: [0.190625, 0.138888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.25], output: [0.0219499781965924]},
    {input: [0.2296875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.325], output: [0.0219499781965924]},
    {input: [0.2359375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.26875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.33125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.4609375, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.3984375, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.1171875, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.2359375, 0.155555555555556], output: [0.067230071798602]},
    {input: [0.134375, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.3421875, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.309375, 0.825], output: [0.0219499781965924]},
    {input: [0.33125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.738888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.766666666666667], output: [0.0898701185996068]},
    {input: [0.353125, 0.730555555555556], output: [0.0898701185996068]},
    {input: [0.196875, 0.675], output: [0.067230071798602]},
    {input: [0.16875, 0.6], output: [0.067230071798602]},
    {input: [0.1125, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.1234375, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.1734375, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.2296875, 0.719444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.747222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.1234375, 0.622222222222222], output: [0.0445900249975972]},
    {input: [0.084375, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.015625, 0.625], output: [0.0445900249975972]},
    {input: [0.1125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.3421875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.2125, 0.425], output: [0.0445900249975972]},
    {input: [0.521875, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.5390625, 0.491666666666667], output: [0.067230071798602]},
    {input: [0.4546875, 0.45], output: [0.0898701185996068]},
    {input: [0.465625, 0.480555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.458333333333333], output: [0.112510165400612]},
    {input: [0.6234375, 0.497222222222222], output: [0.135150212201616]},
    {input: [0.634375, 0.480555555555556], output: [0.157790259002621]},
    {input: [0.6578125, 0.494444444444444], output: [0.157790259002621]},
    {input: [0.803125, 0.525], output: [0.135150212201616]},
    {input: [0.6578125, 0.538888888888889], output: [0.157790259002621]},
    {input: [0.5390625, 0.552777777777778], output: [0.157790259002621]},
    {input: [0.78125, 0.883333333333333], output: [0.135150212201616]},
    {input: [0.7703125, 0.866666666666667], output: [0.112510165400612]},
    {input: [0.675, 0.85], output: [0.225710399405636]},
    {input: [0.5953125, 0.841666666666667], output: [0.225710399405636]},
    {input: [0.690625, 0.816666666666667], output: [0.24835044620664]},
    {input: [0.3875, 0.85], output: [0.180430305803626]},
    {input: [0.2296875, 0.894444444444444], output: [0.180430305803626]},
    {input: [0.4046875, 0.844444444444444], output: [0.157790259002621]},
    {input: [0.3421875, 0.805555555555556], output: [0.135150212201616]},
    {input: [0.353125, 0.808333333333333], output: [0.135150212201616]},
    {input: [0.3375, 0.794444444444444], output: [0.112510165400612]},
    {input: [0.2578125, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.1734375, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.05, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.0671875, 0.988888888888889], output: [0.067230071798602]},
    {input: [0.084375, 0.0694444444444444], output: [0.067230071798602]},
    {input: [0.1125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.1234375, 0.369444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.3765625, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.5, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.5453125, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.465625, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.5109375, 0.875], output: [0.112510165400612]},
    {input: [0.4609375, 0.933333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.969444444444444], output: [0.0898701185996068]},
    {input: [0.3375, 0.0888888888888889], output: [0.0898701185996068]},
    {input: [0.253125, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.2359375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.184375, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.134375, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.140625, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.1625, 0.408333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.415625, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.39375, 0.25], output: [0.112510165400612]},
    {input: [0.45, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.4890625, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.471875, 0.227777777777778], output: [0.135150212201616]},
    {input: [0.4375, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.359375, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.3703125, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.39375, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.415625, 0.269444444444444], output: [0.112510165400612]},
    {input: [0.5, 0.280555555555556], output: [0.135150212201616]},
    {input: [0.478125, 0.277777777777778], output: [0.157790259002621]},
    {input: [0.478125, 0.275], output: [0.157790259002621]},
    {input: [0.3765625, 0.275], output: [0.135150212201616]},
    {input: [0.4546875, 0.266666666666667], output: [0.112510165400612]},
    {input: [0.45, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.4890625, 0.25], output: [0.112510165400612]},
    {input: [0.5390625, 0.213888888888889], output: [0.112510165400612]},
    {input: [0.590625, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.6125, 0.258333333333333], output: [0.135150212201616]},
    {input: [0.66875, 0.230555555555556], output: [0.157790259002621]},
    {input: [0.7140625, 0.216666666666667], output: [0.157790259002621]},
    {input: [0.8484375, 0.213888888888889], output: [0.180430305803626]},
    {input: [0.7421875, 0.355555555555556], output: [0.24835044620664]},
    {input: [0.7875, 0.366666666666667], output: [0.24835044620664]},
    {input: [0.7921875, 0.391666666666667], output: [0.24835044620664]},
    {input: [0.803125, 0.394444444444444], output: [0.225710399405636]},
    {input: [0.696875, 0.361111111111111], output: [0.24835044620664]},
    {input: [0.690625, 0.397222222222222], output: [0.24835044620664]},
    {input: [0.690625, 0.436111111111111], output: [0.270990493007645]},
    {input: [0.6625, 0.430555555555556], output: [0.24835044620664]},
    {input: [0.521875, 0.408333333333333], output: [0.225710399405636]},
    {input: [0.5171875, 0.480555555555556], output: [0.203070352604631]},
    {input: [0.4328125, 0.45], output: [0.180430305803626]},
    {input: [0.465625, 0.602777777777778], output: [0.180430305803626]},
    {input: [0.5109375, 0.677777777777778], output: [0.135150212201616]},
    {input: [0.4046875, 0.719444444444444], output: [0.112510165400612]},
    {input: [0.4375, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.33125, 0.872222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.980555555555556], output: [0.067230071798602]},
    {input: [0.1125, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.084375, 0.00277777777777778], output: [0.067230071798602]},
    {input: [0.071875, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.0671875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.253125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.3765625, 0.0333333333333333], output: [0.0219499781965924]},
    {input: [0.415625, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.409375, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.5, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.55625, 0.902777777777778], output: [0.135150212201616]},
    {input: [0.3765625, 0.916666666666667], output: [0.135150212201616]},
    {input: [0.5625, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.4890625, 0.905555555555556], output: [0.157790259002621]},
    {input: [0.5953125, 0.894444444444444], output: [0.135150212201616]},
    {input: [0.5390625, 0.886111111111111], output: [0.157790259002621]},
    {input: [0.5109375, 0.875], output: [0.157790259002621]},
    {input: [0.50625, 0.875], output: [0.135150212201616]},
    {input: [0.39375, 0.905555555555556], output: [0.157790259002621]},
    {input: [0.3484375, 0.916666666666667], output: [0.135150212201616]},
    {input: [0.140625, 0.0555555555555556], output: [0.0898701185996068]},
    {input: [0.3203125, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.225, 0.9], output: [0.0898701185996068]},
    {input: [0.15625, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.128125, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.2921875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.3875, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.3984375, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.38125, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.4046875, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.4046875, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.4265625, 0.258333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.244444444444444], output: [0.112510165400612]},
    {input: [0.365625, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.4375, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.4265625, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.44375, 0.225], output: [0.112510165400612]},
    {input: [0.4890625, 0.225], output: [0.112510165400612]},
    {input: [0.478125, 0.230555555555556], output: [0.135150212201616]},
    {input: [0.44375, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.5390625, 0.225], output: [0.112510165400612]},
    {input: [0.5109375, 0.205555555555556], output: [0.135150212201616]},
    {input: [0.5453125, 0.2], output: [0.112510165400612]},
    {input: [0.49375, 0.2], output: [0.112510165400612]},
    {input: [0.5453125, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.5671875, 0.202777777777778], output: [0.135150212201616]},
    {input: [0.4890625, 0.194444444444444], output: [0.112510165400612]},
    {input: [0.478125, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.478125, 0.241666666666667], output: [0.135150212201616]},
    {input: [0.4328125, 0.241666666666667], output: [0.135150212201616]},
    {input: [0.4046875, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4328125, 0.244444444444444], output: [0.135150212201616]},
    {input: [0.3765625, 0.294444444444444], output: [0.135150212201616]},
    {input: [0.45, 0.322222222222222], output: [0.135150212201616]},
    {input: [0.359375, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.415625, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.4265625, 0.925], output: [0.0898701185996068]},
    {input: [0.359375, 0.944444444444444], output: [0.0898701185996068]},
    {input: [0.1234375, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.084375, 0.108333333333333], output: [0.067230071798602]},
    {input: [0.078125, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.05, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.0328125, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.125], output: [0.0445900249975972]},
    {input: [0.1125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.991666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.986111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.10625, 0.05], output: [0.0445900249975972]},
    {input: [0.0671875, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.988888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.85], output: [0.0219499781965924]},
    {input: [0.021875, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.190625, 0.275], output: [0.0219499781965924]},
    {input: [0.2078125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.16875, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.275], output: [0.0445900249975972]},
    {input: [0.15625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.028125, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.015625, 0.0555555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.7], output: [0.0219499781965924]},
    {input: [0.0953125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.725], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.591666666666667], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.430555555555556], output: [-0.000690068604412431]},
    {input: [0.134375, 0.647222222222222], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.622222222222222], output: [-0.000690068604412431]},
    {input: [0.128125, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.078125, 0.580555555555556], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.483333333333333], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.211111111111111], output: [-0.000690068604412431]},
    {input: [0.1125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.25], output: [0.0219499781965924]},
    {input: [0.0671875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.38125, 0.277777777777778], output: [0.0898701185996068]},
    {input: [0.5171875, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.49375, 0.216666666666667], output: [0.112510165400612]},
    {input: [0.521875, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.534375, 0.330555555555556], output: [0.112510165400612]},
    {input: [0.5671875, 0.338888888888889], output: [0.157790259002621]},
    {input: [0.690625, 0.377777777777778], output: [0.180430305803626]},
    {input: [0.646875, 0.336111111111111], output: [0.225710399405636]},
    {input: [0.6515625, 0.402777777777778], output: [0.225710399405636]},
    {input: [0.55625, 0.419444444444444], output: [0.225710399405636]},
    {input: [0.4828125, 0.411111111111111], output: [0.180430305803626]},
    {input: [0.5, 0.530555555555556], output: [0.157790259002621]},
    {input: [0.303125, 0.486111111111111], output: [0.135150212201616]},
    {input: [0.4375, 0.452777777777778], output: [0.112510165400612]},
    {input: [0.4046875, 0.444444444444444], output: [0.112510165400612]},
    {input: [0.3375, 0.436111111111111], output: [0.0898701185996068]},
    {input: [0.26875, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.3203125, 0.65], output: [0.0219499781965924]},
    {input: [0.2921875, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.591666666666667], output: [0.0445900249975972]},
    {input: [0.15625, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.575], output: [0.0445900249975972]},
    {input: [0.2921875, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.353125, 0.536111111111111], output: [0.0445900249975972]},
    {input: [0.296875, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.3984375, 0.430555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.421875, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.421875, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.4265625, 0.391666666666667], output: [0.0898701185996068]},
    {input: [0.4609375, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.5453125, 0.355555555555556], output: [0.0898701185996068]},
    {input: [0.5390625, 0.3], output: [0.135150212201616]},
    {input: [0.578125, 0.311111111111111], output: [0.135150212201616]},
    {input: [0.73125, 0.269444444444444], output: [0.157790259002621]},
    {input: [0.7359375, 0.225], output: [0.203070352604631]},
    {input: [0.8484375, 0.219444444444444], output: [0.225710399405636]},
    {input: [0.8203125, 0.225], output: [0.203070352604631]},
    {input: [0.83125, 0.241666666666667], output: [0.225710399405636]},
    {input: [0.775, 0.236111111111111], output: [0.225710399405636]},
    {input: [0.759375, 0.236111111111111], output: [0.225710399405636]},
    {input: [0.803125, 0.222222222222222], output: [0.203070352604631]},
    {input: [0.7078125, 0.227777777777778], output: [0.203070352604631]},
    {input: [0.6625, 0.225], output: [0.180430305803626]},
    {input: [0.6296875, 0.241666666666667], output: [0.203070352604631]},
    {input: [0.640625, 0.227777777777778], output: [0.203070352604631]},
    {input: [0.6125, 0.227777777777778], output: [0.203070352604631]},
    {input: [0.5390625, 0.213888888888889], output: [0.180430305803626]},
    {input: [0.5390625, 0.230555555555556], output: [0.157790259002621]},
    {input: [0.5171875, 0.236111111111111], output: [0.157790259002621]},
    {input: [0.6234375, 0.266666666666667], output: [0.157790259002621]},
    {input: [0.5734375, 0.227777777777778], output: [0.180430305803626]},
    {input: [0.6296875, 0.263888888888889], output: [0.157790259002621]},
    {input: [0.534375, 0.236111111111111], output: [0.157790259002621]},
    {input: [0.465625, 0.283333333333333], output: [0.135150212201616]},
    {input: [0.44375, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.4890625, 0.225], output: [0.112510165400612]},
    {input: [0.4609375, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.4546875, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.45, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.44375, 0.225], output: [0.0898701185996068]},
    {input: [0.409375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.3484375, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.3203125, 0.225], output: [0.067230071798602]},
    {input: [0.365625, 0.225], output: [0.067230071798602]},
    {input: [0.2015625, 0.166666666666667], output: [0.067230071798602]},
    {input: [0.1171875, 0.152777777777778], output: [0.067230071798602]},
    {input: [0.1453125, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.0953125, 0.1], output: [0.0445900249975972]},
    {input: [0.0890625, 0.980555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.9], output: [0.0219499781965924]},
    {input: [0.353125, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.359375, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.39375, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.421875, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.296875, 0.9], output: [0.0898701185996068]},
    {input: [0.33125, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.3140625, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.3375, 0.847222222222222], output: [0.0898701185996068]},
    {input: [0.2921875, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.409375, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.49375, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.866666666666667], output: [0.112510165400612]},
    {input: [0.4609375, 0.863888888888889], output: [0.112510165400612]},
    {input: [0.4375, 0.875], output: [0.112510165400612]},
    {input: [0.50625, 0.866666666666667], output: [0.112510165400612]},
    {input: [0.521875, 0.886111111111111], output: [0.112510165400612]},
    {input: [0.471875, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.49375, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.4328125, 0.875], output: [0.112510165400612]},
    {input: [0.4265625, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.365625, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.359375, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.33125, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.296875, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.3140625, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.253125, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.21875, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.197222222222222], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.3], output: [0.0219499781965924]},
    {input: [0.128125, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2640625, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.175], output: [0.0219499781965924]},
    {input: [0.2578125, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.111111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.3765625, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.966666666666667], output: [0.0898701185996068]},
    {input: [0.3203125, 0.00277777777777778], output: [0.067230071798602]},
    {input: [0.26875, 0.958333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.39375, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.45, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.5, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.863888888888889], output: [0.0898701185996068]},
    {input: [0.465625, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.4609375, 0.919444444444444], output: [0.112510165400612]},
    {input: [0.39375, 0.886111111111111], output: [0.112510165400612]},
    {input: [0.4609375, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.4046875, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.415625, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.3703125, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.3875, 0.922222222222222], output: [0.0898701185996068]},
    {input: [0.359375, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.325, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.325, 0.958333333333333], output: [0.067230071798602]},
    {input: [0.303125, 0.933333333333333], output: [0.0898701185996068]},
    {input: [0.3375, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.325, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.409375, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.421875, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.4265625, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.9], output: [0.0898701185996068]},
    {input: [0.421875, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.421875, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.471875, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.359375, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.4375, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.3984375, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.39375, 0.933333333333333], output: [0.112510165400612]},
    {input: [0.33125, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.3203125, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.33125, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.309375, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.2359375, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.253125, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.21875, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.2125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.805555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.65], output: [0.0219499781965924]},
    {input: [0.0609375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.572222222222222], output: [-0.000690068604412431]},
    {input: [0.16875, 0.5], output: [0.0219499781965924]},
    {input: [0.134375, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.733333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.925], output: [0.0219499781965924]},
    {input: [0.184375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.3421875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.4375, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.590625, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.5625, 0.883333333333333], output: [0.112510165400612]},
    {input: [0.528125, 0.897222222222222], output: [0.135150212201616]},
    {input: [0.49375, 0.247222222222222], output: [0.157790259002621]},
    {input: [0.4828125, 0.266666666666667], output: [0.157790259002621]},
    {input: [0.4890625, 0.222222222222222], output: [0.135150212201616]},
    {input: [0.44375, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.4546875, 0.211111111111111], output: [0.135150212201616]},
    {input: [0.4609375, 0.233333333333333], output: [0.135150212201616]},
    {input: [0.4890625, 0.227777777777778], output: [0.135150212201616]},
    {input: [0.45, 0.233333333333333], output: [0.135150212201616]},
    {input: [0.45, 0.244444444444444], output: [0.135150212201616]},
    {input: [0.3484375, 0.263888888888889], output: [0.135150212201616]},
    {input: [0.3984375, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.44375, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4546875, 0.238888888888889], output: [0.135150212201616]},
    {input: [0.3875, 0.222222222222222], output: [0.135150212201616]},
    {input: [0.3421875, 0.225], output: [0.112510165400612]},
    {input: [0.39375, 0.213888888888889], output: [0.112510165400612]},
    {input: [0.4265625, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.4609375, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.3984375, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4046875, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.38125, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.3484375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.3375, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.28125, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.26875, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.246875, 0.225], output: [0.0898701185996068]},
    {input: [0.275, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.225], output: [0.0898701185996068]},
    {input: [0.2125, 0.197222222222222], output: [0.0898701185996068]},
    {input: [0.190625, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.2015625, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.1625, 0.197222222222222], output: [0.112510165400612]},
    {input: [0.2015625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.1734375, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.1734375, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.1796875, 0.188888888888889], output: [0.0898701185996068]},
    {input: [0.0890625, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.0671875, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.05625, 0.15], output: [0.0898701185996068]},
    {input: [0.1515625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.1796875, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.21875, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.2125, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.2296875, 0.275], output: [0.0898701185996068]},
    {input: [0.275, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.2125, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.240625, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.25], output: [0.067230071798602]},
    {input: [0.275, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.2859375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.253125, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.21875, 0.225], output: [0.0898701185996068]},
    {input: [0.2578125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.365625, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.353125, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.3984375, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.4265625, 0.225], output: [0.112510165400612]},
    {input: [0.521875, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.50625, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.521875, 0.230555555555556], output: [0.135150212201616]},
    {input: [0.3765625, 0.227777777777778], output: [0.157790259002621]},
    {input: [0.309375, 0.252777777777778], output: [0.135150212201616]},
    {input: [0.225, 0.202777777777778], output: [0.112510165400612]},
    {input: [0.2578125, 0.213888888888889], output: [0.112510165400612]},
    {input: [0.3984375, 0.225], output: [0.112510165400612]},
    {input: [0.4890625, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.6125, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.66875, 0.211111111111111], output: [0.135150212201616]},
    {input: [0.584375, 0.216666666666667], output: [0.157790259002621]},
    {input: [0.5953125, 0.208333333333333], output: [0.157790259002621]},
    {input: [0.60625, 0.208333333333333], output: [0.157790259002621]},
    {input: [0.4546875, 0.222222222222222], output: [0.157790259002621]},
    {input: [0.4265625, 0.202777777777778], output: [0.180430305803626]},
    {input: [0.415625, 0.213888888888889], output: [0.157790259002621]},
    {input: [0.4609375, 0.252777777777778], output: [0.135150212201616]},
    {input: [0.5625, 0.241666666666667], output: [0.135150212201616]},
    {input: [0.3703125, 0.230555555555556], output: [0.135150212201616]},
    {input: [0.28125, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.26875, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.4546875, 0.269444444444444], output: [0.135150212201616]},
    {input: [0.4546875, 0.277777777777778], output: [0.135150212201616]},
    {input: [0.478125, 0.280555555555556], output: [0.157790259002621]},
    {input: [0.465625, 0.255555555555556], output: [0.180430305803626]},
    {input: [0.4265625, 0.252777777777778], output: [0.180430305803626]},
    {input: [0.28125, 0.227777777777778], output: [0.157790259002621]},
    {input: [0.303125, 0.244444444444444], output: [0.135150212201616]},
    {input: [0.365625, 0.236111111111111], output: [0.135150212201616]},
    {input: [0.39375, 0.233333333333333], output: [0.135150212201616]},
    {input: [0.4046875, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.33125, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.25], output: [0.0898701185996068]},
    {input: [0.3765625, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.3875, 0.241666666666667], output: [0.0898701185996068]},
    {input: [0.359375, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.325, 0.291666666666667], output: [0.112510165400612]},
    {input: [0.3484375, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.309375, 0.244444444444444], output: [0.112510165400612]},
    {input: [0.2921875, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.3875, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.409375, 0.275], output: [0.0898701185996068]},
    {input: [0.3703125, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.44375, 0.225], output: [0.067230071798602]},
    {input: [0.4609375, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.325, 0.225], output: [0.0898701185996068]},
    {input: [0.4046875, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.4265625, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.4609375, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.3375, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.4046875, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.3484375, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.3375, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.365625, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.359375, 0.352777777777778], output: [0.0898701185996068]},
    {input: [0.190625, 0.45], output: [0.0898701185996068]},
    {input: [0.2296875, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.0671875, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.0953125, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.2078125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.3984375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.225], output: [0.0445900249975972]},
    {input: [0.465625, 0.225], output: [0.0445900249975972]},
    {input: [0.4546875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.309375, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.39375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.1515625, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.415625, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.325, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.2921875, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.196875, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.2296875, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.4046875, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.4890625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.3703125, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.4375, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.478125, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.365625, 0.425], output: [0.0898701185996068]},
    {input: [0.55625, 0.45], output: [0.112510165400612]},
    {input: [0.39375, 0.480555555555556], output: [0.135150212201616]},
    {input: [0.60625, 0.641666666666667], output: [0.135150212201616]},
    {input: [0.646875, 0.652777777777778], output: [0.112510165400612]},
    {input: [0.1734375, 0.636111111111111], output: [0.135150212201616]},
    {input: [0.4546875, 0.488888888888889], output: [0.0898701185996068]},
    {input: [0.4328125, 0.572222222222222], output: [0.067230071798602]},
    {input: [0.296875, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.4], output: [0.0445900249975972]},
    {input: [0.275, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.33125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.3203125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.225, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.2640625, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.4609375, 0.202777777777778], output: [0.0898701185996068]},
    {input: [0.4328125, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.415625, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.478125, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.4890625, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.4328125, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.409375, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.45, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.2125, 0.355555555555556], output: [0.0898701185996068]},
    {input: [0.134375, 0.405555555555556], output: [0.0898701185996068]},
    {input: [0.3140625, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.3875, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.2578125, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.309375, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.2921875, 0.875], output: [0.067230071798602]},
    {input: [0.3703125, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.75], output: [0.0898701185996068]},
    {input: [0.1734375, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.10625, 0.591666666666667], output: [0.067230071798602]},
    {input: [0.140625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.015625, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.078125, 0.425], output: [0.0445900249975972]},
    {input: [0.1, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.625], output: [0.0219499781965924]},
    {input: [0.0890625, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.45], output: [0.0219499781965924]},
    {input: [0.2921875, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.3703125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.3421875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.3703125, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.45, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.3875, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.3984375, 0.241666666666667], output: [0.0898701185996068]},
    {input: [0.38125, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.3703125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.4375, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.38125, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.359375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.2640625, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.4375, 0.275], output: [0.0898701185996068]},
    {input: [0.4328125, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.465625, 0.194444444444444], output: [0.112510165400612]},
    {input: [0.415625, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.4890625, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.5, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.50625, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.5390625, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.465625, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4265625, 0.211111111111111], output: [0.112510165400612]},
    {input: [0.3984375, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4828125, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.3984375, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.4546875, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.415625, 0.219444444444444], output: [0.112510165400612]},
    {input: [0.3421875, 0.269444444444444], output: [0.112510165400612]},
    {input: [0.3484375, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.3765625, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.33125, 0.388888888888889], output: [0.0898701185996068]},
    {input: [0.45, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.488888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.416666666666667], output: [0.0898701185996068]},
    {input: [0.3140625, 0.458333333333333], output: [0.0898701185996068]},
    {input: [0.2859375, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.35], output: [0.0445900249975972]},
    {input: [0.3375, 0.375], output: [0.067230071798602]},
    {input: [0.3984375, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.3875, 0.411111111111111], output: [0.067230071798602]},
    {input: [0.4828125, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.49375, 0.5], output: [0.0898701185996068]},
    {input: [0.38125, 0.463888888888889], output: [0.0898701185996068]},
    {input: [0.3375, 0.497222222222222], output: [0.067230071798602]},
    {input: [0.275, 0.488888888888889], output: [0.067230071798602]},
    {input: [0.3203125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.4609375, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.2359375, 0.597222222222222], output: [0.067230071798602]},
    {input: [0.325, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.466666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.303125, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.508333333333333], output: [0.067230071798602]},
    {input: [0.275, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.6], output: [0.067230071798602]},
    {input: [0.1796875, 0.644444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.0609375, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.127777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.113888888888889], output: [-0.000690068604412431]},
    {input: [0.1625, 0.147222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.04375, 0.158333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.377777777777778], output: [-0.000690068604412431]},
    {input: [0.196875, 0.258333333333333], output: [-0.000690068604412431]},
    {input: [0.134375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.16875, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.05, 0.119444444444444], output: [-0.000690068604412431]},
    {input: [0.15625, 0.191666666666667], output: [-0.000690068604412431]},
    {input: [0.246875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.15], output: [0.0445900249975972]},
    {input: [0.2078125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.1734375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.140625, 0.102777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.225, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.2078125, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.1125, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.0609375, 0.144444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.0888888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.3875, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.4375, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.4265625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.465625, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.4828125, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.471875, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.4609375, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.4546875, 0.225], output: [0.0898701185996068]},
    {input: [0.421875, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.3703125, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.353125, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.275], output: [0.0445900249975972]},
    {input: [0.1734375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.2296875, 0.225], output: [0.067230071798602]},
    {input: [0.3140625, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.246875, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.253125, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.409375, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.50625, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.409375, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.246875, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.2015625, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.1625, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.140625, 0.330555555555556], output: [0.0898701185996068]},
    {input: [0.0328125, 0.141666666666667], output: [0.0898701185996068]},
    {input: [0.1515625, 0.1], output: [0.0898701185996068]},
    {input: [0.04375, 0.111111111111111], output: [0.067230071798602]},
    {input: [0.10625, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.5453125, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.6015625, 0.238888888888889], output: [0.112510165400612]},
    {input: [0.465625, 0.258333333333333], output: [0.135150212201616]},
    {input: [0.421875, 0.258333333333333], output: [0.135150212201616]},
    {input: [0.421875, 0.266666666666667], output: [0.135150212201616]},
    {input: [0.303125, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.3375, 0.147222222222222], output: [0.0898701185996068]},
    {input: [0.140625, 0.666666666666667], output: [0.0898701185996068]},
    {input: [0.1, 0.719444444444444], output: [0.0898701185996068]},
    {input: [0.1234375, 0.816666666666667], output: [0.067230071798602]},
    {input: [0.084375, 0.963888888888889], output: [0.067230071798602]},
    {input: [0.1171875, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.071875, 0.716666666666667], output: [0.067230071798602]},
    {input: [0.04375, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.26875, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.190625, 0.15], output: [0.067230071798602]},
    {input: [0.0609375, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.128125, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.646875, 0.241666666666667], output: [0.0898701185996068]},
    {input: [0.6015625, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.49375, 0.247222222222222], output: [0.112510165400612]},
    {input: [0.584375, 0.405555555555556], output: [0.112510165400612]},
    {input: [0.353125, 0.45], output: [0.135150212201616]},
    {input: [0.3875, 0.508333333333333], output: [0.112510165400612]},
    {input: [0.303125, 0.416666666666667], output: [0.0898701185996068]},
    {input: [0.26875, 0.383333333333333], output: [0.112510165400612]},
    {input: [0.303125, 0.483333333333333], output: [0.112510165400612]},
    {input: [0.4375, 0.561111111111111], output: [0.0898701185996068]},
    {input: [0.325, 0.55], output: [0.0898701185996068]},
    {input: [0.190625, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2578125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.359375, 0.25], output: [0.067230071798602]},
    {input: [0.303125, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.240625, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.359375, 0.225], output: [0.067230071798602]},
    {input: [0.353125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.5390625, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.355555555555556], output: [0.0898701185996068]},
    {input: [0.3875, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.409375, 0.375], output: [0.0898701185996068]},
    {input: [0.421875, 0.313888888888889], output: [0.0898701185996068]},
    {input: [0.5109375, 0.277777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.244444444444444], output: [0.112510165400612]},
    {input: [0.3875, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.4046875, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.353125, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.415625, 0.291666666666667], output: [0.112510165400612]},
    {input: [0.33125, 0.297222222222222], output: [0.112510165400612]},
    {input: [0.353125, 0.3], output: [0.0898701185996068]},
    {input: [0.296875, 0.294444444444444], output: [0.0898701185996068]},
    {input: [0.240625, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.2359375, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.28125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.325], output: [0.0445900249975972]},
    {input: [0.3140625, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.365625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.225], output: [0.067230071798602]},
    {input: [0.1234375, 0.486111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.0953125, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.0671875, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.05, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.0953125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.130555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.2], output: [0.0219499781965924]},
    {input: [0.303125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.3375, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.4046875, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.409375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.465625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.4328125, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.134375, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.33125, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.38125, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.275, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.296875, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.253125, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.2015625, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.240625, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.240625, 0.205555555555556], output: [0.0898701185996068]},
    {input: [0.2359375, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.05, 0.652777777777778], output: [0.067230071798602]},
    {input: [0.49375, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.5734375, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.590625, 0.3], output: [0.135150212201616]},
    {input: [0.3375, 0.369444444444444], output: [0.157790259002621]},
    {input: [0.3140625, 0.380555555555556], output: [0.157790259002621]},
    {input: [0.50625, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.253125, 0.477777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.3484375, 0.380555555555556], output: [0.0898701185996068]},
    {input: [0.3875, 0.372222222222222], output: [0.112510165400612]},
    {input: [0.4828125, 0.405555555555556], output: [0.112510165400612]},
    {input: [0.353125, 0.35], output: [0.112510165400612]},
    {input: [0.4328125, 0.308333333333333], output: [0.135150212201616]},
    {input: [0.3421875, 0.269444444444444], output: [0.135150212201616]},
    {input: [0.3484375, 0.225], output: [0.112510165400612]},
    {input: [0.353125, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.3703125, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.3875, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.16875, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.0671875, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.0609375, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.140625, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.705555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.736111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.309375, 0.925], output: [0.0219499781965924]},
    {input: [0.246875, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.0609375, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.0671875, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.075], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.2078125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.4328125, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.44375, 0.225], output: [0.067230071798602]},
    {input: [0.421875, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.421875, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.38125, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.353125, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.2640625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.3421875, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.286111111111111], output: [0.0898701185996068]},
    {input: [0.246875, 0.338888888888889], output: [0.0898701185996068]},
    {input: [0.275, 0.358333333333333], output: [0.0898701185996068]},
    {input: [0.4375, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.2859375, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.3984375, 0.288888888888889], output: [0.0898701185996068]},
    {input: [0.28125, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.3875, 0.325], output: [0.067230071798602]},
    {input: [0.4546875, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.44375, 0.258333333333333], output: [0.0898701185996068]},
    {input: [0.44375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.5171875, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.45, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.4828125, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.4828125, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.415625, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.409375, 0.258333333333333], output: [0.112510165400612]},
    {input: [0.4265625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.3421875, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.241666666666667], output: [0.0898701185996068]},
    {input: [0.296875, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.3421875, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.359375, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.240625, 0.244444444444444], output: [0.0898701185996068]},
    {input: [0.365625, 0.205555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.190625, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.2578125, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.2921875, 0.225], output: [0.067230071798602]},
    {input: [0.2921875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.246875, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.325], output: [0.0219499781965924]},
    {input: [0.2359375, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.325], output: [0.0445900249975972]},
    {input: [0.2859375, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.441666666666667], output: [0.0445900249975972]},
    {input: [0.2578125, 0.405555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.28125, 0.375], output: [0.067230071798602]},
    {input: [0.303125, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.16875, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.1625, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.1734375, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.190625, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.1234375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.15625, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.1453125, 0.85], output: [0.0898701185996068]},
    {input: [0.1234375, 0.741666666666667], output: [0.067230071798602]},
    {input: [0.1734375, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.2015625, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.925], output: [0.0445900249975972]},
    {input: [0.2921875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.184375, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.969444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.0277777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.1796875, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.1734375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.875], output: [0.0445900249975972]},
    {input: [0.2078125, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.933333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.2015625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.415625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.3203125, 0.394444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.2578125, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.246875, 0.35], output: [0.0898701185996068]},
    {input: [0.39375, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.413888888888889], output: [0.067230071798602]},
    {input: [0.353125, 0.402777777777778], output: [0.0898701185996068]},
    {input: [0.3765625, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.275, 0.369444444444444], output: [0.112510165400612]},
    {input: [0.2640625, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.2859375, 0.305555555555556], output: [0.0898701185996068]},
    {input: [0.2296875, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.1734375, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.134375, 0.325], output: [0.0445900249975972]},
    {input: [0.190625, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.2015625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.2], output: [0.0445900249975972]},
    {input: [0.253125, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2859375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.296875, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.3765625, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.411111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.427777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.2640625, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.2859375, 0.322222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.352777777777778], output: [0.0898701185996068]},
    {input: [0.50625, 0.402777777777778], output: [0.135150212201616]},
    {input: [0.578125, 0.413888888888889], output: [0.135150212201616]},
    {input: [0.4328125, 0.386111111111111], output: [0.157790259002621]},
    {input: [0.4828125, 0.391666666666667], output: [0.157790259002621]},
    {input: [0.471875, 0.363888888888889], output: [0.135150212201616]},
    {input: [0.44375, 0.325], output: [0.135150212201616]},
    {input: [0.5390625, 0.338888888888889], output: [0.135150212201616]},
    {input: [0.66875, 0.394444444444444], output: [0.157790259002621]},
    {input: [0.6859375, 0.413888888888889], output: [0.180430305803626]},
    {input: [0.5625, 0.441666666666667], output: [0.203070352604631]},
    {input: [0.4265625, 0.472222222222222], output: [0.180430305803626]},
    {input: [0.3484375, 0.580555555555556], output: [0.157790259002621]},
    {input: [0.3984375, 0.636111111111111], output: [0.157790259002621]},
    {input: [0.3765625, 0.666666666666667], output: [0.112510165400612]},
    {input: [0.2921875, 0.744444444444444], output: [0.0898701185996068]},
    {input: [0.2578125, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.2859375, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.3375, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.196875, 0.425], output: [0.0219499781965924]},
    {input: [0.196875, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.425], output: [0.0219499781965924]},
    {input: [0.2015625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.35], output: [0.0219499781965924]},
    {input: [0.1625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.377777777777778], output: [-0.000690068604412431]},
    {input: [0.1125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.084375, 0.280555555555556], output: [-0.000690068604412431]},
    {input: [0.134375, 0.15], output: [-0.000690068604412431]},
    {input: [0.16875, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.190625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.266666666666667], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.3375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.3875, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.3875, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.44375, 0.325], output: [0.067230071798602]},
    {input: [0.3375, 0.35], output: [0.067230071798602]},
    {input: [0.2640625, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.3203125, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.1, 0.455555555555556], output: [0.067230071798602]},
    {input: [0.1171875, 0.802777777777778], output: [0.067230071798602]},
    {input: [0.184375, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.0777777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.872222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.4375, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.421875, 0.819444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.4265625, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.808333333333333], output: [0.0898701185996068]},
    {input: [0.39375, 0.822222222222222], output: [0.0898701185996068]},
    {input: [0.3375, 0.8], output: [0.0898701185996068]},
    {input: [0.309375, 0.697222222222222], output: [0.0898701185996068]},
    {input: [0.325, 0.769444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.763888888888889], output: [0.067230071798602]},
    {input: [0.275, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.966666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.0109375, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.469444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.391666666666667], output: [-0.000690068604412431]},
    {input: [0.190625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.8], output: [0.0219499781965924]},
    {input: [0.190625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.3], output: [0.0219499781965924]},
    {input: [0.10625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.35], output: [0.0219499781965924]},
    {input: [0.134375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.188888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.411111111111111], output: [-0.000690068604412431]},
    {input: [0.196875, 0.325], output: [0.0219499781965924]},
    {input: [0.246875, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.2125, 0.3], output: [0.0219499781965924]},
    {input: [0.2859375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.33125, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.128125, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.683333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.00555555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.15625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.071875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.05, 0.025], output: [0.0219499781965924]},
    {input: [0.0671875, 0.00555555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.0109375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.0046875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.0046875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.869444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.00555555555555556], output: [-0.000690068604412431]},
    {input: [0.071875, 0.947222222222222], output: [-0.000690068604412431]},
    {input: [0.071875, 0.933333333333333], output: [-0.000690068604412431]},
    {input: [0.05, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.1625, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.825], output: [0.0219499781965924]},
    {input: [0.1234375, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.9], output: [0.0219499781965924]},
    {input: [0.1515625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.775], output: [0.0219499781965924]},
    {input: [0.1515625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.741666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.730555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.697222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.772222222222222], output: [-0.000690068604412431]},
    {input: [0.04375, 0.311111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.213888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.16875, 0.2], output: [0.0219499781965924]},
    {input: [0.1515625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.219444444444444], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.875], output: [0.0219499781965924]},
    {input: [0.39375, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.45, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.3421875, 0.994444444444444], output: [0.112510165400612]},
    {input: [0.2578125, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.21875, 0.972222222222222], output: [0.067230071798602]},
    {input: [0.2078125, 0.983333333333333], output: [0.067230071798602]},
    {input: [0.190625, 0.00555555555555556], output: [0.067230071798602]},
    {input: [0.2015625, 0.969444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.075], output: [0.0445900249975972]},
    {input: [0.140625, 0.975], output: [0.0445900249975972]},
    {input: [0.0328125, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.021875, 0.0138888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.8], output: [0.0219499781965924]},
    {input: [0.134375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.866666666666667], output: [-0.000690068604412431]},
    {input: [0.3140625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.3703125, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.415625, 0.9], output: [0.067230071798602]},
    {input: [0.39375, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.3203125, 0.997222222222222], output: [0.0898701185996068]},
    {input: [0.225, 0.0555555555555556], output: [0.067230071798602]},
    {input: [0.184375, 0.0111111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.415625, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.3765625, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.2125, 0.969444444444444], output: [0.067230071798602]},
    {input: [0.5, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.584375, 0.2], output: [0.0445900249975972]},
    {input: [0.5671875, 0.211111111111111], output: [0.157790259002621]},
    {input: [0.6234375, 0.208333333333333], output: [0.180430305803626]},
    {input: [0.60625, 0.213888888888889], output: [0.180430305803626]},
    {input: [0.578125, 0.205555555555556], output: [0.157790259002621]},
    {input: [0.521875, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.5390625, 0.225], output: [0.112510165400612]},
    {input: [0.415625, 0.213888888888889], output: [0.112510165400612]},
    {input: [0.365625, 0.2], output: [0.112510165400612]},
    {input: [0.3375, 0.208333333333333], output: [0.0898701185996068]},
    {input: [0.196875, 0.158333333333333], output: [0.0898701185996068]},
    {input: [0.3484375, 0.191666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.309375, 0.222222222222222], output: [0.067230071798602]},
    {input: [0.28125, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.296875, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.28125, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.1625, 0.280555555555556], output: [0.0898701185996068]},
    {input: [0.1125, 0.397222222222222], output: [0.067230071798602]},
    {input: [0.2078125, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.15625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.190625, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.3203125, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.33125, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.2], output: [0.067230071798602]},
    {input: [0.2578125, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.2015625, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.1515625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.3], output: [0.0219499781965924]},
    {input: [0.21875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.25], output: [0.0219499781965924]},
    {input: [0.225, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.296875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.227777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.3421875, 0.161111111111111], output: [0.0445900249975972]},
    {input: [0.38125, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.4265625, 0.194444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.197222222222222], output: [0.0898701185996068]},
    {input: [0.4375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.409375, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.3984375, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.39375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.3765625, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.353125, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.3140625, 0.225], output: [0.067230071798602]},
    {input: [0.275, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.2859375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.240625, 0.275], output: [0.0445900249975972]},
    {input: [0.2296875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.25], output: [0.0445900249975972]},
    {input: [0.353125, 0.25], output: [0.0219499781965924]},
    {input: [0.325, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.33125, 0.225], output: [0.0445900249975972]},
    {input: [0.353125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.3203125, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.2859375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.2640625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.1625, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.225], output: [0.0219499781965924]},
    {input: [0.16875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.071875, 0.4], output: [0.0219499781965924]},
    {input: [0.1125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.25], output: [0.0219499781965924]},
    {input: [0.2296875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2078125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.575], output: [0.0219499781965924]},
    {input: [0.16875, 0.675], output: [0.0219499781965924]},
    {input: [0.0890625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.128125, 0.7], output: [0.0445900249975972]},
    {input: [0.1, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.025], output: [0.0445900249975972]},
    {input: [0.2640625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.5109375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.7359375, 0.405555555555556], output: [0.067230071798602]},
    {input: [0.71875, 0.402777777777778], output: [0.135150212201616]},
    {input: [0.5625, 0.430555555555556], output: [0.203070352604631]},
    {input: [0.5109375, 0.35], output: [0.180430305803626]},
    {input: [0.6015625, 0.386111111111111], output: [0.180430305803626]},
    {input: [0.6796875, 0.483333333333333], output: [0.157790259002621]},
    {input: [0.471875, 0.563888888888889], output: [0.157790259002621]},
    {input: [0.275, 0.569444444444444], output: [0.135150212201616]},
    {input: [0.253125, 0.436111111111111], output: [0.112510165400612]},
    {input: [0.240625, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.3765625, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.3203125, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.275, 0.425], output: [0.067230071798602]},
    {input: [0.2125, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.375], output: [0.0445900249975972]},
    {input: [0.33125, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.415625, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.365625, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.359375, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.359375, 0.25], output: [0.0898701185996068]},
    {input: [0.465625, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.309375, 0.333333333333333], output: [0.0898701185996068]},
    {input: [0.1125, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.1453125, 0.413888888888889], output: [0.067230071798602]},
    {input: [0.1796875, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.409375, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.44375, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.184375, 0.275], output: [0.0898701185996068]},
    {input: [0.1125, 0.186111111111111], output: [0.0898701185996068]},
    {input: [0.0328125, 0.577777777777778], output: [0.067230071798602]},
    {input: [0.04375, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.05625, 0.458333333333333], output: [0.0445900249975972]},
    {input: [0.028125, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.04375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0], output: [0.0219499781965924]},
    {input: [0.128125, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.925], output: [0.0219499781965924]},
    {input: [0.0953125, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.8], output: [-0.000690068604412431]},
    {input: [0.140625, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.1734375, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.421875, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.60625, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.534375, 0.475], output: [0.0898701185996068]},
    {input: [0.49375, 0.452777777777778], output: [0.112510165400612]},
    {input: [0.49375, 0.455555555555556], output: [0.0898701185996068]},
    {input: [0.415625, 0.4], output: [0.0898701185996068]},
    {input: [0.45, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.5390625, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.6234375, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.5109375, 0.302777777777778], output: [0.112510165400612]},
    {input: [0.5625, 0.338888888888889], output: [0.112510165400612]},
    {input: [0.6234375, 0.361111111111111], output: [0.135150212201616]},
    {input: [0.528125, 0.363888888888889], output: [0.157790259002621]},
    {input: [0.578125, 0.375], output: [0.157790259002621]},
    {input: [0.6015625, 0.397222222222222], output: [0.157790259002621]},
    {input: [0.521875, 0.416666666666667], output: [0.135150212201616]},
    {input: [0.5671875, 0.388888888888889], output: [0.135150212201616]},
    {input: [0.50625, 0.394444444444444], output: [0.135150212201616]},
    {input: [0.5453125, 0.411111111111111], output: [0.135150212201616]},
    {input: [0.521875, 0.413888888888889], output: [0.157790259002621]},
    {input: [0.4546875, 0.405555555555556], output: [0.157790259002621]},
    {input: [0.3484375, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.4546875, 0.475], output: [0.112510165400612]},
    {input: [0.2921875, 0.575], output: [0.112510165400612]},
    {input: [0.534375, 0.652777777777778], output: [0.0898701185996068]},
    {input: [0.415625, 0.644444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.680555555555556], output: [0.0898701185996068]},
    {input: [0.3421875, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.39375, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.705555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.711111111111111], output: [0.067230071798602]},
    {input: [0.3484375, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.33125, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.296875, 0.677777777777778], output: [0.067230071798602]},
    {input: [0.3140625, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.425], output: [0.0219499781965924]},
    {input: [0.2578125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.2859375, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.3484375, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.3875, 0.213888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.4046875, 0.225], output: [0.0445900249975972]},
    {input: [0.3984375, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.415625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.415625, 0.247222222222222], output: [0.0898701185996068]},
    {input: [0.3203125, 0.263888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.225], output: [0.0898701185996068]},
    {input: [0.4046875, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.2859375, 0.225], output: [0.0898701185996068]},
    {input: [0.415625, 0.211111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.26875, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.26875, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.190625, 0.3], output: [0.0445900249975972]},
    {input: [0.275, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.3765625, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.26875, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.16875, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.2296875, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.3984375, 0.486111111111111], output: [0.067230071798602]},
    {input: [0.415625, 0.538888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.530555555555556], output: [0.0898701185996068]},
    {input: [0.3140625, 0.541666666666667], output: [0.067230071798602]},
    {input: [0.303125, 0.555555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.2921875, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.38125, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.465625, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.4609375, 0.427777777777778], output: [0.0898701185996068]},
    {input: [0.45, 0.483333333333333], output: [0.0898701185996068]},
    {input: [0.39375, 0.480555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.494444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.2125, 0.761111111111111], output: [-0.000690068604412431]},
    {input: [0.325, 0.9], output: [0.0219499781965924]},
    {input: [0.253125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.1234375, 0.752777777777778], output: [0.0445900249975972]},
    {input: [0.0953125, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.078125, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.75], output: [0.0219499781965924]},
    {input: [0.134375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.111111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.0109375, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.0328125, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.294444444444444], output: [-0.000690068604412431]},
    {input: [0.0046875, 0.380555555555556], output: [-0.000690068604412431]},
    {input: [0.084375, 0.141666666666667], output: [-0.000690068604412431]},
    {input: [0.05625, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.10625, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.316666666666667], output: [-0.000690068604412431]},
    {input: [0.1125, 0.391666666666667], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.327777777777778], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.2578125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.3484375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.3], output: [0.0219499781965924]},
    {input: [0.3421875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.25], output: [0.0445900249975972]},
    {input: [0.39375, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.3984375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.39375, 0.322222222222222], output: [0.067230071798602]},
    {input: [0.465625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.355555555555556], output: [0.0898701185996068]},
    {input: [0.4828125, 0.380555555555556], output: [0.112510165400612]},
    {input: [0.5453125, 0.427777777777778], output: [0.0898701185996068]},
    {input: [0.5171875, 0.413888888888889], output: [0.0898701185996068]},
    {input: [0.4890625, 0.45], output: [0.0898701185996068]},
    {input: [0.296875, 0.505555555555556], output: [0.0898701185996068]},
    {input: [0.296875, 0.558333333333333], output: [0.067230071798602]},
    {input: [0.2359375, 0.702777777777778], output: [0.067230071798602]},
    {input: [0.0390625, 0.65], output: [0.0445900249975972]},
    {input: [0.0890625, 0.55], output: [0.0445900249975972]},
    {input: [0.2859375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.638888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.688888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.716666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.33125, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.3765625, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.528125, 0.925], output: [0.067230071798602]},
    {input: [0.6296875, 0.844444444444444], output: [0.112510165400612]},
    {input: [0.803125, 0.888888888888889], output: [0.157790259002621]},
    {input: [0.8375, 0.872222222222222], output: [0.24835044620664]},
    {input: [0.7984375, 0.891666666666667], output: [0.270990493007645]},
    {input: [0.7078125, 0.858333333333333], output: [0.270990493007645]},
    {input: [0.640625, 0.861111111111111], output: [0.24835044620664]},
    {input: [0.5453125, 0.847222222222222], output: [0.203070352604631]},
    {input: [0.590625, 0.816666666666667], output: [0.203070352604631]},
    {input: [0.55, 0.819444444444444], output: [0.180430305803626]},
    {input: [0.578125, 0.830555555555556], output: [0.180430305803626]},
    {input: [0.646875, 0.863888888888889], output: [0.180430305803626]},
    {input: [0.6125, 0.852777777777778], output: [0.203070352604631]},
    {input: [0.61875, 0.891666666666667], output: [0.180430305803626]},
    {input: [0.5734375, 0.911111111111111], output: [0.180430305803626]},
    {input: [0.521875, 0.894444444444444], output: [0.157790259002621]},
    {input: [0.3984375, 0.875], output: [0.157790259002621]},
    {input: [0.478125, 0.872222222222222], output: [0.112510165400612]},
    {input: [0.55, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.534375, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.55, 0.911111111111111], output: [0.135150212201616]},
    {input: [0.528125, 0.9], output: [0.135150212201616]},
    {input: [0.478125, 0.911111111111111], output: [0.135150212201616]},
    {input: [0.4828125, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.4328125, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.325, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.275, 0.855555555555556], output: [0.0898701185996068]},
    {input: [0.134375, 0.730555555555556], output: [0.067230071798602]},
    {input: [0.184375, 0.708333333333333], output: [0.067230071798602]},
    {input: [0.128125, 0.819444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.0953125, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.713888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.475], output: [0.0445900249975972]},
    {input: [0.0890625, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.0671875, 0.525], output: [0.0445900249975972]},
    {input: [0.1515625, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.296875, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.375], output: [0.0445900249975972]},
    {input: [0.275, 0.425], output: [0.0445900249975972]},
    {input: [0.2078125, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.365625, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.2359375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.3], output: [0.0445900249975972]},
    {input: [0.1, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.0694444444444444], output: [0.0445900249975972]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.44375, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.3875, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.161111111111111], output: [0.067230071798602]},
    {input: [0.2921875, 0.197222222222222], output: [0.0898701185996068]},
    {input: [0.2578125, 0.138888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.15], output: [0.067230071798602]},
    {input: [0.21875, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.140625, 0.172222222222222], output: [0.067230071798602]},
    {input: [0.10625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.0671875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.25], output: [0.0219499781965924]},
    {input: [0.1125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.3421875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.452777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.5], output: [0.0445900249975972]},
    {input: [0.3203125, 0.519444444444444], output: [0.067230071798602]},
    {input: [0.2078125, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.833333333333333], output: [0.0445900249975972]},
    {input: [0.05, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.3484375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.465625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.415625, 0.875], output: [0.067230071798602]},
    {input: [0.3984375, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.415625, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.2921875, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.2640625, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.2640625, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.15625, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.134375, 0.708333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.925], output: [0.0445900249975972]},
    {input: [0.05625, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.513888888888889], output: [0.0219499781965924]},
    {input: [0.028125, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.0328125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.675], output: [0.0219499781965924]},
    {input: [0.128125, 0.625], output: [0.0219499781965924]},
    {input: [0.1, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.533333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.413888888888889], output: [-0.000690068604412431]},
    {input: [0.10625, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.1, 0.322222222222222], output: [-0.000690068604412431]},
    {input: [0.196875, 0.241666666666667], output: [-0.000690068604412431]},
    {input: [0.2359375, 0.219444444444444], output: [-0.000690068604412431]},
    {input: [0.2921875, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.184375, 0.35], output: [0.0219499781965924]},
    {input: [0.4265625, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.478125, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.3984375, 0.441666666666667], output: [0.067230071798602]},
    {input: [0.45, 0.505555555555556], output: [0.067230071798602]},
    {input: [0.44375, 0.633333333333333], output: [0.0898701185996068]},
    {input: [0.50625, 0.594444444444444], output: [0.0898701185996068]},
    {input: [0.5953125, 0.622222222222222], output: [0.067230071798602]},
    {input: [0.634375, 0.741666666666667], output: [0.0898701185996068]},
    {input: [0.3703125, 0.772222222222222], output: [0.0898701185996068]},
    {input: [0.3984375, 0.813888888888889], output: [0.0898701185996068]},
    {input: [0.2359375, 0.775], output: [0.0898701185996068]},
    {input: [0.246875, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.0671875, 0.775], output: [0.0898701185996068]},
    {input: [0.3484375, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.365625, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.3484375, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.2578125, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.3375, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.4046875, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.415625, 0.880555555555556], output: [0.0898701185996068]},
    {input: [0.1625, 0.822222222222222], output: [0.0898701185996068]},
    {input: [0.2921875, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.3984375, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.128125, 0.602777777777778], output: [0.0898701185996068]},
    {input: [0.296875, 0.394444444444444], output: [0.0898701185996068]},
    {input: [0.10625, 0.738888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.988888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.125], output: [0.0445900249975972]},
    {input: [0.2015625, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.925], output: [0.067230071798602]},
    {input: [0.26875, 0.869444444444444], output: [0.067230071798602]},
    {input: [0.196875, 0.811111111111111], output: [0.0898701185996068]},
    {input: [0.3203125, 0.286111111111111], output: [0.0898701185996068]},
    {input: [0.421875, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.4546875, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.409375, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.2859375, 0.202777777777778], output: [0.135150212201616]},
    {input: [0.246875, 0.211111111111111], output: [0.135150212201616]},
    {input: [0.309375, 0.186111111111111], output: [0.135150212201616]},
    {input: [0.2296875, 0.152777777777778], output: [0.135150212201616]},
    {input: [0.2078125, 0.136111111111111], output: [0.112510165400612]},
    {input: [0.2296875, 0.211111111111111], output: [0.112510165400612]},
    {input: [0.1234375, 0.158333333333333], output: [0.112510165400612]},
    {input: [0.10625, 0.186111111111111], output: [0.112510165400612]},
    {input: [0.190625, 0.294444444444444], output: [0.0898701185996068]},
    {input: [0.2015625, 0.0611111111111111], output: [0.0898701185996068]},
    {input: [0.184375, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.15625, 0.0777777777777778], output: [0.112510165400612]},
    {input: [0.225, 0.105555555555556], output: [0.0898701185996068]},
    {input: [0.225, 0.144444444444444], output: [0.0898701185996068]},
    {input: [0.2578125, 0.169444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.141666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.119444444444444], output: [0.067230071798602]},
    {input: [0.4265625, 0.166666666666667], output: [0.0898701185996068]},
    {input: [0.421875, 0.158333333333333], output: [0.0898701185996068]},
    {input: [0.61875, 0.186111111111111], output: [0.135150212201616]},
    {input: [0.640625, 0.216666666666667], output: [0.135150212201616]},
    {input: [0.6625, 0.202777777777778], output: [0.180430305803626]},
    {input: [0.6578125, 0.2], output: [0.203070352604631]},
    {input: [0.6625, 0.205555555555556], output: [0.203070352604631]},
    {input: [0.71875, 0.197222222222222], output: [0.203070352604631]},
    {input: [0.696875, 0.188888888888889], output: [0.203070352604631]},
    {input: [0.6625, 0.188888888888889], output: [0.225710399405636]},
    {input: [0.6234375, 0.197222222222222], output: [0.203070352604631]},
    {input: [0.6234375, 0.202777777777778], output: [0.180430305803626]},
    {input: [0.5390625, 0.205555555555556], output: [0.180430305803626]},
    {input: [0.5453125, 0.230555555555556], output: [0.180430305803626]},
    {input: [0.478125, 0.258333333333333], output: [0.157790259002621]},
    {input: [0.471875, 0.247222222222222], output: [0.157790259002621]},
    {input: [0.50625, 0.25], output: [0.157790259002621]},
    {input: [0.528125, 0.25], output: [0.157790259002621]},
    {input: [0.5953125, 0.238888888888889], output: [0.157790259002621]},
    {input: [0.6296875, 0.227777777777778], output: [0.157790259002621]},
    {input: [0.634375, 0.213888888888889], output: [0.157790259002621]},
    {input: [0.5953125, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.55, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.521875, 0.216666666666667], output: [0.157790259002621]},
    {input: [0.4828125, 0.230555555555556], output: [0.135150212201616]},
    {input: [0.4890625, 0.222222222222222], output: [0.157790259002621]},
    {input: [0.5109375, 0.222222222222222], output: [0.135150212201616]},
    {input: [0.465625, 0.225], output: [0.135150212201616]},
    {input: [0.4265625, 0.208333333333333], output: [0.135150212201616]},
    {input: [0.3765625, 0.225], output: [0.135150212201616]},
    {input: [0.3703125, 0.241666666666667], output: [0.112510165400612]},
    {input: [0.3140625, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.240625, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.10625, 0.0944444444444444], output: [0.0898701185996068]},
    {input: [0.0390625, 0.158333333333333], output: [0.067230071798602]},
    {input: [0.05, 0.166666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.875], output: [0.0219499781965924]},
    {input: [0.1125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.084375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.802777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.275], output: [0.0219499781965924]},
    {input: [0.2359375, 0.275], output: [0.0219499781965924]},
    {input: [0.2859375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.2359375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.253125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.184375, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.4375, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.49375, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.5171875, 0.25], output: [0.0898701185996068]},
    {input: [0.5390625, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.528125, 0.261111111111111], output: [0.112510165400612]},
    {input: [0.5109375, 0.327777777777778], output: [0.112510165400612]},
    {input: [0.590625, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.39375, 0.397222222222222], output: [0.135150212201616]},
    {input: [0.49375, 0.411111111111111], output: [0.135150212201616]},
    {input: [0.3875, 0.455555555555556], output: [0.135150212201616]},
    {input: [0.246875, 0.658333333333333], output: [0.157790259002621]},
    {input: [0.45, 0.758333333333333], output: [0.135150212201616]},
    {input: [0.6296875, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.5171875, 0.830555555555556], output: [0.112510165400612]},
    {input: [0.2859375, 0.766666666666667], output: [0.135150212201616]},
    {input: [0.196875, 0.758333333333333], output: [0.0898701185996068]},
    {input: [0.05625, 0.833333333333333], output: [0.0898701185996068]},
    {input: [0.05, 0.025], output: [0.067230071798602]},
    {input: [0.0390625, 0.0333333333333333], output: [0.067230071798602]},
    {input: [0.1515625, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.190625, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.113888888888889], output: [0.0445900249975972]},
    {input: [0.3140625, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.3765625, 0.644444444444444], output: [0.0219499781965924]},
    {input: [0.4609375, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.3765625, 0.594444444444444], output: [0.067230071798602]},
    {input: [0.3484375, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.4046875, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.33125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.3375, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.415625, 0.291666666666667], output: [0.0898701185996068]},
    {input: [0.4546875, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.415625, 0.155555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.177777777777778], output: [0.0898701185996068]},
    {input: [0.253125, 0.169444444444444], output: [0.0898701185996068]},
    {input: [0.140625, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.3421875, 0.297222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.211111111111111], output: [0.0898701185996068]},
    {input: [0.478125, 0.191666666666667], output: [0.0898701185996068]},
    {input: [0.33125, 0.183333333333333], output: [0.0898701185996068]},
    {input: [0.325, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.0861111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.0111111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.0222222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.00833333333333333], output: [0.0445900249975972]},
    {input: [0.2296875, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.3203125, 0.955555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.980555555555556], output: [0.067230071798602]},
    {input: [0.3140625, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.888888888888889], output: [0.0445900249975972]},
    {input: [0.33125, 0.941666666666667], output: [0.067230071798602]},
    {input: [0.4265625, 0.85], output: [0.0898701185996068]},
    {input: [0.38125, 0.847222222222222], output: [0.0898701185996068]},
    {input: [0.4328125, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.3875, 0.852777777777778], output: [0.0898701185996068]},
    {input: [0.39375, 0.841666666666667], output: [0.0898701185996068]},
    {input: [0.3421875, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.877777777777778], output: [0.067230071798602]},
    {input: [0.2640625, 0.875], output: [0.0898701185996068]},
    {input: [0.1171875, 0.0472222222222222], output: [0.0445900249975972]},
    {input: [0.084375, 0.647222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.225], output: [0.0219499781965924]},
    {input: [0.1796875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.45], output: [0.0219499781965924]},
    {input: [0.240625, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.488888888888889], output: [0.0445900249975972]},
    {input: [0.33125, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.3484375, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.45, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.45, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.4546875, 0.394444444444444], output: [0.0898701185996068]},
    {input: [0.49375, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.4375, 0.366666666666667], output: [0.0898701185996068]},
    {input: [0.55625, 0.375], output: [0.067230071798602]},
    {input: [0.521875, 0.405555555555556], output: [0.0898701185996068]},
    {input: [0.5109375, 0.408333333333333], output: [0.135150212201616]},
    {input: [0.584375, 0.452777777777778], output: [0.135150212201616]},
    {input: [0.66875, 0.530555555555556], output: [0.135150212201616]},
    {input: [0.61875, 0.525], output: [0.135150212201616]},
    {input: [0.5953125, 0.638888888888889], output: [0.135150212201616]},
    {input: [0.4328125, 0.675], output: [0.135150212201616]},
    {input: [0.4609375, 0.841666666666667], output: [0.112510165400612]},
    {input: [0.5171875, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.28125, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.134375, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.0390625, 0.0472222222222222], output: [0.067230071798602]},
    {input: [0.0953125, 0.75], output: [0.067230071798602]},
    {input: [0.3765625, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.780555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.769444444444444], output: [0.0445900249975972]},
    {input: [0.415625, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.28125, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.6], output: [0.0219499781965924]},
    {input: [0.3484375, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.3484375, 0.6], output: [0.0445900249975972]},
    {input: [0.3765625, 0.602777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.625], output: [0.0445900249975972]},
    {input: [0.309375, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.3421875, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.2640625, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.5], output: [0.0445900249975972]},
    {input: [0.409375, 0.475], output: [0.0445900249975972]},
    {input: [0.26875, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.28125, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.325, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.3984375, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.4828125, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.4609375, 0.363888888888889], output: [0.0898701185996068]},
    {input: [0.521875, 0.333333333333333], output: [0.135150212201616]},
    {input: [0.534375, 0.302777777777778], output: [0.157790259002621]},
    {input: [0.584375, 0.322222222222222], output: [0.157790259002621]},
    {input: [0.534375, 0.258333333333333], output: [0.180430305803626]},
    {input: [0.6125, 0.255555555555556], output: [0.203070352604631]},
    {input: [0.5953125, 0.241666666666667], output: [0.180430305803626]},
    {input: [0.6515625, 0.219444444444444], output: [0.157790259002621]},
    {input: [0.640625, 0.222222222222222], output: [0.157790259002621]},
    {input: [0.578125, 0.191666666666667], output: [0.180430305803626]},
    {input: [0.521875, 0.183333333333333], output: [0.135150212201616]},
    {input: [0.521875, 0.208333333333333], output: [0.112510165400612]},
    {input: [0.55, 0.2], output: [0.135150212201616]},
    {input: [0.5, 0.255555555555556], output: [0.112510165400612]},
    {input: [0.3765625, 0.269444444444444], output: [0.135150212201616]},
    {input: [0.61875, 0.258333333333333], output: [0.112510165400612]},
    {input: [0.6125, 0.238888888888889], output: [0.135150212201616]},
    {input: [0.6796875, 0.205555555555556], output: [0.157790259002621]},
    {input: [0.5671875, 0.191666666666667], output: [0.157790259002621]},
    {input: [0.534375, 0.211111111111111], output: [0.157790259002621]},
    {input: [0.5453125, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.4265625, 0.191666666666667], output: [0.112510165400612]},
    {input: [0.4046875, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.478125, 0.225], output: [0.0898701185996068]},
    {input: [0.5453125, 0.291666666666667], output: [0.112510165400612]},
    {input: [0.5453125, 0.380555555555556], output: [0.135150212201616]},
    {input: [0.478125, 0.352777777777778], output: [0.157790259002621]},
    {input: [0.3984375, 0.272222222222222], output: [0.112510165400612]},
    {input: [0.296875, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.3484375, 0.130555555555556], output: [0.0898701185996068]},
    {input: [0.4046875, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.3375, 0.175], output: [0.067230071798602]},
    {input: [0.303125, 0.197222222222222], output: [0.067230071798602]},
    {input: [0.325, 0.219444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.202777777777778], output: [0.067230071798602]},
    {input: [0.2015625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.253125, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.225, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.0444444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.997222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.225, 0.925], output: [0.0445900249975972]},
    {input: [0.296875, 0.925], output: [0.0219499781965924]},
    {input: [0.325, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.365625, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.3203125, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.025], output: [0.0445900249975972]},
    {input: [0.275, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.0328125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.353125, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.296875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.38125, 0.225], output: [0.0445900249975972]},
    {input: [0.4046875, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.309375, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.359375, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.241666666666667], output: [0.0898701185996068]},
    {input: [0.2359375, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.303125, 0.269444444444444], output: [0.0898701185996068]},
    {input: [0.2078125, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.303125, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.3203125, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.421875, 0.3], output: [0.067230071798602]},
    {input: [0.465625, 0.302777777777778], output: [0.067230071798602]},
    {input: [0.471875, 0.302777777777778], output: [0.112510165400612]},
    {input: [0.4546875, 0.341666666666667], output: [0.112510165400612]},
    {input: [0.409375, 0.352777777777778], output: [0.112510165400612]},
    {input: [0.421875, 0.358333333333333], output: [0.112510165400612]},
    {input: [0.3484375, 0.355555555555556], output: [0.112510165400612]},
    {input: [0.3484375, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.33125, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.353125, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.225, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.26875, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.225, 0.222222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.05625, 0.383333333333333], output: [0.067230071798602]},
    {input: [0.0671875, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.078125, 0.963888888888889], output: [0.0445900249975972]},
    {input: [0.1171875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.825], output: [0.0219499781965924]},
    {input: [0.0609375, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.26875, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.309375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.3484375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2921875, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.1234375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.219444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0390625, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.0390625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.303125, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.5, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.163888888888889], output: [0.067230071798602]},
    {input: [0.2015625, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.2078125, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.275], output: [0.0445900249975972]},
    {input: [0.303125, 0.208333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.188888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.3984375, 0.186111111111111], output: [0.067230071798602]},
    {input: [0.2359375, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.2859375, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.2359375, 0.35], output: [0.067230071798602]},
    {input: [0.1453125, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.0671875, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.128125, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.980555555555556], output: [0.0445900249975972]},
    {input: [0.2078125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.961111111111111], output: [0.067230071798602]},
    {input: [0.3375, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.421875, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.3703125, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.4546875, 0.925], output: [0.0898701185996068]},
    {input: [0.39375, 0.919444444444444], output: [0.112510165400612]},
    {input: [0.2359375, 0.936111111111111], output: [0.112510165400612]},
    {input: [0.303125, 0.158333333333333], output: [0.112510165400612]},
    {input: [0.26875, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.253125, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.184375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.55], output: [0.0445900249975972]},
    {input: [0.1453125, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.240625, 0.425], output: [0.0219499781965924]},
    {input: [0.3140625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.3703125, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.359375, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.394444444444444], output: [0.0445900249975972]},
    {input: [0.3421875, 0.477777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.353125, 0.475], output: [0.067230071798602]},
    {input: [0.325, 0.497222222222222], output: [0.067230071798602]},
    {input: [0.3140625, 0.463888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.5], output: [0.0445900249975972]},
    {input: [0.33125, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.26875, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.3203125, 0.422222222222222], output: [0.067230071798602]},
    {input: [0.26875, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.246875, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.35], output: [0.0445900249975972]},
    {input: [0.3203125, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.3375, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.3140625, 0.211111111111111], output: [0.0445900249975972]},
    {input: [0.33125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.3484375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.309375, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.25], output: [0.0445900249975972]},
    {input: [0.253125, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.2359375, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.1734375, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.2359375, 0.3], output: [0.067230071798602]},
    {input: [0.2578125, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.246875, 0.336111111111111], output: [0.0898701185996068]},
    {input: [0.10625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.421875, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.3875, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.4546875, 0.216666666666667], output: [0.0898701185996068]},
    {input: [0.44375, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.528125, 0.230555555555556], output: [0.0898701185996068]},
    {input: [0.471875, 0.233333333333333], output: [0.0898701185996068]},
    {input: [0.4609375, 0.236111111111111], output: [0.112510165400612]},
    {input: [0.409375, 0.261111111111111], output: [0.0898701185996068]},
    {input: [0.3375, 0.252777777777778], output: [0.0898701185996068]},
    {input: [0.26875, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.275, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.577777777777778], output: [0.0445900249975972]},
    {input: [0.421875, 0.616666666666667], output: [0.0445900249975972]},
    {input: [0.4265625, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.4046875, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.39375, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.725], output: [0.067230071798602]},
    {input: [0.084375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.65], output: [0.0219499781965924]},
    {input: [0.2296875, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.365625, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.303125, 0.605555555555556], output: [0.0445900249975972]},
    {input: [0.4609375, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.544444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.3984375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.3984375, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.486111111111111], output: [0.067230071798602]},
    {input: [0.39375, 0.477777777777778], output: [0.067230071798602]},
    {input: [0.4046875, 0.391666666666667], output: [0.067230071798602]},
    {input: [0.3984375, 0.469444444444444], output: [0.0898701185996068]},
    {input: [0.3984375, 0.427777777777778], output: [0.0898701185996068]},
    {input: [0.4046875, 0.425], output: [0.0898701185996068]},
    {input: [0.3703125, 0.436111111111111], output: [0.067230071798602]},
    {input: [0.296875, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.2859375, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.2015625, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.21875, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.2015625, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.0890625, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.45], output: [0.0219499781965924]},
    {input: [0.2125, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.2921875, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.8], output: [0.0219499781965924]},
    {input: [0.2296875, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.8], output: [0.0219499781965924]},
    {input: [0.246875, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.1515625, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.078125, 0.975], output: [0.0445900249975972]},
    {input: [0.084375, 0.969444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.122222222222222], output: [-0.000690068604412431]},
    {input: [0.15625, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.125], output: [0.0219499781965924]},
    {input: [0.10625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.05], output: [0.0219499781965924]},
    {input: [0.2359375, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.0444444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.952777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.947222222222222], output: [0.0445900249975972]},
    {input: [0.225, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.925], output: [0.0445900249975972]},
    {input: [0.21875, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.309375, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.9], output: [0.0445900249975972]},
    {input: [0.33125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.2921875, 0.875], output: [0.067230071798602]},
    {input: [0.2578125, 0.875], output: [0.067230071798602]},
    {input: [0.2296875, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.05, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.830555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.9], output: [0.0219499781965924]},
    {input: [0.1234375, 0.961111111111111], output: [-0.000690068604412431]},
    {input: [0.134375, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.875], output: [0.0219499781965924]},
    {input: [0.1796875, 0.908333333333333], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.8], output: [0.0219499781965924]},
    {input: [0.0609375, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.725], output: [0.0219499781965924]},
    {input: [0.1171875, 0.15], output: [0.0219499781965924]},
    {input: [0.05625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.166666666666667], output: [0.0219499781965924]},
    {input: [0.0109375, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.0138888888888889], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.0527777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.766666666666667], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.0138888888888889], output: [-0.000690068604412431]},
    {input: [0.134375, 0.00555555555555556], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.184375, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.775], output: [0.0219499781965924]},
    {input: [0.2296875, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.05625, 0.977777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.875], output: [0.0219499781965924]},
    {input: [0.0671875, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.21875, 0.925], output: [-0.000690068604412431]},
    {input: [0.3140625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.225, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.1125, 0.975], output: [0.0445900249975972]},
    {input: [0.1453125, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.8], output: [0.0219499781965924]},
    {input: [0.1, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.975], output: [-0.000690068604412431]},
    {input: [0.05625, 0.966666666666667], output: [-0.000690068604412431]},
    {input: [0.04375, 0.605555555555556], output: [-0.000690068604412431]},
    {input: [0.028125, 0.361111111111111], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.563888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.0046875, 0.652777777777778], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.116666666666667], output: [-0.000690068604412431]},
    {input: [0.05625, 0.102777777777778], output: [-0.000690068604412431]},
    {input: [0.071875, 0.175], output: [-0.000690068604412431]},
    {input: [0.071875, 0.727777777777778], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.855555555555556], output: [-0.000690068604412431]},
    {input: [0.071875, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.894444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.140625, 0.811111111111111], output: [-0.000690068604412431]},
    {input: [0.16875, 0.816666666666667], output: [-0.000690068604412431]},
    {input: [0.1625, 0.811111111111111], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.841666666666667], output: [-0.000690068604412431]},
    {input: [0.15625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.877777777777778], output: [-0.000690068604412431]},
    {input: [0.246875, 0.872222222222222], output: [-0.000690068604412431]},
    {input: [0.225, 0.863888888888889], output: [-0.000690068604412431]},
    {input: [0.184375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.3703125, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.875], output: [0.067230071798602]},
    {input: [0.2359375, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.225, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.134375, 0.980555555555556], output: [0.067230071798602]},
    {input: [0.2078125, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.2921875, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.365625, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.303125, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.325, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.2578125, 0.95], output: [0.067230071798602]},
    {input: [0.253125, 0.961111111111111], output: [0.067230071798602]},
    {input: [0.15625, 0.991666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.972222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.1, 0.963888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.028125, 0.725], output: [0.0219499781965924]},
    {input: [0.04375, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.9], output: [0.0219499781965924]},
    {input: [0.084375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.397222222222222], output: [-0.000690068604412431]},
    {input: [0.225, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.309375, 0.563888888888889], output: [-0.000690068604412431]},
    {input: [0.38125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.4265625, 0.658333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.686111111111111], output: [0.0898701185996068]},
    {input: [0.44375, 0.694444444444444], output: [0.067230071798602]},
    {input: [0.33125, 0.494444444444444], output: [0.0898701185996068]},
    {input: [0.359375, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.303125, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.577777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.591666666666667], output: [0.0445900249975972]},
    {input: [0.1453125, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.3875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.3765625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.38125, 0.275], output: [0.067230071798602]},
    {input: [0.365625, 0.3], output: [0.067230071798602]},
    {input: [0.3703125, 0.325], output: [0.0898701185996068]},
    {input: [0.415625, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.572222222222222], output: [0.0898701185996068]},
    {input: [0.5109375, 0.555555555555556], output: [0.067230071798602]},
    {input: [0.415625, 0.583333333333333], output: [0.067230071798602]},
    {input: [0.4828125, 0.625], output: [0.0445900249975972]},
    {input: [0.3875, 0.583333333333333], output: [0.067230071798602]},
    {input: [0.39375, 0.55], output: [0.0445900249975972]},
    {input: [0.45, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.3703125, 0.569444444444444], output: [0.0445900249975972]},
    {input: [0.38125, 0.544444444444444], output: [0.067230071798602]},
    {input: [0.359375, 0.519444444444444], output: [0.067230071798602]},
    {input: [0.365625, 0.558333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.513888888888889], output: [0.067230071798602]},
    {input: [0.3875, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.505555555555556], output: [0.067230071798602]},
    {input: [0.2359375, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.447222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.419444444444444], output: [0.0445900249975972]},
    {input: [0.1, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.0890625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.0609375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.359375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.4265625, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.365625, 0.663888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.672222222222222], output: [0.067230071798602]},
    {input: [0.2296875, 0.652777777777778], output: [0.0445900249975972]},
    {input: [0.2359375, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.777777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.744444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.766666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.597222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.45], output: [0.0445900249975972]},
    {input: [0.04375, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.021875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0916666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.0805555555555556], output: [-0.000690068604412431]},
    {input: [0.196875, 0.161111111111111], output: [-0.000690068604412431]},
    {input: [0.1734375, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.196875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.15625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.25], output: [0.0219499781965924]},
    {input: [0.2078125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.325], output: [0.0219499781965924]},
    {input: [0.196875, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.205555555555556], output: [0.0445900249975972]},
    {input: [0.084375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.155555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.325, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.3484375, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.39375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.4265625, 0.227777777777778], output: [0.067230071798602]},
    {input: [0.3375, 0.35], output: [0.0898701185996068]},
    {input: [0.28125, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.2921875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.3484375, 0.213888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.296875, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.2125, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.1, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.0328125, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.10625, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.3765625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.3984375, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.4046875, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.4375, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.3984375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.325, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.246875, 0.925], output: [0.0898701185996068]},
    {input: [0.225, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.275, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.2640625, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.3140625, 0.947222222222222], output: [0.0445900249975972]},
    {input: [0.365625, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.296875, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.3375, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.240625, 0.883333333333333], output: [0.0898701185996068]},
    {input: [0.33125, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.925], output: [0.067230071798602]},
    {input: [0.253125, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.2125, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.102777777777778], output: [0.067230071798602]},
    {input: [0.3203125, 0.941666666666667], output: [0.112510165400612]},
    {input: [0.3140625, 0.925], output: [0.0898701185996068]},
    {input: [0.353125, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.471875, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.4375, 0.9], output: [0.0898701185996068]},
    {input: [0.465625, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.409375, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.353125, 0.925], output: [0.067230071798602]},
    {input: [0.2578125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.1734375, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.296875, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.9], output: [0.0445900249975972]},
    {input: [0.253125, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.4546875, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.44375, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.521875, 0.9], output: [0.0898701185996068]},
    {input: [0.55625, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.528125, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.4890625, 0.922222222222222], output: [0.112510165400612]},
    {input: [0.4328125, 0.922222222222222], output: [0.112510165400612]},
    {input: [0.4375, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.3484375, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.28125, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.240625, 0.925], output: [0.067230071798602]},
    {input: [0.1234375, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.1, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.575], output: [0.0219499781965924]},
    {input: [0.1734375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.128125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.152777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0666666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.836111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.727777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.275], output: [0.0219499781965924]},
    {input: [0.365625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.3], output: [0.0445900249975972]},
    {input: [0.3484375, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.365625, 0.613888888888889], output: [0.067230071798602]},
    {input: [0.359375, 0.552777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.533333333333333], output: [0.067230071798602]},
    {input: [0.3765625, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.4609375, 0.636111111111111], output: [0.067230071798602]},
    {input: [0.409375, 0.6], output: [0.067230071798602]},
    {input: [0.353125, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.303125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.3375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.415625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.415625, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.3875, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.3703125, 0.402777777777778], output: [0.067230071798602]},
    {input: [0.3703125, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.3875, 0.4], output: [0.067230071798602]},
    {input: [0.4609375, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.4609375, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.4375, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.4828125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.4890625, 0.361111111111111], output: [0.0898701185996068]},
    {input: [0.415625, 0.347222222222222], output: [0.112510165400612]},
    {input: [0.61875, 0.405555555555556], output: [0.112510165400612]},
    {input: [0.5, 0.408333333333333], output: [0.135150212201616]},
    {input: [0.3703125, 0.519444444444444], output: [0.135150212201616]},
    {input: [0.3203125, 0.530555555555556], output: [0.135150212201616]},
    {input: [0.2921875, 0.505555555555556], output: [0.135150212201616]},
    {input: [0.4546875, 0.655555555555556], output: [0.112510165400612]},
    {input: [0.3203125, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.28125, 0.627777777777778], output: [0.067230071798602]},
    {input: [0.246875, 0.636111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.680555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.669444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.638888888888889], output: [0.0445900249975972]},
    {input: [0.353125, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.3203125, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.26875, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.65], output: [0.0445900249975972]},
    {input: [0.184375, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.38125, 0.65], output: [0.0219499781965924]},
    {input: [0.4265625, 0.641666666666667], output: [0.0445900249975972]},
    {input: [0.465625, 0.683333333333333], output: [0.067230071798602]},
    {input: [0.4546875, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.4375, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.4265625, 0.663888888888889], output: [0.067230071798602]},
    {input: [0.3375, 0.575], output: [0.0445900249975972]},
    {input: [0.39375, 0.608333333333333], output: [0.0445900249975972]},
    {input: [0.359375, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.33125, 0.494444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.3984375, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.45], output: [0.0445900249975972]},
    {input: [0.3375, 0.472222222222222], output: [0.067230071798602]},
    {input: [0.3140625, 0.444444444444444], output: [0.067230071798602]},
    {input: [0.365625, 0.444444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.433333333333333], output: [0.0445900249975972]},
    {input: [0.28125, 0.416666666666667], output: [0.067230071798602]},
    {input: [0.225, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.26875, 0.180555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.188888888888889], output: [0.0445900249975972]},
    {input: [0.0890625, 0.797222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.841666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.309375, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.8], output: [0.067230071798602]},
    {input: [0.303125, 0.8], output: [0.067230071798602]},
    {input: [0.39375, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.39375, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.275, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.1796875, 0.116666666666667], output: [0.067230071798602]},
    {input: [0.3203125, 0.802777777777778], output: [0.0445900249975972]},
    {input: [0.134375, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.10625, 0.0888888888888889], output: [0.0445900249975972]},
    {input: [0.0328125, 0.505555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.3765625, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.246875, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.0390625, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.775], output: [0.0219499781965924]},
    {input: [0.3375, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.655555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.75], output: [0.067230071798602]},
    {input: [0.078125, 0.772222222222222], output: [0.0445900249975972]},
    {input: [0.0890625, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.3484375, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.353125, 0.894444444444444], output: [0.067230071798602]},
    {input: [0.409375, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.3875, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.0671875, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.133333333333333], output: [0.0445900249975972]},
    {input: [0.0328125, 0.691666666666667], output: [0.0445900249975972]},
    {input: [0.071875, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.05625, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.202777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.372222222222222], output: [-0.000690068604412431]},
    {input: [0.2296875, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.391666666666667], output: [-0.000690068604412431]},
    {input: [0.21875, 0.425], output: [0.0219499781965924]},
    {input: [0.2859375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.240625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.416666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.4], output: [0.0445900249975972]},
    {input: [0.2078125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.2296875, 0.325], output: [0.0445900249975972]},
    {input: [0.325, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.325], output: [0.067230071798602]},
    {input: [0.303125, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.297222222222222], output: [0.067230071798602]},
    {input: [0.2078125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.230555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.3484375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.3984375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.471875, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.471875, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.45, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.465625, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.4890625, 0.213888888888889], output: [0.0898701185996068]},
    {input: [0.534375, 0.219444444444444], output: [0.0898701185996068]},
    {input: [0.5109375, 0.208333333333333], output: [0.112510165400612]},
    {input: [0.528125, 0.208333333333333], output: [0.112510165400612]},
    {input: [0.528125, 0.227777777777778], output: [0.135150212201616]},
    {input: [0.5171875, 0.213888888888889], output: [0.135150212201616]},
    {input: [0.5, 0.222222222222222], output: [0.112510165400612]},
    {input: [0.5390625, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.4546875, 0.225], output: [0.112510165400612]},
    {input: [0.4609375, 0.233333333333333], output: [0.112510165400612]},
    {input: [0.4375, 0.227777777777778], output: [0.112510165400612]},
    {input: [0.478125, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.4609375, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.478125, 0.230555555555556], output: [0.112510165400612]},
    {input: [0.50625, 0.255555555555556], output: [0.0898701185996068]},
    {input: [0.45, 0.263888888888889], output: [0.112510165400612]},
    {input: [0.528125, 0.252777777777778], output: [0.112510165400612]},
    {input: [0.471875, 0.283333333333333], output: [0.135150212201616]},
    {input: [0.55625, 0.283333333333333], output: [0.157790259002621]},
    {input: [0.5625, 0.397222222222222], output: [0.157790259002621]},
    {input: [0.6015625, 0.477777777777778], output: [0.157790259002621]},
    {input: [0.44375, 0.458333333333333], output: [0.157790259002621]},
    {input: [0.3203125, 0.441666666666667], output: [0.135150212201616]},
    {input: [0.240625, 0.405555555555556], output: [0.112510165400612]},
    {input: [0.296875, 0.425], output: [0.0898701185996068]},
    {input: [0.3484375, 0.438888888888889], output: [0.067230071798602]},
    {input: [0.3421875, 0.522222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.2921875, 0.511111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.466666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.275], output: [0.0219499781965924]},
    {input: [0.2859375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.483333333333333], output: [0.0445900249975972]},
    {input: [0.3140625, 0.588888888888889], output: [0.0445900249975972]},
    {input: [0.4609375, 0.661111111111111], output: [0.0445900249975972]},
    {input: [0.309375, 0.6], output: [0.0445900249975972]},
    {input: [0.353125, 0.65], output: [0.0219499781965924]},
    {input: [0.2859375, 0.577777777777778], output: [0.0445900249975972]},
    {input: [0.184375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.246875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.3765625, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.365625, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.471875, 0.394444444444444], output: [0.067230071798602]},
    {input: [0.4609375, 0.397222222222222], output: [0.0898701185996068]},
    {input: [0.55625, 0.413888888888889], output: [0.112510165400612]},
    {input: [0.471875, 0.422222222222222], output: [0.135150212201616]},
    {input: [0.5, 0.441666666666667], output: [0.135150212201616]},
    {input: [0.4890625, 0.405555555555556], output: [0.112510165400612]},
    {input: [0.471875, 0.386111111111111], output: [0.157790259002621]},
    {input: [0.5734375, 0.422222222222222], output: [0.180430305803626]},
    {input: [0.5, 0.422222222222222], output: [0.180430305803626]},
    {input: [0.3765625, 0.425], output: [0.180430305803626]},
    {input: [0.55625, 0.433333333333333], output: [0.157790259002621]},
    {input: [0.4546875, 0.425], output: [0.157790259002621]},
    {input: [0.3375, 0.325], output: [0.157790259002621]},
    {input: [0.3140625, 0.366666666666667], output: [0.112510165400612]},
    {input: [0.353125, 0.288888888888889], output: [0.112510165400612]},
    {input: [0.309375, 0.286111111111111], output: [0.0898701185996068]},
    {input: [0.3421875, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.3140625, 0.316666666666667], output: [0.0898701185996068]},
    {input: [0.465625, 0.377777777777778], output: [0.067230071798602]},
    {input: [0.359375, 0.311111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.372222222222222], output: [0.0898701185996068]},
    {input: [0.3203125, 0.375], output: [0.0898701185996068]},
    {input: [0.296875, 0.344444444444444], output: [0.0898701185996068]},
    {input: [0.3203125, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.3140625, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.28125, 0.35], output: [0.067230071798602]},
    {input: [0.45, 0.419444444444444], output: [0.067230071798602]},
    {input: [0.534375, 0.372222222222222], output: [0.112510165400612]},
    {input: [0.646875, 0.405555555555556], output: [0.135150212201616]},
    {input: [0.478125, 0.411111111111111], output: [0.157790259002621]},
    {input: [0.3421875, 0.466666666666667], output: [0.203070352604631]},
    {input: [0.3703125, 0.394444444444444], output: [0.157790259002621]},
    {input: [0.5, 0.411111111111111], output: [0.157790259002621]},
    {input: [0.49375, 0.469444444444444], output: [0.180430305803626]},
    {input: [0.303125, 0.627777777777778], output: [0.180430305803626]},
    {input: [0.2078125, 0.633333333333333], output: [0.112510165400612]},
    {input: [0.3140625, 0.672222222222222], output: [0.0898701185996068]},
    {input: [0.2859375, 0.619444444444444], output: [0.067230071798602]},
    {input: [0.3375, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.2859375, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.296875, 0.627777777777778], output: [0.0445900249975972]},
    {input: [0.16875, 0.586111111111111], output: [0.0445900249975972]},
    {input: [0.140625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.325, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.325, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.2921875, 0.4], output: [0.0219499781965924]},
    {input: [0.303125, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.508333333333333], output: [0.0445900249975972]},
    {input: [0.21875, 0.519444444444444], output: [0.0445900249975972]},
    {input: [0.253125, 0.575], output: [0.0219499781965924]},
    {input: [0.421875, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.325, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.1734375, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.33125, 0.961111111111111], output: [0.0219499781965924]},
    {input: [0.415625, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.844444444444444], output: [0.0898701185996068]},
    {input: [0.353125, 0.844444444444444], output: [0.0898701185996068]},
    {input: [0.3875, 0.836111111111111], output: [0.0898701185996068]},
    {input: [0.44375, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.5109375, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.5109375, 0.875], output: [0.112510165400612]},
    {input: [0.5109375, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.465625, 0.908333333333333], output: [0.0898701185996068]},
    {input: [0.528125, 0.922222222222222], output: [0.0898701185996068]},
    {input: [0.465625, 0.925], output: [0.0898701185996068]},
    {input: [0.3875, 0.944444444444444], output: [0.112510165400612]},
    {input: [0.184375, 0.958333333333333], output: [0.0898701185996068]},
    {input: [0.084375, 0.602777777777778], output: [0.067230071798602]},
    {input: [0.1515625, 0.552777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.494444444444444], output: [0.0445900249975972]},
    {input: [0.0890625, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.55], output: [0.0219499781965924]},
    {input: [0.10625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.113888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.625], output: [0.0445900249975972]},
    {input: [0.140625, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.3765625, 0.697222222222222], output: [0.0445900249975972]},
    {input: [0.1625, 0.719444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.686111111111111], output: [0.0445900249975972]},
    {input: [0.15625, 0.672222222222222], output: [0.0445900249975972]},
    {input: [0.1453125, 0.822222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.747222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.0388888888888889], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.177777777777778], output: [-0.000690068604412431]},
    {input: [0.184375, 0.230555555555556], output: [-0.000690068604412431]},
    {input: [0.15625, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.1], output: [0.0219499781965924]},
    {input: [0.1, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.25], output: [0.0219499781965924]},
    {input: [0.084375, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.358333333333333], output: [-0.000690068604412431]},
    {input: [0.140625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.336111111111111], output: [-0.000690068604412431]},
    {input: [0.084375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.365625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.3203125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.225, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.2078125, 0.3], output: [0.0445900249975972]},
    {input: [0.275, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.275], output: [0.0219499781965924]},
    {input: [0.2578125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.3375, 0.275], output: [0.0445900249975972]},
    {input: [0.3140625, 0.275], output: [0.0445900249975972]},
    {input: [0.409375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.365625, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.3765625, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.3984375, 0.236111111111111], output: [0.0898701185996068]},
    {input: [0.3703125, 0.227777777777778], output: [0.0898701185996068]},
    {input: [0.353125, 0.208333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.233333333333333], output: [0.067230071798602]},
    {input: [0.4265625, 0.241666666666667], output: [0.067230071798602]},
    {input: [0.3140625, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.365625, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.409375, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.409375, 0.275], output: [0.067230071798602]},
    {input: [0.5, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.465625, 0.327777777777778], output: [0.0898701185996068]},
    {input: [0.4328125, 0.408333333333333], output: [0.112510165400612]},
    {input: [0.421875, 0.397222222222222], output: [0.112510165400612]},
    {input: [0.471875, 0.388888888888889], output: [0.0898701185996068]},
    {input: [0.4375, 0.425], output: [0.0898701185996068]},
    {input: [0.365625, 0.425], output: [0.112510165400612]},
    {input: [0.38125, 0.391666666666667], output: [0.0898701185996068]},
    {input: [0.39375, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.521875, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.45, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.44375, 0.680555555555556], output: [0.067230071798602]},
    {input: [0.3765625, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.563888888888889], output: [0.0445900249975972]},
    {input: [0.296875, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.3375, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.597222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.39375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.44375, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.380555555555556], output: [0.0898701185996068]},
    {input: [0.4828125, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.640625, 0.3], output: [0.0898701185996068]},
    {input: [0.5953125, 0.225], output: [0.135150212201616]},
    {input: [0.6578125, 0.222222222222222], output: [0.157790259002621]},
    {input: [0.5953125, 0.241666666666667], output: [0.157790259002621]},
    {input: [0.6515625, 0.266666666666667], output: [0.157790259002621]},
    {input: [0.5953125, 0.244444444444444], output: [0.180430305803626]},
    {input: [0.6296875, 0.238888888888889], output: [0.180430305803626]},
    {input: [0.6125, 0.313888888888889], output: [0.180430305803626]},
    {input: [0.578125, 0.377777777777778], output: [0.180430305803626]},
    {input: [0.44375, 0.397222222222222], output: [0.180430305803626]},
    {input: [0.3203125, 0.430555555555556], output: [0.112510165400612]},
    {input: [0.359375, 0.386111111111111], output: [0.0898701185996068]},
    {input: [0.4328125, 0.283333333333333], output: [0.0898701185996068]},
    {input: [0.478125, 0.305555555555556], output: [0.135150212201616]},
    {input: [0.578125, 0.383333333333333], output: [0.157790259002621]},
    {input: [0.5, 0.352777777777778], output: [0.157790259002621]},
    {input: [0.465625, 0.352777777777778], output: [0.157790259002621]},
    {input: [0.49375, 0.344444444444444], output: [0.157790259002621]},
    {input: [0.5625, 0.361111111111111], output: [0.135150212201616]},
    {input: [0.590625, 0.380555555555556], output: [0.157790259002621]},
    {input: [0.6125, 0.463888888888889], output: [0.135150212201616]},
    {input: [0.55, 0.461111111111111], output: [0.157790259002621]},
    {input: [0.5109375, 0.538888888888889], output: [0.157790259002621]},
    {input: [0.4828125, 0.486111111111111], output: [0.135150212201616]},
    {input: [0.465625, 0.425], output: [0.112510165400612]},
    {input: [0.3765625, 0.386111111111111], output: [0.112510165400612]},
    {input: [0.3875, 0.325], output: [0.0898701185996068]},
    {input: [0.309375, 0.383333333333333], output: [0.0898701185996068]},
    {input: [0.2296875, 0.422222222222222], output: [0.0898701185996068]},
    {input: [0.0953125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.2078125, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.275, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.4328125, 0.380555555555556], output: [0.067230071798602]},
    {input: [0.5390625, 0.366666666666667], output: [0.0898701185996068]},
    {input: [0.534375, 0.344444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.336111111111111], output: [0.135150212201616]},
    {input: [0.5671875, 0.327777777777778], output: [0.157790259002621]},
    {input: [0.55625, 0.319444444444444], output: [0.180430305803626]},
    {input: [0.5453125, 0.336111111111111], output: [0.135150212201616]},
    {input: [0.50625, 0.297222222222222], output: [0.180430305803626]},
    {input: [0.521875, 0.280555555555556], output: [0.157790259002621]},
    {input: [0.55625, 0.283333333333333], output: [0.112510165400612]},
    {input: [0.478125, 0.277777777777778], output: [0.112510165400612]},
    {input: [0.478125, 0.397222222222222], output: [0.135150212201616]},
    {input: [0.478125, 0.408333333333333], output: [0.112510165400612]},
    {input: [0.45, 0.394444444444444], output: [0.112510165400612]},
    {input: [0.3984375, 0.366666666666667], output: [0.135150212201616]},
    {input: [0.05625, 0.177777777777778], output: [0.112510165400612]},
    {input: [0.2359375, 0.216666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.184375, 0.35], output: [0.067230071798602]},
    {input: [0.0890625, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.16875, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.525], output: [0.0219499781965924]},
    {input: [0.134375, 0.4], output: [0.0219499781965924]},
    {input: [0.196875, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.438888888888889], output: [-0.000690068604412431]},
    {input: [0.21875, 0.458333333333333], output: [-0.000690068604412431]},
    {input: [0.2640625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.3875, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.45, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.4890625, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.6234375, 0.3], output: [0.0898701185996068]},
    {input: [0.634375, 0.311111111111111], output: [0.112510165400612]},
    {input: [0.6578125, 0.308333333333333], output: [0.157790259002621]},
    {input: [0.61875, 0.325], output: [0.180430305803626]},
    {input: [0.775, 0.347222222222222], output: [0.180430305803626]},
    {input: [0.7078125, 0.297222222222222], output: [0.203070352604631]},
    {input: [0.675, 0.330555555555556], output: [0.225710399405636]},
    {input: [0.6625, 0.397222222222222], output: [0.24835044620664]},
    {input: [0.640625, 0.402777777777778], output: [0.24835044620664]},
    {input: [0.5734375, 0.444444444444444], output: [0.203070352604631]},
    {input: [0.409375, 0.416666666666667], output: [0.180430305803626]},
    {input: [0.4375, 0.425], output: [0.135150212201616]},
    {input: [0.3421875, 0.444444444444444], output: [0.135150212201616]},
    {input: [0.38125, 0.611111111111111], output: [0.112510165400612]},
    {input: [0.26875, 0.591666666666667], output: [0.0898701185996068]},
    {input: [0.10625, 0.619444444444444], output: [0.0898701185996068]},
    {input: [0.26875, 0.588888888888889], output: [0.067230071798602]},
    {input: [0.1125, 0.127777777777778], output: [0.067230071798602]},
    {input: [0.078125, 0.433333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.427777777777778], output: [0.0445900249975972]},
    {input: [0.1796875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.636111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.475], output: [0.0219499781965924]},
    {input: [0.2296875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.309375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.3], output: [0.0445900249975972]},
    {input: [0.1734375, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.1734375, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.35], output: [0.0219499781965924]},
    {input: [0.15625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.238888888888889], output: [-0.000690068604412431]},
    {input: [0.253125, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.236111111111111], output: [-0.000690068604412431]},
    {input: [0.303125, 0.25], output: [0.0219499781965924]},
    {input: [0.2359375, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.3], output: [0.0219499781965924]},
    {input: [0.240625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.0671875, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.028125, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.85], output: [0.0219499781965924]},
    {input: [0.1453125, 0.113888888888889], output: [-0.000690068604412431]},
    {input: [0.0328125, 0.958333333333333], output: [-0.000690068604412431]},
    {input: [0.078125, 0.675], output: [0.0219499781965924]},
    {input: [0.0609375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.0890625, 0.4], output: [0.0219499781965924]},
    {input: [0.275, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.225, 0.275], output: [0.0445900249975972]},
    {input: [0.1796875, 0.366666666666667], output: [0.0445900249975972]},
    {input: [0.134375, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.502777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.613888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.808333333333333], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.941666666666667], output: [-0.000690068604412431]},
    {input: [0.1, 0.0333333333333333], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.366666666666667], output: [-0.000690068604412431]},
    {input: [0.15625, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.425], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.252777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.577777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.808333333333333], output: [-0.000690068604412431]},
    {input: [0.16875, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.777777777777778], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.733333333333333], output: [-0.000690068604412431]},
    {input: [0.1625, 0.716666666666667], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.711111111111111], output: [-0.000690068604412431]},
    {input: [0.015625, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.222222222222222], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.0944444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.0953125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.269444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.330555555555556], output: [-0.000690068604412431]},
    {input: [0.21875, 0.297222222222222], output: [-0.000690068604412431]},
    {input: [0.2640625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.26875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.365625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.3984375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.303125, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.196875, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.0953125, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.0953125, 0.716666666666667], output: [0.0445900249975972]},
    {input: [0.1625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0638888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.275, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.353125, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.3765625, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.3140625, 0.891666666666667], output: [0.067230071798602]},
    {input: [0.28125, 0.952777777777778], output: [0.067230071798602]},
    {input: [0.275, 0.941666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.930555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.925], output: [0.0445900249975972]},
    {input: [0.275, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.246875, 0.880555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.1453125, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.15625, 0.936111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.2125, 0.975], output: [0.067230071798602]},
    {input: [0.196875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.296875, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.2578125, 0.952777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.95], output: [0.0445900249975972]},
    {input: [0.2921875, 0.936111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.26875, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.941666666666667], output: [0.0219499781965924]},
    {input: [0.184375, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.1125, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.140625, 0.827777777777778], output: [0.0445900249975972]},
    {input: [0.078125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.747222222222222], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.0861111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.275], output: [0.0219499781965924]},
    {input: [0.2078125, 0.25], output: [0.0219499781965924]},
    {input: [0.225, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.3], output: [0.0219499781965924]},
    {input: [0.184375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.2859375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.3765625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3875, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.3421875, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.33125, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.240625, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.33125, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.3484375, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.296875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.196875, 0.25], output: [0.0445900249975972]},
    {input: [0.2078125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1453125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.10625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.825], output: [0.0219499781965924]},
    {input: [0.3703125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.3875, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.528125, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.421875, 0.897222222222222], output: [0.0898701185996068]},
    {input: [0.5734375, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.5109375, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.4546875, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.4546875, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.415625, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.38125, 0.908333333333333], output: [0.112510165400612]},
    {input: [0.359375, 0.9], output: [0.0898701185996068]},
    {input: [0.190625, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.04375, 0.780555555555556], output: [0.067230071798602]},
    {input: [0.190625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.0138888888888889], output: [0.0445900249975972]},
    {input: [0.0671875, 0.0333333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.3875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.471875, 0.938888888888889], output: [0.0445900249975972]},
    {input: [0.50625, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.465625, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.5, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.421875, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.45, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.4375, 0.927777777777778], output: [0.0898701185996068]},
    {input: [0.5390625, 0.925], output: [0.0898701185996068]},
    {input: [0.4265625, 0.919444444444444], output: [0.135150212201616]},
    {input: [0.38125, 0.894444444444444], output: [0.135150212201616]},
    {input: [0.4890625, 0.888888888888889], output: [0.135150212201616]},
    {input: [0.465625, 0.891666666666667], output: [0.135150212201616]},
    {input: [0.478125, 0.919444444444444], output: [0.135150212201616]},
    {input: [0.5, 0.919444444444444], output: [0.112510165400612]},
    {input: [0.45, 0.925], output: [0.112510165400612]},
    {input: [0.1515625, 0.633333333333333], output: [0.067230071798602]},
    {input: [0.2125, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.2359375, 0.436111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.3140625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3203125, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.3], output: [0.0445900249975972]},
    {input: [0.1796875, 0.358333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.25], output: [0.0219499781965924]},
    {input: [0.1625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.1796875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.0694444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.880555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.296875, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.28125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3484375, 0.197222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.2125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.0609375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.95], output: [0.0219499781965924]},
    {input: [0.0953125, 0.975], output: [0.0219499781965924]},
    {input: [0.04375, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.647222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.3375, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.3875, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.359375, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.3421875, 0.330555555555556], output: [0.067230071798602]},
    {input: [0.21875, 0.355555555555556], output: [0.067230071798602]},
    {input: [0.253125, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.4328125, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.45, 0.402777777777778], output: [0.0898701185996068]},
    {input: [0.303125, 0.358333333333333], output: [0.112510165400612]},
    {input: [0.4609375, 0.352777777777778], output: [0.0898701185996068]},
    {input: [0.3203125, 0.411111111111111], output: [0.0898701185996068]},
    {input: [0.3875, 0.383333333333333], output: [0.0898701185996068]},
    {input: [0.478125, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.3875, 0.377777777777778], output: [0.0898701185996068]},
    {input: [0.33125, 0.397222222222222], output: [0.0898701185996068]},
    {input: [0.3421875, 0.375], output: [0.067230071798602]},
    {input: [0.3703125, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.3203125, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.3375, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.28125, 0.366666666666667], output: [0.0898701185996068]},
    {input: [0.2578125, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.2359375, 0.344444444444444], output: [0.067230071798602]},
    {input: [0.246875, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.28125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.361111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.525], output: [0.0219499781965924]},
    {input: [0.0609375, 0.5], output: [0.0219499781965924]},
    {input: [0.084375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.0671875, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.04375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.438888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.4], output: [0.0219499781965924]},
    {input: [0.071875, 0.488888888888889], output: [-0.000690068604412431]},
    {input: [0.078125, 0.375], output: [0.0219499781965924]},
    {input: [0.1453125, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.26875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.3375, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.3375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.296875, 0.275], output: [0.0445900249975972]},
    {input: [0.2296875, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.1515625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.275], output: [0.0219499781965924]},
    {input: [0.071875, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.827777777777778], output: [0.0219499781965924]},
    {input: [0.0328125, 0.738888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.875], output: [-0.000690068604412431]},
    {input: [0.0390625, 0.0277777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.0166666666666667], output: [-0.000690068604412431]},
    {input: [0.05, 0.986111111111111], output: [-0.000690068604412431]},
    {input: [0.028125, 0.961111111111111], output: [-0.000690068604412431]},
    {input: [0.05625, 0.558333333333333], output: [-0.000690068604412431]},
    {input: [0.071875, 0.433333333333333], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.291666666666667], output: [-0.000690068604412431]},
    {input: [0.196875, 0.344444444444444], output: [-0.000690068604412431]},
    {input: [0.225, 0.288888888888889], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.375], output: [0.0219499781965924]},
    {input: [0.0671875, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.816666666666667], output: [-0.000690068604412431]},
    {input: [0.134375, 0.902777777777778], output: [-0.000690068604412431]},
    {input: [0.128125, 0.933333333333333], output: [-0.000690068604412431]},
    {input: [0.196875, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.190625, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.941666666666667], output: [-0.000690068604412431]},
    {input: [0.309375, 0.95], output: [0.0219499781965924]},
    {input: [0.28125, 0.969444444444444], output: [0.0445900249975972]},
    {input: [0.2359375, 0.966666666666667], output: [0.0445900249975972]},
    {input: [0.1, 0.975], output: [0.0445900249975972]},
    {input: [0.05, 0.594444444444444], output: [0.0445900249975972]},
    {input: [0.05625, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.0328125, 0.625], output: [0.0219499781965924]},
    {input: [0.1453125, 0.425], output: [0.0219499781965924]},
    {input: [0.1125, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.1515625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.247222222222222], output: [-0.000690068604412431]},
    {input: [0.1125, 0.4], output: [0.0219499781965924]},
    {input: [0.225, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.25], output: [0.0445900249975972]},
    {input: [0.184375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.275], output: [0.0219499781965924]},
    {input: [0.078125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.447222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.472222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.477777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.0671875, 0.0777777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.419444444444444], output: [-0.000690068604412431]},
    {input: [0.078125, 0.425], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.516666666666667], output: [-0.000690068604412431]},
    {input: [0.04375, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.325], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.591666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.475], output: [0.0219499781965924]},
    {input: [0.1234375, 0.4], output: [0.0219499781965924]},
    {input: [0.140625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.2125, 0.225], output: [0.0219499781965924]},
    {input: [0.309375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.3203125, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.225, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.325], output: [0.0445900249975972]},
    {input: [0.3140625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2921875, 0.3], output: [0.0445900249975972]},
    {input: [0.246875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.225, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.21875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.16875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.325], output: [0.0219499781965924]},
    {input: [0.0609375, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.015625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.925], output: [0.0219499781965924]},
    {input: [0.1234375, 0.983333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.1234375, 0.913888888888889], output: [-0.000690068604412431]},
    {input: [0.05, 0.458333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.675], output: [0.0219499781965924]},
    {input: [0.0609375, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.028125, 0.0194444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.669444444444444], output: [0.0219499781965924]},
    {input: [0.05, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.0046875, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.955555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.769444444444444], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.190625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.875], output: [0.0219499781965924]},
    {input: [0.190625, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.04375, 0.9], output: [0.0219499781965924]},
    {input: [0.04375, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.184375, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.26875, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.975], output: [0.0219499781965924]},
    {input: [0.2015625, 0.925], output: [0.0219499781965924]},
    {input: [0.2296875, 0.958333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.325, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.353125, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.2640625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.253125, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.8], output: [0.0445900249975972]},
    {input: [0.1796875, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.2296875, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.3375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.303125, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.4265625, 0.9], output: [0.067230071798602]},
    {input: [0.3421875, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.303125, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.325, 0.95], output: [0.0898701185996068]},
    {input: [0.353125, 0.95], output: [0.067230071798602]},
    {input: [0.3140625, 0.952777777777778], output: [0.0898701185996068]},
    {input: [0.309375, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.2859375, 0.95], output: [0.067230071798602]},
    {input: [0.225, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.1125, 0.808333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.847222222222222], output: [-0.000690068604412431]},
    {input: [0.196875, 0.838888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.105555555555556], output: [-0.000690068604412431]},
    {input: [0.128125, 0.855555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.780555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.0390625, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.711111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.2578125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.3765625, 0.25], output: [-0.000690068604412431]},
    {input: [0.21875, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.2125, 0.15], output: [0.0445900249975972]},
    {input: [0.365625, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.2921875, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.33125, 0.308333333333333], output: [0.0898701185996068]},
    {input: [0.28125, 0.3], output: [0.0898701185996068]},
    {input: [0.190625, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.3140625, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.3203125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.21875, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.140625, 0.3], output: [0.0219499781965924]},
    {input: [0.1515625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.2359375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1734375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.1171875, 0.175], output: [0.0219499781965924]},
    {input: [0.16875, 0.325], output: [0.0219499781965924]},
    {input: [0.0671875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.05625, 0.0388888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.0972222222222222], output: [-0.000690068604412431]},
    {input: [0.0890625, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.015625, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.0109375, 0.180555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.25], output: [0.0219499781965924]},
    {input: [0.0671875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.0953125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.35], output: [0.0219499781965924]},
    {input: [0.05625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.196875, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.216666666666667], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.272222222222222], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.313888888888889], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.330555555555556], output: [-0.000690068604412431]},
    {input: [0.1625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.3], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.294444444444444], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.308333333333333], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.294444444444444], output: [-0.000690068604412431]},
    {input: [0.128125, 0.305555555555556], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.3203125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2578125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.3984375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.3375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.2921875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.325, 0.3], output: [0.0445900249975972]},
    {input: [0.296875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.196875, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.1453125, 0.275], output: [0.0445900249975972]},
    {input: [0.028125, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.05, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.875], output: [0.0219499781965924]},
    {input: [0.078125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.85], output: [0.0219499781965924]},
    {input: [0.0671875, 0.541666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.516666666666667], output: [0.0219499781965924]},
    {input: [0.04375, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.719444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.475], output: [0.0219499781965924]},
    {input: [0.0890625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2015625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.275], output: [0.0219499781965924]},
    {input: [0.21875, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.191666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.0953125, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.21875, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.194444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.275], output: [0.0219499781965924]},
    {input: [0.1125, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.194444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.1171875, 0.238888888888889], output: [0.0445900249975972]},
    {input: [0.05, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.10625, 0.175], output: [0.0219499781965924]},
    {input: [0.15625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.2296875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.2296875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.1625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.409375, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.26875, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.3140625, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.45, 0.861111111111111], output: [0.0898701185996068]},
    {input: [0.4265625, 0.925], output: [0.112510165400612]},
    {input: [0.4828125, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.5171875, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.5453125, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.3421875, 0.833333333333333], output: [0.112510165400612]},
    {input: [0.3203125, 0.841666666666667], output: [0.112510165400612]},
    {input: [0.225, 0.847222222222222], output: [0.0898701185996068]},
    {input: [0.184375, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.190625, 0.894444444444444], output: [0.0898701185996068]},
    {input: [0.240625, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.1125, 0.413888888888889], output: [0.0898701185996068]},
    {input: [0.2359375, 0.419444444444444], output: [0.0898701185996068]},
    {input: [0.2640625, 0.430555555555556], output: [0.067230071798602]},
    {input: [0.325, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.411111111111111], output: [0.0445900249975972]},
    {input: [0.246875, 0.391666666666667], output: [0.0445900249975972]},
    {input: [0.1734375, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.10625, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.0671875, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.15], output: [0.0219499781965924]},
    {input: [0.0609375, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.365625, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.3984375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.39375, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.4328125, 0.9], output: [0.0898701185996068]},
    {input: [0.44375, 0.9], output: [0.067230071798602]},
    {input: [0.3203125, 0.836111111111111], output: [0.067230071798602]},
    {input: [0.3203125, 0.825], output: [0.0445900249975972]},
    {input: [0.3203125, 0.841666666666667], output: [0.067230071798602]},
    {input: [0.26875, 0.858333333333333], output: [0.067230071798602]},
    {input: [0.190625, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.2015625, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.1125, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.566666666666667], output: [0.0445900249975972]},
    {input: [0.0671875, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.238888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.475], output: [0.0219499781965924]},
    {input: [0.0390625, 0.486111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.05], output: [0.0219499781965924]},
    {input: [0.0671875, 0.980555555555556], output: [-0.000690068604412431]},
    {input: [0.0671875, 0.95], output: [0.0219499781965924]},
    {input: [0.0953125, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.3703125, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.415625, 0.891666666666667], output: [0.0219499781965924]},
    {input: [0.39375, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.3484375, 0.95], output: [0.067230071798602]},
    {input: [0.225, 0.963888888888889], output: [0.0898701185996068]},
    {input: [0.1515625, 0.0305555555555556], output: [0.067230071798602]},
    {input: [0.071875, 0.966666666666667], output: [0.067230071798602]},
    {input: [0.071875, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.10625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.0953125, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.825], output: [0.0219499781965924]},
    {input: [0.140625, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.972222222222222], output: [0.0219499781965924]},
    {input: [0.1, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.830555555555556], output: [-0.000690068604412431]},
    {input: [0.39375, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.4328125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.415625, 0.927777777777778], output: [0.067230071798602]},
    {input: [0.44375, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.3375, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.3375, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.952777777777778], output: [0.067230071798602]},
    {input: [0.2015625, 0.983333333333333], output: [0.067230071798602]},
    {input: [0.1234375, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.2125, 0.905555555555556], output: [0.067230071798602]},
    {input: [0.1171875, 0.925], output: [0.0445900249975972]},
    {input: [0.0671875, 0.7], output: [0.0445900249975972]},
    {input: [0.0671875, 0.580555555555556], output: [0.0445900249975972]},
    {input: [0.0953125, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.594444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.666666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.85], output: [0.0219499781965924]},
    {input: [0.184375, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.10625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.277777777777778], output: [-0.000690068604412431]},
    {input: [0.04375, 0.163888888888889], output: [-0.000690068604412431]},
    {input: [0.2125, 0.0222222222222222], output: [-0.000690068604412431]},
    {input: [0.325, 0.916666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.15625, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.184375, 0.958333333333333], output: [0.0445900249975972]},
    {input: [0.1453125, 0.925], output: [0.0445900249975972]},
    {input: [0.071875, 0.925], output: [0.0445900249975972]},
    {input: [0.0671875, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.0671875, 0.633333333333333], output: [0.0445900249975972]},
    {input: [0.0390625, 0.980555555555556], output: [0.0445900249975972]},
    {input: [0.0671875, 0.630555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.25], output: [0.0219499781965924]},
    {input: [0.134375, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.05, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.6], output: [0.0219499781965924]},
    {input: [0.1453125, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.16875, 0.866666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.325, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.4046875, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.3875, 0.869444444444444], output: [0.0898701185996068]},
    {input: [0.359375, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.3984375, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.2578125, 0.888888888888889], output: [0.0898701185996068]},
    {input: [0.4328125, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.3421875, 0.952777777777778], output: [0.067230071798602]},
    {input: [0.1453125, 0.00555555555555556], output: [0.067230071798602]},
    {input: [0.1453125, 0.388888888888889], output: [0.067230071798602]},
    {input: [0.0671875, 0.397222222222222], output: [0.0445900249975972]},
    {input: [0.1796875, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1515625, 0.275], output: [0.0219499781965924]},
    {input: [0.1515625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.0416666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.134375, 0.188888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.825], output: [0.067230071798602]},
    {input: [0.1234375, 0.1], output: [0.0898701185996068]},
    {input: [0.134375, 0.136111111111111], output: [0.067230071798602]},
    {input: [0.1515625, 0.111111111111111], output: [0.067230071798602]},
    {input: [0.15625, 0.105555555555556], output: [0.0445900249975972]},
    {input: [0.04375, 0.0555555555555556], output: [0.0445900249975972]},
    {input: [0.04375, 0.75], output: [0.0445900249975972]},
    {input: [0.0328125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.2640625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.380555555555556], output: [0.0445900249975972]},
    {input: [0.196875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.190625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1453125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2640625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.365625, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.247222222222222], output: [0.067230071798602]},
    {input: [0.2921875, 0.230555555555556], output: [0.067230071798602]},
    {input: [0.225, 0.163888888888889], output: [0.0445900249975972]},
    {input: [0.2015625, 0.127777777777778], output: [0.0445900249975972]},
    {input: [0.21875, 0.133333333333333], output: [0.0219499781965924]},
    {input: [0.28125, 0.136111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.108333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.116666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.1], output: [0.0219499781965924]},
    {input: [0.134375, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.375], output: [0.0219499781965924]},
    {input: [0.184375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.275, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.2125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.1734375, 0.225], output: [0.0219499781965924]},
    {input: [0.190625, 0.35], output: [0.0219499781965924]},
    {input: [0.1453125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.4890625, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.471875, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.3703125, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.3703125, 0.838888888888889], output: [0.0898701185996068]},
    {input: [0.353125, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.309375, 0.788888888888889], output: [0.067230071798602]},
    {input: [0.26875, 0.791666666666667], output: [0.067230071798602]},
    {input: [0.2578125, 0.827777777777778], output: [0.067230071798602]},
    {input: [0.275, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.1453125, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.0671875, 0.0388888888888889], output: [0.067230071798602]},
    {input: [0.05, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.3], output: [0.0445900249975972]},
    {input: [0.2640625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.1, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.05625, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.505555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.633333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.65], output: [0.0219499781965924]},
    {input: [0.225, 0.702777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.772222222222222], output: [0.0219499781965924]},
    {input: [0.365625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.39375, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.1796875, 0.944444444444444], output: [0.0898701185996068]},
    {input: [0.1625, 0.180555555555556], output: [0.067230071798602]},
    {input: [0.15625, 0.186111111111111], output: [0.0445900249975972]},
    {input: [0.184375, 0.172222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.158333333333333], output: [0.0445900249975972]},
    {input: [0.084375, 0.0722222222222222], output: [0.0445900249975972]},
    {input: [0.1171875, 0.102777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.144444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.141666666666667], output: [0.0219499781965924]},
    {input: [0.0390625, 0.275], output: [0.0219499781965924]},
    {input: [0.0390625, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.365625, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.736111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.2359375, 0.75], output: [0.0219499781965924]},
    {input: [0.2359375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.783333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.825], output: [0.0445900249975972]},
    {input: [0.3484375, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.21875, 0.986111111111111], output: [0.067230071798602]},
    {input: [0.140625, 0.05], output: [0.067230071798602]},
    {input: [0.0890625, 0.955555555555556], output: [0.067230071798602]},
    {input: [0.140625, 0.986111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.994444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.00555555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.975], output: [0.0219499781965924]},
    {input: [0.05625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.425], output: [0.0219499781965924]},
    {input: [0.2359375, 0.433333333333333], output: [0.0219499781965924]},
    {input: [0.253125, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.35], output: [0.0219499781965924]},
    {input: [0.15625, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.26875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.516666666666667], output: [0.0445900249975972]},
    {input: [0.16875, 0.3], output: [0.0445900249975972]},
    {input: [0.1125, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.21875, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.3375, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.3984375, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.3703125, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.3], output: [0.0445900249975972]},
    {input: [0.303125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.3421875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.663888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.0611111111111111], output: [0.0219499781965924]},
    {input: [0.1796875, 0.225], output: [0.0219499781965924]},
    {input: [0.184375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.21875, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.65], output: [0.0445900249975972]},
    {input: [0.04375, 0.877777777777778], output: [0.0219499781965924]},
    {input: [0.246875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.16875, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.2640625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.2921875, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.28125, 0.322222222222222], output: [0.0445900249975972]},
    {input: [0.2640625, 0.35], output: [0.0445900249975972]},
    {input: [0.296875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.28125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.1125, 0.0305555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.084375, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.641666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.725], output: [0.0219499781965924]},
    {input: [0.2015625, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.3140625, 0.747222222222222], output: [0.067230071798602]},
    {input: [0.409375, 0.752777777777778], output: [0.112510165400612]},
    {input: [0.4265625, 0.791666666666667], output: [0.112510165400612]},
    {input: [0.590625, 0.861111111111111], output: [0.112510165400612]},
    {input: [0.421875, 0.847222222222222], output: [0.135150212201616]},
    {input: [0.3984375, 0.855555555555556], output: [0.112510165400612]},
    {input: [0.465625, 0.880555555555556], output: [0.135150212201616]},
    {input: [0.3375, 0.913888888888889], output: [0.112510165400612]},
    {input: [0.4328125, 0.936111111111111], output: [0.112510165400612]},
    {input: [0.3765625, 0.916666666666667], output: [0.135150212201616]},
    {input: [0.3421875, 0.905555555555556], output: [0.112510165400612]},
    {input: [0.359375, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.4046875, 0.897222222222222], output: [0.112510165400612]},
    {input: [0.3140625, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.3203125, 0.852777777777778], output: [0.112510165400612]},
    {input: [0.2296875, 0.847222222222222], output: [0.0898701185996068]},
    {input: [0.253125, 0.830555555555556], output: [0.0898701185996068]},
    {input: [0.365625, 0.833333333333333], output: [0.067230071798602]},
    {input: [0.471875, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.6296875, 0.875], output: [0.067230071798602]},
    {input: [0.634375, 0.858333333333333], output: [0.157790259002621]},
    {input: [0.6234375, 0.85], output: [0.157790259002621]},
    {input: [0.6296875, 0.847222222222222], output: [0.157790259002621]},
    {input: [0.6859375, 0.863888888888889], output: [0.157790259002621]},
    {input: [0.6515625, 0.85], output: [0.180430305803626]},
    {input: [0.746875, 0.869444444444444], output: [0.180430305803626]},
    {input: [0.66875, 0.880555555555556], output: [0.180430305803626]},
    {input: [0.646875, 0.886111111111111], output: [0.157790259002621]},
    {input: [0.775, 0.891666666666667], output: [0.203070352604631]},
    {input: [0.7359375, 0.911111111111111], output: [0.203070352604631]},
    {input: [0.7421875, 0.902777777777778], output: [0.180430305803626]},
    {input: [0.675, 0.9], output: [0.157790259002621]},
    {input: [0.6578125, 0.9], output: [0.157790259002621]},
    {input: [0.6625, 0.9], output: [0.157790259002621]},
    {input: [0.6515625, 0.9], output: [0.157790259002621]},
    {input: [0.6125, 0.891666666666667], output: [0.135150212201616]},
    {input: [0.5625, 0.894444444444444], output: [0.135150212201616]},
    {input: [0.5453125, 0.883333333333333], output: [0.112510165400612]},
    {input: [0.49375, 0.872222222222222], output: [0.112510165400612]},
    {input: [0.44375, 0.861111111111111], output: [0.112510165400612]},
    {input: [0.4890625, 0.85], output: [0.112510165400612]},
    {input: [0.5453125, 0.855555555555556], output: [0.0898701185996068]},
    {input: [0.590625, 0.880555555555556], output: [0.0898701185996068]},
    {input: [0.6015625, 0.891666666666667], output: [0.135150212201616]},
    {input: [0.675, 0.897222222222222], output: [0.157790259002621]},
    {input: [0.6625, 0.894444444444444], output: [0.157790259002621]},
    {input: [0.703125, 0.891666666666667], output: [0.180430305803626]},
    {input: [0.7078125, 0.886111111111111], output: [0.157790259002621]},
    {input: [0.690625, 0.886111111111111], output: [0.203070352604631]},
    {input: [0.6625, 0.897222222222222], output: [0.203070352604631]},
    {input: [0.4328125, 0.908333333333333], output: [0.067230071798602]},
    {input: [0.3421875, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.902777777777778], output: [0.067230071798602]},
    {input: [0.184375, 0.786111111111111], output: [0.0445900249975972]},
    {input: [0.253125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.825], output: [0.0445900249975972]},
    {input: [0.275, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.409375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.5109375, 0.911111111111111], output: [0.067230071798602]},
    {input: [0.534375, 0.925], output: [0.0898701185996068]},
    {input: [0.465625, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.45, 0.919444444444444], output: [0.135150212201616]},
    {input: [0.4375, 0.922222222222222], output: [0.112510165400612]},
    {input: [0.421875, 0.941666666666667], output: [0.0898701185996068]},
    {input: [0.4046875, 0.925], output: [0.0898701185996068]},
    {input: [0.3203125, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.296875, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.1796875, 0.963888888888889], output: [0.067230071798602]},
    {input: [0.16875, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.0609375, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.04375, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.021875, 0.786111111111111], output: [0.0219499781965924]},
    {input: [0.021875, 0.186111111111111], output: [0.0219499781965924]},
    {input: [0.0046875, 0.075], output: [0.0219499781965924]},
    {input: [0.021875, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.021875, 0.163888888888889], output: [0.0219499781965924]},
    {input: [0.015625, 0.583333333333333], output: [0.0219499781965924]},
    {input: [0.1125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.0671875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.128125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.1734375, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.0609375, 0.572222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.196875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.240625, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.2015625, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2296875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.325], output: [0.0219499781965924]},
    {input: [0.275, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.38125, 0.275], output: [0.0219499781965924]},
    {input: [0.2859375, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.184375, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.5390625, 0.275], output: [0.0445900249975972]},
    {input: [0.26875, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.128125, 0.658333333333333], output: [0.067230071798602]},
    {input: [0.359375, 0.216666666666667], output: [0.0445900249975972]},
    {input: [0.2359375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.3484375, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2125, 0.388888888888889], output: [0.0445900249975972]},
    {input: [0.1515625, 0.813888888888889], output: [0.0445900249975972]},
    {input: [0.084375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.3375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1515625, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.2296875, 0.225], output: [0.0445900249975972]},
    {input: [0.2296875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.3], output: [0.0219499781965924]},
    {input: [0.2296875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.383333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.6], output: [0.0219499781965924]},
    {input: [0.140625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.1, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.833333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.813888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.25], output: [0.0219499781965924]},
    {input: [0.1, 0.194444444444444], output: [-0.000690068604412431]},
    {input: [0.1, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.021875, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.028125, 0.752777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.10625, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.708333333333333], output: [0.0219499781965924]},
    {input: [0.0328125, 0.00833333333333333], output: [0.0219499781965924]},
    {input: [0.0390625, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.0609375, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.05, 0.805555555555556], output: [-0.000690068604412431]},
    {input: [0.04375, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.844444444444444], output: [-0.000690068604412431]},
    {input: [0.1125, 0.877777777777778], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.913888888888889], output: [-0.000690068604412431]},
    {input: [0.2078125, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.3875, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.4265625, 0.925], output: [-0.000690068604412431]},
    {input: [0.5, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.5171875, 0.902777777777778], output: [0.0898701185996068]},
    {input: [0.5625, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.5625, 0.9], output: [0.135150212201616]},
    {input: [0.49375, 0.897222222222222], output: [0.157790259002621]},
    {input: [0.521875, 0.9], output: [0.135150212201616]},
    {input: [0.471875, 0.925], output: [0.135150212201616]},
    {input: [0.45, 0.930555555555556], output: [0.112510165400612]},
    {input: [0.465625, 0.925], output: [0.135150212201616]},
    {input: [0.3765625, 0.911111111111111], output: [0.135150212201616]},
    {input: [0.39375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.409375, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.10625, 0.652777777777778], output: [0.0898701185996068]},
    {input: [0.1734375, 0.363888888888889], output: [0.0898701185996068]},
    {input: [0.190625, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.196875, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.1171875, 0.872222222222222], output: [0.067230071798602]},
    {input: [0.421875, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.5390625, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.521875, 0.925], output: [0.112510165400612]},
    {input: [0.5109375, 0.922222222222222], output: [0.135150212201616]},
    {input: [0.5671875, 0.919444444444444], output: [0.135150212201616]},
    {input: [0.5625, 0.905555555555556], output: [0.135150212201616]},
    {input: [0.5453125, 0.911111111111111], output: [0.157790259002621]},
    {input: [0.534375, 0.913888888888889], output: [0.135150212201616]},
    {input: [0.55625, 0.919444444444444], output: [0.135150212201616]},
    {input: [0.5171875, 0.919444444444444], output: [0.157790259002621]},
    {input: [0.4265625, 0.922222222222222], output: [0.157790259002621]},
    {input: [0.45, 0.930555555555556], output: [0.135150212201616]},
    {input: [0.2578125, 0.925], output: [0.135150212201616]},
    {input: [0.3203125, 0.975], output: [0.112510165400612]},
    {input: [0.2015625, 0.941666666666667], output: [0.067230071798602]},
    {input: [0.10625, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.0953125, 0.455555555555556], output: [0.0445900249975972]},
    {input: [0.071875, 0.475], output: [0.0445900249975972]},
    {input: [0.184375, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.2359375, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.1453125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1234375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.225], output: [0.0219499781965924]},
    {input: [0.1171875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.15625, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.28125, 0.925], output: [0.0445900249975972]},
    {input: [0.2125, 0.95], output: [0.0445900249975972]},
    {input: [0.078125, 0.655555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.908333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.1625, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.0890625, 0.675], output: [0.067230071798602]},
    {input: [0.2578125, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.184375, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.2078125, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.2015625, 0.402777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.1125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.1, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.0609375, 0.691666666666667], output: [0.067230071798602]},
    {input: [0.5, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.5453125, 0.863888888888889], output: [0.0898701185996068]},
    {input: [0.5453125, 0.852777777777778], output: [0.135150212201616]},
    {input: [0.6578125, 0.872222222222222], output: [0.135150212201616]},
    {input: [0.584375, 0.855555555555556], output: [0.180430305803626]},
    {input: [0.471875, 0.85], output: [0.135150212201616]},
    {input: [0.3875, 0.852777777777778], output: [0.112510165400612]},
    {input: [0.4375, 0.863888888888889], output: [0.0898701185996068]},
    {input: [0.303125, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.2578125, 0.772222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.616666666666667], output: [0.067230071798602]},
    {input: [0.21875, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.3421875, 0.85], output: [0.0445900249975972]},
    {input: [0.2296875, 0.847222222222222], output: [0.067230071798602]},
    {input: [0.246875, 0.866666666666667], output: [0.067230071798602]},
    {input: [0.38125, 0.925], output: [0.067230071798602]},
    {input: [0.1625, 0.95], output: [0.067230071798602]},
    {input: [0.0890625, 0.852777777777778], output: [0.0445900249975972]},
    {input: [0.1125, 0.794444444444444], output: [0.0445900249975972]},
    {input: [0.04375, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.05625, 0], output: [0.0219499781965924]},
    {input: [0.2078125, 0.55], output: [0.0219499781965924]},
    {input: [0.15625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.0390625, 0.55], output: [0.0219499781965924]},
    {input: [0.1734375, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.186111111111111], output: [-0.000690068604412431]},
    {input: [0.1171875, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.1], output: [0.0219499781965924]},
    {input: [0.184375, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.240625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.9], output: [0.0445900249975972]},
    {input: [0.128125, 0.95], output: [0.0219499781965924]},
    {input: [0.1, 0.25], output: [0.0219499781965924]},
    {input: [0.028125, 0.375], output: [0.0219499781965924]},
    {input: [0.134375, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.2859375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.196875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.4], output: [0.0219499781965924]},
    {input: [0.04375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.0890625, 0.8], output: [0.0219499781965924]},
    {input: [0.0671875, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.128125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.325], output: [0.0219499781965924]},
    {input: [0.071875, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.2640625, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.3765625, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.275, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.433333333333333], output: [0.0445900249975972]},
    {input: [0.0671875, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.55], output: [0.0445900249975972]},
    {input: [0.0953125, 0.986111111111111], output: [0.0445900249975972]},
    {input: [0.2078125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.241666666666667], output: [0.0445900249975972]},
    {input: [0.2078125, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.225, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.246875, 0.697222222222222], output: [0.0219499781965924]},
    {input: [0.3203125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.49375, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.415625, 0.866666666666667], output: [0.0445900249975972]},
    {input: [0.3703125, 0.855555555555556], output: [0.067230071798602]},
    {input: [0.3703125, 0.866666666666667], output: [0.0898701185996068]},
    {input: [0.2859375, 0.880555555555556], output: [0.112510165400612]},
    {input: [0.1515625, 0.677777777777778], output: [0.112510165400612]},
    {input: [0.1234375, 0.633333333333333], output: [0.112510165400612]},
    {input: [0.184375, 0.625], output: [0.0898701185996068]},
    {input: [0.04375, 0.858333333333333], output: [0.0898701185996068]},
    {input: [0.0328125, 0.402777777777778], output: [0.0898701185996068]},
    {input: [0.134375, 0.152777777777778], output: [0.0898701185996068]},
    {input: [0.2015625, 0.222222222222222], output: [0.0898701185996068]},
    {input: [0.246875, 0.238888888888889], output: [0.0898701185996068]},
    {input: [0.3765625, 0.275], output: [0.067230071798602]},
    {input: [0.45, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.421875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.421875, 0.308333333333333], output: [0.067230071798602]},
    {input: [0.3375, 0.613888888888889], output: [0.067230071798602]},
    {input: [0.3421875, 0.688888888888889], output: [0.0445900249975972]},
    {input: [0.2921875, 0.666666666666667], output: [0.0445900249975972]},
    {input: [0.3140625, 0.722222222222222], output: [0.0445900249975972]},
    {input: [0.521875, 0.8], output: [0.0445900249975972]},
    {input: [0.640625, 0.875], output: [0.067230071798602]},
    {input: [0.528125, 0.875], output: [0.112510165400612]},
    {input: [0.471875, 0.855555555555556], output: [0.112510165400612]},
    {input: [0.39375, 0.836111111111111], output: [0.112510165400612]},
    {input: [0.3703125, 0.8], output: [0.112510165400612]},
    {input: [0.353125, 0.827777777777778], output: [0.112510165400612]},
    {input: [0.3421875, 0.844444444444444], output: [0.112510165400612]},
    {input: [0.33125, 0.855555555555556], output: [0.0898701185996068]},
    {input: [0.253125, 0.877777777777778], output: [0.0898701185996068]},
    {input: [0.15625, 0.816666666666667], output: [0.0898701185996068]},
    {input: [0.10625, 0.811111111111111], output: [0.067230071798602]},
    {input: [0.084375, 0.861111111111111], output: [0.067230071798602]},
    {input: [0.1125, 0.975], output: [0.0445900249975972]},
    {input: [0.084375, 0.85], output: [0.0445900249975972]},
    {input: [0.028125, 0.808333333333333], output: [0.0445900249975972]},
    {input: [0.071875, 0.438888888888889], output: [0.0445900249975972]},
    {input: [0.1234375, 0.402777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.408333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.452777777777778], output: [0.0219499781965924]},
    {input: [0.0953125, 0.802777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.677777777777778], output: [0.0219499781965924]},
    {input: [0.184375, 0.658333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.75], output: [0.0219499781965924]},
    {input: [0.2078125, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.783333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.733333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.769444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.0671875, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.588888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.555555555555556], output: [0.0219499781965924]},
    {input: [0.2015625, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.2640625, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.184375, 0.4], output: [0.0445900249975972]},
    {input: [0.0953125, 0.627777777777778], output: [0.067230071798602]},
    {input: [0.04375, 0.547222222222222], output: [0.067230071798602]},
    {input: [0.128125, 0.547222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.071875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.491666666666667], output: [0.0219499781965924]},
    {input: [0.134375, 0.483333333333333], output: [0.0219499781965924]},
    {input: [0.134375, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.375], output: [0.0219499781965924]},
    {input: [0.1796875, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.190625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1734375, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.28125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.325], output: [0.0445900249975972]},
    {input: [0.309375, 0.311111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.325, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.325, 0.3], output: [0.0219499781965924]},
    {input: [0.26875, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.134375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.1, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.1171875, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.0328125, 0.661111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.0328125, 0.175], output: [0.0219499781965924]},
    {input: [0.078125, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.275], output: [0.0219499781965924]},
    {input: [0.134375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.184375, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.2859375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.196875, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.2078125, 0.438888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.283333333333333], output: [-0.000690068604412431]},
    {input: [0.128125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.375], output: [-0.000690068604412431]},
    {input: [0.1, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.0890625, 0.266666666666667], output: [-0.000690068604412431]},
    {input: [0.084375, 0.475], output: [-0.000690068604412431]},
    {input: [0.05, 0.608333333333333], output: [-0.000690068604412431]},
    {input: [0.04375, 0.630555555555556], output: [-0.000690068604412431]},
    {input: [0.028125, 0.85], output: [-0.000690068604412431]},
    {input: [0.015625, 0.141666666666667], output: [-0.000690068604412431]},
    {input: [0.0609375, 0.852777777777778], output: [0.0219499781965924]},
    {input: [0.078125, 0.808333333333333], output: [-0.000690068604412431]},
    {input: [0.021875, 0.886111111111111], output: [-0.000690068604412431]},
    {input: [0.078125, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.95], output: [0.0219499781965924]},
    {input: [0.134375, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.0390625, 0.125], output: [0.0219499781965924]},
    {input: [0.05625, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.566666666666667], output: [0.0219499781965924]},
    {input: [0.1171875, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.619444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.427777777777778], output: [0.0219499781965924]},
    {input: [0.0890625, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.455555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.966666666666667], output: [-0.000690068604412431]},
    {input: [0.1, 0.838888888888889], output: [-0.000690068604412431]},
    {input: [0.1125, 0.908333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.858333333333333], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.816666666666667], output: [-0.000690068604412431]},
    {input: [0.1, 0.897222222222222], output: [0.0219499781965924]},
    {input: [0.028125, 0.561111111111111], output: [-0.000690068604412431]},
    {input: [0.04375, 0.766666666666667], output: [0.0219499781965924]},
    {input: [0.078125, 0.980555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.822222222222222], output: [0.0219499781965924]},
    {input: [0.0109375, 0.652777777777778], output: [0.0219499781965924]},
    {input: [0.0109375, 0.325], output: [0.0219499781965924]},
    {input: [0.05625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.275, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.2359375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.227777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.303125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.415625, 0.236111111111111], output: [0.067230071798602]},
    {input: [0.353125, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.2640625, 0.288888888888889], output: [0.0898701185996068]},
    {input: [0.3203125, 0.261111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.3375, 0.352777777777778], output: [0.0898701185996068]},
    {input: [0.3703125, 0.358333333333333], output: [0.0898701185996068]},
    {input: [0.3421875, 0.341666666666667], output: [0.0898701185996068]},
    {input: [0.4046875, 0.325], output: [0.067230071798602]},
    {input: [0.409375, 0.322222222222222], output: [0.0898701185996068]},
    {input: [0.3140625, 0.277777777777778], output: [0.0898701185996068]},
    {input: [0.2578125, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.2859375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.28125, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.196875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.25], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.2], output: [0.0219499781965924]},
    {input: [0.184375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.021875, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.0109375, 0.758333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1234375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.05, 0.497222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.05, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.05625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.246875, 0.275], output: [0.0219499781965924]},
    {input: [0.190625, 0.377777777777778], output: [0.0219499781965924]},
    {input: [0.1234375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.134375, 0.330555555555556], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1625, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1796875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.3], output: [0.0445900249975972]},
    {input: [0.10625, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.190625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.319444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.10625, 0.3], output: [0.0445900249975972]},
    {input: [0.1234375, 0.386111111111111], output: [0.0445900249975972]},
    {input: [0.1625, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.1796875, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.275, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.325, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.246875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.275, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.26875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.2921875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.1453125, 0.202777777777778], output: [0.0445900249975972]},
    {input: [0.0328125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.1, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.225], output: [0.0219499781965924]},
    {input: [0.0609375, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.1734375, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.2078125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2078125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.2125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.325, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.240625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.275], output: [0.0219499781965924]},
    {input: [0.0953125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.1453125, 0.325], output: [0.0219499781965924]},
    {input: [0.0953125, 0.341666666666667], output: [-0.000690068604412431]},
    {input: [0.2015625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.16875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2078125, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.2296875, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2015625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.33125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.275, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.353125, 0.233333333333333], output: [0.0445900249975972]},
    {input: [0.33125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3375, 0.333333333333333], output: [0.067230071798602]},
    {input: [0.240625, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.21875, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.2125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.1734375, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.078125, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.1515625, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.275, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.26875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.1796875, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.0609375, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.0671875, 0.569444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.128125, 0.411111111111111], output: [0.0219499781965924]},
    {input: [0.10625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.275], output: [0.0219499781965924]},
    {input: [0.10625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.0722222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.05], output: [0.0219499781965924]},
    {input: [0.134375, 0.0583333333333333], output: [0.0219499781965924]},
    {input: [0.2640625, 0.938888888888889], output: [0.0219499781965924]},
    {input: [0.296875, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.38125, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.365625, 0.930555555555556], output: [0.067230071798602]},
    {input: [0.421875, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.3875, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.33125, 0.925], output: [0.0898701185996068]},
    {input: [0.225, 0.947222222222222], output: [0.0898701185996068]},
    {input: [0.1171875, 0.966666666666667], output: [0.067230071798602]},
    {input: [0.05625, 0.991666666666667], output: [0.067230071798602]},
    {input: [0.028125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.021875, 0.191666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.377777777777778], output: [0.0445900249975972]},
    {input: [0.084375, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.0890625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.1125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.084375, 0.0805555555555556], output: [0.0219499781965924]},
    {input: [0.0328125, 0.75], output: [0.0219499781965924]},
    {input: [0.2921875, 0.00277777777777778], output: [0.0219499781965924]},
    {input: [0.45, 0.888888888888889], output: [0.0219499781965924]},
    {input: [0.365625, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.246875, 0.786111111111111], output: [0.067230071798602]},
    {input: [0.184375, 0.805555555555556], output: [0.0898701185996068]},
    {input: [0.1, 0.669444444444444], output: [0.067230071798602]},
    {input: [0.1734375, 0.663888888888889], output: [0.0898701185996068]},
    {input: [0.0390625, 0.969444444444444], output: [0.0898701185996068]},
    {input: [0.140625, 0.969444444444444], output: [0.067230071798602]},
    {input: [0.10625, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.305555555555556], output: [0.067230071798602]},
    {input: [0.26875, 0.291666666666667], output: [0.067230071798602]},
    {input: [0.309375, 0.3], output: [0.067230071798602]},
    {input: [0.296875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.253125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.184375, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.372222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.363888888888889], output: [0.0445900249975972]},
    {input: [0.1, 0.525], output: [0.0219499781965924]},
    {input: [0.084375, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.744444444444444], output: [0.0219499781965924]},
    {input: [0.534375, 0.861111111111111], output: [0.0219499781965924]},
    {input: [0.60625, 0.855555555555556], output: [0.0898701185996068]},
    {input: [0.5171875, 0.858333333333333], output: [0.135150212201616]},
    {input: [0.55625, 0.872222222222222], output: [0.135150212201616]},
    {input: [0.6515625, 0.866666666666667], output: [0.135150212201616]},
    {input: [0.6515625, 0.863888888888889], output: [0.157790259002621]},
    {input: [0.6859375, 0.880555555555556], output: [0.180430305803626]},
    {input: [0.646875, 0.872222222222222], output: [0.180430305803626]},
    {input: [0.584375, 0.886111111111111], output: [0.157790259002621]},
    {input: [0.5734375, 0.916666666666667], output: [0.157790259002621]},
    {input: [0.534375, 0.919444444444444], output: [0.135150212201616]},
    {input: [0.534375, 0.916666666666667], output: [0.135150212201616]},
    {input: [0.534375, 0.925], output: [0.112510165400612]},
    {input: [0.478125, 0.925], output: [0.112510165400612]},
    {input: [0.44375, 0.927777777777778], output: [0.112510165400612]},
    {input: [0.4265625, 0.919444444444444], output: [0.112510165400612]},
    {input: [0.365625, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.3140625, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.2921875, 0.897222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.916666666666667], output: [0.0445900249975972]},
    {input: [0.303125, 0.85], output: [0.0445900249975972]},
    {input: [0.3875, 0.844444444444444], output: [0.0445900249975972]},
    {input: [0.5171875, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.5734375, 0.863888888888889], output: [0.067230071798602]},
    {input: [0.6234375, 0.863888888888889], output: [0.112510165400612]},
    {input: [0.640625, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.6296875, 0.897222222222222], output: [0.157790259002621]},
    {input: [0.6859375, 0.902777777777778], output: [0.157790259002621]},
    {input: [0.6234375, 0.908333333333333], output: [0.157790259002621]},
    {input: [0.6515625, 0.916666666666667], output: [0.112510165400612]},
    {input: [0.6796875, 0.913888888888889], output: [0.135150212201616]},
    {input: [0.584375, 0.927777777777778], output: [0.135150212201616]},
    {input: [0.590625, 0.922222222222222], output: [0.135150212201616]},
    {input: [0.61875, 0.927777777777778], output: [0.135150212201616]},
    {input: [0.55, 0.930555555555556], output: [0.135150212201616]},
    {input: [0.5625, 0.922222222222222], output: [0.112510165400612]},
    {input: [0.5453125, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.50625, 0.911111111111111], output: [0.112510165400612]},
    {input: [0.4828125, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.45, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.2859375, 0.886111111111111], output: [0.067230071798602]},
    {input: [0.325, 0.891666666666667], output: [0.0445900249975972]},
    {input: [0.246875, 0.880555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.894444444444444], output: [0.0445900249975972]},
    {input: [0.2640625, 0.847222222222222], output: [0.0445900249975972]},
    {input: [0.2359375, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.947222222222222], output: [0.0219499781965924]},
    {input: [0.4375, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.471875, 0.9], output: [0.067230071798602]},
    {input: [0.5390625, 0.905555555555556], output: [0.0898701185996068]},
    {input: [0.5390625, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.4890625, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.5625, 0.913888888888889], output: [0.067230071798602]},
    {input: [0.5, 0.9], output: [0.067230071798602]},
    {input: [0.5171875, 0.933333333333333], output: [0.112510165400612]},
    {input: [0.5, 0.933333333333333], output: [0.112510165400612]},
    {input: [0.471875, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.39375, 0.952777777777778], output: [0.0898701185996068]},
    {input: [0.2578125, 0.966666666666667], output: [0.0898701185996068]},
    {input: [0.1734375, 0.969444444444444], output: [0.067230071798602]},
    {input: [0.1234375, 0.0166666666666667], output: [0.0898701185996068]},
    {input: [0.2015625, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.1734375, 0.922222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.791666666666667], output: [0.0219499781965924]},
    {input: [0.16875, 0.816666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.913888888888889], output: [0.0219499781965924]},
    {input: [0.140625, 0.480555555555556], output: [0.0219499781965924]},
    {input: [0.16875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.0111111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.415625, 0.897222222222222], output: [0.0445900249975972]},
    {input: [0.4328125, 0.919444444444444], output: [0.0445900249975972]},
    {input: [0.409375, 0.936111111111111], output: [0.067230071798602]},
    {input: [0.365625, 0.922222222222222], output: [0.067230071798602]},
    {input: [0.359375, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.359375, 0.913888888888889], output: [0.0898701185996068]},
    {input: [0.4328125, 0.919444444444444], output: [0.0898701185996068]},
    {input: [0.3484375, 0.938888888888889], output: [0.0898701185996068]},
    {input: [0.38125, 0.95], output: [0.0898701185996068]},
    {input: [0.275, 0.952777777777778], output: [0.0898701185996068]},
    {input: [0.240625, 0.944444444444444], output: [0.0898701185996068]},
    {input: [0.10625, 0.977777777777778], output: [0.067230071798602]},
    {input: [0.028125, 0.741666666666667], output: [0.0445900249975972]},
    {input: [0.0328125, 0.0194444444444444], output: [0.0445900249975972]},
    {input: [0.078125, 0.430555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.1, 0.561111111111111], output: [0.0219499781965924]},
    {input: [0.1171875, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.084375, 0.55], output: [0.0219499781965924]},
    {input: [0.2078125, 0.105555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.21875, 0.925], output: [0.0219499781965924]},
    {input: [0.415625, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.45, 0.861111111111111], output: [0.0445900249975972]},
    {input: [0.39375, 0.905555555555556], output: [0.0445900249975972]},
    {input: [0.3421875, 0.922222222222222], output: [0.112510165400612]},
    {input: [0.421875, 0.930555555555556], output: [0.0898701185996068]},
    {input: [0.33125, 0.891666666666667], output: [0.0898701185996068]},
    {input: [0.353125, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.3421875, 0.911111111111111], output: [0.0898701185996068]},
    {input: [0.3375, 0.936111111111111], output: [0.0898701185996068]},
    {input: [0.2578125, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.196875, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.0890625, 0.869444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.04375, 0.0666666666666667], output: [0.0445900249975972]},
    {input: [0.05, 0.119444444444444], output: [0.0219499781965924]},
    {input: [0.078125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.134375, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.1453125, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.25], output: [0.0219499781965924]},
    {input: [0.309375, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.409375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.296875, 0.275], output: [0.067230071798602]},
    {input: [0.253125, 0.286111111111111], output: [0.0445900249975972]},
    {input: [0.1515625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2078125, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.2015625, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.2125, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.325, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.325, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.246875, 0.35], output: [0.0445900249975972]},
    {input: [0.2859375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.2859375, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.2859375, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.3375, 0.336111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.2640625, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.415625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.4375, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.4046875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.415625, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.353125, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.3140625, 0.313888888888889], output: [0.0898701185996068]},
    {input: [0.2296875, 0.383333333333333], output: [0.0898701185996068]},
    {input: [0.38125, 0.341666666666667], output: [0.067230071798602]},
    {input: [0.296875, 0.363888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.369444444444444], output: [0.067230071798602]},
    {input: [0.240625, 0.347222222222222], output: [0.067230071798602]},
    {input: [0.3140625, 0.330555555555556], output: [0.0445900249975972]},
    {input: [0.3375, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.38125, 0.347222222222222], output: [0.0445900249975972]},
    {input: [0.353125, 0.35], output: [0.067230071798602]},
    {input: [0.365625, 0.338888888888889], output: [0.067230071798602]},
    {input: [0.296875, 0.316666666666667], output: [0.067230071798602]},
    {input: [0.225, 0.35], output: [0.067230071798602]},
    {input: [0.2078125, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.303125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.309375, 0.3], output: [0.0219499781965924]},
    {input: [0.303125, 0.3], output: [0.0219499781965924]},
    {input: [0.246875, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.21875, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.240625, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.3203125, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.353125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.3203125, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.2921875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.303125, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.26875, 0.275], output: [0.067230071798602]},
    {input: [0.253125, 0.3], output: [0.0445900249975972]},
    {input: [0.240625, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.246875, 0.35], output: [0.0445900249975972]},
    {input: [0.3421875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.309375, 0.325], output: [0.0898701185996068]},
    {input: [0.353125, 0.325], output: [0.067230071798602]},
    {input: [0.4375, 0.319444444444444], output: [0.067230071798602]},
    {input: [0.465625, 0.327777777777778], output: [0.067230071798602]},
    {input: [0.49375, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.44375, 0.286111111111111], output: [0.067230071798602]},
    {input: [0.44375, 0.272222222222222], output: [0.0898701185996068]},
    {input: [0.415625, 0.266666666666667], output: [0.0898701185996068]},
    {input: [0.365625, 0.277777777777778], output: [0.067230071798602]},
    {input: [0.3703125, 0.275], output: [0.067230071798602]},
    {input: [0.365625, 0.258333333333333], output: [0.067230071798602]},
    {input: [0.3484375, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.325, 0.25], output: [0.067230071798602]},
    {input: [0.26875, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.253125, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.2578125, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.240625, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2921875, 0.275], output: [0.0219499781965924]},
    {input: [0.190625, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.134375, 0.275], output: [0.0445900249975972]},
    {input: [0.1515625, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.2078125, 0.291666666666667], output: [0.0445900249975972]},
    {input: [0.1734375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.275, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.275, 0.3], output: [0.0445900249975972]},
    {input: [0.3140625, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.296875, 0.294444444444444], output: [0.0445900249975972]},
    {input: [0.21875, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2578125, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.2359375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2078125, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.275], output: [0.0219499781965924]},
    {input: [0.2078125, 0.241666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2578125, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.21875, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.1734375, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.10625, 0.4], output: [0.0219499781965924]},
    {input: [0.078125, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.2078125, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.2359375, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.26875, 0.327777777777778], output: [0.0445900249975972]},
    {input: [0.253125, 0.3], output: [0.0219499781965924]},
    {input: [0.2296875, 0.352777777777778], output: [0.0445900249975972]},
    {input: [0.2578125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.296875, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.309375, 0.252777777777778], output: [0.0445900249975972]},
    {input: [0.365625, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.3703125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.365625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.3484375, 0.311111111111111], output: [0.067230071798602]},
    {input: [0.3703125, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.2859375, 0.275], output: [0.067230071798602]},
    {input: [0.246875, 0.294444444444444], output: [0.067230071798602]},
    {input: [0.2859375, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.2921875, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.1625, 0.325], output: [0.0445900249975972]},
    {input: [0.028125, 0.25], output: [0.0445900249975972]},
    {input: [0.1234375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.1453125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.15625, 0.325], output: [0.0219499781965924]},
    {input: [0.1515625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.16875, 0.3], output: [0.0219499781965924]},
    {input: [0.2125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.330555555555556], output: [0.0219499781965924]},
    {input: [0.184375, 0.3], output: [0.0219499781965924]},
    {input: [0.2015625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.15625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.244444444444444], output: [0.0219499781965924]},
    {input: [0.225, 0.272222222222222], output: [0.0219499781965924]},
    {input: [0.2015625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.2078125, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.0890625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.190625, 0.3], output: [0.0219499781965924]},
    {input: [0.2359375, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.1125, 0.2], output: [0.0219499781965924]},
    {input: [0.071875, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.213888888888889], output: [0.0219499781965924]},
    {input: [0.128125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.078125, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.363888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0953125, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.0671875, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.1, 0.175], output: [0.0219499781965924]},
    {input: [0.10625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.45], output: [0.0219499781965924]},
    {input: [0.16875, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.286111111111111], output: [-0.000690068604412431]},
    {input: [0.1796875, 0.25], output: [0.0219499781965924]},
    {input: [0.240625, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.21875, 0.297222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.128125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.172222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.302777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.4], output: [0.0219499781965924]},
    {input: [0.0890625, 0.4], output: [0.0219499781965924]},
    {input: [0.1, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.1515625, 0.375], output: [0.0219499781965924]},
    {input: [0.1234375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.2078125, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.2359375, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.225, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.283333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.419444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.2859375, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.361111111111111], output: [0.0219499781965924]},
    {input: [0.071875, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.2296875, 0.797222222222222], output: [0.0219499781965924]},
    {input: [0.190625, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.886111111111111], output: [0.0219499781965924]},
    {input: [0.1234375, 0.00555555555555556], output: [0.0219499781965924]},
    {input: [0.078125, 0.130555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.0472222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.015625, 0.216666666666667], output: [0.0219499781965924]},
    {input: [0.1796875, 0.375], output: [0.0219499781965924]},
    {input: [0.1796875, 0.35], output: [0.0445900249975972]},
    {input: [0.1515625, 0.355555555555556], output: [0.0445900249975972]},
    {input: [0.2078125, 0.341666666666667], output: [0.0445900249975972]},
    {input: [0.1234375, 0.3], output: [0.0219499781965924]},
    {input: [0.184375, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.1515625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.761111111111111], output: [0.0219499781965924]},
    {input: [0.0890625, 0.919444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.722222222222222], output: [0.0219499781965924]},
    {input: [0.296875, 0.730555555555556], output: [0.0219499781965924]},
    {input: [0.140625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.705555555555556], output: [0.0219499781965924]},
    {input: [0.225, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.2015625, 0.586111111111111], output: [0.0219499781965924]},
    {input: [0.190625, 0.627777777777778], output: [0.0219499781965924]},
    {input: [0.2296875, 0.605555555555556], output: [0.0219499781965924]},
    {input: [0.275, 0.611111111111111], output: [0.0445900249975972]},
    {input: [0.3421875, 0.613888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.26875, 0.677777777777778], output: [0.0445900249975972]},
    {input: [0.128125, 0.616666666666667], output: [0.0219499781965924]},
    {input: [0.2578125, 0.558333333333333], output: [0.0219499781965924]},
    {input: [0.1734375, 0.694444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.253125, 0.630555555555556], output: [0.0445900249975972]},
    {input: [0.134375, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.325, 0.988888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.977777777777778], output: [0.0445900249975972]},
    {input: [0.0890625, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.0671875, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.015625, 0.444444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.777777777777778], output: [0.0219499781965924]},
    {input: [0.134375, 0.794444444444444], output: [0.0219499781965924]},
    {input: [0.196875, 0.763888888888889], output: [0.0219499781965924]},
    {input: [0.1625, 0.788888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.1625, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.2078125, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.841666666666667], output: [0.0219499781965924]},
    {input: [0.253125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.147222222222222], output: [0.0445900249975972]},
    {input: [0.140625, 0.275], output: [0.0445900249975972]},
    {input: [0.078125, 0.122222222222222], output: [0.0219499781965924]},
    {input: [0.1171875, 0.325], output: [0.0219499781965924]},
    {input: [0.0671875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.028125, 0.538888888888889], output: [0.0219499781965924]},
    {input: [0.0328125, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.021875, 0.622222222222222], output: [0.0219499781965924]},
    {input: [0.015625, 0.672222222222222], output: [0.0219499781965924]},
    {input: [0.05, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.608333333333333], output: [0.0219499781965924]},
    {input: [0.1171875, 0.177777777777778], output: [0.0219499781965924]},
    {input: [0.084375, 0.15], output: [-0.000690068604412431]},
    {input: [0.05625, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.1234375, 0.580555555555556], output: [0.0219499781965924]},
    {input: [0.0609375, 0.527777777777778], output: [0.0219499781965924]},
    {input: [0.140625, 0.911111111111111], output: [0.0219499781965924]},
    {input: [0.1453125, 0.883333333333333], output: [0.0219499781965924]},
    {input: [0.2859375, 0.875], output: [0.0219499781965924]},
    {input: [0.246875, 0.994444444444444], output: [0.0219499781965924]},
    {input: [0.4046875, 0.95], output: [0.0219499781965924]},
    {input: [0.421875, 0.941666666666667], output: [0.0445900249975972]},
    {input: [0.409375, 0.95], output: [0.067230071798602]},
    {input: [0.3765625, 0.95], output: [0.0898701185996068]},
    {input: [0.309375, 0.941666666666667], output: [0.067230071798602]},
    {input: [0.2359375, 0.969444444444444], output: [0.067230071798602]},
    {input: [0.16875, 0.944444444444444], output: [0.067230071798602]},
    {input: [0.134375, 0.919444444444444], output: [0.067230071798602]},
    {input: [0.05, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.028125, 0.872222222222222], output: [0.0445900249975972]},
    {input: [0.05, 0.730555555555556], output: [0.0445900249975972]},
    {input: [0.0671875, 0.755555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.683333333333333], output: [0.0219499781965924]},
    {input: [0.0609375, 0.575], output: [0.0219499781965924]},
    {input: [0.134375, 0.508333333333333], output: [0.0219499781965924]},
    {input: [0.04375, 0.536111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.180555555555556], output: [0.0219499781965924]},
    {input: [0.1796875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.1, 0.902777777777778], output: [0.0219499781965924]},
    {input: [0.05625, 0.0972222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2125, 0.844444444444444], output: [0.0219499781965924]},
    {input: [0.2921875, 0.936111111111111], output: [-0.000690068604412431]},
    {input: [0.1625, 0.0916666666666667], output: [0.0445900249975972]},
    {input: [0.071875, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.0890625, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.325], output: [0.0219499781965924]},
    {input: [0.184375, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.10625, 0.375], output: [0.0219499781965924]},
    {input: [0.1, 0.391666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.253125, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.246875, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.2921875, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.359375, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.26875, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.3375, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.3375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.353125, 0.255555555555556], output: [0.067230071798602]},
    {input: [0.365625, 0.244444444444444], output: [0.067230071798602]},
    {input: [0.353125, 0.238888888888889], output: [0.067230071798602]},
    {input: [0.240625, 0.269444444444444], output: [0.067230071798602]},
    {input: [0.28125, 0.283333333333333], output: [0.0445900249975972]},
    {input: [0.2015625, 0.266666666666667], output: [0.0445900249975972]},
    {input: [0.296875, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.190625, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.325, 0.225], output: [0.0445900249975972]},
    {input: [0.2859375, 0.252777777777778], output: [0.067230071798602]},
    {input: [0.309375, 0.261111111111111], output: [0.0445900249975972]},
    {input: [0.303125, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.240625, 0.302777777777778], output: [0.0445900249975972]},
    {input: [0.275, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.26875, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.240625, 0.316666666666667], output: [0.0445900249975972]},
    {input: [0.275, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2359375, 0.255555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2859375, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.269444444444444], output: [0.0219499781965924]},
    {input: [0.28125, 0.275], output: [0.0445900249975972]},
    {input: [0.2296875, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.297222222222222], output: [0.0445900249975972]},
    {input: [0.275, 0.277777777777778], output: [0.0445900249975972]},
    {input: [0.2640625, 0.336111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.313888888888889], output: [0.067230071798602]},
    {input: [0.39375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.2578125, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.3375, 0.288888888888889], output: [0.067230071798602]},
    {input: [0.303125, 0.266666666666667], output: [0.067230071798602]},
    {input: [0.38125, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.359375, 0.272222222222222], output: [0.067230071798602]},
    {input: [0.225, 0.358333333333333], output: [0.067230071798602]},
    {input: [0.3703125, 0.352777777777778], output: [0.067230071798602]},
    {input: [0.409375, 0.361111111111111], output: [0.067230071798602]},
    {input: [0.2640625, 0.372222222222222], output: [0.067230071798602]},
    {input: [0.2640625, 0.366666666666667], output: [0.067230071798602]},
    {input: [0.253125, 0.275], output: [0.0445900249975972]},
    {input: [0.2859375, 0.305555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.338888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.386111111111111], output: [0.0219499781965924]},
    {input: [0.246875, 0.4], output: [0.0219499781965924]},
    {input: [0.1796875, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.388888888888889], output: [0.0219499781965924]},
    {input: [0.2296875, 0.602777777777778], output: [0.0219499781965924]},
    {input: [0.1796875, 0.95], output: [0.0219499781965924]},
    {input: [0.2015625, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.2015625, 0.333333333333333], output: [0.0219499781965924]},
    {input: [0.140625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.375], output: [0.0219499781965924]},
    {input: [0.16875, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.1515625, 0.925], output: [0.0219499781965924]},
    {input: [0.084375, 0.544444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.522222222222222], output: [0.0219499781965924]},
    {input: [0.128125, 0.222222222222222], output: [0.0219499781965924]},
    {input: [0.28125, 0.469444444444444], output: [0.0445900249975972]},
    {input: [0.39375, 0.916666666666667], output: [0.067230071798602]},
    {input: [0.3484375, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.240625, 0.980555555555556], output: [0.067230071798602]},
    {input: [0.2296875, 0.991666666666667], output: [0.067230071798602]},
    {input: [0.2359375, 0.947222222222222], output: [0.067230071798602]},
    {input: [0.1796875, 0.972222222222222], output: [0.067230071798602]},
    {input: [0.15625, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.359375, 0.922222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.886111111111111], output: [0.0445900249975972]},
    {input: [0.4046875, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.38125, 0.916666666666667], output: [0.0898701185996068]},
    {input: [0.2859375, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.0328125, 0.169444444444444], output: [0.0445900249975972]},
    {input: [0.0390625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.05625, 0.511111111111111], output: [0.0219499781965924]},
    {input: [0.253125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.0671875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.15625, 0.380555555555556], output: [0.0219499781965924]},
    {input: [0.15625, 0.333333333333333], output: [0.0445900249975972]},
    {input: [0.134375, 0.725], output: [0.0445900249975972]},
    {input: [0.3875, 0.283333333333333], output: [0.067230071798602]},
    {input: [0.325, 0.263888888888889], output: [0.067230071798602]},
    {input: [0.196875, 0.669444444444444], output: [-0.000690068604412431]},
    {input: [0.028125, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.0953125, 0.372222222222222], output: [0.0219499781965924]},
    {input: [0.071875, 0.552777777777778], output: [0.0219499781965924]},
    {input: [0.071875, 0.530555555555556], output: [0.0219499781965924]},
    {input: [0.05625, 0.894444444444444], output: [-0.000690068604412431]},
    {input: [0.1625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.1796875, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.21875, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.1234375, 0.263888888888889], output: [0.0219499781965924]},
    {input: [0.0609375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.869444444444444], output: [0.0219499781965924]},
    {input: [0.071875, 0.944444444444444], output: [0.0219499781965924]},
    {input: [0.1171875, 0.811111111111111], output: [0.0219499781965924]},
    {input: [0.303125, 0.872222222222222], output: [0.0219499781965924]},
    {input: [0.421875, 0.894444444444444], output: [0.0219499781965924]},
    {input: [0.240625, 0.844444444444444], output: [0.0898701185996068]},
    {input: [0.2125, 0.841666666666667], output: [0.067230071798602]},
    {input: [0.196875, 0.838888888888889], output: [0.067230071798602]},
    {input: [0.225, 0.875], output: [0.0445900249975972]},
    {input: [0.2578125, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.196875, 0.858333333333333], output: [0.0445900249975972]},
    {input: [0.184375, 0.855555555555556], output: [0.0219499781965924]},
    {input: [0.21875, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.26875, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.2015625, 0.930555555555556], output: [0.0219499781965924]},
    {input: [0.071875, 0.863888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.819444444444444], output: [0.0219499781965924]},
    {input: [0.10625, 0.85], output: [0.0219499781965924]},
    {input: [0.021875, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.028125, 0.805555555555556], output: [0.0219499781965924]},
    {input: [0.04375, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.05, 0.572222222222222], output: [0.0219499781965924]},
    {input: [0.084375, 0.475], output: [0.0219499781965924]},
    {input: [0.134375, 0.183333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.04375, 0.461111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.2], output: [0.0219499781965924]},
    {input: [0.15625, 0.183333333333333], output: [0.0445900249975972]},
    {input: [0.05625, 0.375], output: [0.0219499781965924]},
    {input: [0.05625, 0.519444444444444], output: [0.0219499781965924]},
    {input: [0.1, 0.5], output: [0.0219499781965924]},
    {input: [0.0890625, 0.430555555555556], output: [0.0219499781965924]},
    {input: [0.084375, 0.422222222222222], output: [0.0219499781965924]},
    {input: [0.05625, 0.3], output: [0.0219499781965924]},
    {input: [0.128125, 0.691666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.3], output: [0.0219499781965924]},
    {input: [0.10625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.1625, 0.325], output: [0.0219499781965924]},
    {input: [0.0953125, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.184375, 0.247222222222222], output: [0.0219499781965924]},
    {input: [0.1234375, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.2015625, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.196875, 0.25], output: [0.0445900249975972]},
    {input: [0.0953125, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1234375, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.10625, 0.4], output: [0.0219499781965924]},
    {input: [0.1734375, 0.394444444444444], output: [0.0219499781965924]},
    {input: [0.128125, 0.425], output: [0.0219499781965924]},
    {input: [0.1515625, 0.397222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.383333333333333], output: [0.0219499781965924]},
    {input: [0.05, 0.488888888888889], output: [0.0219499781965924]},
    {input: [0.16875, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.1625, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.225, 0.325], output: [0.0219499781965924]},
    {input: [0.275, 0.358333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.28125, 0.252777777777778], output: [0.0219499781965924]},
    {input: [0.1515625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.28125, 0.219444444444444], output: [0.0219499781965924]},
    {input: [0.2296875, 0.280555555555556], output: [0.0445900249975972]},
    {input: [0.253125, 0.288888888888889], output: [0.0445900249975972]},
    {input: [0.253125, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.0890625, 0.280555555555556], output: [0.0219499781965924]},
    {input: [0.1515625, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.15625, 0.294444444444444], output: [0.0219499781965924]},
    {input: [0.140625, 0.369444444444444], output: [0.0219499781965924]},
    {input: [0.084375, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.21875, 0.205555555555556], output: [0.0219499781965924]},
    {input: [0.1453125, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.1625, 0.338888888888889], output: [0.0219499781965924]},
    {input: [0.084375, 0.355555555555556], output: [0.0219499781965924]},
    {input: [0.1125, 0.325], output: [0.0219499781965924]},
    {input: [0.21875, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.3140625, 0.305555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.2578125, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.296875, 0.25], output: [0.0219499781965924]},
    {input: [0.26875, 0.236111111111111], output: [0.0445900249975972]},
    {input: [0.1734375, 0.25], output: [0.0445900249975972]},
    {input: [0.190625, 0.325], output: [0.0445900249975972]},
    {input: [0.05, 0.0833333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.197222222222222], output: [0.0219499781965924]},
    {input: [0.140625, 0.125], output: [0.0219499781965924]},
    {input: [0.184375, 0.169444444444444], output: [0.0219499781965924]},
    {input: [0.1125, 0.441666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.905555555555556], output: [0.0219499781965924]},
    {input: [0.1171875, 0.0222222222222222], output: [0.0219499781965924]},
    {input: [0.1625, 0.0166666666666667], output: [0.0219499781965924]},
    {input: [0.071875, 0.0361111111111111], output: [0.0219499781965924]},
    {input: [0.04375, 0.286111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.45], output: [0.0219499781965924]},
    {input: [0.05, 0.436111111111111], output: [0.0219499781965924]},
    {input: [0.0953125, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.1453125, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.2359375, 0.316666666666667], output: [0.0219499781965924]},
    {input: [0.246875, 0.352777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.405555555555556], output: [0.0219499781965924]},
    {input: [0.28125, 0.233333333333333], output: [0.0219499781965924]},
    {input: [0.309375, 0.255555555555556], output: [0.0219499781965924]},
    {input: [0.3203125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.3484375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.247222222222222], output: [0.0445900249975972]},
    {input: [0.2859375, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.309375, 0.280555555555556], output: [0.067230071798602]},
    {input: [0.303125, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.28125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.309375, 0.244444444444444], output: [0.0445900249975972]},
    {input: [0.2578125, 0.263888888888889], output: [0.0445900249975972]},
    {input: [0.184375, 0.313888888888889], output: [0.0445900249975972]},
    {input: [0.2359375, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.21875, 0.236111111111111], output: [0.0219499781965924]},
    {input: [0.240625, 0.3], output: [0.0219499781965924]},
    {input: [0.225, 0.266666666666667], output: [0.0219499781965924]},
    {input: [0.240625, 0.275], output: [0.0219499781965924]},
    {input: [0.240625, 0.269444444444444], output: [0.0445900249975972]},
    {input: [0.2296875, 0.272222222222222], output: [0.0445900249975972]},
    {input: [0.15625, 0.308333333333333], output: [0.0445900249975972]},
    {input: [0.1234375, 0.858333333333333], output: [0.0219499781965924]},
    {input: [0.225, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.240625, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.3140625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.240625, 0.9], output: [0.0219499781965924]},
    {input: [0.16875, 0.830555555555556], output: [0.0445900249975972]},
    {input: [0.10625, 0.758333333333333], output: [0.0445900249975972]},
    {input: [0.078125, 0.65], output: [0.0219499781965924]},
    {input: [0.1125, 0.636111111111111], output: [0.0445900249975972]},
    {input: [0.1453125, 0.680555555555556], output: [0.0219499781965924]},
    {input: [0.134375, 0.366666666666667], output: [0.0219499781965924]},
    {input: [0.303125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.303125, 0.341666666666667], output: [0.0219499781965924]},
    {input: [0.3421875, 0.4], output: [0.0219499781965924]},
    {input: [0.3140625, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.471875, 0.452777777777778], output: [0.0445900249975972]},
    {input: [0.5, 0.466666666666667], output: [0.067230071798602]},
    {input: [0.465625, 0.436111111111111], output: [0.0898701185996068]},
    {input: [0.3421875, 0.441666666666667], output: [0.0898701185996068]},
    {input: [0.246875, 0.319444444444444], output: [0.0898701185996068]},
    {input: [0.246875, 0.4], output: [0.067230071798602]},
    {input: [0.4265625, 0.413888888888889], output: [0.0445900249975972]},
    {input: [0.38125, 0.386111111111111], output: [0.067230071798602]},
    {input: [0.309375, 0.630555555555556], output: [0.067230071798602]},
    {input: [0.2359375, 0.702777777777778], output: [0.0445900249975972]},
    {input: [0.2296875, 0.711111111111111], output: [0.0445900249975972]},
    {input: [0.3140625, 0.816666666666667], output: [0.0445900249975972]},
    {input: [0.359375, 0.836111111111111], output: [0.0219499781965924]},
    {input: [0.2640625, 0.863888888888889], output: [0.0445900249975972]},
    {input: [0.190625, 0.727777777777778], output: [0.0445900249975972]},
    {input: [0.028125, 0.611111111111111], output: [0.0219499781965924]},
    {input: [0.078125, 0.525], output: [0.0219499781965924]},
    {input: [0.1234375, 0.575], output: [0.0219499781965924]},
    {input: [0.128125, 0.6], output: [0.0445900249975972]},
    {input: [0.1171875, 0.444444444444444], output: [0.0445900249975972]},
    {input: [0.1734375, 0.230555555555556], output: [0.0219499781965924]},
    {input: [0.2578125, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.246875, 0.258333333333333], output: [0.0445900249975972]},
    {input: [0.246875, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.225, 0.313888888888889], output: [0.0219499781965924]},
    {input: [0.190625, 0.322222222222222], output: [0.0219499781965924]},
    {input: [0.184375, 0.319444444444444], output: [0.0219499781965924]},
    {input: [0.15625, 0.35], output: [0.0219499781965924]},
    {input: [0.1515625, 0.413888888888889], output: [0.0219499781965924]},
    {input: [0.078125, 0.463888888888889], output: [0.0219499781965924]},
    {input: [0.0390625, 0.475], output: [0.0219499781965924]},
    {input: [0.04375, 0.147222222222222], output: [0.0219499781965924]},
    {input: [0.0390625, 0.211111111111111], output: [0.0219499781965924]},
    {input: [0.028125, 0.161111111111111], output: [0.0219499781965924]},
    {input: [0.134375, 0.258333333333333], output: [0.0219499781965924]},
    {input: [0.1515625, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.071875, 0.344444444444444], output: [0.0219499781965924]},
    {input: [0.0609375, 0.208333333333333], output: [0.0219499781965924]},
    {input: [0.1, 0.227777777777778], output: [-0.000690068604412431]},
    {input: [0.078125, 0.138888888888889], output: [0.0219499781965924]},
    {input: [0.1796875, 0.202777777777778], output: [-0.000690068604412431]},
    {input: [0.0953125, 0.225], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.225], output: [-0.000690068604412431]},
    {input: [0.1453125, 0.261111111111111], output: [0.0219499781965924]},
    {input: [0.140625, 0.277777777777778], output: [0.0219499781965924]},
    {input: [0.1171875, 0.308333333333333], output: [0.0219499781965924]},
    {input: [0.190625, 0.325], output: [0.0219499781965924]},
    {input: [0.190625, 0.311111111111111], output: [0.0219499781965924]},
    {input: [0.196875, 0.325], output: [0.0219499781965924]},
    {input: [0.2015625, 0.327777777777778], output: [0.0219499781965924]},
    {input: [0.15625, 0.291666666666667], output: [0.0219499781965924]},
    {input: [0.140625, 0.288888888888889], output: [0.0219499781965924]},
    {input: [0.1125, 0.347222222222222], output: [0.0219499781965924]},
    {input: [0.078125, 0.336111111111111], output: [0.0219499781965924]},
    {input: [0.0609375, 0.416666666666667], output: [0.0219499781965924]},
    {input: [0.26875, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.325, 0.880555555555556], output: [0.0445900249975972]},
    {input: [0.2640625, 0.875], output: [0.0445900249975972]},
    {input: [0.196875, 0.794444444444444], output: [0.067230071798602]},
    {input: [0.128125, 0.758333333333333], output: [0.067230071798602]},
    {input: [0.225, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.1734375, 0.775], output: [0.067230071798602]},
    {input: [0.3203125, 0.902777777777778], output: [0.0445900249975972]},
    {input: [0.2125, 0.0361111111111111], output: [0.067230071798602]},
    {input: [0.078125, 0.461111111111111], output: [0.0445900249975972]},
    {input: [0.028125, 0.791666666666667], output: [0.0445900249975972]},
    {input: [0.0890625, 0.944444444444444], output: [0.0445900249975972]},
    {input: [0.084375, 0.497222222222222], output: [0.0445900249975972]},
    {input: [0.16875, 0.883333333333333], output: [0.0445900249975972]},
    {input: [0.04375, 0.547222222222222], output: [0.0445900249975972]},
    {input: [0.1234375, 0.811111111111111], output: [0.0445900249975972]},
    {input: [0.1, 0.933333333333333], output: [0.0219499781965924]},
    {input: [0.4890625, 0.877777777777778], output: [0.0445900249975972]},
    {input: [0.5734375, 0.880555555555556], output: [0.067230071798602]},
    {input: [0.49375, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.5390625, 0.888888888888889], output: [0.112510165400612]},
    {input: [0.5734375, 0.886111111111111], output: [0.112510165400612]},
    {input: [0.61875, 0.9], output: [0.157790259002621]},
    {input: [0.521875, 0.902777777777778], output: [0.135150212201616]},
    {input: [0.5, 0.888888888888889], output: [0.157790259002621]},
    {input: [0.521875, 0.913888888888889], output: [0.135150212201616]},
    {input: [0.534375, 0.911111111111111], output: [0.135150212201616]},
    {input: [0.55625, 0.902777777777778], output: [0.135150212201616]},
    {input: [0.578125, 0.894444444444444], output: [0.157790259002621]},
    {input: [0.528125, 0.861111111111111], output: [0.157790259002621]},
    {input: [0.415625, 0.833333333333333], output: [0.135150212201616]},
    {input: [0.4609375, 0.825], output: [0.112510165400612]},
    {input: [0.3703125, 0.833333333333333], output: [0.112510165400612]},
    {input: [0.4890625, 0.886111111111111], output: [0.112510165400612]},
    {input: [0.5671875, 0.877777777777778], output: [0.135150212201616]},
    {input: [0.55, 0.877777777777778], output: [0.135150212201616]},
    {input: [0.534375, 0.894444444444444], output: [0.135150212201616]},
    {input: [0.528125, 0.886111111111111], output: [0.135150212201616]},
    {input: [0.5, 0.883333333333333], output: [0.135150212201616]},
    {input: [0.49375, 0.894444444444444], output: [0.112510165400612]},
    {input: [0.471875, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.5390625, 0.9], output: [0.112510165400612]},
    {input: [0.5, 0.877777777777778], output: [0.112510165400612]},
    {input: [0.4890625, 0.891666666666667], output: [0.112510165400612]},
    {input: [0.4609375, 0.919444444444444], output: [0.112510165400612]},
    {input: [0.465625, 0.902777777777778], output: [0.112510165400612]},
    {input: [0.44375, 0.886111111111111], output: [0.0898701185996068]},
    {input: [0.49375, 0.855555555555556], output: [0.135150212201616]},
    {input: [0.5, 0.863888888888889], output: [0.112510165400612]},
    {input: [0.5453125, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.528125, 0.794444444444444], output: [0.0898701185996068]},
    {input: [0.303125, 0.783333333333333], output: [0.0898701185996068]},
    {input: [0.471875, 0.788888888888889], output: [0.0898701185996068]},
    {input: [0.3765625, 0.85], output: [0.0898701185996068]},
    {input: [0.45, 0.936111111111111], output: [0.0898701185996068]},
    {input: [0.38125, 0.938888888888889], output: [0.067230071798602]},
    {input: [0.134375, 0.344444444444444], output: [0.0445900249975972]},
    {input: [0.2125, 0.847222222222222], output: [0.0219499781965924]},
    {input: [0.1734375, 0.913888888888889], output: [0.0445900249975972]},
    {input: [0.246875, 0.9], output: [0.0445900249975972]},
    {input: [0.3484375, 0.911111111111111], output: [0.0445900249975972]},
    {input: [0.4046875, 0.933333333333333], output: [0.067230071798602]},
    {input: [0.1734375, 0.927777777777778], output: [0.0445900249975972]},
    {input: [0.50625, 0.927777777777778], output: [0.0219499781965924]},
    {input: [0.5109375, 0.888888888888889], output: [0.067230071798602]},
    {input: [0.4265625, 0.844444444444444], output: [0.067230071798602]},
    {input: [0.4046875, 0.825], output: [0.0898701185996068]},
    {input: [0.55625, 0.875], output: [0.135150212201616]},
    {input: [0.5953125, 0.883333333333333], output: [0.157790259002621]},
  ]