import React from 'react';

const HistoryWindow = ({ closeHistoryPage, selectedMarker, userSettingsObject, convertUnitsUserSettings }) => (

<div className="history-page">
    <span className='history-close-btn' onClick={closeHistoryPage}>x</span>
    <h3>{selectedMarker.name}</h3>
    <table>
        <thead>
            <tr>
            <th>Wind <br /> Direction</th>
            <th>Wind <br /> Speed</th>
            {selectedMarker.waveHeight && <th>Wave <br /> Height</th>}
            <th>Time</th>
            </tr>
        </thead>
        <tbody>
            {selectedMarker.windDirection[0].map((direction, index) => (
            <tr key={index}>
                <td>{direction}</td>
                <td>{convertUnitsUserSettings(selectedMarker.windSpeed[0][index], userSettingsObject.wind_speed)}</td>
                {selectedMarker.waveHeight && <td>{convertUnitsUserSettings(selectedMarker.waveHeight[0][index], userSettingsObject.wave_height)}</td>}
                <td>{selectedMarker.updateTime[0][index]}</td>
            </tr>
            ))}
        </tbody>
    </table>
</div>

)

export default HistoryWindow;