import React from 'react';

const TableFilterForm = ({ column }) => {
    const { filterValue, setFilter } = column;
    return (
        <span>
            <input
                value={filterValue || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder='search...'
            />
        </span>
    );
};

export default TableFilterForm;