// bell buoy - red marker off pt grey .. maybe spanish bank
// t10
// sandheads
// hole in the wall
// Waterfall
// entrance island
// defence islands
// thrasher rock
// five finger island
// the hump vancouver
// the hump CR
// tunstall

const bellBuoy = {
    name: 'Bell Buoy',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.281949, lng: -123.271577
    }
}

const t10 = {
    name: 'T10',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.178258, lng: -123.291197
    }
}

const sandHeads = {
    name: 'Sand Heads',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.105063, lng: -123.305525
    }
}

const holeInTheWall = {
    name: 'Hole in the Wall',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.386872, lng: -123.262489
    }
}

const waterfall = {
    name: 'Waterfall',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.409628, lng: -123.367210
    }
}

const entranceIsland = {
    name: 'Entrance Island',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.207487, lng: -123.810321
    }
}

const defenceIslands = {
    name: 'Defence Islands',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.575324, lng: -123.284696
    }
}

const thrasherRock = {
    name: 'Thrasher Rock',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon', 'lingcod', 'Prawn'],
    coordinates: {
        lat: 49.155072, lng: -123.662280
    }
}

const fiveFingerIsland = {
    name: 'Five Finger Island',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon', 'Lingcod'],
    coordinates: {
        lat: 49.230059, lng: -123.915370
    }
}

const theHumpVancouver = {
    name: 'The Hump',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.287062, lng: -123.430056
    }
}

const tunstallBay = {
    name: 'Tunstall Bay',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.352996, lng: -123.429932
    }
}

const graceIslands = {
    name: 'Grace Islands',
    type: 'fishingHotSpot',
    speciesArray: ['Salmon'],
    coordinates: {
        lat: 49.429910, lng: -123.443796
    }
}

const constanceBank = {
    name: 'Constance Bank',
    type: 'fishingHotSpot',
    speciesArray: ['Halibut'],
    coordinates: {
        lat: 48.362337, lng: -123.364635
    }
}

export const fishingHotSpotsArray = [
    bellBuoy,
    t10,
    sandHeads,
    holeInTheWall,
    waterfall,
    entranceIsland,
    defenceIslands,
    thrasherRock,
    fiveFingerIsland,
    theHumpVancouver,
    tunstallBay,
    graceIslands,
    constanceBank
]