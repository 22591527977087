

const GreenButton = ({ onClick, btnText, className }) => {
    return (
    <button
        className={`${className} green-btn`}
        type='button'
        onClick={onClick}
    >
    {btnText}
  </button>
    )
}

export default GreenButton;