

const SignUpLoginBtn = ({className, btnText, onBtnClick}) => {
    return (
        <div>
            <button className={`${className} signUp-login-btn`} onClick={onBtnClick}>{btnText}</button>
        </div>
    );
}

export default SignUpLoginBtn;