import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import GreyButton from './greyButton';
import SimpleCloseBtn from './simpleCloseBtn';
import fishSpeciesArray from '../data/fishingSpecies';
import axios from 'axios';
import * as geolib from 'geolib';

const CatchLog = ({ 
    userLocation, 
    userInfo, 
    catchLogModal, 
    closeCatchLogModal, 
    subAreaLabel, 
    prepareMapForCatchLogLocation, 
    userSelectedMarker,
    handleMapClickableForLocation,
    mapClickableForLocation,
    setUserLocationBackToOriginal
}) => {

    const userId = userInfo.id;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

    const getDefaultDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (`0${now.getMonth() + 1}`).slice(-2); // Add 1 because months are 0-indexed
        const day = (`0${now.getDate()}`).slice(-2);
        const hours = (`0${now.getHours()}`).slice(-2);
        const minutes = (`0${now.getMinutes()}`).slice(-2);
    
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };
    
    const [speciesName, setSpeciesName] = useState([]);
    const [selectedSpeciesType, setSelectedSpeciesType] = useState('');
    const [selectedSpeciesName, setSelectedSpeciesName] = useState('');
    const [filteredSpeciesNames, setFilteredSpeciesNames] = useState([]);
    let formattedPreviousDay;
    let formattedNextDay;
    let currentTide;
    const [tideStationData, setTideStationData] = useState([]);
    let tideData = [];
    const [catchInfo, setCatchInfo] = useState({
        species: '',
        subSpecies: '',
        length: null,
        weight: null,
        depth: null,
        locationLat: userSelectedMarker?.lat.length > 0 ? userSelectedMarker.lat : '',
        locationLng: userSelectedMarker?.lng.length > 0 ? userSelectedMarker.lng : '',
        lure: '',
        speed: 2,
        tide: '',
        weather: '',
        dateTime: getDefaultDateTime(),
        notes: '',
        areaName: '',
        retained: 'retained',
        share: 'private',
    });

        useEffect(() => {
            console.log("getting tide stations")

            const getTideData = async () => {
                try {
                  const response = await fetch('https://api-iwls.dfo-mpo.gc.ca/api/v1/stations');
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                    const data = await response.json();
                    setTideStationData(data);
                    console.log(data);
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
              }

                getTideData();

            }, []);


        const tideStatus = async () => {
                
        // Convert userTime to a Date object
        const userDatePST = new Date(catchInfo.dateTime);
        const userDateUTC = userDatePST.toLocaleString('en-US', { timeZone: 'UTC' });
        const userDate = new Date(userDateUTC);
    
        // Calculate previous day
        const previousDay = new Date(userDate);
        previousDay.setDate(userDate.getDate() - 2);
    
        // Calculate next day
        const nextDay = new Date(userDate);
        nextDay.setDate(userDate.getDate() + 2);

        console.log("previousDay", previousDay, "next day", nextDay)
    
        // Format the dates as strings in the required format
        formattedPreviousDay = formatDate(previousDay);
        formattedNextDay = formatDate(nextDay);
    
        function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
        }

            // get nearest tide station and tide data

            async function findNearestStationTideData() {


                const userChosenLocation = {
                    lat: userSelectedMarker?.lat > 0 ? userSelectedMarker.lat : userLocation.lat,
                    lng: userSelectedMarker?.lng > 0 ? userSelectedMarker.lng : userLocation.lng,
                };

                console.log("userChosenLocation", userChosenLocation)
                try {
                  const nearest = geolib.findNearest(userChosenLocation, tideStationData.map(station => ({
                    latitude: station.latitude,
                    longitude: station.longitude,
                  })));

                  const nearestStationInfo = tideStationData.find(station => station.latitude === nearest.latitude && station.longitude === nearest.longitude);
                    
                  const response = await fetch(`https://api-iwls.dfo-mpo.gc.ca/api/v1/stations/${nearestStationInfo.id}/data?time-series-code=wlp-hilo&from=${formattedPreviousDay}T00:00:00Z&to=${formattedNextDay}T00:30:00Z`);
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                  const data = await response.json();
                //   setData(data)
                  tideData = data
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
              }
    
      function analyzeTideData(data, userTime) {
    
        if (!data) {
            console.log("no data so returned")
            return [];
        }
    
        // Convert userTime to a Date object
        const userDate = new Date(userTime);
      
        // Find the two elements with eventDates chronologically closest to userTime
        const closestTimes = findClosestTimes(tideData, userDate);
      
        // Check if the earlier time's value is lower or higher than the later time's value
        const isTideRising = closestTimes[0]?.value !== undefined && closestTimes[1]?.value !== undefined && closestTimes[0].value < closestTimes[1].value;
      
        if (closestTimes[0]?.value !== undefined && closestTimes[1]?.value !== undefined) {
    
        // Check if the user's time is within 30 minutes of earlier tide
        const isWithin30MinutesPreviousTide = Math.abs(userDate - new Date(closestTimes[0].eventDate)) < 30 * 60 * 1000;
        const isWithin30MinutesNextTide = Math.abs(userDate - new Date(closestTimes[1].eventDate)) < 30 * 60 * 1000;
    
        const isWithin30Minutes = isWithin30MinutesPreviousTide || isWithin30MinutesNextTide;
    
        
        // check how long user is from previous and next tide
        const timeFromPreviousTide = Math.abs(userDate - new Date(closestTimes[0].eventDate));
        const timeToNextTide = Math.abs(userDate - new Date(closestTimes[1].eventDate));
    
        // check if user is closer to previous or next tide
        const isCloserToPreviousTide = timeFromPreviousTide < timeToNextTide;
    
        let tideStatus;    
    
        if (isWithin30Minutes && isCloserToPreviousTide && isTideRising) {
            tideStatus = "slack low tide";
        } else if (isWithin30Minutes && isCloserToPreviousTide && !isTideRising) {
            tideStatus = "slack high tide";
        } else if (isWithin30Minutes && !isCloserToPreviousTide && isTideRising) {
            tideStatus = "slack high tide";
        } else if (isWithin30Minutes && !isCloserToPreviousTide && !isTideRising) {
            tideStatus = "slack low tide";
        } else if (!isWithin30Minutes && isTideRising) {
            tideStatus = "rising tide";
        } else if (!isWithin30Minutes && !isTideRising) {
            tideStatus = "falling tide";
        }
    
        return {
          tideStatus,
          isTideRising,
          closestTimes,
        };
      }
    
          // If closestTimes[0] or closestTimes[1] is undefined, return appropriate default values or handle the case as needed
          return {
            tideStatus: "unknown",
            isTideRising: false,
            closestTimes: [],
        };
    }
      
      function findClosestTimes(data, userDate) {
        // Sort the data by eventDate
        const sortedData = [...data].sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));
    
        // Find the closest times chronologically
        let closestIndex = 0;
        for (let i = 1; i < sortedData.length; i++) {
          if (new Date(sortedData[i].eventDate) > userDate) {
            break;
          }
          closestIndex = i;
        }
    
        // Take the two closest times
        const closestTimes = [sortedData[closestIndex], sortedData[closestIndex + 1]];
        return closestTimes;
      }


      try {
        // await getTideData();
    
        await findNearestStationTideData();
    
        const result = analyzeTideData(tideData, catchInfo.dateTime);

        currentTide = result.tideStatus        
    
    } catch (error) {
        console.error('Error in tideStatus:', error);
    }
    
    };

    useEffect(() => {
        setCatchInfo((prevCatchInfo) => ({
            ...prevCatchInfo,
            locationLat: userSelectedMarker?.lat,
            locationLng: userSelectedMarker?.lng,
        })
        );
    }, [userSelectedMarker]);


    const handleInputChange = (event) => {

        const { name, value } = event.target;
    
        if (name === 'speciesType') {
            setCatchInfo({
                ...catchInfo,
                species: value,
                subSpecies: '',
            });
            setSelectedSpeciesName('');
            setSelectedSpeciesType(value);
        } else if (name === 'speciesName') {
            setCatchInfo({
                ...catchInfo,
                subSpecies: value,
            });
            setSelectedSpeciesName(value);
        } else {
            setCatchInfo({
                ...catchInfo,
                [name]: value,
            });
        }
    };
    
    useEffect(() => {
        if (selectedSpeciesType === '') {
          setFilteredSpeciesNames(speciesName.sort());
        } else {
          const filteredNames = fishSpeciesArray
            .filter((item) => item.species_type === selectedSpeciesType)
            .map((item) => item.species_name)
            .filter((name) => !name.toLowerCase().includes('and'))
            .sort();
      
          setFilteredSpeciesNames(filteredNames);
        }
      }, [selectedSpeciesType, speciesName, fishSpeciesArray]);

      useEffect(() => {
        setCatchInfo((prevCatchInfo) => ({
            ...prevCatchInfo,
            areaName: subAreaLabel,
        }));
    }, [userLocation, subAreaLabel]);

      useEffect(() => {
        setCatchInfo((prevCatchInfo) => ({
            ...prevCatchInfo,
            locationLat: userLocation?.lat,
            locationLng: userLocation?.lng,
            areaName: subAreaLabel,
        }));
    }, [userLocation]);
      
      const sendCatchLog = async () => {

        await tideStatus();

        if (catchInfo.species === '' || catchInfo.subSpecies === '') {
            alert('Please select a species and sub species');
            return;
        }

        axios.post(`${API_BASE_URL}/catchLog`, {
            userId: userId,
            species: catchInfo.species,
            subSpecies: catchInfo.subSpecies,
            length: catchInfo.length,
            weight: catchInfo.weight,
            depth: catchInfo.depth,
            locationLat: catchInfo.locationLat,
            locationLng: catchInfo.locationLng,
            lure: catchInfo.lure,
            speed: catchInfo.speed,
            tide: currentTide,
            dateTime: catchInfo.dateTime,
            notes: catchInfo.notes,
            areaName: catchInfo.areaName,
            retained: catchInfo.retained,
            share: catchInfo.share,
        })
            .then((response) => {
                closeCatchLogModal();
                setSelectedSpeciesType('');
                setSelectedSpeciesName('');
                handleMapClickableForLocation();
                setCatchInfo({            
                    species: '',
                    subSpecies: '',
                    length: null,
                    weight: null,
                    depth: null,
                    locationLat: userLocation?.lat,
                    locationLng: userLocation?.lng,
                    lure: '',
                    speed: 2,
                    tide: '',
                    weather: '',
                    dateTime: getDefaultDateTime(),
                    notes: '',
                    areaName: '',
                    retained: 'retained',
                    share: 'private',
                });
            })
            .catch((error) => {
                console.log(error);
                handleMapClickableForLocation();
                closeCatchLogModal();
            });
        };
      
    // Create a Set to store unique species types
    const uniqueSpeciesTypes = new Set();

    // Iterate through fishSpeciesArray and add unique species types to the Set
    fishSpeciesArray.forEach((type) => {
    uniqueSpeciesTypes.add(type.species_type);
    });

    // Convert the Set back to an array
    const uniqueSpeciesArray = Array.from(uniqueSpeciesTypes);

    // if (selectedSpeciesType === 'Crab') {
    //     setCatchImg(require('../images/crab (2).png'));
    // } else if (selectedSpeciesType === 'Salmon') {
    //     setCatchImg(require('../images/fish (4).png'));
    // } else if (selectedSpeciesType === 'Other Finfish') {
    //     setCatchImg(require('../images/fish (4).png'));
    // } else if (selectedSpeciesType === 'Invertebrates') {
    //     setCatchImg(require('../images/shrimp (5).png'));
    // } 

    
    return (

        <Modal
            isOpen={catchLogModal}
            onRequestClose={closeCatchLogModal}
            contentLabel='Reset Password Modal'
            className="modal-general"
            overlayClassName="modal-overlay"
      >
        <div className='catch-log-container'>
          <h2>Catch Log</h2>
          <form>
          <div className='form-field medium-input'>
          Species:
            <div className='flex-row'>
                <select
                    value={selectedSpeciesType}
                    onChange={handleInputChange}
                    name='speciesType'
                >
                    <option value="-">-- Select --</option>
                    {uniqueSpeciesArray.map((type, index) => (
                        <option key={index} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
                {/* <img src={require(catchImg)} /> */}
            </div>
            </div>
            <div className='form-field medium-input'>
            Sub Species:
                <select
                    value={selectedSpeciesName}
                    onChange={handleInputChange}
                    name='speciesName'
                >
                    <option value="select">-- Select --</option>
                    {filteredSpeciesNames.map((name, index) => (
                        <option key={index} value={name}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
            <div className='catch-log-user-location'>
            {/* GET FISHING SUB AREA IF USER SELECTS LOCATION ON MAP */}
            <div
            className={mapClickableForLocation ? 'catch-log-map-btn' : 'catch-log-map-btn catch-log-map-btn-selected'}
            onClick={() => {
                setUserLocationBackToOriginal();
                }
            }
            >Use My Location
            </div>
            <button
                className={mapClickableForLocation ? 'catch-log-map-btn catch-log-map-btn-selected' : 'catch-log-map-btn'}
                onClick={() => {
                    closeCatchLogModal();
                    prepareMapForCatchLogLocation();
                    }
                }
                >Go to map
                </button>
            </div>
            <div className='form-field medium-input'>
            Sub Area:
                <input
                    name='areaName'
                    type='text'
                    placeholder='ex. 29-3'
                    value={catchInfo.areaName}
                    onChange={handleInputChange}
                />                   
            </div>
            <div className='form-field medium-input'>
            Length (cm):
                <input
                    name='length'
                    type='number'
                    // placeholder='Length (cm)'
                    value={catchInfo.length}
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-field medium-input'>
            Weight (lbs):
                <input
                    name='weight'
                    type='number'
                    // placeholder='Weight (lbs)'
                    value={catchInfo.weight}
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-field medium-input'>
            Depth (ft):
                <input
                    name='depth'
                    type='number'
                    // placeholder='Depth (ft)'
                    value={catchInfo.depth}
                    onChange={handleInputChange}
                />
            </div>
            <div className='hidden'>
            Location:
                <input
                    name='location'
                    type='text'
                    // placeholder='Location'
                    value={`${catchInfo.locationLat}, ${catchInfo.locationLng}`}
                    // onChange={handleInputChange}
                />
            </div>
            <div className='form-field medium-input'>
            Lure/Bait:
                <input
                    name='lure'
                    type='text'
                    // placeholder='Lure'
                    value={catchInfo.lure}
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-field medium-input'>
            Speed (mph):
                <input
                    name='speed'
                    type='number'
                    step='0.1'
                    // placeholder=''
                    value={catchInfo.speed}
                    onChange={handleInputChange}
                />
            </div>
            <div className='hidden'>
            Tide:
                <input
                    name='tide'
                    type='text'
                    // placeholder='Tide'
                    value={catchInfo.tide}
                    onChange={handleInputChange}
                />
            </div>
            {/* <div className='form-field medium-input'>
                <input
                name='weather'
                type='text'
                placeholder='Weather'
                value={catchInfo.weather}
                onChange={handleInputChange}
                />
            </div> */}
            <div className='form-field medium-input'>
            Date and Time:
                <input
                    name='dateTime'
                    type='datetime-local'
                    // placeholder='Date/Time'
                    value={catchInfo.dateTime}
                    onChange={handleInputChange}
                />
            </div>
            {/* <div className='form-field medium-input'>
                <textarea
                name='notes'
                type='text'
                placeholder='Notes'
                value={catchInfo.notes}
                onChange={handleInputChange}
                />
            </div>             */}
            <div className='form-field catch-log-radio-btn'>
                <label>Retained</label>
                <input
                    type='radio'
                    id='retained'
                    name='retained'
                    value='retained'
                    checked={catchInfo.retained === 'retained'}
                    onChange={handleInputChange}
                />
                <label>Released</label>
                <input
                    type='radio'
                    id='released'
                    name='retained'
                    value='released'
                    checked={catchInfo.retained === 'released'}
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-field catch-log-radio-btn'>
                <label>Private</label>
                <input
                    type='radio'
                    id='private'
                    name='share'
                    value='private'
                    checked={catchInfo.share === 'private'}
                    onChange={handleInputChange}
                />
                <label>Public</label>
                <input
                    type='radio'
                    id='public'
                    name='share'
                    value='public'
                    checked={catchInfo.share === 'public'}
                    onChange={handleInputChange}
                />
                <p>Let others see this catch?</p>
            </div>
            <div>
            {/* RENAME GREY BTN TO BLUE BTN */}
              {catchInfo.locationLat !== undefined && 
              <GreyButton btnText={'Record Catch'} onClick={sendCatchLog} />}
              <SimpleCloseBtn text={'X'} onClick={() => {
                closeCatchLogModal();
                handleMapClickableForLocation();
                setCatchInfo((prevCatchInfo) => ({
                    ...prevCatchInfo,
                    species: '',
                    subSpecies: '',
                    length: '',
                    weight: '',
                    depth: '',
                    locationLat: userSelectedMarker?.lat.length > 0 ? userSelectedMarker.lat : '',
                    locationLng: userSelectedMarker?.lng.length > 0 ? userSelectedMarker.lng : '',
                    lure: '',
                    speed: '2',
                    tide: '',
                    weather: '',
                    dateTime: getDefaultDateTime(),
                    notes: '',
                    areaName: '',
                    retained: 'retained',
                    share: 'private',
                    }));                    
                }}
                />
            </div>
          </form>
        </div>
      </Modal>
        
    )
}

export default CatchLog;