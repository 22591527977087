
const UserSettingsBar = ({userSettingsObject}) => {
    return (
        <div className="user-settings-bar">
            <span>Wave Height: <b>{userSettingsObject.wave_height || 'feet'}</b></span>
            <span>Wind Speed: <b>{userSettingsObject.wind_speed || 'knots'}</b></span>
            <span>Temperature: <b>{userSettingsObject.temperature || 'celcius'}</b></span>
        </div>
    )
}

export default UserSettingsBar;