import React from "react";
import { useTable, useGlobalFilter, useFilters, useSortBy } from "react-table";
import TableFilterForm from "./tableFilterForm";
import { useState, useEffect } from "react";
import axios from "axios";
import SimpleCloseBtn from "./simpleCloseBtn";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const CatchLogTable = ({ closeCatchLogModal, userInfo }) => {

    const [userCatchLogData, setUserCatchLogData] = useState([]);

    useEffect(() => {
        // call axios for fishing regs
        axios.get(API_BASE_URL + `/getCatchLog`, {
            params: {
              userId: userInfo.id,
            }
          })
        .then((response) => {
          // Handle success
          setUserCatchLogData(response.data.rows);
        })
        .catch((error) => {
          // Handle error
          console.error('Error getting regs:', error.response.data);
        });
        
      }, []);

    const data = React.useMemo(() => userCatchLogData, [userCatchLogData]);
    const columns = React.useMemo(
      () => [
        {
            Header: "Area",
            accessor: "area_name",
            Filter: TableFilterForm,
        },
        {
          Header: "Species",
          accessor: "species",
          Filter: TableFilterForm,
        },
        {
          Header: "Sub Species",
          accessor: "sub_species",
          Filter: TableFilterForm,
        },
        {
          Header: "Length (cm)",
          accessor: "length",
          Filter: TableFilterForm,
        },
        {
          Header: "Weight (lbs)",
          accessor: "weight",
          Filter: TableFilterForm,
        },
        {
          Header: "Depth (ft)",
          accessor: "depth",
          Filter: TableFilterForm,
        },
        // {
        //   Header: "Location Lat",
        //   accessor: "location_lat",
        //   Filter: TableFilterForm,
        // },
        // {
        //   Header: "Location Lng",
        //   accessor: "location_lng",
        //   Filter: TableFilterForm,
        // },
        {
          Header: "Lure/Bait",
          accessor: "lure",
          Filter: TableFilterForm,
        },
        {
          Header: "Speed (mph)",
          accessor: "speed",
          Filter: TableFilterForm,
        },
        {
          Header: "Tide",
          accessor: "tide",
          Filter: TableFilterForm,
        },
        {
          Header: "Date and Time",
          accessor: "date_time",
          Filter: TableFilterForm,
          Cell: ({ value }) => {
            // Convert UTC to PST
            const pstDate = new Date(value);
            const pstOptions = { timeZone: 'America/Los_Angeles', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
            const pstDateString = pstDate.toLocaleString('en-US', pstOptions);
            return pstDateString;
          }
        },
        {
          Header: "Notes",
          accessor: "notes",
          Filter: TableFilterForm,
        },
        {
          Header: "Retained",
          accessor: "retained",
          Filter: TableFilterForm,
        },
        {
          Header: "Shared",
          accessor: "shared",
          Filter: TableFilterForm,
        },
      ],
      []
    );
  
    const { 
      getTableProps, 
      getTableBodyProps, 
      headerGroups, 
      rows, 
      state,
      setGlobalFilter,
      prepareRow 
    } =
      useTable({ 
        columns, 
        data,
        initialState: { sortBy: [{ id: 'date_time', desc: true }] } // Initial sorting by 'date_time' in descending order
      }, useFilters, useGlobalFilter, useSortBy);
  
      const { globalFilter } = state;

      return (
      <div className="fishing-area-info-box">
        <SimpleCloseBtn text={'X'} onClick={closeCatchLogModal} />
        <h3>Catch Log</h3>
        <div className="search-container">
          Search all fields:{' '}
          <input
              type="text"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
        <table className="fishing-area-info-box-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} style={{ position: "sticky", top: "0", background: "white" }}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{
                      color: row.cells.some((cell) => /Open/.test(cell.value)) ? "black" : "#626262"
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td className="test-class" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

      )
}

export default CatchLogTable;