import { Oval } from  'react-loader-spinner';

const Loader = () => {
    return(
    <div className="loader">
    <Oval
      height={80}
      width={80}
      color="blue"
      wrapperStyle={{}}
      wrapperClass="loader"
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="white"
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  </div>
    )
}

export default Loader;