import React from "react";
import { Line } from "react-chartjs-2";
import SimpleCloseBtn from "./simpleCloseBtn";

const HistoryGraph = ({ 
    handleGraphClose, 
    selectedMarker, 
    windData, 
    chartOptionsWind, 
    waveData, 
    chartOptionsWaves,
    }) => (
    <div className='history-graph'>
        <SimpleCloseBtn onClick={handleGraphClose} className='graph-close-btn' text='x' />
        <h3>{selectedMarker.name}</h3>
        <Line 
            data={windData}   
            height={'80px'}
            options={chartOptionsWind}
        />
        {selectedMarker.type !== 'weatherStation' && 
        <Line 
            data={waveData} 
            height={'80px'} 
            options={chartOptionsWaves}
        />
        }
    </div>
)

export default HistoryGraph;