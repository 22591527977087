const domettPoint = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
    { lat: 49.550833, lng: -123.323333 }, // 49°33.050'N 123°19.400'W
    { lat: 49.546667, lng: -123.331667 }, // 49°32.800'N 123°19.900'W
    { lat: 49.5575, lng: -123.338333 },   // 49°33.450'N 123°20.300'W
    { lat: 49.565, lng: -123.324167 },    // 49°33.900'N 123°19.450'W
    { lat: 49.561667, lng: -123.3075 },  // 49°33.700'N 123°18.450'W
    { lat: 49.555833, lng: -123.313333 } // 49°33.350'N 123°18.800'W
  ]}

const easternBurrardInlet = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
        { lat: 49.291950, lng: -123.004167 }, // 49°17.517'N 123°00.250'W
        { lat: 49.293333, lng: -123.004167 }, // 49°17.600'N 123°00.250'W
        { lat: 49.300000, lng: -122.987000 }, // 49°18.000'N 122°58.700'W
        { lat: 49.295000, lng: -122.950000 }, // 49°17.700'N 122°57.000'W
        { lat: 49.290833, lng: -122.950000 }  // 49°17.450'N 122°57.000'W          
    ],
    }

const howeSoundAlbertaBay = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { lat: 49.468633, lng: -123.250833 },
        { lat: 49.468833, lng: -123.244800 },
        { lat: 49.466033, lng: -123.243333 },
        { lat: 49.461067, lng: -123.242967 },
        { lat: 49.458667, lng: -123.244100 },
        { lat: 49.458717, lng: -123.250133 },
        { lat: 49.465067, lng: -123.252967 },
        { lat: 49.468633, lng: -123.250833 }
      ]
    }
    
const howeSoundAnvilIsland = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { lat: 49.547900, lng: -123.290417 },
        { lat: 49.547750, lng: -123.280250 },
        { lat: 49.542217, lng: -123.281150 },
        { lat: 49.542033, lng: -123.285300 },
        { lat: 49.542900, lng: -123.290717 },
        { lat: 49.547900, lng: -123.290417 }
      ]
    }      

const bowyerIsland = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.437161, lng: -123.2755739 },
      { lat: 49.4353749, lng: -123.2772905 },
      { lat: 49.4343702, lng: -123.2796938 },
      { lat: 49.4324724, lng: -123.2810671 },
      { lat: 49.4280066, lng: -123.2824404 },
      { lat: 49.4251037, lng: -123.2819254 },
      { lat: 49.4240988, lng: -123.2824404 },
      { lat: 49.4236521, lng: -123.2824404 },
      { lat: 49.4230938, lng: -123.2817537 },
      { lat: 49.4224797, lng: -123.2821829 },
      { lat: 49.4217539, lng: -123.2823545 },
      { lat: 49.4211956, lng: -123.2820971 },
      { lat: 49.4198556, lng: -123.2790072 },
      { lat: 49.418683, lng: -123.2773764 },
      { lat: 49.4182364, lng: -123.2772905 },
      { lat: 49.417343, lng: -123.2772905 },
      { lat: 49.4144952, lng: -123.2748873 },
      { lat: 49.4141602, lng: -123.2756598 },
      { lat: 49.4135459, lng: -123.2748014 },
      { lat: 49.4119265, lng: -123.2748014 },
      { lat: 49.4107538, lng: -123.274544 },
      { lat: 49.4099161, lng: -123.2735998 },
      { lat: 49.4096369, lng: -123.2725698 },
      { lat: 49.4079615, lng: -123.2676775 },
      { lat: 49.407794, lng: -123.2661325 },
      { lat: 49.4084641, lng: -123.2652742 },
      { lat: 49.4094135, lng: -123.2640726 },
      { lat: 49.411759, lng: -123.2634718 },
      { lat: 49.4122057, lng: -123.2626135 },
      { lat: 49.4130992, lng: -123.2633001 },
      { lat: 49.4182922, lng: -123.2587511 },
      { lat: 49.4189622, lng: -123.2576353 },
      { lat: 49.4196322, lng: -123.2561762 },
      { lat: 49.4205256, lng: -123.2559187 },
      { lat: 49.4215306, lng: -123.2558329 },
      { lat: 49.4225913, lng: -123.2568628 },
      { lat: 49.4234288, lng: -123.2570345 },
      { lat: 49.4244896, lng: -123.2566912 },
      { lat: 49.4252711, lng: -123.2566912 },
      { lat: 49.4261644, lng: -123.2575495 },
      { lat: 49.4265552, lng: -123.2586653 },
      { lat: 49.4282857, lng: -123.2581503 },
      { lat: 49.4334213, lng: -123.2621843 },
      { lat: 49.4342586, lng: -123.2645876 },
      { lat: 49.4345377, lng: -123.2664759 },
      { lat: 49.4353191, lng: -123.2687933 },
      { lat: 49.4379424, lng: -123.274029 },
      { lat: 49.4382633, lng: -123.2749731 },
      { lat: 49.4381238, lng: -123.2753379 },
      { lat: 49.4377331, lng: -123.275531 }
    ]
  }        

const pasleyIsland = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.390599, lng: -123.4398892 },
      { lat: 49.3766861, lng: -123.4463265 },
      { lat: 49.3476743, lng: -123.4538796 },
      { lat: 49.3492958, lng: -123.4563687 },
      { lat: 49.3490722, lng: -123.4581712 },
      { lat: 49.3493517, lng: -123.4616044 },
      { lat: 49.349184, lng: -123.4645226 },
      { lat: 49.348513, lng: -123.4650376 },
      { lat: 49.3583531, lng: -123.4864953 },
      { lat: 49.3610364, lng: -123.4915593 },
      { lat: 49.3628252, lng: -123.4921601 },
      { lat: 49.3665914, lng: -123.49882 },
      { lat: 49.3849366, lng: -123.4785132 },
      { lat: 49.3874229, lng: -123.4839205 }
    ]
  }    

const howeSoundBowyerIsland = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { lat: 49.412900, lng: -123.270317 },
        { lat: 49.413667, lng: -123.262717 },
        { lat: 49.401600, lng: -123.267383 },
        { lat: 49.405150, lng: -123.273717 },
        { lat: 49.412900, lng: -123.270317 }
      ]
    }

const howeSoundBrunswickPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.476283, lng: -123.249417 },
      { lat: 49.473283, lng: -123.256283 },
      { lat: 49.469617, lng: -123.250517 },
      { lat: 49.473900, lng: -123.245533 },
      { lat: 49.476283, lng: -123.249417 },
    ]       
    }

const howeSoundCarmeloPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.454217, lng: -123.389967 }, // 49° 27.253' N, 123° 23.398' W
      { lat: 49.454317, lng: -123.384467 }, // 49° 27.259' N, 123° 23.028' W
      { lat: 49.439833, lng: -123.388583 }, // 49° 26.390' N, 123° 23.315' W
      { lat: 49.434050, lng: -123.393500 }, // 49° 26.043' N, 123° 23.610' W
      { lat: 49.435600, lng: -123.399367 }, // 49° 26.136' N, 123° 24.003' W
      { lat: 49.454217, lng: -123.389967 }  // Back to the beginning point
    ]
    }

const howeSoundCollingwoodChannel = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.389117, lng: -123.410417 },
      { lat: 49.391717, lng: -123.410633 },
      { lat: 49.393367, lng: -123.414750 },
      { lat: 49.388000, lng: -123.422433 },
      { lat: 49.382450, lng: -123.427583 },
      { lat: 49.379983, lng: -123.421600 },
      { lat: 49.382017, lng: -123.419083 },
      { lat: 49.384467, lng: -123.417167 },
      { lat: 49.389117, lng: -123.410417 },
    ]    
    }      

const howeSoundDefenceIslands = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.566533, lng: -123.28985 }, // 49 33.992n, 123 17.391w
      { lat: 49.561783, lng: -123.286683 }, // 49 33.707n, 123 17.201w
      { lat: 49.5573, lng: -123.279167 }, // 49 33.438n, 123 16.750w
      { lat: 49.559217, lng: -123.274367 }, // 49 33.553n, 123 16.462w
      { lat: 49.562167, lng: -123.276033 }, // 49 33.730n, 123 16.562w
      { lat: 49.568367, lng: -123.2845 }, // 49 34.102, 123 17.070w
      { lat: 49.566533, lng: -123.28985 }, // 49 33.992n, 123 17.391w
    ]
    
    }      

const howeSoundDormanPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.376283, lng: -123.322983 }, // 49 22.577n, 123 19.379w
      { lat: 49.372517, lng: -123.324233 }, // 49 22.351n, 123 19.454w
      { lat: 49.372117, lng: -123.318533 }, // 49 22.287n, 123 19.152w
      { lat: 49.375715, lng: -123.317517 }, // 49 22.543n, 123 19.051w
      { lat: 49.376283, lng: -123.322983 }, // 49 22.577n, 123 19.379w
    ]    
}      

const howeSoundEastDefenceIslands = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.57885, lng: -123.275917 }, // 49 34.731n, 123 16.555w
      { lat: 49.575583, lng: -123.27565 }, // 49 34.535n, 123 16.539w
      { lat: 49.576333, lng: -123.268067 }, // 49 34.580n, 123 16.084w
      { lat: 49.5808, lng: -123.268667 }, // 49 34.854n, 123 16.120w
      { lat: 49.5808, lng: -123.272617 }, // 49 34848n, 123 16.357w
      { lat: 49.57885, lng: -123.275917 }, // 49 34.731n, 123 16.555w
    ]    
}

const howeSoundHalkettPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.450599, lng: -123.311433 }, // 49 27.036n, 123 18.686w
      { lat: 49.445699, lng: -123.316400 }, // 49 26.742n, 123 18.984w
      { lat: 49.444281, lng: -123.312932 }, // 49 26.657n, 123 18.776w
      { lat:49.444932, lng: -123.309632 }, // 49 26.696n, 123 18.578w
      { lat: 49.448283, lng: -123.307400 }, // 49 26.897n, 123 18.444w
      { lat: 49.450599, lng: -123.311433 }, // 49 27.036n, 123 18.686w
    ]
    }
    
const howeSoundLangdale = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.438050, lng: -123.460067 },
      { lat: 49.427233, lng: -123.471517 },
      { lat: 49.423850, lng: -123.467233 },
      { lat: 49.423300, lng: -123.448167 },
      { lat: 49.436600, lng: -123.448250 },
      { lat: 49.438050, lng: -123.460067 },
    ] 
    }
    
const howeSoundLionsBayKelvinGrove = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { "lat": 49.460483, "lng": -123.262683 },
        { "lat": 49.454250, "lng": -123.241933 },
        { "lat": 49.449167, "lng": -123.242583 },
        { "lat": 49.449200, "lng": -123.250767 },
        { "lat": 49.454917, "lng": -123.261083 },
        { "lat": 49.460483, "lng": -123.262683 }
      ]
    }
        
const howeSoundLostReef = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.498917, lng: -123.300117 },
      { lat: 49.496650, lng: -123.303383 },
      { lat: 49.492450, lng: -123.299017 },
      { lat: 49.493183, lng: -123.291983 },
      { lat: 49.498033, lng: -123.297200 },
      { lat: 49.498917, lng: -123.300117 },
    ]        
    }
        
        
const howeSoundMarinersRest = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.462133, lng: -123.455950 },
      { lat: 49.458467, lng: -123.461767 },
      { lat: 49.453533, lng: -123.452317 },
      { lat: 49.457317, lng: -123.447850 },
      { lat: 49.462133, lng: -123.455950 },
    ]     
    }          

const howeSoundQueenCharlotteChannel = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.358100, lng: -123.287567 },
      { lat: 49.342133, lng: -123.294833 },
      { lat: 49.340017, lng: -123.299267 },
      { lat: 49.346083, lng: -123.313233 },
      { lat: 49.349700, lng: -123.309733 },
      { lat: 49.351633, lng: -123.300617 },
      { lat: 49.358350, lng: -123.295617 },
      { lat: 49.358100, lng: -123.287567 },
    ]             
    }
        
const howeSoundQueenCharlotteChannel2 = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.338133, lng: -123.294883 },
      { lat: 49.337067, lng: -123.291683 },
      { lat: 49.333217, lng: -123.289617 },
      { lat: 49.330033, lng: -123.290733 },
      { lat: 49.328667, lng: -123.297333 },
      { lat: 49.332283, lng: -123.301783 },
      { lat: 49.338133, lng: -123.294883 },
    ]             
    }    
        
const howeSoundQueenCharlotteChannel3 = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.331967, lng: -123.330783 },
      { lat: 49.321600, lng: -123.331750 },
      { lat: 49.321783, lng: -123.339067 },
      { lat: 49.327383, lng: -123.340350 },
      { lat: 49.330317, lng: -123.339350 },
      { lat: 49.332450, lng: -123.334950 },
      { lat: 49.331967, lng: -123.330783 },
    ]             
    }
        
const howeSoundQueenCharlotteChannel4 = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.343950, lng: -123.319367 },
      { lat: 49.342950, lng: -123.312000 },
      { lat: 49.340683, lng: -123.310617 },
      { lat: 49.334467, lng: -123.313633 },
      { lat: 49.334600, lng: -123.318917 },
      { lat: 49.328633, lng: -123.319783 },
      { lat: 49.328767, lng: -123.325233 },
      { lat: 49.337650, lng: -123.330467 },
      { lat: 49.343950, lng: -123.319367 },
    ]              
    }

const lionsBay = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.470981, lng: -123.246255 },
      { lat: 49.470833, lng: -123.262500 },
      { lat: 49.442500, lng: -123.260000 },
      { lat: 49.442500, lng: -123.238617 },
    ]        
    }          

const marinersRest = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.471667, lng: -123.459167 },
      { lat: 49.471667, lng: -123.471667 },
      { lat: 49.455000, lng: -123.460000 },
      { lat: 49.455000, lng: -123.448333 },
    ]        
    }          

const indianArmCrokerIslandNorthLine = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      {lat: 49.449975, lng: -122.862013 },
      {lat: 49.450032, lng: -122.881502 },
    ]
    }

const indianArmCrokerIslandSouthLine = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      {lat: 49.389860, lng: -122.870401 },
      {lat: 49.389807, lng: -122.882311 },
    ]
    }
        
const indianArmTwinIslandsNorth = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.355000, lng: -122.885000 },
      { lat: 49.359717, lng: -122.893333 },
    ]
}

const indianArmTwinIslandsSouth = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.342646, lng: -122.919597 },
      { lat: 49.342349, lng: -122.890230 },
    ]
}

const pamRocks = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.519400, lng: -123.319567 },
      { lat: 49.480000, lng: -123.300000 },
      { lat: 49.476667, lng: -123.276667 },
      { lat: 49.503333, lng: -123.298333 },
      { lat: 49.520500, lng: -123.286667 },
    ]
  }        

const passageIslandWestVan = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.372500, lng: -123.293700 },
      { lat: 49.345833, lng: -123.305833 },
      { lat: 49.354417, lng: -123.276583 },
      { lat: 49.355000, lng: -123.276583 },
    ]
  }        

const passageIsland = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.345833, lng: -123.306933 },
      { lat: 49.345000, lng: -123.318333 },
      { lat: 49.335000, lng: -123.318333 },
      { lat: 49.339167, lng: -123.307217 },
    ]
  }    
      
  const westBay = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.460283, lng: -123.416667 },
      { lat: 49.457217, lng: -123.407500 },
    ]
  }

  const upperCentreBay = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.467500, lng: -123.389167 },
      { lat: 49.467717, lng: -123.376950 },
    ]
  }

const woolRidgeIsland = {
  "type":"rockfish closure",
  "area": "28",
    "coordinates": [
      { lat: 49.521667, lng: -123.435000 },
      { lat: 49.526667, lng: -123.453333 },
      { lat: 49.508333, lng: -123.476667 },
      { lat: 49.501667, lng: -123.450833 },
    ]
  }

  const foreSlopeHills = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates": 
    [
      { lat: 49.160767, lng: -123.392383 },
      { lat: 49.153517, lng: -123.392783 },
      { lat: 49.153117, lng: -123.376450 },
      { lat: 49.156483, lng: -123.377033 },
      { lat: 49.160567, lng: -123.384133 },
      { lat: 49.160767, lng: -123.392383 },
    ]
  }

  const halibutBankRca = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.368333, lng: -123.790000 },
      { lat: 49.328333, lng: -123.738333 },
      { lat: 49.285000, lng: -123.648333 },
      { lat: 49.285000, lng: -123.611667 },
      { lat: 49.368333, lng: -123.745000 },
      { lat: 49.368333, lng: -123.790000 },
    ]
    }
  
    const halibutBankSpongeReef = {
      "type":"sponge reef closure",
      "area": "29",
      "coordinates": [
        { lat: 49.362800, lng: -123.691683 },
        { lat: 49.369800, lng: -123.702783 },
        { lat: 49.375917, lng: -123.740933 },
        { lat: 49.369567, lng: -123.745800 },
        { lat: 49.364917, lng: -123.731800 },
        { lat: 49.361217, lng: -123.710717 },
        { lat: 49.360167, lng: -123.697383 },
        { lat: 49.344267, lng: -123.669700 },
        { lat: 49.346717, lng: -123.664333 },
        { lat: 49.349350, lng: -123.668983 },
        { lat: 49.352900, lng: -123.667417 },
        { lat: 49.362800, lng: -123.691683 },
      ]
    }


    const galianoIslandNorth = {
      "type":"rockfish closure",
      "area": "29",
      "coordinates":     [
        { lat: 48.933333, lng: -123.435000 },
        { lat: 48.948000, lng: -123.431167 },
        { lat: 48.973333, lng: -123.495000 },
        { lat: 48.960000, lng: -123.509000 },
      ]
    }

  const gabriolaPassage = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates": [
      {lat: 49.118500, lng: -123.685417 },
      {lat: 49.125767, lng: -123.681617 }
    ]  
  }

  const gabriolaPassage2 = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates": [
      { lat: 49.136667, lng: -123.685667 },
      { lat: 49.136667, lng: -123.692483 },
    ]  
  }

  const gabriolaPassage3 = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates": [
      { lat: 49.129583, lng: -123.717217 },
      { lat: 49.127083, lng: -123.715217 },
    ]  
  }

  const mayneIsland = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.859167, lng: -123.253333 },
      { lat: 48.871667, lng: -123.253333 },
      { lat: 48.899167, lng: -123.318333 },
      { lat: 48.894517, lng: -123.322783 },
      { lat: 48.887650, lng: -123.320833 },
      { lat: 48.886050, lng: -123.320833 },
    ]
  }

  const mayneIsland2 = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.872519, lng: -123.292593 },
      { lat: 48.884564, lng: -123.305065 },
      { lat: 48.878517, lng: -123.313065 }
    ]
  }

  const valdesIslandEast = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.028333, lng: -123.595283 },
      { lat: 49.040000, lng: -123.573333 },
      { lat: 49.078333, lng: -123.608333 },
      { lat: 49.067783, lng: -123.631117 },
    ]
  }
  
  const sechelt = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.414083, lng: -123.831900 },
      { lat: 49.404217, lng: -123.804717 },
      { lat: 49.415167, lng: -123.799183 },
      { lat: 49.412233, lng: -123.784717 },
      { lat: 49.422883, lng: -123.774900 },
      { lat: 49.431650, lng: -123.787767 },
      { lat: 49.432467, lng: -123.814817 },
      { lat: 49.414083, lng: -123.831900 },
    ]
  }
  
  const outerGulf1 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.915600, lng: -123.326483 },
      { lat: 48.904717, lng: -123.308817 },
      { lat: 48.901900, lng: -123.310317 },
      { lat: 48.901083, lng: -123.312850 },
      { lat: 48.913117, lng: -123.332150 },
      { lat: 48.915033, lng: -123.329883 },
      { lat: 48.915600, lng: -123.326483 },
    ]
  }
  
  const outerGulf2 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.876467, lng: -123.254350 },
      { lat: 48.875333, lng: -123.242283 },
      { lat: 48.866183, lng: -123.229467 },
      { lat: 48.863250, lng: -123.232450 },
      { lat: 48.869167, lng: -123.240733 },
      { lat: 48.867300, lng: -123.244633 },
      { lat: 48.874650, lng: -123.258683 },
      { lat: 48.876467, lng: -123.254350 },
    ]
  }
  
  const outerGulf3 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.860033, lng: -123.220550 },
      { lat: 48.855150, lng: -123.212517 },
      { lat: 48.848550, lng: -123.215633 },
      { lat: 48.847400, lng: -123.217650 },
      { lat: 48.852717, lng: -123.227700 },
      { lat: 48.859650, lng: -123.222967 },
      { lat: 48.860033, lng: -123.220550 },
    ]
  }
  
  const outerGulf4 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.849983, lng: -123.206517 },
      { lat: 48.843467, lng: -123.193383 },
      { lat: 48.834950, lng: -123.182600 },
      { lat: 48.832650, lng: -123.186367 },
      { lat: 48.847617, lng: -123.210900 },
      { lat: 48.849317, lng: -123.209433 },
      { lat: 48.849983, lng: -123.206517 },
    ]
  }
  
  const mcCallBank = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.366667, lng: -123.620000 },
      { lat: 49.366667, lng: -123.666667 },
      { lat: 49.331667, lng: -123.598333 },
      { lat: 49.331667, lng: -123.550000 },
      { lat: 49.366667, lng: -123.620000 },
    ]
  }

  const bedwellHarbour = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":       [
      { lat: 48.754167, lng: -123.241667 },
      { lat: 48.748333, lng: -123.245833 },
    ],
  }

  const bedwellHarbour2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":       [
      { lat: 48.736667, lng: -123.231667 },
      { lat: 48.732500, lng: -123.208050 },
    ],
  }

  const brethourDomvilleForrestPooch = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.689992, lng: -123.266526 },
      { lat: 48.684800, lng: -123.293700 },
      { lat: 48.694167, lng: -123.332967 },
      { lat: 48.681083, lng: -123.340167 },
      { lat: 48.666050, lng: -123.341117 },
      { lat: 48.656667, lng: -123.327417 },
      { lat: 48.649817, lng: -123.301350 },
      { lat: 48.662300, lng: -123.275850 },
      { lat: 48.661854, lng: -123.257141 },
    ],
  }     

  const coalIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":   [
      { lat: 48.691233, lng: -123.388150 },
      { lat: 48.687967, lng: -123.402067 },
    ],   
  }
  const coalIsland2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.674633, lng: -123.396733 },
      { lat: 48.669650, lng: -123.404550 },
    ],  
  }
  const coalIsland3 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":      [
      { lat: 48.667450, lng: -123.397650 },
      { lat: 48.670533, lng: -123.377817 },
    ],
  }
  const coalIsland4 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":      [
      { lat: 48.670533, lng: -123.377817 },
      { lat: 48.679217, lng: -123.377100 },
    ],
  }

  const mapleBay = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.821900, lng: -123.586583 },
      { lat: 48.808400, lng: -123.588883 },
    ],    
  }
  const mapleBay2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":      [
      { lat: 48.806950, lng: -123.546883 },
      { lat: 48.787717, lng: -123.554050 },
    ],  
  }

  const navyChannel = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.833333, lng: -123.327967 },
      { lat: 48.819017, lng: -123.327200 },
    ],   
  }

  const navyChannel2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":  [
      { lat: 48.803333, lng: -123.275900 },
      { lat: 48.815000, lng: -123.232217 },
    ],     
  }

  const pateyRock = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.704167, lng: -123.525000 },
      { lat: 48.697500, lng: -123.525000 },
      { lat: 48.697500, lng: -123.508333 },
      { lat: 48.704167, lng: -123.508333 },
      { lat: 48.704167, lng: -123.525000 },
    ],   
  }

  const portlandIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.725920, lng: -123.387068 },
      { lat: 48.710401, lng: -123.382457 },
      { lat: 48.722103, lng: -123.350716 },
      { lat: 48.733533, lng: -123.340067 },
      { lat: 48.735000, lng: -123.370000 },
    ],   
  }

  const prevostIslandNorth = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.832483, lng: -123.359167 },
      { lat: 48.860383, lng: -123.391083 },
      { lat: 48.850000, lng: -123.423717 },
    ],   
  }

  const prevostIslandNorth2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.842533, lng: -123.419583 },
      { lat: 48.830900, lng: -123.417217 },
      { lat: 48.810300, lng: -123.381700 },
      { lat: 48.809067, lng: -123.365667 },
    ],   
  }

  const russellIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":   [
      { lat: 48.755133, lng: -123.426383 },
      { lat: 48.742750, lng: -123.409300 },
      { lat: 48.753667, lng: -123.387033 },
    ],     
  }

  const southSaturna = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.774550, lng: -123.202567 },
      { lat: 48.755500, lng: -123.203333 },
    ],   
  }

  const southSaturna2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.735650, lng: -123.183817 },
      { lat: 48.724367, lng: -123.160000 },
      { lat: 48.745833, lng: -123.083333 },
      { lat: 48.780700, lng: -123.080767 },
    ],    
  }

  const bellIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":       [
      { lat: 48.8333527, lng: -123.2333433 },
      { lat: 48.846741, lng: -123.2440721 },
      { lat: 48.8549869, lng: -123.2463037 },
      { lat: 48.8266867, lng: -123.1574078 },
      { lat: 48.8081494, lng: -123.1677075 },
    ],
  }

  const ballenasIsland = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.350383, lng: -124.160250 }, // Starting point
      { lat: 49.350383, lng: -124.126667 }, // Move true east
      { lat: 49.326667, lng: -124.126667 }, // Move true south
      { lat: 49.326667, lng: -124.160250 }, // Move true west
      { lat: 49.350383, lng: -124.160250 }  // Move true north (back to starting point)
    ]
  }

  const coffinPoint = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.010000, lng: -123.765250 }, // Starting point (North of Coffin Point)
      { lat: 49.010000, lng: -123.746667 }, // Move to
      { lat: 48.996667, lng: -123.733333 }, // Move to
      { lat: 48.992500, lng: -123.733333 }, // Move to
      { lat: 48.992500, lng: -123.759999 }, // Move to (Coffin Point)
      // Continue with coordinates following the shoreline as needed
    ]
  }

  const dangerReef = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.060000, lng: -123.726667 }, // Starting point (in water)
      { lat: 49.065000, lng: -123.716667 }, // Move to
      { lat: 49.050000, lng: -123.705000 }, // Move to
      { lat: 49.046667, lng: -123.713333 }, // Move to
      // Move back to the beginning point
      { lat: 49.060000, lng: -123.726667 }
    ]
  }

  const deCourcyIslandNorth = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.121667, lng: -123.768233 }, // Starting point (Link Island)
      { lat: 49.127200, lng: -123.733333 }, // Move to (Gabriola Island)
      { lat: 49.100000, lng: -123.734583 }, // Move to (Pirates Cove)
      { lat: 49.100000, lng: -123.737583 }, // Move to (De Courcy Island)
      // Continue with coordinates following the easterly shoreline of De Courcy Island as needed
      { lat: 49.111683, lng: -123.759333 }, // Move to (De Courcy Island)
      { lat: 49.111767, lng: -123.759183 }, // Move to (Link Island)
      // Continue with coordinates following the easterly shoreline of Link Island as needed
      { lat: 49.121667, lng: -123.768233 }  // Move back to the beginning point
    ]
  }

  const departureBay = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.215483, lng: -123.941133 }, // Starting point (north of Horswell Bluff)
      { lat: 49.215483, lng: -123.934217 }, // Move to (Horswell Channel Buoy)
      { lat: 49.198783, lng: -123.925217 }, // Move to (McKay Point)
      // Continue with coordinates following the shoreline of Newcastle Island as needed
      { lat: 49.195000, lng: -123.947017 }, // Move to (Shaft Point)
      { lat: 49.207117, lng: -123.959517 }, // Move to (Brandon Islands)
      { lat: 49.210000, lng: -123.955833 }, // Move to (Pacific Biological)
      // Continue with coordinates following the shoreline as needed
    ]
  }

  const gabriolaIsland = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.227867, lng: -123.792950 }, // Starting point
      { lat: 49.220583, lng: -123.790483 }, // Move to
      { lat: 49.219750, lng: -123.798033 }, // Move to
      { lat: 49.223183, lng: -123.801983 }, // Move to
      { lat: 49.227050, lng: -123.803433 }, // Move to
      { lat: 49.227867, lng: -123.792950 }  // Move back to the beginning point
    ]
  }

  const lasquetiSouthYoungPoint = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.438883, lng: -124.136117 }, // Starting point (in water)
      { lat: 49.438883, lng: -124.095550 }, // Move to
      { lat: 49.410550, lng: -124.095550 }, // Move to
      { lat: 49.410550, lng: -124.136117 }, // Move to
      { lat: 49.438883, lng: -124.136117 }  // Move back to the beginning point
    ]
  }

  const nanooseSchoonerCove = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.301667, lng: -124.170250 }, // Starting point (Vancouver Island)
      { lat: 49.304350, lng: -124.154883 }, // Move to (Amelia Island)
      { lat: 49.306667, lng: -124.131950 }, // Move to (Yeo Islands)
      { lat: 49.285000, lng: -124.093333 }, // Move to (Ada Islands)
      { lat: 49.270367, lng: -124.085000 }, // Move to (Maude Island)
      { lat: 49.261967, lng: -124.124500 }, // Move to (Imperieuse Rock Light Buoy)
      { lat: 49.265383, lng: -124.124900 }, // Move to (Nanoose Harbour)
      // Continue with coordinates following the shoreline of Nanoose Harbour as needed
      // ...
      // { lat: 49.301667, lng: -124.170250 }  // Move back to the beginning point
    ]
  }

  const northumberlandChannel = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.199100, lng: -123.854167 }, // Move to (Tinson Point)
      { lat: 49.217400, lng: -123.890533 }, // Starting point (Snake Island Light)
      { lat: 49.166667, lng: -123.893333 }, // Move to (Jack Point Area)
      // Continue with coordinates following the shoreline as needed
      // { lat: 49.155000, lng: -123.857000 }, // Move to (Gabriola Island)
      // Continue with coordinates following the shoreline as needed
    ]
  }

  const northumberlandChannel2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.140283, lng: -123.857000 }, // Starting point (Snake Island Light)
      { lat: 49.155840, lng: -123.857002 }, // Move to (Tinson Point)
    ]
  }
  
  const reynoldsPointLinkIsland = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.115283, lng: -123.762500 },
      { lat: 49.115283, lng: -123.805217 },
    ]
  }

  const reynoldsPointLinkIsland2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.096667, lng: -123.800667 },
      { lat: 49.110621, lng: -123.759120 },
    ]
  }

  const ruxtonPyladesIslands = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.092500, lng: -123.719883 }, // Starting point (De Courcy Island)
      { lat: 49.095000, lng: -123.711667 }, // Move to (in water)
      { lat: 49.070833, lng: -123.680000 }, // Move to (in water)
      { lat: 49.058333, lng: -123.676667 }, // Move to (in water)
      { lat: 49.056667, lng: -123.700000 }, // Move to (in water)
      { lat: 49.068333, lng: -123.703333 }, // Move to (in water)
      { lat: 49.087500, lng: -123.737500 }, // Move to (in water)
      { lat: 49.091300, lng: -123.735000 }, // Move to (De Courcy Island)
    ]
  }

  const saltSpringIslandNorth = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lng: -123.5988398, lat: 48.933373 },
      { lng: -123.6042472, lat: 48.9392931 },
      { lng: -123.5912867, lat: 48.9587963 },
      { lng: -123.5900851, lat: 48.9590217 },
      { lng: -123.5897744, lat: 48.9593986 },
      { lng: -123.5890448, lat: 48.9593704 },
      { lng: -123.5885942, lat: 48.9586801 },
      { lng: -123.5873711, lat: 48.9578629 },
      { lng: -123.5866415, lat: 48.9572852 },
      { lng: -123.5852039, lat: 48.9571866 },
      { lng: -123.5847104, lat: 48.9575388 },
      { lng: -123.5836375, lat: 48.9565948 },
      { lng: -123.5828865, lat: 48.9567498 },
      { lng: -123.5826933, lat: 48.9571725 },
      { lng: -123.5815561, lat: 48.9571302 },
      { lng: -123.5804188, lat: 48.9568625 },
      { lng: -123.5793889, lat: 48.9561721 },
      { lng: -123.5790026, lat: 48.9556931 },
      { lng: -123.5776937, lat: 48.9554535 },
      { lng: -123.5774147, lat: 48.9557353 },
      { lng: -123.5774362, lat: 48.9562708 },
      { lng: -123.5743678, lat: 48.9565948 }
    ]
  }

  const saltSpringIslandNorth2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.919717, lng: -123.548400 },
      { lat: 48.931583, lng: -123.535033 },
    ]
  }

  const thetisKuperIslands = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.021117, lng: -123.686567 },
      { lat: 49.041133, lng: -123.713083 },
      { lat: 48.929550, lng: -123.706067 },
      { lat: 48.933850, lng: -123.632017 }
    ]
  }

  const thetisKuperIslands2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.957584, lng: -123.662362 },
      { lat: 48.969267, lng: -123.673667 },
    ]
  }

  const trincomaliChannel = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.979256, lng: -123.568679 },
      { lat: 48.956633, lng: -123.574277 },
    ]
  }
  const trincomaliChannel2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.931667, lng: -123.535150 },
      { lat: 48.923517, lng: -123.494450 },
      { lat: 48.903117, lng: -123.447950 },
    ]
  }

  const trincomaliChannel3 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.899150, lng: -123.441883 },
      { lat: 48.893233, lng: -123.428600 },
    ]
  }
  const trincomaliChannel4 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.893633, lng: -123.413367 },
      { lat: 48.895533, lng: -123.410800 },
    ]
  }

  const davieBay = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.602950, lng: -124.397617 }, // Starting point (near Davie Bay)
      { lat: 49.591667, lng: -124.408333 }, // Move to (in water)
      { lat: 49.562500, lng: -124.345000 }, // Move to (in water)
      { lat: 49.575867, lng: -124.331667 }, // Move to (shoreline)
    ]
  }

  const hardyIsland = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.751667, lng: -124.220833 }, // Starting point (Ball Point)
      { lat: 49.761667, lng: -124.229167 }, // Move to (in water)
      { lat: 49.773333, lng: -124.090917 }, // Move to (east side of Vanguard Bay)
    ]
  }

  const hardyIsland2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.7317448, lng: -124.2014997 },
      { lat: 49.7135005, lng: -124.2003222 }
    ]
  }

  const hardyIsland3 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.6794741, lng: -124.2017635 },
      { lat: 49.7010642, lng: -124.2018504 }
    ]
  }

  const hothamSound = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.836667, lng: -124.038017 }, // Starting point (Elephant Point)
      { lat: 49.859450, lng: -124.013100 }, // Move to (Harmony Islands)
      { lat: 49.861117, lng: -124.008783 }  // Move to (shoreline)
    ]
  }

  const malaspinaStrait = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.653333, lng: -124.187500 }, // Starting point (in water)
      { lat: 49.602500, lng: -124.100000 }, // Move to (in water)
      { lat: 49.586667, lng: -124.156667 }, // Move to (in water)
      { lat: 49.643333, lng: -124.225000 }, // Move to (in water)
      { lat: 49.653333, lng: -124.187500 }  // Return to the beginning point
    ]
  }

  const mcNaughtonPoint = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.590000, lng: -124.015833 },
      { lat: 49.588333, lng: -124.021667 },
      { lat: 49.576667, lng: -124.016233 },
      { lat: 49.565000, lng: -124.011667 },
      { lat: 49.545000, lng: -123.995000 },
      { lat: 49.547883, lng: -123.990167 }
    ]
  }

  const princessLouisaInlet = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 50.164469, lng: -123.850206 },
      { lat: 50.164356, lng: -123.849234 }
    ]
  }

  const queensReachEast = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 50.190383, lng: -123.937733 }, // Starting point (shoreline)
      { lat: 50.185833, lng: -123.943333 }, // Move to (in water)
      { lat: 50.158333, lng: -123.866667 }, // Move to (in water)
      { lat: 50.162500, lng: -123.857500 }, // Move to (shoreline)
    ]
  }

  const queensReachWest = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 50.132467, lng: -123.850850 }, // Starting point (shoreline)
      { lat: 50.128333, lng: -123.835000 }, // Move to (in water)
      { lat: 50.102200, lng: -123.817617 }, // Move to (shoreline)
    ]
  }

  const sabineJervisJedediah = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.514879, lng: -124.291955 },
      { lat:49.537500, lng: -124.279167 },
      { lat:49.509450, lng: -124.210000 }
    ]
  }

  const sabineJervisJedediah2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.501667, lng: -124.193050 },
      { lat: 49.486667, lng: -124.170000 },
      { lat: 49.460000, lng: -124.141667 },
      { lat: 49.458333, lng: -124.167783 }
    ]
  }

  const salmonInlet = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.637062, lng: -123.716546 },
      { lat: 49.648287, lng: -123.717401 }
    ]
  }

  const salmonInlet2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.676324, lng: -123.555737 },
      { lat: 49.682427, lng: -123.560232 }
    ]
  }

  const sinclairBank = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.723333, lng: -124.312500 }, // Starting point (in water)
      { lat: 49.723333, lng: -124.250000 }, // Move to (in water)
      { lat: 49.685000, lng: -124.250000 }, // Move to (in water)
      { lat: 49.685000, lng: -124.312500 }, // Move to (in water)
      { lat: 49.723333, lng: -124.312500 }  // Return to the beginning point
    ]
  }

  const skookumchuckNarrows = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.625000, lng: -123.843333 },
      { lat: 49.625000, lng: -123.828333 },
      { lat: 49.648333, lng: -123.845000 },
      { lat: 49.725283, lng: -123.879683 },
      { lat: 49.725283, lng: -123.867483 }
    ]
  }

  const skookumchuckNarrows2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.746667, lng: -123.906950 },
      { lat: 49.746667, lng: -123.891217 }
    ]
  }

  const thormanbyIsland = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.508383, lng: -123.982677 },
      { lat: 49.518722, lng: -123.985111 },
      { lat: 49.513517, lng: -123.971117 },
      { lat: 49.513533, lng: -123.967633 },
      { lat: 49.512850, lng: -123.965667 }
    ]
  }

  const thormanbyIsland2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.492217, lng: -123.954000 },
      { lat: 49.509783, lng: -123.933650 }
    ]
  }

  const ajaxAchillesBank = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.658333, lng: -124.723667 }, // Starting point (in water)
      { lat: 49.681667, lng: -124.660000 }, // Move to (in water)
      { lat: 49.550000, lng: -124.475000 }, // Move to (in water)
      { lat: 49.532500, lng: -124.487500 }, // Move to (in water)
      { lat: 49.658333, lng: -124.723667 }  // Return to the beginning point
    ]
  }

  const baynesSound = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.520000, lng: -124.801667 }, // Starting point (in water)
      { lat: 49.503333, lng: -124.775000 }, // Move to (in water)
      { lat: 49.498333, lng: -124.785000 }, // Move to (in water)
      { lat: 49.513333, lng: -124.811667 }, // Move to (in water)
      { lat: 49.520000, lng: -124.801667 }  // Return to the beginning point
    ]
  }

  const chromeIsland = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.474817, lng: -124.685483 }, // Boyle Point
      { lat: 49.472200, lng: -124.685200 }, // Chrome Island Light
      { lat: 49.467783, lng: -124.728333 }, // Mapleguard Point
      { lat: 49.471850, lng: -124.736583 }, // Mapleguard Buoy
      { lat: 49.482967, lng: -124.732433 }  // Repulse Point
    ]
  }

  const eastHornbyIsland = {
    "type":"sponge reef closure",
    "area": "14",
    "coordinates": [
      { lat: 49.558167, lng: -124.487167 }, // Starting point
      { lat: 49.545017, lng: -124.479333 }, // Move to
      { lat: 49.527617, lng: -124.490567 }, // Move to
      { lat: 49.527717, lng: -124.498267 }, // Move to
      { lat: 49.544183, lng: -124.496200 }, // Move to
      { lat: 49.555667, lng: -124.498917 }, // Move to
      { lat: 49.558300, lng: -124.496217 }, // Move to
      { lat: 49.558167, lng: -124.487167 }  // Return to the beginning point
    ]
  }

  const lasquetiIslandSouth = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.455250, lng: -124.221667 }, // Starting point (east of Boat Cove)
      { lat: 49.430000, lng: -124.221667 }, // Move to (in water)
      { lat: 49.430000, lng: -124.304167 }, // Move to (in water)
      { lat: 49.460000, lng: -124.304167 }, // Move to (near Jenkins Creek)
    ]
  }

  const mitlenatchIsland = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.975000, lng: -125.031667 }, // Starting point (in water)
      { lat: 49.975000, lng: -124.968333 }, // Move to (in water)
      { lat: 49.925000, lng: -124.968333 }, // Move to (in water)
      { lat: 49.925000, lng: -125.031667 }, // Move to (in water)
      { lat: 49.975000, lng: -125.031667 }  // Return to the beginning point
    ]
  }

  const oysterBay = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.926667, lng: -125.182450 }, // Starting point (south of Shelter Point)
      { lat: 49.926667, lng: -125.101667 }, // Move to (in water)
      { lat: 49.898333, lng: -125.150000 }, // Move to (shoreline)
      // Continue with coordinates for crossing Oyster Bay as needed
      // ...
      { lat: 49.926667, lng: -125.182450 }  // Return to the beginning point
    ]
  }

  const parksVille = {
    "type":"sponge reef closure",
    "area": "14",
    "coordinates": [
      { lat: 49.361333, lng: -124.329367 }, // Starting point
      { lat: 49.358567, lng: -124.314883 }, // Move to
      { lat: 49.353183, lng: -124.295383 }, // Move to
      { lat: 49.352733, lng: -124.295400 }, // Move to
      { lat: 49.345417, lng: -124.306333 }, // Move to
      { lat: 49.357200, lng: -124.330183 }, // Move to
      { lat: 49.361333, lng: -124.329367 }  // Return to the beginning point
    ]
  }

  const savoieRocks = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.512949, lng: -124.708270 },
      { lat: 49.510000, lng: -124.711667 },
      { lat: 49.495833, lng: -124.688333 },
      { lat: 49.496667, lng: -124.675367 }
    ]
  }

  const sisterIslets = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.498333, lng: -124.462500 }, // Starting point (in water)
      { lat: 49.498333, lng: -124.418333 }, // Move to (in water)
      { lat: 49.468333, lng: -124.418333 }, // Move to (in water)
      { lat: 49.468333, lng: -124.462500 }, // Move to (in water)
      { lat: 49.498333, lng: -124.462500 }  // Return to the beginning point
    ]
  }

  const brentWoodBay = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.579800, lng: -123.470333 }, // Starting point (Sluggett Point)
      { lat: 48.577450, lng: -123.487033 }, // Move to (Willis Point)
      { lat: 48.588333, lng: -123.491667 }, // Move to (in water)
      { lat: 48.598333, lng: -123.491667 }, // Move to (in water)
      { lat: 48.598950, lng: -123.480867 }, // Move to (Henderson Point)
    ]
  }

  const darcyIsland = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.572019, lng: -123.226776 },
      { lat: 48.571784, lng: -123.269090 },
      { lat: 48.570965, lng: -123.284587 },
      { lat: 48.568947, lng: -123.286648 },
      { lat: 48.565367, lng: -123.285000 },
      { lat: 48.540000, lng: -123.285000 },
      { lat: 48.540000, lng: -123.248333 },
      { lat: 48.446667, lng: -123.191667 },
      { lat: 48.400533, lng: -123.136583 },
      { lat: 48.422801, lng: -123.115183 },
      { lat: 48.453475, lng: -123.159847 },
      { lat: 48.548701, lng: -123.218938 },
      { lat: 48.572019, lng: -123.226776 }
    ]
  }

  const discoveryChathamIslands = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.424300, lng: -123.249150 },
      { lat: 48.430517, lng: -123.255183 }
    ]
  }


  const discoveryChathamIslands2 = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.435000, lng: -123.258867 },
      { lat: 48.438333, lng: -123.261667 },
      { lat: 48.442500, lng: -123.255000 },
      { lat: 48.447681, lng: -123.238446 },
      { lat: 48.433333, lng: -123.228333 },
      { lat: 48.423333, lng: -123.222217 },
      { lat: 48.423450, lng: -123.225000 }
    ]
  }


  const discoveryChathamIslands3 = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.421883, lng: -123.227350 },
      { lat: 48.419600, lng: -123.237417 }
    ]
  }

  const duntzeHead = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.430900, lng: -123.439583 },
      { lat: 48.425833, lng: -123.441667 },
      { lat: 48.419167, lng: -123.423333 },
      { lat: 48.421850, lng: -123.420133 }
    ]
  }

  const midFinlaysonArm = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates":  [
      { lat: 48.515000, lng: -123.535750 },
      { lat: 48.515000, lng: -123.540000 },
      { lat: 48.523333, lng: -123.540000 },
      { lat: 48.533333, lng: -123.545833 },
      { lat: 48.546667, lng: -123.548333 },
      { lat: 48.546972, lng: -123.536985 }
    ]
  }

  const raceRocks = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.3114563, lng: -123.5323947 },
      { lat: 48.3097437, lng: -123.5362571 },
      { lat: 48.3057475, lng: -123.5423511 },
      { lat: 48.3022078, lng: -123.5451835 },
      { lat: 48.2996384, lng: -123.5456127 },
      { lat: 48.2990104, lng: -123.5481018 },
      { lat: 48.2975258, lng: -123.5487026 },
      { lat: 48.2955844, lng: -123.5481018 },
      { lat: 48.294271, lng: -123.5442394 },
      { lat: 48.2933573, lng: -123.5412353 },
      { lat: 48.2919868, lng: -123.5400337 },
      { lat: 48.2890743, lng: -123.5387462 },
      { lat: 48.2866185, lng: -123.5402053 },
      { lat: 48.2853049, lng: -123.5409778 },
      { lat: 48.2842769, lng: -123.5402912 },
      { lat: 48.2838771, lng: -123.5367721 },
      { lat: 48.2858761, lng: -123.5315364 },
      { lat: 48.2847909, lng: -123.5278457 },
      { lat: 48.2847338, lng: -123.5248416 },
      { lat: 48.2834773, lng: -123.5183185 },
      { lat: 48.2842769, lng: -123.5157436 },
      { lat: 48.284848, lng: -123.5151428 },
      { lat: 48.286847, lng: -123.5163444 },
      { lat: 48.2896454, lng: -123.5137695 },
      { lat: 48.2906733, lng: -123.5135978 },
      { lat: 48.291587, lng: -123.5135978 },
      { lat: 48.296498, lng: -123.5154003 },
      { lat: 48.3010659, lng: -123.5205501 },
      { lat: 48.302322, lng: -123.5208934 },
      { lat: 48.3068322, lng: -123.5214084 },
      { lat: 48.3106, lng: -123.5211509 },
      { lat: 48.3119129, lng: -123.525185 },
      { lat: 48.3117417, lng: -123.5299056 },
      { lat: 48.3114563, lng: -123.5323947 }
    ]
  }

  const bentinckIsland = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.3202009, lng: -123.5405085 },
      { lat: 48.3184601, lng: -123.5399506 },
      { lat: 48.31729, lng: -123.5373756 },
      { lat: 48.314065, lng: -123.5374186 },
      { lat: 48.3130376, lng: -123.5378477 },
      { lat: 48.312638, lng: -123.5392639 },
      { lat: 48.3131232, lng: -123.5400364 },
      { lat: 48.3148642, lng: -123.5406801 },
      { lat: 48.3148927, lng: -123.5420534 },
      { lat: 48.3140936, lng: -123.5434267 },
      { lat: 48.31064, lng: -123.5432121 },
      { lat: 48.3097837, lng: -123.54377 },
      { lat: 48.3097267, lng: -123.5452291 },
      { lat: 48.3104688, lng: -123.5463879 },
      { lat: 48.3098123, lng: -123.547332 },
      { lat: 48.3100406, lng: -123.5486624 },
      { lat: 48.3107827, lng: -123.5487911 },
      { lat: 48.311639, lng: -123.5580179 }
    ]
  }

  const trialIsland = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.410000, lng: -123.308883 },
      { lat: 48.406167, lng: -123.298333 },
      { lat: 48.396667, lng: -123.300833 },
      { lat: 48.396667, lng: -123.310000 },
      { lat: 48.410000, lng: -123.308883 },
    ]
    }


    const shadwellPassage = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.904800, lng: -127.832367 },
        { lat: 50.895950, lng: -127.816400 }
      ]
    }

    const shadwellPassage2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.933333, lng: -127.837717 },
        { lat: 50.930000, lng: -127.800000 },
        { lat: 50.916000, lng: -127.735917 }
      ]
    }

    const goletasChannel = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.880383, lng: -127.843983},
        { lat: 50.853333, lng: -127.858333 }
      ]
    }

    const goletasChannel2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.831973, lng: -127.654831 },
        { lat: 50.809560, lng: -127.654591 }
      ]
    }

    const browningPassageHuntRock = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.901317, lng: -127.696950 },
        { lat: 50.910000, lng: -127.681117 },
        { lat: 50.875550, lng: -127.648083 },
        { lat: 50.867850, lng: -127.645617 }
      ]
    }

    const browningPassageHuntRock2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.842233, lng: -127.631317 },
        { lat: 50.842233, lng: -127.660683 }
      ]
    }

    const bolivarPassage = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.899600, lng: -127.517583 },
        { lat: 50.902633, lng: -127.519233 },
        { lat: 50.905000, lng: -127.521317 },
        { lat: 50.927517, lng: -127.543383 },
        { lat: 50.918333, lng: -127.582133 },
        { lat: 50.918333, lng: -127.581667 },
        { lat: 50.907283, lng: -127.570967 },
        { lat: 50.902500, lng: -127.581267 },
        { lat: 50.900000, lng: -127.587600 },
        { lat: 50.893333, lng: -127.596667 },
        { lat: 50.871667, lng: -127.556667 },
        { lat: 50.885000, lng: -127.527733 }
      ]
    }

    const shelterBay = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.926850, lng: -127.408017 },
        { lat: 50.927817, lng: -127.410283 },
        { lat: 50.929567, lng: -127.415667 },
        { lat: 50.955517, lng: -127.503717 },
        { lat: 50.968333, lng: -127.503333 },
        { lat: 50.975333, lng: -127.469250 }
      ]
    }

    const browningIslandsRaynorGroup = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.872450, lng: -127.180483 },
        { lat: 50.871667, lng: -127.212217 },
        { lat: 50.879833, lng: -127.237550 },
        { lat: 50.885383, lng: -127.246700 },
        { lat: 50.884900, lng: -127.249450 },
        { lat: 50.898617, lng: -127.313783 },
        { lat: 50.895567, lng: -127.327567 },
        { lat: 50.898467, lng: -127.334750 },
        { lat: 50.916950, lng: -127.365650 }
      ]
    }

    const druryInletMuirheadIslands = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.936233, lng: -127.140467 },
        { lat: 50.934233, lng: -127.137683 }
      ]
    }

    const druryInletMuirheadIslands2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.920483, lng: -127.083333 },
        { lat: 50.900117, lng: -127.083333 }
      ]
    }

    const hardyBay = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        {"lat": 50.745892, "lng": -127.4871866},
        {"lat": 50.7456204, "lng": -127.4885599},
        {"lat": 50.7450773, "lng": -127.4893752},
        {"lat": 50.7443713, "lng": -127.4898473},
        {"lat": 50.7434751, "lng": -127.4898473},
        {"lat": 50.742769, "lng": -127.4895469},
        {"lat": 50.7422259, "lng": -127.4885599},
        {"lat": 50.7419, "lng": -127.4873153},
        {"lat": 50.7417914, "lng": -127.4861566},
        {"lat": 50.7419, "lng": -127.4848691},
        {"lat": 50.7422802, "lng": -127.4837104},
        {"lat": 50.7428777, "lng": -127.4830667},
        {"lat": 50.7435566, "lng": -127.4826804},
        {"lat": 50.7443441, "lng": -127.4825946},
        {"lat": 50.7449687, "lng": -127.4830667},
        {"lat": 50.7455118, "lng": -127.4837962},
        {"lat": 50.7458377, "lng": -127.484912},
        {"lat": 50.7460277, "lng": -127.4862424},
        {"lat": 50.745892, "lng": -127.4871866}
      ]
    }

    const numasIslands = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.798333, lng: -127.126667 },
        { lat: 50.776667, lng: -127.040000 },
        { lat: 50.738333, lng: -127.066667 },
        { lat: 50.761667, lng: -127.155000 },
        { lat: 50.798333, lng: -127.126667 },
      ]
    }

    const susquash = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.658933, lng: -127.282450 },
        { lat: 50.665000, lng: -127.266667 },
        { lat: 50.630000, lng: -127.196667 },
        { lat: 50.621667, lng: -127.206667 }
      ]
    }


    const haddingtonPassage = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.602583, lng: -127.038283 },
        { lat: 50.610000, lng: -127.006667 },
        { lat: 50.582817, lng: -127.023517 },
        { lat: 50.602583, lng: -127.038283 },
      ]
    }

    const burleyBay = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.934267, lng: -126.775000 },
        { lat: 50.925683, lng: -126.775000 }
      ]
    }

    const burleyBay2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.920400, lng: -126.801667 },
        { lat: 50.921450, lng: -126.801667 }
      ]
    }

    const burleyBay3 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.947633, lng: -126.822200 },
        { lat: 50.956950, lng: -126.823450 }
      ]
    }

    const mackenzieNimmo = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.939383, lng: -126.685000 },
        { lat: 50.937683, lng: -126.685000 }
      ]
    }

    const mackenzieNimmo2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.934083, lng: -126.685000 },
        { lat: 50.928767, lng: -126.685000 }
      ]
    }

    const mackenzieNimmo3 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.928950, lng: -126.734467 },
        { lat: 50.939333, lng: -126.734467 }
      ]
    }

    const wakemanSound = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.996519, lng: -126.503092 },
        { lat: 50.995731, lng: -126.542812 }
    ]
    }

    const sicksonPolkinghorne = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.838333, lng: -126.947500 },
        { lat: 50.834167, lng: -126.968333 },
        { lat: 50.820333, lng: -126.956367 },
        { lat: 50.813667, lng: -126.940750 },
        { lat: 50.803600, lng: -126.942350 }
      ]
    }

    const sicksonPolkinghorne2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.786667, lng: -126.914833 },
        { lat: 50.786383, lng: -126.910000 },
        { lat: 50.805000, lng: -126.883333 }
      ]
    }

    const sicksonPolkinghorne3 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.845000, lng: -126.926233 },
        { lat: 50.842500, lng: -126.933883 }
      ]
    }

    const sicksonPolkinghorne4 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.837083, lng: -126.905000 },
        { lat: 50.836583, lng: -126.905000 }
      ]
    }

    const norwellChannel = {  
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.782883, lng: -126.848183 },
        { lat: 50.750000, lng: -126.786667 },
        { lat: 50.763033, lng: -126.757850 }
      ]
    }

    const norwellChannel2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.767633, lng: -126.746233 },
        { lat: 50.768050, lng: -126.741750 }
      ]
    };
    
    const norwellChannel3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.766833, lng: -126.750800 },
        { lat: 50.766933, lng: -126.750250 }
      ]
    };
    
    const greenwaySound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.862583, lng: -126.733933 },
        { lat: 50.859783, lng: -126.706167 }
      ]
    };
    
    const greenwaySound2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.837450, lng: -126.814500 },
        { lat: 50.838750, lng: -126.814500 }
      ]
    };
    
    const belleisleSound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.903333, lng: -126.420800 },
        { lat: 50.903333, lng: -126.417383 }
      ]
    };
    
    const kwatsiBay = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.846200, lng: -126.280167 },
        { lat: 50.843900, lng: -126.261350 }
      ]
    };
    
    const bondSound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.832733, lng: -126.215683 },
        { lat: 50.825683, lng: -126.192117 },
        { lat: 50.873383, lng: -126.179267 }
      ]
    };
    
    const thompsonSound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.769467, lng: -126.138883 },
        { lat: 50.743283, lng: -126.123933 }
      ]
    };
    
    const salmonChannel = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.708333, lng: -126.871667 },
        { lat: 50.736667, lng: -126.815000 },
        { lat: 50.735000, lng: -126.804167 },
        { lat: 50.688750, lng: -126.808400 },
        { lat: 50.696667, lng: -126.848333 },
        { lat: 50.708333, lng: -126.871667 },
      ]
    };
    
    const weyntonPassage = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.583883, lng: -126.885550 },
        { lat: 50.618700, lng: -126.830000 },
        { lat: 50.586667, lng: -126.805400 },
        { lat: 50.571567, lng: -126.787500 },
        { lat: 50.564450, lng: -126.775550 },
        { lat: 50.574450, lng: -126.863883 }
      ]
    };
    
    const edenBonwickMisummer = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.703933, lng: -126.677683 },
        { lat: 50.701583, lng: -126.689767 },
        { lat: 50.715550, lng: -126.694267 },
        { lat: 50.726883, lng: -126.686167 },
        { lat: 50.740033, lng: -126.686300 }
      ]
    };
    
    const edenBonwickMisummer2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.758500, lng: -126.713850 },
        { lat: 50.750000, lng: -126.750000 },
        { lat: 50.725000, lng: -126.750000 },
        { lat: 50.725000, lng: -126.718333 },
        { lat: 50.720000, lng: -126.724450 },
        { lat: 50.678333, lng: -126.701667 },
        { lat: 50.638500, lng: -126.721217 },
        { lat: 50.634583, lng: -126.722317 },
        { lat: 50.628183, lng: -126.726200 }
      ]
    };
    
    const edenBonwickMisummer3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.602833, lng: -126.687033 },
        { lat: 50.595617, lng: -126.670033 },
        { lat: 50.592583, lng: -126.670100 }
      ]
    };
    
    const edenBonwickMisummer4 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.588850, lng: -126.605617 },
        { lat: 50.604083, lng: -126.604583 },
        { lat: 50.606433, lng: -126.577133 },
        { lat: 50.611917, lng: -126.564383 }
      ]
    };
    
    const edenBonwickMisummer5 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.631117, lng: -126.572500 },
        { lat: 50.660000, lng: -126.618333 },
        { lat: 50.661667, lng: -126.631667 },
        { lat: 50.659150, lng: -126.671533 },
        { lat: 50.666667, lng: -126.693333 },
        { lat: 50.677783, lng: -126.692800 },
        { lat: 50.679267, lng: -126.689217 },
        { lat: 50.685583, lng: -126.669200 }
      ]
    };
    
    const portElizabeth = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.672833, lng: -126.460967 },
        { lat: 50.669067, lng: -126.469467 }
      ]
    };
    
    const lowerClioChannel = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.589950, lng: -126.533033 },
        { lat: 50.582383, lng: -126.534667 }
      ]
    };
    
    const lowerClioChannel2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.556617, lng: -126.553183 },
        { lat: 50.551900, lng: -126.553183 }
      ]
    };
    
    const lowerClioChannel3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.589267, lng: -126.459050 },
        { lat: 50.571433, lng: -126.459050 }
      ]
    };    

    const viscountIsland = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.650083, lng: -126.300850 },
        { lat: 50.649950, lng: -126.266417 }
      ]
    };
    
    const viscountIsland2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.656700, lng: -126.238533 },
        { lat: 50.664100, lng: -126.208217 }
      ]
    };
    
    const viscountIsland3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.666550, lng: -126.196567 },
        { lat: 50.668483, lng: -126.184817 }
      ]
    };
    
    const viscountIsland4 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.688333, lng: -126.201283 },
        { lat: 50.688333, lng: -126.197217 }
      ]
    };
    
    const viscountIsland5 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.725867, lng: -126.202117 },
        { lat: 50.713283, lng: -126.198383 }
      ]
    };
    
    const havannahChannel = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.520000, lng: -126.415250 },
        { lat: 50.516667, lng: -126.409517 },
        { lat: 50.512317, lng: -126.349883 },
        { lat: 50.511667, lng: -126.277483 }
      ]
    };
    
    const havannahChannel2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.575550, lng: -126.172450 },
        { lat: 50.585000, lng: -126.180350 }
      ]
    };
    
    const havannahChannel3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.585000, lng: -126.210367 },
        { lat: 50.578950, lng: -126.216667 }
      ]
    };
    
    const havannahChannel4 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.554167, lng: -126.279283 },
        { lat: 50.554167, lng: -126.269200 }
      ]
    };
    
    const upperCallInlet = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.597900, lng: -126.130917 },
        { lat: 50.593017, lng: -126.126117 }
      ]
    };
    
    const cracroftPointSouth = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.548150, lng: -126.677217 },
        { lat: 50.530133, lng: -126.625433 },
        { lat: 50.533883, lng: -126.619800 }
      ]
    };
    
    const westCracroftIsland = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.520050, lng: -126.520217 },
        { lat: 50.511667, lng: -126.520000 },
        { lat: 50.513333, lng: -126.561667 },
        { lat: 50.525000, lng: -126.559267 }
      ]
    };
    
    const wellborneChannel = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.474607, lng: -125.912676 },
        { lat: 50.460063, lng: -125.902425 }
      ]
    };
    
    const wellborneChannel2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.496414, lng: -125.846662 },
        { lat: 50.489920, lng: -125.817061 }
      ]
    };
    
    const wellborneChannel3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.478522, lng: -125.782402 },
        { lat: 50.475615, lng: -125.794278 }
      ]
    };
    
    const forwardHarbour = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.473284, lng: -125.770568 },
        { lat: 50.466447, lng: -125.769355 }
      ]
    };
    
    const loughboroughInlet = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.550343, lng: -125.549977 },
        { lat: 50.559553, lng: -125.535768 }
      ]
    };
    
    const buteInletNorth = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.836667, lng: -124.912833 },
        { lat: 50.831667, lng: -124.860483 }
      ]
    };
    
    const chancellorChannel = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.441017, lng: -125.736667 },
        { lat: 50.438217, lng: -125.743517 }
      ]
    };
    
    const chancellorChannel2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.423333, lng: -125.739783 },
        { lat: 50.413433, lng: -125.726183 }
      ]
    };
    
    const chancellorChannel3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.440000, lng: -125.632600 },
        { lat: 50.427533, lng: -125.625000 }
      ]
    };
    
    const chancellorChannelEast = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.449350, lng: -125.593200 },
        { lat: 50.438333, lng: -125.590817 }
      ]
    };
    
    const chancellorChannelEast2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.450804, lng: -125.529488 },
        { lat: 50.446121, lng: -125.525103 }
      ]
    };
    
    const walkemIslandsHemmingBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.375567, lng: -125.548817 },
        { lat: 50.357617, lng: -125.525183 },
        { lat: 50.345117, lng: -125.465500 },
        { lat: 50.343333, lng: -125.458333 },
        { lat: 50.343333, lng: -125.451667 },
        { lat: 50.365000, lng: -125.375000 },
        { lat: 50.388333, lng: -125.351667 },
        { lat: 50.391767, lng: -125.355817 }
      ]
    };
    
    const thurstonBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.375400, lng: -125.331200 },
        { lat: 50.349800, lng: -125.350000 }
      ]
    };
    
    const kanishBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.271584, lng: -125.363318 },
        { lat: 50.248343, lng: -125.363168 }
      ]
    };
    
    const octopusIslandsHoskynChannel = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.293050, lng: -125.224800 },
        { lat: 50.295833, lng: -125.210917 }
      ]
    };
    
    const octopusIslandsHoskynChannel2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.165067, lng: -125.160983 },
        { lat: 50.143400, lng: -125.152400 }
      ]
    };
    
    const octopusIslandsHoskynChannel3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.276450, lng: -125.089050 },
        { lat: 50.275911, lng: -125.075169 }
      ]
    };

    const readCortesIslands = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.178217, lng: -125.080133 },
        { lat: 50.178650, lng: -125.011667 },
        { lat: 50.179683, lng: -124.985850 }
      ]
    };
    
    const readCortesIslands2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.148617, lng: -125.101933 },
        { lat: 50.130000, lng: -125.054467 }
      ]
    };
    
    const deepWaterBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.181017, lng: -125.348083 },
        { lat: 50.190567, lng: -125.337800 }
      ]
    };
    
    const menziesBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.136467, lng: -125.381233 },
        { lat: 50.127900, lng: -125.390000 }
      ]
    };
    
    const menziesBay2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.116367, lng: -125.377717 },
        { lat: 50.113767, lng: -125.366667 }
      ]
    };
    
    const menziesBay3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.115883, lng: -125.350050 },
        { lat: 50.128583, lng: -125.361033 }
      ]
    };
    
    const maudIsland = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.128883, lng: -125.338800 },
        { lat: 50.114450, lng: -125.297217 }
      ]
    };
    
    const heriotBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.108333, lng: -125.161667 },
        { lat: 50.108333, lng: -125.113333 },
        { lat: 50.095000, lng: -125.113333 },
        { lat: 50.095000, lng: -125.161667 },
        { lat: 50.108333, lng: -125.161667 },
      ]
    };
    
    const pendrellSound = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.209833, lng: -124.758333 },
        { lat: 50.205000, lng: -124.746667 }
      ]
    };
    
    const teakerneArm = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.173284, lng: -124.899731 },
        { lat: 50.186940, lng: -124.899873 }
      ]
    };
    
    const desolationSound = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.141667, lng: -124.781917 },
        { lat: 50.155431, lng: -124.646835 }
      ]
    };

    const desolationSound2 = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.079167, lng: -124.840217 },
        { lat: 50.075000, lng: -124.805533 }
      ]
    };
    
    const desolationSound3 = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.115800, lng: -124.849700 },
        { lat: 50.087950, lng: -124.849600 }
      ]
    };
    
    const copelandIslands = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.045000, lng: -124.876667 },
        { lat: 50.050000, lng: -124.863333 },
        { lat: 50.033833, lng: -124.828333 },
        { lat: 49.995000, lng: -124.791667 },
        { lat: 49.981667, lng: -124.816667 },
        { lat: 50.045000, lng: -124.876667 }
      ]
    }
    
    const dinnerRock = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 49.948283, lng: -124.718850 },
        { lat: 49.938333, lng: -124.733333 },
        { lat: 49.915000, lng: -124.686667 },
        { lat: 49.926667, lng: -124.669567 }
      ]
    }
    
    const sookeBay = {
      "type": "rockfish closure",
      "area": "20",
      "coordinates": [
        { lat: 48.363333, lng: -123.799450 },
        { lat: 48.360000, lng: -123.796667 },
        { lat: 48.365000, lng: -123.765000 },
        { lat: 48.353333, lng: -123.758333 },
        { lat: 48.348333, lng: -123.734267 },
        { lat: 48.353950, lng: -123.734267 }
      ]
    }
    
    const becherBayEast = {
      "type": "rockfish closure",
      "area": "20",
      "coordinates": [
        { lat: 48.327217, lng: -123.594683 },
        { lat: 48.328333, lng: -123.604450 },
        { lat: 48.326050, lng: -123.606217 },
        { lat: 48.316117, lng: -123.607783 },
        { lat: 48.312433, lng: -123.604450 },
        { lat: 48.315000, lng: -123.590550 }
      ]
    }
    
    const frederickIsland = {
      "type": "rockfish closure",
      "area": "1",
      "coordinates": [
        { lat: 53.945617, lng: -133.133933 },
        { lat: 53.937433, lng: -133.197700 },
        { lat: 53.937433, lng: -133.291667 },
        { lat: 53.783333, lng: -133.166667 },
        { lat: 53.782500, lng: -133.121680 }
      ]
    }
    
    const dunira = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.425833, lng: -130.835000 },
        { lat: 54.402467, lng: -130.921083 },
        { lat: 54.326667, lng: -130.970000 },
        { lat: 54.371667, lng: -130.783333 }
      ]
    }
    
    const dunira2 = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.412000, lng: -130.718917 },
        { lat: 54.419450, lng: -130.730883 }
      ]
    }
    
    const hodgsonReegs = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.398274, lng: -130.485116 },
        { lat: 54.398333, lng: -130.543333 },
        { lat: 54.374167, lng: -130.543333 },
        { lat: 54.374608, lng: -130.483987 }
      ]
    }
    
    const gullRocksNorth = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.188333, lng: -130.576667 },
        { lat: 54.188333, lng: -130.538333 },
        { lat: 54.171667, lng: -130.506667 },
        { lat: 54.171667, lng: -130.565000 },
        { lat: 54.188333, lng: -130.576667 }
      ]
    }
    
    const gullRocksSouth = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.163333, lng: -130.558333 },
        { lat: 54.163333, lng: -130.488333 },
        { lat: 54.140000, lng: -130.440000 },
        { lat: 54.128333, lng: -130.481667 },
        { lat: 54.128333, lng: -130.531667 },
        { lat: 54.163333, lng: -130.558333 }
      ]
    }
    
    const stephensIsland = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.042417, lng: -130.710967 },
        { lat: 54.095000, lng: -130.750000 },
        { lat: 54.095000, lng: -130.911667 },
        { lat: 54.000000, lng: -130.866667 },
        { lat: 54.000000, lng: -130.748333 },
        { lat: 54.025417, lng: -130.714450 }
      ]
    }
    
    const porcherPeninsula = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 53.910000, lng: -130.731667 },
        { lat: 53.906667, lng: -130.858333 },
        { lat: 53.818333, lng: -130.693333 },
        { lat: 53.837250, lng: -130.659617 }
      ]
    }
    
    const goschen = {
      "type": "rockfish closure",
      "area": "5",
      "coordinates": [
        { lat: 53.800000, lng: -130.625000 },
        { lat: 53.786667, lng: -130.653333 },
        { lat: 53.760000, lng: -130.601667 },
        { lat: 53.788183, lng: -130.552700 }
      ]
    }
    
    const westBanksIsland = {
      "type": "rockfish closure",
      "area": "5",
      "coordinates": [
        { lat: 53.465000, lng: -130.663333 },
        { lat: 53.466417, lng: -130.587433 },
        { lat: 53.395000, lng: -130.378333 },
        { lat: 53.311667, lng: -130.358333 },
        { lat: 53.405000, lng: -130.633333 },
        { lat: 53.465000, lng: -130.663333 }
      ]
    }
    
    const northDangerRocks = {
      "type": "rockfish closure",
      "area": "105",
      "coordinates": [
        { lat: 53.265000, lng: -130.370000 },
        { lat: 53.268333, lng: -130.278333 },
        { lat: 53.166667, lng: -130.103333 },
        { lat: 53.166667, lng: -130.355000 },
        { lat: 53.265000, lng: -130.370000 },
      ]
    }
    
    const otterPassage = {
      "type": "rockfish closure",
      "area": "6",
      "coordinates": [
        { lat: 53.166667, lng: -129.952117 },
        { lat: 53.166667, lng: -130.148333 },
        { lat: 53.143333, lng: -130.148333 },
        { lat: 53.083333, lng: -129.835000 },
        { lat: 53.085000, lng: -129.776667 },
        { lat: 53.163917, lng: -129.792983 }
      ]
    }
    
    const westAristazabalIsland = {
      "type": "rockfish closure",
      "area": "106",
      "coordinates": [
        { lat: 52.391667, lng: -129.421667 },
        { lat: 52.391667, lng: -129.091667 },
        { lat: 52.649000, lng: -129.413333 },
        { lat: 52.755000, lng: -129.418333 },
        { lat: 52.713333, lng: -129.541667 },
        { lat: 52.391667, lng: -129.421667 }
      ]
    }
    
    const kitasuBay = {
      "type": "rockfish closure",
      "area": "6",
      "coordinates": [
        { lat: 52.655433, lng: -128.858733 },
        { lat: 52.523333, lng: -128.743150 }
      ]
    }
    
    const kitasuBay2 = {
      "type": "rockfish closure",
      "area": "6",
      "coordinates": [
        { lat: 52.669100, lng: -128.762597 },
        { lat: 52.669791, lng: -128.740389 }
      ]
    }
    
    const mcmullinGroup = {
      "type": "rockfish closure",
      "area": "7",
      "coordinates": [
        { lat: 52.091667, lng: -128.495000 },
        { lat: 52.091667, lng: -128.388333 },
        { lat: 52.006667, lng: -128.388333 },
        { lat: 52.006667, lng: -128.495000 },
        { lat: 52.091667, lng: -128.495000 },
      ]
    }
    
    const gooseIsland = {
      "type": "rockfish closure",
      "area": "7",
      "coordinates": [
        { lat: 52.006667, lng: -128.495000 },
        { lat: 51.935000, lng: -128.541667 },
        { lat: 51.843333, lng: -128.485000 },
        { lat: 51.843333, lng: -128.435000 },
        { lat: 52.006667, lng: -128.388333 },
        { lat: 52.006667, lng: -128.495000 }
      ]
    }

    // MULTI AREA
    const westCalvertIsland = {
      "type": "rockfish closure",
      "area": "8",
      "coordinates": [
        { lat: 51.597000, lng: -128.148933 },
        { lat: 51.597000, lng: -128.167467 },
        { lat: 51.480250, lng: -128.180000 },
        { lat: 51.480250, lng: -128.086567 }
      ]
    }
    
    const fishEggInlet = {
      "type": "rockfish closure",
      "area": "9",
      "coordinates": [
        { lat: 51.623333, lng: -127.791667 },
        { lat: 51.610417, lng: -127.782500 }
      ]
    }
    
    const fishEggInlet2 = {
      "type": "rockfish closure",
      "area": "9",
      "coordinates": [
        { lat: 51.685414, lng: -127.808930 },
        { lat: 51.679743, lng: -127.808378 }
      ]
    }
    
    const smithSound = {
      "type": "rockfish closure",
      "area": "10",
      "coordinates": [
        { lat: 51.374167, lng: -127.777600 },
        { lat: 51.366917, lng: -127.808133 },
        { lat: 51.309333, lng: -127.844333 },
        { lat: 51.248250, lng: -127.833750 },
        { lat: 51.257950, lng: -127.803967 },
        { lat: 51.304717, lng: -127.684450 },
        { lat: 51.311383, lng: -127.687500 },
        { lat: 51.325833, lng: -127.700067 }
      ]
    }
    
    const stormIslands = {
      "type": "rockfish closure",
      "area": "11",
      "coordinates": [
        { lat: 51.033333, lng: -127.745000 },
        { lat: 51.058783, lng: -127.631717 },
        { lat: 51.000217, lng: -127.564200 },
        { lat: 51.018333, lng: -127.701667 },
        { lat: 51.033333, lng: -127.745000 }
      ]
    }
    
    // MULTI AREA
    const scottIslands = {
      "type": "rockfish closure",
      "area": "111",
      "coordinates": [
        { lat: 50.866667, lng: -128.941667 },
        { lat: 50.866667, lng: -128.550000 },
        { lat: 50.750000, lng: -128.550000 },
        { lat: 50.750000, lng: -128.941667 },
        { lat: 50.866667, lng: -128.941667 }
      ]
    }
    
    const topKnot = {
      "type": "rockfish closure",
      "area": "127",
      "coordinates": [
        { lat: 50.608396, lng: -128.303018 },
        { lat: 50.481337, lng: -128.274393 },
        { lat: 50.481412, lng: -128.118775 }
      ]
    }
    
    const holbergInlet = {
      "type": "rockfish closure",
      "area": "27",
      "coordinates": [
        { lat: 50.601950, lng: -127.750217 },
        { lat: 50.593333, lng: -127.759833 }
      ]
    }
    
    const holbergInlet2 = {
      "type": "rockfish closure",
      "area": "27",
      "coordinates": [
        { lat: 50.580893, lng: -127.619028 },
        { lat: 50.595192, lng: -127.618429 }
      ]
    }
    
    const brooksBay = {
      "type": "rockfish closure",
      "area": "27",
      "coordinates": [
        { lat: 50.288136, lng: -127.863909 },
        { lat: 50.287717, lng: -127.864883 },
        { lat: 50.230783, lng: -127.808250 },
        { lat: 50.129278, lng: -127.920504 }
      ]
    }
    
    const checlesetBay = {
      "type": "rockfish closure",
      "area": "26",
      "coordinates": [
        { lat: 50.081167, lng: -127.814267 },
        { lat: 50.059967, lng: -127.841750 },
        { lat: 49.995000, lng: -127.716667 },
        { lat: 50.107700, lng: -127.607567 }
      ]
    }
    
    const westOfBajoReef = {
      "type": "rockfish closure",
      "area": "125",
      "coordinates": [
        { lat: 49.631667, lng: -127.018517 },
        { lat: 49.631667, lng: -126.876667 },
        { lat: 49.558333, lng: -126.876667 },
        { lat: 49.631667, lng: -127.018517 }
      ]
    }
    
    const estevanPoint = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.392100, lng: -126.463633 },
        { lat: 49.325000, lng: -126.398333 },
        { lat: 49.283333, lng: -126.520000 },
        { lat: 49.368333, lng: -126.745000 },
        { lat: 49.417783, lng: -126.576633 }
      ]
    }
    
    const bedwellSound1 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.260407, lng: -125.846266 },
        { lat: 49.259926, lng: -125.809715 }
      ]
    }
    
    const bedwellSound2 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.333099, lng: -125.808775 },
        { lat: 49.332914, lng: -125.802155 }
      ]
    }
    
    const saranacIslands1 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.258333, lng: -125.927017 },
        { lat: 49.258333, lng: -125.867017 }
      ]
    }
    
    const saranacIslands2 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.235000, lng: -125.898767 },
        { lat: 49.245417, lng: -125.936367 }
      ]
    }
    
    const saranacIslands3 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.254833, lng: -125.857970 },
        { lat: 49.241408, lng: -125.855426 }
      ]
    }
    
    const vargasIslandDunlapIsland1 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.210000, lng: -125.968867 },
        { lat: 49.226000, lng: -125.964783 },
        { lat: 49.221667, lng: -125.945833 }
      ]
    }
    
    const vargasIslandDunlapIsland2 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.215683, lng: -125.939550 },
        { lat: 49.201667, lng: -125.950800 }
      ]
    }    

      const brokenIslandsGroup = {
        "type":"rockfish closure",
        "area": "23",
        "coordinates": [
          { lat: 48.9229007, lng: -125.3440797 },
          { lat: 48.9074455, lng: -125.3744638 },
          { lat: 48.905753, lng: -125.3700006 },
          { lat: 48.9038348, lng: -125.3725755 },
          { lat: 48.895597, lng: -125.3720606 },
          { lat: 48.888543, lng: -125.372833 },
          { lat: 48.8809236, lng: -125.3773821 },
          { lat: 48.8737547, lng: -125.3734338 },
          { lat: 48.8640402, lng: -125.3684539 },
          { lat: 48.8631932, lng: -125.3615016 },
          { lat: 48.8638708, lng: -125.3593559 },
          { lat: 48.8644919, lng: -125.3518028 },
          { lat: 48.8620639, lng: -125.3529186 },
          { lat: 48.8614428, lng: -125.3493995 },
          { lat: 48.8625721, lng: -125.345108 },
          { lat: 48.8604264, lng: -125.3420181 },
          { lat: 48.8589582, lng: -125.3432197 },
          { lat: 48.8568688, lng: -125.3404731 },
          { lat: 48.8542146, lng: -125.3377265 },
          { lat: 48.8639837, lng: -125.3180713 },
          { lat: 48.8650001, lng: -125.316183 },
          { lat: 48.8670327, lng: -125.3173847 },
          { lat: 48.8733558, lng: -125.3179855 },
          { lat: 48.8747671, lng: -125.3181571 },
          { lat: 48.8739768, lng: -125.3136939 },
          { lat: 48.8749365, lng: -125.3113765 },
          { lat: 48.8736381, lng: -125.3097457 },
          { lat: 48.8735816, lng: -125.3022785 },
          { lat: 48.8756703, lng: -125.30657 },
          { lat: 48.8767993, lng: -125.3063125 },
          { lat: 48.8762913, lng: -125.3039951 },
          { lat: 48.8791701, lng: -125.3062267 },
          { lat: 48.8790572, lng: -125.3085441 },
          { lat: 48.8806942, lng: -125.311119 },
          { lat: 48.8907968, lng: -125.2774734 },
          { lat: 48.9049031, lng: -125.2625389 },
          { lat: 48.913365, lng: -125.2628822 },
          { lat: 48.916975, lng: -125.2603073 },
          { lat: 48.9172006, lng: -125.2525825 },
          { lat: 48.9259988, lng: -125.2546424 },
          { lat: 48.9279161, lng: -125.2484626 },
          { lat: 48.9317506, lng: -125.2544708 },
          { lat: 48.9293823, lng: -125.2658004 },
          { lat: 48.9306228, lng: -125.269577 },
          { lat: 48.9380656, lng: -125.2609939 },
          { lat: 48.9399824, lng: -125.2645988 },
          { lat: 48.9370507, lng: -125.2676887 },
          { lat: 48.9372762, lng: -125.2709503 },
          { lat: 48.9421247, lng: -125.2752418 },
          { lat: 48.9293823, lng: -125.2941246 },
          { lat: 48.9222767, lng: -125.321762 },
          { lat: 48.9195695, lng: -125.3269119 },
          { lat: 48.918103, lng: -125.3312034 },
          { lat: 48.917539, lng: -125.3353233 },
          { lat: 48.9229007, lng: -125.3440797 }
        ]
      }

      const folgerPassage = {
        "type": "rockfish closure",
        "area": "23",
        "coordinates": [
          { lat: 48.846667, lng: -125.315000 },
          { lat: 48.808333, lng: -125.248333 },
          { lat: 48.790000, lng: -125.271667 },
          { lat: 48.830000, lng: -125.338333 },
          { lat: 48.846667, lng: -125.315000 }
        ]
      }
      
      const pachenaPoint = {
        "type": "rockfish closure",
        "area": "21",
        "coordinates": [
          { lat: 48.722117, lng: -125.097583 },
          { lat: 48.713333, lng: -125.097583 },
          { lat: 48.676333, lng: -124.992133 },
          { lat: 48.706380, lng: -124.993170 }
        ]
      }
      
      const darePoint = {
        "type": "rockfish closure",
        "area": "21",
        "coordinates": [
          { lat: 48.661667, lng: -124.819683 },
          { lat: 48.643333, lng: -124.831667 },
          { lat: 48.640550, lng: -124.796117 }
        ]
      }
      
      const carmanah = {
        "type": "rockfish closure",
        "area": "20",
        "coordinates": [
          { lat: 48.622500, lng: -124.764117 },
          { lat: 48.616667, lng: -124.780000 },
          { lat: 48.575000, lng: -124.691667 },
          { lat: 48.585000, lng: -124.679567 }
        ]
      }

    export const closureAreas = [
        domettPoint, 
        easternBurrardInlet,
        howeSoundAlbertaBay,
        howeSoundAnvilIsland,
        howeSoundBowyerIsland,
        howeSoundBrunswickPoint,
        howeSoundCarmeloPoint,
        howeSoundCollingwoodChannel,
        howeSoundDefenceIslands,
        howeSoundDormanPoint,
        howeSoundEastDefenceIslands,
        howeSoundHalkettPoint,
        howeSoundLangdale,
        howeSoundLionsBayKelvinGrove,
        howeSoundLostReef,
        howeSoundMarinersRest,
        howeSoundQueenCharlotteChannel,
        howeSoundQueenCharlotteChannel2,
        howeSoundQueenCharlotteChannel3,
        howeSoundQueenCharlotteChannel4,
        lionsBay,
        marinersRest,
        indianArmCrokerIslandNorthLine,
        indianArmCrokerIslandSouthLine,
        indianArmTwinIslandsNorth,
        indianArmTwinIslandsSouth,
        pamRocks,
        passageIslandWestVan,
        passageIsland,
        westBay,
        upperCentreBay,
        woolRidgeIsland,
        pasleyIsland,
        bowyerIsland,
        foreSlopeHills,
        halibutBankRca,
        halibutBankSpongeReef,
        galianoIslandNorth,
        gabriolaPassage,
        gabriolaPassage2,
        gabriolaPassage3,
        mayneIsland,
        mayneIsland2,
        valdesIslandEast,
        sechelt,
        outerGulf1,
        outerGulf2,
        outerGulf3,
        outerGulf4,
        mcCallBank,
        bedwellHarbour,
        bedwellHarbour2,
        brethourDomvilleForrestPooch,
        coalIsland,
        coalIsland2,
        coalIsland3,
        coalIsland4,
        mapleBay,
        mapleBay2,
        navyChannel,
        navyChannel2,
        pateyRock,
        portlandIsland,
        prevostIslandNorth,
        prevostIslandNorth2,
        russellIsland,
        southSaturna,
        southSaturna2,
        bellIsland,
        ballenasIsland,
        coffinPoint,
        dangerReef,
        deCourcyIslandNorth,
        departureBay,
        gabriolaIsland,
        lasquetiSouthYoungPoint,
        nanooseSchoonerCove,
        northumberlandChannel,
        northumberlandChannel2,
        reynoldsPointLinkIsland,
        reynoldsPointLinkIsland2,
        ruxtonPyladesIslands,
        saltSpringIslandNorth,
        saltSpringIslandNorth2,
        thetisKuperIslands,
        thetisKuperIslands2,
        trincomaliChannel,
        trincomaliChannel2,
        trincomaliChannel3,
        trincomaliChannel4,
        davieBay,
        hardyIsland,
        hardyIsland2,
        hardyIsland3,
        hothamSound,
        malaspinaStrait,
        mcNaughtonPoint,
        princessLouisaInlet,
        queensReachEast,
        queensReachWest,
        sabineJervisJedediah,
        sabineJervisJedediah2,
        salmonInlet,
        salmonInlet2,
        sinclairBank,
        skookumchuckNarrows,
        skookumchuckNarrows2,
        thormanbyIsland,
        thormanbyIsland2,
        ajaxAchillesBank,
        baynesSound,
        chromeIsland,
        eastHornbyIsland,
        lasquetiIslandSouth,
        mitlenatchIsland,
        oysterBay,
        parksVille,
        savoieRocks,
        sisterIslets,
        brentWoodBay,
        darcyIsland,
        discoveryChathamIslands,
        discoveryChathamIslands2,
        discoveryChathamIslands3,
        duntzeHead,
        midFinlaysonArm,
        raceRocks,
        bentinckIsland,
        trialIsland,
        brokenIslandsGroup,
        shadwellPassage,
        shadwellPassage2,
        goletasChannel,
        goletasChannel2,
        browningPassageHuntRock,
        browningPassageHuntRock2,
        bolivarPassage,
        shelterBay,
        browningIslandsRaynorGroup, 
        druryInletMuirheadIslands,
        druryInletMuirheadIslands2,
        hardyBay, 
        numasIslands,
        susquash,
        haddingtonPassage,
        burleyBay,
        burleyBay2,
        burleyBay3,
        mackenzieNimmo,
        mackenzieNimmo2,
        mackenzieNimmo3,
        wakemanSound,
        sicksonPolkinghorne,
        sicksonPolkinghorne2,
        sicksonPolkinghorne3,
        sicksonPolkinghorne4,
        norwellChannel,
        norwellChannel2,
        norwellChannel3,
        greenwaySound,
        greenwaySound2,
        belleisleSound,
        kwatsiBay,
        bondSound,
        thompsonSound,
        salmonChannel,
        weyntonPassage,
        edenBonwickMisummer,
        edenBonwickMisummer2,
        edenBonwickMisummer3,
        edenBonwickMisummer4,
        edenBonwickMisummer5,
        portElizabeth,
        lowerClioChannel,
        lowerClioChannel2,
        lowerClioChannel3,
        viscountIsland,
        viscountIsland2,
        viscountIsland3,
        viscountIsland4,
        viscountIsland5,
        havannahChannel,
        havannahChannel2,
        havannahChannel3,
        havannahChannel4,
        upperCallInlet,
        cracroftPointSouth,
        westCracroftIsland,
        wellborneChannel,
        wellborneChannel2,
        wellborneChannel3,
        forwardHarbour,
        loughboroughInlet,
        buteInletNorth,
        chancellorChannel,
        chancellorChannel2,
        chancellorChannel3,
        chancellorChannelEast,
        chancellorChannelEast2,
        walkemIslandsHemmingBay,
        thurstonBay, 
        kanishBay, 
        octopusIslandsHoskynChannel,
        octopusIslandsHoskynChannel2,
        octopusIslandsHoskynChannel3,
        readCortesIslands,
        readCortesIslands2,
        deepWaterBay,
        menziesBay,
        menziesBay2, 
        menziesBay3,
        maudIsland,
        heriotBay, 
        pendrellSound, 
        teakerneArm,
        desolationSound,
        desolationSound2,
        desolationSound3,
        copelandIslands,
        dinnerRock,
        sookeBay,
        becherBayEast,
        frederickIsland,
        dunira,
        dunira2,
        hodgsonReegs,
        gullRocksNorth,
        gullRocksSouth,
        stephensIsland,
        porcherPeninsula,
        goschen,
        westBanksIsland,
        northDangerRocks,
        otterPassage,
        westAristazabalIsland,
        kitasuBay,
        kitasuBay2,
        mcmullinGroup,
        gooseIsland,
        topKnot,
        holbergInlet,
        holbergInlet2,
        brooksBay,
        checlesetBay,
        westOfBajoReef,
        estevanPoint,
        bedwellSound1,
        bedwellSound2,
        saranacIslands1,
        saranacIslands2,
        saranacIslands3,
        vargasIslandDunlapIsland1,
        vargasIslandDunlapIsland2,
        carmanah,
        folgerPassage,
        pachenaPoint,
        darePoint,
        westCalvertIsland,
        fishEggInlet,
        fishEggInlet2,
        smithSound,
        stormIslands,
        scottIslands,
    ]

